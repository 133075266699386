import {getLGToken} from "../../utils/common";

const url_arr = [
	'https://11-alarm-mop.meshare.com',
	'https://12-alarm-mop.meshare.com',
	'https://13-alarm-mop.meshare.com',
	'https://14-alarm-mop.meshare.com'
]

const url_arr_dev = [
	'https://11-alarm-mop.meshare.cn',
]

const getUrl = () => {
	let env = process.env.NODE_ENV; // development
	let uArr:any = env === 'development' ? url_arr_dev : url_arr
	// uArr = url_arr_dev;
	return getRandomElementFromArray(uArr);
}

const getRandomElementFromArray = (array) => {
	if (array && array.length > 0) {
		// 生成一个随机索引，范围是数组长度减一
		const randomIndex = Math.floor(Math.random() * array.length);
		// 使用该索引从数组中取出元素并返回
		return array[randomIndex];
	} else {
		return null; // 如果数组为空或未定义，则返回null或其他默认值
	}
}

let apiUrl = getUrl();
let _token = getLGToken();

export default function pfFileUrl(url){
	let _token = getLGToken();
	return `${apiUrl}/storage/get_file?token=${_token}&url=${url}`;
}
