import './index.less';
import {Drawer} from "antd";
import Icon from "@ant-design/icons";
import CloseIcon from "../../../../assets/images/cloudPlan/close.svg";
import React from "react";
import {useFreeTrialSubscribeStore} from "../../../../store/cloudPlan";
import {getLGToken} from "../../../../utils/common";

export default function SubscribeModelFreetrial(){

	const {freeTrialSubscribeZU, setFreeTrialSubscribeZU} = useFreeTrialSubscribeStore()
	let _token = getLGToken();

	// let _plan = `${cloudInfoZU?.data?.period}-${cloudInfoZU?.data?.pay_type}`
	let url = `https://11-web.meshare.com/zmd/application/cloud/trial.html?token=${_token}&tokenid=${_token}&version=2`

	const onClose = () => {
		setFreeTrialSubscribeZU({open: false, data: freeTrialSubscribeZU.data});
	};
	return <Drawer
		title="Basic Drawer"
		placement="right"
		width={640}
		className='security-subscribe-modal free-trial-subscribe-modal'
		onClose={onClose}
		open={true}
	>
		<div className='details-modal-title'>
			<p></p>
			<p>Free Trial</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>
		<div className={'free-trial-sub-modal-main-box'}>
			<iframe src={url}></iframe>
		</div>
	</Drawer>
}
