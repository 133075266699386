import {
    BrowserRouter as Router,
    Route,
    Routes,
  } from 'react-router-dom';
import LayOut from '../layout';
import Login from '../pages/login';
import ForgotPassword from '../pages/forgotPassword';
import SignUp from '../pages/signUp';
import FilePreviewPage from "../pages/filePreview";
import {getPlatfromByUrl} from "../utils/common";



  const BasicRouter = () => {
  	try {
			console.log('env:', process.env.NODE_ENV);

			let _platfrom = getPlatfromByUrl();
			let faviconLink = document.querySelector("link[rel='icon']");
			let page_title = 'Smartz Eaze Web App';
			let page_icon = '/favicon.ico';

			if(['zmd', 'zmodo'].includes(_platfrom)){
				page_title = 'Zmodo Web App';
				page_icon = '/res/zmodo/favicon.ico'
			}else{
				page_title = 'Smartz Eaze Web App';
				page_icon = '/favicon.ico'
			}
			if(faviconLink){
				faviconLink.setAttribute('href', page_icon);
			}
			document.title = page_title;

		}catch (e) {
			console.log(e);
		}


    return (
      <Router>
        <Routes>
          <Route path='/*' element={<LayOut />} />
          {/*<Route path="/" element={<Login />} />*/}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<SignUp />} />

					<Route path="/file-preview" element={<FilePreviewPage />} />
        </Routes>
      </Router>
    );
  };

  export default BasicRouter;
