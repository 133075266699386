import token from "../token";

function jumpPage(func, urlpath, option:any = null){
	let url = urlpath

	if(url.includes('?')){
		// url = `${url}&token=${token}`
		url = `${url}`
	}else{
		// url = `${url}?token=${token}`
		url = `${url}`
	}
	if(Object.prototype.toString.call(func) === '[object Function]'){
		if(option === null){
			func(url)
		}else{
			func(url, option)
		}
	}else{
		window.location.href = url
	}
}

export default jumpPage
