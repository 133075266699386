import {Popup} from 'antd-mobile'
import {useEffect, useState} from 'react'
import './index.less'

let keysObj = {
	1: 'name',
	2: 'updateTime',
	3: 'size'
}

function SortByModal(props) {
	const {visible, onVisible, sortFunc, sortValue} = props
	const [itemObj, setItemObj] = useState<any>({
		orderBy: '',
		order: true, //Asc:顺序 Desc:逆序
	})
	const handleItem = (str) => {
		// itemObj(str)
		// onVisible(false)


		//name, updateTime, size
		if(sortFunc){
			let _order = itemObj.orderBy === str ? !itemObj.order : true;

			let _itemObj:any = {
				orderBy: str,
				order: _order ? 'asc' : 'desc',
			}

			sortFunc(_itemObj)
		}
	}

	useEffect(() => {
		let o = Object.entries(keysObj);
		let _itemObj:any = {
			orderBy: sortValue.orderBy,
			order: sortValue.order === 'asc' ? true : false,
		}
		if(sortValue){
			setItemObj(() => _itemObj)
		}
	}, [sortValue])

	return <Popup
		className="sort-by-modal"
		visible={visible}
		onMaskClick={() => {
			onVisible(false)
		}}
		onClose={() => {
			onVisible(false)
		}}
	>
		<div className="popup-title">Sort by</div>
		<div className={itemObj.orderBy === 'name' ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem('name')}>
			<p style={{marginLeft: "0px"}}>Name</p>
			{
				itemObj.orderBy === 'name' ? <>
						<img src="/res/icons/icon_order.svg" alt="" style={{width: "14px", marginLeft: "8px", transform: itemObj.order ? 'rotate(0deg)' : 'rotate(180deg)' }}/>
				</>
				:
				null
			}
		</div>
		<div className={itemObj.orderBy === 'updateTime' ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem('updateTime')}>
			<p style={{marginLeft: "0px"}}>Last Modified</p>
			{
				itemObj.orderBy === 'updateTime' ? <>
						<img src="/res/icons/icon_order.svg" alt="" style={{width: "14px", marginLeft: "8px", transform: itemObj.order ? 'rotate(0deg)' : 'rotate(180deg)' }}/>
					</>
					:
					null
			}
		</div>
		<div className={itemObj.orderBy === 'size' ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem('size')}>
			<p style={{marginLeft: "0px"}}>Storage Used</p>
			{
				itemObj.orderBy === 'size' ? <>
						<img src="/res/icons/icon_order.svg" alt="" style={{width: "14px", marginLeft: "8px", transform: itemObj.order ? 'rotate(0deg)' : 'rotate(180deg)' }}/>
					</>
					:
					null
			}
		</div>
	</Popup>
}

export default SortByModal
