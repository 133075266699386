import service from '../newRequest'
import getApiUrl from "../apiUrl";
import requsetData from "../request";
import {getLGToken, isJSON, json2params} from "../../utils/common";
import token from "../../utils/token";
const url1 = getApiUrl('cloudDisk');
const url = '/vdr'



// 获取文件夹的内容
export function getFolderList(data) {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    if (data.dirId || data.dirId === 0) {
        return service.get(`${url}/folder?dirId=${data.dirId}&order=${data.order}&orderBy=${data.orderBy}&key=${data.key}&page=${data.page}&pageSize=${data.pageSize}`, null, headers)
    } else if (data.key) {
        return service.get(`${url}/folder?dirId=${data.dirId}&key=${data.key}&order=${data.order}&orderBy=${data.orderBy}&page=${data.page}&pageSize=${data.pageSize}`, null, headers)
    } else if (data.collect) {
        return service.get(`${url}/folder?collect=${data.collect}&order=${data.order}&orderBy=${data.orderBy}&page=${data.page}&pageSize=${data.pageSize}`, null, headers)
    } else {
        return service.get(`${url}/folder?order=${data.order}&orderBy=${data.orderBy}&key=${data.key}&page=${data.page}&pageSize=${data.pageSize}`, null, headers)
    }


}

// 获取文件夹的内容
export function getFolder(data) {
    // const token = "248540b29b8356fedcda94276ab3cad2"
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
        // 'Authorization': `Bearer ${token}`
    }
    return service.post(`${url}/folder`, data, headers)
}

// 重命名文件夹
export function getRename(data) {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    return service.put(`${url}/folder/${data.id}/rename/${data.name}`, null, headers)
}

// // 获取文件夹信息
// export function getFileDetails(id) {
//     let headers = {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`
//     }
//     return service.get(`${url}/folder/${id}`, null, headers)
// }

// 收藏

export function getCollect(data) {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    return service.post(`${url}/collect`, data, headers)
}

// 删除
export function getFolderDelete(data) {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    return service.delete(`${url}/folder/${data.id}`, null, headers)
}

// // 文件下载， 读取文件
// export function getFileByUrl(data){
//     const token = localStorage.getItem('token')
//     let headers = {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//     }
// 	return `${url}/disk/download_file?url=${data.cover}&name=${data.name}&token=${token}`
// }


// 获取回收站列表
export function getRecycle(data) {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    return service.get(`${url}/recycle/?isRecycle=${data.isRecycle}`, null, headers)
}


// 获取文件夹下的内容
export function getFolderContent(params = {}) {
    // dirId
    return requsetData(`${url}/folder?${json2params(params)}`, null)
}

// 新建文件夹
export function createFolder(params) {
    return requsetData(`${url}/folder`, params, { method: 'post', headers: null })
}

// 收藏文件夹/文件
export function collectFolderOrFile(params) {
    return requsetData(`${url}/collect`, params, { method: 'post', headers: null })
}

// 批量收藏文件夹/文件
export function collectListFolderOrFile(params) {
    return requsetData(`${url}/collect/batch-collect`, params, { method: 'post', headers: null })
}


// 批量取消收藏文件夹/文件
export function cancelCollectListFolderOrFile(params) {
    return requsetData(`${url}/collect/batch-cancel`, params, { method: 'delete', headers: null })
}


// 获取文件夹信息
export function getFolderDetails(id, params: any = null) {
    if (isJSON(params)) {
        return requsetData(`${url}/folder/${id}?${json2params(params)}`, { method: 'get', headers: null })
    } else {
        return requsetData(`${url}/folder/${id}`, { method: 'get', headers: null })
    }

}

// 获取文件信息
export function getFileDetails(id, params: any = null) {
    if (isJSON(params)) {
        return requsetData(`${url}/file/${id}?${json2params(params)}`, { method: 'get', headers: null })
    } else {
        return requsetData(`${url}/file/${id}`, { method: 'get', headers: null })
    }
}


// 删除文件夹
export function deleteFolder(id) {
    return requsetData(`${url}/folder/${id}`, {}, { method: 'delete', headers: null })
}

// 批量删除文件夹
export function deleteFolderList(ids = []) {
    return requsetData(`${url}/folder/batch-delete`, ids, { method: 'delete', headers: null })
}

// 批量永久删除文件夹
export function deleteFolderListForever(ids = []) {
    return requsetData(`${url}/folder/batch-delete-forever`, ids, { method: 'delete', headers: null })
}

// 删除文件
export function deleteFile(id) {
    return requsetData(`${url}/file/${id}`, {}, { method: 'delete', headers: null })
}

// 批量删除文件
export function deleteFileList(ids = []) {
    return requsetData(`${url}/file/batch-delete`, ids, { method: 'delete', headers: null })
}

// 批量永久删除文件
export function deleteFileListForever(ids = []) {
    return requsetData(`${url}/file/batch-delete-forever`, ids, { method: 'delete', headers: null })
}

// 重命名文件夹
export function renameFolder(id, name) {
    return requsetData(`${url}/folder/${id}/rename/${name}`, {}, { method: 'put', headers: null })
}

// 重命名文件
export function renameFile(id, name) {
    return requsetData(`${url}/file/${id}/rename/${name}`, {}, { method: 'put', headers: null })
}

// 复制文件夹
export function copyFolder(id, dirId) {
    return requsetData(`${url}/folder/${id}/copy/${dirId}`, {}, { method: 'post', headers: null })
}

// 复制文件
export function copyFile(id, dirId) {
    return requsetData(`${url}/file/${id}/copy/${dirId}`, {}, { method: 'post', headers: null })
}

// 移动文件夹
export function moveFolder(id, dirId) {
    return requsetData(`${url}/folder/${id}/move/${dirId}`, {}, { method: 'put', headers: null })
}

// 移动文件
export function moveFile(id, dirId) {
    return requsetData(`${url}/file/${id}/move/${dirId}`, {}, { method: 'put', headers: null })
}

// 文件下载， 读取文件
export function getFileByUrl(params) {
    return requsetData(`${url}/disk/download_file?${json2params({ ...params, token })}`, { method: 'get', headers: null })
}

// 批量复制
export function copyItemsList(data) {
    return requsetData(`${url}/folder/batch-copy`, data, { method: 'post', headers: null })
}

// 批量移动
export function moveItemsList(data) {
    return requsetData(`${url}/folder/batch-move`, data, { method: 'put', headers: null })
}


// 获取大文件
export function getLargeFile(params = {}) {
    return requsetData(`${url}/file/large-files?${json2params(params)}`, {})
}
