import { useState, useEffect, useRef, useImperativeHandle } from 'react'
import './index.less'

import * as PIXI from 'pixi.js/dist/pixi'
// import { formatDate } from '../../utils/date'
// import { formatLocalDate } from '../../utils/localdate'
import iconRuler from '../../image/ruler.png';
import { getDevCVRRecordList, checkDevCVRRecord } from '../../../../server/device'
import dayjs from 'dayjs'
import _ from 'lodash'

export default function VideoProgressBar(props) {
  const {
    type,
    deviceInfo,
    runPlayTime,
		setPlaybackTime,
    setCurrentDateToPlayer,
    playerCurrentDate,
    videoFPS,
    playerGetCurrentPlayingTime,
		playbackTime,
		setRecordDate
  } = props;
  const physical_id = deviceInfo.physical_id ? deviceInfo.physical_id : '';

  const [progressBar, setProgressBar] = useState({
    padding: 25, //左右留白
    diviceRatio: 1,
    processHeight: 20,
    processWidth: 7 * (window.innerWidth - 50), //减去padding的两倍
    controllerHeight: 80,
    chooseIndex: 1, //指向最小一段的视频
    backgroundColor: "0xdcddde", //app.renderer.backgroundColor 0xdcddde
    labelColors: ["0x07111b"],
    lineColors: ["0x5f5f5f", "0x9c9c9c"],
    barColors: {
      blue: "0xb1daf7",
      yellow: "0xffd065",
      red: "0xff6c65",
      gray: "0xdcddde",
    },
    // chooseImg: ruler,
    scale: 120,
    scaleHR: 120,
    scaleMIN: 60,
    scaleSEC: 300,
    width: window.innerWidth, //app.renderer.view.width,app.renderer.view.style.width
    height: 77, //app.renderer.view.height
    perStep: (window.innerWidth - 50) / 120, //default
    perStepHR: (window.innerWidth - 50) / 120,
    perStepMIN: (window.innerWidth - 50) / 60,
    perStepSEC: (window.innerWidth - 50) / 300,
    timeController: {
      viewStartTime: 0, //视图内录像起止时间
      viewEndTime: 0,
    },
    liveProgressBarItemStartTime: 0,
    liveProgressBarItemStartPosX: 0,
    onemsWidth: (window.innerWidth - 50) / (24 * 60 * 60 * 1000), //1ms的视图宽度
  })

  const [recordInfo, setRecordInfo] = useState<any>({});
  const [recordList, setRecordList] = useState<any>([]);
  const [hasRecordDays, setHasRecordDays] = useState<any>({});
  const [currentDate, setCurrentDate] = useState<string>(''); // 当前的日期时间
  const [currentDateHours, setCurrentDateHours] = useState<string>(''); // 当前的日期时间

  const [currentDateX, setCurrentDateX] = useState<string>(''); // 当前的日期时间
  const [barType, setBarType] = useState<string>('HR'); //HR MIN

  const vPlayerRef = useRef(null);
  const vApplicationRef = useRef(null);
  const vAllContainerRef = useRef(null); // 大容器
  const vScaleContainerRef = useRef(null); // 刻度容器
  const vRecordContainerRef = useRef(null); // 记录进度条容器

  const vDiffRef = useRef<number>(0);

  const vMouseEventRef = useRef<any>({
    down: false,
    up: false,
    move: false,
    x: 0,
    y: 0,
    leave: false,
    enter: false,
    automanual: true,
    diffLength: 0,
    currentPosition: 0,
  });

  const mouseEventRef = useRef({
    down: false,
    up: false,
    move: false,
    x: 0,
    y: 0,
    leave: false,
    enter: false,
    automanual: true,
    diffLength: 0,
    currentPosition: 0,
  })

  const mouseEvent = mouseEventRef.current;

  const loadTimeCountRef = useRef<any>(0);
  const progressBarConfigRef = useRef<any>(null);
  const currentDateRef = useRef<any>('');
  const currentDateHoursRef = useRef<any>('');
  const currentDateHoursIIRef = useRef<any>('');  // 与进行对比
  const barTypeRef = useRef<any>('HR');
  const devOffsetTimeRef = useRef(0);
  const runnerTimeIntimevalRef = useRef<any>(0)

  const clickProgressBarTimeOutRef = useRef<any>(0); //点击进度条事件，去重处理
  const clickProgressBarTimeOutIIRef = useRef<any>(0); //点击进度条事件，去重处理
  const dateTimeResetFlagRef = useRef<boolean>(false); //点击之后， 切换日期的， 指针回到零点位置或者记录开始的位置

  const mouseObtainedReferenceDateTimeRef = useRef({
    start: 0,
    end: 0,
  }); //鼠标获取的两次参考时间

  //1 拖动的时间

  //2 最新的视频的实时时间
  const [currentRealTime, setCurrentRealTime] = useState<any>('');
  const [clickPSTime, setClickPSTime] = useState<any>('');  //点击时的时间， 用于处理选点之后  剪切视频
  const [runnerTime, setRunnerTime] = useState<any>('');
  const runnerTimeRef = useRef<number>(0);

  //3 拖动后 播放的时间


  // 播放状态
  const [playStatus, setPlayStatus] = useState<string>(''); // '' 'live' 'playback'

  const [cvrRecordDateArray, setCvrRecordDateArray] = useState<any>([]);
  const cvrRecordDateArrayRef = useRef<any>([]);
  const playbackStartDateTimeRef = useRef<any>('');

  //鼠标事件 操作中
	const mouseEventOperatingRef = useRef<boolean>(false)


  const progressTimeObjectRef = useRef<any>({
    start_date_time: 0,
    end_date_time: 0,
    real_date_time: 0,
  });

  const local_offset_time = new Date().getTimezoneOffset() * 60 * 1000;

  //暴露方法给父组件调用
  useImperativeHandle(props.onRef, () => {
    return {
      ChildSetCurrentDate: setCurrentDateX,
			setCurrentDate:setCurrentDate,
			setCurrentDateHours: setCurrentDateHours
    }
  })

  // 进度条基础处理  绑定事件等
  useEffect(() => {

    if(vPlayerRef.current){ // && vApplicationRef.current === null

      let c_width = vPlayerRef.current.clientWidth;
      let c_height = 100;
      let c_padding = 30;
      // 设置canvas的宽度和高度

      let c_available_length = c_width - 2 * c_padding;

      let progressBarConfig:any = {
        width: c_width,
        height: c_height,
        padding: c_padding,
        available_width: c_available_length,
      }

      //Create a Pixi Application
			vApplicationRef.current = new PIXI.Application({
				width: c_width,
				height: c_height,
				antialias: true,    // default: false 反锯齿
				transparent: false, // default: false 透明度
				resolution: 1,       // default: 1 分辨率
				forceCanvas: true,   // default: false 强制使用canvas渲染
				backgroundColor: '#f5f5f5', // default: 0x000000 背景颜色
			});

      //Add the canvas that Pixi automatically created for you to the HTML document
			if(vPlayerRef.current.childNodes.length !== 0){
				vPlayerRef.current.removeChild(vPlayerRef.current.childNodes[0]);
			}
      vPlayerRef.current.appendChild(vApplicationRef.current.view);

      // 大容器， 包含所有内容
      const allContainer:any = new PIXI.Container();
      vAllContainerRef.current = allContainer;
      allContainer.interactive = true;
      allContainer.buttonMode = true;
      vApplicationRef.current.stage.addChild(allContainer);

      // 整个背景
      const bg = new PIXI.Graphics();
      bg.beginFill(0xf5f5f5);
      bg.drawRect(0, 0, c_width, c_height);
      bg.endFill();
      const bgContainer = new PIXI.Container();
      bgContainer.removeChildren();
      bgContainer.addChild(bg);
      allContainer.addChild(bgContainer);

      // 创建刻度轨道，并添加背景
      const trackbg = new PIXI.Graphics();
      trackbg.beginFill(0xd3d3d3);
      trackbg.drawRect(0, 20, c_width, 25);
      trackbg.endFill();
      // track.interactive = true;

      const trackbgContainer = new PIXI.Container();
      trackbgContainer.addChild(trackbg);
      allContainer.addChild(trackbgContainer);

      const trackContainer:any = new PIXI.Container();
      vScaleContainerRef.current = trackContainer;
      trackContainer.removeChildren();
      allContainer.addChild(trackContainer);

      // 进度刻度条容器
      let recordContainer:any = new PIXI.Container();
      vRecordContainerRef.current = recordContainer;
      allContainer.addChild(recordContainer);

      let sprite = PIXI.Sprite.from(iconRuler);
      let rulerContainer = new PIXI.Container();
      // app.stage.addChild(sprite);

      sprite.interactive = true;
      sprite.buttonMode = true;

      progressBarConfig.sprite = sprite;
      progressBarConfig.rulerContainer = rulerContainer;

      progressBarConfigRef.current = progressBarConfig;


      let mouse_event = ''; // 鼠标一次事件

      allContainer.on('pointermove', (event) => {
        //some function here that happens on mousemove

        if(mouseEvent.down){
          // console.log('move', event.data.global);
          mouseEvent.x = event.data.global.x - 5 + 1;

          lastPos(mouseEvent.x, false, 10)
        }
      })

      allContainer.on('pointerdown', (event) => {
        // console.log('allContainer onpointerdown');
        mouseEvent.down = true;
        mouseEvent.up = false;
        mouseEvent.automanual = false;
      })

      allContainer.on('pointerup', (event) => {
        // console.log('allContainer onpointerup', event.data.global);
        // console.log(event, '------3------');
        mouseEvent.down = false;
        mouseEvent.up = true;

        mouseEvent.x = event.data.global.x - 5 + 1;

        lastPos(mouseEvent.x, true)

        const progressBarConfig = progressBarConfigRef.current;
        let xxx = event.data.global.x + 1;
        let _diff = ((xxx - progressBarConfig.padding) / progressBarConfig.ratio) * 1000;


        // 现在的时间
        let _now_date_time = dayjs((new Date().getTime()) + devOffsetTimeRef.current + local_offset_time).format('YYYY-MM-DD HH:mm:ss');

        // 点击的时间
        // let _click_date = new Date(`${}`)
        // let _click_date_time = dayjs(_click_date + _diff).format('YYYY-MM-DD HH:mm:ss');

        // console.log(_now_date_time, '_now_date_time', devOffsetTimeRef.current);
        // 当前进度开始的时间
        let now_start_time = 0;


				let ct:any = dayjs().format('YYYY-MM-DD 00:00:00');
				if(currentDateRef.current){
					ct = dayjs(currentDateRef.current).format('YYYY-MM-DD 00:00:00');
				}

				let dv = dayjs(ct).valueOf();
				if(_diff){
					ct = dayjs(dv + _diff).format('YYYY-MM-DD HH:mm:ss');
				}

				let ct_ss = Math.floor(new Date(ct).getTime() / 1000);

				// 当前进度时间最大实时
				let now_real_time = runnerTimeRef.current * 1000 + devOffsetTimeRef.current + local_offset_time;

				// 当前点击处的时间
				let now_click_time = ct_ss * 1000;

				if(now_real_time > now_click_time){
					currentPlayTime(_diff);
				}

      })

      allContainer.on('pointerout', (event) => {
        if(mouseEvent.down){
          mouseEvent.down = false;
          mouseEvent.up = true;
          mouseEvent.x = event.data.global.x - 5 + 1;

          lastPos(mouseEvent.x)
        }
      })


      const lastPos = (v, fl=false, ms=100) => {
        clearTimeout(clickProgressBarTimeOutRef.current)

				mouseEventOperatingRef.current = !fl;

        const innFun = () => {
					const progressBarConfig = progressBarConfigRef.current;
					let xxx = v + 5;
					let _diff = ((xxx - progressBarConfig.padding) / progressBarConfig.ratio) * 1000;

					let ct:any = dayjs().format('YYYY-MM-DD 00:00:00');
					if(currentDateRef.current){
						ct = dayjs(currentDateRef.current).format('YYYY-MM-DD 00:00:00');
					}

					if(barTypeRef.current === 'MIN' && currentDateHoursRef.current){
						ct = `${currentDateHoursRef.current}:00:00`;
					}

					let dv = dayjs(ct).valueOf();
					if(_diff){
						ct = dayjs(dv + _diff).format('YYYY-MM-DD HH:mm:ss');
					}

					let ct_ss = Math.floor(new Date(ct).getTime() / 1000);


					// 当前进度开始的时间
					let now_start_time = 0;
					// 当前进度时间最大实时
					let now_real_time = runnerTimeRef.current * 1000 + devOffsetTimeRef.current + local_offset_time;

					// 当前点击处的时间
					let now_click_time = ct_ss * 1000;
					let now_date = new Date(`${currentDateRef.current} 00:00:00`).getTime();


					if(now_click_time >= now_real_time){
						// 将时间转换成 像素
						let _px = (((now_real_time - now_date) / 1000) * progressBarConfig.ratio);
						setSpritePosition(_px + progressBarConfig.padding - 5);

						// console.log(_px, _px + progressBarConfig.padding, progressBarConfig.ratio, progressBarConfig.padding, v);
						// console.log('时间未到, 播放最新视频， 转为实时播放');

						setPlayStatus((v) => 'live');

						let now_click_date_hours = dayjs(now_real_time).format('YYYY-MM-DD HH')

						// currentDateHoursRef.current = now_click_date_hours;
						// setCurrentDateHours(() => now_click_date_hours);

						// setDateAndHour(now_real_time);
					}else{
						// console.log('有效时间， 回放中...');

						setPlayStatus((v) => 'playback');


						//  切换到进度条的两端位置

						let now_click_date = dayjs(now_click_time).format('YYYY-MM-DD')
						let now_click_date_hours = dayjs(now_click_time).format('YYYY-MM-DD HH')

						// 前一个时间段 或者前一天的位置， 需要切换
						if(mouseEvent.x + 5 < progressBarConfig.padding){

							//   提取前一天的日期时间
							if(mouseEvent.up){
								// console.log('你好，你点击在前一天了', dayjs(now_click_time).format('YYYY-MM-DD'));

								// setCurrentDate(() => now_click_date);
								// // setCurrentDateHours(() => now_click_date_hours);
								// currentDateRef.current = now_click_date;
								// // currentDateHoursRef.current = now_click_date_hours;

								dateTimeResetFlagRef.current = true;

								setDateAndHour(now_click_time);
							}

							setSpritePosition(progressBarConfig.padding - 5);

						}else if(progressBarConfig.available_width + progressBarConfig.padding < mouseEvent.x + 5){


							if(mouseEvent.up) {
								// console.log('这是后一天的位置了哦')

								// setCurrentDate(() => now_click_date);
								// // setCurrentDateHours(() => now_click_date_hours);
								// currentDateRef.current = now_click_date;
								// // currentDateHoursRef.current = now_click_date_hours;
								dateTimeResetFlagRef.current = true;

								setDateAndHour(now_click_time);
							}

							setSpritePosition(progressBarConfig.padding - 5);
						}else{
							// console.log('这是其他位置')
							setSpritePosition(mouseEvent.x);
						}
					}
				}

				if(ms === 10){
					innFun();
				}else{
					clickProgressBarTimeOutRef.current = setTimeout(() => {
						innFun();
					},100)
				}

      }

      window.addEventListener('resize', function() {
        // if(vPlayerRef.current){
        //   vApplicationRef.current.renderer.resize(vPlayerRef.current.clientWidth, c_height);
        // }
				// vApplicationRef.current.renderer.resize(window.innerWidth, c_height);
      })
    }

    return () => {
      window.removeEventListener('resize', null);
      clearTimeout(clickProgressBarTimeOutRef.current);
    }
  }, [props.width])


  // 视频进度刻度条
  useEffect(() => {
    if(progressBarConfigRef.current){
      if(vScaleContainerRef.current){
        vScaleContainerRef.current.removeChildren();
      }

      const progressBarConfig = progressBarConfigRef.current;
      // 创建刻度
      const scale = new PIXI.Graphics();
      const scaleContainer = vScaleContainerRef.current //new PIXI.Container();
      let count = 24;
      let available_width = progressBarConfig.available_width;
      let c_padding = progressBarConfig.padding;

      let ratio = 1;  // 时间和像素的比例
      let timeLen = 0; // 时间的长度

      if(barType === 'HR'){
        count = 24;
        timeLen = count * 60 * 60;
        ratio = available_width / (count * 60 * 60);
      }else if(barType === 'MIN'){
        count = 12; // 一小时每分钟一个刻度
        timeLen = 1 * 60 * 60;
        ratio = available_width / (1 * 60 * 60);
      }

      progressBarConfig.ratio = ratio;

      for(let i = 0; i < count; i++){
        let x = c_padding + i * (available_width / count);

        let _diff = available_width / count;

        // 画刻度线 画刻度数字
        drawScale(scale, x, i, barType, count);

        if(i === count - 1){
          i = count;
          x = c_padding + i * (available_width / count);
          drawScale(scale, x, i, barType, count);
        }
      }
      scaleContainer.addChild(scale);
      vAllContainerRef.current.addChild(scaleContainer);

    }
  }, [barType, props.width])

  useEffect(() => {
    if(type){
      setBarType(() => type);
      barTypeRef.current = type;


    }
  }, [type])

  // 绘画时间刻度
  function drawScale(_scale, _x, _text, _type, _count){
    const progressBarConfig = progressBarConfigRef.current;
    let available_width = progressBarConfig.available_width;
    let c_padding = progressBarConfig.padding;
    let o_text = _text;

    const track = _scale;
    track.lineStyle(0.5, 0x000000);
    track.moveTo(_x, 45);
    track.lineTo(_x, 60);
    track.closePath();

    if(_type === 'HR'){

      for(let i = 0; i < 4; i++){
        let _diff = (available_width / _count) / 4;

        if(i !== 0 && _text !== 24){
          track.lineStyle(0.25, 0x000000);
          track.moveTo(_x + (_diff * i), 50);
          track.lineTo(_x + (_diff * i), 60);
          track.closePath();
        }
      }

      if(_text === 24){
        _text = 0
      }

      if(_text < 12){
        _text = `${_text}AM`;
      }else{
        _text = `${_text}PM`;
      }

    }else if(_type === 'MIN'){

      for(let i = 0; i < 5; i++){
        let _diff = (available_width / _count) / 5;

        if(i !== 0 && _text !== _count){
          track.lineStyle(0.25, 0x000000);
          track.moveTo(_x + (_diff * i), 50);
          track.lineTo(_x + (_diff * i), 60);
          track.closePath();
        }
      }

      _text = _text * 5;
    }

    let _space = available_width / _count;
    let _fontSize = 12;
    if(_space < 35 && _type === 'HR'){
      _fontSize = 10;
    }

    let text = new PIXI.Text(_text, {fontFamily : 'Arial', fontSize: _fontSize, fill : 0x000000, align : 'center'});
    text.x = _x - 10;
    if(_x > 10){
      text.x = _x - 6;
    }
    text.y = 60;


    if(_space < 35 && _type === 'HR'){

      if(o_text % 2 === 0){
        let textContainer = new PIXI.Container();
        textContainer.addChild(text);
        vScaleContainerRef.current.addChild(textContainer);
      }

    }else{
      let textContainer = new PIXI.Container();
      textContainer.addChild(text);
      vScaleContainerRef.current.addChild(textContainer);
    }
  }

  // 指针位置
  const setSpritePosition = (x) => {
    const progressBarConfig = progressBarConfigRef.current;
    let sprite = progressBarConfig.sprite;
    let rulerContainer = progressBarConfig.rulerContainer;
    let allContainer = vAllContainerRef.current;
		// console.log(mouseEventOperatingRef.current, '鼠标事件执行中...', x);

    sprite.x = x;
    sprite.y = 0;
    sprite.tabIndex = 99999;
    rulerContainer.setChildIndex = 1000;
    rulerContainer.addChild(sprite);

    allContainer.addChild(rulerContainer);
    allContainer.setChildIndex(rulerContainer, allContainer.children.length - 1);
  }

  const checkIotekRecord = async () => {
    // let res:any = await checkIotekDevCVRRecord(physical_id);
    let res:any = await checkDevCVRRecord(physical_id);
    const {data, result} = res//setRecordList
    let _arr = []
    if(result === 'ok' && Array.isArray(data)){
      _arr = data;
    }

    setCvrRecordDateArray(() => _arr)
    cvrRecordDateArrayRef.current = _arr;

    if(setRecordDate){
			setRecordDate(_arr);
		}
  }

  const getIotekRecordList = async () => {

    await checkIotekRecord();

    let req = {
      physical_id: physical_id,
      channel_id: props.channel,
      record_cloud: 1, // 1 | 0
      // record_date: '2011-12-03_2011-12-04'
    }
    // let res:any = await getIotekDevCVRRecordList(req);
    let res:any = await getDevCVRRecordList(req);
    const {data, result} = res//setRecordList

    if(result === 'ok'){
      setRecordInfo((v) => res);

      let { current_time, offset_seconds } = res;
      if(current_time && offset_seconds){
        let _date_time = dayjs(new Date(current_time).getTime() + (offset_seconds * 1000)).format('YYYY-MM-DD');
        let _date_time_hours = dayjs(new Date(current_time).getTime() + (offset_seconds * 1000)).format('YYYY-MM-DD HH');

        // setCurrentDate(() => _date_time);
        // setCurrentDateHours(() => _date_time_hours);
        // currentDateHoursRef.current = _date_time_hours;
        // // currentDateHoursIIRef.current = _date_time_hours;

				setDateAndHour(current_time);

        devOffsetTimeRef.current = offset_seconds * 1000;

        if(playbackTime === 0){
					setPlayStatus((v) => 'live');
				}
      }

      if(Array.isArray(data)){
        let _data = data;
        _data.map((o:any) => {
          o.record_type = o.type;
          o.start_time = timeFmt(o.start, offset_seconds);
          o.end_time = timeFmt(o.end, offset_seconds);
          return o;
        })

        setRecordList(() => _data);
      }
    }
  }

  const timeFmt = (tm, offset_seconds, tag='-') => {
    let date = new Date(tm * 1000);
    let yr = date.getUTCFullYear(),
      mth:any = date.getUTCMonth(),  // +1
      day:any = date.getUTCDate();

    let hour:any = date.getUTCHours(),
      minute:any = date.getUTCMinutes(),
      second:any = date.getUTCSeconds();

    mth = mth + 1;
    mth = mth < 10 ? `0${mth}` : mth;
    day = day < 10 ? `0${day}` : day;

    hour = hour < 10 ? `0${hour}` : hour;
    minute = minute < 10 ? `0${minute}` : minute;
    second = second < 10 ? `0${second}` : second;

    return `${yr}${tag}${mth}${tag}${day} ${hour}:${minute}:${second}`;
  }

  const setDateAndHour = (ms) => {
  	let _date = dayjs(ms).format('YYYY-MM-DD');
  	let _date_hour = dayjs(ms).format('YYYY-MM-DD HH');


		setCurrentDate(() => _date);
		setCurrentDateHours(() => _date_hour);
		currentDateRef.current = _date;
		currentDateHoursRef.current = _date_hour;
		setCurrentDateToPlayer(() => _date);
	}


  // 当前播放的时间
  const currentPlayTime = (v) => {
    // console.log(`--------current-PlayTime--------1------`)
    let ct:any = dayjs().format('YYYY-MM-DD 00:00:00');

    if(barTypeRef.current === 'HR'){
      if(currentDateRef.current){
        ct = dayjs(currentDateRef.current).format('YYYY-MM-DD 00:00:00');
      }
    }else{
      if(currentDateHoursRef.current){
        ct = dayjs(currentDateHoursRef.current).format('YYYY-MM-DD HH:00:00');
      }
    }

    let dv = dayjs(ct).valueOf();

    if(v){
      ct = dayjs(dv + v).format('YYYY-MM-DD HH:mm:ss');

      let _t = dayjs(dv + v).format('YYYY-MM-DD HH');
      // currentDateHoursRef.current = _t;
      // setCurrentDateHours(() => _t);

			setDateAndHour(dv + v)
    }

    playBackFunc(ct);
  }

  useEffect(() => {
    if(physical_id){
      // getRecordList();
      getIotekRecordList()
    }
  }, [physical_id, props.channel])


  // 画告警记录
  useEffect(() => {
    clearTimeout(loadTimeCountRef.current)
    currentDateRef.current = currentDate;

    loadTimeCountRef.current = setTimeout(() => {

      if(recordList.length && currentDate){
        if(vRecordContainerRef.current){
          vRecordContainerRef.current.removeChildren();
        }

        if(Object.prototype.toString.call(setCurrentDateToPlayer) === '[object Function]'){
          setCurrentDateToPlayer(currentDate)
        }

        let c_yellow = progressBar.barColors.yellow;
        let c_red = progressBar.barColors.red;
        let c_blue = progressBar.barColors.blue;
        let c_gray = progressBar.barColors.gray;
        let deviceOffset = recordInfo.offset_seconds * 1000;
        let current_time = recordInfo.current_time;
        let one = progressBar.onemsWidth;
        let progressBarConfig = progressBarConfigRef.current;
        let ratio = progressBarConfig.ratio;
        let height = progressBarConfig.height;

        // let device_local_time = new Date((current_time).replace(/-/g, '/')).getTime() + deviceOffset; // 设备的当天时间
        let current_start_time = new Date((`${currentDate} 00:00:00`).replace(/-/g, '/')).getTime() //当天开始的时间戳
        if(barType === 'MIN'){
          current_start_time = new Date((`${currentDateHoursRef.current}:00:00`).replace(/-/g, '/')).getTime()
        }

        let local_offset_time = new Date().getTimezoneOffset() * 60 * 1000;

        // console.log(currentDateHoursRef.current, currentDateHours, '-----------');
				// console.log(currentDateRef.current, currentDate, '***********');

        for(let item of recordList){
          let color, x, width, data;
          switch (item.record_type) {
            case 2:
              color = c_yellow;
              break;
            case 1:
              color = c_red;
              break;
            case 0:
              color = c_blue;
              break;
            default:
              color = c_gray;
              break;
          }

          let {start_time, end_time, start, end, record_type} = item;
          width = (end - start) * ratio;

          let local_start_time = dayjs(start *1000 + local_offset_time + deviceOffset).format('YYYY-MM-DD HH:mm:ss');
          let local_end_time = dayjs(end *1000 + local_offset_time + deviceOffset).format('YYYY-MM-DD HH:mm:ss');

          // let ps_start  = ((start *1000 - current_start_time) * ratio)/1000;
          let ps_start  = ((start *1000  + local_offset_time + deviceOffset - current_start_time) * ratio)/1000;
          x = progressBarConfig.padding + ps_start;

          let e_currentDate = barType === 'MIN' ? currentDateHoursRef.current : currentDate;

          // 提取当天的时间记录
          if(
          		(
          			local_start_time.indexOf(e_currentDate)>-1 ||
								local_end_time.indexOf(e_currentDate)>-1 ||
								(local_start_time<e_currentDate && local_end_time>e_currentDate)
							) && true
					){

          //  提取当天记录的开始时间

          //  提取当天记录的结束时间

            if(barType === 'MIN'){
              let _start = start;
              // 如果记录的开始时间 小于 当前小时的开始
              if(local_start_time < `${e_currentDate}:00:00`){
                _start = dayjs(`${e_currentDate}:00:00`).unix() - Math.floor((local_offset_time + deviceOffset) / 1000);
                x = 0;
              }
              let _end = end;
              width = progressBarConfig.padding *2 + (_end - _start) * ratio;
              if(local_end_time > `${e_currentDate}:59:59`){
                _end = dayjs(`${e_currentDate}:59:59`).unix() - Math.floor((local_offset_time + deviceOffset) / 1000);
                width = progressBarConfig.padding *2 + (_end - _start) * ratio;
              }
            }
            data = item;
            data.x = x;
            data.width = width;
            data.color = color;

            let recordItem = new PIXI.Graphics();
            recordItem.beginFill(color);
            recordItem.record_data = data;
            recordItem.interactive = true;
            recordItem.buttonMode = true;
            recordItem.drawRect(x, 20, width, 25)
            recordItem.endFill();
            vRecordContainerRef.current.addChild(recordItem);
          }
        }

        if(recordList.length){
          //  再追加，最后一条新记录， 用于实时显示

          // 记录的当前时间 和 本地的当前时间
          let dt = (new Date().getTime()) + deviceOffset + local_offset_time;
          let local_current_date = dayjs(dayjs(dt).format('YYYY-MM-DD 00:00:00')).format('YYYY-MM-DD');
          let local_current_date_hours = dayjs(dt).format('YYYY-MM-DD HH');

          if(barType === 'MIN'){
            if(currentDateHours === local_current_date_hours){
              realPlayTimeFunc();
            }
          }else{
            if(currentDate === local_current_date){
              realPlayTimeFunc();
            }
          }
        }
      }
    },500)

  },[recordList, currentDate, currentDateHours, barType, runnerTime]) //runnerTime

  //实时播放时间
  const realPlayTimeFunc = () => {
    let lastRecord = _.cloneDeep(recordList[recordList.length -1]);

    let c_blue = progressBar.barColors.blue;
    let deviceOffset = recordInfo.offset_seconds * 1000;
    let progressBarConfig = progressBarConfigRef.current;
    let ratio = progressBarConfig.ratio;

    let current_start_time = new Date((`${currentDate} 00:00:00`).replace(/-/g, '/')).getTime() //当天开始的时间戳
    if(barType === 'MIN'){
      current_start_time = new Date((`${currentDateHours}:00:00`).replace(/-/g, '/')).getTime() - 1000
    }

    lastRecord.start_time = lastRecord.end_time;
    lastRecord.start = lastRecord.end;
    lastRecord.end = runnerTime;
    lastRecord.end_time = dayjs((runnerTime*1000 + local_offset_time)).format('YYYY-MM-DD HH:mm:ss');

    let {start_time, end_time, start, end} = lastRecord;

    let ps_start  = ((start *1000  + local_offset_time + deviceOffset - current_start_time) * ratio)/1000;

    let color=c_blue, x, width, data;

    x = progressBarConfig.padding + ps_start;
    width = (end - start) * ratio;

    data = lastRecord;
    data.x = x;
    data.width = width;
    data.color = color;

    let recordItem = new PIXI.Graphics();
    recordItem.beginFill(color);
    recordItem.record_data = data;
    recordItem.interactive = true;
    recordItem.buttonMode = true;
    recordItem.drawRect(x, 20, width, 25)
    recordItem.endFill();
    vRecordContainerRef.current.addChild(recordItem);
  }

  // 处理实时指针的位置
  useEffect(() => {

    // 播放中时 处理
    if(playStatus === 'live'){
      let _ps = dateTime2PX(runnerTime);

      if(videoFPS){
				setSpritePosition(_ps);

        setCurrentRealTime(() => runnerTime);

				let _dt = dateTimeLocal2DevDateTime(runnerTime);
				setDateAndHour(_dt.ms)
      }

    }else if(playStatus === 'playback'){

      if(videoFPS){
        playbackStartDateTimeRef.current = playbackStartDateTimeRef.current + 1;

        let _ps = dateTime2PX(playbackStartDateTimeRef.current);

        if(!mouseEventOperatingRef.current){
					setSpritePosition(_ps);

					let _dt = dateTimeLocal2DevDateTime(playbackStartDateTimeRef.current);
					setDateAndHour(_dt.ms);
				}

        setCurrentRealTime(() => playbackStartDateTimeRef.current);
      }
    }

  }, [runnerTime, playStatus])


	useEffect(() => {
		if(playStatus === 'live'){
			setPlaybackTime(0);

			// let _date = dayjs((runnerTime*1000 + local_offset_time)).format('YYYY-MM-DD');
			// let _date_hour = dayjs((runnerTime*1000 + local_offset_time)).format('YYYY-MM-DD HH');
			//
			// setCurrentDate(() => _date);
			// console.log(_date);

			setDateAndHour(runnerTime*1000 + local_offset_time);
		}
	}, [playStatus])


  // 把现在播放的时间传给播放器
  useEffect(() => {
    if(playerGetCurrentPlayingTime){
      playerGetCurrentPlayingTime(currentRealTime)
    }
  }, [currentRealTime])


  // 当天时间变化  切换记录条  并回放
  useEffect(() => {

    let progressBarConfig:any = progressBarConfigRef.current;
    if(currentDateX && progressBarConfig){
      setPlayStatus((v) => 'playback');
      setCurrentDate(() => currentDateX);

      setSpritePosition(progressBarConfig.padding - 5);
      let _dt = `${currentDateX} 00:00:01`;
			setDateAndHour(_dt);

      playBackFunc(_dt);
    }
  }, [currentDateX])

  // 当天时间变化  切换记录条
  useEffect(() => {

  }, [currentDateHours])

	//播放器传的回放时间
	useEffect(() => {
		// console.log(`播放器的回放时间： ${playbackTime}`)
		if(playbackTime){
			playbackStartDateTimeRef.current = Number(playbackTime);
			let stx = dateTime2PX(playbackTime, 1);

			// setSpritePosition(stx);
			setCurrentRealTime(() => playbackStartDateTimeRef.current);

			setPlayStatus(() => 'playback');

		// 记录时间变化
			let ums = dateTimeLocal2DevDateTime(playbackTime,1);
			let _t = dayjs(ums.ms).format('YYYY-MM-DD HH');
			currentDateHoursRef.current = _t;
			setCurrentDateHours(() => _t);

		}else{
			// if(videoFPS) {
				setPlayStatus(() => 'live');
			// }
		}
	}, [playbackTime])

  // 时间驱动
  useEffect(() => {
    clearInterval(runnerTimeIntimevalRef.current)
    runnerTimeIntimevalRef.current = setInterval(() => {
      let _tm = Math.floor(new Date().getTime() / 1000);
      setRunnerTime((v) => _tm)
      runnerTimeRef.current = _tm;
    }, 1000);

    window['realPlayTimeFunc'] = realPlayTimeFunc

    return () => {
      clearInterval(runnerTimeIntimevalRef.current);
    }
  }, [])

  // 日期时间转换
  const dateTimeLocal2DevDateTime = (aTimestamp:number, fl=0) => {
    let _aTimestamp = aTimestamp;
    let len = String(aTimestamp).length;
    if(len === 10){
      _aTimestamp = aTimestamp * 1000;
    }

    if(fl === 0){
			_aTimestamp = _aTimestamp + local_offset_time + (recordInfo.offset_seconds * 1000);
		}

    let ret = {
      unix: Math.floor(_aTimestamp / 1000),
      ms: _aTimestamp
    }
    return ret;
  }

  // 时间转成长度 位置
  const dateTime2PX = (v,f=0) => {
    let progressBarConfig = progressBarConfigRef.current;
    let ratio = progressBarConfig.ratio;
    let dt = dateTimeLocal2DevDateTime(v,f);
    let stDate = dayjs(dt.ms).format('YYYY-MM-DD 00:00:00');

    if(barType === 'MIN'){
      stDate = dayjs(dt.ms).format('YYYY-MM-DD HH:00:00');
    }

    let stDTime = dayjs(stDate).unix();
    let diff = dt.unix - stDTime

    let ps = progressBarConfig.padding + diff*ratio - 5;

    return ps;
  }

  // 回放时执行
  const playBackFunc = (v) => {

    if(isNaN(Number(v))){
      v = new Date(v).getTime();
    }

    if(v.toString().length === 13){
      v = Math.floor(v/1000);
    }

    v = v - Math.floor(local_offset_time/1000) - Math.floor(devOffsetTimeRef.current/1000);

    if(Object.prototype.toString.call(setPlaybackTime) === '[object Function]'){
			setPlaybackTime(v) // 传给上级组件

      playbackStartDateTimeRef.current = v;
    }

    // console.log(`>>>>>>>回放时间： ${v} ${dayjs(v*1000).format('YYYY-MM-DD HH:mm:ss')} >>>>>>>>>>>`)
  }


  return (<div className={'e-ii-vplayer-container'} ref={vPlayerRef}></div>)
}
