/* eslint-disable */
import {getLGToken} from "../common";

function getToken(){
	// let searchParams = new URLSearchParams(location.search);
	// // let _token = searchParams.get('token')
	// let _token = localStorage.getItem('token');

	// // let _tokenii = sessionStorage.getItem('my-cloud-disk-token');
	// let _tokenii = localStorage.getItem('token');
	// if(_tokenii !== null){
	// 	_token = _tokenii;
  	// }

	// return _token
}

// const token = getToken() ? getToken() : '';
const token = getLGToken();


export default token;
