import {Ellipsis, Input} from "antd-mobile";
import {useEffect, useRef, useState} from "react";
import {getFolderContent} from "../../../../../server/folder";
import {isJSON} from "../../../../../utils/common";
import dayjs from "dayjs";

export default function InnerSearch(props){
	const {closeSearchPanel, openFolder} = props;
	const [folderList, setFolderList] = useState<any>([]);
	const [searchList, setSearchList] = useState<any>([]); // 搜索列表
	const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键字
	const [pageInfo, setPageInfo] = useState<any>({
		page:0,
		pageSize: 10 * 1,
		orderBy: 'name', //name, updateTime, size
		order: 'Asc', //Asc:顺序 Desc:逆序
	})


	const searchTimeOutRef:any =  useRef(0);

	// 修改搜索值
	const changeSearchValue = (v) => {
		setSearchValue(() => v)
	}

	// 搜索
	const searchFunc = async () => {
		let req = {
			pageSize: 99,
			orderBy: 'name', //name, updateTime, size
			order: 'Asc', //Asc:顺序 Desc:逆序
			// dirId: 0,
			key: searchValue,
			page: 0,
		}

		let res:any = await getFolderContent(req);
		let {data, result}:any = res;
		if(result === 'ok' && data){
			let files:any = data['files']; //文件
			let dirs:any = data['dirs']; // 文件夹
			dirs = Array.isArray(dirs) ? dirs : [];
			files = isJSON(files) ? files['content'] : [];
			files = Array.isArray(files) ? files : [];

			dirs.map((item:any) => {
				let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified';
				let _dt = dayjs(item.update_at).format('MMM D, YYYY');
				item.icon = '/res/icons/icon_folder.svg';
				item.content = `${_lb} ${_dt}`;
				return item;
			})

			setFolderList(() => dirs);
		}
	}

	useEffect(() => {
		clearTimeout(searchTimeOutRef.current);
		searchTimeOutRef.current = setTimeout(() => {
			if(searchValue){
				searchFunc();
			}else{
				setFolderList(() => []);
			}

		}, 200)

		return () => {
			clearTimeout(searchTimeOutRef.current);
			setFolderList(() => []);
		}
	}, [searchValue])

	return <>
		<div className={'search-panel-box'}>
			<div className={'-inpage'}>
				<div className={'-head-search-box'}>
					<div className={'-icon'} onClick={closeSearchPanel}>
						<img src="/res/icons/icon_close.svg" alt=""/>
					</div>
					<div className={'-input'}>
						<Input placeholder={'Search for folder'} onChange={changeSearchValue} clearable={true} />
						<span className={'-search-icon'}><img src="/res/icons/icon_search.svg" alt=""/></span>
					</div>
				</div>
				<div className={'-body-search-box'}>
					<div className={'md-content'}>
						{
							folderList.map((item: any) => {
								return (
									<div
										className="content-item"
										key={item.id}
										onClick={() => openFolder(item)}
									>
										<div className="content-item-type">
											<img src={item.icon} alt=""/>
											<div className='content-item-txt'>
												<h4><Ellipsis direction='end' content={item.name}/></h4>
												<div style={{display: 'flex', alignItems: 'center'}}>
													{
														item?.collect ? <img src="/res/icons/icon_favourite_on.svg" alt="" style={{
															width: "12px",
															marginRight: '7px',
															verticalAlign: "middle"
														}}/> : null
													}
													<Ellipsis direction='end' content={item.content}/>
												</div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>

	</>
}
