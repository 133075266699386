import './index.less'
import DeviceAlertApi from "../../../../server/deviceAlert";
import {useEffect, useRef, useState} from "react";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Button, DatePicker, Image, Modal, Space, Spin} from "antd";
import dayjs from "dayjs";
import pfFileUrl from "../../../../server/PFFileUrl";
import DeviceCenterApi from "../../../../server/device";

import MenuPopup from "../../../../components/menuModal";
import VideoPlayer from "../../../../components/videoPlayer";
import {getLGToken} from "../../../../utils/common";
import {useNavigate} from "react-router-dom";

const deviceAlertApi = new DeviceAlertApi();
const deviceCenterApi = new DeviceCenterApi();

export default function DeviceCenterModuleAlerts(){
	const [alertList, setAlertList] = useState<any>([]);
	const [alertListII, setAlertListII] = useState<any>([]);
	const [currentDate, setCurrentDate] = useState<any>('');
	const [deviceList, setDeviceList] = useState<any>([]);
	const [currentDeviceID, setCurrentDeviceID] = useState<string>('');
	const [reloadData, setReloadData] = useState<boolean>(false);
	const [requestStatus, setRequestStatus] = useState<number>(0);
	const [editItems, setEditItems] = useState<boolean>(false);
	const [selectList, setSelectList] = useState<any>([]);

	const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);
	const [videoData, setVideoData] = useState<any>({});

	const [targetElement, setTargetElement] = useState(null);

	const reqTimeOutRef = useRef<any>(0);

	const navigate = useNavigate();

	useEffect(() => {
		getDevList();

		let _today = dayjs().format('YYYY-MM-DD');
		setCurrentDate(() => _today);

		return () => {
			clearTimeout(reqTimeOutRef.current)
		}
	}, [])

	useEffect(() => {
		if(currentDate){
			let req = {
				physical_id: currentDeviceID,
				max_time: dayjs(`${currentDate} 23:59:59`).unix() + dayjs().utcOffset() * 60,
				min_time: dayjs(`${currentDate} 00:00:00`).unix() + dayjs().utcOffset() * 60
			}

			clearTimeout(reqTimeOutRef.current);

			reqTimeOutRef.current = setTimeout(() => {
				getAlertList(req);
			},350)
		}

	}, [currentDate, currentDeviceID])

	useEffect(() => {
		if(!editItems){
			setSelectList(() => [])
		}
	}, [editItems])

	useEffect(() => {
		if(!openVideoModal){
			setVideoData(() => {})
		}
	}, [openVideoModal])

	const getAlertList = (req={}) => {
		setReloadData(() => true);
		setRequestStatus(() => -1);
		deviceAlertApi.getAlertList(req).then((res) => {
			setAlertList(() => res);

			setReloadData(() => false);
			setRequestStatus(() => 1);
		})
	}

	const getDevList = () => {
		setReloadData(() => true);
		deviceCenterApi.getDeviceList().then((res) => {
			setDeviceList(() => res)
		})
	}

	useEffect(() => {
		let dev_obj = {};
		for(let o of deviceList){
			dev_obj[o.physical_id] = o.time_zone_offset;
		}

		let local_off_time = new Date().getTimezoneOffset() * 60; // 本地时间
		let alert_arr = [];
		for(let item of alertList){
			let dev_off_time = dev_obj[item.from_id];
			item.show_time = Number(item.alarm_time) + local_off_time + dev_off_time;
			alert_arr.push(item);
		}

		setAlertListII(() => alert_arr);

	}, [deviceList, alertList])

	const changeDateFun = (e) => {
		let _day = e.format('YYYY-MM-DD');
		setCurrentDate(() => _day);
	}

	const changeDateByButtonFun = (f) => {
		let _day = dayjs(currentDate).add(f, 'day').format('YYYY-MM-DD');
		setCurrentDate(() => _day);
	}

	const changeDeviceFun = (o) => {
		let _id = o?.physical_id ? o?.physical_id : '';
		// console.log(_id)
		setCurrentDeviceID(() => _id);
	}

	const clickCurrentItem = (o) => {
		if(editItems){
			let _selectList = JSON.parse(JSON.stringify(selectList));
			if(_selectList.includes(o.id)){
				_selectList = _selectList.filter((v) => v !== o.id);
			}else{
				_selectList.push(o.id);
			}
			setSelectList(() => _selectList);
		}else{
			setOpenVideoModal(() => true);

			let _data = o;
			if(Number(o.cloud_playback) === 1){
				let _dev:any = {};
				for(let item of deviceList){
					if(item.physical_id === o.from_id){
						_dev = JSON.parse(JSON.stringify(item));
					}
				}

				let aes_key = _dev.aes_key === undefined ? `` : `&aes_key=${_dev.aes_key}`
				let has_audio = _dev.support_audio === undefined ? `` : `&has_audio=${_dev.support_audio}`
				let aes_key_has_audio = aes_key !== '' && has_audio !== '' ? `${aes_key}${has_audio}` : '';
				let randomNumber = new Date().getTime()

				let token = getLGToken();
				let _host = 'flv.meshare.com';
				if(
					(window.location?.hostname).includes('localhost') ||
					(window.location?.hostname).includes('meshare.cn') ||
					(window.location?.hostname).includes('sz-user.smartzliving.ai')
				){
					// _host = 'flv.meshare.cn';
				}
				//wss://flv.meshare.com/live-ws  https://flv.meshare.com/live
				let url = `https://${_host}/playback?devid=${_dev.physical_id}&start_time=${_data.alarm_time}&token=${token}&media_type=${1}&channel=${0}&rn=${randomNumber}${aes_key_has_audio}`;
				_data.playback_url = url;

				// 云设备跳转播放

				navigate(`/device-center/player/${_dev.physical_id}?play_back=1&start_time=${_data.alarm_time}`)
				return;
			}

			setVideoData(() => _data);
			readAlertFun(o);

			// console.log(JSON.stringify(o,null, 2));
		}
	}

	const removeAlertItemsFun = () => {
		if(selectList.length === 0) return;
		let _ids = selectList.join('-');
		deviceAlertApi.removeAlert(_ids).then((res) => {
			if(res){
				let _alertList = JSON.parse(JSON.stringify(alertList));
				_alertList = _alertList.filter((v) => !selectList.includes(v.id));
				setAlertList(() => _alertList);
				setSelectList(() => []);
			}
		})
	}

	const selectAllFun = () => {
		let _alertList = JSON.parse(JSON.stringify(alertList));
		let _arr = [];

		if(_alertList.length === selectList.length){
			setSelectList(() => _arr);
		}else{
			for(let item of _alertList){
				_arr.push(item.id);
			}
			setSelectList(() => _arr);
		}
	}

	// 读告警
	const readAlertFun = (o) => {
		if(o.id === undefined) return;
		deviceAlertApi.readAlert(o.id).then((res) => {
			if(res){
				let _alertList = JSON.parse(JSON.stringify(alertList));
				_alertList = _alertList.map((item:any) => {
					if(o.id === item.id){
						item.if_read = '1';
					}
					return item;
				});
				setAlertList(() => _alertList);
			}
		});
	}

	return <>
		<div className={'device-center-alerts'}>

			{
				editItems ? <>
					<div className={'--head-box'}>
						<Button shape={'round'} style={{width: '160px'}} onClick={() => setEditItems(false)}>Cancel</Button>
						<Space >
							<Button shape={'round'} style={{width: '160px'}} onClick={selectAllFun}>Select All</Button>
							<Button shape={'round'} style={{width: '160px', color: '#16ADFD'}} onClick={removeAlertItemsFun}>
								{selectList.length === 0 ? 'Delete' : `Delete (${selectList.length})`}
							</Button>
						</Space>
					</div>
				</>
				:
				<>
					<div className={'--head-box'}>
						<div className={'dev-name-items'}>
							<span
								className={`dev-name-item ${currentDeviceID === '' ? 'dev-name-item-active' : ''}`}
								onClick={() => changeDeviceFun({})}
							>All Devices</span>
							{
								deviceList.map((item:any, index: number) => {
									return <span
										className={`dev-name-item ${currentDeviceID === item?.physical_id ? 'dev-name-item-active' : ''}`}
										key={index}
										onClick={() => changeDeviceFun(item)}
									>{item.name}</span>
								})
							}
						</div>
						<div className={'fliter-box'}>
							<div className={'date-selector'}>
								<span className={'change-date-btn-l'} onClick={() => changeDateByButtonFun(-1)}><LeftOutlined style={{color: '#ffffff', fontSize: '16px'}} /></span>
								<div className={'date-input'}>
									<DatePicker
										inputReadOnly
										allowClear={false}
										suffixIcon={null}
										format={'MMMM DD'}
										value={dayjs(currentDate)}
										onChange={changeDateFun}
									/>
								</div>
								<span className={'change-date-btn-r'} onClick={() => changeDateByButtonFun(1)}><RightOutlined style={{color: '#ffffff', fontSize: '16px'}} /></span>
							</div>

							<div className={'edit-btn'} onClick={() => setEditItems(true)}>
								<img src="/res/icons/icon_edit_alert.png" alt=""/>
							</div>
						</div>
					</div>
				</>
			}


			<div className={'--body-box'}>

				{
					reloadData && <div className={'loading-status-box'}>
            <div className={'loading-status-icon'}>
              <Spin size="large" />
            </div>
          </div>
				}



				<div className={'grid-items'}>
					{
						alertListII.map((item:any, index:number) => {

							return <div className={'dev-alert-item'} key={index} onClick={() => clickCurrentItem(item)}>
								<div className={'option-status-box'}>
									{
										editItems ? <>
											<div className={'option-item'}>
												{
													selectList.includes(item.id) ?
														<img src="/res/icons/icon_select.svg" alt=""/>
														:
														<img src="/res/icons/icon_select_un.svg" alt=""/>
												}

											</div>
										</>
										:
										<>
											<div className={'option-item'}>
												<img src={item.if_read === '0' ? "/res/icons/icon_alert_unread.png" : "/res/icons/icon_alert_read.png"} alt=""/>
											</div>
										</>
									}
									<div className={'v-line'}></div>
								</div>
								<div className={'words-image-box'}>
									<div className={'words-box'}>
										<p className={'h1'}>{item.alarm_time ? dayjs(Number(item.show_time) * 1000).format('hh:mm A') : '--'}</p>
										<p className={'w1'}>{item?.moving_object ? item?.moving_object : (Number(item.type) === 0 ? 'Motion Detecion' : '---')}</p>
										<p className={'w1'}>{item.device_name}</p>
										{/*<p className={'w1'}>{dayjs(Number(item.alarm_time) * 1000).format('YYYY/MM/DD hh:mm A')}</p>*/}
									</div>
									<div className={'image-box'}>
										<Image src={pfFileUrl(item.image_url)} preview={false} alt=""/>
									</div>
								</div>
							</div>
						})
					}
				</div>

				{
					requestStatus > 0 && alertList.length === 0 && <div className={'no-data-box'}>
            <img src="/res/icons/icon-no-device.png" alt=""/>
            <p className={'text-18'}>There are no data yet. </p>
          </div>
				}

			</div>
		</div>

		<Modal
			title={`${videoData?.device_name} - ${videoData?.alarm_time ? dayjs(Number(videoData?.alarm_time) * 1000).format('hh:mm A') : '--'}`}
			centered
			open={openVideoModal}
			onOk={() => setOpenVideoModal(false)}
			onCancel={() => setOpenVideoModal(false)}
			footer={false}
			className={'alert-video-modal'}
		>
			<div className={'video-modal-box'} style={{background: '#000'}}>

				{
					videoData?.playback_url ? <>
						<VideoPlayer
							isMain={true}
							url={videoData?.playback_url}
							physical_id={videoData?.from_id}
							width={500}
							height={375}
							scaleMode={1}
						/>
					</>
					:
					<>
						<video
							src={`${pfFileUrl(videoData?.video_url)}&transcoding=1&physical_id=${videoData?.from_id}`}
							poster={pfFileUrl(videoData?.image_url)}
							controls={true}
							style={{maxWidth: '600px', maxHeight: '400px', width: '100%', verticalAlign: 'bottom'}}
						/>
					</>
				}

			</div>
		</Modal>
	</>
}
