// import {Modal, Form, Input} from 'antd-mobile'
import { Button, Modal, Input, message } from 'antd';
import './index.less'
import { useEffect, useState } from "react";

function NameModal(props) {

	const {
		visible,
		onVisible,
		title,
		value,
		error_message,
		onClose,
		onAction,
		closeButtonText,
		actionButtonText,
		fileType,
		reqData,
		init
	} = props
	// const [form] = Form.useForm()

	const [text, setText] = useState(''); // 名称
	const [textExt, setTextExt] = useState(''); //后缀
	const [message, setMessage] = useState('');


	useEffect(() => {
		if (value !== undefined) {
			let mText = ''
			if (fileType) {
				let reg = `\.${fileType}$`
				mText = value.replace(new RegExp(reg), '')
				setTextExt(() => fileType)
			} else {
				mText = value
			}

			if (!visible) {
				setTimeout(() => {
					setText(() => mText)
					setTextExt(() => '')
				}, 200)
			} else {
				setText(() => mText)
			}

		}

		// console.log(value, fileType, visible, textExt, '24/02/23');
	}, [value, fileType, visible])

	useEffect(() => {
		if (error_message !== undefined) {
			setMessage(() => error_message)
		}
	}, [error_message])

	const changeValue = (v) => {
		if (v.target.value) {
			setText(v.target.value)
		} else {
			setText("")
		}
		
	}

	const handleOk = () => {
		let _text = textExt ? `${text}.${textExt}` : text;
	
		if (text.trim() === '') {
			setMessage(() => 'The name cannot be empty.')
		} else {
			onAction(_text)
			setMessage(() => '')
			onClose()
		}
	}

	const handleCancel = () => {
		onClose()

		setTimeout(() => {
			setText(() => '')
			setMessage(() => '')
			setTextExt(() => '')
		}, 350)
	}


	return <Modal open={visible} className='prompt-conter' centered onOk={handleOk} onCancel={handleCancel} footer={null}

	>
		<div className='prompt'>
			<div className='title'>{title}</div>
		</div>
		<div className='prompt-con'>
			<Input value={text} placeholder='Untitled Folder' onChange={changeValue} />
		</div>
		<div className='prompt-footer'>
			<Button onClick={handleCancel}>Cancel</Button>
			<Button onClick={handleOk}>Submit</Button>
		</div>
	</Modal>
}

export default NameModal
