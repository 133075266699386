import service from '../newRequest'
import {getLGToken} from "../../utils/common";
const url = '/vdr'


// 存储信息
export function getStorageInfo() {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    console.log(getLGToken(), 'getLGToken 12')
    return service.get(`${url}/vdr/storage`, null, headers)
}
