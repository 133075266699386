import './index.less';
import { useEffect, useRef, useState } from 'react';
import {
	Popup,
	Space,
	Image,
	Checkbox, Toast
} from 'antd-mobile'
import { Input, message } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import token from "../../../utils/token";
// import usePlatform from "../../../utils/platform";
import { getFolderContent, deleteFileList, getCollect } from "../../../server/folder";
import dayjs from "dayjs";
import jumpPage from "../../../utils/jumpPage";
import fileType from "../../../utils/fileType";
import {getLGToken, isJSON, sizeFormat} from "../../../utils/common";
import NAPI from "../../../utils/NAPI";
// import NameModal from "../../../components/name-modal";
// import HeadNav from "../../../components/headNav";
import getApiUrl from "../../../server/apiUrl";
import { PlayOutline } from 'antd-mobile-icons';
// import ContentItem from "../../../components/content-item";
// import { setSettingConfig } from "../../../server/setting";
import { getUserBasicCloudDisk } from "../../../utils/common";
import { getStorageInformation } from "../../../server/storage";
import DetailsModal from "../../../components/details-modal";
import LoadDataWaitingState from "../../../components/loadDataWaitingState";
import DeleteModal from "../../../components/delete-modal";
import TipsModal from "../../../components/tips-modal";
// import HomeHeadNav from "../../../components/homeHeadNav";
// import HeadNavII from "../../../components/headNavII";
// import StoragePage from '../Storage'
import CleanUpMeDrive from '../cleanUpMeDrive'
import fileTypeIcon from "../../../utils/fileTypeIcon";
import Navigation from "../meDrive/components/navigation"
import _ from 'lodash';

const imageUrl = getApiUrl('cloudDisk');


function PhotoPage() {
	const navigate = useNavigate()
	// const [isShow, setIsShow] = useState<any>(undefined)
	// const [visible, setVisible] = useState<boolean>(false)
	// const [itemNum, setItemNum] = useState<Number>(1)
	// const [isFolder, setIsFolder] = useState<boolean>(false)
	// const [isSortBy, setIsSortBy] = useState<boolean>(false)
	// const [isMeDrive, setIsMeDrive] = useState<boolean>(false)
	// const [isPause, setIsPause] = useState<boolean>(false)
	// const [isUpgradeStorage, setIsUpgradeStorage] = useState<boolean>(false)
	const [openItemDetails, setOpenItemDetails] = useState<boolean>(false)
	const [detailsObj, setDetailsObj] = useState<any>({})
	// const [folderList, setFolderList] = useState<any>([]); //  文件夹列表
	const [fileList, setFileList] = useState<any>([]); //  文件列表
	// const [addFolderName, setAddFolderName] = useState<any>(''); //  新建文件夹的名称
	const [currentListFolderPid, setCurrentListFolderPid] = useState<any>(0); // 当前列表的pid
	// const [activeID, setActiveID] = useState<any>(-1); // 操作更多时的文件夹或者文件ID
	const [pageInfo, setPageInfo] = useState<any>({
		page: 0,
		pageSize: 10 * 5,
		orderBy: 'name', //name, updateTime, size
		order: 'asc', //Asc:顺序 Desc:逆序
	})

	// const [onePageData, setOnePageData] = useState<any>({
	// 	number_of_elements: 0,
	// 	page_number: 0,
	// 	total_elements: 0,
	// 	total_pages: 0,
	// }); // 文件分也请求数据
	// const [loadMoreFlag, setLoadMoreFlag] = useState<boolean>(false);
	// const [sortRequst, setSortRequst] = useState<boolean>(false);
	// const [storeInfo, setStoreInfo] = useState<any>({});
	// const [currentFolderInfo, setCurrentFolderInfo] = useState<any>({ name: '' })
	const [enterLongPressEvent, setEnterLongPressEvent] = useState<boolean>(false);  // 进入长按事件
	const [userBasicCloudDisk] = useState(getUserBasicCloudDisk());
	const [fileSelectMap, setFileSelectMap] = useState<any>({});
	const [morePopupStatus, setMorePopupStatus] = useState(false);
	const [viewObj, setViewObj] = useState({ type: 'All', time: 'Days' });
	const [viewFileList, setViewFileList] = useState<any>({}); //  显示的文件列表
	const [loadingMainData, setLoadingMainData] = useState<number>(0); // 0: 还没请求数据， 1：请求中， 2：请求结束
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})
	const location = useLocation();
	const params = new URLSearchParams(location.search)
	const isManagement = params.get('isManagement')
	const timeOutRef: any = useRef(0);
	const reqPageRef = useRef(-1);
	const currentReqFolderID = useRef(-1);  //当前请求的ID
	const imageOuterRef: any = useRef(false);
	const pressTimeOut: any = useRef(0);
	const collateDataRef: any = useRef(0);
	const [isStorage, setIsStorage] = useState<any>(false);
	const storeInfoRef = useRef<any>({});
	const [isCleanUpMeDrive, setIsCleanUpMeDrive] = useState<boolean>(false)
	const [isAllSelect, setIsAllSelect] = useState<number>(1) // 1 未选择 2 一个和多个选择 3全选择
	const [isAllHide, setIsAllHide] = useState<boolean>(false) // 显示全选按钮
	const [allListId, setAllListId] = useState<any[]>([])
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
	const [currentMouseEnter, setCurrentMouseEnter] = useState<number>(0)
	const [searchVal, setSearchVal] = useState<string>("")
	const [isNavigation, setIsNavigation] = useState<boolean>(true);
	const file_type = params.get('file_type')
	const [isAllNum, setIsAllNum] = useState<number>(1)
	const [currentTime, setCurrentTime] = useState<string>("Days")
	const [isTipsModal, setIsTipsModal] = useState<boolean>(false);
    const [sizeNum, setSizeNum] = useState<any>(0)

	// 根据路由控制管理弹窗
	useEffect(() => {
		if (isManagement === 'true') {
			setIsStorage(true)
		}
	}, [isManagement])


	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	}, [])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if (NAPI.check()) {
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	useEffect(() => {
		let searchParams = new URLSearchParams(location.search);
		let _id: any = searchParams.get('id');

		if (_id === null || _id === undefined) {
			_id = 0;
		}

		setCurrentListFolderPid(() => _id);
		setFileList(() => []);
		setFileSelectMap(() => Object({}));

	}, [location.search])

	useEffect(() => {


		clearTimeout(timeOutRef.current);
		timeOutRef.current = setTimeout(() => {
			initPageData();

			setViewObj({ type: 'All', time: 'Days' })
			setCurrentTime('Days')
		}, 250);

		return () => {
			clearTimeout(timeOutRef.current);
		}
	}, [currentListFolderPid, file_type, searchVal])

	// 数据初始化
	const initPageData = async () => {
		currentReqFolderID.current = currentListFolderPid;
		setLoadingMainData(() => 1)
		await getDiskStorageSize();
		getPageData(currentListFolderPid, 0);
	}

	const getDiskStorageSize = async () => {
		let res: any = await getStorageInformation();
		let { data, result } = res;
		if (result === 'ok' && data) {

			if (isJSON(data)) {
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id,
					backup_id: data.backup_id,
				}

				if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data)

				storeInfoRef.current = data;
			}
		} else if (result === "401") {
			navigate('/login')
		}
	}

	// 获取页面数据
	const getPageData = async (dirId, _page, _flag = 0) => {
		if (currentReqFolderID.current !== dirId) return;
        let req:any = {}
		if (searchVal) {
			req = {
				...pageInfo,
				page: _page,
				// type: '1,3'
				type: file_type,
				key: searchVal
			}
		} else {
			req = {
				...pageInfo,
				page: _page,
				// type: '1,3'
				type: file_type
			}
		}
		

		let res = await getFolderContent(req);
		reqPageRef.current = -1;
		const token = getLGToken()
		let { data, result }: any = res;
		if (result === 'ok' && data) {
			if (isJSON(data)) {
				let _onePageData = {
					number_of_elements: data['files']['number_of_elements'],
					page_number: data['files']['page_number'],
					total_elements: data['files']['total_elements'],
					total_pages: data['files']['total_pages'],
				}

				let files: any = data['files']['content']; //文件

				files.map((item: any) => {
					let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified';
					let _dt = dayjs(item.update_at).format('MMM D, YYYY');

					item.icon = fileType[item.type]['icon'];

					if (item.type === 3 && item.cover && Number(item.status) !== 0) {
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 1 && item.cover && Number(item.status) !== 0) {
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 2 && Number(item.status) !== 0) {
						item.icon = fileTypeIcon[item.ext];
					}
					item.content = pageInfo.orderBy === 'size' ? `Storage used: ${sizeFormat(item.size)}` : `${_lb} ${_dt}`;

					let last_modify_time = Number(item.last_modify_time);
					last_modify_time = Number.isNaN(last_modify_time) ? 0 : last_modify_time * 1000;

					last_modify_time = item.update_at ? item.update_at : item.create_at;

					item.date_days = dayjs(last_modify_time).format('DD/MM, dddd');
					item.date_months = dayjs(last_modify_time).format('MMM YYYY');
					item.date_years = dayjs(last_modify_time).format('YYYY');
					item.isSelect = false
					item.isHide = false


					return item;
				})



				console.log(files, 'files')

				// 第一次加载的时候， 添加文件夹数据， 添加文件数据
				if (data['files']['page_number'] === 0) {

					setFileList(() => files);

					setTimeout(() => {
						setLoadingMainData(() => 2);
					}, 200)
				} else { // 文件数据追加， 处理分页数据
					setFileList((prevList) => prevList.concat(files));
				}

				// 已加载完成
				if (_onePageData.page_number + 1 >= _onePageData.total_pages || _onePageData.total_pages === 0) {

				} else {
					getPageData(dirId, _page + 1)
				}

			}
		}
	}


	const openMorePopup = (v) => {
		setMorePopupStatus(() => v)
	}

	const selectViewItem = (_ky, _val) => {
		let _viewObj = JSON.parse(JSON.stringify(viewObj));
		_viewObj[_ky] = _val;

		setViewObj(() => _viewObj);
		setCurrentTime(_val)
	}


	console.log(viewObj, 'ViewObj')

	// 按压事件
	const pressEvent = (evt) => {
		if (enterLongPressEvent) return;
		let { type } = evt;

		const countTime = () => {
			clearTimeout(pressTimeOut.current);
			pressTimeOut.current = setTimeout(() => {
				setEnterLongPressEvent(() => true);
			}, 900);
		}

		if (type === 'touchstart') {
			countTime();
		} else if (type === 'touchend') {
			clearTimeout(pressTimeOut.current);
		} else if (type === 'touchmove') {
			clearTimeout(pressTimeOut.current);
		}
	}


	// 打开文件
	const openFile = (o) => {
		if (Number(o.status) !== 0) {
			jumpPage(navigate, `/vault/file?id=${o.id}&type=${o.type}&target=2`)
		}
	}

	//点击事件
	const clickItem = (_lb, o) => {

		if (enterLongPressEvent) {
			let _fileSelectMap = JSON.parse(JSON.stringify(fileSelectMap));
			let _fileSelectItemsIDs = Array.isArray(_fileSelectMap[_lb]) ? _fileSelectMap[_lb] : [];
			if (_fileSelectItemsIDs.includes(o.id)) {
				_fileSelectItemsIDs = _fileSelectItemsIDs.filter((item) => {
					return item !== o.id;
				})
			} else {
				_fileSelectItemsIDs.push(o.id);
			}
			_fileSelectMap[_lb] = _fileSelectItemsIDs;
			setFileSelectMap(() => _fileSelectMap);

		} else {
			openFile(o)
		}
	}

	// 改变每行的选择状态
	const changeItemsCheckbox = (_checked, _lb, _items) => {

		let _fileSelectMap = JSON.parse(JSON.stringify(fileSelectMap));

		if (_checked) {
			if (Array.isArray(_items)) {
				let _fileSelectItemsIDs = _items.map((item: any) => {
					return item.id;
				})
				_fileSelectMap[_lb] = _fileSelectItemsIDs;
				setFileSelectMap(() => _fileSelectMap);
			}

		} else {
			_fileSelectMap[_lb] = [];
			setFileSelectMap(() => _fileSelectMap);
		}
	}

	const collateData = (arr) => {
		const compare = (a, b) => {
			var val1 = b.update_at;
			var val2 = a.update_at;
			if (val1 > val2) {
				return -1;
			} else if (val1 < val2) {
				return 1;
			} else {
				return 0;
			}
		}
		// arr = arr.sort(compare);

		let _ls = {};

		let _date = `date_${(viewObj.time).toLowerCase()}`;
		let _type = viewObj.type; // All Starred  Images  Videos
		let _fileSelectMap = JSON.parse(JSON.stringify(fileSelectMap));


		for (let item of arr) {
			let _key = item[_date];
			if (_type === 'All') {
				if (_ls[_key] === undefined) {
					_ls[_key] = [item];

					if (_fileSelectMap[_key] === undefined) {
						_fileSelectMap[_key] = [];
					}

				} else {
					// if(_ls[_key].length < 11) {
					(_ls[_key]).push(item);
					// }
				}
			} else if (_type === 'Starred') {
				if (item.collect) {
					if (_ls[_key] === undefined) {
						_ls[_key] = [item];

						if (_fileSelectMap[_key] === undefined) {
							_fileSelectMap[_key] = [];
						}
					} else {
						(_ls[_key]).push(item);
					}
				}
			} else if (_type === 'Images') {
				if (item.type === 3) {
					if (_ls[_key] === undefined) {
						_ls[_key] = [item];

						if (_fileSelectMap[_key] === undefined) {
							_fileSelectMap[_key] = [];
						}
					} else {
						(_ls[_key]).push(item);
					}
				}
			} else if (_type === 'Videos') {
				if (item.type === 1) {
					if (_ls[_key] === undefined) {
						_ls[_key] = [item];

						if (_fileSelectMap[_key] === undefined) {
							_fileSelectMap[_key] = [];
						}
					} else {
						(_ls[_key]).push(item);
					}
				}
			}
		}

		// setFileSelectMap(() => _fileSelectMap)

		return _ls;
	}

	useEffect(() => {
		clearTimeout(collateDataRef.current);
		collateDataRef.current = setTimeout(() => {
			let _viewFileList = collateData(fileList);
			setViewFileList(() => _viewFileList)
		}, 250)
	}, [fileList, viewObj])

	const downLoadFunc = () => {
		let _arr = [];
		let _fileList = JSON.parse(JSON.stringify(fileList));
		let _checkedListIDS = checkAllFunc(fileSelectMap);

		for (let item of _fileList) {
			if (_checkedListIDS.includes(item.id)) {
				_arr.push(item);
			}
		}

		if (NAPI.check()) {
			NAPI.apptodo('downloadList', _arr)

			setEnterLongPressEvent(() => false);
		}
	}

	const checkLongPressMoreMenu = () => {
		setOpenItemDetails(() => true)
	}

	const selectAllFunc = (_checked) => {
		let _viewFileList = JSON.parse(JSON.stringify(viewFileList));
		let _fileSelectMap = {};
		for (let _lb in _viewFileList) {
			let _items = _viewFileList[_lb];
			if (_checked) {
				_fileSelectMap[_lb] = _items.map((item) => {
					return item.id;
				})
			} else {
				_fileSelectMap[_lb] = [];
			}

		}
		setFileSelectMap(() => _fileSelectMap);
	}


	const reqDataFunc = (v, o, action = '') => {

		if (v && action === 'closeLongPress') {
			setEnterLongPressEvent(() => false);
			return;
		}

		if (v) {
			let _iFolder = [];
			let _iFile = [];
			if (isJSON(o)) {
				if (o.type === undefined) {
					_iFolder.push(o);
				} else {
					_iFile.push(o);
				}

			} else if (Array.isArray(o)) {
				for (let item of o) {
					if (item.type === undefined) {
						_iFolder.push(item);
					} else {
						_iFile.push(item);
					}
				}
			}


			if (action === 'delete') {
				removeItemFromList(_iFile)
			} else if (action === 'rename') {
				updateList(_iFile);
				setDetailsObj(() => o);
			} else if (action === 'collect') {
				updateList(_iFile);
				setDetailsObj(() => o);
			}
		}
	}

	const removeItemFromList = (_iFile) => {

		let _fileList = JSON.parse(JSON.stringify(fileList));

		_iFile = _iFile.map((item: any) => {
			return item.id;
		})

		_fileList = _fileList.filter((item: any) => {
			return (!_iFile.includes(item.id))
		})

		setFileList(() => _fileList);
	}

	const updateList = (_iFile) => {

		let _fileList = JSON.parse(JSON.stringify(fileList));

		_fileList = _fileList.map((item: any) => {
			for (let o of _iFile) {
				if (item.id === o.id) {
					item = o;
				}
			}

			return item;
		})

		setFileList(() => _fileList);
	}

	let _list = viewFileList;

	const getEleHeight = () => {
		let _dom = imageOuterRef.current;

		if (_dom) {
			let _h = 3;
			if (viewObj.time === 'Months') {
				_h = 5;
			} else if (viewObj.time === 'Years') {
				_h = 6;
			}
			return `${(_dom.clientWidth - 120) / _h}px`;
		}
		return 'auto';
	}

	const checkAllFunc = (_obj) => {
		let _ls = [];
		if (isJSON(_obj)) {
			for (let _lb in _obj) {
				let _item = _obj[_lb];
				_ls = _ls.concat(_item)
			}
		}
		return _ls;
	}

	const getCheckedList = (_als, _ids) => {
		let _res = [];
		for (let item of _als) {
			if (_ids.includes(item.id)) {
				_res.push(item);
			}
		}
		return _res;
	}

	let _eleHeight: any = typeof imageOuterRef.current === 'object' ? getEleHeight() : 'auto';
	let _viewFileListAll = checkAllFunc(viewFileList);
	let _checkedListIDS = checkAllFunc(fileSelectMap);
	let _checkedList = getCheckedList(_viewFileListAll, _checkedListIDS);
	let _indeterminate = _checkedListIDS.length > 0 && _checkedListIDS.length < _viewFileListAll.length && _viewFileListAll.length > 0;
	let _checkedAll = _checkedListIDS.length === _viewFileListAll.length;

	let _has_unuse_size: any = Number(cloudDiskInfo?.usable);
	_has_unuse_size = isNaN(_has_unuse_size) ? -1 : _has_unuse_size;
	_has_unuse_size = _has_unuse_size > 0;
	// let _tad

	const endDom = () => {
		return <div onClick={() => openMorePopup(true)}>
			<img src="/res/icons/icon_more.svg" alt="" style={{ transform: "rotate(0deg)", opacity: 1 }} />
		</div>
	}

	const endDomII = () => {
		return <div>
			<Space align={"center"} justify={"center"} direction={"horizontal"} style={{ '--gap': '25px' }}>
				<div className={'check-box-quadrate'}>
					<Checkbox
						onChange={(e) => selectAllFunc(e)}
						indeterminate={_indeterminate}
						checked={_checkedAll}
						block
					></Checkbox>
				</div>

				{
					_checkedList.length !== 0 ?
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px" }} onClick={downLoadFunc} />
						:
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px", opacity: 0.35 }} />
				}
				{
					_checkedList.length === 0 ? <img src="/res/icons/icon_more.svg" alt="" style={{ transform: "rotate(90deg)", opacity: 0.5 }} />
						: <img src="/res/icons/icon_more.svg" alt="" style={{ transform: "rotate(90deg)" }} onClick={checkLongPressMoreMenu} />
				}
			</Space>
		</div>
	}
	// 移入
	const handleMouseEnter = (item) => {
		setCurrentMouseEnter(item.id)
	}
	// 移出
	const handleMouseLeave = () => {
		setCurrentMouseEnter(0)
	}

	const initCollect = async (o) => {
		let params:any = {
			type: 2,
			fid: o.id
		}
		const res:any = await getCollect(params)
		if (res.result === "ok") {
			initPageData()
		  } else {
			message.success(`${res.error}`)
		  }
	   }
	
	   const handleFavourite = (o) => {
		// 判断网盘到期
		 if ((!_has_unuse_size) || Number(cloudDiskInfo.size) === 0) {
			 setIsTipsModal(true)
			 return false
		 }
		initCollect(o)
	}
 


	const createElement = (arr) => {
		let _res: any = [];

		for (let _label of Object.keys(arr)) {
			let _items = arr[_label];
			let newLabel = dayjs(_label, "DD/MM, dddd").valueOf()
			let _checkedItems = fileSelectMap[_label] === undefined ? [] : fileSelectMap[_label];
			console.log(_label, newLabel, '675')
			_res.push(<div
				className={'item-area'}
				key={_label}
			>
				<div className={'item-title'}>
					{/* <span>{dayjs().format('DD/MM, dddd') === _label ? "Today" : _label}</span> */}
					<span>{_label}</span>
					{
						enterLongPressEvent && <Checkbox
							block
							onChange={(e) => changeItemsCheckbox(e, _label, _items)}
							checked={_items.length === _checkedItems.length}
							indeterminate={_items.length > 0 && _items.length > _checkedItems.length && _checkedItems.length > 0}
						/>
					}

				</div>
				<div className={'list-area'}>
					{
						_items.map((item: any, index: number) => {

							return <div
								className={`item-image -${(viewObj.time).toLowerCase()} list-area-tom`}
								// style={{height: _eleHeight}}
								key={index}
								onTouchStart={pressEvent}
								onTouchEnd={pressEvent}
								onTouchMove={pressEvent}
								// onClick={() => clickItem(_label, item)}
								onDoubleClick={() => clickItem(_label, item)}
								onMouseLeave={handleMouseLeave}
								onMouseEnter={() => handleMouseEnter(item)}
							>
								<Image src={item.icon} lazy={true} fit={'cover'} alt="" className='adm-image-tom' />
								{
									(item.id === currentMouseEnter || item.isHide || isAllHide) && <div className="content-select" onClick={() => handleSelect(item)}>
										{item.isSelect ? <img src="/res/icons/icon_select1_active.svg" style={{ width: "18px", height: "18px" }} alt="" /> : <img src="/res/icons/icon_select1.svg" style={{ width: "18px", height: "18px" }} alt="" />}
									</div>
								}

								{
									(enterLongPressEvent) && <span className={'item-checkbox'}>
										<Checkbox
											block
											checked={_checkedItems.includes(item.id)}
										/>
									</span>
								}

								{
									item.collect && <span className={'item-collect'} onClick={() => handleFavourite(item)}>
										<img src="/res/icons/collect_on1.svg" alt="" style={{ width: "24px", marginRight: "8px" }} />
									</span>
								}

								{(item.cover && item.type === 1) && <span className={'video-player-btn'}><PlayOutline fontSize={30} color={'#ffffff'} /></span>}
							</div>
						})
					}
				</div>
			</div>)
		}

		return _res;
	}

   

	const _listElement = createElement(_list);



	const handleDownload = () => {
		let newFileList = _.cloneDeep(fileList)
		let isALL = newFileList.some((item: any) => item.isSelect)
		if (!isALL) {
			message.warning("Folder cannot be downloaded!")
			return false
		} else {
			let Arr: any = []
			newFileList.map((item: any) => {
				if (item.isSelect) {
					let url = `${imageUrl}/disk/download_file?id=${item.id}&name=${item.name}&token=${getLGToken()}`;
					let name = item.name
					Arr.push({
						url: url,
						name: name,
					})
				}
			})


			Arr.forEach(file => {
				let iframe = document.createElement('iframe');
				iframe.style.display = 'none';
				document.body.appendChild(iframe);
				iframe.src = file.url;
				setTimeout(() => {
					document.body.removeChild(iframe);
				}, 5000);
			});

			// getPageData(currentListFolderPid, 0, 0, searchVal);
			setIsAllSelect(1)
		}


	}
	// 单选
	const handleSelect = (it: any) => {
		let newFileList = _.cloneDeep(fileList)
		let allList: any = []
		newFileList.map((item: any) => {
			if (item.id === it.id) {
				item.isSelect = !item.isSelect;

			}
			if (item.isSelect) {
				allList.push(item.id)
			}

			return item
		})
		setAllListId(allList)
		setFileList(newFileList)
		let isALL1 = newFileList.some((item: any) => item.isSelect)
		if (isALL1) {
			setIsAllSelect(2)
			setIsAllHide(true)
		} else {
			setIsAllSelect(1)
			setIsAllHide(false)
		}
		let isALL = newFileList.every((item: any) => item.isSelect)
		if (isALL) {
			setIsAllSelect(3)
		}
	}


	const handleSelectAll = (num: number) => {
		let newFileList = _.cloneDeep(fileList)
		if (num === 1) {
			setIsAllSelect(3)
			newFileList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFileList(newFileList)
			
		} else if (num === 2) {
			setIsAllSelect(3)
			newFileList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFileList(newFileList)
		} else {
			newFileList.map((it: any) => {
				it.isSelect = false;
				return it
			})
			setFileList(newFileList)
			setIsAllSelect(1)
			setIsAllHide(false)
		}
	}


	// 回收站删除
	const handleMoveToTrash = async () => {
		let newFileList = _.cloneDeep(fileList)
		let isALL = newFileList.some((item: any) => item.isSelect)
		if (!isALL) {
			message.warning("Please select files to delete!")
			return false
		} else {
			setIsDeleteModal(true)
		}
	}


	useEffect(()=> {
		let newFileList = _.cloneDeep(fileList)
		let list = [...newFileList]
		let numTotal = list.filter((item: any) => item.isSelect).length	
		setIsAllNum(numTotal)
		if (numTotal >= 1) {
			setIsNavigation(false)
		} else {
			setIsNavigation(true)
		}
		let arr:any = []
		list.map((item: any) => {
			if (item.isSelect) {
				arr.push(item)
			}
			return item
		})
		let sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0);
		setSizeNum(sizeFormat(sum))
	}, [fileList])

	const initDeleteFileList = async () => {
		if (allListId.length >= 1) {
			const res: any = await deleteFileList(allListId)
			if (res.result === "ok") {
				message.success("Delete successful!")
				initPageData()
			}
		}
		setIsAllHide(false)
	}


	const handleSearchInput = async (e) => {
		const val = (e.target.value).trim()
		if (val) {
			setSearchVal(val)
		} else {
			setSearchVal('')

		}
	}
    
	const handleTime = (item: string) => {
		setCurrentTime(item)

	}



	return <div className='photo-page-tom'>
		
	    <div className='controls' style={{ paddingTop: "16px" }}>
		    {
						isNavigation && <Navigation />
					}
			     <div className='controls-item'>
						{
							(isAllHide) && <div className='sort-download' onClick={handleDownload}>
								<img src="/res/icons/icon_save_to_device_v2.svg" />
								<span>Download</span>
							</div>
						}
						{ (isAllHide) && <div className="move-to-line"></div> }
						{
							(isAllHide) && <div className='move-to-trash' onClick={handleMoveToTrash}>
								<img src="/res/icons/icon_trash_v2.svg" />
								<span>Move to trash</span>
							</div>
						}
						{ (isAllHide) && <div className="move-to-line"></div> }
						{(isAllHide) && <div className='move-to-trash move-to-trash-selected'><span>{isAllNum}</span> &nbsp; Selected ({sizeNum})</div>}
						{
						isAllHide && <div className='select-all'>
							{isAllSelect === 1 && <img src="/res/icons/icon_select1.svg" onClick={() => handleSelectAll(1)} style={{ width: "18px", height: "18px" }} alt="" />}
							{isAllSelect === 2 && <img src="/res/icons/icon_select2_active.svg" onClick={() => handleSelectAll(2)} style={{ width: "18px", height: "18px" }} alt="" />}
							{isAllSelect === 3 && <img src="/res/icons/icon_select1_active.svg" onClick={() => handleSelectAll(3)} style={{ width: "18px", height: "18px" }} alt="" />}

						</div>
					}
				  </div>
				  <div className="page-con-item">
					    <div className="page-con-search">
							<Input placeholder="Search" value={searchVal} onChange={(e) => handleSearchInput(e)}/>
							<img src="/res/icons/icon_search.svg" alt="" className="search-img"></img>
						</div>
						<div className="page-con-time">
                             <div className={currentTime === "Days" && 'page-con-day'} onClick={() => selectViewItem('time', 'Days')}>Day</div>
							 <div className={currentTime === "Months" && 'page-con-month'}  onClick={() => selectViewItem('time', 'Months')}>Month</div>
							 <div className={currentTime === "Years" && 'page-con-year'} onClick={() => selectViewItem('time', 'Years')}>Year</div>
						</div>
					</div>
		</div>
		

		<div className={'photo-page'} style={{ top: isAllHide ? "-20px" : "0px" }}>
			<div className={'-body'}>
				<div className={isAllHide ? '-alist -alist-mt12' : '-alist'} ref={imageOuterRef}>
					{
						loadingMainData === 1 && <div style={{ paddingTop: '60px' }}><LoadDataWaitingState /></div>
					}
					{
						loadingMainData === 2 && _listElement
					}
				</div>
			</div>
		</div>
		
		<Popup
			className="photo-show-type-popup"
			visible={morePopupStatus}
			onMaskClick={() => { openMorePopup(false) }}
			onClose={() => { openMorePopup(false) }}
		>
			<div className={'-area'}>
				<p className={'-title'}>Show</p>
				<div className={'-items'}>
					<span className={`${viewObj.type === 'All' ? '-act' : ''}`} onClick={() => selectViewItem('type', 'All')}>All</span>
					<span className={`${viewObj.type === 'Starred' ? '-act' : ''}`} onClick={() => selectViewItem('type', 'Starred')}>Starred</span>
					<span className={`${viewObj.type === 'Images' ? '-act' : ''}`} onClick={() => selectViewItem('type', 'Images')}>Images</span>
					<span className={`${viewObj.type === 'Videos' ? '-act' : ''}`} onClick={() => selectViewItem('type', 'Videos')}>Videos</span>
				</div>
			</div>
			<div className={'row-box'}>
				<p className={'-title'}>Time Frame</p>
				<div className={'-items'}>
					<span className={`${viewObj.time === 'Days' ? '-act' : ''}`} onClick={() => selectViewItem('time', 'Days')}>Days</span>
					<span className={`${viewObj.time === 'Months' ? '-act' : ''}`} onClick={() => selectViewItem('time', 'Months')}>Months</span>
					<span className={`${viewObj.time === 'Years' ? '-act' : ''}`} onClick={() => selectViewItem('time', 'Years')}>Years</span>
				</div>
			</div>
		</Popup>
		<TipsModal visible={isTipsModal} onVisible={setIsTipsModal} diskInfo={cloudDiskInfo} />
		<DetailsModal
			type={"file"}
			visible={openItemDetails}
			onVisible={setOpenItemDetails}
			data={_checkedList}
			reqData={reqDataFunc}
			cloudDiskInfo={cloudDiskInfo}
		/>
		<CleanUpMeDrive open={isCleanUpMeDrive} onIsStorage={setIsStorage} onOpen={setIsCleanUpMeDrive} />
		<DeleteModal visible={isDeleteModal} onVisible={setIsDeleteModal} title="Delete" contentObj="Are you sure you want to delete the selected file?" onAction={initDeleteFileList} />
	</div>
}

export default PhotoPage;
