import HomeHeadNavX from "../homeHeadNavX";
import HeadNavX from "../headNavX";
import HeadNav from "../headNav";

export default function VaultHeadNav(props){

	return <>
		<div className={'vault-head-contain-x'}>
			<HomeHeadNavX
				// storeInfo={setStoreInfoZU}
				// onVisible={setVisibleZU}
				// itemNum={itemNumZU}
				// onItemNum={setAItemNumZU}
				// onIsFolder={setIsFolderZU}
				// onIsStorage={setIsStorageZU}
				// initPageData={initPageDataZU}
				// list={listZU}
				// newFileList={fileListZU}
				// onFileList={setSetAFileListZU}
			></HomeHeadNavX>
			
			{/* <HeadNavX
			></HeadNavX> */}
			{/* <HeadNav endElement={null} /> */}

			{/*<HomeHeadNavX*/}
			{/*	// storeInfo={setStoreInfo}*/}
			{/*	// onVisible={setVisible}*/}
			{/*	// itemNum={itemNum}*/}
			{/*	// onItemNum={setItemNum}*/}
			{/*	// onIsFolder={setIsFolder}*/}
			{/*	// onIsStorage={setIsStorage}*/}
			{/*	// initPageData={initPageData}*/}
			{/*	// list={[...folderList, ...fileList]}*/}
			{/*	// newFileList={fileList}*/}
			{/*	// onFileList={setFileList}*/}
			{/*></HomeHeadNavX>*/}
			{/*<HeadNavX*/}
			{/*	cloudDiskInfo={cloudDiskInfo}*/}
			{/*></HeadNavX>*/}
		</div>
	</>
}
