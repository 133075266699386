import getApiUrl from "../apiUrl";
import requsetData from "../request";
import {isJSON, json2params} from "../../utils/common";

const url = getApiUrl('cloudDisk');

// 获取垃圾筐内容
export function getTrashList(params:any=0){
	if(isJSON(params)){
		return requsetData(`${url}/recycle?${json2params(params)}`, {})
	}else{
		if(params === 0){
			return requsetData(`${url}/recycle`, {})
		}else{
			return requsetData(`${url}/recycle?pageSize=${params}`, {})
		}
	}

}

// 删除文件夹（永久删除）
export function deleteTrashFolder(id){
	return requsetData(`${url}/recycle/folder/${id}`, {}, {method: 'delete', headers: null})
}

// 删除文件（永久删除）
export function deleteTrashFile(id){
	return requsetData(`${url}/recycle/file/${id}`, {}, {method: 'delete', headers: null})
}

// 批量删除文件夹（永久删除）
export function deleteTrashFolderList(ids=[]){
	return requsetData(`${url}/recycle/folders`, ids, {method: 'delete', headers: null})
}

// 批量删除文件（永久删除）
export function deleteTrashFileList(ids=[]){
	return requsetData(`${url}/recycle/files`, ids, {method: 'delete', headers: null})
}

// 清理回收站
export function clearTrash(){
	return requsetData(`${url}/recycle/clear`, {}, {method: 'delete', headers: null})
}

// 恢复文件夹
export function recoverTrashFolder(id){
	return requsetData(`${url}/recycle/recover-folder/${id}`, {}, {method: 'put', headers: null})
}

// 批量恢复文件夹

export function recoverTrashFolders(data){
	return requsetData(`${url}/recycle/recover-folders`, data, {method: 'put', headers: null})
}

// 恢复文件
export function recoverTrashFile(id){
	return requsetData(`${url}/recycle/recover-file/${id}`, {}, {method: 'put', headers: null})
}

// 批量恢复文件

export function recoverTrashFiles(data){
	return requsetData(`${url}/recycle/recover-files`, data, {method: 'put', headers: null})
}