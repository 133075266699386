import { useParams } from 'react-router-dom';
import getApiUrl from "../../server/apiUrl";
import {getPlatfromByUrl} from "../common";

const platformMap = {
	"meshare":     { id: 1,     cid: 0 },
	"zmd":         { id: 2,     cid: 0 },
	"zmodo":       { id: 2,     cid: 0 },
	"funlux":      { id: 3,     cid: 0 },
	"sho":         { id: 5,     cid: 0 },
	"hubbell":     { id: 6,     cid: 0 },
	"ismartalarm": { id: 10,    cid: 100 },
	"mekeep":      { id: 10001, cid: 0 },
	"leftrice":    { id: 16,    cid: 101 },
	"24hcam":      { id: 1510,  cid: 151 },
	"cartu":       { id: 1520,  cid: 152 },
	"smartz":      { id: 21,    cid: 0 },
}

export default function usePlatform(){
	// const params = useParams();
	let ret:any = {};
	let pf = getPlatfromByUrl(); //'meshare'; // 默认为meshare

	// if(params.hasOwnProperty('platform')){
	// 	pf = params['platform']
	// }else{
	// 	let ua = navigator.userAgent;
	// 	// console.log(ua);
	// }

	if(pf){
		ret = platformMap[pf]
	}

	return ret
}




