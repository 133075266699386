/* eslint-disable */

import axios from "axios";
import {getLGToken} from "../../utils/common";

const url_arr = [
	'https://11-mng-mop.meshare.com',
	'https://12-mng-mop.meshare.com',
]

const url_arr_dev = [
	'https://11-mng-mop.meshare.cn',
	// 'https://12-mng-mop.meshare.cn',
]

const alarm_arr = ['https://11-alarm-mop.meshare.com'];
const alarm_arr_dev = ['https://11-alarm-mop.meshare.cn'];

const getUrl = (m='') => {
	let env = process.env.NODE_ENV; // development
	let uArr:any = env === 'development' ? url_arr_dev : url_arr;

	if(m === 'alarm'){
		uArr = env === 'development' ? alarm_arr_dev : alarm_arr;
	}

	return getRandomElementFromArray(uArr);
}

const getRandomElementFromArray = (array) => {
	if (array && array.length > 0) {
		// 生成一个随机索引，范围是数组长度减一
		const randomIndex = Math.floor(Math.random() * array.length);
		// 使用该索引从数组中取出元素并返回
		return array[randomIndex];
	} else {
		return null; // 如果数组为空或未定义，则返回null或其他默认值
	}
}

function deviceConv(devices, tokenid) {
	let devs = [];
	let base = `https://11-alarm-mop.meshare.com/storage/get_file?token=${ tokenid }&url=`;

	devices = reSort(devices);

	for (let dev of devices) {
		if(-1 == ['0', '1'].indexOf(dev.is_owner) || ('1' != dev.is_owner && '0' == dev.share_on)) {
			continue; // 忽略非属主设备或属主关闭分享的设备
		}
		let passive = [];
		if(~~dev.device_channel > 1) {
			let pdevs = {};
			if(dev.passive_device instanceof Array && dev.passive_device.length) {
				for(let pdev of dev.passive_device) {
					let online = 0, name = 'CH' + pdev.channel_id, camera_online = 0;
					for(let d of devices) {
						if(d.physical_id == pdev.physical_id) {
							online = ~~d.device_online;
							name   = d.device_name;
							camera_online = ~~d.dev_online;
							break;
						}
					}
					pdevs[~~pdev.channel_id] = {
						physical_id: pdev.physical_id,
						name       : name,
						type       : ~~pdev.device_type,
						channel    : ~~pdev.channel_id,
						online     : online,
						camera_online: camera_online,
					};
				}
			}
			for(let i = 0; i < ~~dev.device_channel; ++ i) {
				passive.push({
					physical_id: dev.physical_id,
					name       : pdevs.hasOwnProperty(i) ? pdevs[i].name : 'CH'+ (i + 1),
					type       : pdevs.hasOwnProperty(i) ? pdevs[i].type : ~~dev.device_type,
					channel    : i,
					online     : pdevs.hasOwnProperty(i) ? pdevs[i].online : ~~dev.device_online,
					camera_online: pdevs.hasOwnProperty(i) ? pdevs[i].camera_online : ~~dev.dev_online,
				});
			}
		}

		let __device_capacities = dev.device_capacities !== undefined ? dev.device_capacities : '';
		// let __support_audio = (dev.device_extend_capacity & 65536) == 65536 ? 1 : 0;  //G3
		let __support_audio = __device_capacities.indexOf('31') > -1 || __device_capacities.indexOf('2c') > -1 ? 1 : 0;  //G4

		devs.push({
			id              : ~~dev.id,
			physical_id     : dev.physical_id,
			name            : dev.device_name,
			owner           : ~~dev.is_owner,
			owner_name      : dev.from_email,
			online          : ~~dev.device_online,
			status          : ~~dev.device_status,
			icon            : base + `http://10.80.6.31:88/v1/swift/icons/${ dev.device_model }.png`,
			photo           : dev.image_url != undefined ? base + dev.image_url[0]['1'] : '',
			photo_small     : '',
			photo_list      : ~~dev.device_channel > 1 ? converConv(base, dev.image_url) : [],
			type            : ~~dev.device_type,
			channel         : ~~dev.device_channel,
			sku             : dev.device_model,
			version         : dev.device_version.substr(dev.device_version.lastIndexOf(';') + 1),
			new_version     : null, // dev.device_upgrade
			time_zone       : dev.time_zone,
			time_zone_offset: ~~dev.offset_seconds,
			unread          : ~~dev.no_read_num,
			// hub_id 		: ~~dev.hub_id, // 虚拟组
			passive_device  : passive,
			support_audio   : __support_audio,
			settings: {
				notification: ~~dev.notification && ~~dev.notifications ? 1 : 0,
				device_on   : ~~dev.device_on,
				mute        : ~~dev.mute,
				microwave_switch: ~~dev.microwave_switch,
				nightvision : ~~dev.nightvision,
				imageflip   : ~~dev.imageflip,
				// pilot: ~~dev.device_pilot, // 设备指示灯 0 / 1
				// answering: ~~dev.answering, // 门铃应答推送 0 / 1
				// signal: ~~dev.signal_intensity, // 中继信号强度 1 - 3
				// sensitivity: ~~dev.sensitivity, // 异响灵敏度 1 - 3
			},
			//
			// support_hls   : dev?.device_supply_capacity ? ~~(dev.device_supply_capacity & 0x8000000 > 0) : false, // eslint-disable-line
			support_osd   : ~~(dev.device_supply_capacity >> 26 & 1), //device_supply_capacity  bit26 : 为1 表示主码流没有OSD（次码流有OSD）， 为0 表示主次码流都有OSD。
			support_cloud : ~~dev.support_cvr,
			support_osd2   : ~~dev.device_supply_capacity,
			cloud: !~~dev.if_cvr ? null : {
				type      : ~~dev.period,
				pay       : 1,
				status    : ~~dev.status,
				method    : 1,
				start_time: 0,
				end_time  : 0,
				next_time : 0,
				message   : '',
			},
			permission: (dev.permission) ? (dev.permission) : null,
			aes_key: dev.aes_key,
		});
	}
	return devs;
}

function converConv(base, convers = []) {
	for(let i = convers.length; i --; ) {
		for(let key in convers[i]) {
			convers[i][key] = base + convers[i][key];
		}
	}
	return convers;
}

function reSort(devs){
	if(Array.isArray(devs)){
		devs.reverse();
	}
	return devs;
}

let apiUrl = getUrl();  //'/mng-mop'
let _token = getLGToken();

export default class DeviceCenterApi{

	getDeviceList(physical_ids=''){
		_token = getLGToken();
		let _path = 'device/device_list';
		let _url = `${apiUrl}/${_path}?token=${_token}`
		if(physical_ids){
			_url = `${_url}&physical_ids=${physical_ids}`
		}else{
			_url = `${_url}&start=${0}&count=999`
		}

		return new Promise((resolve, reject) => {
			axios.get(_url).then(({data}) => {
				if(data.result === 'ok'){
					let arr = data?.data;
					if(Array.isArray(arr)){
						arr = deviceConv(arr, _token);
					}else{
						arr = []
					}
					resolve(arr)
				}
			}).catch((e) => {
				console.log(e)
				resolve([])
			})
		})
	}

	//查询设备在线状态
	checkDeviceOnlineStatus(physical_id){
		_token = getLGToken();
		let _path = 'device/is_online';
		let _url = `${apiUrl}/${_path}`

		let req = {
			token: _token,
			physical_id: physical_id
		}

		let headers = {
			"Content-Type": "application/x-www-form-urlencoded"
		}

		return new Promise((resolve, reject) => {
			axios.post(_url, req, {headers}).then(({data}) => {
				if(data.result === 'ok'){
					let device_online = data?.data?.device_online;
					resolve(Number(device_online) === 1);
				}
			}).catch((e) => {
				console.log(e)
				resolve(false)
			})
		})
	}

//	查询设备云服务状态
	checkDeviceCloudServeStatus(physical_id=''){
		_token = getLGToken();
		let _path = 'device/storage_list';
		let _url = `${apiUrl}/${_path}?token=${_token}`;

		if(physical_id){
			_url = `${_url}&physical_id=${physical_id}`
		}

		let headers = {
			"Content-Type": "application/x-www-form-urlencoded"
		}

		return new Promise((resolve, reject) => {
			axios.get(_url).then(({data}) => {
				if(data.result === 'ok'){
					let arr = data?.data;
					if(Array.isArray(arr)){
						arr = arr;
					}else{
						arr = []
					}
					resolve(arr)
				}
			}).catch((e) => {
				console.log(e)
				resolve([])
			})
		})
	}

}



// 剪视频
export function clipIotekVideo(data:any){

	let access_token = getLGToken();
	data['token'] = access_token;
	let req = ''
	for(let ky of Object.keys(data)){

		if(req){
			req = `${req}&${ky}=${data[ky]}`
		}else{
			req = `${ky}=${data[ky]}`
		}
	}

	let headers = {
		"Content-Type": "application/x-www-form-urlencoded"
	}

	let _url = `${getUrl()}/device/video_clip?${req}`;

	return new Promise((resolve, reject) => {
		axios.post(_url, data, {headers}).then(({data}) => {
			resolve(data)
		}).catch((e) => {
			console.log(e)
			resolve(e)
		})
	})
	// return service.post(`${getUrl()}/device/video_clip?${req}`, null)
}

// 查询是否剪辑好了视频
export function checkClipIotekVideo(data:any){
	let access_token = getLGToken();
	data['token'] = access_token;
	let req = ''
	for(let ky of Object.keys(data)){

		if(req){
			req = `${req}&${ky}=${data[ky]}`
		}else{
			req = `${ky}=${data[ky]}`
		}
	}
	let headers = {
		"Content-Type": "application/x-www-form-urlencoded"
	}
	let _url = `${getUrl()}/device/download_clip?${req}`;

	return new Promise((resolve, reject) => {
		axios.post(_url, data).then(({data}) => {
			resolve(data)
		}).catch((e) => {
			console.log(e)
			resolve(e)
		})
	})
	// return service.post(`${zaira2iotek}/openapi/video_query?${req}`, null)
}

// 下载剪辑的视频
export function downloadClipIotekVideo(f_url=''){

	let access_token = getLGToken();
	// data['token'] = access_token;
	// let req = ''
	// for(let ky of Object.keys(data)){
	//
	// 	if(req){
	// 		req = `${req}&${ky}=${data[ky]}`
	// 	}else{
	// 		req = `${ky}=${data[ky]}`
	// 	}
	// }
	// return `${getUrl('alarm')}/storage/download_file?${req}`;
	return `/alarm_mop/storage/download_file?token=${access_token}&url=${f_url}`
}

export function getVideoRecordList(physical_id, channel_id=0){
	let token:any = localStorage.getItem('pfToken');
	token = token ? token : false;

	let _token = localStorage.getItem('pf-iotek-token');
	if(_token){
		token = _token;
	}
	return new Promise((resolve, reject) => {resolve(true)})
	// return service.get(`${url}/devices/${physical_id}/record/${channel_id}?tokenid=${token}`)
}

// iotek 设备云记录列表
export function getIotekDevCVRRecordList(data:any){

	let access_token = localStorage.getItem('pfToken');
	data['access_token'] = access_token;
	let req = ''
	for(let ky of Object.keys(data)){

		if(req){
			req = `${req}&${ky}=${data[ky]}`
		}else{
			req = `${ky}=${data[ky]}`
		}
	}
	return new Promise((resolve, reject) => {resolve(true)})
	// return service.post(`${zaira2iotek}/openapi/record_list?${req}`, null)
}

// 直接Iotek获取 云记录
export function checkIotekDevCVRRecord(physical_id, channel_id=0){
	let access_token = localStorage.getItem('pfToken');

	let req = `?physical_id=${physical_id}&channel_id=${channel_id}&access_token=${access_token}`
	// return service.post(`${zaira2iotek}/openapi/record_date${req}`, null)
	return ''
}

// Baytowne 设备云记录列表
export function getDevCVRRecordList(data:any){
	let token = getLGToken();

	let _url = `${getUrl()}/device/record_list?token=${token}`;
	if(data.physical_id){
		_url = `${_url}&physical_id=${data.physical_id}`
	}
	if(data.channel_id){
		_url = `${_url}&channel_id=${data.channel_id}`
	}else{
		_url = `${_url}&channel_id=0`
	}
	if(data.record_date){
		_url = `${_url}&record_date=${data.record_date}`
	}
	if(data.record_cloud){
		_url = `${_url}&record_cloud=${data.record_cloud}`
	}else{
		_url = `${_url}&record_cloud=1`
	}

	return new Promise((resolve, reject) => {
		axios.get(_url).then(({data}) => {
			resolve(data)
		}).catch((e) => {
			console.log(e)
			resolve(e)
		})
	})
}

// 通过baytowne 项目获取云记录
export function checkDevCVRRecord(physical_id, channel_id=0){
	let token = getLGToken();
	let _url = `${getUrl()}/device/record_date?physical_id=${physical_id}&channel_id=${channel_id}&token=${token}`;

	return new Promise((resolve, reject) => {
		axios.get(_url).then(({data}) => {
			resolve(data)
		}).catch((e) => {
			console.log(e)
			resolve(e)
		})
	})
}

// 唤醒低功耗设备
export function wakeupDevice(physical_id){
	let access_token = getLGToken();
	let req = {
		token: access_token,
		physical_id: physical_id,
		media_type: 1,
		is_play_live_video: 1,
		channel_id: 0,
	}
	// data['token'] = access_token;
	// let req = ''
	// for(let ky of Object.keys(data)){
	//
	// 	if(req){
	// 		req = `${req}&${ky}=${data[ky]}`
	// 	}else{
	// 		req = `${ky}=${data[ky]}`
	// 	}
	// }

	let headers = {
		"Content-Type": "application/x-www-form-urlencoded"
	}

	let _url = `${getUrl()}/device/wakeup`;

	return new Promise((resolve, reject) => {
		axios.post(_url, req, {headers}).then(({data}) => {
			resolve(data)
		}).catch((e) => {
			console.log(e)
			resolve(e)
		})
	})
}

