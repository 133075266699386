import './index.less'
import React, { useState } from 'react';
import { Row, Col, Button, Form, Input, DatePicker, Select, Drawer } from 'antd';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg' 
import HelpSvg from '../../../../assets/images/checkOut/help.svg'

import Icon from "@ant-design/icons";

const { TextArea } = Input;

const ShippingAddressModal = (props) => {
    const { open, onOpen } = props;
    const [RmaForm] = Form.useForm();
    const [InformationForm] = Form.useForm();
    
    const handleSubmit = () => {
        onOpen(false);
    }


    const onClose = () => {
        onOpen(false);
    };
    return <Drawer title="" placement="right" width={464} className='shipping-address-modal' onClose={onClose} open={open}>
        <div className='details-modal-title'>
            <p></p>
            <p>Shipping Address</p>
            <p onClick={onClose}>
                <Icon component={CloseIcon} style={{ fontSize: "14px" }} />
            </p>
        </div>
        <div className='shipping-address-modal-from'>
            <Form
                form={RmaForm}
            >
                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item label="" name="region">
                            <Select value="Country / Region" placeholder="Country / Region">
                                <Select.Option value="Country / Region" >Country / Region</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="firstName">
                            <Input placeholder='First Name' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="lastName">
                            <Input placeholder='Last Name' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="" name="company">
                            <Input placeholder='Company (Optional)' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="" name="address">
                            <Input placeholder='Address' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="" name="status">
                            <Input placeholder='Apartment, Suite, Etc (Optional)' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="city">
                            <Input placeholder='City' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="province">
                            <Select value="Province" placeholder="Province">
                                <Select.Option value="Province" >Province</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="code">
                            <Input placeholder='Postal Code' />
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ position: "relative" }}>
                        <Form.Item label="" name="phone">
                            <Input placeholder='Phone' />
                        </Form.Item>
                        <Icon component={HelpSvg} style={{ fontSize: "16px", position:"absolute", top: "16px", right: "16px" }} />
                    </Col>
                </Row>
            </Form>
        </div>
       
        <div className='shipping-address-modal-footer'>
            <Button onClick={handleSubmit}>Cancel</Button>
            <Button onClick={handleSubmit}>Save</Button>
        </div>


    </Drawer>;
}

export default ShippingAddressModal;