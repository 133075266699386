const fileTypeIcon = {
	'doc': '/res/icons/file-type/icon_word.svg',
	'docx': '/res/icons/file-type/icon_word.svg',
	'xls': '/res/icons/file-type/icon_excel.svg',
	'xlsx': '/res/icons/file-type/icon_excel.svg',
	'link': '/res/icons/file-type/icon_link.svg',
	'pdf': '/res/icons/file-type/icon_pdf.svg',
	'png': '/res/icons/file-type/icon_picture.svg',
	'jpg': '/res/icons/file-type/icon_picture.svg',
	'gif': '/res/icons/file-type/icon_picture.svg',
	'ppt': '/res/icons/file-type/icon_ppt.svg',
	'pptx': '/res/icons/file-type/icon_ppt.svg',
	'txt': '/res/icons/file-type/icon_txt.svg',
	'unknown': '/res/icons/file-type/icon_unknown.svg',
	'mp4': '/res/icons/file-type/icon_video.svg',
	'mov': '/res/icons/file-type/icon_video.svg',
	'mp3': '/res/icons/file-type/icon_voice.svg',
	'zip': '/res/icons/file-type/icon_zip.svg',
	'rar': '/res/icons/file-type/icon_zip.svg',
	'7z': '/res/icons/file-type/icon_zip.svg',
}

export default fileTypeIcon;
