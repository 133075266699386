import '../index.less'
import DeviceCenterModuleAlerts from "../modules/alerts";
const  Alerts = () => {
	return <>
		<div className={'page-device-center '}>
			<div className={'main-container'}>
				<p className={'h1-title'}>Alerts</p>
				<div>
                <DeviceCenterModuleAlerts />
				</div>
			</div>
		</div>
	</>
}


export default Alerts