import { Button, Modal, Input, message } from 'antd';
import './index.less'
import { useEffect, useState } from "react";
import {useLocation, useNavigate, useParams} from 'react-router-dom'

function TipsModal(props) {

	const {
		visible,
		onVisible,
		diskInfo
		
	} = props
    const navigate = useNavigate()
	const [content, setContent] = useState('')


	useEffect(() => {
		    let _content 
       		if (Number(diskInfo?.size) === 0 || Number(diskInfo?.usable) < 0) {
				_content = 'Access restricted';
			}
			if (Number(diskInfo?.size) > 0 && Number(diskInfo?.usable) < 0) {
				_content = 'Not enough space'
			}
			setContent(_content)
    }, [diskInfo])


	

	const toSubscribeFun = () => {
		let data = {
			size_type: 1,
			order_pf: 'web_pay',
			pay_type: 2,
			order_type: 9,
			is_vault: 1,
		}

		let _params = `?order_type=${data.order_type}&size_type=${data.size_type}&order_pf=${data.order_pf}&pay_type=${data.pay_type}`
		navigate(`/cloud-plan-form-jump/subscribe${_params}`, {state: {order: data}})
	}

	

	const handleOk = () => {
		toSubscribeFun()
	}

	const handleCancel = () => {
		onVisible(false)
	}


	return <Modal open={visible} className='tips-modal' centered onOk={handleOk} onCancel={handleCancel} footer={null}

	>
		<div className='prompt'>
			<div className='title'>Tips</div>
		</div>
		<div className='prompt-con'>
             <p>{content}</p>
		</div>
		<div className='prompt-footer'>
            <Button onClick={handleOk}>Restore now</Button>
			<Button onClick={handleCancel}>Later</Button>
			
		</div>
	</Modal>
}

export default TipsModal
