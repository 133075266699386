import {Modal} from "antd";
import './index.less';

export default function CommModal(props){
	const {title, open, onOk, onCancel, children, cancelText, okText, confirmLoading} = props;

	return <>
		<Modal
			title={title}
			open={open}
			onOk={onOk}
			onCancel={onCancel}
			cancelText={cancelText}
			okText={okText}
			confirmLoading={confirmLoading}
		>
			{children}
		</Modal>
	</>
}
