import getApiUrl from "../apiUrl";
import token from "../../utils/token";
import requsetData from "../request";
import {json2params} from "../../utils/common";

const url = '/mng-mop' // //getApiUrl('PFMng');


// 获取计划列表
export const planListGPA = (params) => {
	return requsetData(`${url}/cloud/vdr_gpa_product?${json2params(params)}`, params)
}

export const planListIAP = (params) => {
	return requsetData(`${url}/cloud/vdr_apple_product?${json2params(params)}`, params)
}


//下单
export const subscribeGPA = (params) => {
	return requsetData(`${url}/cloud/vdr_gpa_order?${json2params(params)}`, params)
}

export const subscribeIAP = (params) => {
	return requsetData(`${url}/cloud/vdr_apple_order?${json2params(params)}`, params)
}

/* 功能说明：获取订单列表
 * 关键字段说明：
   order_type：
    1：Apple 云告警
		2：Google 云告警
		3：Apple 云录像
		4：Google 云录像
		5：Vdr Apple 云计划
		6：Vdr Google 云计划

	 status：
	  0：待支付已确认
	  1：有效；
	  2：已取消（到期才失效）；
	  3：宽限期；
	  4：暂时保留；
	  5：已到期（已失效）
	  6：客服操作撤销

 *
**/
export const getOrderList = (params) => {
	return requsetData(`${url}/cloud/vdr_plan_records?${json2params(params)}`, params)
}


// 获取内购订单列表
export const getCloudOrderList = (params) => {
	return requsetData(`${url}/cloud/order_list?${json2params(params)}`, params)
}



// 获取内购订单列表详情
export const getCloudOrderInfo = (params) => {
	return requsetData(`${url}/cloud/order_info?${json2params(params)}`, params)
}

// 获取用户的是否有memory 状态
export const getMemoryStatus = (params) => {
	return requsetData(`${url}/cloud/enable_memory?${json2params(params)}`, params)
}
