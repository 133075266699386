import './index.less'
import SmartzLogo from '../../assets/images/topBar/smartz_logo.svg'
import LogoPng from '../../assets/images/logo.png'
import Message from '../../assets/images/topBar/message.svg'
import Down from '../../assets/images/topBar/down.svg'
import TransferList from '../../assets/images/topBar/transfer_list.svg'
import UserPng from '../../assets/images/topBar/user.png'
import Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {useEffect, useRef, useState} from 'react';
import { getLogout } from '../../server/login'
import {  message } from 'antd';
import IconUserDefault from '../../assets/images/user-default-profile.png';
import {useMyNavigate} from "../../hook/useMyNavigate";
import {getCookie, getLGToken, getPlatfromByUrl, removeCookie} from "../../utils/common";
import mainLogo from "../../utils/logo/index.js";
import lang from "../../utils/lang";
const _platform = getPlatfromByUrl();


const TopBar = () => {
    const navigate = useNavigate();
    const {navigateX} = useMyNavigate();
    const [accountObj, setAccountObj] = useState<any>({})

	const time_a_ref = useRef<any>(0);

    const handleOut = async () => {
        let params = {
            token: getLGToken(),
        }

			handleAccount();
    }
    const handleAccount = () => {
			navigateX('/account');
    }

    useEffect(() => {
        const account:any = getCookie('accountInfo');
        if (JSON.stringify(account) !== '' && account !== null)
        setAccountObj(JSON.parse(account));
    }, [])

	useEffect(() => {
		postMSGReceiveFun();

		return () => {
			clearInterval(time_a_ref.current);
		}
	}, [])

	const postMSGReceiveFun = () => {
		clearInterval(time_a_ref.current);
		removeCookie('WWW_EVT_MSG_LOGIN_OR_LOGOUT');
		time_a_ref.current = setInterval(() => {
			let ev = getCookie('WWW_EVT_MSG_LOGIN_OR_LOGOUT');
			let _pathname = window.location.pathname;

			if(_pathname !== '/login'){
				if(ev === 'login'){
					removeCookie('WWW_EVT_MSG_LOGIN_OR_LOGOUT');
					window.location.reload();
				}else if(ev === 'logout'){console.log('postMSGReceiveFun---logout');
					removeCookie('WWW_EVT_MSG_LOGIN_OR_LOGOUT');
					window.location.href = '/login'
					// window.location.reload();
				}
			}
		}, 1000)
	}

    return (
        <div className="top-bar">
            <div className='top-bar-l'>
                 <div className='top-bar-l-logo'>
									 {(_platform === 'zmodo' || _platform === 'zmd') && <img src={LogoPng} alt="" style={{ width: "40px" }} />}
									 {_platform === 'smartz' && <Icon component={SmartzLogo} style={{fontSize: "35px"}}/>}
                 </div>
                 <div className='top-bar-l-txt'>{lang?.name}</div>
            </div>
            <div className='top-bar-r'>
								{/*<div className={'upload-list-switch-btn'} style={{marginRight: '24px'}} onClick={toUploadFilePage}>*/}
								{/*	<img src={'/res/icons/upload/icon_upload_list.svg'} style={{width: '16px'}} alt=""/>*/}
								{/*</div>*/}

                {/* <div>
                   <Icon component={TransferList} style={{ fontSize: "17px", marginRight: "60px" }}/>
                </div>                 */}

                <div className='message'>
                  <Icon component={Message}  style={{ fontSize: "17px" }}/>
                </div>
                <div className='user' >
                    <div className='user-txt' onClick={handleAccount}>
                         <h4>{accountObj?.username}</h4>
                         <p>{accountObj?.email}</p>
                    </div>
                    <div className='user-img' onClick={handleAccount}>
                        <img src={IconUserDefault} alt="" />
                    </div>
                    <div className='user-down' onClick={handleOut}>
                    	<Icon component={Down}  style={{ fontSize: "14px" }}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TopBar;
