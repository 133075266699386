const lang = {
  "name": 'Smartz Eaze Web App',
  "sign": {
    "welcome": "Welcome to Smartz Eaze",
      "description_in": "Sign in with the same username and credentials <br/>as your Smartz Eaze App.",
      "description_up": "By continuing, I agree to Smartz's {terms_of_service} <br />and {privacy_policy}."
    },
  "note": {
    "terms_of_service": "<a href='https://www.smartz.cloud/terms-of-services' target='_blank'>Terms of Service</a>",
    "privacy_policy": "<a href='https://www.smartz.cloud/privacy-policy' target='_blank'>Privacy Policy</a>",
    "copy": "Copyright © {year} Smartz. All right reserved"
  }
}

export default lang;
