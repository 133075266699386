import service from '../newRequest'
import getApiUrl from "../apiUrl";

import requsetData from "../request";
import {getLGToken, isJSON, json2params} from "../../utils/common";

// const url = getApiUrl('cloudDisk');
const url = '/vdr'; //getApiUrl('cloudDisk')
const login = '/app'; //getApiUrl('PFApp')





export function getFolder(data) {
    // const token = "7e0db25286bb079545500877bc13a9ec"
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    return service.get(`${url}/folder?dirId=${data.dirId}&order=${data.order}&orderBy=${data.orderBy}&key=${data.key}&page=${data.page}&pageSize=${data.pageSize}`, null, headers)
}


// 登录
export function loginUser(data) {
    let headers = {
        // 'Content-Type': 'application/json',
			"Content-Type": "application/x-www-form-urlencoded"
    }
	return service.post(`${login}/user/login`, data, headers)
  //   return service.get(`${login}/user/login?email=${data.email}&password=${data.password}&cid=${data.cid}&platform=${data.platform}&client=${data.client}&language=${data.language}`, null, headers)
}

// 删除文件夹（永久删除）
// export function loginUser(data){
// 	return requsetData(`${url}/user/login?email=${data.email}&password=${data.password}&cid=${data.cid}&platform=${data.platform}&client=${data.client}&language=${data.language}`, {}, {method: 'get', headers: null})
// }

// 修改密码
export function getChangePassword(data) {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return service.post(`${login}/user/modify_passwd`, data, headers)
}



// 发送邮箱验证码
export function getSendVerifForgetPasswd(data) {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return service.post(`${login}/user/forget_passwd`, data, headers)
}


// 修改密码
export function getResetPasswd(data) {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return service.post(`${login}/user/reset_passwd`, data, headers)
}

// 退出登录
export function getLogout(data) {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return service.post(`${login}/user/logout`, data, headers)
}



// 注册账户
export function getTryRegister(data) {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return service.post(`${login}/user/try_register`, data, headers)
}


// 注册账户
export function getActivateEmail(data) {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return service.post(`${login}/user/activate_email`, data, headers)
}

// 获取验证码
export function getUserCaptcha(){
	// user/captcha
	return service.get(`${login}/user/captcha`, {}, {});
}
