import {getLGToken, getPlatfromByUrl} from "../../../../utils/common";
import {useDeviceDetailStore} from "../../../../store/cloudPlan";
import Icon from "@ant-design/icons";
import CloseIcon from "../../../../assets/images/cloudPlan/close.svg";
import {Drawer} from "antd";
import React from "react";

export default function DeviceDetailModel(){
	let _platform = getPlatfromByUrl();

	const {deviceDetailZU, setDeviceDetailZU} = useDeviceDetailStore();

	let _token = getLGToken();
	let _physical_id = deviceDetailZU.data.physical_id
	if(['zmodo', 'zmd'].includes(_platform)){
		_platform = 'zmd';
	}

	let url = `https://11-web.meshare.com/${_platform}/application/cloud/detail.html?token=${_token}&physical_id=${_physical_id}&version=2`
	//有信息交互  在cloud主页
	const onClose = () => {
		setDeviceDetailZU({open: false, data: deviceDetailZU.data});
	};

	return <Drawer
		title="Basic Drawer"
		placement="right"
		width={640}
		className='security-subscribe-modal subscribe-modal-cvr'
		onClose={onClose}
		open={true}
	>
		<div className='details-modal-title'>
			<p></p>
			<p>&nbsp;</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>
		<div className={'cvr-sub-modal-main-box'}>
			<iframe src={url}></iframe>
		</div>
	</Drawer>
}
