import { useLocation, useNavigate } from 'react-router-dom'
import jumpPage from "../../utils/jumpPage";
import { getFolderDetails } from "../../server/folder";
import './index.less'
import { useEffect, useState } from "react";

export default function HeadNav(props) {
	const {icon, start, endElement} = props;
	const [filePath, setFilePath] = useState<any[]>([])
	const [title, setTitile] = useState<any>("")
	const location = useLocation();
	let searchParams = new URLSearchParams(location.search);
	let currentListFolderPid: any = searchParams.get('id');
	let _type: any = searchParams.get('type');


    function getFolderNamesFromPath(path) {
		// 正则表达式匹配所有由斜杠分隔的单词，不包括以斜杠开头和结尾
		const folderNames = path?.match(/(?:\/|^)[^\/]+(?=\/|$)/g);
	    const newFolderNames = folderNames?.map((item:any) => {
			return item.replace(/^\/|\/$/g, '')
		})

		return newFolderNames || [];
	  }



	  const getDetail = () => {
		if (currentListFolderPid === "undefined") {
			return false
		}
		// console.log(currentListFolderPid, 'currentListFolderPid')
		getFolderDetails(currentListFolderPid).then((res: any) => {
			const { data, result } = res;
			if (data && result === 'ok') {
				const newData = getFolderNamesFromPath(data.location);
                let obj = newData.map((key, index) => {
					return {
                        name: key,
                        id: data?.parents[index]
                    }

				});

				setFilePath(() => obj)
				setTitile(() => data.name)
			}
		}).catch(e => {
			let { message } = e;
			if (message) {
				message?.error(message);
			}
		})
	}


	useEffect(() => {
		if (currentListFolderPid && _type !== "3") {
			getDetail();
		}
	}, [currentListFolderPid])



	let _iconUrl = icon ? icon : '/res/icons/icon_back.svg';
	const navigate = useNavigate()

	const backFunc = () => {
		let searchParams = new URLSearchParams(location.search);
		let _hisdeep:any = searchParams.get('hisdeep');

		if(typeof _hisdeep === 'string'){
			_hisdeep = - Number(_hisdeep)
		}else{
			_hisdeep = -1;
		}
		navigate(_hisdeep);
	}

	const firstFunc = (item) => {
		// const params = new URLSearchParams(location.search)
		let name:any
		if (location.pathname.includes("/vault/cloud-gallery-photo")) {
			name = `/vault?id=${item.id}&type=undefined`
		} else {
			name = `${location?.pathname}?id=${item.id}&type=undefined`
		}
		  
		jumpPage(navigate, name);

	}

	const handleAllFiles = async () => {
		jumpPage(navigate, `/vault`);
	}

	return <>
	{
		(currentListFolderPid) ? <div className={'ui-head-nav-tom'}>
		<div className={'head-nav-start'}>
			<div className={'head-nav-title'}>
				{filePath?.map((item:any) => {
					return <div className='head-nav-title' key={item.id}> {item.name === "Vault" ? <p onClick={handleAllFiles}><span style={{ marginRight: "4px" }}>All Files</span> &gt;  </p>  : <p onClick={() => firstFunc(item)} style={{ marginLeft: "4px" }}><span >{item.name}</span>  &gt; </p>} </div>
				})}
				<span className="head-nav-title-actvie" style={{marginLeft: filePath.length >= 1 ? "4px" : "" }} >{title}</span>
			</div>
		</div>
		<div className={'head-nav-end'}>
			{ endElement } 
		</div>
	</div> : <></>
	}

	</>
}
