import Icon from "@ant-design/icons";
import SelectActive from "../../../../assets/images/cloudPlan/select_active.svg";

export default function CVRPlanCardSmartz(props){
	const {cloudPromoInfo, handleSubscribe, hasFreeTrial} = props;

	return <div className='cloud-plan-con-card-con cloud-plan-con-card-con-3'>
		<div className='subscribe-card'>
			{cloudPromoInfo?.p1?.discount &&<div className={'a-promo-tag'}>{cloudPromoInfo?.p1?.discount}% Off</div>}
			<div className='subscribe-card-title'>
				Plus
			</div>
			<div className='subscribe-card-con'>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>Clips</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>Events history</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>30 Days Storage</span>
				</p>
			</div>
			{
				cloudPromoInfo?.p1?.discount ?
					<div className='subscribe-starting'>
						Starting at:
						<span className={'o_price'}>${cloudPromoInfo?.p1?.o_price}</span>
						<span>${cloudPromoInfo?.p1?.price}</span>
					</div>
					:
					<div className='subscribe-starting'>
						Starting at: <span>$3.99</span>
					</div>
			}
			<div className='subscribe-card-btn' onClick={() => handleSubscribe(5,2,0)}>
				{hasFreeTrial ? "Start My Free Trial" : "Subscribe"}
			</div>
		</div>
		<div className='subscribe-card'>
			{cloudPromoInfo?.p2?.discount &&<div className={'a-promo-tag'}>{cloudPromoInfo?.p2?.discount}% Off</div>}
			<div className='subscribe-card-title'>
				Premium
			</div>
			<div className='subscribe-card-con'>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>Clips</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>Events history</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>60 Days Storage</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>300GB bonus Vault</span>
				</p>
			</div>
			{
				cloudPromoInfo?.p2?.discount ?
					<div className='subscribe-starting'>
						Starting at:
						<span className={'o_price'}>${cloudPromoInfo?.p2?.o_price}</span>
						<span>${cloudPromoInfo?.p2?.price}</span>
					</div>
					:
					<div className='subscribe-starting'>
						Starting at: <span>$7.99</span>
					</div>
			}

			<div className='subscribe-card-btn' onClick={() => handleSubscribe(5, 3, 0)}>
				{hasFreeTrial ? "Start My Free Trial" : "Subscribe"}
			</div>
		</div>
		<div className='subscribe-card'>
			{cloudPromoInfo?.p3?.discount &&<div className={'a-promo-tag'}>{cloudPromoInfo?.p3?.discount}% Off</div>}
			<div className='subscribe-card-title'>
				Platinum
			</div>
			<div className='subscribe-card-con'>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>Continuous</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>24/7 recording</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>60 Days Storage</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>500GB bonus Vault</span>
				</p>
			</div>
			{
				cloudPromoInfo?.p3?.discount ?
					<div className='subscribe-starting'>
						Starting at:
						<span className={'o_price'}>${cloudPromoInfo?.p3?.o_price}</span>
						<span>${cloudPromoInfo?.p3?.price}</span>
					</div>
					:
					<div className='subscribe-starting'>
						Starting at: <span>$14.99</span>
					</div>
			}

			<div className='subscribe-card-btn' onClick={() => handleSubscribe(6,3,0)}>
				{hasFreeTrial ? "Start My Free Trial" : "Subscribe"}
			</div>
		</div>
	</div>

}
