import ReactDOM from 'react-dom/client';
import './assets/css/init.css';
import BasicRouter from './router';
import 'lib-flexible';
import 'video.js/dist/video-js.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
NodePlayer.load(() => {
	root.render(
		// <React.StrictMode>
		<BasicRouter />
		// </React.StrictMode>
	);
});



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals