// import Icon from "@ant-design/icons";
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { Menu } from 'antd';
import CloudPlan from '../../assets/images/leftNav/cloud_plan.svg'
import DeviceCenter from '../../assets/images/leftNav/device_center.svg'
import Vault from '../../assets/images/leftNav/vault.svg'
// import ShoppingCart from '../../assets/images/leftNav/shopping_cart.svg'
// import MyOrders from '../../assets/images/leftNav/my_orders.svg'
import {getPlatfromByUrl} from "../../utils/common";

import './index.less'
import { useMyNavigate } from "../../hook/useMyNavigate";

const _platform = getPlatfromByUrl();

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}


let items = [
  getItem('Cloud Plan', 'sub1', <CloudPlan />,),
  getItem('Device Center', 'sub2', <DeviceCenter />, [
    getItem('Live View', '1'),
    getItem('Alerts', '2'),
    getItem('Memories', 'sub2-3'),
  ]),
  getItem('Vault', 'sub3', <Vault />, [
    getItem('All Files', '3'),
    getItem('Starred', '4'),
    getItem('Trash', '5'),
  ]),
  // getItem('Shopping Cart', 'sub4', <ShoppingCart />, null),
  // getItem('My Orders', 'sub5', <MyOrders />, null),
];

if(_platform === 'zmodo' || _platform === 'zmd'){
  items = [
    getItem('Cloud Plan', 'sub1', <CloudPlan />,),
    getItem('Device Center', 'sub2', <DeviceCenter />, [
      getItem('Live View', '1'),
      getItem('Alerts', '2'),
    ]),
    getItem('Vault', 'sub3', <Vault />, [
      getItem('All Files', '3'),
      getItem('Starred', '4'),
      getItem('Trash', '5'),
    ]),
    // getItem('Shopping Cart', 'sub4', <ShoppingCart />, null),
    // getItem('My Orders', 'sub5', <MyOrders />, null),
  ];
}


function LeftNav() {
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [currentKeys, setCurrentKeys] = useState(["sub1"])
  const [itemsList] = useState(items)
  const onOpenChange = keys => {
    //make sure only one submenu is open
    // console.log(keys, '38')
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (items.map(i => i.key).indexOf(latestOpenKey) === -1) {
      // console.log(keys, '40')

      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const navigate = useNavigate()
  const location = useLocation()
  const { navigateX } = useMyNavigate()

  useEffect(() => {
    if (location.pathname.includes("/account")) {
      setCurrentKeys([""])
      setOpenKeys([""])
    } else if (location.pathname.includes("/cloud-plan")) {
      setCurrentKeys(["sub1"])
      setOpenKeys(["sub1"])
    } else if (location.pathname.includes("/device-center")) {
      setCurrentKeys(["1"])
      setOpenKeys(["1"])
      setOpenKeys(["sub2"])
    } else if (location.pathname.includes("/alerts")) {
      setCurrentKeys(["2"])
      setOpenKeys(["2"])
      setOpenKeys(["sub2"])
    } else if (location.pathname.includes("/vault")) {
      setCurrentKeys(["3"])
      setOpenKeys(["3"])
      setOpenKeys(["sub3"])
    } else if (location.pathname.includes("/favourite")) {
      setCurrentKeys(["4"])
      setOpenKeys(["4"])
      setOpenKeys(["sub3"])
    } else if (location.pathname.includes("/trash")) {
      setCurrentKeys(["5"])
      setOpenKeys(["5"])
      setOpenKeys(["sub3"])
    } else if (location.pathname.includes("/memories")) {
      setCurrentKeys(["sub2-3"])
      setOpenKeys(["sub2-3"])
      setOpenKeys(["sub2"])
    }
  }, [location])


  const onClick = (e) => {
    setCurrentKeys([e.key])
    switch (e.key) {
      case 'sub1':
        navigateX('/cloud-plan')
        break;
      case '1':
        navigateX('/device-center')
        break;
      case '2':
        navigateX('/alerts')
        break;
      case '3':
        navigateX(`/vault`)
        break;
      case '4':
        navigate('/favourite')
        break;
      case '5':
        navigate('/trash')
        break;
      case 'sub2-3':
        navigate('/memories')
        break;
      default:
        break;
    }
  };

  // console.log(currentKeys, '107')

  return (
    <div className='navContainer'>
      {/* <div className='logoContainer'>
        <img src={BaytowneIcon} alt="" />
      </div> */}
      <div className='navItemContianer'>
        <Menu
          onClick={onClick}
          className='sectionMenu'
          defaultSelectedKeys={currentKeys}
          // defaultOpenKeys={currentKeys}
          defaultOpenKeys={currentKeys}
          selectedKeys={currentKeys}
          mode="inline"
          items={itemsList}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      </div>
    </div>
  )
}

export default LeftNav
