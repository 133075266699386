import './index.less'
import { Button, message, Form, Row, Col, Input, Select } from 'antd';
import { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
// import LogoSvg from '../../assets/images/logo.svg'
import LogoPng from '../../assets/images/logo.png'
// import Icon from "@ant-design/icons";
import { useState } from 'react';
import { getSendVerifForgetPasswd, getResetPasswd } from '../../server/login'
import md5 from 'js-md5';
import lang from "../../utils/lang";
import { getPlatfromByUrl } from "../../utils/common";
import mainLogo from "../../utils/logo/index.js";
import Icon from "@ant-design/icons";
import zonecodeData from '../../assets/json/zonecode.json';
import usePlatform from "../../utils/platform";

const _platform = getPlatfromByUrl();
const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};
const Login = () => {
	const navigate = useNavigate();
	const [userform] = Form.useForm();
	const [forgotPasswordform] = Form.useForm();
	const [isActive, setIsActive] = useState(false);
	const [isResetActive, setIsResetActive] = useState(false);
	const [userName, setUserName] = useState<string>('')
	const [current, setCurrent] = useState(1);
	const [verifyId, setVerifyId] = useState("")
	const [count, setCount] = useState(0) // 默认0秒
	const timerRef = useRef(null) // 使用useRef设置一个倒计时器，这样我就可以防止重新渲染
	const [isSending, setIsSending] = useState(false); // 是否正在发送验证码

	const [cityList, setCityList] = useState<any>([]);
	const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false);
	const [phoneRegion, setPhoneRegion] = useState<string>('1');

	const inputUserNameRef = useRef<any>(null);

	const platformObj = usePlatform();

	useEffect(() => {
		if (Array.isArray(zonecodeData?.zonecode)) {
			setCityList(() => zonecodeData?.zonecode);
		}
	}, [zonecodeData])

	const handleUser = async (e, ky) => {
		const values = await userform.validateFields();

		if (ky === 'username') {
			let val = values.username;
			let phoneReg = /^\d{6,11}$/;

			let isPhone = phoneReg.test(val);
			setIsPhoneNumber(() => isPhone);

			// if(inputUserNameRef.current && val.length > 3 && val.length < 8){
			setTimeout(() => { inputUserNameRef.current.focus(); }, 50)
			// }
		} else if (ky === 'phone_region') {
			setPhoneRegion(() => e)
		}

		if (values.username) {
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}

	const handleforgotPassword = async (e) => {
		const values = await forgotPasswordform.validateFields();
		if (values.vCode && values.password && values.rePassword) {
			setIsResetActive(true)
		} else {
			setIsResetActive(false)
		}
	}

	// 发送验证码
	const handleNext = async () => {
		const values = await userform.validateFields();
		const params = {
			cid: platformObj?.cid ? platformObj?.cid : 0,
			platform: platformObj?.id ? platformObj?.id : 21,
			client: 2,
			language: "en",
		}

		if (isPhoneNumber) {
			params['phone_number'] = values.username;
			params['phone_region'] = phoneRegion;
		} else {
			params['email'] = values.username;
		}

		setUserName(values.username)
		const res: any = await getSendVerifForgetPasswd(params)
		if (res.result === "ok") {
			message.success('Verification code sent successfully')
			setVerifyId(res?.data?.verify_id)
			setCurrent(2)
			setCount(59)
			setIsSending(true);
		} else {
			message.error(res.error)
		}
	}

	const handResend = async () => {
		const params = {
			cid: platformObj?.cid ? platformObj?.cid : 0,
			platform: platformObj?.id ? platformObj?.id : 21,
			client: 2,
			language: "en",
		}

		if (isPhoneNumber) {
			params['phone_number'] = userName;
			params['phone_region'] = phoneRegion;
		} else {
			params['email'] = userName;
		}

		const res: any = await getSendVerifForgetPasswd(params)
		if (res.result === "ok") {
			message.success('Verification code sent successfully')
			setVerifyId(res?.data?.verify_id)
			setCount(59)
			setIsSending(true);
			forgotPasswordform.setFieldsValue({ vCode: '' })

		} else {
			message.error(res.error)
		}
	}


	const handleResetPassword = async () => {
		if (isResetActive) {
			const values = await forgotPasswordform.validateFields();
			// const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
			// const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z][A-Za-z\d@$!%*#?&]{8,}$/
			const regex = /^(?!^\d+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^a-z0-9]+$)(?!^[^A-Z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S*$/
			if (!regex.test(values.password) || !regex.test(values.rePassword)) {
				message.error('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number')
				return
			}
			if (values.password === values.rePassword) {
				const params = {
					verify_id: verifyId,
					verify_code: values.vCode,
					password: md5(values.rePassword)
				}
				const res: any = await getResetPasswd(params)
				if (res.result === "ok") {
					message.success('Password reset successfully')

					let _search = new URLSearchParams(window.location.search);
					let return_url = _search.get('return_url');

					if (return_url) {
						return_url = decodeURIComponent(return_url);
						window.location.href = return_url;
					} else {
						navigate('/login')
					}
				} else {
					message.error(`${res.error}`)
				}

			} else {
				message.warning('Passwords do not match')
			}

		}
	}

	// 监听count的变化
	useEffect(() => {
		if (count >= 59) {
			timerRef.current = setInterval(() => {
				setCount((preCount) => preCount - 1);
			}, 1000);
		} else if (count === 0) {
			clearInterval(timerRef.current);
			setIsSending(false);
		}
	}, [count]);

	const selectCityList = (
		<Select popupClassName={'ct-list-select-x'} defaultValue="1" onChange={(e) => handleUser(e, 'phone_region')}>
			{
				(cityList).map((item: any, index: number) => {
					return <Select.Option value={item.value} key={index}>{item.value}</Select.Option>
				})
			}
		</Select>
	);

	let _year = lang?.note?.copy;
	if (_year) {
		_year = _year.replace('{year}', (new Date().getFullYear()).toString());
	}

	return (
		<>
		<div className="forgot-password-con">
			<div className='login-logo'>
				<p>
					{(_platform === 'zmodo' || _platform === 'zmd') &&
						<img src={mainLogo} alt="" style={{ width: "96px" }} />}
					{_platform === 'smartz' && <Icon component={mainLogo} style={{ fontSize: "174px" }} />}
				</p>
				<p>{lang?.sign?.welcome}</p>
			</div>
			{
				current === 1 && <div className='login-con'>
					<div className='login-form'>
						<div className='login-form-title'>
							Forgot Password
						</div>
						<div className='forgot-password'>
							<p>Please enter your email / phone to reset your password.</p>
						</div>
						<Form
							{...layout}
							form={userform}
							layout="vertical"
						>
							<Row>
								<Col span={24}>
									<Form.Item label="" name="username">
										<Input
											placeholder="Email / Phone Number"
											onChange={(e) => handleUser(e, 'username')}
											addonAfter={isPhoneNumber ? selectCityList : null}
											ref={inputUserNameRef}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form>

						<Row>
							<Col span={24}>
								<Button onClick={handleNext} className={isActive ? 'login-button-active' : "login-button"}>Next</Button>
							</Col>
						</Row>
					</div>
				</div>
			}
			{
				current === 2 && <div className='login-con'>
					<div className='login-form'>
						<div className='login-form-title'>
							Forgot Password
						</div>
						<div className='forgot-password'>
							<p>A verification code has been sent to your {isPhoneNumber ? 'Phone Number' : 'Email'}. <br />
								Please enter the code and your new password.</p>
						</div>
						<Form
							{...layout}
							form={forgotPasswordform}
							layout="vertical"
						>
							<Row>
								<Col span={24} style={{ position: "relative" }}>
									<Form.Item label="" name="vCode">
										<Input onChange={(e) => handleforgotPassword(e)} placeholder='Verification Code' />
									</Form.Item>
									{isSending ? <span className='resend'>{count}s</span> :
										<span className='resend' onClick={handResend}>Resend</span>}

								</Col>
							</Row>
							<Row>
								<Col span={24} className='border-pass'>
									<Form.Item label="" name="password">
										<Input.Password onChange={(e) => handleforgotPassword(e)} placeholder='Password' type='password' />
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24} className='border-pass'>
									<Form.Item label="" name="rePassword">
										<Input.Password onChange={(e) => handleforgotPassword(e)} placeholder='Re-enter Password'
											type='password' />
									</Form.Item>
								</Col>
							</Row>
						</Form>
						<Row>
							<Col span={24}>
								<Button onClick={handleResetPassword}
									className={isResetActive ? 'login-button-active' : "login-button"}>Reset Password</Button>
							</Col>
						</Row>
						<div className='login-up'>
							<p>Security Requirements:</p>
							<p>Password must have more than 8 characters</p>
							<p>Password must have numbers and letters</p>
						</div>
					</div>
				</div>
			}



		</div>
		<div className='login-footer'>
				<div>
					<p>{_year}</p>
				</div>
				<div>
					<p className={'a-link-sty'} dangerouslySetInnerHTML={{ __html: lang?.note?.privacy_policy }}></p>
					<p className={'a-link-sty'} dangerouslySetInnerHTML={{ __html: lang?.note?.terms_of_service }}></p>
					<p>Support</p>
				</div>
			</div>
		</>
	)
}

export default Login
