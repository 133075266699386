import {create} from 'zustand';

// 网盘的大小信息
const useVaultInfoStore = create(set => ({
  vaultInfoZU: {},
	setVaultInfoZU: (vaultInfoZU) => set({ vaultInfoZU }),
}));

// 更新网盘的指令
const useUpdateVaultInfoStore = create(set => ({
  isUpdateVaultInfoZU: false,
  setIsUpdateVaultInfoZU: (isUpdateVaultInfoZU) => set({ isUpdateVaultInfoZU }),
}));

// 文件列表
const useFileListStore = create(set => ({
  fileListZU: [],
  setFileListZU: (fileListZU) => set({ fileListZU }),
}));

// 是否更新文件列表
const useIsUpdateFileListStore = create(set => ({
  isUpdatefileListZU: false,
  setIsUpdateFileListZU: (fileListZU) => set({ fileListZU }),
}));

// 上传列表信息
const useUploadFileListStore = create(set => ({
  uploadFileListZU: [],
  setUploadFileListZU: (uploadFileListZU) => set({ uploadFileListZU }),
}));

// 当前上传目录的ID
const useCurrentUploadFolderIdStore = create(set => ({
  currentUploadFolderIdZU: 0,
  setCurrentUploadFolderIdZU: (currentUploadFolderIdZU) => set({ currentUploadFolderIdZU }),
}));

const useOpenPlanDetailStore = create(set => ({
  openPlanDetailZU: false,
  setOpenPlanDetailZU: (openPlanDetailZU) => set({ openPlanDetailZU }),
}));

const useCreateDirStore = create(set => ({
  createDirZU: false,
  setCreateDirZU: (createDirZU) => set({ createDirZU }),
}));

// 网盘的详细信息
const useVaultDetailInfoStore = create(set => ({
  vaultDetailInfoZU: {},
  setVaultDetailInfoZU:  (vaultDetailInfoZU) => set({ vaultDetailInfoZU }),
}))

// 系统目录的信息
const useVaultSystemFolderInfoStore = create(set => ({
  vaultSystemFolderInfoZU: [],
  setVaultSystemFolderInfoZU:  (vaultSystemFolderInfoZU) => set({ vaultSystemFolderInfoZU }),
}))

const useUpdateFolderListStore = create(set => ({
  updateFolderListZU: {update: false, data: {}},
  setUpdateFolderListZU: (updateFolderListZU) => set({updateFolderListZU})
}))

// 正在上传文件
const useUploadingFileStatusStore = create(set => ({
  uploadingFileStatusZU: false,
  setUploadingFileStatusZU: (uploadingFileStatusZU) => set({uploadingFileStatusZU})
}))

export {
  useVaultInfoStore,
  useUpdateVaultInfoStore,
  useFileListStore,
  useUploadFileListStore,
  useCurrentUploadFolderIdStore,
  useIsUpdateFileListStore,
  useOpenPlanDetailStore,
  useCreateDirStore,
  useVaultDetailInfoStore,
  useVaultSystemFolderInfoStore,
  useUpdateFolderListStore,
  useUploadingFileStatusStore
};
