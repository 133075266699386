/* eslint-disable */

const NAPI = {
  check: function () {
    return 'undefined' != typeof (NativeAPI);
  },
  tokenid: function (relogin, viewname) {
    return NAPI.check() ? NativeAPI.tokenid(relogin, viewname ? viewname : '') : false;
  },
  open: function (url, notnew) {
    if (NAPI.check() && !notnew) {
      NativeAPI.open(url);
    } else {
      if (notnew) {
        location.href = url;
      } else {
        window.open(url);
      }
    }
    return true;
  },
  player: function (type, data) {
    if (NAPI.check()) {
      NativeAPI.player(type, data);
      return true;
    }
    return false;
  },
  jump: function (view) {
    return NAPI.check() ? NativeAPI.jump(view) : false;
  },
  jump2: function (view, _parms = null) { //@_parms: name=nm&id=1
    // var _parms = arguments.length > 1 && arguments[1] != undefined ? arguments[1] : null;
    if (_parms != null) {
      return NAPI.check() ? NativeAPI.jump(view, _parms) : false;
    }
    return NAPI.check() ? NativeAPI.jump(view) : false;
  },
  close: function () {
    return NAPI.check() ? NativeAPI.close() : false;
  },
  payment: function (order, dev_id, prd_id, callback) {
    if (NAPI.check()) {
      NativeAPI.payment(order, dev_id, prd_id, callback);
      return true;
    }
    return false;
  },
  //Google Pay
  payment2: function (token_id, order_id, prd_id, account_id, profile_id, is_up, token, sku_id, callback) {
    if (NAPI.check()) {
      NativeAPI.payment(token_id, order_id, prd_id, account_id, profile_id, is_up, token, sku_id, callback);
      return true;
    }
    return false;
  },
  leave: function (arg='') {
    return NAPI.check() ? NativeAPI.leavePage(arg) : '';
  },
  go: function (view) {
    return NAPI.check() ? NativeAPI.go(view) : false;
  },
  callPhone: function (phonenumber) {
    NativeAPI.callPhone(phonenumber);
  },
  apptodo: function (action, data, callback=''){ // action: 操作， data：json对象, callback: 回调
    console.log('apptodo', action, data, callback);
    NativeAPI.apptodo(action, JSON.stringify(data), callback);
  }
};

if (isSpWKWebView()) {
  NAPI.check = function () {
    return true;
  }
  NAPI.tokenid = function (relogin, viewname) {
    window.webkit.messageHandlers.tokenid.postMessage(`{ "relogin": "${relogin}", "relgion": "${relogin}", "viewname": "${viewname ? viewname : ''}" }`);
  }
  NAPI.open = function (url, notnew) {
    if (NAPI.check() && !notnew) {
      window.webkit.messageHandlers.open.postMessage(`{ "action": "${url}" }`);
    } else {
      if (notnew) {
        location.href = url;
      } else {
        window.open(url);
      }
    }
    return true;
  }
  NAPI.player = function (type, data) {
    if (NAPI.check()) {
      window.webkit.messageHandlers.player.postMessage(`{ "type": "${type}", "data": ${data} }`);
      return true;
    }
    return false;
  }
  NAPI.jump = function (view) {
    window.webkit.messageHandlers.jump.postMessage(`{"action": "${view}"}`);
  }
  NAPI.jump2 = function (view, _parms = null) {
    // var _parms = arguments.length > 1 && arguments[1] != undefined ? arguments[1] : null;
    if (_parms != null) {
      window.webkit.messageHandlers.jump.postMessage(`{"action": "${view}", "parms": "${_parms}"}`);
    } else {
      window.webkit.messageHandlers.jump.postMessage(`{"action": "${view}"}`);
    }
  }
  NAPI.close = function () {
    window.webkit.messageHandlers.close.postMessage('{"action":"close"}');
  }
  NAPI.payment = function (order, dev_id, prd_id, callback) {
    if (NAPI.check()) {
      window.webkit.messageHandlers.payment.postMessage(`{"order": "${order}", "dev_id": "${dev_id}", "prd_id": "${prd_id}", "callback": "${callback}"}`);
      return true;
    }
    return false;
  }
  NAPI.leave = function (arg) {
    window.webkit.messageHandlers.leavePage.postMessage(`{"action": "${arg}"}`);
  }
  NAPI.go = function (view) {
    window.webkit.messageHandlers.go.postMessage(`{"action": "${view}"}`);
  }
  NAPI.callPhone = function (phonenumber) {
    window.webkit.messageHandlers.callPhone.postMessage(`{"action": "${phonenumber}"}`)
  }

  NAPI.apptodo = function (action, data, callback=''){// action: 操作， data：json对象, callback: 回调
    console.log('apptodo', action, data, callback);
    window.webkit.messageHandlers.apptodo.postMessage(`{"action": "${action}", "data": ${JSON.stringify(data)}, "callback": "${callback}"}`);
  }
}

function isSpWKWebView(){
  var ua = navigator.userAgent;
  if(-1 < ua.indexOf('WKWebView')) {
    return true;
  }
  return false;
}

export default NAPI;
