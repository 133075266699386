import VideoProgressBar from '../VideoProgressBar'
import './index.less'
// import VideoPng from '../../assets/securityAlerts/video.png'
import {useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash'
// import Video1Png from '../../assets/securityAlerts/video1.png'
import VideoPreview from '../VideoPreview'
// import VideoWebRTCPlayer from '../VideoWebRTCPlayer'

import PlayListMD from "../playList";

import IconPlay from '../../image/icon_Play.svg';
import IconPause from '../../image/icon_Pause.svg';
import IconSoundOff from '../../image/icon_Sound_Off.svg';
import IconSoundOn from '../../image/icon_Sound_On.svg';
import IconCalendar from '../../image/icon_Calendar.png';
import IconClip from '../../image/icon_Clip.png';
import IconFull from '../../image/icon_Full.svg';
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import ClipVideo from '../clipVideo'
import {getLGToken} from "../../../../utils/common";
import {useLocation} from "react-router-dom";
import {wakeupDevice} from "../../../../server/device";

export default function VideoPlayerII(props) {
  const deviceInfo = props.data ? props.data : {};
  const config:any = props.config ? props.config : {};

  const [loadPage, setLoadPage] = useState<boolean>(false);

  const [data, setData] = useState<any>({type: 'HR'});
  const [liveURL, setLiveURL] = useState<string>('');
  const [playTime, setPlayTime] = useState<number>(0);
  const [playbackTime, setPlaybackTime] = useState<number>(0);
  const [devObject, setDevObject] = useState<any>({});
  const [pause, setPause] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1);
  const [currentDate, setCurrentDate] = useState<any>('');
  const [playing, setPlaying] = useState<boolean>(false);
  const [vdieoPlayer, setVdieoPlayer] = useState<any>(null);
  const [fps, setFps] = useState<number>(0);

	const [playerSize, setPlayerSize] = useState<any>({width: 300, height: 168});

  const [showPlayList, setShowPlayList] = useState<boolean>(false);
	const [recordDateArr, setRecordDateArr] = useState<any>([]); // 有存储记录的日期

  const [playUrl, setPlayUrl] = useState<any>('');
  const [openDateWin, setOpenDateWin] = useState<boolean>(false);

  const [isMP4Player, setIsMP4Player] = useState<boolean>(false);

	const [MP4PlayerUrl, setMP4PlayerUrl] = useState<string>('');

	const [wWidth, setWWidth] = useState<number>(window.innerWidth);

	const [screenSize] = useState<any>({width: window.screen.width, height: window.screen.height})

  // 当前的播放时间
  const [currentPlayingTime, setCurrentPlayingTime] = useState<any>('');

  const [openClipVideo, setOpenClipVideo] = useState<boolean>(false);

  const eVideoProgressBarRef = useRef<any>(null);
  const isLiveRef = useRef<boolean>(false);
  const videoBoxRef = useRef<any>(null);
  const optionAtimerRef = useRef<any>(0);
	const optionBtimerRef = useRef<any>(0);
  const playerBoxRef = useRef<any>(null);
  const mouse_clk_flag_a_ref = useRef<any>(0);

  const mediaTypeRef = useRef<number>(1); // 清晰度类型 1 标清， 2 高清

	const mp4VideoPlayRef = useRef<any>(null);

	const firstLoadPageRef = useRef<boolean>(true);
	const timeoutRef = useRef<any>(null);

  let token:any = getLGToken();
  token = token ? token : false;

  const location = useLocation()

  let paramsSearch = new URLSearchParams(location.search);
  let url_param_play_back = paramsSearch.get('play_back');
	let url_param_start_time = paramsSearch.get('start_time');

  const clickFunc = () => {
    // return;
    let _data = _.cloneDeep(data);
    _data.type = _data.type === 'HR' ? 'MIN' : 'HR';
    setData(() => _data);
  }

  useEffect(() => {
  	if(Number(url_param_play_back) === 1 && url_param_start_time){
  		let s_time = Number(url_param_start_time);

  		setTimeout(() => {
				getDateTimeFromVideoListFun(s_time);
			}, 650)
		}
	}, [url_param_start_time,url_param_play_back])

  //getDateTimeFromVideoListFun

  useEffect(() => {
		setLoadPage(true);

  	return () => {
			clearTimeout(optionAtimerRef.current);
			clearTimeout(optionBtimerRef.current);

			window.removeEventListener('resize', playerSizeDone);
		}
	}, [])

  // 播放时间驱动
  const playTimeDrive = (v) => {
    setPlaying(() => v);
  }

  useEffect(() => {

    const {data, channel} = props;

    clearTimeout(optionBtimerRef.current);

		optionBtimerRef.current = setTimeout(() => {
			if(Object.prototype.toString.call(data) === '[object Object]'){
				let _host = 'https://flv.meshare.com/playback'
				let physical_id = data.physical_id;
				let tokenid = getLGToken();
				let media_type = 2; // 1, 2
				// let channel = 0;
				let start_time = playbackTime;
				let cid = 0;
				let aes_key_has_audio = data.aes_key_has_audio;

				let _url = `${_host}?devid=${physical_id}&token=${tokenid}&media_type=${media_type}&channel=${channel}&start_time=${start_time}&cid=${cid}${aes_key_has_audio}`;

				if(physical_id){
					// 不是回放
					if(playbackTime === 0){
						let _host = 'https://flv.meshare.com/live'
						let physical_id = data.physical_id;
						let tokenid = getLGToken();
						let media_type = mediaTypeRef.current; // 1, 2
						// let channel = 0;
						let start_time = dayjs().unix();
						let cid = 0;
						let aes_key_has_audio = data.aes_key_has_audio?data.aes_key_has_audio:'';

						let _url = `${_host}?devid=${physical_id}&token=${tokenid}&media_type=${media_type}&channel=${channel}&start_time=${start_time}&cid=${cid}${aes_key_has_audio}`;

						setDevObject(() => data);
						setPlayUrl(() => _url)
						isLiveRef.current = true;

					}else{
						data.url = _url;
						setDevObject(() => data);

						setPlayUrl(() => _url);
						isLiveRef.current = false;
						// setIsLive(() => false);
					}
				}


				// console.log(playbackTime, 'playbackTime', dayjs(playbackTime * 1000).format('YYYY-MM-DD HH:mm:ss'))

			}
		}, 20)


  }, [playbackTime, JSON.stringify(props)])

	// 切换播放列表时处理，适配
	useEffect(() => {
		clearTimeout(optionAtimerRef.current);
		optionAtimerRef.current = setTimeout(() => {
			playerSizeFun()
		}, 20)
	}, [showPlayList])


	useEffect(() => {
		if(loadPage){
			window.addEventListener('resize', playerSizeDone);
		}
	}, [loadPage])

	const playerSizeDone = () => {console.log('playerSizeDone => 132');
		clearTimeout(optionAtimerRef.current);
		optionAtimerRef.current = setTimeout(() => {
			playerSizeFun()
		}, 200)
	}

	useEffect(() => {
		let {type, physical_id} = deviceInfo;
		if(type === 36){
			wakeupDeviceFun(physical_id);
		}
	}, [deviceInfo, playbackTime])

	// 唤醒低功耗设备
	const wakeupDeviceFun = async (dev_id) => {
  	let res:any = wakeupDevice(dev_id);
	}

	const playerSizeFun = () => {
		if (playerBoxRef.current !== null) {
			let w = playerBoxRef.current.clientWidth;

			let win_h = window.innerHeight - 64 - 100;

			let h = Math.round(w * 9 / 16);

			h = h < win_h ? h : win_h;

			let _playerSize = {
				width: w,
				height: h
			}
			setPlayerSize(() => _playerSize);
			setWWidth(() => window.innerWidth);
		}
	}

  const volumeFunc = () => {
    if(volume === 1){
      setVolume(() => 0);
    }else{
      setVolume(() => 1);
    }
  }

  const playFunc = () => {
    if(playing) return;
    setPause(() => !pause)
  }

  const changeCurrentDateFunc = (e) => {

    let _date = dayjs(e).format('YYYY-MM-DD');

    if(eVideoProgressBarRef.current){
      eVideoProgressBarRef.current.ChildSetCurrentDate(_date);
    }
  }

  // 切换清晰度
  const changeDefinitionFunc = () => {

    if(isLiveRef.current){
			mediaTypeRef.current = mediaTypeRef.current === 1 ? 2 : 1;
			const {data} = props;
			let _host = 'https://flv.meshare.com/live'
			let physical_id = data.physical_id;
			let tokenid = getLGToken();
			let media_type = mediaTypeRef.current; // 1, 2
			let channel = props.channel;
			let start_time = dayjs().unix();
			let cid = 0;
			let aes_key_has_audio = data.aes_key_has_audio?data.aes_key_has_audio:'';

			let _url = `${_host}?devid=${physical_id}&token=${tokenid}&media_type=${media_type}&channel=${channel}&start_time=${start_time}&cid=${cid}${aes_key_has_audio}`;

			if(physical_id){
				setPlayUrl(() => _url)
			}
		}
  }

  const clipFunc = (v) => {
    setOpenClipVideo(() => v);
  }

  const liveFun = () => {
  	setIsMP4Player(() => false);
		setPlaybackTime(() => 0);
	}

  const fullScreenFunc = () => {

  	if(isMP4Player){
			let mp4_dom = mp4VideoPlayRef.current;
			if(mp4_dom){
				mp4_dom.requestFullscreen();
			}
  		return;
		}

    if(vdieoPlayer){
      vdieoPlayer.fullscreen();
			// setIsFullScreen(() => !isFullScreen)
			//
			// if(!isFullScreen){
			// 	document.querySelector('#gookoeeii').requestFullscreen()
			// }else{
			// 	document.exitFullscreen()
			// }
    }
  }

  const getDateTimeFromVideoListFun = (v, is_cloud=1, mp4_url='') => {

  	// 普通设备， 没有云服务
  	if(is_cloud === 0){
			setIsMP4Player(() => true);
			setMP4PlayerUrl(() => mp4_url);

			setTimeout(() => {
				let mp4_dom = mp4VideoPlayRef.current;
				if(mp4_dom){
					mp4_dom.play();
				}
			}, 500)

  		return;
		}

		setPlaybackTime(v)

		let _date = dayjs(v * 1000).format('YYYY-MM-DD');
		let _date_hour = dayjs(v * 1000).format('YYYY-MM-DD HH');

		if(eVideoProgressBarRef.current){
			eVideoProgressBarRef.current.setCurrentDate(_date);
			eVideoProgressBarRef.current.setCurrentDateHours(_date_hour);
		}
	}

  useEffect(() => {
		// console.log(currentDate);
  }, [currentDate])

	useEffect(() => {
		if(currentPlayingTime){
			// console.log(`当前指针的时间： ${dayjs(currentPlayingTime*1000).format('YYYY/MM/DD HH:mm:ss')}, ---${currentPlayingTime}`)
		}

	}, [currentPlayingTime])

	useEffect(() => {
		// console.log(recordDateArr, 'sdas')

	}, [recordDateArr])

	const clickDateFun = () => {
		if(openDateWin){

		}else{
			if(mouse_clk_flag_a_ref.current === 0){
				onOpenChangeDateFun(true, 1);
			}

		}
	}

	const onOpenChangeDateFun = (v, f=0) => {
		mouse_clk_flag_a_ref.current = 1;
		setOpenDateWin(v);

		setTimeout(() => {
			mouse_clk_flag_a_ref.current = 0;
		}, 500)
	}

	// 日期选择器自定义渲染
	const dateTimeCellRender = (current, info) => {

		let a_date = current.format('YYYY-MM-DD');

		if(recordDateArr.includes(a_date)){
			return <strong className={'a-date-has-recode'}>{current.date()}</strong>
		}

  	if (info.type !== 'date') {
			return info.originNode;
		}

  	return <>{current.date()}</>;
	}


  let _starTime = playbackTime ? playbackTime : currentPlayingTime

  return <>
    <div className={'e-vplayer-ii-container'} ref={videoBoxRef}>
      <div className={`${config.playerBar ? 'e-video-box e-has-player-bar' : 'e-video-box'}`} style={{height: isMP4Player ? '100%' : ''}}>

				<div className={'ii-video-preview-box'} ref={playerBoxRef}>

					{
						!isMP4Player ?
							<div className={'e-video-preview-box'}>
								{
									playUrl && <VideoPreview
                    isMain={true}
                    url={playUrl}
                    physical_id={props.physical_id}
                    width={playerSize.width}
                    height={playerSize.height}
                    pause={pause}
                    volume={volume}
                    setVideoRef={setVdieoPlayer}
                    scaleMode={1}
                    setFps={setFps}
                    playTimeDrive={playTimeDrive}
                    setPlayTime={setPlayTime}
                  />
								}
							</div>
							:
							<div className={'e-video-preview-box'}>
								<video
									ref={mp4VideoPlayRef}
									src={MP4PlayerUrl}
									controls={true}
									autoPlay={true}
									style={{width: '100%', height: '100%', verticalAlign: 'bottom'}}
								></video>
							</div>
					}

					{isMP4Player && <span className={'a-btn-live'} onClick={liveFun}>LIVE</span>}

					{!isMP4Player && <div className={'e-vplayer-control-box'}>
						<div className={'vplayer-control-box'}>
							<span
								className={'v-btn v-btn-player'}
								onClick={playFunc}
								style={{opacity: playing?0.5:1, cursor: playing?'default':'pointer'}}
							>
								{playing ? <IconPause /> : <IconPlay />}
							</span>
							<span className={'v-btn v-btn-sound'} onClick={volumeFunc}>
								{volume === 1 ? <IconSoundOn /> : <IconSoundOff />}
							</span>

							{
								config.calendar && <span className={'v-btn v-btn-calendar'}>
                  <div>
										<DatePicker
											suffixIcon={<img style={{width: '20px', height: 'auto'}} src={IconCalendar} />}
											allowClear={false}
											onChange={changeCurrentDateFunc}
											value={dayjs(currentDate)}
                      cellRender={dateTimeCellRender}
                      open={openDateWin}
                      onOpenChange={onOpenChangeDateFun}
										/>
									</div>
									<div className={'v-dis-date'} style={{minWidth: '42px'}} onClick={clickDateFun}>
										{currentDate && <>{dayjs(`${currentDate} 00:00:00`).format('MMM DD')}</>}
									</div>
								</span>
							}

							{
								config.duration && <span className={'v-btn v-btn-duration'} onClick={clickFunc}>
									<i className={`${data.type !== 'MIN'?'v-act':''}`}>HR</i>
									<i className={`${data.type === 'MIN'?'v-act':''}`}>MIN</i>
								</span>
							}

							{
								config.definition && <span className={'v-btn v-btn-definition'} onClick={changeDefinitionFunc}>
									<i className={`${mediaTypeRef.current === 1 && 'v-act'}`}>LD</i>
									<i className={`${mediaTypeRef.current === 2 && 'v-act'}`}>HD</i>
								</span>
							}

							{
								isLiveRef.current ? null : <span className={'v-btn v-btn-live'} onClick={liveFun}>LIVE</span>
							}
						</div>
						<div className={'vplayer-display-box'}></div>
						<div className={'vplayer-option-box'}>
							{config.clip && <span className={'v-btn v-btn-montage'} onClick={() => clipFunc(true)}>
                <img src={IconClip} />
            </span>}
							{config.fullscreen && <span className={'v-btn v-btn-full'} onClick={fullScreenFunc}><IconFull /></span>}
						</div>

						<div className={'vp-win-area'}>
							{openClipVideo && <ClipVideo
                startTime={_starTime}
                closeWin={clipFunc}
                open={openClipVideo}
                physical_id={deviceInfo.physical_id}
              />
							}
						</div>
					</div>}
				</div>
				<div className={`ii-play-list-box ${showPlayList?'':'ii-play-list-hide'}`}>
					<PlayListMD
						showClose={true}
						offset_seconds={deviceInfo?.time_zone_offset}
						physical_id={props.physical_id}
						onChange={setShowPlayList}
						open={showPlayList}
						onClick={getDateTimeFromVideoListFun}
						playbackTime={playbackTime}
					/>
				</div>



        {/*<VideoWebRTCPlayer physical_id={deviceInfo.physical_id} />*/}
      </div>

			{/*/!*回放时间获取*!/*/}
			{/*setPlaybackTime={setPlaybackTime}*/}
			{/*/!*设置播放器播放的视频日期*!/*/}
			{/*setCurrentDateToPlayer={setCurrentDate}*/}
			{/*/!*播放器播放的视频日期*!/*/}
			{/*playerCurrentDate={currentDate}*/}
			{/*/!*进度条的DOM*!/*/}
			{/*onRef={eVideoProgressBarRef}*/}
			{/*/!*播放的视频帧数*!/*/}
			{/*videoFPS={fps}*/}
			{/*/!*获取当前的播放时间*!/*/}
			{/*playerGetCurrentPlayingTime={setCurrentPlayingTime}*/}

      {
        config.playerBar && !isMP4Player && <div className={'e-video-bar-box'}>
          <VideoProgressBar
            {...data}
            deviceInfo={deviceInfo}
            runPlayTime={playTime}
            setPlaybackTime={setPlaybackTime}
            setCurrentDateToPlayer={setCurrentDate}
            playerCurrentDate={currentDate}
            onRef={eVideoProgressBarRef}
            videoFPS={fps}
            playerGetCurrentPlayingTime={setCurrentPlayingTime}
            playbackTime={playbackTime}
            setRecordDate={setRecordDateArr}
            channel={props.channel}
            width={wWidth}
          />
        </div>
      }
    </div>
  </>
}
