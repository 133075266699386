import {Toast} from "antd-mobile";
import NAPI from "../../utils/NAPI";
import {useEffect, useState} from "react";
import {isFunction} from "../../utils/common";

export default function WaitingToken(props) {
	// const [loading, setLoading] = useState<boolean>(false);
	const {status, change} = props;

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
console.log('tokenCallback', v);
		if(isFunction(change)){
			change(false)
		}
	}

	const getToken = () => {
		console.log('WaitingToken getToken')
		if(NAPI.check()){
			// setLoading((() => true))
			NAPI.apptodo('getToken', {}, 'tokenCallback');
			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	useEffect(() => {
		if(status){
			getToken();
		}
	}, [status])

	return <>

	</>
}
