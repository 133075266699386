import "./index.less"
import {useEffect, useRef, useState} from 'react';
import HeadNav from "../../../components/headNav"
import ContentItem from "../../../components/content-item"
import {getRecentRecord} from "../../../server/record";
import {getLGToken, isJSON} from "../../../utils/common";
import dayjs from "dayjs";

import fileType from "../../../utils/fileType";
import {DotLoading, InfiniteScroll, Input, Toast} from "antd-mobile";
import getApiUrl from "../../../server/apiUrl";
import {getStorageInformation} from "../../../server/storage";
import token from "../../../utils/token";
import jumpPage from "../../../utils/jumpPage";
import {useNavigate} from "react-router-dom";
import DetailsModal from "../../../components/details-modal";
import NAPI from "../../../utils/NAPI";

const imageUrl = getApiUrl('cloudDisk');
const icon_folder = '/res/icons/icon_folder.svg';

const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
	return (
		<>
			{hasMore ? (
				<>
					<span>Loading</span>
					<DotLoading />
				</>
			) : (
				// <span>--- End ---</span>
				<></>
			)}
		</>
	)
}

function Recent() {

	const [list, setList] = useState<any[]>([])
	const [isShow, setIsShow] = useState<boolean>(false)
	const [isSortBy, setIsSortBy] = useState<boolean>(false)
	const [recordList, setRecordList] = useState<any>([]); // 记录列表
	const [recordListDisplay, setRecordListDisplay] = useState<any>([]); // 显示的的列表，转换后的列表
	const [recordDate, setRecordDate] = useState<any>([]);
	const [openItemDetails, setOpenItemDetails] = useState<boolean>(false)
	const [detailsObj, setDetailsObj] = useState<any>({})
	const [isSearchMode, setIsSearchMode] = useState(false);
	const [searchValue, setSearchValue] = useState('')
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})

	const [pageInfo, setPageInfo] = useState<any>({
		page: 0,
		pageSize: 10 * 3,
		orderBy: 'createTime',
		order: 'desc', //Asc:顺序 Desc:逆序
	})
	const [loadMoreFlag, setLoadMoreFlag] = useState<boolean>(false);

	const timeoutRef:any = useRef(0);
	const dateKeysRef = useRef([]); // 记录每天的日期
	const page_numberRef = useRef(0); //页码
	const loadMoreRef = useRef(false);

	const navigate = useNavigate()

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if(NAPI.check()){
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	const handleSortBy = () => {
		setIsSortBy(() => !isSortBy)
	}

	const handleList = () => {
		setIsShow(() => !isShow)
	}

	const handleMore = (item: any) => {
		setOpenItemDetails(() => true)
		setDetailsObj(() => item)
	}

	const handleSearch = () => {
		setIsSearchMode(() => true)
	}

	const closeSearchMode = () => {
		setIsSearchMode(() => false)
		getRecordList(0)
	}

	const initData = async () => {
		await getDiskStorageSize();
		getRecordList(0);
	}

	const getDiskStorageSize = async () => {
		let res:any = await getStorageInformation();
		let {data, result} = res;
		if(result === 'ok' && data){

			if(isJSON(data)){
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id,
					backup_id: data.backup_id,
				}

				if(user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id){
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data)
			}
		} else if(Number(result) === 401){
			navigate('/login')
		}
	}

	const getRecordList = (_page=0) => {console.log(searchValue);
		let req = {
			...pageInfo,
			page: _page,
		}

		if(searchValue){
			req['key'] = searchValue;
		}
		const token = getLGToken()
		getRecentRecord(req).then((res: any) => {
			let {data, result} = res;
			if (result === 'ok' && data) {

				let _tday = dayjs().format('MMM DD, YYYY');
				let _yday = dayjs().subtract(1, 'day').format('MMM DD, YYYY');

				if(isJSON(data)){
					let records = Array.isArray(data.content) ? data.content : [];
					let _onePageData = {
						number_of_elements: data['number_of_elements'],
						page_number: data['page_number'],
						total_elements: data['total_elements'],
						total_pages: data['total_pages'],
					}

					records.map((item:any) => {
						item.dateStr = dayjs(item.create_time).format('MMM DD, YYYY');

						if(item.dateStr === _tday){
							item.dateStr = 'Today'
						}else if(item.dateStr === _yday){
							item.dateStr = 'Yesterday'
						}

						if(!dateKeysRef.current.includes(item.dateStr)){
							dateKeysRef.current.push(item.dateStr)
						}

						item.icon = icon_folder //item.data_type === 1 ? icon_folder : fileType[item?.file?.type]['icon'];
						if(item.data_type === 2){
							item.icon = fileType[2]['icon']
							if(isJSON(item.file)){
								if(((item.file.type === 3 && item.file.cover) || (item.file.type === 1 && item.file.cover)) && (Number(item.file.status) !== 0) ){
									item.icon = `${imageUrl}/disk/download_file?url=${item.file.cover}&name=${item.file.name}&token=${token}`;
								}else if(item.file.type === 1 && item.file.cover && (Number(item.file.status) !== 0)){
									item.icon = `${imageUrl}/disk/download_file?url=${item.file.cover}&name=${item.file.name}&token=${token}`;
								}
								item.url = item.file.url
								// console.log(item)
							}
						}

						if(item.data_type === 3 && item.cover){
							item.icon = `${imageUrl}/disk/download_file?url=${item.file.cover}&name=${item.file.name}&token=${token}`;
						}

						item._rid = item.id;
						item.id = item.data_id;

						if(isJSON(item.file)){
							item.type = item.file.type;
							item.ext = item.file.ext;
							item.type = item.file.type;
						}

						item.disabled = item.delete;

						//eventType （1：创建/上传；2：重命名）
						// dataType （1：文件夹；2：文件）
						let _arr = {
							'1-1': 'Create on',
							'1-2': 'Upload on',
							'2-1': 'Modify on',
							'2-2': 'Modify on',
						}
						let _str = _arr[`${item?.event_type}-${item?.data_type}`]
						item.content = `${_str} ${dayjs(item.create_time).format('MMM DD, YYYY')}`

						return item;
					})

					if(_page === 0){
						setRecordList((prev) => [].concat(records));
					}else{
						setRecordList((prev) => prev.concat(records));
					}


					// 已加载完成
					if(_onePageData.page_number + 1 >= _onePageData.total_pages || _onePageData.total_pages === 0){
						setLoadMoreFlag(() => false)
					}else{
						// getRecordList(_page + 1)
						setLoadMoreFlag(() => data.page_number < data.total_pages)
					}

					// setLoadMoreFlag(() => data.page_number < data.total_pages)
					page_numberRef.current = data.page_number;

					setTimeout(() => {
						loadMoreRef.current = true;
					}, 250)
				}
			}
		}).catch(e => {
			console.log(e)
			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			initData();
		}, 200)

		return () => {
			clearTimeout(timeoutRef.current);
		}
	}, [])

	useEffect(() => {
		if(isSearchMode){
			clearTimeout(timeoutRef.current);
			timeoutRef.current = setTimeout(() => {
				getRecordList(0);
			}, 200)
		}
	}, [searchValue, isSearchMode])

	const openRowFunc = (o) => {
		if(o.delete) return;
		if(isJSON(o.file)){
			if(Number(o.file.status) === 0) return;
		}


		if(o.data_type === 1){
			openFolder(o);
		}else if(o.data_type === 2){
			if(Number(o.file.status) !== 0) {
				openFile(o);
			}
		}
	}

	const openFolder = (o) => {
		jumpPage(navigate, `/vault?id=${o.data_id}&type=${o.type}`);
	}

	const openFile = (o) => {

		if(isJSON(o)){
			jumpPage(navigate, `/file?id=${o.file.id}&type=${o.file.type}`)
		}

	}

	const changeSearchValue = (v) => {
		setSearchValue(() => v);
	}

	const reqData = (v, o, action='') => {

		if(v){
			let _iFolder = [];
			let _iFile = [];
			if(isJSON(o)){
				if(o.type === undefined){
					_iFolder.push(o);
				}else{
					_iFile.push(o);
				}

			}else if(Array.isArray(o)){
				for(let item of o){
					if(item.type === undefined){
						_iFolder.push(item);
					}else{
						_iFile.push(item);
					}
				}
			}


			if(action === 'delete'){
				removeItemFromList(_iFolder, _iFile)
			}else if(action === 'rename'){
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			}else if(action === 'collect'){
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			}
		}
	}

	const removeItemFromList = (_folder:any, _file:any) => {
		let _mArr = [..._folder, ..._file];
		let _recordList = JSON.parse(JSON.stringify(recordList));
		let _arr = [];
		for(let item of _recordList){
			for(let o  of _mArr){
				if((o.type === item.type && o.id === item.id)){
					item.delete = true;
					if(isJSON(item.file)){
						delete item.file
					}
				}

				_arr.push(item);
			}
		}
		setRecordList(() => _arr)
	}

	const updateList = (_folder:any, _file:any) => {
		let _mArr = [..._folder, ..._file];
		let _recordList = JSON.parse(JSON.stringify(recordList));
		let _arr = [];
		for(let item of _recordList){
			for(let o  of _mArr){
				if(o.type === item.type && o.id === item.id){
					item = o;
				}
			}
			_arr.push(item);
		}

		setRecordList(() => _arr)
	}

	useEffect(() => {
		transformList(recordList);
	}, [recordList])

	const transformList = (_list) => {

		let _aList = {}
		for(let dt of dateKeysRef.current){
			_aList[dt] = []
		}

		for(let item of _list){
			_aList[item.dateStr].push(item);
		}

		setRecordListDisplay(() => _aList);
		setRecordDate(() => dateKeysRef.current);
	}

	// 生成日期列表
	const dateListElement = () => {
		return recordDate.map((item:any, index:number) => {
			let _list = recordListDisplay[item] ? recordListDisplay[item] : []

			return <div key={index}>
				<div className="content-title">
					<div>
						<h4>{item}</h4>
					</div>
				</div>
				<ContentItem
					list={_list}
					isShow={isShow}
					onHandleMore={handleMore}
					onClick={openRowFunc}
				/>
			</div>
		})
	}


	const loadMoreFunc = async () => {

		if(loadMoreRef.current){
			loadMoreRef.current = false;

			page_numberRef.current = page_numberRef.current + 1;
			getRecordList(page_numberRef.current);
		}
	}

	const searchDom = () => {
		return <div className={'-search-box'}>
			<span className={'-btn'}>
				<img src={'/res/icons/icon_close.svg'} style={{width: '15px'}} alt="" onClick={closeSearchMode} />
			</span>
			<div className={'-sch'}>
				<span className={'sh-icon'}><img src="/res/icons/icon_search2.svg" alt=""/></span>
				<Input clearable={true} onChange={changeSearchValue} />
			</div>
		</div>
	}

	return (
		<div className="recent-page">
			<div className={'-head'}>
				{
					isSearchMode ? searchDom() : <HeadNav
						title="Recent"
						endElement={
							<span onClick={handleSearch}>
						<img src="/res/icons/icon_search2.svg" style={{width: "20px"}} alt=""/>
					</span>}
					/>
				}
			</div>
			<div className={'-body'}>
				<div className="content">
					{
						dateListElement()
					}

					<div className={'bottom-flag'}>
						<InfiniteScroll
							loadMore={loadMoreFunc}
							hasMore={loadMoreFlag}
						>
							<InfiniteScrollContent hasMore={loadMoreFlag} />
						</InfiniteScroll>
					</div>

				</div>
			</div>


			<DetailsModal
				type={"file"}
				visible={openItemDetails}
				onVisible={setOpenItemDetails}
				data={detailsObj}
				reqData={reqData}
				func={'record'}
				cloudDiskInfo={cloudDiskInfo}
			/>
		</div>
	)
}

export default Recent
