const lang = {
  "name": 'Zmodo Web App',
  "sign": {
    "welcome": "Welcome to Zmodo",
    "description_in": "Sign in with the same username and credentials as <br/>your Zmodo App.",
    "description_up": "By continuing, I agree to Zmodo's {terms_of_service} <br />and {privacy_policy}."
  },
  "note": {
    "terms_of_service": "<a href='https://www.zmodo.com/terms-of-services/' target='_blank'>Terms of Service</a>",
    "privacy_policy": "<a href='https://www.zmodo.com/privacy-policy/' target='_blank'>Privacy Policy</a>",
    "copy": "Copyright © {year} Zmodo. All right reserved"
  }
}

export default lang;
