import './index.less'
import SecurityPlanPlus from '../../assets/images/cloudPlan/security_plan_plus.png'
import Device1 from '../../assets/images/cloudPlan/device1.png'
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from 'antd'
import RmaModal from './components/rmaModal'
import ActivateCloudModal from './components/activateCloudModal'
import { useEffect, useState } from 'react';
import _ from 'lodash';

const MyOrders = () => {
    const navigate = useNavigate();
    const [isRmaModal, setIsRmaModal] = useState(false)
    const [isActivateCloudModal, setIsActivateCloudModal] = useState(false)
    const [ordersList, setOrdersList] = useState([
        { id: "1", name: "My Orders", active: true },
        { id: "2", name: "Pending for Payment", active: false },
        { id: "3", name: "Shipping", active: false },
        { id: "4", name: "Finished", active: false },
        { id: "5", name: "RMA", active: false },
    ])
    const [current, setCurrent] = useState<string>("1")
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const id = params.get('id')
    console.log(id, '26')

    const handleRma = () => {
        setIsRmaModal(true)
    }

    const handleActivateCloud = () => {
        setIsActivateCloudModal(true)
    }

    const handleOrders = (it: any) => {
        const newList = _.cloneDeep(ordersList)
        navigate(`/my-orders?id=${it.id}`);
        newList.map((item: any) => {
            if (item.id === it.id) {
                item.active = true
            } else {
                item.active = false
            }
            return item
        })
        setCurrent(it.id)
        setOrdersList(newList)
    }

    // const handleViewDetails = () => {
    //     navigate('/my-orders-details?id=1');
    // }

    const handleCheckOut = () => {
        navigate('/cloud-plan?id=1');
    }

    const handleViewDetails = () => {
        navigate('/my-orders-details?id=1');
    }
   
    useEffect(() => {
       if (id) {
        setCurrent(id)
       }
    }, [id])




    return (
        <>
            <div className='my-orders'>
                <div className='my-orders-title'>
                    My Orders
                </div>
                <div className='my-orders-tab'>
                    {
                        ordersList.map((item: any) => {
                            return <p key={item.id} className={(current === item.id) ? 'active' : ''} onClick={() => handleOrders(item)}>{item.name}</p>
                        })
                    }
                </div>
                {
                    current === "1" && <>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408270001</p>
                                <p>Finished</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    <Button className='marginRight'>Invoice</Button>
                                    <Button onClick={handleActivateCloud}>Activate Cloud</Button>
                                </div>
                            </div>
                        </div>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408279024</p>
                                <p className='active'>Shipping</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>
                                    <div className='my-orders-card-con-one-content' style={{ marginTop: "24px" }}>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p><span>$39.99</span>$0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    <Button className='marginRight'>Invoice</Button>
                                    <Button className='marginRight'>Track Shipping</Button>
                                    <Button onClick={handleActivateCloud}>Activate Cloud</Button>
                                </div>
                            </div>
                        </div>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408270152</p>
                                <p>Finished</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Annual</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-orders-card-con-two '>
                                    <Button className='marginRight'>Invoice</Button>
                                    <Button onClick={handleRma}>RMA</Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    current === "2" && <>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408270001</p>
                                <p className='active'>Pending for Payment</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    {/* <Button className='marginRight'>View Details</Button> */}
                                    <Button onClick={handleCheckOut} className='check-out'>Check Out</Button>
                                </div>
                            </div>
                        </div>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408279024</p>
                                <p className='active'>Pending for Payment</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>
                                    <div className='my-orders-card-con-one-content' style={{ marginTop: "24px" }}>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p><span>$39.99</span>$0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    {/* <Button className='marginRight'>View Details</Button> */}
                                    <Button onClick={handleCheckOut} className='check-out'>Check Out</Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    current === "3" && <>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408279024</p>
                                <p className='active'>Shipping</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>
                                    <div className='my-orders-card-con-one-content' style={{ marginTop: "24px" }}>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p><span>$39.99</span>$0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    <Button className='marginRight'>Invoice</Button>
                                    <Button className='marginRight'>Track Shipping</Button>
                                    <Button onClick={handleActivateCloud}>Activate Cloud</Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    current === "4" && <>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408270001</p>
                                <p>Finished</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    <Button className='marginRight'>Invoice</Button>
                                    <Button className='marginRight' onClick={handleViewDetails}>View Details</Button>
                                    <Button onClick={handleActivateCloud}>Activate Cloud</Button>
                                </div>
                            </div>
                        </div>

                    </>
                }
                {
                    current === "5" && <>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408270001</p>
                                <p>Finished</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    <Button className='marginRight' onClick={handleViewDetails}>View Details</Button>
                                    <Button onClick={handleActivateCloud}>Re-purchase</Button>
                                </div>
                            </div>
                        </div>
                        <div className='my-orders-card-one'>
                            <div className='my-orders-card-title'>
                                <p>Order #: SO202408279024</p>
                                <p className='active'>Shipping</p>
                            </div>
                            <div className='my-orders-card-con'>
                                <div className='my-orders-card-con-one'>
                                    <div className='my-orders-card-con-one-content'>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p>$39.99</p>
                                        </div>
                                    </div>
                                    <div className='my-orders-card-con-one-content' style={{ marginTop: "24px" }}>
                                        <div className='my-orders-card-con-one-l'>
                                            <div>
                                                <img src={SecurityPlanPlus} alt="" />
                                            </div>
                                            <div>
                                                <p>Security Plan: Plus+Single Camera+Monthly</p>
                                                <p>Date: 30/07/2024</p>
                                            </div>
                                        </div>
                                        <div className='my-orders-card-con-one-r'>
                                            <p><span>$39.99</span>$0</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-orders-card-con-two my-orders-card-con-two-active'>
                                    <Button className='marginRight' onClick={handleViewDetails}>View Details</Button>
                                    <Button onClick={handleActivateCloud}>Re-purchase</Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <RmaModal open={isRmaModal} onOpen={setIsRmaModal} />
            <ActivateCloudModal open={isActivateCloudModal} onOpen={setIsActivateCloudModal} />
        </>
    )
}

export default MyOrders