// 打开cloud订阅
import {create} from "zustand";

const useAccountBillingHistoryStore = create(set => ({
  accountBillingHistoryZU: {open: false, data: {}},
  setAccountBillingHistoryZU: (accountBillingHistoryZU) => set({accountBillingHistoryZU})
}))

export {
  useAccountBillingHistoryStore
}
