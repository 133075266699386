/* eslint-disable */

import axios from "axios";
import {getLGToken} from "../../utils/common";

const url_arr = [
	'https://11-alarm-mop.meshare.com',
	'https://12-alarm-mop.meshare.com',
	'https://13-alarm-mop.meshare.com',
	'https://14-alarm-mop.meshare.com'
]

const url_arr_dev = [
	'https://11-alarm-mop.meshare.cn',
]

const getUrl = () => {
	let env = process.env.NODE_ENV; // development
	let uArr:any = env === 'development' ? url_arr_dev : url_arr;
	// uArr = url_arr_dev;
	return getRandomElementFromArray(uArr);
}

const getRandomElementFromArray = (array) => {
	if (array && array.length > 0) {
		// 生成一个随机索引，范围是数组长度减一
		const randomIndex = Math.floor(Math.random() * array.length);
		// 使用该索引从数组中取出元素并返回
		return array[randomIndex];
	} else {
		return null; // 如果数组为空或未定义，则返回null或其他默认值
	}
}

let apiUrl = getUrl();
let _token = getLGToken();

export default class DeviceAlertApi{

	// 获取设备的告警信息 min_time
	getAlertList(data:any={}){
		_token = getLGToken();
		//physical_id='', max_time=0, count=100, main_type=1
		let physical_id = data?.physical_id;
		let max_time = data?.max_time ? data?.max_time : 0;
		let count = data?.count ? data?.count : 999;
		let main_type = data?.main_type ? data?.main_type : 1;
		let min_time = data?.min_time ? data?.min_time : 0;

		let _path = 'message/search';
		let _url = `${apiUrl}/${_path}?token=${_token}&max_time=${max_time}&count=${count}&main_type=${main_type}`;
		if(physical_id){
			_url = `${_url}&physical_id=${physical_id}`
		}

		if(min_time){
			_url = `${_url}&min_time=${min_time}`
		}

		return new Promise((resolve, reject) => {
			axios.get(_url).then(({data}) => {
				if(data.result === 'ok'){
					let arr = data?.data;
					resolve(arr)
				}
			}).catch((e) => {
				console.log(e)
				resolve([])
			})
		})
	}

//	读告警
	readAlert(id, read=1){
		_token = getLGToken();
		let _path = 'message/update';
		let _url = `${apiUrl}/${_path}`

		let req = {
			token: _token,
			id: id,
			read: 1,
		}

		let headers = {
			"Content-Type": "application/x-www-form-urlencoded"
		}

		return new Promise((resolve, reject) => {
			axios.post(_url, req, {headers}).then(({data}) => {
				resolve(data.result === 'ok');
			}).catch((e) => {
				console.log(e)
				resolve(false)
			})
		})
	}

//	删除告警
	removeAlert(ids){
		_token = getLGToken();
		let _path = 'message/delete';
		let _url = `${apiUrl}/${_path}`;

		let req = {
			token: _token,
			id: ids,
		}

		let headers = {
			"Content-Type": "application/x-www-form-urlencoded"
		}

		return new Promise((resolve, reject) => {
			axios.post(_url, req, {headers}).then(({data}) => {
				resolve(data.result === 'ok');
			}).catch((e) => {
				console.log(e)
				resolve(false)
			})
		})
	}
}
