import { useState } from 'react';
import { Popup } from 'antd-mobile'
import './index.less'


function TrashDetailsModal(props) {
	const { visible, onVisible, detailsObj, type, onAction, cloudDiskInfo = {}, init, onIsFileDetailsModal, positionObj, onCurrentBg, onIsAllNum } = props

	const [itemNum, setItemNum] = useState<number>(0)
	
	const handleItem = (num: number) => {
		setItemNum(num)
		if (num === 3) {
			onAction('delete')
			onVisible(false)
			onIsAllNum(1)
		} else if (num === 1) {
			onAction('restore')
			onVisible(false)
			// onIsFileDetailsModal(false)
		} else if (num === 2) {
			// onAction('detail')
			onIsFileDetailsModal(true)
			onVisible(false)
		}
		setTimeout(() => {
			setItemNum(0)
		}, 500)
	}

	let _notEnoughSpace = Number(cloudDiskInfo.usable) < 1;




	return <Popup
		className="details-popup-con"
		visible={visible}
		onMaskClick={() => {
			onVisible(false)
			onCurrentBg(0)
		}}
		onClose={() => {
			onVisible(false)
			onCurrentBg(0)
		}}
		bodyStyle={{
			width: "250px",
			top: `${positionObj?.top}px`,
			left: `${positionObj?.left}px`,
		}}

	>
		{/* <div className="popup-title">
			<div>
				<img src={detailsObj?.icon} alt=""/>
			</div>
			<div>
				<h5>{detailsObj?.name}</h5>
				<p>{detailsObj?.content}</p>
			</div>
		</div> */}
		{
			type === "picture" && <>
				{/* <div className={itemNum === 7 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(7)}>
          <img src="/res/icons/icon_share.svg" alt="" style={{width: "20px"}}/>
          <p>Share</p>
        </div> */}
				<div className={itemNum === 8 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(8)}>
					<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px" }} />
					<p>Save to Device</p>
				</div>
				<div className="underline"></div>
			</>
		}

		<div
			className={itemNum === 1 ? "popup-item popup-item-active" : "popup-item"}
			onClick={() => handleItem(1)}
			style={{ opacity: `${_notEnoughSpace ? 0.65 : 1}` }}
		>
			<img src="/res/icons/icon_restore.svg" alt="" style={{ width: "24px" }} />
			<p>Restore</p>
		</div>
		{/* <div className="underline"></div> */}
		<div className={itemNum === 2 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(2)}>
			<img src="/res/icons/icon_detail_v2.svg" alt="" style={{ width: "22px" }} />
			<p>File information</p>
		</div>
		<div className={itemNum === 3 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(3)}>
			<img src="/res/icons/icon_trash_v2.svg" alt="" style={{ width: "16px" }} />
			<p>Permanently Remove</p>
		</div>
		
	</Popup>
}

export default TrashDetailsModal
