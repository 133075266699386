const dev_url = {
	PFMng: [
		'https://11-mng-mop.meshare.cn',
		// 'https://12-mng-mop.meshare.cn'
	],
	cloudDisk: [
		'https://12-vdr-mop.meshare.cn',
		'https://12-vdr-mop.meshare.cn'
	],
	WEBPay: [
		'https://store-test.smartz.cloud',
	],
	PFApp: [
		'https://11-app-mop.meshare.cn'
	],
	downloadZip: [
		'https://12-vdr-mop.meshare.cn',
	]
}
const pro_url = {
	PFMng: [
		'https://11-mng-mop.meshare.com',
		'https://11-mng-mop.meshare.com'
	],
	cloudDisk: [
		'https://11-vdr-mop.meshare.com',
		'https://11-vdr-mop.meshare.com'
	],
	WEBPay: [
		'https://houtai.smartz.cloud',
	],
	PFApp: [
		'https://11-app-mop.meshare.com'
	],
	downloadZip: [
		'https://11-vdr-folder.meshare.com',
	]
}


function getRandomIndex(n){
	// Math.random()
	return Math.floor(Math.random() * n);
}

export default function getApiUrl(v){
	let env = process.env.NODE_ENV; // development
	let uArr:any = env === 'development' ? dev_url : pro_url;
	let _url = '';

	if(v){
		uArr = uArr[v] !== undefined ? uArr[v] : [''];
		let randomIndex = getRandomIndex(uArr.length);
		_url = uArr[randomIndex];
	}
	return _url
}

