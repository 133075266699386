import {Button, Row, Col, Modal} from 'antd';
import './index.less'
import {useNavigate} from "react-router-dom";
import PhotoStorage from '../../../../assets/images/vault/photo_storage.png'
import Sharing from '../../../../assets/images/vault/sharing.png'
import FilePreview from '../../../../assets/images/vault/file_preview.png'
import Intelligent from '../../../../assets/images/vault/Intelligent.png'
import DataSync from '../../../../assets/images/vault/data_sync.png'
import CloudBackup from '../../../../assets/images/vault/cloud_backup.png'

import {IconVaultPlan_1, IconVaultPlan_2, IconVaultPlan_3, IconVaultPlan_4, IconVaultPlan_5, IconVaultPlan_6} from '../../../../pages/cloudPlan/iconsMap'
import Icon from "@ant-design/icons";
import React from "react";


function NewAccountModal(props) {

	const {
		open,
		onOpen,
		position
	} = props

	const navigate = useNavigate();


	const handleOk = () => {
		navigate('/cloud-plan')
		onOpen(false)
	}

	const handCancle = () => {
		onOpen(false)
	}


	return <Modal
		open={open}
		destroyOnClose={true}
		className='new-account-modal'
		centered
		onOk={handleOk}
		onCancel={() => onOpen(false)}
		footer={null}
		getContainer={position}
	>
		<div className='prompt'>
			{/* <div className='title'>Notice</div> */}
			<img src="/res/icons/valut_tips.svg" alt="" />
			<p>You have no Vault storage yet.Subscribe now<br/>and get more features from the Vault</p>
		</div>
		<div className='prompt-con'>
			<div className='cloud-features-con'>
				<Row gutter={40}>
					<Col span={8}>
						<div className='cloud-features-con-card'>
							<p><Icon component={IconVaultPlan_1} style={{fontSize: "30px"}}/></p>
							<p>Camera Roll Backup</p>
						</div>
					</Col>
					<Col span={8}>
						<div className='cloud-features-con-card'>
							<p><Icon component={IconVaultPlan_2} style={{fontSize: "30px"}}/></p>
							<p>File Storage</p>
						</div>
					</Col>
					<Col span={8}>
						<div className='cloud-features-con-card'>
							<p><Icon component={IconVaultPlan_3} style={{fontSize: "30px"}}/></p>
							<p>File Preview</p>
						</div>
					</Col>
					<Col span={8} style={{marginTop: "24px"}}>
						<div className='cloud-features-con-card'>
							<p><Icon component={IconVaultPlan_4} style={{fontSize: "30px"}}/></p>
							<p>Muti-platform Access</p>
						</div>
					</Col>
					<Col span={8} style={{marginTop: "24px"}}>
						<div className='cloud-features-con-card'>
							<p><Icon component={IconVaultPlan_5} style={{fontSize: "30px"}}/></p>
							<p>Sharing</p>
						</div>
					</Col>
					<Col span={8} style={{marginTop: "24px"}}>
						<div className='cloud-features-con-card'>
							<p><Icon component={IconVaultPlan_6} style={{fontSize: "30px"}}/></p>
							<p>Bank-level Security</p>
						</div>
					</Col>
				</Row>
			</div>
		</div>
		<div className='prompt-footer'>
			<Button onClick={handCancle}>Maybe Later</Button>
			<Button onClick={handleOk}>Subscribe</Button>
		</div>
	</Modal>
}

export default NewAccountModal
