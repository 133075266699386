import '../index.less';
import DeviceCenterModuleMemories from "../modules/Memories";

const  Memories = () => {
	return <>
		<div className={'page-device-center '}>
			<div className={'main-container'}>
				<p className={'h1-title'}>Memories</p>
				<div>
					<DeviceCenterModuleMemories />
				</div>
			</div>
		</div>
	</>
}


export default Memories
