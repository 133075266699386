import './index.less'
import React, { useState } from 'react';
import { Row, Col, Button, Form, Input, DatePicker, Select, Drawer } from 'antd';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'


import Icon from "@ant-design/icons";

const { TextArea } = Input;

const RmaModal = (props) => {
    const { open, onOpen } = props;
    const [RmaForm] = Form.useForm();
    const [InformationForm] = Form.useForm();
    
    const handleSubmit = () => {
        onOpen(false);
    }


    const onClose = () => {
        onOpen(false);
    };
    return <Drawer title="Basic Drawer" placement="right" width={776} className='rma-modal' onClose={onClose} open={open}>
        <div className='details-modal-title'>
            <p></p>
            <p>RMA</p>
            <p onClick={onClose}>
                <Icon component={CloseIcon} style={{ fontSize: "14px" }} />
            </p>
        </div>
        <div className='rma-modal-txt'>
            <p>Order #: <span>SO202408270001</span></p>
            <p>SKU #: <span>SH001C</span></p>
            <p>Quantity: <span>2PCS</span></p>
        </div>
        <div className='rma-from'>
            <Form
                form={RmaForm}
            >
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label="" name="sku">
                            <Input placeholder='Return SKU#' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="quantity">
                            <Input placeholder='Return Quantity' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="reason">
                            <Select value="RMA Reason" placeholder="RMA Reason">
                                <Select.Option value="RMA Reason" >RMA Reason</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="" name="type">
                            <Select value="RMA Type" placeholder="RMA Type">
                                <Select.Option value="RMA Type" >RMA Type</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="" name="cardCode">
                            <TextArea style={{ height: "120px" }} placeholder='Describe the issue or reason for return' rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
        <div className='information'>
            <div className='information-title'>
                Contact Information:
            </div>
            <div className='information-form'>
                <Form
                    form={InformationForm}
                >
                    <Row gutter={12}>
                        <Col span={8}>
                            <Form.Item label="" name="firstName">
                                <Input placeholder='First Name' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="" name="lastName">
                                <Input placeholder='Last Name' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="" name="email">
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="" name="address">
                                <Input placeholder='Return Address' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="" name="city">
                                <Input placeholder='City' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="" name="state">
                                <Input placeholder='State' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="" name="region">
                                <Select value="Country/Region" placeholder="Country/Region">
                                    <Select.Option value="Country/Region" >Country/Region</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="" name="code">
                                <Input placeholder='Zip Code' />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item label="" name="telephone">
                                <Input placeholder='Telephone' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
        <div className='rma-footer'>
            <Button onClick={handleSubmit}>Submit</Button>
        </div>


    </Drawer>;
}

export default RmaModal;