import getApiUrl from "../apiUrl";
import requsetData from "../request";

const url = getApiUrl('cloudDisk');

// 存储信息
export function getStorageInformation(){
	return requsetData(`${url}/vdr/storage`, {})
}

// 根据文件类型获取存储信息
export function getStorageStatInformation(type){
	return requsetData(`${url}/vdr/storage-stat?type=${type}`, {})
}


//获取网盘过期的提示消息
export function getMSGExpire(){
	return requsetData(`${url}/vdr/tip-msg`, {})
}
