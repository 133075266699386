import "./index.less"
import { useEffect, useRef, useState } from 'react';
// import HeadNav from "../../../components/headNav"
// import HeadNavIII from "../../../components/headNavIII"
import ContentItem from "../../../components/content-item"
import { createFolder, getFolderContent, getFolderDetails, deleteFileList } from "../../../server/folder";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import usePlatform from "../../../utils/platform";
import { getLGToken, getUserBasicCloudDisk, getUserBasicFolderIDS, isJSON, sizeFormat } from "../../../utils/common";
import dayjs from "dayjs";
import fileType from "../../../utils/fileType";
import {
	AutoCenter,
	Checkbox,
	Dialog,
	Modal,
	Popup,
	PullToRefresh, Space, Toast
} from "antd-mobile";
import { Input, message } from 'antd';
import jumpPage from "../../../utils/jumpPage";
import MeDriveModal from "../meDrive/components/me-drive-modal";
// import SortByModal from "../meDrive/components/sort-by-modal";
import CreateNewFolder from "../meDrive/components/create-new-folder";
import DetailsModal from "../../../components/details-modal";
import NAPI from "../../../utils/NAPI";
// import turn = CSS.turn;
import token from "../../../utils/token";
import getApiUrl from "../../../server/apiUrl";
import { getStorageInformation } from "../../../server/storage";
import { setSettingConfig } from "../../../server/setting";
import { getLargeFile } from "../../../server/folder";
import fileTypeIcon from "../../../utils/fileTypeIcon";
import FileDetailsModal from "../components/fileDetailsModal";
import DeleteModal from "../../../components/delete-modal";
import TipsModal from "../../../components/tips-modal";
import { dateTimeFormat } from "../../../utils/common";
import Navigation from "../meDrive/components/navigation"
import _ from 'lodash';

// const icon_folder = '/res/icons/icon_folder.svg';
const imageUrl = getApiUrl('cloudDisk');

// let keysObj = {
// 	'name': 'Name',
// 	'updateTime': 'Last Modified',
// 	'size': 'Storage Used'
// }

// const pageTileMap = {
// 	'0': 'Audio',
// 	'1': 'Videos',
// 	'2': 'File',
// 	'3': 'Images',
// }

function LargeFilePage(props) {
	const navigate = useNavigate()
	const [isShow, setIsShow] = useState<any>(undefined)
	const [visible, setVisible] = useState<boolean>(false)
	const [itemNum, setItemNum] = useState<Number>(1)
	const [isFolder, setIsFolder] = useState<boolean>(false)
	const [isSortBy, setIsSortBy] = useState<boolean>(false)
	const [isMeDrive, setIsMeDrive] = useState<boolean>(false)
	const [isUpgradeStorage, setIsUpgradeStorage] = useState<boolean>(false)
	const [openItemDetails, setOpenItemDetails] = useState<boolean>(false)
	const [detailsObj, setDetailsObj] = useState<any>({})
	const [folderList, setFolderList] = useState<any>([]); //  文件夹列表
	const [fileList, setFileList] = useState<any>([]); //  文件列表
	const [addFolderName, setAddFolderName] = useState<any>(''); //  新建文件夹的名称
	const [currentListFolderPid, setCurrentListFolderPid] = useState<any>(0); // 当前列表的pid
	// const [activeID, setActiveID] = useState<any>(-1); // 操作更多时的文件夹或者文件ID
	const [pageInfo, setPageInfo] = useState<any>({
		page: 0,
		pageSize: 10 * 1.5,
		orderBy: 'size', //name, updateTime, size
		order: 'desc', //Asc:顺序 Desc:逆序
	})

	const [onePageData, setOnePageData] = useState<any>({
		number_of_elements: 0,
		page_number: 0,
		total_elements: 0,
		total_pages: 0,
	}); // 文件分也请求数据
	const [sortRequst, setSortRequst] = useState<boolean>(false);
	const [storeInfo, setStoreInfo] = useState<any>({});
	const [currentFolderInfo, setCurrentFolderInfo] = useState<any>({ name: '' })
	const [enterLongPressEvent, setEnterLongPressEvent] = useState<boolean>(false);  // 进入长按事件
	const [userBasicCloudDisk] = useState(getUserBasicCloudDisk())
	const [folderSelectList, setFolderSelectList] = useState<any>([]);
	const [fileSelectList, setFileSelectList] = useState<any>([]);
	const [basicFolderSelectList, setBasicFolderSelectList] = useState<any>([]);
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})
	const location = useLocation();
	const timeOutRef: any = useRef(0);
	const reqTimeceRef: any = useRef(true);
	const reqPageRef = useRef(-1);
	const pullPageFlag = useRef(false); // 下拉刷新标志
	const currentReqFolderID = useRef(-1);  //当前请求的ID
	const curentFileTypeRef = useRef<any>(null);
	const [positionObj, setPositionObj] = useState<any>({
		top: 0,
		left: 0
	})
	const containRef: any = useRef<any>(false)
	const [isFileDetailsModal, setIsFileDetailsModal] = useState<any>(false)
	const [inputVal, setInputVal] = useState<string>("")
	const [currentBg, setCurrentBg] = useState<number>(0) // 设置背景
	const [isSystem, setIsSystem] = useState<number>(0)
	const [isFile, setIsFile] = useState<number>(0)
	const [isFold, setIsFold] = useState<number>(0)
	const [isAllHide, setIsAllHide] = useState<boolean>(false) // 显示全选按钮
	const [isAllSelect, setIsAllSelect] = useState<number>(1) // 1 未选择 2 一个和多个选择 3全选择
	const [arrFileId, setArrFileId] = useState<any[]>([])
	const [arrFolderId, setArrFolderId] = useState<any[]>([])
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
	const [isTipsModal, setIsTipsModal] = useState<boolean>(false);
	const [searchVal, setSearchVal] = useState<string>("")
	const [isNavigation, setIsNavigation] = useState<boolean>(true);
	const params = new URLSearchParams(location.search)
	const file_type = params.get('file_type')
	const [isName, setIsName] = useState<boolean>(false);
	const [isSort, setIsSort] = useState<any>(1)
	const [isAllNum, setIsAllNum] = useState<number>(1)
	const [sizeNum, setSizeNum] = useState<any>(0)


	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	}, [])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	useEffect(() => {
		let searchParams = new URLSearchParams(location.search);
		let _id: any = searchParams.get('id');
		let _file_type: any = searchParams.get('file_type');

		curentFileTypeRef.current = _file_type

		if (_id === null || _id === undefined) {
			_id = 0;
		}

		if (_id === 0) {
			// 页面请求信息初始化
			resetPageInfo();
		}

		setCurrentListFolderPid(() => _id);
		setFileList(() => []);
		setFolderList(() => []);
		setFolderSelectList(() => []);
		setFileSelectList(() => []);
		setBasicFolderSelectList(() => []);

	}, [location.search])

	useEffect(() => {
		// 页面请求信息初始化
		resetPageInfo();

		clearTimeout(timeOutRef.current);
		timeOutRef.current = setTimeout(() => {

			initPageData();

			if (currentListFolderPid) {
				getDetail();
			} else {
				setCurrentFolderInfo({ name: '' })
			}
		}, 250);

		return () => {
			clearTimeout(timeOutRef.current);
		}
	}, [currentListFolderPid, file_type, searchVal])

	useEffect(() => {

		if (sortRequst) {
			resetPageInfo();

			clearTimeout(timeOutRef.current);
			timeOutRef.current = setTimeout(() => {

				initPageData();
			}, 250);

			return () => {
				clearTimeout(timeOutRef.current);
			}
		}
	}, [sortRequst, ])

	// 数据初始化
	const initPageData = async () => {
		currentReqFolderID.current = currentListFolderPid;
		await getDiskStorageSize();
		getPageData(currentListFolderPid, 0);
	}


	const getDiskStorageSize = async () => {
		let res: any = await getStorageInformation();
		let { data, result } = res;
		if (result === 'ok' && data) {

			if (isJSON(data)) {
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id,
					backup_id: data.backup_id,
				}

				if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data)
			}
		} else if (result === "401") {
			navigate('/login')
		}
	}

	// 获取页面数据
	const getPageData = async (dirId, _page, _flag = 0) => {
		if (currentReqFolderID.current !== dirId) return;
		let searchParams = new URLSearchParams(location.search);
		let _file_type: any = searchParams.get('file_type');
		let req:any = {}
		if (searchVal) {
			req = {
				...pageInfo,
				// dirId: dirId,
				page: _page,
				key: searchVal
				
			}
		} else {
			req = {
				...pageInfo,
				// dirId: dirId,
				page: _page,
				
			}
		}
		

		if (_file_type === "2") {
			req.type = 0 
		} else if (_file_type === "4") {
			req.type = 2
		} else if (_file_type === "5") {
			req.type = 100
		}

		
		let _id: any = searchParams.get('id');

		let _func = curentFileTypeRef.current === null ? getLargeFile : getFolderContent;
		if (curentFileTypeRef.current === null) {
			req = { pageSize: 0, order: pageInfo.order, orderBy: pageInfo.orderBy };
		}
		let res: any = await _func(req);

		setSortRequst(() => false);

		reqPageRef.current = -1;

		let { data, result }: any = res;
		if (result === 'ok' && data) {
			let _data = curentFileTypeRef.current === null ? data : data.files.content;

			let _onePageData: any = curentFileTypeRef.current === null ? {} : {
				number_of_elements: data['files']['number_of_elements'],
				page_number: data['files']['page_number'],
				total_elements: data['files']['total_elements'],
				total_pages: data['files']['total_pages'],
			}

			if (Array.isArray(_data)) {


				let files: any = _data; //文件


				files.map((item: any) => {
					let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified on';
					let _dt = dayjs(item.update_at).format(dateTimeFormat);
					item.createdTime =  dayjs(item.create_at).format('MM/DD/YYYY hh:mm A')
					item.modifiedTime = dayjs(item.update_at).format('MM/DD/YYYY hh:mm A')


					item.icon = fileType[item.type]['icon'];
					// if (item.type === 3 && item.cover) {
					// 	item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					// } else if ((item.type === 1 && item.cover && item.status === 1)) {
					// 	item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					// }
					if (item.type === 3 && item.cover && Number(item.status) !== 0) {
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 1 && item.cover && Number(item.status) !== 0) {
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 2 && Number(item.status) !== 0) {
						item.icon = fileTypeIcon[item.ext];
					}
					item.content = `${_lb} ${_dt}`;
					item.content = pageInfo.orderBy === 'size' ? `Storage used: ${sizeFormat(item.size)}` : `${_lb} ${_dt}`;

					return item;
				})

				if (curentFileTypeRef.current !== null) {
					if (_page === 0) {
						setFileList(() => files);
					} else {
						setFileList((preLS) => preLS.concat(files));
					}

				} else {
					setFileList(() => files);
				}


				if (_onePageData.page_number + 1 >= _onePageData.total_pages || _onePageData.total_pages === 0) {

				} else {
					if (curentFileTypeRef.current !== null) {
						getPageData(dirId, _page + 1)
					}

				}
			}
			setCurrentBg(0)
			setIsAllHide(false)
			setIsFile(0)
			setIsFold(0)
		}
	}



	// 新建文件夹
	useEffect(() => {
		if (addFolderName !== '') {
			createFolderFunc(currentListFolderPid, addFolderName);
		}
	}, [addFolderName])

	const createFolderFunc = (pid, name) => {
		let req = {
			pid: pid,
			name: name
		}

		createFolder(req).then((res: any) => {
			// setAddFolderName
			let { result, data } = res;
			if (result === 'ok' && data) {
				setAddFolderName(() => '');
				if (typeof data === 'number') {
					openFolder(null, { id: data, type: undefined })
				} else {
					initPageData()
				}

			} else {
				if (res?.error) {
					Dialog.alert({
						content: <AutoCenter style={{ textAlign: "center" }}>{res?.error}</AutoCenter>,
						destroyOnClose: true,
						confirmText: 'OK'
					})
				}
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	const getDetail = () => {
		// getFileDetails
		getFolderDetails(currentListFolderPid).then((res: any) => {
			const { data, result } = res;
			if (data && result === 'ok') {
				setCurrentFolderInfo(() => data);
			}
		}).catch(e => {
			console.log(e);
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	// 打开文件夹
	const openFolder = (e, o) => {
		setCurrentFolderInfo(() => o);
		setFileList(() => []);
		setFolderList(() => []);
		jumpPage(navigate, `/vault/favourite?id=${o.id}&type=${o.type}&target=3`);
	}

	const handleMeDrive = () => {
		setIsMeDrive(() => !isMeDrive)
	}

	const handleList = () => {
		let _isShow = isShow === undefined ? false : isShow;
		setIsShow(() => !isShow)
	}
	const handlePopup = () => {
		setVisible(() => !visible)
	}

	const handleSortBy = () => {
		setIsSortBy(() => !isSortBy)
	}
	const handleSortBy1 = () => {
		setIsSortBy(() => false)
	}

	const handleItem = (num: number) => {

		if (num === 3) {
			setIsFolder(true)
			setVisible(false)
		} else if (num === 1) {

			console.log(storeInfo)
			if (storeInfo?.size === 0) {
				const _show_modal = Modal.show({
					title: 'Tips',
					content: 'Access restricted',
					closeOnAction: !true,
					actions: [
						{
							key: 'submit',
							text: 'Restore now',
							primary: true,
						},
						{
							key: 'close',
							text: 'Later',
						},
					],
					onAction: ({ key }) => {
						if (key === 'close') {
							_show_modal.close();
						} else if (key === 'submit') {
							jumpPage(navigate, `/plan`);
							_show_modal.close();
						}
					}
				})

			} else {
				if (NAPI.check()) {
					NAPI.apptodo('upload', { folder_id: currentListFolderPid }, 'reloadList')

					setVisible(false)
				}
			}

		} else if (num === 2) {
			if (NAPI.check()) {
			}
		}

		setItemNum(num)
	}

	useEffect(() => {
		window['reloadList'] = reloadList;

		let _isShow = localStorage.getItem('me-drive-view-style');
		setIsShow(() => _isShow === 'block');
	}, [])

	useEffect(() => {
		if (isShow !== undefined) {
			let _isShow = localStorage.getItem('me-drive-view-style');
			if (_isShow !== null) {
				localStorage.setItem('me-drive-view-style', isShow ? 'block' : 'list');
			} else {
				localStorage.setItem('me-drive-view-style', 'list');
			}
		}
	}, [isShow])

	const reloadList = (id) => {
		setCurrentListFolderPid(() => id);
		setVisible(() => false)
	}

	const handleSearch = () => {
		// navigate(`/search?token=${token}`)
		// console.log(, '111')
		jumpPage(navigate, `/search?id=${currentListFolderPid}`);
	}

	const handleMore = (e, item: any) => {
		// e.stopPropagation();
		setOpenItemDetails(true)
		setDetailsObj(item)
	}

	// 操作后的处理
	const reqData = (v, o, action = '') => {

		if (v && action === 'closeLongPress') {
			setEnterLongPressEvent(() => false);
			return;
		}

		if (v) {
			let _iFolder = [];
			let _iFile = [];
			if (isJSON(o)) {
				if (o.type === undefined) {
					_iFolder.push(o);
				} else {
					_iFile.push(o);
				}

			} else if (Array.isArray(o)) {
				for (let item of o) {
					if (item.type === undefined) {
						_iFolder.push(item);
					} else {
						_iFile.push(item);
					}
				}
			}


			if (action === 'delete') {
				removeItemFromList(_iFolder, _iFile)
			} else if (action === 'rename') {
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			} else if (action === 'collect') {
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			}
		}
	}

	const updateList = (_folder: any, _file: any) => {

		if (_folder.length > 0) {
			let _folderList = JSON.parse(JSON.stringify(folderList));
			_folderList = _folderList.map((o: any) => {
				for (let item of _folder) {
					if (o.id === item.id) {
						o = item;
					}
				}
				return o;
			})
			setFolderList(() => _folderList);
		}

		if (_file.length > 0) {
			let _fileList = JSON.parse(JSON.stringify(fileList));
			_fileList = _fileList.map((o: any) => {
				for (let item of _file) {
					if (o.id === item.id) {
						o = item;
					}
				}
				return o;
			})
			setFileList(() => _fileList);
		}

		if (enterLongPressEvent) {
			cancelSelectFunc();
		} else {
			setDetailsObj(() => Object({}));
		}
	}

	const removeItemFromList = (_folder: any, _file: any) => {

		if (_folder.length > 0) {
			let _arrids = _folder.map((item: any) => {
				return item.id;
			})

			let _folderList = JSON.parse(JSON.stringify(folderList));
			let _arr = [];
			for (let o of _folderList) {
				if (!_arrids.includes(o.id)) {
					_arr.push(o);
				}
			}
			setFolderList(() => _arr);
		}

		if (_file.length > 0) {
			let _arrids = _file.map((item: any) => {
				return item.id;
			})

			let _fileList = JSON.parse(JSON.stringify(fileList));
			let _arr = [];
			for (let o of _fileList) {
				if (!_arrids.includes(o.id)) {
					_arr.push(o);
				}
			}
			setFileList(() => _arr);
		}

		if (enterLongPressEvent) {
			cancelSelectFunc();
		} else {
			setDetailsObj(() => Object({}));
		}
	}

	// 重置页面信息
	const resetPageInfo = () => {
		let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		_pageInfo.page = 0;
		setPageInfo((prev) => _pageInfo);

		reqPageRef.current = 0;
	}

	const loadMoreData = async () => {
		let _onePageData = JSON.parse(JSON.stringify(onePageData));

		if (_onePageData.page_number < _onePageData.total_pages) {

			if (reqTimeceRef.current) {

				reqTimeceRef.current = false;
				let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
				_pageInfo.page = _pageInfo.page + 1;
				setPageInfo((prev) => _pageInfo);

			}
		}
	}

	// 排序方式
	const sortFunc = (v) => {
		let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		_pageInfo = {
			..._pageInfo,
			...v
		}
		// console.log(_pageInfo, '_pageInfo470')

		let _localJSON = {
			order: _pageInfo.order,
			orderBy: _pageInfo.orderBy,
		}

		localStorage.setItem('vault-sort-info', JSON.stringify(_localJSON));

		setPageInfo(() => _pageInfo);
		setSortRequst(() => true);
	}

	useEffect(() => {
		// setIsUpgradeStorage
		let _remind = localStorage.getItem('remind-storage-almost-full');

		if (_remind === null) {
			if (storeInfo['size'] && storeInfo['used']) {
				let _diff = storeInfo['size'] - storeInfo['used']
				let _kb = _diff / 1024;
				let _mb = _kb / 1024;
				let _gb = _mb / 1024;
				let _tb = _gb / 1024;

				if (_mb < 300) {
					if (storeInfo['size'] <= (1024 * 1024 * 1024 * 3)) {
						setIsUpgradeStorage(() => true)
					}
				} else {
					if (_gb < 1) {
						if (storeInfo['size'] > (1024 * 1024 * 1024 * 3)) {
							setIsUpgradeStorage(() => true)
						}
					}
				}
			}
		}

		if (storeInfo?.cloud_gallery_id === null && storeInfo?.size !== 0) {
			createCloudDisk();
		}

	}, [storeInfo])

	// 没有云盘，调用编辑自动同步接口生成;
	const createCloudDisk = () => {
		let req = {
			auto_save: false,
			back_up: false,
			only_wifi: false
		}
		setSettingConfig(req).then((res: any) => {
			let { data, result } = res;
			if (result === 'ok' && data) {
				// reloadPage();
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}



	// 打开文件
	const openFile = (e, o) => {
		if (Number(o.status) !== 0) {
			jumpPage(navigate, `/vault/file?id=${o.id}&type=${o.type}&target=5`)
		}

	}

	// 下拉刷新数据
	const onRefreshFunc = async () => {
		console.log('onRefreshFunc')
		if (pullPageFlag.current) return;
		resetPageInfo();
		pullPageFlag.current = true;

		clearTimeout(timeOutRef.current);
		timeOutRef.current = setTimeout(() => {
			initPageData()
			pullPageFlag.current = false;
		}, 1050);
	}

	const clickRowMore = (o) => {
		handleMore(null, o)
	}

	const clickRowFunc = (o) => {

		if (enterLongPressEvent) {
			if (o.type === undefined) {
				if (o.disabled) {

				} else {
					let _folderSelectList = JSON.parse(JSON.stringify(folderSelectList));
					let _disIDS = getUserBasicFolderIDS();
					let _folderList = JSON.parse(JSON.stringify(folderList));
					let _disIDSArr = [];

					// for(let item of _folderList){
					// 	if(_disIDS.includes(item.id)){
					// 		_disIDSArr.push(item.id);
					// 	}
					// }

					// setBasicFolderSelectList(() => _disIDSArr)

					if (_folderSelectList.includes(o.id)) {
						_folderSelectList = _folderSelectList.filter((item) => {
							return item !== o.id;
						})
					} else {
						_folderSelectList.push(o.id)
					}

					setFolderSelectList(() => _folderSelectList)
				}
			} else {
				let _fileSelectList = JSON.parse(JSON.stringify(fileSelectList));

				if (_fileSelectList.includes(o.id)) {
					_fileSelectList = _fileSelectList.filter((item) => {
						return item !== o.id;
					})
				} else {
					_fileSelectList.push(o.id)
				}

				setFileSelectList(() => _fileSelectList)
			}
			return;
		}


		if (o.type === undefined) {
			openFolder(null, o)
		} else {
			if (Number(o.status) !== 0) {
				openFile(null, o)
			}
		}
	}

	const selectAllFunc = (v) => {

		let _folderList = JSON.parse(JSON.stringify(folderList));
		let _fileList = JSON.parse(JSON.stringify(fileList));
		let _disIDS = getUserBasicFolderIDS();

		let _arr = []
		let _disIDSArr = []
		if (v) {
			for (let item of _folderList) {
				// if(_disIDS.includes(item.id)){
				// 	_disIDSArr.push(item.id);
				// }else{
				// 	_arr.push(item.id);
				// }
				_arr.push(item.id);
			}
		}

		setBasicFolderSelectList(() => _disIDSArr)
		setFolderSelectList(() => _arr)

		let _arrii = [];

		if (v) {
			for (let item of _fileList) {
				_arrii.push(item.id);
			}
		}

		setFileSelectList(() => _arrii)
	}

	const downLoadFunc = (e) => {
		let _arr = [];
		let _fileList = JSON.parse(JSON.stringify(fileList));

		for (let item of _fileList) {
			if (fileSelectList.includes(item.id)) {
				_arr.push(item);
			}
		}

		if (NAPI.check()) {
			NAPI.apptodo('downloadList', _arr)

			setEnterLongPressEvent(() => false);
		}
	}

	const checkLongPressMoreMenu = () => {
		setOpenItemDetails(() => true)
	}

	const changePressStart = (v) => {
		if (pullPageFlag.current) return;
		setEnterLongPressEvent(() => v)
		// pullPageFlag.current
	}

	useEffect(() => {
		if (!enterLongPressEvent) {
			setFolderSelectList(() => []);
			setFileSelectList(() => []);
		}
	}, [enterLongPressEvent])

	const cancelSelectFunc = () => {
		setEnterLongPressEvent(() => false);
		setFolderSelectList(() => []);
		setFileSelectList(() => []);
		setBasicFolderSelectList(() => []);

	}

	const getEleSize = () => {
		let _dom = containRef.current;
		if (_dom) {
			let _h = _dom.clientWidth;
			_h = (_h - 120) / 6;

			return {
				style: {
					width: `${_h}px`,
					height: `152px`
				}
			}
		} else {
			return {};
		}
	}


	const _eleSize = typeof containRef.current === 'object' ? getEleSize() : {}

	const getSelectDataByList = () => {
		let _arr = [];
		for (let item of folderList) {
			if (folderSelectList.includes(item.id)) {
				_arr.push(item)
			}
		}

		for (let item of fileList) {
			if (fileSelectList.includes(item.id)) {
				_arr.push(item)
			}
		}

		return _arr
	}

	const actions = [
		{ key: 'updateTime', icon: null, text: 'Modified Date' },
		{ key: 'name', icon: null, text: 'Name' },
		{ key: 'types', icon: null, text: 'Types' },
		{ key: 'size', icon: null, text: 'Size' },
	]
	// name, updateTime, size, types
	const sortMenuFun = (v) => {
		let _data: any = {
			orderBy: v.key,
		}

		if (pageInfo.orderBy === v.key) {
			_data.order = pageInfo.order === 'asc' ? 'desc' : 'asc';
		}
		console.log(_data, '_data')

		sortFunc(_data);
	}


	const _selectData = enterLongPressEvent ? getSelectDataByList() : detailsObj;

	const endDom = () => {
		let _checked = (fileSelectList.length + folderSelectList.length + basicFolderSelectList.length === fileList.length + folderList.length) && (fileList.length + folderList.length !== 0) && (fileSelectList.length + folderSelectList.length > 0);
		let _indeterminate = (!_checked) && (fileSelectList.length + folderSelectList.length > 0);
		return <div>
			<Space align={"center"} justify={"center"} direction={"horizontal"} style={{ '--gap': '25px' }}>
				<div className={'check-box-quadrate'}>
					<Checkbox
						onChange={selectAllFunc}
						indeterminate={_indeterminate}
						checked={_checked}
						block
					></Checkbox>
				</div>

				{
					folderSelectList.length === 0 && fileSelectList.length > 0 ?
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px" }} onClick={downLoadFunc} />
						:
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px", opacity: 0.35 }} />
				}
				<img src="/res/icons/icon_more.svg" alt="" style={{ transform: "rotate(90deg)" }} onClick={checkLongPressMoreMenu} />
			</Space>
		</div>
	}


	const handleDownload = () => {
		let newFileList = _.cloneDeep(fileList)
		let newFolderList = _.cloneDeep(folderList) // 文件夹
		let isALL = newFolderList.some((item: any) => item.isSelect)

		if (isALL) {
			message.warning("Folder cannot be downloaded!")
			return false
		} else {
			let Arr: any = []
			newFileList.map((item: any) => {
				if (item.isSelect) {
					let url = `${imageUrl}/disk/download_file?id=${item.id}&name=${item.name}&token=${getLGToken()}`;
					let name = item.name
					Arr.push({
						url: url,
						name: name,
					})
				}
			})


			Arr.forEach(file => {
				let iframe = document.createElement('iframe');
				iframe.style.display = 'none';
				document.body.appendChild(iframe);
				iframe.src = file.url;
				setTimeout(() => {
					document.body.removeChild(iframe);
				}, 5000);
			});

			// getPageData(currentListFolderPid, 0, 0, searchVal);
			initPageData()
			setIsAllSelect(1)
			setIsAllHide(false)
		}

	}

	// 判断文件显示
	useEffect(() => {
		let newFileList = _.cloneDeep(fileList)
		if (newFileList.length >= 1) {
			let isTrue = newFileList.some((item: any) => item.isSelect)
			if (isTrue) {
				setIsFile(1)
			} else {
				setIsFile(0)
			}
		}
		let _arr = getUserBasicFolderIDS();
		let newFolderList = _.cloneDeep(folderList)
		if (newFolderList.length >= 1) {
			let isTrue = newFolderList.some((item: any) => item.isSelect)
			if (isTrue) {
				setIsFold(1)
				setIsFile(0)
			} else {
				setIsFold(0)
			}
			let newList: any[] = []
			newFolderList.map((it: any) => {
				if (it.isSelect) {
					if (_arr.includes(it.id)) {
						newList.push(it)
					}
				}
				return it
			})
			if (newList.length >= 1) {
				setIsSystem(1)
				setIsFile(0)
			} else {
				setIsSystem(0)
			}
		}
		let list = [...newFolderList, ...newFileList]
		let numTotal = list.filter((item: any) => item.isSelect).length
		setIsAllNum(numTotal)
		if (numTotal >= 1) {
			setIsNavigation(false)
		} else {
			setIsNavigation(true)
		}

	}, [fileList, folderList])

	// 全选
	const handleSelect = (num: Number) => {
		let newFolderList = _.cloneDeep(folderList)
		let newFileList = _.cloneDeep(fileList)
		if (num === 1) {
			setIsAllSelect(3)
			newFileList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFileList(newFileList)
			newFolderList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFolderList(newFolderList)

		} else if (num === 2) {
			newFileList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFileList(newFileList)
			newFolderList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFolderList(newFolderList)
			setIsAllSelect(3)
		} else {
			newFileList.map((it: any) => {
				it.isSelect = false;
				return it
			})
			setFileList(newFileList)
			newFolderList.map((it: any) => {
				it.isSelect = false;
				return it
			})
			setFolderList(newFolderList)
			setIsAllSelect(1)
		}
	}

	useEffect(() => {
		let newFolderList = _.cloneDeep(folderList)
		let newFileList = _.cloneDeep(fileList)
		let list = [...newFolderList, ...newFileList]
		let num = list.length >= 1 ? list.some((it: any) => it.isSelect) : false
		let num1 = list.length >= 1 ? list.every((it: any) => it.isSelect) : false
		if (num) {
			setIsAllSelect(2)
		} else {
			setIsAllSelect(1)
		}
		if (num1) {
			setIsAllSelect(3)
		}
		let arr:any = []
		list.map((item: any) => {
			if (item.isSelect) {
				arr.push(item)
			}
			return item
		})
		let sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0);
		setSizeNum(sizeFormat(sum))


	}, [folderList, fileList])

	// 回收站删除
	const handleMoveToTrash = async () => {
		let newFileList = _.cloneDeep(fileList)
		let newFolderList = _.cloneDeep(folderList)  // 文件夹
		let arrFile: any[] = []
		let arrFolder: any[] = []
		let systemList: any = []
		newFileList.map((item: any) => {
			if (item.isSelect) {
				arrFile.push(item.id)
			}
			return item
		})

		newFolderList.map((item: any) => {
			if (item.isSelect) {
				let _arr = getUserBasicFolderIDS();
				if (!_arr.includes(item.id)) {
					arrFolder.push(item.id)
				} else {
					systemList.push(item.id)
				}
			}
			return item
		})

		if (systemList.length >= 1) {
			message.warning("System folders cannot be selected. Please select other files and folders to delete!")
			return false
		}

		setArrFileId(arrFile)
		setArrFolderId(arrFolder)
		if (arrFile.length === 0 && arrFolder.length === 0) {
			message.warning("Please select files to delete!")
			return false
		} else if (arrFile.length >= 1 && arrFolder.length >= 1) {
			setIsDeleteModal(true)
			setIsAllHide(false)
		} else {
			setIsDeleteModal(true)
			// setIsAllHide(false)
		}
	}

	let _has_unuse_size: any = Number(cloudDiskInfo?.usable);
	_has_unuse_size = isNaN(_has_unuse_size) ? -1 : _has_unuse_size;
	_has_unuse_size = _has_unuse_size > 0;

	// 复制文件
	const handleCopyTo = () => {
		if ((!_has_unuse_size) || Number(cloudDiskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}
		let newFileList = _.cloneDeep(fileList)
		let newFolderList = _.cloneDeep(folderList)  // 文件夹
		let arrFile: any[] = []
		let arrFolder: any[] = []
		let objFolderArr: any[] = []
		let objFileArr: any[] = []
		let systemList: any = []
		newFolderList.map((item: any) => {
			if (item.isSelect) {
				let _arr = getUserBasicFolderIDS();
				if (!_arr.includes(item.id)) {
					arrFolder.push(item.id)
					objFolderArr.push(item)
				} else {
					systemList.push(item.id)
				}
			}
			return item
		})


		newFileList.map((item: any) => {
			if (item.isSelect) {
				arrFile.push(item.id)
				objFileArr.push(item)
			}
		})

		let target = (location.pathname).replace(/\//g, '')
		if ((arrFolder.length === 1 && arrFile.length === 0)) {
			let _pathUrl = `/vault/middle?id=${0}&action_id=${objFolderArr[0]?.id}&type=${objFolderArr[0]?.type}&action_type=1&hisdeep=1&target=${target}`
			jumpPage(navigate, _pathUrl)
		} else if (arrFolder.length === 0 && arrFile.length === 1) {
			let _pathUrl = `/vault/middle?id=${0}&action_id=${objFileArr[0]?.id}&type=${objFileArr[0]?.type}&action_type=1&hisdeep=1&target=${target}`
			jumpPage(navigate, _pathUrl)
		} else if ((arrFolder.length > 1 && arrFile.length === 0) || (arrFolder.length === 0 && arrFile.length > 1) || (arrFolder.length >= 1 && arrFile.length >= 1)) {
			let _copyData = {
				dirs: arrFolder,
				files: arrFile
			}
			let _pathUrl = `/vault/middle?id=${0}&action_type=1&hisdeep=1&action_data=${JSON.stringify(_copyData)}&target=${target}`;
			jumpPage(navigate, _pathUrl)
		}
		else {
			let _pathUrl = `/vault/middle?id=${0}&action_id=${objFolderArr[0]?.id}&type=${objFolderArr[0]?.type}&action_type=1&hisdeep=1&target=${target}`
			jumpPage(navigate, _pathUrl)
		}
	}

	// 移动
	const handleMoveTo = async () => {
		if ((!_has_unuse_size) || Number(cloudDiskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}
		let newFileList = _.cloneDeep(fileList)
		let newFolderList = _.cloneDeep(folderList)  // 文件夹
		let arrFile: any[] = []
		let arrFolder: any[] = []
		let objFolderArr: any[] = []
		let objFileArr: any[] = []
		let systemList: any = []
		newFolderList.map((item: any) => {
			if (item.isSelect) {
				let _arr = getUserBasicFolderIDS();
				if (!_arr.includes(item.id)) {
					arrFolder.push(item.id)
					objFolderArr.push(item)
				} else {
					systemList.push(item.id)
				}
			}
		})

		if (systemList.length >= 1) {
			message.warning("System files cannot be selected. Please select other files and folders to move!")
			return false
		}


		newFileList.map((item: any) => {
			if (item.isSelect) {
				arrFile.push(item.id)
				objFileArr.push(item)
			}
		})

		let target = (location.pathname).replace(/\//g, '')
		if (arrFolder.length === 0 && arrFile.length === 0) {
			message.warning("System files cannot be selected. Please select other files and folders to move!")
		} else if ((arrFolder.length === 1 && arrFile.length === 0)) {
			let _pathUrl = `/vault/middle?id=${0}&action_id=${objFolderArr[0]?.id}&type=${objFolderArr[0]?.type}&action_type=2&hisdeep=1&target=${target}`
			jumpPage(navigate, _pathUrl)
		} else if (arrFolder.length === 0 && arrFile.length === 1) {
			let _pathUrl = `/vault/middle?id=${0}&action_id=${objFileArr[0]?.id}&type=${objFileArr[0]?.type}&action_type=2&hisdeep=1&target=${target}`
			jumpPage(navigate, _pathUrl)
		} else if ((arrFolder.length > 1 && arrFile.length === 0) || (arrFolder.length === 0 && arrFile.length > 1) || (arrFolder.length >= 1 && arrFile.length >= 1)) {
			let _copyData = {
				dirs: arrFolder,
				files: arrFile
			}
			let _pathUrl = `/vault/middle?id=${0}&action_type=2&hisdeep=1&action_data=${JSON.stringify(_copyData)}&target=${target}`;
			jumpPage(navigate, _pathUrl)
		} else {
			message.warning("System files cannot be selected. Please select other files and folders to  move!")
		}
	}

	const initDeleteFileList = async () => {
		if (arrFileId.length >= 1) {
			const res: any = await deleteFileList(arrFileId)
			if (res.result === "ok") {
				message.success("Delete successful!")
				initPageData()
			}
		}
		setIsAllHide(false)
	}

	const handleMouseLeave = () => {
		let newFileList = _.cloneDeep(fileList)
		let newFolderList = _.cloneDeep(folderList)  // 文件夹
		let isTrue1 = newFileList.some((item: any) => item.isSelect)
		let isTrue2 = newFolderList.some((item: any) => item.isSelect)

		if (isTrue1 || isTrue2) {
			setIsAllHide(() => true)
		} else {
			setIsAllHide(() => false)
		}

	}

	const handleMouseEnter = () => {
		let newFileList = _.cloneDeep(fileList)
		setIsAllHide(() => true)
		// if (newFileList.length >= 1) {
		// 	setIsAllHide(() => true)
		// } else {
		// 	setIsAllHide(() => false)
		// }

	}

	const handleSearchInput = async (e) => {
		const val = (e.target.value).trim()
		if (val) {
			setSearchVal(val)
		} else {
			setSearchVal('')

		}
	}


	const handleSort = (e:any) => {
		if (Number(e.target.id) === 1) {
			let _data: any = {
				orderBy: "name",
				order: isName ? 'desc' : 'asc',
			}
			sortFunc(_data);
		} else if (Number(e.target.id) === 2) {
			let _data: any = {
				orderBy: "createTime",
				order: isName ? 'desc' : 'asc',
			}
			sortFunc(_data);
		} else if (Number(e.target.id) === 3) {
			let _data: any = {
				orderBy: "updateTime",
				order: isName ? 'desc' : 'asc',
			}
			sortFunc(_data);
		} else if (Number(e.target.id) === 4) {
			let _data: any = {
				orderBy: "size",
				order: isName ? 'desc' : 'asc',
			}
			sortFunc(_data);
		}
		setIsSort(Number(e.target.id))
		setIsName(() => !isName)
	}


	return (
		<div className="favourite-con large-file-page">
			{/* {
				enterLongPressEvent ? <>
						<div className={'fav-file-head-box'}>
							<HeadNav
								start={cancelSelectFunc}
								icon={'/res/icons/icon_close.svg'}
								title={ _select_count === 0 ? 'Select items' : `${_select_count} selected` }
								endElement={endDom()}
						/>
						</div>
					</>
					:
					<>
						<div className={'fav-file-head-box'}>
							<HeadNavIII
								title={curentFileTypeRef.current === null ? "Large File" : pageTileMap[curentFileTypeRef.current]}
								endElement={
									null
								}
							/>
						</div>
					</>
			} */}

			<div className={'vault-head-contain'} style={{ top: "190px" }}>
				<div className="content-title">
					{/* <div onClick={handleSortBy}>
						<Popover.Menu
							actions={actions.map(action => ({
								...action,
								icon: null,
								text: <Space>
									<span onClick={handleSortBy1}>{action.text}</span>
									{
										pageInfo.orderBy === action.key ? <span>
											{<img src="/res/icons/icon_order.svg" style={{ width: "12px", transform: pageInfo.order == 'asc' ? 'rotate(0deg)' : 'rotate(180deg)' }} />}
										</span> : null
									}

								</Space>
							}))}
							onAction={sortMenuFun}
							placement='bottom-start'
							trigger='click'
							className={'adm-popover--sort-menu'}
							destroyOnHide={true}
						>
							<Space style={{ '--gap': '10px' }} justify={'start'} align={'center'}>
								<div className='adm-popover--sort-menu-all'>
								{
									 <div className='select-all' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
										{isAllSelect === 1 && <img src="/res/icons/icon_select1.svg" onClick={() => handleSelect(1)} style={{ width: "18px", height: "18px" }} alt="" />}
										{isAllSelect === 2 && <img src="/res/icons/icon_select2_active.svg" onClick={() => handleSelect(2)} style={{ width: "18px", height: "18px" }} alt="" />}
										{isAllSelect === 3 && <img src="/res/icons/icon_select1_active.svg" onClick={() => handleSelect(3)} style={{ width: "18px", height: "18px" }} alt="" />}

									</div>
								}
									<h4>Sort</h4>
									<div style={{ marginLeft: "12px",transform: `${pageInfo.order === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)'}` }}><img src="/res/icons/icon_order.svg" style={{ width: '12px' }} /></div>
								</div>
							</Space>
						</Popover.Menu>
					</div> */}
					{
						isNavigation && <Navigation />
					}
					<div className='controls'>
						{
							(isFile === 1) && <div className='sort-download' onClick={handleDownload}>
								<img src="/res/icons/icon_save_to_device_v2.svg" />
								<span>Download</span>
							</div>
						}
						{(isFile === 1) && <div className="move-to-line"></div>}
						{
							((isFile === 1 || isFold === 1) && isSystem === 0) && <div className='move-to-trash' onClick={handleMoveToTrash}>
								<img src="/res/icons/icon_trash_v2.svg" />
								<span>Move to trash</span>
							</div>
						}
						{((isFile === 1 || isFold === 1) && isSystem === 0) && <div className="move-to-line"></div>}
						{
							(Number(cloudDiskInfo?.size) !== 0) && (isSystem === 1 || isFile === 1 || isFold === 1) && <div className='move-to-trash' onClick={handleCopyTo}>
								<img src="/res/icons/icon_copy_to_v2.svg" />
								<span>Copy to</span>
							</div>

						}
						{(Number(cloudDiskInfo?.size) !== 0) && (isSystem === 1 || isFile === 1 || isFold === 1) && <div className="move-to-line"></div>}

						{
							(Number(cloudDiskInfo?.size) !== 0) &&
							((isFile === 1 || isFold === 1) && isSystem === 0) && <div className='move-to-trash' onClick={handleMoveTo}>
								<img src="/res/icons/icon_move_to_v2.svg" />
								<span>Move to</span>
							</div>
						}
						{(Number(cloudDiskInfo?.size) !== 0) &&
							((isFile === 1 || isFold === 1) && isSystem === 0) && <div className="move-to-line"></div>}


						{(isFile === 1 || isFold === 1 || isSystem === 1) && <div className='move-to-trash move-to-trash-selected'><span>{isAllNum}</span> &nbsp; Selected ({sizeNum})</div>}
					</div>
					<div className="page-con-item">
						<div className="page-con-search">
							<Input placeholder="Search" value={searchVal} onChange={(e) => handleSearchInput(e)} />
							<img src="/res/icons/icon_search.svg" alt="" className="search-img"></img>
						</div>
						<div className="page-con-sort">
							<Space align={"center"} style={{ '--gap': '16px' }}>
								<div onClick={handleList} style={{ cursor: "pointer" }}>
									{isShow ? <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_1.svg"} alt="" /> : <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_1-active.svg"} alt="" />}
								</div>
								<div onClick={handleList} style={{ cursor: "pointer" }}>
									{isShow ? <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_2-active.svg"} alt="" /> : <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_2.svg"} alt="" />}
								</div>
							</Space>
						</div>
					</div>
				</div>
				<div className="content-title-header" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  onClick={(e) => handleSort(e)}>
					<div id="1">
						Name
						{isSort === 1 && <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px", transform: isName ? 'rotate(0deg)' : 'rotate(180deg)' }} alt="" className="order-img"></img>}
					</div>
					<div id="2">
						Created Time
						{isSort === 2 && <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px", transform: isName ? 'rotate(0deg)' : 'rotate(180deg)' }} alt="" className="order-img"></img>}
					</div>
					<div id="3">
						Modified Time
						{isSort === 3 && <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px", transform: isName ? 'rotate(0deg)' : 'rotate(180deg)' }} alt="" className="order-img"></img>}
					</div>
					<div id="4">
						Size
						{isSort === 4 && <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px", transform: isName ? 'rotate(0deg)' : 'rotate(180deg)' }} alt="" className="order-img"></img>}
					</div>
					<div></div>
					{isAllHide &&
							<div className='select-all'  >
								{isAllSelect === 1 && <img src="/res/icons/icon_select1.svg" onClick={() => handleSelect(1)} style={{ width: "18px", height: "18px" }} alt="" />}
								{isAllSelect === 2 && <img src="/res/icons/icon_select2_active.svg" onClick={() => handleSelect(2)} style={{ width: "18px", height: "18px" }} alt="" />}
								{isAllSelect === 3 && <img src="/res/icons/icon_select1_active.svg" onClick={() => handleSelect(3)} style={{ width: "18px", height: "18px" }} alt="" />}

							</div>
						}
				</div>
			</div>

			<div className="fav-content" ref={containRef}>


				<div className={'file-item-list'}>
					<PullToRefresh
						onRefresh={onRefreshFunc}
						renderText={() => { return <div>Pull down to refresh</div> }}
					>

						{
							(folderList.length === 0 && fileList.length === 0) && <div className="content-data">
								<div className="content-data-img">
									<img src="/res/icons/no_file_found.png" alt="" />
								</div>
							</div>
						}

						{
							(folderList.length > 0 || fileList.length > 0) && <>
								<ContentItem
									{..._eleSize}
									isShow={isShow}
									list={[...folderList, ...fileList]}
									folderList={folderList}
									fileList={fileList}
									onHandleMore={clickRowMore}
									onClick={clickRowFunc}
									onLongPress={changePressStart}
									longPress={enterLongPressEvent}
									folderCheckedList={folderSelectList}
									fileCheckedList={fileSelectList}
									onPositionObj={setPositionObj}
									inputVal={inputVal}
									init={initPageData}
									onInputVal={setInputVal}
									onIsAllSelect={setIsAllSelect}
									onFolderList={setFolderList}
									onFileList={setFileList}
									isAllHide={isAllHide}
									onIsAllHide={setIsAllHide}
									currentBg={currentBg}
									onCurrentBg={setCurrentBg}
									cloudDiskInfo={cloudDiskInfo}
								/>
							</>
						}

					</PullToRefresh>
				</div>

			</div>
			<FileDetailsModal open={isFileDetailsModal} onOpen={setIsFileDetailsModal} initPageData={initPageData} data={_selectData} />
			<Popup
				className="popup-con"
				visible={visible}
				onMaskClick={() => {
					setVisible(false)
				}}
				onClose={() => {
					setVisible(false)
				}}
			>
				<div className="popup-title">Add To Vault</div>
				<div className={itemNum === 1 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(1)}>
					<img src="/res/icons/icon_photo.svg" alt="" style={{ width: "20px" }} />
					<p>Upload Photos Or Videos</p>
				</div>
				<div className={itemNum === 3 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(3)}>
					<img src="/res/icons/icon_create_new_folder.svg" alt="" style={{ width: "18px" }} />
					<p>Create New Folder</p>
				</div>
			</Popup>

			<MeDriveModal
				visible={isMeDrive}
				onVisible={setIsMeDrive}
				storeInfo={setStoreInfo}
			/>
			{/* <SortByModal
				visible={isSortBy}
				onVisible={setIsSortBy}
				sortFunc={sortFunc}
				sortValue={{orderBy: pageInfo.orderBy, order: pageInfo.order}}
			/> */}
			<CreateNewFolder
				visible={isFolder}
				onVisible={setIsFolder}
				setFolderName={setAddFolderName}
			/>
			<DetailsModal
				type={"file"}
				visible={openItemDetails}
				onVisible={setOpenItemDetails}
				init={initPageData}
				data={_selectData}
				reqData={reqData}
				onClick={clickRowFunc}
				cloudDiskInfo={cloudDiskInfo}
				onIsFileDetailsModal={setIsFileDetailsModal}
				positionObj={positionObj}
				onCurrentBg={setCurrentBg}
			/>
			<DeleteModal visible={isDeleteModal} onVisible={setIsDeleteModal} title="Notice" contentObj={`Are you sure you want to delete the ${isAllNum} files? These files will be permanently removed in 30 days.`} onAction={initDeleteFileList} />
			<TipsModal visible={isTipsModal} onVisible={setIsTipsModal} diskInfo={cloudDiskInfo} />
		</div>
	);
}

export default LargeFilePage
