import getApiUrl from "../apiUrl";
import requsetData from "../request";
import {json2params} from "../../utils/common";

const url = getApiUrl('cloudDisk');

// 获取操作记录
export function getRecentRecord(params={}){
	return requsetData(`${url}/record?${json2params(params)}`, {})
}
