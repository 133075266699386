import React, {useEffect, useState} from 'react';
import './index.less'
import {useNavigate, useParams} from "react-router-dom";
import {
	checkPaymentResultCloud,
	checkPaymentResultVault,
	getInfoSubscription,
	getVdrInfo
} from "../../../server/cloudPlan";
import pfFileUrl from "../../../server/PFFileUrl";
import {Alert, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useMyNavigate} from "../../../hook/useMyNavigate";

const Return = () => {
	const [resultMessage, setResultMessage] = useState<string>('');
	const [list, setList] = useState<any>([]);
	const [loading, setLoading] = useState<number>(0);

	const urlPathParams = useParams()
	const navigate = useNavigate();
	const {navigateX} = useMyNavigate()

	useEffect(() => {
		initialize()
	}, [])

	const initialize = async () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sessionId = urlParams.get('session_id');
		// const token = localStorage.getItem('token');

		let type = urlPathParams.type;

		let _fun = type === 'vault' ? checkPaymentResultVault : checkPaymentResultCloud;

		setLoading(() => 1);
		let session:any = await _fun(sessionId);

		if (session.status == 'open') {
			// window['replace'](window.location.origin + '/checkout')
		} else if (session.status == 'complete') {

			let subscription_id = session?.subscription_id;
			subscription_id = session?.subscription ? session?.subscription : subscription_id;

			if(subscription_id){
				getOrderDetailFun(subscription_id);
			}else if(session?.vdr_subscription_id){
				getOrderDetailFun(session.vdr_subscription_id);
			}

		}
	}

	// 查订单详情
	const getOrderDetailFun = async (id) => {
		let type = urlPathParams.type;
		let func = type === 'vault' ? getVdrInfo : getInfoSubscription;
		let res:any = await func(id);
		let arr = [];
		if(type === 'vault'){
			let {size_type} = res;
			if(size_type){
				let icon = '/res/icons/icon_notice_a.svg';
				let item = {
					icon: icon,
					name: Number(size_type) === 1 ? 'Basic (1TB)' : (Number(size_type) === 2 ? 'Advanced (3TB)' : '-')
				}
				arr.push(item);
			}

		}else{
			let {devices} = res;
			if(Array.isArray(devices)){
				for(let item of devices){
					let icon = pfFileUrl(item.pic_url);
					let name = item.device_name;
					arr.push({icon,name});
				}
			}
		}

		setList(() => arr)
		setLoading(() => 2);
	}

	const loadDevIconErrorFun = (e) => {
		e.target.src = '/res/icons/camera-1.png';
	}

	const toCloudPage = () => {
		navigateX('/cloud-plan');
	}

	return <section id="success" className="hidden payment-result-page">
		{/*<p>*/}
		{/*	We appreciate your business! A confirmation email will be sent to <span id="customer-email"></span>.*/}

		{/*	If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.*/}
		{/*</p>*/}

		{
			loading === 1 && <div className={'loading-status-box'}>
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </div>
		}

		{/*<Alert*/}
		{/*	message="Error"*/}
		{/*	description="This is an error message about copywriting."*/}
		{/*	type="error"*/}
		{/*	showIcon*/}
		{/*/>*/}

		{
			loading === 2 && <>
        <div className={'t-box'}>
          <div className="-icon">
            <img src="/res/icons/icon_select.svg" width="80" alt="" />
          </div>
          <p className="h1">Successfully</p>
          <p className="w1">
            Thank you for your subscribing!
          </p>
        </div>

        <div className={'b-box'}>
					{
						list.map((item:any, index:number) => {

							return <div className={'row-item'} key={index}>
								<div className={'ico-box'}>
									<img src={item.icon} alt="" onError={loadDevIconErrorFun} />
								</div>
								<div className={'name-box'}>
									<span>{item.name}</span>
								</div>
							</div>
						})
					}
        </div>

        <div className={'re-btn'} onClick={toCloudPage}>
          Finish
        </div>

			</>
		}

	</section>
}


export default Return
