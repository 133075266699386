import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import './index.less';
import { loadStripe } from '@stripe/stripe-js';
const Navigation =  () => {
    const navigate = useNavigate()
    const location = useLocation();
	const params = new URLSearchParams(location.search)
   const [navigationId, setNavigationId] = useState<number>(0);
   // const [isImage, setIsImage] = useState<boolean>(false);
	const file_type = params.get('file_type')
   


    const handleNavigation = (num) => {
        setNavigationId(num)
      //   if (num === 1 || num === 3) {
      //    navigate(`/vault/photo?file_type=${num}`)
      //   } else {
      //     navigate(`/vault/large-file?file_type=${num}`)
      //   }

        if (num === 1) {
            navigate(`/vault/photo?file_type=${num}`)
            localStorage.setItem('isVideo', "true")
            let isImage = localStorage.getItem("isVideo")
            if (isImage === "true" && file_type === "1") {
               localStorage.removeItem('isVideo') 
               navigate(`/vault`)
            }
        } else if (num === 3) {
            navigate(`/vault/photo?file_type=${num}`)
            localStorage.setItem('isImage', "true")
            let isImage = localStorage.getItem("isImage")
            if (isImage === "true" && file_type === "3") {
               localStorage.removeItem('isImage') 
               navigate(`/vault`)
            }
        } else if (num === 2) {
            navigate(`/vault/large-file?file_type=${num}`)
            localStorage.setItem('isAudio', "true")
            let isImage = localStorage.getItem("isAudio")
            if (isImage === "true" && file_type === "2") {
               localStorage.removeItem('isAudio') 
               navigate(`/vault`)
            }
        } else if (num === 4) {
            navigate(`/vault/large-file?file_type=${num}`)
            localStorage.setItem('isDocument', "true")
            let isImage = localStorage.getItem("isDocument")
            if (isImage === "true" && file_type === "4") {
               localStorage.removeItem('isDocument') 
               navigate(`/vault`)
            }
        } else if (num === 5) {
         navigate(`/vault/large-file?file_type=${num}`)
         localStorage.setItem('isOthers', "true")
         let isImage = localStorage.getItem("isOthers")
         if (isImage === "true" && file_type === "5") {
            localStorage.removeItem('isOthers') 
            navigate(`/vault`)
         }
        }
      }

     useEffect(() => {
      if (file_type) {
         setNavigationId(Number(file_type))
      } else {
         setNavigationId(0)
      }

     }, [file_type])
   




   
    return <div className="navigation">
    <div className={navigationId === 3 ? 'navigation-active' : ''} onClick={() =>handleNavigation(3)}>
      <img src={navigationId === 3 ? "/res/icons/image_on.svg" : "/res/icons/image_off.svg"} width={18} />
      <span>Image</span>
    </div>
    <div className={navigationId === 1 ? 'navigation-active' : ''} onClick={() => handleNavigation(1)}>
       <img src={navigationId === 1 ? "/res/icons/video_on.svg" : "/res/icons/video_off.svg"} width={20}/>
       <span>Video</span>
    </div>
    <div className={navigationId === 2 ? 'navigation-active' : ''} onClick={() => handleNavigation(2)}>
       <img src={navigationId === 2 ? "/res/icons/audio_on.svg" : "/res/icons/audio_off.svg"} width={13}/>
       <span>Audio</span>
    </div>
    <div className={navigationId === 4 ? 'navigation-active' : ''} onClick={() => handleNavigation(4)}>
       <img src={navigationId === 4 ? "/res/icons/document_on.svg" : "/res/icons/document_off.svg"} width={16}/>
       <span>Document</span>
    </div>
    <div className={navigationId === 5 ? 'navigation-active' : ''} onClick={() =>handleNavigation(5)}>
       <img src={navigationId === 5 ? "/res/icons/others_on.svg" : "/res/icons/others_off.svg"} width={18}/>
       <span>Others</span>
    </div>
</div>
}

export default Navigation