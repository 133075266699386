import './index.less'

import MaybeLaterModal from './components/maybeLaterModal'
import DetailsModal from './components/detailsModal'

import {getListSubscription} from '../../server/cloudPlan'
import {useLocation} from "react-router-dom";
import _ from 'lodash';
import Icon from "@ant-design/icons";
import {useEffect, useState} from 'react';
import CloudPlanOne from './components/cloudPlanOne'
import SecuritySubscribeModal from './components/securitySubscribeModal'
import VaultSubscribeModal from './components/vaultSubscribeModal'
import CheckOut from './components/checkOut'
import {
	useChangeCloudDeviceStore,
	useCloudSubscribeStore, useDeviceDetailStore, useFreeTrialSubscribeStore,
	useOrderDetailStore,
	usePaymentStore, useUpdateCloudPageStore,
	useVaultSubscribeStore
} from "../../store/cloudPlan";
import ChangeCloudDeviceModal from "./components/changeCloudDeviceModal";
import SubscribeModelCVRBRT from "./components/SubscribeModelCVRBRT";
import SubscribeModelVaultBRT from "./components/SubscribeModelVaultBRT";
import SubscribeModelFreetrial from "./components/SubscribeModelFreetrial";
import {getPlatfromByUrl} from "../../utils/common";
import CVRPlanCardZmodo from "./components/CVRPlanCardZmodo";
import DeviceDetailModel from "./components/deviceDetailModel";


const CloudPlan = () => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const target = params.get('target')

	const [current, setCurrent] = useState<number>(5)
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [yearplusObj, setYearPlusObj] = useState<any>({})
	const [num, setNum] = useState<number>(1)
	const [planList, setPlanList] = useState<any>([])
	const [updateMainPage, setUpdateMainPage] = useState<number>(1);

	const {cloudInfoZU, setCloudInfoZU} = useCloudSubscribeStore();
	const {vaultInfoZU, setVaultInfoZU} = useVaultSubscribeStore();
	const {paymentInfoZU} = usePaymentStore();
	const {orderDetailZU} = useOrderDetailStore();
	const {changeCloudDeviceZU} = useChangeCloudDeviceStore();
	const {freeTrialSubscribeZU, setFreeTrialSubscribeZU} = useFreeTrialSubscribeStore();
	const {updateCloudPageZU, setUpdateCloudPageZU} = useUpdateCloudPageStore();
	const {deviceDetailZU, setDeviceDetailZU} = useDeviceDetailStore();

	const _platform = getPlatfromByUrl();

	const handleMaybeLater = () => {
		setIsModalOpen(true);
	}

	// Security Plan 订单
	const initListCloudPlan = async () => {
		const res: any = await getListSubscription()
		res.map((item: any) => {
			item.skuList = (item.sku)?.split('_')
			item.species = item?.skuList?.[0]
			item.time = item?.skuList?.[1]
			item.num = Number(item?.skuList?.[2])
			item.device = item?.skuList?.[3]


			return item
		})


		setPlanList(() => res)
	}

	useEffect(() => {
		initListCloudPlan();
		receivePostMessage();

		return () => {
			window.removeEventListener('message', processMessageEvent);
		}
	}, [])

	useEffect(() => {
		if (target == null || target == undefined) {
			setCurrent(1)
		}
	}, [target])

	useEffect(() => {
		if(updateCloudPageZU){
			let a = new Date().getTime();
			setUpdateMainPage(() => a);
			setUpdateCloudPageZU(false);
		}
	}, [updateCloudPageZU])

	const receivePostMessage = () => {
		window.removeEventListener('message', processMessageEvent);
		window.addEventListener('message', processMessageEvent)
	}

	const processMessageEvent = (evt) => {
		let data = evt.data
		window['message-test'] = (evt)

		let a = new Date().getTime();
		if([
			'https://payment.zmodo.com', 'https://payment.meshare.com', 'https://payment.funlux.com',
			'https://payment.sho-ai.com', 'https://user.zmodo.com'
		].includes(evt.origin)){
			if(data === 'complete-subscription-cvr'){
				setUpdateMainPage(() => a)
				if(['zmodo', 'zmd'].includes(_platform)){
					setCloudInfoZU({open: false, data: cloudInfoZU.data})
				}

			}else if(data === 'complete-subscription-vdr'){
				setUpdateMainPage(() => a)
				if(['zmodo', 'zmd'].includes(_platform)){
					setVaultInfoZU({open: false, data: vaultInfoZU.data})
				}

			}
		}else if(['https://11-web.meshare.cn', 'https://11-web.meshare.com', 'https://user.zmodo.com'].includes(evt.origin)){
			if(data === 'complete-freetrial-subscription-cvr'){
				setUpdateMainPage(() => a)
				setFreeTrialSubscribeZU({open: false, data: freeTrialSubscribeZU.data})
			}else if(data === 'complete-cancel-subscription-cvr'){
				setUpdateMainPage(() => a);
				setDeviceDetailZU({open: false, data: deviceDetailZU.data});
			}
		}
	}

	const handleQuanity = (target) => {
		if (target === "add") {
			let count = num + 1;
			count = count > 2 ? 100 : count
			setNum(count)
			yearplusObj.num += 1
			setYearPlusObj(() => yearplusObj)

		} else {
			let count = num;
			count = count === 100 ? 3 : count
			if (num === 1) {
				return false
			}
			setNum(count - 1)
		}

	}

	return <>
		<CloudPlanOne key={updateMainPage} />

		<MaybeLaterModal open={isModalOpen} onOpen={setIsModalOpen}/>

		<DetailsModal
			open={orderDetailZU.open}
		/>

		{/*<ActivateCloudModal open={isActivateCloudModal} onOpen={setIsActivateCloudModal} />*/}
		<ChangeCloudDeviceModal
			open={changeCloudDeviceZU.open}
		/>

		{['smartz'].includes(_platform) && <SecuritySubscribeModal open={cloudInfoZU.open}/>}
		{['zmodo', 'zmd'].includes(_platform) && cloudInfoZU.open && <SubscribeModelCVRBRT/>}

		{['smartz'].includes(_platform) && <VaultSubscribeModal open={vaultInfoZU.open}/>}
		{['zmodo', 'zmd'].includes(_platform) && vaultInfoZU.open && <SubscribeModelVaultBRT/>}

		{['zmodo', 'zmd'].includes(_platform) && freeTrialSubscribeZU.open && <SubscribeModelFreetrial />}

		{['zmodo', 'zmd'].includes(_platform) && deviceDetailZU.open && <DeviceDetailModel/>}

		<CheckOut
			open={paymentInfoZU.open}
		/>

		{/* <WelcomeModal open={isWelcomeModal} onOpen={setIsWelcomeModal} /> */}
	</>
}

export default CloudPlan
