import './index.less'
import HeadNav from '../../../components/headNav'
import {getFileDetails, getFolderDetails, renameFile, renameFolder} from '../../../server/folder'
import {useEffect, useState} from 'react';
import NameModal from '../../../components/name-modal'
import dayjs from 'dayjs'
import {useLocation, useNavigate} from "react-router-dom";
import {getLGToken, getUserBasicFolderIDS, isJSON, sizeFormat} from "../../../utils/common";
import fileType from "../../../utils/fileType";
import {getStorageInformation} from "../../../server/storage";
import {Modal, Toast, Image} from "antd-mobile";
import {PlayOutline} from 'antd-mobile-icons';
import NAPI from "../../../utils/NAPI";
import getApiUrl from "../../../server/apiUrl";
import token from "../../../utils/token";
import jumpPage from "../../../utils/jumpPage";
import {useMyNavigate} from "../../../hook/useMyNavigate";

const fileUrl = getApiUrl('cloudDisk');

const Details = () => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const id = params.get('id')
	const type = params.get('type')
	const [open, setOpen] = useState<boolean>(false)

	const [filesObj, setFilesObj] = useState<any>({})
	const [openRenameWin, setOpenRenameWin] = useState<boolean>(false)
	const [renameInfo, setRenameInfo] = useState({error: '', open: false})
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})

	const navigate = useNavigate();
	const {navigateX} = useMyNavigate()

	let searchParams = new URLSearchParams(location.search);
	let _frompage:any = searchParams.get('frompage');

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if(NAPI.check()){
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	const getDiskStorageSize = async () => {
		let res:any = await getStorageInformation();
		let {data, result} = res;
		if(result === 'ok' && data){

			if(isJSON(data)){
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id
				}

				if(user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id){
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data)
			}
		} else if (Number(result) === 401){
			navigateX('/login')
		}
	}

	const initGetFileDetails = async (id) => {
		let _func = type === 'undefined' ? getFolderDetails : getFileDetails;
		let req:any = null;
		if(_frompage === 'trash'){
			req = {isRecycle:1}
		}
        let token = getLGToken()
		const res: any = await _func(id, req)
		if (res && res.result === "ok") {
			let _arr = getUserBasicFolderIDS();

			let _data = res.data;
			if(type === 'undefined'){
				if(_arr.includes(_data.id)){
					_data.disabled = true;
					_data.system_dir = true;
				}
			}else{
				if([1,3].includes(_data.type)&& _data?.cover){
					// item.icon = getFileByUrl({url: item.url, name: item.name})
					_data.icon = `${fileUrl}/disk/download_file?url=${_data?.cover}&name=${_data.name}&token=${token}`;
				}
			}


			if(_frompage === 'trash'){
				_data.disabled = true;
				_data.system_dir = true;
			}

			setFilesObj(_data)
		}
	}

	const initPage = async (id) => {
		await getDiskStorageSize();
		initGetFileDetails(id)
	}

	useEffect(() => {
		initPage(id)
	}, [])

	const handleRenameFolder = () => {

		if(tipFunc()) return;

		// setOpen(true)
		let _renameInfo = {
			error: '',
			open: true
		}
		setRenameInfo(() => _renameInfo)
		setOpenRenameWin(() => true)
	}

	const init = async () => {

	}

	const onCloseRenameFunc = () => {
		setOpenRenameWin(() => false)
		let _renameInfo = {
			error: '',
			open: false
		}
		setRenameInfo(() => _renameInfo)
	}

	const onActionRenameFunc = (v) => {
		let _func = filesObj.type === undefined ? renameFolder : renameFile;

		if(v === '' || v === undefined) {
			let _renameInfo = {
				error: 'Cannot be empty',
				open: true
			}
			setRenameInfo(() => _renameInfo)
			return;
		}

		_func(filesObj.id, v).then((res:any) => {
			let {result, data, error} = res;

			if(result === 'ok' && data){
				onCloseRenameFunc();
				initGetFileDetails(id);
			}else{
				if(error){

					let _renameInfo = {
						error: error,
						open: true
					}
					setRenameInfo(() => _renameInfo)
				}
			}
		}).catch(e => {
			console.log(e)
			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	const tipFunc = () => {

		if(Number(cloudDiskInfo.size) === 0 || Number(cloudDiskInfo.usable) < 0) {
			let _content = 'Access restricted';
			if (Number(cloudDiskInfo.size) > 0 && Number(cloudDiskInfo.usable) < 0) {
				_content = 'Not enough space'
			}
			// Modal.alert({
			// 	title: 'Tips',
			// 	content: _content,
			// 	destroyOnClose: true,
			// 	confirmText: 'OK'
			// })

			const _show_modal = Modal.show({
				title: 'Tips',
				content: _content,
				closeOnAction: !true,
				actions: [
					{
						key: 'submit',
						text: 'Restore now',
						primary: true,
					},
					{
						key: 'close',
						text: 'Later',
					},
				],
				onAction: ({key}) => {
					if(key === 'close'){
						_show_modal.close();
					}else if(key === 'submit'){
						jumpPage(navigateX, `/plan`);
						_show_modal.close();
					}
				}
			})

			return true;
		}else{
			return false;
		}
	}

	return <div className="details">
		<HeadNav title={filesObj.name}/>
		<div className="details-con">
			<div className="details-con-img">
				{
					filesObj.icon ? <>
						<Image src={filesObj.icon} />
						{filesObj.type === 1 && <span className={'a-ply-btn'}><PlayOutline fontSize={30} color={'#ffffff'}/></span>}
					</>
					:
					<>
						<img src={ filesObj.type === undefined ? '/res/icons/icon_folder.svg' : fileType[filesObj.type]['icon'] } />
					</>
				}

			</div>
			<div className="details-content">
				<div className="details-content-item">
					<div>
						<p>Folder Name</p>
						<p>{filesObj.name}</p>
					</div>
					{
						filesObj.disabled ? null : <div
							onClick={handleRenameFolder}
							style={{opacity: `${Number(cloudDiskInfo.size) > 0 ? 1 : 0.65}`}}
						>
							<img src="/res/icons/icon_shape.svg"/>
						</div>
					}

				</div>
				<div className="details-content-item">
					<div>
						<p>Type</p>
						<p>{filesObj.type === undefined ? 'Folder' : fileType[filesObj.type]['label']}</p>
					</div>
					<div>
					</div>
				</div>
				<div className="details-content-item">
					<div>
						<p>Location</p>
						<p>{filesObj?.location && filesObj?.location?.slice(1)}</p>
					</div>
					<div>
					</div>
				</div>

				<div className="details-content-item">
					<div>
						<p>Storage Used</p>
						<p>{Number.isNaN(Number(filesObj.size)) ? 'undefined' : sizeFormat(filesObj.size, 1000)}</p>
					</div>
					<div>
					</div>
				</div>
				<div className="details-content-item">
					<div>
						<p>Create Time</p>
						<p>{dayjs(filesObj.create_at).format('DD-MM-YYYY h:mm')}</p>
					</div>
					<div>
					</div>
				</div>
				<div className="details-content-item">
					<div>
						<p>Modified Time</p>
						<p>{dayjs(filesObj.update_at).format('DD-MM-YYYY h:mm')}</p>
					</div>
					<div>
					</div>
				</div>
			</div>
		</div>
		{/*<RenameFile visible={open} onVisible={setOpen} init={init}/>*/}
		<NameModal
			title={'Rename File'}
			value={filesObj.name}
			fileType={filesObj.ext}
			visible={renameInfo.open}
			error_message={renameInfo.error}
			onClose={onCloseRenameFunc}
			onAction={onActionRenameFunc}
			closeButtonText={'Cancel'}
			actionButtonText={'Confirm'}
		/>
	</div>
}

export default Details
