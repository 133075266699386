import { Checkbox, Ellipsis, Image } from "antd-mobile";
import { Col, Row, Input, message } from 'antd';
import { getLGToken, isFunction, sizeFormat } from "../../utils/common";
import { getFileDetails, getFolderDetails, renameFile, renameFolder, getCollect } from '../../server/folder'
import { useEffect, useRef, useState } from "react";
import { getUserBasicFolderIDS } from "../../utils/common";
import './index.less'
import { PlayOutline } from 'antd-mobile-icons'
import _ from 'lodash';
import { encode } from "js-base64";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import TipsModal from "../../components/tips-modal";

import kkFileType from "../../utils/kkFileType";
import getApiUrl from "../../server/apiUrl";

const fileUrl = getApiUrl('cloudDisk');

function ContentItem(props) {
	const { list, isShow, onClick, onHandleMore, onLongPress, longPress, folderCheckedList, fileCheckedList, style, inputVal, onInputVal, onPositionObj,
		onFolderList, onFileList, folderList, fileList, onIsAllSelect, isAllHide, onIsAllHide, cloudDiskInfo, currentBg, onCurrentBg, init } = props

	const timeOutRef = useRef<any>(0)
	const [currentInput, setCurrentInput] = useState<number>(0)
	const [newList, setNewList] = useState<any[]>(list)
	const [currentMouseEnter, setCurrentMouseEnter] = useState<number>(0)
	const [isTipsModal, setIsTipsModal] = useState<boolean>(false);


	const pageTopRef = useRef(0);

	const divRef = useRef(null);
	const navigate = useNavigate()
	const location = useLocation()
	let _orderExpired = Number(cloudDiskInfo?.size) === 0
	let _has_unuse_size: any = Number(cloudDiskInfo?.usable);
	_has_unuse_size = isNaN(_has_unuse_size) ? -1 : _has_unuse_size;
	_has_unuse_size = _has_unuse_size > 0;



	useEffect(() => {
		pageTopRef.current = window.pageYOffset;
	}, []);

	const openRow = (e, o) => {
		e.stopPropagation();

		let { ext } = o;
		let _ext = ext ? ext.toLowerCase() : '';
		if (_ext && kkFileType.includes(_ext)) {
			let token = getLGToken();

			// let _url = `${fileUrl}/disk/download_file?url=${o?.url}&name=${encodeURIComponent(o?.name)}&token=${token}&fullfilename=${encodeURIComponent(o?.name)}`;
			let _url = `${fileUrl}/disk/download_file?id=${o?.id}&name=${encodeURIComponent(o?.name)}&token=${token}&fullfilename=${encodeURIComponent(o?.name)}`;


			window.open(`/file-preview?url=${encodeURIComponent(encode(_url))}&filename=${o.name}&type=${_ext}`);
			return;
		}

		if (isFunction(onClick)) {
			onClick(o)
		}
	}


	useEffect(() => {
		if (list.length !== 0) {
			setNewList(list)
		} else {
			setNewList([])
		}

	}, [folderList, fileList])



	const onHandleMoreFunc = (e, o) => {
		e.stopPropagation();
		if (isFunction(onHandleMore)) {
			onHandleMore(o)
		}
		onCurrentBg(o.id)
		const rect = e.target.getBoundingClientRect();
		if (rect.bottom >= 450) {
			onPositionObj({
				top: 380,
				left: rect.left - 300
			})
		} else if (rect.bottom <= 406) {
			onPositionObj({
				top: 360,
				left: rect.left - 300
			})
		} else {
			onPositionObj({
				top: rect.top + 20,
				left: rect.left - 300
			})
		}
		// onIsAllHide(false)

	}

	const pressEvent = (e) => {
		let { type } = e;

		const countTime = () => {
			clearTimeout(timeOutRef.current);
			timeOutRef.current = setTimeout(() => {
				if (isFunction(onLongPress)) {
					onLongPress(true)
				}
			}, 900);
		}

		if (type === 'touchstart') {
			countTime();
		} else if (type === 'touchend') {
			clearTimeout(timeOutRef.current);
		} else if (type === 'touchmove') {
			clearTimeout(timeOutRef.current);
		}
	}

	let _folderCheckedList = folderCheckedList ? folderCheckedList : [];
	let _fileCheckedList = fileCheckedList ? fileCheckedList : [];

	const handleInput = (e,) => {
		let val = (e.target.value);
		// console.log(val ,'122')

		if (val) {
			onInputVal(val)

		} else {
			onInputVal('')
		}
	}

	// 鼠标右键事件
	const handleRight = (e: any, item: any) => {
		e.preventDefault();
		if (item.status === 0 || _orderExpired) {
			return false
		}
		let _arr = getUserBasicFolderIDS();
		if (_arr.includes(item.id)) {
			message.warning("The system file cannot be modified !")
			return false;
		} else {
			setCurrentMouseEnter(0)

			setCurrentInput(item.id)
			onCurrentBg(0)
			let newName = (item.name).replace(/\.[^/.]+$/, "")
			onInputVal(newName)

		}

	}

	const handleAgree = async (item: any) => {
		let oldList = _.cloneDeep(newList)

		if (inputVal) {
			let _func = item.type === undefined ? renameFolder : renameFile;
			let newName = (inputVal).replace(/\.[^/.]+$/, "")
			let newName1 = (inputVal).replace(/\.[^/.]+$/, "")
			let oldNmae
			if (item.type !== undefined) {
				oldNmae = (item.name).match(/.[^.]+$/)[0]
				newName1 = `${newName1}${oldNmae}`
			} else {
				oldNmae = item.name
			}
			const res: any = await _func(item.id, newName1)
			if (res.result === 'ok') {
				message.success("Rename successfully!")
				setCurrentInput(0)

				oldList.map((it: any) => {
					if (it.id === item.id) {
						if (item.type === undefined) {
							it.name = newName;
						} else {
							it.name = `${newName}${oldNmae}`;
						}
					}
					return it
				})
				setNewList(oldList)
				onInputVal('')
				setTimeout(() => {
					init()
				}, 500)
			} else {
				message.error(res.error)
			}
		} else {
			message.warning("Cannot be empty!")
		}

	}

	const handleCancel = () => {
		setCurrentInput(0)
		onInputVal('')
	}

	// 移入
	const handleMouseEnter = (item) => {
		setCurrentMouseEnter(item.id)
	}
	// 移出
	const handleMouseLeave = () => {
		setCurrentMouseEnter(0)
	}

	const handleSelect = (item) => {
		let newFolderList = _.cloneDeep(folderList)
		let newFileList = _.cloneDeep(fileList)
		let newArrFile: any = []
		let newArrFold: any = []
		if (item.type || item.type === 0) {
			newFileList.map((it: any) => {
				if (item.id === it.id) {
					it.isSelect = !it.isSelect;
					newArrFile.push(it)
				}
				return it
			})
			onFileList(newFileList)
		} else {
			newFolderList.map((it: any) => {
				if (item.id === it.id) {
					it.isSelect = !it.isSelect;
					newArrFold.push(it)
				}

				return it
			})
			onFolderList(newFolderList)
		}
		let isTrue1 = newFileList.some((item: any) => item.isSelect)
		let isTrue2 = newFolderList.some((item: any) => item.isSelect)
		if (isTrue1 || isTrue2) {
			onIsAllSelect(2)
			onIsAllHide(true)
		} else if (!isTrue1 && !isTrue2) {
			onIsAllSelect(1)
			onIsAllHide(false)
		}
	}

	const handleFavourite = (o) => {
		// 判断网盘到期
		if ((!_has_unuse_size) || Number(cloudDiskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}
		initCollect(o)
	}

	const initCollect = async (o) => {
		let params: any = {}
		if (o.type) {
			params = {
				type: 2,
				fid: o.id
			}
		} else {
			params = {
				type: 1,
				fid: o.id
			}
		}




		const res: any = await getCollect(params)
		if (res.result === "ok") {
			init()
		} else {
			message.success(`${res.error}`)
		}
	}


	return <>
		{
			newList.length > 0 && <> {isShow ?
				<div className="content-two">
					<Row gutter={21.5}>
						{
							newList.map((item: any, index: number) => {

								let _checked = false;
								if (item.type) {
									_checked = _fileCheckedList.includes(item.id)
								} else {
									_checked = _folderCheckedList.includes(item.id)
								}
								return <Col key={index}><div
									className="content-two-item"
									onMouseLeave={handleMouseLeave} onMouseEnter={() => handleMouseEnter(item)}
									onDoubleClick={(e) => openRow(e, item)}
									// onTouchStart={pressEvent}
									// onTouchEnd={pressEvent}
									// onTouchMove={pressEvent}
									style={{ border: item.id === currentBg ? "1px #666 solid" : "", ...style }}
								>

									{
										longPress || item.disabled ? null : <>
											<div className="more" ref={divRef} >
												<span className={"a-more-btn"} onClick={(e) => onHandleMoreFunc(e, item)}>
													<img src="/res/icons/icon_more.svg" alt="" />
												</span>
											</div>
										</>
									}
									{
										item.id === currentInput && <div className="slelect">
											<p onClick={() => handleAgree(item)}><img src="/res/icons/icon_agree.svg" alt="" /></p>
											<p onClick={handleCancel}><img src="/res/icons/icon_cancel.svg" alt="" /></p>
										</div>
									}


									{
										((location.pathname.includes("/vault") || location.pathname.includes("/favourite") || location.pathname.includes("/vault/large-file")) && (item.id === currentMouseEnter) || item.isHide || isAllHide) &&

										<div className="content-select" onClick={() => handleSelect(item)}>
											{item.isSelect ? <img src="/res/icons/icon_select1_active.svg" style={{ width: "18px", height: "18px" }} alt="" /> : <img src="/res/icons/icon_select1.svg" style={{ width: "18px", height: "18px" }} alt="" />}
										</div>
									}

									<div className={`file ${item.cover ? 'real-file' : ''} ${item.type === undefined ? '-folder' : ''}`} onDoubleClick={(e) => openRow(e, item)} onContextMenu={(e) => handleRight(e, item)}>
										{/*<img src={item.icon} alt=""/>*/}
										{
											item.type === undefined ?
												<Image src={item.icon} lazy={true} fit={'contain'} className={'-dir'} alt="" />
												:
												<Image src={item.icon} lazy={true} fit={'cover'} alt="" />
										}
										{(item.cover && item.type === 1) && <span className={'vblock-player-btn'}><PlayOutline fontSize={30} color={'#ffffff'} /></span>}
									</div>

									<div className={'--bottom-text-box'}>
										<div className="txt">
											
											<span className="txt-nowrap">{item.name}</span>
										</div>
										<p className={'--date'}>
										{item.collect && <img src="/res/icons/icon_favourite_on.svg" alt="" style={{ width: "11px", marginRight: "5px" }} />}
											{item.content}
											</p>
									</div>
									{item.id === currentInput && <div className="right-context-menu-tom">
										<div className="right-context-menu-con">
											<p style={{ marginTop: "-20px" }}><Input value={inputVal} onChange={(e) => handleInput(e)} /></p>
										</div>
									</div>}
									{/*<div className="txt">*/}
									{/*	{item.collect && <img src="/res/icons/icon_favourite_on.svg" alt="" style={{width: "10px", marginRight: "5px"}}/>}*/}
									{/*	<Ellipsis direction='end' content={item.name} />*/}
									{/*</div>*/}
									{
										longPress && !item.disabled ? <>
											<div className={'-card-checkbox'}>
												<Checkbox checked={_checked}></Checkbox>
											</div>
										</>
											:
											<>

											</>
									}

								</div>
								</Col>
							})
						}
					</Row>

				</div> :
				<>
					{
						newList.map((item: any, index: number) => {
							let _checked = false;
							if (item.type) {
								_checked = _fileCheckedList.includes(item.id)
							} else {
								_checked = _folderCheckedList.includes(item.id)
							}
							return (
								<div className="content-all content-all-tom" key={index} onMouseLeave={handleMouseLeave} onMouseEnter={() => handleMouseEnter(item)}>
									{/* {
										item.isHide && <div className="content-select">
										<img src="/res/icons/icon_select1.svg" style={{ width: "18px", height: "18px" }} alt=""/>
									 </div>
									} */}
									{
										((location.pathname.includes("/vault") || location.pathname.includes("/favourite") || location.pathname.includes("/vault/large-file")) && (item.id === currentMouseEnter) || item.isHide || isAllHide) &&
										<div className="content-select" onClick={() => handleSelect(item)}>
											{item.isSelect ? <img src="/res/icons/icon_select1_active.svg" style={{ width: "18px", height: "18px" }} alt="" /> : <img src="/res/icons/icon_select1.svg" style={{ width: "18px", height: "18px" }} alt="" />}
										</div>
									}

									<div
										className={item.id === currentInput ? "content-item content-item-active" : "content-item"}
										style={{ background: item.id === currentBg ? "#fff" : "" }}
										key={index}
									// onTouchStart={pressEvent}
									// onTouchEnd={pressEvent}
									// onTouchMove={pressEvent}


									>
										<div className="content-item-type" onDoubleClick={(e) => openRow(e, item)} onContextMenu={(e) => handleRight(e, item)}>
											<span className={'vlist-file-icon-box'}>
												<Image src={item.icon} lazy={true} fit={'cover'} alt="" />
												{(item.cover && item.type === 1) && <span className={'vlist-player-btn'}><PlayOutline fontSize={20} color={'#ffffff'} /></span>}
											</span>

											<div className='content-item-txt'>
												<p>
													{item.name}
													{item.collect ? <img src="/res/icons/icon_favourite_on.svg" onClick={() => handleFavourite(item)} alt="" className="icon-favourite-on" /> : <img onClick={() => handleFavourite(item)} src="/res/icons/icon_favourite_v2.svg" alt="" className="icon-favourite-on"></img>}
												</p>
												{/* <p>{item.content}</p> */}
											</div>
										</div>
										<div className="content-item-date">{item.createdTime}</div>
										<div className="content-item-date">{item.modifiedTime}</div>
										<div className="content-item-size">{item.size ? sizeFormat(item.size) : "--"}</div>
										{item.id === currentInput && <div className="right-context-menu">
											<div className="right-context-menu-con">
												<p><Input value={inputVal} onChange={(e) => handleInput(e)} /></p>
												<p onClick={() => handleAgree(item)}><img src="/res/icons/icon_agree.svg" alt="" /></p>
												<p onClick={handleCancel}><img src="/res/icons/icon_cancel.svg" alt="" /></p>
											</div>
										</div>}



										{
											longPress ? <>
												{
													<div className={'content-item-checkbox'} onClick={(e) => openRow(e, item)}><Checkbox checked={_checked}></Checkbox></div>
												}
											</>
												:
												<>
													{
														item.id !== currentInput &&
														((!item.delete)) && <div className='content-item-more' onClick={(e) => onHandleMoreFunc(e, item)}>
															<img src="/res/icons/icon_more.svg" alt="" />
														</div>
													}
												</>

										}
									</div>
								</div>
							)
						})
					}
				</>
			}
			</>
		}
		<TipsModal visible={isTipsModal} onVisible={setIsTipsModal} diskInfo={cloudDiskInfo} />
	</>
}

export default ContentItem
