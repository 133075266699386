import service from '../newRequest'
import getApiUrl from "../apiUrl";

import requsetData from "../request";
import {getLGToken, isJSON, json2params} from "../../utils/common";

// const url = getApiUrl('cloudDisk');
const url 		= '/vdr' //getApiUrl('cloudDisk')
const pfApp 	= '/app'// //getApiUrl('PFApp')
const store 	= getApiUrl('WEBPay'); //'/store'; //
const mngMop 	= '/mng-mop' // //getApiUrl('PFMng')


export function getFolder(data) {
    // const token = "7e0db25286bb079545500877bc13a9ec"
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    return service.get(`${url}/folder?dirId=${data.dirId}&order=${data.order}&orderBy=${data.orderBy}&key=${data.key}&page=${data.page}&pageSize=${data.pageSize}`, null, headers)
}

// subscription

 // List cloud plan
export function getListCloudPlan() {
	let token = getLGToken()
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getLGToken()}`
    }
    return service.get(`${store}/subscribe/plan/${token}`, null, headers)
}

 // List subscription by user token 订单列表
 export function getListSubscription() {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/subscribe/list/${getLGToken()}`, null, headers)
}


 // Get subscription and payment info 订单列表
 export function getInfoSubscription(id) {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/subscribe/info/${id}/${getLGToken()}`, null, headers)
}

// Cancel subscription
export function cancelCloudSubscription(data) {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.put(`${store}/subscribe/cancel`, data, headers)
}

// Create subscription
export function getSubscriptionSubmit() {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.post(`${store}/subscribe/submit`, null, headers)
}

// Change subscription (upgrade/downgrade)
export function getSubscriptionChange(data) {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.post(`${store}/subscribe/change`, data, headers)
}

// Get subscription and payment info
export function getSubscriptionInfo(id) {
    let token = getLGToken()
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/subscribe/info/${id}/${token}`, null, headers)
}

// List payment history
export function getSubscribeList(id) {
    let token = getLGToken()
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/subscribe/list/${id}/${token}`, null, headers)
}


// Vault
// var List列表订单

export function getVdrList() {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/vdr/list/${getLGToken()}`, null, headers)
}

// List vdr plan
export function getVdrPlanList() {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/vdr/plan`, null, headers)
}

// Cancel vdr cancel
export function cancelVaultSubscription(data) {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.put(`${store}/vdr/cancel`, data, headers)
}


// Create vdr subscription
export function getVdrSubmit(data) {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.post(`${store}/vdr/submit`, data, headers)
}

// Change vdr subscription (upgrade/downgrade)
export function getVdrChange(data) {
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.post(`${store}/vdr/change`, data, headers)
}

// Get vdr subscription and payment info

export function getVdrInfo(id) {
    let token = getLGToken()
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/vdr/info/${id}/${token}`, null, headers)
}


// List vdr payment history

export function getVdrArr(id) {
    let token = getLGToken()
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${store}/vdr/list/${id}/${token}`, null, headers)
}


// 设备列表
export function getDeviceStorageList() {
    let token = getLGToken()
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${mngMop}/device/storage_list?token=${token}`, null, headers)
}

// /subscribe/session
export function getSubscribeSession() {
    let token = getLGToken()
    let headers = {
        'Content-Type': 'application/json',
    }
    return service.get(`${mngMop}/device/storage_list?token=${token}`, null, headers)
}

// 绑定安卓订单设备
export function bundledDeviceAndroid(data){
	// 'https://11-mng-mop.meshare.cn/cloud/gpa_devices?callback=?'
	// {tokenid: _tokenid, token: _tokenid, order_id: oid, physical_id: pids.join('_')}
	let headers = {
		"Content-Type": "application/x-www-form-urlencoded"
		// 'Content-Type': 'application/json',
	}
	return service.post(`${mngMop}/cloud/gpa_devices`, data, headers)
}

// 绑定苹果订单设备
export function bundledDeviceApple(data){
	// {tokenid: _tokenid, token: _tokenid, order_id: oid, physical_id: pids.join('_')}
	// 'https://11-mng-mop.meshare.cn/cloud/apple_devices?callback=?'

	let headers = {
		"Content-Type": "application/x-www-form-urlencoded"
		// 'Content-Type': 'application/json',
	}
	return service.post(`${mngMop}/cloud/apple_devices`, data, headers)
}

// 绑定web订单设备
export function bundledDeviceWEB(data){
	// 'https://11-mng-mop.meshare.cn/cloud/change_pay_order_devices'
	// let req = {
	// 	token: _tokenid,
	// 	sub_token: this.order_id,
	// 	devices: pids // []
	// }

	let headers = {
		"Content-Type": "application/x-www-form-urlencoded"
		// 'Content-Type': 'application/json',
	}
	return service.post(`${mngMop}/cloud/change_pay_order_devices`, data, headers)
}

// 激活订单
export function activateStripeOrder(data){
	let headers = {
		// "Content-Type": "application/x-www-form-urlencoded"
		'Content-Type': 'application/json',
	}
	return service.put(`${store}/order/activate`, data, headers)
}

// 获取官网订单列表
export function getShippingOrderList(){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${store}/order/list/${token}`, null, headers)
}

// 查询支付结果
export function checkPaymentResultCloud(sessionId){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${store}/subscribe/session/${sessionId}/${token}`, null, headers)
}

// 查询支付结果
export function checkPaymentResultVault(sessionId){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${store}/vdr/session/${sessionId}/${token}`, null, headers)
}

// 是否参加平台活动
export function checkPFPromo(){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${pfApp}/promotion/is_join?token=${token}&client=2`, null, headers)
}

// 获取活动banner数据
export function getPFPromoBanner(channel_type, client){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${pfApp}/promotion/ad_list?token=${token}&channel_type=${channel_type}&client=${client}`, null, headers)
}

// 获取支付历史记录
export function getPaymentHistoryCloud(id){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${store}/subscribe/history/${id}/${token}`, null, headers)
}
export function getPaymentHistoryVault(id){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${store}/vdr/history/${id}/${token}`, null, headers)
}

// 获取支付信息详情
export function getPaymentInfoDetail(id){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${store}/subscribe/info/${id}/${token}`, null, headers)
}

// 查询是否是是bundle order，只可升级，不能降级， 0：不受限制， 1，只能升级
export function checkBundleOrderStatus(id){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	return service.get(`${store}/order/count/${token}/${id}`, null, headers)
}

// 提交调查表数据
export function submitReport(data){
	let headers = {
		"Content-Type": "application/x-www-form-urlencoded"
	}
	return service.post(`${mngMop}/cloud/cloud_report`, data, headers)
}

// 获取 braintree 订单
export function getBraintreeOrderList(){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	let url = `https://payment.zmodo.com/subscribe/info?tokenid=${token}`;
	return service.get(url, null, headers)
}

// 获取 braintree cvr 数据
export function getBRTCvrDeviceList(){

}

// 取消 braintree cvr 订单
export function cancelBRTVaultOrder(){
	let token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
	}
	let url = `https://payment.zmodo.com/vdr/unsubscribe?tokenid=${token}`;
	return service.get(url, null, headers)
}

