import axios from "axios";
import token from "../utils/token";
import {Toast} from "antd-mobile";
import NAPI from "../utils/NAPI";
import {useEffect} from "react";
import {getLGToken} from "../utils/common";

let count = 0;

export default function requsetData(url ,params, _config={headers:'', method: 'get'}){
	// let _token = localStorage.getItem('my-cloud-disk-token') === null ? token : sessionStorage.getItem('my-cloud-disk-token');
	let _token = getLGToken();

	const config:any = {
		url: url,
		method: _config.method, //get delete post put
		headers: {
			Authorization:  `Bearer ${_token}`
		}
	}

	if(_config.method === 'post' || _config.method === 'put' || _config.method === 'delete'){
		config.data = params;
	}


	const tokenCallback = (v) => {
		// sessionStorage.setItem('my-cloud-disk-token', v);
		localStorage.setItem('token', v);
        // setLoading((() => false))
        Toast.clear();
		// setLoading((() => false))
		Toast.clear();
		count = 0;
		window.location.reload();
	}

	window['tokenCallback'] = tokenCallback

	const getTokenFunc = () => {
		if(count === 1) return;
		console.log('WaitingToken getToken requsetData')
		if(NAPI.check()){
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
		count = 1;
	}


	return new Promise((resolve, reject) => {
		axios(config).then(({data}) => {
			// console.log(data, 'ajaxs')
			const {result} = data;
			if(Number(result) === 401){
				getTokenFunc();
			}
			resolve(data)
		}).catch((err) => {
			reject(err)
		})
	})
}
