import HeadNav from "../../../components/headNav";
import './index.less'
import {Checkbox, Ellipsis, Space, Toast} from "antd-mobile";
import {useEffect, useState} from "react";
import NAPI from "../../../utils/NAPI";

const icon_file_list = '/res/icons/icon_file_list.svg';
const icon_file_block = '/res/icons/icon_file_block.svg';
const icon_order_asc = '/res/icons/icon_order_asc.svg';
const icon_order_desc = '/res/icons/icon_order_desc.svg';

export default function FileItemsPage() {
	const [isShowList, setIsShowList] = useState<boolean>(false);
	const [isShowDesc, setIsShowDesc] = useState<boolean>(false);
	const [list, setList] = useState<any>([]);

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if(NAPI.check()){
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	useEffect(() => {
		let _list = [
			{id: 1, name: 'Screenshot_ 1', size: '20.6 MB', date: '2023-08-28', img: ''},
			{id: 2, name: 'Screenshot_ 2', size: '21.6 MB', date: '2023-08-29', img: ''},
			{id: 3, name: 'Screenshot_ 3', size: '22.6 MB', date: '2023-08-30', img: ''},
			{id: 4, name: 'Screenshot_ 4', size: '23.6 MB', date: '2023-08-31', img: ''},
		];

		setList(() => _list);
	}, [])

	const deleteFunc = () => {
		alert('删除');
	}

	return <>
		<div className={'page-file-items'}>
			<div className={'page-head-container'}>
				<HeadNav
					title={'Items in Trash'}
					endElement={<span onClick={deleteFunc}><img src={'/res/icons/icon_trash.svg'} /></span>}
				/>
			</div>
			<div className={'page-body-container'}>
				<div className={'a-c-intro'}>Select items to premanently delete.</div>

				<div className={'a-c-list-con'}>
					<div className={'a-c-ahead'}>
						<div className={'a-c-lbx'}>
							<Space align={'center'}>
								<Checkbox>
									All Items
								</Checkbox>
								<span> (7)</span>
							</Space>
						</div>
						<div className={'a-c-rbx'}>
							<Space align={'center'} style={{'--gap': '30px'}}>
								<span>Size <img onClick={() => setIsShowDesc(!isShowDesc)} src={isShowDesc?icon_order_desc:icon_order_asc} alt=""/></span>
								<span className={'a-c-chg-sty'}>
									<img
										onClick={() => setIsShowList(!isShowList)}
										src={!isShowList?icon_file_list:icon_file_block}
										alt=""
									/>
								</span>
							</Space>
						</div>
					</div>

					<div className={isShowList?'a-c-alist':'a-c-ablock'}>

						{
							list.map((item:any, index:number) => {

								return <div className={'a-c-aitem'} key={index}>
									<Checkbox>
										<div className={'a-c-aflex'}>
											<div className={'a-c-icon-name'}>
												<div className={'a-c-aicon'}>
													<img src={item.img} alt=""/>
												</div>
												<div className={'a-c-aname'}>
													<span className={'a-fs-h1'}><Ellipsis direction='end' content={item.name} /></span>
													{
														isShowList?<span className={'a-fs-h3'}>{item.date}</span>
															: <span className={'a-fs-h3'}>{item.size},{item.date}</span>
													}
												</div>
											</div>
											{isShowList&&<span className={'a-c-asize'}>{item.size}</span>}
										</div>
									</Checkbox>
								</div>
							})
						}


					</div>
				</div>
			</div>
		</div>
	</>
}
