import {BaseResource} from "../../../utils/resource";
import './index.less'
import HeadNav from "../../../components/headNav";
import {Checkbox, Ellipsis, Toast} from "antd-mobile";
import {useEffect, useRef, useState} from "react";
import NAPI from "../../../utils/NAPI";

const icon_Favourite = '/res/icons/icon_Favourite.svg'
const icon_Favourite_on = '/res/icons/icon_Favourite-on.svg'

export default function UploadPage() {
	const [list, setList] = useState<any>({});
	const [titleCheckedAll, setTitleCheckedAll] = useState<boolean>(false);
	const keysRef = useRef([])
	const checkedAllRef = useRef(false);

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if(NAPI.check()){
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	useEffect(() => {
		let _list = [
			{id:1, checked: false,},
			{id:2, checked: false,},
			{id:3, checked: false,},
			{id:4, checked: false,},
			{id:5, checked: false,},
			{id:6, checked: false,},
			{id:7, checked: false,},
			{id:8, checked: false,},
			{id:9, checked: false,},
		]
		let _obj = {
			today: {
				list: JSON.parse(JSON.stringify(_list)), //Object.assign([],_list),
				checkedArr: [],
			},
			yesterday: {
				list: JSON.parse(JSON.stringify(_list)),
				checkedArr: [],
			},
		}

		keysRef.current = Object.keys(_obj);

		setList(() => _obj)
	}, [])

	const chooseAllItems = () => {
		let _list = JSON.parse(JSON.stringify(list));
		let _keys = Object.keys(_list);
		for(let ky of _keys){
			console.log(ky);
			let _arr = [];

			_list[ky]['list'].map((item) => {

				if(!titleCheckedAll){
					item.checked = true;
					if(item.checked){
						_arr.push(item.id);
					}
				}else{
					item.checked = false;
				}

				return item;
			})

			_list[ky]['checkedArr'] = _arr;
		}
		setTitleCheckedAll(() => !titleCheckedAll)
		setList(() => _list);
	}

	const changeCheckedFunc = (e, o, ky) => {
		// let _list = [].concat(list);
		let _list = Object.assign({}, list)

		if(Array.isArray(_list[ky]['list'])){
			let _arr = [];
			_list[ky]['list'].map((item) => {

				if(o === null){
					if(e){
						item.checked = true;
						if(item.checked){
							_arr.push(item.id);
						}
					}else{
						item.checked = false;
					}

				}else{
					if(item.id === o.id){
						item.checked = !item.checked;
					}

					if(item.checked){
						_arr.push(item.id);
					}
				}

				return item;
			})

			_list[ky]['checkedArr'] = _arr;

			setList(() => _list);
		}
	}

	// console.log(list);
	// console.log(keysRef.current);

	const createElement = () => {
		let _element = [];

		if(Array.isArray(keysRef.current)){
			if(keysRef.current.length){
				for(let _key of keysRef.current){

					let _res = list[_key]['list'].map((item:any, index: number) => {
						return <div className={'a-file-item'} key={index}>
							<div className={'a-file-icon'}>

							</div>
							<span className={'a-collect-icon'}>
								<img src={icon_Favourite} alt=""/>
							</span>
							<span className={'a-check-box'}>
								<Checkbox
									value={item.id}
									checked={item.checked}
									onChange={(e) => changeCheckedFunc(e, item, _key)}
								/>
							</span>
						</div>
					})

					let _resii = <div className={'a-part-container'} key={_key}>
						<div className={'a-title-box'}>
							<span>{_key}</span>
							<span>
							<Checkbox
								indeterminate={list[_key]['checkedArr'].length > 0 && list[_key]['checkedArr'].length < list[_key]['list'].length}
								checked={list[_key]['checkedArr'].length === list[_key]['list'].length}
								onChange={(e) => changeCheckedFunc(e, null, _key)}
							/>
						</span>
						</div>
						<div className={'a-main-box'}>
							<div className={'a-file-list'}>
								{_res}
							</div>
						</div>
					</div>

					// _element = _element.concat(_resii)
					_element.push(_resii)
				}
			}
		}

		return _element;
	}

	return <>
		<div className={'page-upload'}>
			<div className={'page-head-container'}>
				<HeadNav
					title={'Select Files To Upload'}
					endElement={
						<span
							className={`a-opt-slt-all ${titleCheckedAll?'_ys':''}`}
							onClick={chooseAllItems}
						>
							All
						</span>
					}
				/>
			</div>
			<div className={'page-body-container'}>

				{
					createElement()
				}

			</div>
		</div>
	</>
}
