import { Row, Col, Button, Form, Input, DatePicker, Select } from 'antd';
import dayjs from "dayjs";
import _ from 'lodash';
import Icon from "@ant-design/icons";
import { useEffect, useState } from 'react';
import { getCloudOrderList } from '../../../server/plan'
import { getListSubscription, getVdrPlanList, getVdrChange } from '../../../server/cloudPlan'
import SecurityPlanPlus from '../../../assets/images/cloudPlan/security_plan_plus.png'
import SelectActive from '../../../assets/images/cloudPlan/select_active.svg'
import Selected from '../../../assets/images/cloudPlan/selected.svg'
import Unselected from '../../../assets/images/cloudPlan/unselected.svg'
import Add from '../../../assets/images/cloudPlan/add.svg'
import Minus from '../../../assets/images/cloudPlan/minus.svg'
import SafetyTips from '../../../assets/images/cloudPlan/safety_tips.svg'
import { useLocation, useNavigate } from "react-router-dom";
import MaybeLaterModal from '../components/maybeLaterModal'
import {getLGToken} from "../../../utils/common";



const SubscribeVault = (props) => {
    const { onIsDetailsModal, onDetailsObj } = props
    const navigate = useNavigate();
    const [isBanner, setIsBanner] = useState(true);
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const target = params.get('target')

    const [subscriptionsList, setSubscriptionsList] = useState<any[]>([])
    const [loseList, setLoseList] = useState<any[]>([])
    const [cloudPlanList, setCloudPlanList] = useState<any[]>([])
    // const [subscriptionsList, setSubscriptionsList] = useState<any[]>([])
    // const [loseList, setLoseList] = useState<any[]>([])
    const [detailsObj, setDetailsObj] = useState<any>({})
    const [plusList, setPlusList] = useState<any[]>([])
    const [isPlus, setIsPlus] = useState<any>(false)
    const [monthplusObj, setMonthPlusObj] = useState<any>({})
    const [yearplusObj, setYearPlusObj] = useState<any>({})
    const [premiumList, setPremiumList] = useState<any[]>([])
    const [platiumList, setPlatiumList] = useState<any[]>([])
    const [num, setNum] = useState<number>(1000)
    const [plan, setPlan] = useState<string>('basic')
    const [planList, setPlanList] = useState<any>([])
    const [currentPlanInfo, setCurrentPlanInfo] = useState<any>({ count: 0, plan: '', year: '', month: '' });
    const [isMaybeLaterModal, setIsMaybeLaterModal] = useState(false)
    const handleBanner = () => {
        setIsBanner(false);
    }


    useEffect(() => {
        // planList
        let _currentPlanInfo: any = _.cloneDeep(currentPlanInfo);
        for (let item of planList) {
            console.log(num, item.num, item, plan , item.species, '54')
            if (num === item.num && plan === item.species) {
                _currentPlanInfo.num = num;
                _currentPlanInfo.plan = plan;
                if (item.time === 'month') {
                    _currentPlanInfo.month = item.price;
                } else if (item.time === 'year') {
                    _currentPlanInfo.year = item.price;
                }
            }
        }


        if (_currentPlanInfo.num && _currentPlanInfo.plan) {
            setCurrentPlanInfo(_currentPlanInfo)
        }
        console.log(_currentPlanInfo);
    }, [num, plan, planList])

    console.log(currentPlanInfo, '54')

    useEffect(() => {
        if (target === "advanced") {
            setPlan(target)
            setNum(3000)
        } else {
            setPlan(target)
        }
    }, [target])


    const initListSubscription = async () => {
        const res: any = await getListSubscription()
        res.map((item: any) => {
            item.list = (item.description)?.split('_')

            return item
        })
        setCloudPlanList(res)
    }

    useEffect(() => {
        // initListSubscription()
        // initListCloudPlan()

    }, [])

    const handleViewDetails = (item: any) => {
        onIsDetailsModal(true)
        onDetailsObj(item)
    }


    const initVdrPlanList = async () => {
        // const res: any = await getVdrPlanList()
        const res:any = [
            {
              "product_key": "VDR_1_2_2",
              "sku": "vdr_year_3000_gb",
              "price": "159.99"
            },
            {
              "product_key": "VDR_1_2_1",
              "sku": "vdr_month_3000_gb",
              "price": "15.99"
            },
            {
              "product_key": "VDR_1_1_2",
              "sku": "vdr_year_1000_gb",
              "price": "59.99"
            },
            {
              "product_key": "VDR_1_1_1",
              "sku": "vdr_month_1000_gb",
              "price": "5.99"
            }
          ]
        res.map((item: any) => {
            item.skuList = (item.sku)?.split('_')
            if (Number(item.skuList[2]) === num) {
                item.species = "basic"
            } else {
                item.species = "advanced"
            }

            item.time = item.skuList[1]
            item.num = Number(item.skuList[2])
            item.storage = item.skuList[3]
            return item
        })

        setPlanList(() => res)
    }

    useEffect(() => {
        initVdrPlanList()
    }, [])


    // const handleQuanity = (target) => {
    //     if (target === "add") {
    //         let count = num + 1;
    //         count = count > 2 ? 100 : count
    //         setNum(count)
    //     } else {
    //         let count = num;
    //         count = count === 100 ? 3 : count
    //         if (num === 1) {
    //             return false
    //         }
    //         setNum(count - 1)
    //     }
    // }

    const handleMaybeLater = () => {

    }

    const handleCheckOut = () => {
        let newPlanList = _.cloneDeep(planList)
        let key:any
        let id:any
        newPlanList.map((item:any) => {
            if (item.species === currentPlanInfo.plan && item.num === currentPlanInfo.num ) {

                key = item.product_key
                id = item.id
            }
        })
        if (id) {
            const params = {
                user_token: getLGToken(),
                product_key: key,
                subscription_id: id
            }
            const res:any =  getVdrChange(params)
            navigate(`/check-out?product_key=${key}`)
        } else {
            navigate(`/check-out?product_key=${key}`)
        }

    }

   const handlePlan = (target:string) => {
     if (target === "basic") {
        setNum(1000)
     } else {
        setNum(3000)
     }
     setPlan(target)
   }


    return <>
        <div className='your-cart'>
            <div className='your-cart-title'>
                Subscribe
            </div>
            <div className='set-meal' style={{ width: "66.66%" }}>
                        <div className={plan === "basic" ? 'set-meal-l active' : "set-meal-l"} onClick={() => handlePlan("basic")}>
                            <h4>Basic</h4>
                            <p>
                                <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                                <span>1TB Cloud Storage</span>
                            </p>
                            <p>
                                <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                                <span>Transfer Files up to 50 GB</span>
                            </p>
                        </div>

                        <div className={plan === "advanced" ? 'set-meal-l vault-active1' : "set-meal-l"} onClick={() => handlePlan("advanced")} >
                            <h4>Advanced</h4>
                            <p>
                                <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                                <span>3TB Cloud Storage</span>
                            </p>
                            <p>
                                <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                                <span>Transfer Files up to 100 GB</span>
                            </p>
                        </div>
            </div>
            <div className='your-cart-card'>
                <div className='your-cart-card-l'>
                    <div>
                    <img src={SecurityPlanPlus} alt="" />
                    </div>
                    <div>
                        <p>Vault Plan</p>
                        <p>{plan}</p>
                    </div>
                </div>
                <div className='your-cart-card-m'>
                    {/* <div>Device Quantity</div>
                    <div>
                        <p onClick={() => handleQuanity("minus")} className={num === 1 ? "" : 'active'}><Icon component={Minus} style={{ fontSize: "14px" }} /></p>
                        <p>{num === 100 ? <span style={{ color: "#16ADFD" }}>Unlimited</span> : num}</p>
                        <p onClick={() => handleQuanity("add")} className={num >= 3 ? "" : 'active'}><Icon component={Add} style={{ fontSize: "14px" }} /></p>
                    </div> */}
                </div>
                <div className='your-cart-card-r'>
                    <p onClick={() => setIsPlus(true)}>
                        <Icon component={isPlus ? Selected : Unselected} style={{ fontSize: "24px", marginRight: "12px" }} />
                        ${currentPlanInfo.year} <span>/year</span></p>
                    <p onClick={() => setIsPlus(false)}>
                        <Icon component={!isPlus ? Selected : Unselected} style={{ fontSize: "24px", marginRight: "12px" }} />
                        ${currentPlanInfo.month} <span>/month</span></p>
                </div>
            </div>


            <div className='note'>
                <h4>Note:</h4>
                <p>The Vault Plan is a recurring subscription and will automatically renew. You can cancel at any time.</p>
            </div>
            <div className='footer'>
                <div className='footer-l'>
                    <p>
                        <Icon component={SafetyTips} style={{ fontSize: "18px" }} />
                    </p>
                    <p>The Vault storage is protected by bank-level encryption that can be accessed only by your personal account, to<br/>
                        ensure that your files are kept safe at all times.</p>
                </div>
                <div className='footer-r'>
                    <Button onClick={handleMaybeLater}>Maybe Later</Button>
                    <Button onClick={handleCheckOut}>Check Out</Button>
                </div>
            </div>
            <MaybeLaterModal open={isMaybeLaterModal} onOpen={setIsMaybeLaterModal}/>
        </div>
    </>
}

export default SubscribeVault
