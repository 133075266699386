import {Spin} from "antd";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import './index.less'

export default function VideoPlayer(props){
	const {
		url,
		autoplay,
		scaleMode,
		bufferTime,
		volume,
		keepScreenOn,
		physical_id,
		setVideoRef,
		width,
		height,
		isMain,
		playTimeDrive,
		setPlayTime,
		pause,
		setFps
	} = props
	const videoPlayRef = useRef(null);
	const [reconnectNum, setReconnectNum] = useState(0);
	const [liveURL, setLiveURL] = useState<string>('');
	const [timeCount, setTimeCount] = useState<number>(0);

	const [loadding, setLoading] = useState<any>(null);

	const videoElement = useRef(null)
	const timeRef = useRef<number>(0);
	const reconnectNumRef = useRef(0);

	const timeOutRef = useRef<any>(0);

	// const canvasRef = useRef(null)

	useEffect(() => {
		NodePlayer.debug(true)
		videoPlayRef.current = new NodePlayer()

		try {
			if(physical_id && videoPlayRef.current) {
				videoPlayRef?.current?.setView(physical_id)
				if(url) {
					start(url)
				}
			}

			videoPlayRef.current.setScaleMode(scaleMode);
			videoPlayRef.current.setBufferTime(500);

			if(videoPlayRef.current && videoPlayRef.current.on) {

				videoPlayRef.current.on('error', (err) => {
					// console.log(reconnectNumRef.current, err, 'video error')
					playTimeDriveFunc(false);
					if(setFps){
						setFps(0);
					}


					setLoading((v) => null);

					if(url && reconnectNumRef.current < 10) {

						reconnectNumRef.current = reconnectNumRef.current + 1
						setTimeout(() => {
							start(url)
						}, 1100)
					}
				})

				videoPlayRef?.current?.on('stats', (s) => {
					// console.log(s)
					let {fps, buf} = s;
					playTimeDriveFunc(fps !== 0);
					setTimeCount((v) => v + 1);

					setLoading((v) => fps);
					if(setFps){
						setFps(fps)
					}

					if(fps !== 0){
						reconnectNumRef.current = 0;
					}
				})
			}
		}catch (e) {
			console.log(e)
		}

		return () => {
			if(videoPlayRef.current) {
				videoPlayRef?.current?.stop();
				videoPlayRef?.current?.release(false);
				clearTimeout(timeOutRef.current);
			}
		}

	},[])

	useEffect(() => {
		if(Object.prototype.toString.call(setPlayTime) === '[object Function]'){
			setPlayTime(timeCount);
		}
	}, [timeCount])

	useEffect(() => {
		if(url && isMain){
			// setLiveURL(() => url)
			// stop();
			start(url);
		}
	}, [url, isMain])

	useEffect(() => {
		if(liveURL){
			// console.log(liveURL, 'liveURL')
			start(liveURL);
		}
	}, [liveURL])

	const start = (url) => {
		// console.log('MMMMM',url);
		clearTimeout(timeOutRef.current);

		timeOutRef.current = setTimeout(() => {
			if(url && videoPlayRef.current) {
				stop();
				try {
					videoPlayRef?.current?.start(url)
				} catch(err) {
					console.log(err, 'start error')
				}
			}
		}, 150)
	}

	const stop = () => {
		if(videoPlayRef.current) {
			// videoPlayRef.current.clearView();
			videoPlayRef?.current?.stop()
			playTimeDriveFunc(false);
		}
	}

	const pauseFunc = (v:boolean) => {
		try {
			if(videoPlayRef.current) {
				// videoPlayRef.current.clearView();
				// videoPlayRef.current.pause(v);
				// playTimeDriveFunc(v);
				if(v){
					stop();
				}else{
					start(url);
				}

			}
		}catch (e) {
			console.log(e)
		}

	}

	useEffect(() => {
		// console.log('声音控制', volume, physical_id);
		if(!isNaN(volume)){
			if(videoPlayRef.current) {
				videoPlayRef?.current?.setVolume(volume)
			}
		}else{
			if(videoPlayRef.current) {
				videoPlayRef?.current?.setVolume(0)
			}
		}

	}, [volume])

	useEffect(() => {
		// console.log('播放控制', pause);

		pauseFunc(pause)
	}, [pause])

	useEffect(() => {
		// console.log(width, height, new Date().getTime());
		if(width || height){
			videoPlayRef?.current?.resizeView(width, height);
		}

	}, [width, height])

	useEffect(() => {
		setVideoRefFunc(true);

		return () => {
			setVideoRefFunc(false);
		}
	}, [])

	const setVideoRefFunc = (bl) => {
		if(setVideoRef !== undefined){
			if(bl){
				setVideoRef(videoPlayRef.current)
			}else{
				setVideoRef(null)
			}
		}
	}

	const playTimeDriveFunc = (v) => {
		if(Object.prototype.toString.call(playTimeDrive) === '[object Function]'){
			playTimeDrive(v)
		}
	}

	const fullScreenFunc = () => {
		if(videoPlayRef.current) {
			videoPlayRef.current.fullscreen()
		}
	}

	return (
		<div className="video-preview-con" ref={videoElement}>
			<canvas className="video-canvas" id={physical_id}> </canvas>

			{
				loadding === 0 && <div className={'video-loading-con'}>
          <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 50, color: '#ffffff' }} spin />} />
        </div>
			}

		</div>
	)
}
