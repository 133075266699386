import {getLGToken} from "../../../../utils/common";
import {useVaultSubscribeStore} from "../../../../store/cloudPlan";
import Icon from "@ant-design/icons";
import CloseIcon from "../../../../assets/images/cloudPlan/close.svg";
import {Drawer} from "antd";
import React from "react";

export default function SubscribeModelVaultBRT(){

	const {vaultInfoZU, setVaultInfoZU} = useVaultSubscribeStore();

	let _token = getLGToken();
	let url = `https://payment.zmodo.com/vdr?token=${_token}&tokenid=${_token}&version=2`
	//有信息交互  在cloud主页
	const onClose = () => {
		setVaultInfoZU({open: false, data: vaultInfoZU.data});
	};

	return <Drawer
		title="Basic Drawer"
		placement="right"
		width={640}
		className='security-subscribe-modal subscribe-modal-cvr'
		onClose={onClose}
		open={true}
	>
		<div className='details-modal-title'>
			<p></p>
			<p>Vault Plan Subscribe</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>
		<div className={'cvr-sub-modal-main-box'}>
			<iframe src={url}></iframe>
		</div>
	</Drawer>
}
