import {getPlatfromByUrl} from "../common";
import lang_smartz from "../../lang/smartz";
import lang_zmodo from "../../lang/zmodo";

function getLang(){
	let pf = getPlatfromByUrl();
	let res = lang_smartz;

	if(pf === 'smartz'){
		res = lang_smartz;
	}else if(pf === 'zmodo' || pf === 'zmd'){
		res = lang_zmodo;
	}

	return res;
}

const lang = getLang();

export default lang;
