import { message } from 'antd'
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { getUUid } from '../utils'
import qs from 'qs'
import {errorToLogin, getLGToken} from "../utils/common";


export interface ResponseData<T> {
  code: number;
  tips?: string;
  data: T;
  message: string;
}

const service = axios.create({
  timeout: 30* 60* 1000, //请求超时时间
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  }
})


service.interceptors.request.use(
  (config: any) => {
    if (config.params) {
      for (const item in config.params) {
        if (item === null) {
          delete config.params[item]
        }
      }
    }
    // if (config && config.headers) {
    //   const token = getLGToken()
		//
    //   if (token) {
    //     config.headers['X-KT-Login-Token'] = token
    //   }
    //   // config.headers['Authorization'] = `Bearer ${token}`
    //   config.headers['X-KT-Request-Id'] = getUUid()
    //   config.headers['X-KT-Timestamp'] = new Date().getTime().toString()
    //   config.headers['X-KT-Debug'] = '0'
    // }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response: AxiosResponse<ResponseData<any>>) => {
    const res:any = response.data;
     if (res.result === "1002") {
      message.error(res.error)
      setTimeout(() => {
        // window.location.href = `/login`
      }, 1000)
     }

     if (response.status === 200 || response.status === 201) {
      if (response.data.code === 401 || response.data.code === 403) {
      	let req = encodeURIComponent(window.location.href)
	      // console.log(window.location);
        // window.location.href = `/login?req=${req}`
      } else {
        return response
      }

     } else if (response.status === 500) {
       return Promise.reject(res.message)
     } else {
      // window.location.href = `/login`
      message.error(res.error)

			 // console.log(res.error);
     }
    // switch (response.status) {
    //   case 200: //正常数据
    //       // if (response.data.code === 401) {
    //       //   window.location.href = '/login'
    //       // } else {
    //       //   return response
    //       // }
    //   case 201://正常数据
    //     return response
    //   case 500://服务器异常
    //     return Promise.reject(res.message)
    // }
    message.error(response.data.message)
    // return Promise.reject(new Error(response.data.message))
    return Promise.reject(new Error(response.data.message))
  },
  (error) => {

		const {response} = error;
		if(response){
			if(response.hasOwnProperty('data')){
				let data = response.data;
				if(data?.error === 'Expiring user token, please refresh.'){
					errorToLogin();
				}
			}
		}

    return Promise.reject(error)
  }
)

const httpRequest = {
  get<T>(url: string, params = {}, headers:any={}): Promise<T> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      params: params,
      responseType: 'json',
      headers
    }
    return new Promise((resolve, reject) => {
      service(options)
        .then((res: AxiosResponse<ResponseData<T>>) => {
          // @ts-ignore
          return resolve(res.data)
          // return resolve(res.data.data)
        })
        .catch((err) => {
          //  alert(err.response.data.errorMessage)
           return reject(err)
        })
    })
  },

  post<T>(url: string, data = {}, headers:any={}, onUploadProgress:any=null): Promise<T> {
    const options:any = {
      url: url,
      method: 'POST',
      data,
      responseType: 'json',
	    headers
    }

    if(onUploadProgress){
			options.onUploadProgress = onUploadProgress
		}

    return new Promise((resolve, reject) => {
      service(options)
        .then((res: AxiosResponse<ResponseData<T>>) => {
          // @ts-ignore
          return resolve(res.data)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  },
  put<T>(url: string, params = {}, headers:any={}): Promise<T> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'put',
      // params: params,
      data: params,
      responseType: 'json',
      headers
    }

    return new Promise((resolve, reject) => {
      service(options)
        .then((res: AxiosResponse<ResponseData<T>>) => {
          // @ts-ignore
          return resolve(res.data)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  },
  delete<T>(url: string, params = {}, headers:any={}): Promise<T> {
    // console.log( qs.stringify(params), 'params')
    const options: AxiosRequestConfig = {
      url: url,
      method: 'delete',
      data: params,
      // params: params,
      // params: qs.stringify(params),
      responseType: 'json',
      headers
    }

    return new Promise((resolve, reject) => {
      service(options)
        .then((res: AxiosResponse<any>) => {
          return resolve(res.data)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  },

  postFile<T>(url: string, params = {}, headers:any={}): Promise<T> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: qs.stringify(params),
      responseType: 'json',
      headers
    }
    return new Promise((resolve, reject) => {
      service(options)
        .then((res: AxiosResponse<ResponseData<T>>) => {
          return resolve(res.data.data)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export default httpRequest
