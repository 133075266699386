import './index.less'
import {Button, message, Form, Row, Col, Input, Select, Spin} from 'antd';
import {useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
// import LogoSvg from '../../assets/images/logo.svg'
// import LogoPng from '../../assets/images/logo.png'
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {useState} from 'react';
import {getUserCaptcha, loginUser} from '../../server/login'
import md5 from 'js-md5';
// import axios from 'axios';
import {removeCookie, returnUrl, setCookie} from "../../utils/common";
import lang from "../../utils/lang";
import mainLogo from "../../utils/logo/index.js";
import {getPlatfromByUrl} from "../../utils/common";
import zonecodeData from '../../assets/json/zonecode.json';
import usePlatform from "../../utils/platform";

const _platform = getPlatfromByUrl();

const layout = {
	labelCol: {span: 24},
	wrapperCol: {span: 24},
};
const Login = () => {
	const navigate = useNavigate();
	const [userform] = Form.useForm();
	const [isActive, setIsActive] = useState(true);
	const [captcha, setCaptcha] = useState<string>('');
	const [captchaVaule, setCaptchaVaule] = useState<string>('');
	const [captchaID, setCaptchaID] = useState<string>('');
	const [reloadCaptcha, setReloadCaptcha] = useState<boolean>(false);
	const [captchaValidityTime, setCaptchaValidityTime] = useState<number>(600);
	const [cityList, setCityList] = useState<any>([]);
	const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false);
	const [phoneRegion, setPhoneRegion] = useState<string>('1');

	const [loading, setLoading] = useState<boolean>(false);

	const inputUserNameRef = useRef<any>(null);

	const platformObj = usePlatform();

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			userform.setFieldsValue({
				username: 'ms011818@mailnesia.com',
				password: 'ms123456',
			});
		}
		getCaptcha();
	}, [])

	useEffect(() => {
		if (Array.isArray(zonecodeData?.zonecode)) {
			setCityList(() => zonecodeData?.zonecode);
		}
	}, [zonecodeData])

	const handleLogIn = async () => {
		if (isActive) {
			const values = await userform.validateFields();

			const params:any = {
				cid: platformObj?.cid ? platformObj?.cid : 0,
				platform: platformObj?.id ? platformObj?.id : 21,
				client: 2,
				language: "en",
				// email: values.username,
				password: md5(values.password),
				captcha_id: captchaID,
				captcha_code: values.captcha_code,
			}

			if(isPhoneNumber){
				params['phone_number'] = values.username;
				params['phone_region'] = phoneRegion;
			}else{
				params['email'] = values.username;
			}

			setLoading(() => true);
			const res: any = await loginUser(params)

			if (res.result === "ok") {
				setCookie('token', res.token);
				setCookie('oldPassword', params?.password)
				setCookie('accountInfo', JSON.stringify(res.data))
				setCookie('email', res.data?.email);
				setCookie('username', res.data?.username);
				setCookie('WEB_APP_EVT_MSG_LOGIN_OR_LOGOUT', 'login');
				setTimeout(() => {
					removeCookie('WEB_APP_EVT_MSG_LOGIN_OR_LOGOUT');
				}, 5000)

				message.success('Login Successfully')

				let slt = returnUrl();

				if (!slt) {
					navigate('/cloud-plan');
				}

			} else {
				if (res?.error) {
					message.error(res?.error)
					setLoading(() => false);
				}
			}

			setTimeout(() => {
				setLoading(() => false);
			}, 300)
		} else {
			message.error('Please enter your username and password')
		}

	}

	const handleCreateAccount = () => {
		navigate('/sign-up');
	}
	const handleForgotPassword = () => {
		navigate('/forgot-password');
	}

	const getCaptcha = async () => {
		if (reloadCaptcha) return;
		setReloadCaptcha(() => true);

		let res: any = await getUserCaptcha();
		let {file_type, base64_data, captcha_id, result, effective_time} = res;

		if (result === 'ok') {
			let img_base64_data = `data:${file_type};base64,${base64_data}`;
			setCaptcha(() => img_base64_data);
			setCaptchaValidityTime(() => effective_time);
			setCaptchaID(() => captcha_id);
		}

		setReloadCaptcha(() => false);
	}

	const reloadCaptchaFun = async () => {
		setCaptchaVaule(() => '');
		userform.setFieldValue('captcha_code', '');
		await getCaptcha();
	}

	const handleUser = async (e, ky) => {
		const values = await userform.validateFields();

		if(ky === 'username'){
			let val = values.username;
			let phoneReg = /^\d{6,11}$/;

			let isPhone = phoneReg.test(val);
			setIsPhoneNumber(() => isPhone);

			// if(inputUserNameRef.current && val.length > 5 && val.length < 7){
				setTimeout(() => {inputUserNameRef.current.focus();},50)
			// }
		}else if(ky === 'phone_region'){
			setPhoneRegion(() => e)
		}else{
			setCaptchaVaule(() => values.captcha_code)
		}

		if (values.username && values.password) {
			setIsActive(true)
		} else {
			setIsActive(false)
		}
	}

	const onKeyUpFun = (e) => {
		let {key, code, keyCode, which} = e;
		if (key === 'Enter' || code === 'Enter' || keyCode === 13 || which === 13) {
			handleLogIn()
		}
	}

	const selectCityList = (
		<Select popupClassName={'ct-list-select-x'} defaultValue="1" onChange={(e) => handleUser(e, 'phone_region')}>
			{
				(cityList).map((item:any, index: number) => {
					return <Select.Option value={item.value} key={index}>{item.value}</Select.Option>
				})
			}
		</Select>
	);

	const captchaImageElement = (
		<div className={'login-form-captcha-code-img-box'} onClick={reloadCaptchaFun}>
			<Spin indicator={<LoadingOutlined spin />} size="default" delay={200} spinning={reloadCaptcha} >
				<img src={captcha} alt=""/>
			</Spin>
		</div>
	)

	let _year = lang?.note?.copy;
	if (_year) {
		_year = _year.replace('{year}', (new Date().getFullYear()).toString());
	}

	return (
		<>
		<div className="login">
			<div className='login-logo'>
				<p>
					{(_platform === 'zmodo' || _platform === 'zmd') &&
          <img src={mainLogo} alt="" style={{width: "96px", marginTop: "90px", marginBottom: "70px"}}/>}
					{_platform === 'smartz' && <Icon component={mainLogo} style={{fontSize: "174px"}}/>}
					{/* <Icon component={LogoSvg} style={{ fontSize: "174px" }} /> */}
				</p>
				<p>{lang?.sign?.welcome}</p>
			</div>
			<div className='login-con'>
				<div className='login-form'>
					<div className='login-form-title'>
						Login
					</div>
					<Form
						{...layout}
						form={userform}
						layout="vertical"
					>
						<Row>
							<Col span={24}>
								<Form.Item label="" name="username">
									<Input
										onChange={(e) => handleUser(e, 'username')}
										onKeyUp={onKeyUpFun}
										placeholder='Email / Phone Number'
										addonAfter={ isPhoneNumber ? selectCityList : null}
										ref={inputUserNameRef}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24} className='login-form-password'>
								<Form.Item label="" name="password">
									<Input.Password
										onChange={(e) => handleUser(e, 'password')}
										onKeyUp={onKeyUpFun}
										minLength={8}
										placeholder='Password'
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24} className='login-form-captcha-code'>
								<Form.Item label="" name="captcha_code">
									<Input
										onChange={(e) => handleUser(e, 'captcha_code')}
										onKeyUp={onKeyUpFun}
										placeholder=''
										addonAfter={captchaImageElement}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
					<div className='login-form-footer'>
						<p dangerouslySetInnerHTML={{__html: lang?.sign?.description_in}}></p>
					</div>
					<Row>
						<Col span={24}>
							<Button onClick={handleLogIn} className={isActive && captchaVaule ? 'login-button-active' : "login-button"}
											loading={loading}>Login</Button>
						</Col>
					</Row>
					<div className='login-txt'>
						<p onClick={handleCreateAccount}>Create Account</p>
						<p onClick={handleForgotPassword}>Forgot Password</p>
					</div>
				</div>
			</div>
		</div>
		<div className='login-footer'>
				<div>
					<p>{_year}</p>
				</div>
				<div>
					<p className={'a-link-sty'} dangerouslySetInnerHTML={{__html: lang?.note?.privacy_policy}}></p>
					<p className={'a-link-sty'} dangerouslySetInnerHTML={{__html: lang?.note?.terms_of_service}}></p>
					<p>Support</p>
				</div>
			</div>
		</>
	)
}

export default Login
