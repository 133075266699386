import Icon from "@ant-design/icons";
import CloseIcon from "../../../../assets/images/cloudPlan/close.svg";
import {Drawer} from "antd";
import React from "react";
import {useCloudSubscribeStore} from "../../../../store/cloudPlan";
import {getLGToken} from "../../../../utils/common";
import './index.less'

export default function SubscribeModelCVRBRT(){
	const {cloudInfoZU, setCloudInfoZU} = useCloudSubscribeStore();
	let _token = getLGToken();

	let _plan = `${cloudInfoZU?.data?.period}-${cloudInfoZU?.data?.pay_type}`
	let url = `https://payment.zmodo.com/checkout?token=${_token}&plan=${_plan}&tokenid=${_token}&version=2`
	//有信息交互  在cloud主页
	const onClose = () => {
		setCloudInfoZU({open: false, data: cloudInfoZU.data});
	};
	return <Drawer
			title="Basic Drawer"
			placement="right"
			width={640}
			className='security-subscribe-modal subscribe-modal-cvr'
			onClose={onClose}
			open={true}
	>
		<div className='details-modal-title'>
			<p></p>
			<p>Safeguard Plan Subscribe</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>
		<div className={'cvr-sub-modal-main-box'}>
			<iframe src={url}></iframe>
		</div>
	</Drawer>
}
