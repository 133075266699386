import {Route, Routes, useNavigate, useLocation, useBeforeUnload} from "react-router-dom";
import {useEffect, useRef} from 'react';
import CloudPlan from "pages/cloudPlan";
import SubscribeSecurity from "pages/cloudPlan/subscribeSecurity";
import SubscribeVault from "pages/cloudPlan/subscribeVault";
import CheckOut from "pages/cloudPlan/components/checkOut";
import Result from "pages/cloudPlan/result";
import MyOrders from "pages/myOrders";
import MyOrdersDetails from "pages/myOrders/components/details";
import Account from "pages/account";
import Vault from "pages/vault";
import VaultManagement from "pages/vault/vaultManagement";
// import Search from "pages/vault/search";
import TopBar from "pages/topBar";
import LeftNav from "pages/leftNav";
import HomePage from "pages/vault/meDrive";
import Search from "pages/vault/search";
import StoragePage from "pages/vault/Storage";
import PlanPage from "pages/vault/Plan";
import CleanUpMeDrivePage from "pages/vault/cleanUpMeDrive";
import FileItemsPage from "pages/vault/FileItems";
import UploadPage from "pages/vault/Upload";
import Favourite from "pages/vault/Favourite";
import Recent from "pages/vault/Recent";
import Trash from "pages/vault/Trash";
import Settings from "pages/vault/Settings"
import Details from "pages/vault/Details"
import MiddlePage from "pages/vault/middlePage";
import FilePage from "pages/vault/File";
import PhotoPage from "pages/vault/Photo";
import InitPage from "pages/vault/initPage";
import LargeFilePage from "pages/vault/largeFilePage";
import DeviceCenter from "../pages/deviceCenter";
import DeviceCenterPlayer from "../pages/deviceCenter/player";
import Alerts from "../pages/deviceCenter/alerts";
import LiveView from "../pages/deviceCenter/live-view";

import './index.less';
import VaultRouter from "./vault";

import CloudPlanFromJump from "../pages/cloudPlanFromJump";
import Memories from "../pages/deviceCenter/memories";
import {checkPFToken} from "../server/checkToken";
import {errorToLogin} from "../utils/common";

function usePrevious(value) {
	const ref = useRef();
	// 在每个渲染周期中更新ref.current的值
	useEffect(() => {
		ref.current = value;
	});
	// 返回上一个值
	return ref.current;
}


function LayOut() {
  const location = useLocation();
	const navigate = useNavigate()
	const previousLocation = usePrevious(location);

	const {pathname} = location;
// console.log(pathname, previousLocation)

  const MyRoute = (path, element) => {
    return <>
      <Route path={`/${path}`} element={element} />
      <Route path={`/:platform/${path}`} element={element} />
    </>
  }

	useEffect(() => {
		const showRouteChange = (from, to) => {
			// console.log(`From: ${from.pathname}, To: ${to.pathname}`);

			let isVaultPage = window['isVaultPage']
			let vaultLoadingListCount = window['vaultLoadingListCount'];

			// console.log(isVaultPage, vaultLoadingListCount,900);

			// if(String(to.pathname).indexOf('/vault') === -1 && String(from.pathname).indexOf('/vault') > -1){
			// 	if(vaultLoadingListCount > 0){
			// 		alert('Switching pages may result in file upload failure')
			// 	}
			// }
		};

		if (previousLocation !== undefined) {
			showRouteChange(previousLocation, location);
		}
	}, [location, previousLocation])



	useBeforeUnload(() => {
		// console.log(previousLocation, location)
	})

	useEffect(() => {
		// 检查token 是否有效
		if(pathname){
			checkPFToken().then((res:any) => {
				if(Number(res.result) === 401){
					errorToLogin();
				}
			});
		}
	}, [pathname])

  return (
    <>
      <div className='pageContainer'>
        <div>
          <TopBar />
        </div>
        <div className="outterBodyContainer">
          <div className='outterNavContainer'>
            <LeftNav />
          </div>
          <div className='contentContainer'>
            <Routes>
							<Route path="/" element={<CloudPlan />} />
              <Route path="/cloud-plan" element={<CloudPlan />} />
							<Route path="/cloud-plan/:dokey" element={<CloudPlan />} />
              <Route path="/subscribe-security" element={<SubscribeSecurity />}></Route>
              <Route path="/subscribe-vault" element={<SubscribeVault />}></Route>
              <Route path="/check-out" element={<CheckOut />} />
              <Route path="/result" element={<Result />} />
							<Route path="/result/:type" element={<Result />} />
							<Route path="/cloud-plan-form-jump/:dokey" element={<CloudPlanFromJump />} />

              {/* <Route path="/vault" element={<Vault />} />
              <Route path="/vault-search" element={<Search />} />
              <Route path="/vault-management" element={<VaultManagement />} /> */}
              <Route path="/my-orders" element={<MyOrders />} />
              <Route path="/my-orders-details" element={<MyOrdersDetails />} />
              <Route path="/account" element={<Account />} />

              <Route path="/device-center" element={<LiveView />} />
              <Route path="/alerts" element={<Alerts />} />
							<Route path="/memories" element={<Memories />} />
              <Route path="/device-center/:pathKey" element={<LiveView />} />
              <Route path="/device-center/player/:physical_id" element={<DeviceCenterPlayer />} />
             {/* {MyRoute('large-file', <LargeFilePage />)} */}
              {/* {MyRoute('middle', <MiddlePage />)}  */}
							<Route path="/vault" element={<VaultRouter />} />
							<Route path="/vault/*" element={<VaultRouter />} />
              <Route path="favourite" element={<Favourite />} />
              <Route path="trash" element={<Trash />} />
              {/* {MyRoute('trash', <Trash />)} */}
            </Routes>
          </div>
        </div>

      </div>
    </>
  );
}

export default LayOut;
