import './index.less';
import { useEffect, useRef, useState } from 'react';
import { Ellipsis, Space, InfiniteScroll, Dialog, AutoCenter, Toast, DotLoading } from 'antd-mobile'
import { Input } from 'antd';
import CreateNewFolder from '../meDrive/components/create-new-folder'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import usePlatform from "../../../utils/platform";
import { getFolderContent, createFolder, copyFolder, copyFile, moveFolder, moveFile, copyItemsList, moveItemsList, getFolderDetails } from "../../../server/folder";
import dayjs from "dayjs";
import BottomOperation from '../../../components/bottom-operation'
import jumpPage from "../../../utils/jumpPage";
import RenameFile from "../../../components/name-modal";
import HeadNav from "../../../components/headNav";
import fileType from "../../../utils/fileType";
import SortByModal from "../meDrive/components/sort-by-modal";
import {getLGToken, getUserBasicFolderIDS, isJSON, sizeFormat} from "../../../utils/common";
import NAPI from "../../../utils/NAPI";
import InnerSearch from "./modules/innerSearch";
import { getUserBasicCloudDisk } from "../../../utils/common";
import token from "../../../utils/token";
import getApiUrl from "../../../server/apiUrl";
import type { ToastHandler } from "antd-mobile/es/components/toast";
import LoadDataWaitingState from "../../../components/loadDataWaitingState";
import { Drawer } from 'antd';
import CloseIcon from '../../../assets/images/cloudPlan/close.svg'
import Icon from "@ant-design/icons";
import fileTypeIcon from "../../../utils/fileTypeIcon";
import { getStorageInformation } from "../../../server/storage";

const imageUrl = getApiUrl('cloudDisk');
const icon_folder = '/res/icons/icon_folder_v2.svg';
const icon_folder_sys = '/res/icons/icon_folder.svg';

const keysObj = {
	'name': 'Name',
	'updateTime': 'Last Modified',
	'size': 'Storage Used'
}

const typeObj = {
	1: { title: 'Copy To', button: 'Copy' },
	2: { title: 'Move To', button: 'Move' },
	3: { title: 'Upload To', button: 'Set Location' },
}

const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
	return (
		<>
			{hasMore ? (
				<>
					<span>Loading</span>
					<DotLoading />
				</>
			) : (
				// <span>--- End ---</span>
				<></>
			)}
		</>
	)
}

function MiddlePage(props) {
	const { open } = props
	const navigate = useNavigate()
	const [isShow, setIsShow] = useState<boolean>(false)
	const [visible, setVisible] = useState<boolean>(false)
	const [itemNum, setItemNum] = useState<Number>(1)
	const [isFolder, setIsFolder] = useState<boolean>(false)
	const [isSortBy, setIsSortBy] = useState<boolean>(false)
	const [isMeDrive, setIsMeDrive] = useState<boolean>(false)
	const [isPause, setIsPause] = useState<boolean>(false)
	const [isUpgradeStorage, setIsUpgradeStorage] = useState<boolean>(true)
	const [isDetails, setIsDetails] = useState<boolean>(false)
	const [detailsObj, setDetailsObj] = useState<any>({})

	const [folderList, setFolderList] = useState<any>([]); //  文件夹列表
	const [fileList, setFileList] = useState<any>([]); //  文件列表
	const [addFolderName, setAddFolderName] = useState<any>(''); //  新建文件夹的名称
	const [currentListFolderPid, setCurrentListFolderPid] = useState<any>(0); // 当前列表的pid
	// const [activeID, setActiveID] = useState<any>(-1); // 操作更多时的文件夹或者文件ID
	const [copyWin, setCopyWin] = useState<boolean>(!false);
	const [moveWin, setMoveWin] = useState<boolean>(false);
	const [optionItem, setOptionItem] = useState<any>({});
	const [actionItemID, setActionItemID] = useState<any>(0);
	const [actionItemType, setActionItemType] = useState<any>(0);
	const [actionFileType, setActionFileType] = useState<any>(0); // 被操纵的文件类型 0  目录， 1 文件
	const [openSearchPanel, setOpenSearchPanel] = useState<boolean>(false);
	const [loadingMainData, setLoadingMainData] = useState<number>(0); // 0: 还没请求数据， 1：请求中， 2：请求结束
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})
	const [isCreateFolder, setIsCreateFolder] = useState<boolean>(false);

	const [pageInfo, setPageInfo] = useState<any>({
		page: 0,
		pageSize: 10 * 1,
		orderBy: 'name', //name, updateTime, size
		order: 'Asc', //Asc:顺序 Desc:逆序
	})

	const [onePageData, setOnePageData] = useState<any>({
		number_of_elements: 0,
		page_number: 0,
		total_elements: 0,
		total_pages: 0,
	}); // 文件分也请求数据
	const [loadMoreFlag, setLoadMoreFlag] = useState<boolean>(false)
	const [sortRequst, setSortRequst] = useState<boolean>(false);
	const [filePath, setFilePath] = useState<any[]>([])
	const [filePathCurrent, setFilePathCurrent] = useState<string>("")
	const [fileSearch, setFileSearch] = useState<string>("")

	const parmas = useParams();
	const location = useLocation();
	const pf = usePlatform();
	const timeOutRef: any = useRef(0);
	const touchTimeLen: any = useRef(0);
	const reqTimeceRef: any = useRef(true);
	const reqPageRef = useRef(-1);
	const [searchVal, setSearchVal] = useState('')
	const [errorObj, setErrorObj] = useState<any>("")

	const toastRef = useRef<ToastHandler>();

	let searchParams = new URLSearchParams(location.search);
	
	let _id: any = searchParams.get('id');
	let _action_id: any = searchParams.get('action_id');
	let _action_type: any = searchParams.get('action_type');
	let _file_type: any = searchParams.get('type');
	let _action_data: any = searchParams.get('action_data');
	let hisdeep:any = searchParams.get('hisdeep')

	let _params: any = {
		id: _id,
		action_id: _action_id,
		action_type: _action_type,
		type: _file_type,
		action_data: _action_data,
	}

	if (_action_data) {
		_params['action_data'] = JSON.parse(_action_data);
	}
	if (_action_type !== null) {
		_file_type = _file_type === 'undefined' ? 0 : 1;
		_params['type'] = Number(_file_type);
	}
	if (_action_id !== null) {
		_params['action_id'] = Number(_action_id);
	}

	useEffect(() => {
		let searchParams = new URLSearchParams(location.search);
		let _id: any = searchParams.get('id');
		let _action_id: any = searchParams.get('action_id');
		let _action_type: any = searchParams.get('action_type');
		let _file_type: any = searchParams.get('type');



		if (_id === null || _id === undefined) {
			_id = 0;
		}
		setCurrentListFolderPid(() => _id);

		if (_action_id !== null && _action_id !== undefined && _action_id !== 0) {
			setActionItemID(() => _action_id)
		}

		if (_action_type !== null && _action_type !== undefined && _action_type !== 0) {
			setActionItemType(() => Number(_action_type))
		}

		if (_action_type !== null) {
			_file_type = _file_type === 'undefined' ? 0 : 1;
			setActionFileType(() => _file_type)
		}



		setLoadingMainData(() => 0);
		
	}, [location.search])

    useEffect(() => {
		setFileSearch(location?.search)
	}, [])


	function getFolderNamesFromPath(path) {
		// 正则表达式匹配所有由斜杠分隔的单词，不包括以斜杠开头和结尾
		const folderNames = path?.match(/(?:\/|^)[^\/]+(?=\/|$)/g);
	    const newFolderNames = folderNames?.map((item:any) => {
			return item.replace(/^\/|\/$/g, '')
		})

		return newFolderNames || [];
	  }

    // 获取文件夹路径
	const initFolderDetails = async () => {
		const res:any = await getFolderDetails(_id)
		const { data, result } = res;
		if (data && result === 'ok') {
			if (_id !== "0") {
				const newData = getFolderNamesFromPath(data.location);
                let obj = newData.map((key, index) => {
					return {
                        name: key,
                        id: data?.parents[index]
                    }

				});
				setFilePath(() => obj)
				setFilePathCurrent(data.name)
			} else {
                 setFilePath(() => ["Vault"])
			}

		}
	}

	useEffect(() => {
		initFolderDetails()
	}, [_id])


	useEffect(() => {
		// 页面请求信息初始化
		resetPageInfo();
		getDiskStorageSize()

		clearTimeout(timeOutRef.current);
		setLoadingMainData(() => 1)
		timeOutRef.current = setTimeout(() => {
			getFolder(currentListFolderPid);
		}, 250);

		return () => {
			clearTimeout(timeOutRef.current);
		}
	}, [currentListFolderPid])

	const getDiskStorageSize = async () => {
		let res: any = await getStorageInformation();
		let { data, result } = res;
		if (result === 'ok' && data) {
			if (isJSON(data)) {
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id,
					backup_id: data.backup_id,
				}

				if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data)
			}
		} else {
			navigate('/login')
		}
	}

	useEffect(() => {
		if (sortRequst) {
			resetPageInfo();

			clearTimeout(timeOutRef.current);
			timeOutRef.current = setTimeout(() => {
				getFolder(currentListFolderPid);
			}, 250);

			return () => {
				clearTimeout(timeOutRef.current);
			}
		}
	}, [sortRequst])

	// 重置页面信息
	const resetPageInfo = () => {
		let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		_pageInfo.page = 0;
		setPageInfo((prev) => _pageInfo);

		reqPageRef.current = 0;
	}

	// 获取文件夹内容
	const getFolder = async (dirId, inx = 0) => {
		let req = {
			...pageInfo,
			dirId: dirId,
		}
		if (inx) {
			req.page = inx;
		} else {
			if (reqPageRef.current !== -1) {
				req.page = reqPageRef.current;
			}
		}

		setOptionItem(() => { }); // 清空选择的文件夹

		let res = await getFolderContent(req);
		reqPageRef.current = -1;
		const token = getLGToken()
		let { data, result }: any = res;
		if (result === 'ok' && data) {
			if (isJSON(data)) {

				let _onePageData = {
					number_of_elements: data['files']['number_of_elements'],
					page_number: data['files']['page_number'],
					total_elements: data['files']['total_elements'],
					total_pages: data['files']['total_pages'],
				}

				setOnePageData(() => _onePageData)


				let files: any = data['files']['content']; //文件
				files.map((item: any) => {
					let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified';
					let _dt = dayjs(item.update_at).format('MMM D, YYYY');

					item.icon = fileType[item.type]['icon']; //  icon_img;
					if (item.type === 3 && item.cover) {
						// item.icon = getFileByUrl({url: item.url, name: item.name})
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 1 && item.cover) {
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 2) {
						item.icon = fileTypeIcon[item.ext];
					}
					item.content = pageInfo.orderBy === 'size' ? `Storage used: ${sizeFormat(item.size)}` : `${_lb} ${_dt}`;
					return item;
				})

				// 第一次加载的时候， 添加文件夹数据， 添加文件数据
				if (data['files']['page_number'] === 0) {
					let dirs: any = data['dirs']; // 文件夹
					let _arr = getUserBasicFolderIDS();
					console.log(_arr, '347')

					dirs.map((item: any) => {
						let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified';
						let _dt = dayjs(item.update_at).format('MMM D, YYYY');
						item.icon = icon_folder;
						item.content = `${_lb} ${_dt}`;

						if (_arr.includes(item.id)) {
							// item.disabled = true;
							item.system_dir = true;
							item.icon = icon_folder_sys;
						}

						return item;
					})

					let _dir_arr = []
					for (let o of dirs) {
                        if (_action_type === "2") {
							if (!_arr.includes(o.id)) {
								if (_params?.action_data?.dirs?.length >= 1) {
									if (!_params?.action_data?.dirs.includes(o.id)) {
										_dir_arr.push(o);
									}
								} else {
										if (o.id !== Number(_action_id)) {
											_dir_arr.push(o);
									}
								}
							}
						} else {
							  if (!_arr.includes(o.id)) {
								if (_params?.action_data?.dirs?.length >= 1) {
									if (!_params?.action_data?.dirs.includes(o.id)) {
										if (_arr.includes(o.id)) {
											_dir_arr.unshift(o)
										} else {
											_dir_arr.push(o);
										}

									}
								} else {
										if (o.id !== Number(_action_id)) {
											if (_arr.includes(o.id)) {
												_dir_arr.unshift(o)
											} else {
												_dir_arr.push(o);
											}
									}
								}
							  }

						}



					}

					setFolderList(() => _dir_arr);

					setFileList(() => files);

					setLoadingMainData(() => 2)
				} else { // 文件数据追加， 处理分页数据
					setFileList((prevList) => prevList.concat(files));
				}

				if (_onePageData.page_number === _onePageData.total_pages) {
					setLoadMoreFlag(() => false);
				} else {
					setLoadMoreFlag(() => true);
				}

				let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
				_pageInfo.page = _onePageData.page_number;
				setPageInfo(() => _pageInfo)
			}

			setSortRequst(() => false);

			setTimeout(() => {
				reqTimeceRef.current = true
			}, 600)
		}
	}

	// 新建文件夹
	useEffect(() => {
		if (addFolderName !== '') {
			createFolderFunc(currentListFolderPid, addFolderName);
		}
		// console.log(addFolderName,addFolderName!=='', 'addFolderName')
	}, [addFolderName])

	const createFolderFunc = (pid, name) => {
		let req = {
			pid: pid,
			name: name
		}

		createFolder(req).then((res: any) => {
			// setAddFolderName
			let { result, data } = res;
			if (result === 'ok' && data) {
				// getFolder(pid);
				setAddFolderName(() => '');
				if (typeof data === 'number') {
					openFolder({ id: data, type: undefined })
				} else {
					getFolder(pid);
				}
				setIsCreateFolder(false)
				setSearchVal('')
			} else {
				if (res?.error) {
					setErrorObj(res?.error)
					setIsCreateFolder(false)
					// alert(res?.error)
					// Dialog.alert({
					// 	content: <AutoCenter style={{ textAlign: "center" }}>{res?.error}</AutoCenter>,
					// 	destroyOnClose: true,
					// 	confirmText: 'OK'
					// })
				}
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}


	// 打开文件夹
	const openFolder = (o) => {
		let _type = actionFileType === 0 ? undefined : actionFileType

		let searchParams = new URLSearchParams(location.search);
		let _hisdeep: any = searchParams.get('hisdeep');
		let target = searchParams.get('target')
		if (typeof _hisdeep === 'string') {
			_hisdeep = Number(_hisdeep) + 1
		}

		let _pathUrl = `/vault/middle?id=${o.id}&type=${_type}&action_id=${actionItemID}&action_type=${actionItemType}&hisdeep=${_hisdeep}&target=${target}`
		if (_params.action_data) {
			_pathUrl = `/vault/middle?id=${o.id}&action_type=${actionItemType}&hisdeep=${_hisdeep}&action_data=${JSON.stringify(_params.action_data)}&target=${target}`
		}

		jumpPage(navigate, _pathUrl);

		if (openSearchPanel) {
			setTimeout(() => {
				setOpenSearchPanel(() => false);
			}, 500)
		}
	}

	const handleMeDrive = () => {
		setIsMeDrive(() => !isMeDrive)
	}

	const handleList = () => {
		setIsShow(() => !isShow)
	}
	const handlePopup = () => {
		// setVisible(() => true)
		// setIsFolder
		console.log('handlePopup')
	}

	const handleSortBy = () => {
		setIsSortBy(() => !isSortBy)
	}

	const handleItem = (num: number) => {

		if (num === 3) {
			setIsFolder(true)
			setVisible(false)
		} else {
			setItemNum(num)
		}
	}

	const handleSearch = () => {
		// jumpPage(navigate, '/search')
		setOpenSearchPanel(() => true)
	}

	const closeSearchPanel = () => {
		setOpenSearchPanel(() => false)
	}

	const handleMore = (item: any) => {
		setIsDetails(true)
		setDetailsObj(item)
	}

	const hanleUpgradeStorage = () => {
		setIsUpgradeStorage(false)
	}

	const chooseAction = () => {
		if (Number(_params.id) === Number(_params.action_id) && Number(_params.type) === 0 && Number(_params.action_type) === 2) {

			Toast.show({
				content: 'Cannot move to itself.'
			})
			return;
		}

		if (Number(_params.id) === Number(_params.action_id) && Number(_params.type) === 0 && Number(_params.action_type) === 1) {

			Toast.show({
				content: 'Cannot copy to itself.'
			})
			return;
		}

		let _dirs = isJSON(_params.action_data) ? _params.action_data.dirs : null;

		if (Array.isArray(_dirs)) {
			if ((_dirs.includes(Number(_params.id))) && Number(_params.action_type) === 2) {

				Toast.show({
					content: 'Cannot move to itself.'
				})
				return;
			} else if ((_dirs.includes(Number(_params.id))) && Number(_params.action_type) === 1) {
				Toast.show({
					content: 'Cannot copy to itself.'
				})
				return;
			}
		}

		if (Number(actionItemType) === 1) {
			copyAction();
		} else if (Number(actionItemType) === 2) {
			moveAction();
		} else if (Number(actionItemType) === 3) { // 设置为上传目录  与app 交互
			if (NAPI.check()) {
				NAPI.apptodo('setLocation', { folder_id: currentListFolderPid })
			}
		}
	}

	const copyAction = () => {
		if (currentListFolderPid === undefined || currentListFolderPid === null) {
			return;
		}

		let userBasicCloudDisk = getUserBasicCloudDisk();
		let folderID = currentListFolderPid
		if (Number(folderID) === 0 && userBasicCloudDisk.hasOwnProperty('root_id')) {
			folderID = userBasicCloudDisk['root_id'];
		}
		toastRef.current = Toast.show({
			icon: 'loading',
			content: 'Copying...',
			duration: 0,
			position: 'center',
			maskClickable: false,
			maskStyle: {
				background: 'rgba(255, 255, 255, 0.5)',
				color: '#fff'
			}
		})

		if (_params.action_data) {
			let req = {
				..._params.action_data,
				dir_id: folderID
			}
			copyItemsList(req).then((res: any) => {

				let { result, data, error } = res;
				if (result === 'ok' && data) {
					backToFolder()
					toastRef.current.close();
				} else {
					if (error) {
						Toast.show({
							content: error
						})
					}
				}
			}).catch(e => {
				console.log(e);
				let { message } = e;
				if (message) {
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			})

		} else {
			let _func = actionFileType === 0 ? copyFolder : copyFile;
			console.log(actionItemID, folderID, 'actionItemID, folderID')
			_func(actionItemID, folderID).then((res: any) => {

				let { result, data, error } = res;
				if (result === 'ok' && data) {
					backToFolder()
					toastRef.current.close();
				} else {
					toastRef.current.close();
					if (error) {
						Toast.show({
							content: error
						})
					}
				}
			}).catch(e => {
				console.log(e);
				let { message } = e;
				if (message) {
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			})
		}


	}

	const moveAction = () => {
		if (currentListFolderPid === undefined || currentListFolderPid === null) {
			return;
		}

		let userBasicCloudDisk = getUserBasicCloudDisk();
		let folderID = currentListFolderPid
		if (Number(folderID) === 0 && userBasicCloudDisk.hasOwnProperty('root_id')) {
			folderID = userBasicCloudDisk['root_id'];
		}

		toastRef.current = Toast.show({
			icon: 'loading',
			content: 'Moving...',
			duration: 0,
			position: 'center',
			maskClickable: false,
			maskStyle: {
				background: 'rgba(255, 255, 255, 0.5)',
				color: '#fff'
			}
		})

		if (_params.action_data) {

			let req = {
				..._params.action_data,
				dir_id: folderID
			}
			moveItemsList(req).then((res: any) => {

				let { result, data, error } = res;
				if (result === 'ok' && data) {
					backToFolder()

					toastRef.current.close();
				} else {
					if (error) {
						Toast.show({
							content: error
						})
					}
				}
			}).catch(e => {
				console.log(e);
				let { message } = e;
				if (message) {
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			})

		} else {
			let _func = actionFileType === 0 ? moveFolder : moveFile;
			_func(actionItemID, folderID).then((res: any) => {

				let { result, data, error } = res;
				if (result === 'ok' && data) {
					backToFolder()
					toastRef.current.close();
				} else {
					toastRef.current.close();
					Toast.show({
						content: error
					})
				}
			}).catch(e => {
				console.log(e);
				let { message } = e;
				if (message) {
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			})
		}
	}

	const hdStartFunc = () => {
		let _hisdeep: any = searchParams.get('hisdeep');
		// 关闭  回到首页

		_hisdeep = Number(_hisdeep);
		if (Number.isNaN(_hisdeep)) {
			jumpPage(navigate, '/?id=0', { replace: true });
		} else {
			navigate(-_hisdeep);
		}
	}

	const backToFolder = () => {
		let searchParams = new URLSearchParams(location.search);
		let _hisdeep: any = searchParams.get('hisdeep');
		let target = searchParams.get('target')
		if (typeof _hisdeep === 'string') {
			_hisdeep = Number(_hisdeep)
		}
 
		if (target.includes("favourite")) {
			jumpPage(navigate, `/favourite?id=${currentListFolderPid}&hisdeep=${_hisdeep}&target=${target}`, { replace: true });
		} else  {
			jumpPage(navigate, `/vault?id=${currentListFolderPid}&hisdeep=${_hisdeep}&target=${target}`, { replace: true });
		}
		
	}

	const createFolderWin = () => {
		setIsFolder(() => true);
	}

	// 重新请求数据
	const reqData = (v, o) => {

		if (v) {
			getFolder(currentListFolderPid);
			setDetailsObj(() => o);
		}
	}

	const loadMoreDataII = async () => {
		let _onePageData = JSON.parse(JSON.stringify(onePageData));

		if (_onePageData.page_number < _onePageData.total_pages) {

			if (reqTimeceRef.current) {

				reqTimeceRef.current = false;
				let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
				_pageInfo.page = _pageInfo.page + 1;
				setPageInfo((prev) => _pageInfo);

				await getFolder(currentListFolderPid, _pageInfo.page);
			}
		}
	}

	const actionName = () => {
		let action_title: any = typeObj[actionItemType]; //actionItemType===1 ? `Copy To` : `Move To`;
		action_title = action_title ? action_title['title'] : ''
		if (optionItem) {
			action_title = `${action_title} "${optionItem.name}"`
		}

		return action_title;
	}

	// 排序方式
	const sortFunc = (v) => {
		let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		_pageInfo = {
			..._pageInfo,
			...v
		}

		let _localJSON = {
			order: _pageInfo.order,
			orderBy: _pageInfo.orderBy,
		}

		// localStorage.setItem('vault-sort-info', JSON.stringify(_localJSON));

		setPageInfo(() => _pageInfo);
		setSortRequst(() => true);
	}

	const onClose = () => {
		// jumpPage(navigate, `/vault?id=${_id}&type=undefined`);
		jumpPage(navigate, `/vault`);
		// navigate(-1);
	}

	const handleAllFiles = async () => {
		// // jumpPage(navigate, `/vault`);
		// const params = new URLSearchParams(location.search)
		// // console.log(location?.pathname, 'location?.pathname', params)
		// const _action_id = params.get('action_id')
		// const _type = params.get('type')
		// const _action_data = params.get('action_data')
		// const  _hisdeep = params.get('hisdeep')
		// let _pathUrl
		// let target = (location.pathname).replace(/\//g, '')

        // if (_action_data) {
		// 	if (_action_type === "1") {
		// 		_pathUrl  = `/vault/middle?id=0&action_id=${_action_id}&type=${_type}&action_type=1&hisdeep=1&action_data=${_action_data}&target=vault`
		// 	} else {
		// 		 _pathUrl = `/vault/middle?id=0&action_id=${_action_id}&type=${_type}&action_type=2&hisdeep=1&action_data=${_action_data}&target=vault`
		// 	}
		// } else {

		// 	if (_action_type === "1") {
		// 		_pathUrl  = `/vault/middle?id=0&action_id=${_action_id}&type=${_type}&action_type=1&hisdeep=${_hisdeep}&target=vault`
		// 	} else {
		// 		 _pathUrl = `/vault/middle?id=0&action_id=${_action_id}&type=${_type}&action_type=2&hisdeep=${_hisdeep}&target=vault`
		// 	}
		// }
		     let _pathUrl = fileSearch
			jumpPage(navigate, _pathUrl)
			setFilePath(() => ["Vault"])
			setFilePathCurrent("")
	}

	const firstFunc = (item) => {
		// const location = useLocation();
		const params = new URLSearchParams(location.search)
		// console.log(location?.pathname, 'location?.pathname', params)
		const _action_id = params.get('action_id')
		const _type = params.get('type')
		const _action_data = params.get('action_data')
		const  _hisdeep = params.get('hisdeep')
		let _pathUrl
		let target = (location.pathname).replace(/\//g, '')

        if (_action_data) {
			if (_action_type === "1") {
				_pathUrl  = `/vault/middle?id=${item.id}&action_id=${_action_id}&type=${_type}&action_type=1&hisdeep=1&action_data=${_action_data}&target=${target}`
			} else {
				 _pathUrl = `/vault/middle?id=${item.id}&action_id=${_action_id}&type=${_type}&action_type=2&hisdeep=1&action_data=${_action_data}&target=${target}`
			}
		} else {

			if (_action_type === "1") {
				_pathUrl  = `/vault/middle?id=${item.id}&action_id=${_action_id}&type=${_type}&action_type=1&hisdeep=${_hisdeep}&target=${target}`
			} else {
				 _pathUrl = `/vault/middle?id=${item.id}&action_id=${_action_id}&type=${_type}&action_type=2&hisdeep=${_hisdeep}&target=${target}`
			}
		}


		jumpPage(navigate, _pathUrl)
		// let name = `${location?.pathname}?id=${item.id}&type=undefined`
		// jumpPage(navigate, name);
		// navigate(-1)
		// if(Object.prototype.toString.call(start) === '[object Function]'){
		// 	start()
		// }else{
		// 	backFunc();
		// }
	}

	const handleClose = () => {
		setIsCreateFolder(false)
	}

	const handleSearchInput = async (e) => {
		const val = (e.target.value).trim()
		if (val) {
			setSearchVal(val)
		} else {
			setSearchVal('')
		}
	}

	const handleOK = () => {
		if (searchVal) {
			// console.log(`${location.pathname}${location.search}`, '111')
			// let obj = 	`${location.pathname}${location.search}`
			// jumpPage(navigate, `${location.pathname}${location.search}`);
			createFolderFunc(_id, searchVal)
			setSearchVal('')
			// console.log(searchVal, location, 'searchVal')
		}
	}


	

	return (
		<Drawer open={open} width={464} className="page-storge-con page-storge-con-tom" onClose={onClose} >
			<div className={'page-storage'}>
				<div className='details-modal-title'>
					<p></p>
					{_action_type === "1" && <p>Copy to</p> }
					{_action_type === "2" && <p>Move to</p> }
					<p onClick={onClose}>
						<Icon component={CloseIcon} style={{ fontSize: "14px" }} />
					</p>
				</div>
				<div className={'head-nav-title1'}>
                    {filePath?.map((item:any, index:number) => {
						return <div key={index}> {(item?.name === "Vault" || item === "Vault") ? <p onClick={handleAllFiles}><span style={{ marginRight: "4px" }}>All Files</span>  &gt; </p>  :
						<p style={{ marginLeft: "4px" }} onClick={() => firstFunc(item)}><span>{item?.name}</span>  &gt; </p>}</div>
					})}
				    <div><span style={{marginLeft: filePath.length >= 1 ? "4px" : "" }}>{filePathCurrent}</span></div>
					{/* <Ellipsis direction='end' content={title} /> */}
				</div>
				<div className="middle-page">

					{/* { !openSearchPanel &&<div className={'hd-box'}>
				<HeadNav
					title={actionName()}
					icon={'/res/icons/icon_close.svg'}
					start={hdStartFunc}
					endElement={
						<span>
							<Space style={{'--gap': '32px'}}>
								<span onClick={handleSearch}>
									<img src="/res/icons/icon_search2.svg" style={{width: "20px", verticalAlign: 'middle'}} alt="" />
								</span>
								<span onClick={createFolderWin}>
									<img src="/res/icons/icon_create_new_folder.svg" style={{width: "20px", verticalAlign: 'middle'}} alt="" />
								</span>
							</Space>
						</span>
					}
				/>

			</div> } */}

					{!openSearchPanel && <div className={`mn-box ${copyWin ? 'mn-ht' : ''}`}>
						<div className="md-content">
							{
								isCreateFolder && <div className='md-content-input'>
								<Input placeholder='' value={searchVal} onChange={(e) => handleSearchInput(e)}/>
								<p onClick={handleOK}><img src="/res/icons/icon_agree.svg" alt="" /></p>
								<p onClick={handleClose}><img src="/res/icons/icon_cancel.svg" alt="" /></p>
								{/* <Icon component={CloseIcon} onClick={handleClose} style={{ fontSize: "14px", cursor: "pointer" }} /> */}
							</div>
							}
						
							{/* <div className="content-title">
						<div>
							<h4>{keysObj[pageInfo.orderBy]}</h4>
							<img src="/res/icons/icon_order.svg" alt="" onClick={handleSortBy} style={{transform: `${pageInfo.order === 'Asc' ? 'rotate(0deg)' : 'rotate(180deg)'}`}} />
						</div>
						<div onClick={handleList}>
							<img src={isShow ? "/res/icons/icon_list_two.svg" : "/res/icons/icon_list_one.svg"} alt=""/>
						</div>
					</div> */}

							<div className={'mn-list mn-list-tom'}>
								{
									loadingMainData === 1 ? <>
										<LoadDataWaitingState />
									</>
										:
										<>
											{
												(folderList.length === 0 && fileList.length === 0 && loadingMainData === 2) && <div className="content-data">
													<div className="content-data-img">
														<img src="/res/icons/no_file_found.png" alt="" />
													</div>
													<div className="content-data-txt">
														<h4 style={{ color: "#ff3141" }}>No files.</h4>
														{/* <p>Tap the "Upload" button to upload files.</p> */}
													</div>
												</div>
											}

											{
												(folderList.length > 0 || fileList.length > 0) && <> {isShow ?
													<div className="content-two">
														
														{
															folderList.map((item: any) => {
																return <div
																	className="content-two-item"
																	key={item.id}
																	onClick={() => openFolder(item)}
																>
																	<div className="file"><img src={item.icon} alt="" /></div>
																	<div className="txt">

																		<Ellipsis direction='end' content={item.name} />
																	</div>
																</div>
															})
														}

														{
															fileList.map((item: any) => {
																return <div className="content-two-item" key={item.id} >
																	<div className="file"><img src={item.icon} alt="" /></div>
																	<div className="txt">

																		<Ellipsis direction='end' content={item.name} />
																	</div>
																</div>
															})
														}
													</div>
													:
													<>
														{
															folderList.map((item: any) => {
																return (
																	<div
																		className="content-item"
																		key={item.id}
																		onClick={() => openFolder(item)}
																	>
																		<div className="content-item-type">
																			<img src={item.icon} alt="" />
																			<div className='content-item-txt'>
																				<h4><Ellipsis direction='end' content={item.name} /></h4>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					{
																						item?.collect ? <img src="/res/icons/icon_favourite_on.svg" alt="" style={{ width: "12px", marginRight: '7px', verticalAlign: "middle" }} /> : null
																					}
																					<Ellipsis direction='end' content={item.content} />
																				</div>
																			</div>
																		</div>
																	</div>
																)
															})
														}

														{/* {
															fileList.map((item: any) => {
																return (
																	<div className="content-item" key={item.id}>
																		<div className="content-item-type">
																			<img src={item.icon} alt="" />
																			<div className='content-item-txt'>
																				<h4><Ellipsis direction='end' content={item.name} /></h4>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					{
																						item?.collect ? <img src="/res/icons/icon_favourite_on.svg" alt="" style={{ width: "12px", marginRight: '7px', verticalAlign: "middle" }} /> : null
																					}
																					<Ellipsis direction='end' content={item.content} />
																				</div>
																			</div>
																		</div>
																	</div>
																)
															})
														} */}
													</>
												}
												</>
											}

											<div className={'a-list-bottom-flag'}>
												<InfiniteScroll loadMore={loadMoreDataII} hasMore={loadMoreFlag}>
													<InfiniteScrollContent hasMore={loadMoreFlag} />
												</InfiniteScroll>
											</div>
										</>
								}



							</div>
						</div>
						<BottomOperation
						    onIsCreateFolder={setIsCreateFolder}
							title="Create Folder"
							buttonTxt={typeObj[actionItemType]?.button}
							todoAction={chooseAction}
							visible={copyWin}
							errorObj={errorObj}
							onErrorObj={setErrorObj}
						/>
					</div>}

					<CreateNewFolder visible={isFolder} onVisible={setIsFolder} setFolderName={setAddFolderName} />

					<SortByModal
						visible={isSortBy}
						onVisible={setIsSortBy}
						sortFunc={sortFunc}
						sortValue={{ orderBy: pageInfo.orderBy, order: pageInfo.order }}
					/>
					{
						openSearchPanel && <InnerSearch
							closeSearchPanel={closeSearchPanel}
							openFolder={openFolder}
						/>
					}
				</div>
			</div>
		</Drawer>
	);
}

export default MiddlePage;
