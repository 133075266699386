import { useRef, useState } from 'react';
import { Popup, Ellipsis, Toast, Modal } from 'antd-mobile'
import './index.less'
import {
	collectFolderOrFile,
	deleteFile,
	deleteFolder,
	renameFolder,
	renameFile,
	deleteFolderList,
	deleteFileList,
	collectListFolderOrFile,
	cancelCollectListFolderOrFile,
	copyItemsList,
	moveItemsList,
	getFileDetails
} from "../../server/folder";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/delete-modal";
import TipsModal from "../../components/tips-modal";
import NameModal from "../name-modal";
import BottomOperation from "../bottom-operation";
import jumpPage from "../../utils/jumpPage";
import NAPI from "../../utils/NAPI";
import {getLGToken, isFunction, isJSON} from "../../utils/common";
import { ToastHandler } from "antd-mobile/es/components/toast";
import NewAccountModal from "../../components/homeHeadNavX/components/new-account-modal";

import dayjs from "dayjs";
import getApiUrl from "../../server/apiUrl";
import kkFileType from "../../utils/kkFileType";
import {encode} from "js-base64";


const fileUrl = getApiUrl('cloudDisk');
const downloadZipUrl = getApiUrl('downloadZip');

function DetailsModal(props) {
	const { onIsFileDetailsModal,  visible, onVisible, data, type, reqData, func, cloudDiskInfo = {}, onClick, positionObj, init, onCurrentBg,  } = props

	const [itemNum, setItemNum] = useState<number>(0)
	const [delInfo, setDelInfo] = useState({ title: '', content: '', open: false })
	const [renameInfo, setRenameInfo] = useState({ error: '', open: false })
	const [openRenameWin, setOpenRenameWin] = useState<boolean>(false)
	const [copyWin, setCopyWin] = useState<boolean>(false)
	const [moveWin, setMoveWin] = useState<boolean>(false)
	const [isTipsModal, setIsTipsModal] = useState<boolean>(false);
	const [open, setOpen] = useState(false);


	const navigate = useNavigate()
	const toastRef = useRef<ToastHandler>()
	const location = useLocation()

	let _data = data;
	let _hasFolder = false; //有文件夹
	let _hasFile = false; //有文件
	let _itemlen = 1;
	let _collectAll = false; // 是否都收藏
	let _collectItemsLen = 0; //收藏的个数
	let _disabled = false; // 默认目录不能被删除和重命名
	let _isSysFolder = false; //是否是系统文件夹
	let _notEnoughSpace = Number(cloudDiskInfo.usable) < 1;
	let _orderExpired = Number(cloudDiskInfo.size) === 0

	if (isJSON(data)) {
		_hasFolder = data.type === undefined;
		_hasFile = data.type !== undefined
		_collectAll = data.collect;
		_collectItemsLen = data.collect ? 1 : 0;
		_disabled = data.disabled;
		_isSysFolder = data.system_dir;

		if ((!_orderExpired) && data.status === 0) {
			_orderExpired = true;
		}

	} else if (Array.isArray(data)) {

		let _arr = [];
		for (let item of data) {
			if ((!_hasFolder) && item.type === undefined) {
				_hasFolder = true;
			} else if ((!_hasFile) && item.type !== undefined) {
				_hasFile = true;
			}

			if ((!_disabled) && item.type === undefined) {
				_disabled = item.disabled;
			}

			if ((!_orderExpired) && item.status === 0) {
				_orderExpired = true;
			}

			if ((!_isSysFolder) && item.type === undefined) {
				_isSysFolder = item.system_dir;
			}

			if (item.collect) {
				_arr.push(item);
			}
		}

		_itemlen = data.length;
		_collectItemsLen = _arr.length;

		_collectAll = _itemlen === _collectItemsLen;

		if (_itemlen === 1) {
			_data = data[0];
		}
	}


	const handleItem = (num: number) => {
		setItemNum(() => num)
	}

	// console.log(detailsObj, 'detailsObj');

	// 收藏
	const collectFunc = () => {
		if (_orderExpired) {
			tipFunc();
			return;
		}

		handleItem(1);

		if (_itemlen === 1) {
			let req = {
				type: _data.type === undefined ? 1 : 2,
				fid: _data.id
			}
			collectFolderOrFile(req).then((res: any) => {
				let { result, data } = res;
				if (result === 'ok' && data) {

					if (Object.prototype.toString.call(reqData) === '[object Function]') {
						let _detailsObj = JSON.parse(JSON.stringify(_data));
						_detailsObj.collect = !_detailsObj.collect;
						reqData(true, _detailsObj, 'collect');
						init()
						onVisible(() => false);
					}
				}
			}).catch(e => {
				console.log(e)
				let { message } = e;
				if (message) {
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			})
		} else {
			let _file = [];
			let _folder = [];

			for (let item of _data) {
				if (item.type === undefined) {
					_folder.push(item.id);
				} else {
					_file.push(item.id);
				}
			}

			let req = {
				dirs: _folder,
				files: _file
			}

			let _func = _itemlen > _collectItemsLen ? collectListFolderOrFile : cancelCollectListFolderOrFile;
			_func(req).then((res: any) => {
				let { result, data } = res;
				if (result === 'ok' && data) {
					if (Object.prototype.toString.call(reqData) === '[object Function]') {
						let _detailsObj = JSON.parse(JSON.stringify(_data));
						_detailsObj = _detailsObj.map((o: any) => {
							o.collect = !_collectAll;
							return o;
						})
						reqData(true, _detailsObj, 'collect');

						onVisible(() => false);
					}
				}
			}).catch(e => {
				console.log(e)
				let { message } = e;
				if (message) {
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			})
		}

		setTimeout(() => {
			setItemNum(() => 0)
		}, 500)
	}


	// 修改名称
	const editNameFunc = () => {

		if (_orderExpired) {
			tipFunc();
			return;
		}

		handleItem(2);
		let _renameInfo = {
			error: '',
			open: true
		}
		setRenameInfo(() => _renameInfo)
		setOpenRenameWin(() => true)
		onVisible(() => false);
		setTimeout(() => {
			setItemNum(() => 0)
		}, 500)
	}

	const onCloseRenameFunc = () => {
		setOpenRenameWin(() => false)
		let _renameInfo = {
			error: '',
			open: false
		}
		setRenameInfo(() => _renameInfo)
	}

	const onActionRenameFunc = (v) => {
		let _func = _data.type === undefined ? renameFolder : renameFile;

		if (v === '' || v === undefined) {
			let _renameInfo = {
				error: 'Cannot be empty',
				open: true
			}
			setRenameInfo(() => _renameInfo)
			return;
		}

		_func(_data.id, v).then((res: any) => {
			let { result, data, error } = res;

			if (result === 'ok' && data) {
				onCloseRenameFunc();
				let _detailsObj = JSON.parse(JSON.stringify(_data));
				_detailsObj.name = v;
				reqData(true, _detailsObj, 'rename');
				init()

			} else {
				if (error) {

					let _renameInfo = {
						error: error,
						open: true
					}
					setRenameInfo(() => _renameInfo)
				}
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	// 复制
	const copyFunc = () => {
		if (_notEnoughSpace || _orderExpired) {
			tipFunc();
			return;
		}
		let target = (location.pathname).replace(/\//g, '')
		handleItem(3);
		onVisible(() => false);
		// setCopyWin(() => true);
		let _pathUrl = '';

		if (_itemlen === 1) {
			_pathUrl = `/vault/middle?id=${0}&action_id=${_data.id}&type=${_data.type}&action_type=1&hisdeep=1&target=${target}`
		} else {
			let _file = [];
			let _folder = [];


			for (let item of _data) {
				if (item.type === undefined) {
					_folder.push(item.id);
				} else {
					_file.push(item.id);
				}
			}

			let _copyData = {
				dirs: _folder,
				files: _file
			}
			_pathUrl = `/vault/middle?id=${0}&action_type=1&hisdeep=1&action_data=${JSON.stringify(_copyData)}&target=${target}`;
		}

		jumpPage(navigate, _pathUrl)
	}

	const copyAction = () => {
		console.log('copyAction');
	}

	// 移动
	const moveFunc = () => {
		if (_notEnoughSpace || _orderExpired) {
			tipFunc();
			return;
		}

		handleItem(4);
		onVisible(() => false);
		// setMoveWin(() => true);
		let _pathUrl = '';
		let target = (location.pathname).replace(/\//g, '')

		if (_itemlen === 1) {
			_pathUrl = `/vault/middle?id=${0}&action_id=${_data.id}&type=${_data.type}&action_type=2&hisdeep=1&target=${target}`
		} else {
			let _file = [];
			let _folder = [];

			for (let item of _data) {
				if (item.type === undefined) {
					_folder.push(item.id);
				} else {
					_file.push(item.id);
				}
			}

			let _copyData = {
				dirs: _folder,
				files: _file
			}
			_pathUrl = `/vault/middle?id=${0}&action_type=2&hisdeep=1&action_data=${JSON.stringify(_copyData)}&target=${target}`;
		}

		jumpPage(navigate, _pathUrl)
	}

	// 详情
	const detailFunc = () => {
		handleItem(5);
		onIsFileDetailsModal(true)
		onVisible(false)
		init()
		// navigate(`details?id=${detailsObj.id}`)
		// jumpPage(navigate, `/details?id=${data.id}&type=${data.type}`)
		setTimeout(() => {
			setItemNum(() => 0)
		}, 500)
	}

	//  删除
	const deleteFunc = () => {
		handleItem(6);
		let _copyWord = _itemlen === 1 ? _data.name : `${_itemlen} Items`
		let _delInfo = {
			title: _copyWord,
			content: `Are you sure you want to delete "${_copyWord}" ? The file will be permanently removed in 30 days.`,
			open: true
		}

		setDelInfo(() => _delInfo);
		setItemNum(() => 0);
		onVisible(() => false);
		// init()
	}

	const onCloseFunc = () => {
		let _delInfo = {
			title: '',
			content: '',
			open: false,
		}
		setDelInfo(() => _delInfo)
	}

	const onActionFunc = async () => {

		if (_itemlen === 1) {
			let _func = _data.type === undefined ? deleteFolder : deleteFile;
			_func(_data.id).then((res: any) => {
				let { result, data } = res;

				if (result === 'ok' && data) {
					onCloseFunc();
					init();
					if (location.pathname === '/file') {
						navigate(-1)
					} else {
						let _detailsObj = JSON.parse(JSON.stringify(_data));
						reqData(true, _detailsObj, 'delete');
					}
				}
			}).catch(e => {
				console.log(e)
				let { message } = e;
				if (message) {
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			})
		} else {


			let _arrFolder = [];
			let _arrFile = [];

			for (let o of _data) {
				if (o.type === undefined) {
					_arrFolder.push(o.id);
				} else {
					_arrFile.push(o.id);
				}
			}

			let _flag = false;
			onCloseFunc();

			toastRef.current = Toast.show({
				icon: 'loading',
				content: 'Processing...',
				duration: 0,
				position: 'center',
				maskClickable: false,
				maskStyle: {
					background: 'rgba(255, 255, 255, 0.5)',
					color: '#fff'
				}
			})

			let _detailsObj = JSON.parse(JSON.stringify(_data));
			reqData(true, _detailsObj, 'delete');

			if (_arrFolder.length > 0) {
				let res: any = await deleteFolderList(_arrFolder);
				let { result, data, error } = res;

				if (result === 'ok' && data) {
					_flag = true

					if (_arrFile.length === 0) {
						let _detailsObj = JSON.parse(JSON.stringify(_data));
						reqData(true, _detailsObj, 'delete');
					}

				} else {
					Toast.show({
						content: error,
					})

					toastRef.current.close();
				}

			} else {
				_flag = true
			}

			if (_arrFile.length > 0) {
				let res: any = await deleteFileList(_arrFile);
				let { result, data, error } = res;

				if (result === 'ok' && data && _flag) {

					let _detailsObj = JSON.parse(JSON.stringify(_data));
					reqData(true, _detailsObj, 'delete');
				} else {
					Toast.show({
						content: error,
					})

					toastRef.current.close();
				}

			}

			toastRef.current.close();

		}
	}


    function downloadImage(url, name) {
		let link = document.createElement('a');
		link.href = url;
		link.download = name;
		document.body.appendChild(link);
		link.click()
		document.body.removeChild(link);
	}



	const downloadFunc = async () => {
		handleItem(8);
			// let xlsx =  `${fileUrl}/disk/download_file?url=${data.url}&name=${data.name}&token=${localStorage.getItem("token")}`;
			// downloadImage(xlsx, data.name)
			// 	onVisible(() => false);

		let {type, name, url, id} = _data;
		let item:any = {}
		let a_token = getLGToken();
		//文件
		if(type){
			item = {
				url: `${fileUrl}/disk/download_file?url=${url}&name=${name}&token=${a_token}`,
				name: name,
				is_file: true,
			}
		}else{ //文件夹
			item = {
				url: `${downloadZipUrl}/disk/download_folder?token=${a_token}&dirId=${id}`,
				name: name,
				is_file: false,
			}
		}

		if(item.url){
			downloadUrlFun(item)
			onVisible(() => false);
		}
	}

	const downloadUrlFun = (o) => {
		let iframe = document.createElement('iframe');
		iframe.style.display = 'none';
		document.body.appendChild(iframe);
		iframe.src = o.url;

		let timer = o.is_file ? 5 : 600;

		setTimeout(() => {
			document.body.removeChild(iframe);
		}, 1000 * timer);
	}

	const closeLongPressFunc = () => {
		if (isFunction(reqData)) {
			reqData(true, {}, 'closeLongPress');
		}
	}

	const shareFunc = () => {

		if (_orderExpired) {
			tipFunc();
			return;
		}

		handleItem(7)
		let _arr = Array.isArray(_data) ? _data : [_data]
		let __arr = [];
		for (let item of _arr) {
			let _file = item['file']
			if (isJSON(_file)) {
				__arr.push(_file);
			} else {
				__arr.push(item)
			}
		}

		if (NAPI.check()) {
			NAPI.apptodo('share', __arr);
			onVisible(() => false);
			closeLongPressFunc();
		}
	}

	const openFunc = () => {
		if (_orderExpired) {
			tipFunc();
			return;
		}

		let { ext } = data;
		let _ext = ext ? ext.toLowerCase() : '';
		if (_ext && kkFileType.includes(_ext)) {
			let token = getLGToken();

			let _url = `${fileUrl}/disk/download_file?url=${data?.url}&name=${data?.name}&token=${token}&fullfilename=${data?.name}`;


			window.open(`/file-preview?url=${encodeURIComponent(encode(_url))}&filename=${encodeURIComponent(data.name)}&type=${_ext}`);
			return;
		}

		onClick(data)
		onVisible(false)
		setTimeout(() => {
			setItemNum(() => 0)
		}, 500)
	}

	const tipFunc = () => {

		if (Number(cloudDiskInfo.size) === 0 && Number(cloudDiskInfo.used) === 0) {
			setOpen(true)
			onVisible(() => false);
			return false
	   }
		if (Number(cloudDiskInfo.size) === 0 || Number(cloudDiskInfo.usable) < 0) {
			let _content = 'Access restricted';
			if (Number(cloudDiskInfo.size) > 0 && Number(cloudDiskInfo.usable) < 0) {
				_content = 'Not enough space'
			}


			setIsTipsModal(true)

			return true;
		} else {
			return false;
		}
	}

	let _lb = _data.create_at === _data.update_at ? 'Created on' : 'Modified';
	let _dt = dayjs(_data.update_at).format('MMM D, YYYY HH:mm:ss A');
	let _datetimeText = `${_lb} ${_dt}`; //_data?.content



	return <>
		<Popup
			className="details-popup-con"
			visible={visible}
			// mask={false}
			onMaskClick={() => {
				onVisible(false)
				onCurrentBg(0)
			}}
			onClose={() => {
				onVisible(false)
				onCurrentBg(0)
			}}
			style={{

			}}
			bodyStyle= {{
				width: "250px",
				top: `${positionObj?.top}px`,
				left: `${positionObj?.left}px`,
			}}



		>

			{/* {
				_itemlen === 1 ?
					<div className="popup-title">
						<div>
							<img src={_data?.icon} alt="" />
						</div>
						<div>
							<h5><Ellipsis direction={"end"} content={_data?.name} /></h5>
							<p>{_datetimeText}</p>
						</div>
					</div>
					:
					<div className="popup-title">
						<h3 style={{ fontSize: '22px' }}>{_itemlen} items</h3>
					</div>
			} */}

		   {/* <div className="popup-title">
			   <h3 style={{ fontSize: '22px' }}>Open</h3>
			</div> */}
			{
				(_disabled || _isSysFolder || (_itemlen !== 1) || func !== undefined) ? null : <div className={itemNum === 9 ? "popup-item popup-item-active" : "popup-item"}
				 onClick={openFunc} style={{ opacity: `${_orderExpired ? 0.65 : 1}` }}>
				<img src="/res/icons/icon_open.svg" alt="" style={{ width: "18px" }} />
				<p>Open</p>
			</div>
			}

			{
				(_hasFile && !_hasFolder || true) && <>

					{/* <div
						className={itemNum === 7 ? "popup-item popup-item-active" : "popup-item"}
						onClick={shareFunc}
						style={{ opacity: `${_orderExpired ? 0.65 : 1}` }}
					>
						<img src="/res/icons/icon_share_v2.svg" alt="" style={{ width: "20px" }} />
						<p>Share</p>
					</div> */}
					<div
						className={itemNum === 8 ? "popup-item popup-item-active" : "popup-item"}
						onClick={downloadFunc}
					>
						<img src="/res/icons/icon_save_to_device_v2.svg" alt="" style={{ width: "18px" }} />
						<p>Download</p>
					</div>
					{/* <div className="underline"></div> */}
				</>
			}


			{
				(_itemlen - _collectItemsLen !== 0 && _itemlen - _collectItemsLen !== _itemlen) ? null : <>
					<div
						className={itemNum === 1 ? "popup-item popup-item-active" : "popup-item"}
						onClick={collectFunc}
						style={{ opacity: `${_orderExpired ? 0.65 : 1}` }}
					>
						{
							_collectAll ? <>
								<img src="/res/icons/icon_favourite_v2.svg" alt="" style={{ width: "20px" }} />
								<p>Remove from Starred</p>
							</>
								:
								<>
									<img src="/res/icons/icon_favourite_on_v2.svg" alt="" style={{ width: "20px" }} />
									<p>Starred</p>
								</>
						}
					</div>
				</>
			}
			{/*打印*/}
            {/* {
				(data.type === 2 || data.type === 3) ? <div
				className={itemNum === 2 ? "popup-item popup-item-active" : "popup-item"}
				onClick={printFunc}
				style={{ opacity: `${_orderExpired ? 0.65 : 1}` }}
			>
				<img src="/res/icons/icon_print.svg" alt="" style={{ width: "20px" }} />
				<p>Print</p>
			</div> : null
			} */}


			{/*重命名*/}
			{
				(_disabled || _isSysFolder || (_itemlen !== 1) || func !== undefined) ? null : <div
					className={itemNum === 2 ? "popup-item popup-item-active" : "popup-item"}
					onClick={editNameFunc}
					style={{ opacity: `${_orderExpired ? 0.65 : 1}` }}
				>
					<img src="/res/icons/icon_rename_v2.svg" alt="" style={{ width: "20px" }} />
					<p>Rename</p>
				</div>
			}



			{
				_disabled ? null : <div
					className={itemNum === 3 ? "popup-item popup-item-active" : "popup-item"}
					onClick={copyFunc}
					style={{ opacity: `${_notEnoughSpace || _orderExpired ? 0.65 : 1}` }}
				>
					<img src="/res/icons/icon_copy_to_v2.svg" alt="" style={{ width: "20px" }} />
					<p>Copy to</p>
				</div>
			}

			{
				_disabled || _isSysFolder ? null : <div
					className={itemNum === 4 ? "popup-item popup-item-active" : "popup-item"}
					onClick={moveFunc}
					style={{ opacity: `${_notEnoughSpace || _orderExpired ? 0.65 : 1}` }}
				>
					<img src="/res/icons/icon_move_to_v2.svg" alt="" style={{ width: "20px" }} />
					<p>Move to</p>
				</div>
			}



			{/* <div className="underline"></div> */}


			{
				_itemlen === 1 ?
					<div className={itemNum === 5 ? "popup-item popup-item-active" : "popup-item"} onClick={detailFunc}>
						<img src="/res/icons/icon_detail_v2.svg" alt="" style={{ width: "20px" }} />
						<p>File information</p>
					</div>
					:
					null
			}


			{
				_disabled || _isSysFolder ? null :
					<div className={itemNum === 6 ? "popup-item popup-item-active" : "popup-item"} onClick={deleteFunc}>
						<img src="/res/icons/icon_trash_v2.svg" alt="" style={{ width: "16px" }} />
						<p>Move to trash</p>
					</div>
			}




		</Popup>

		<DeleteModal
			title={delInfo.title}
			contentObj={delInfo.content}
			visible={delInfo.open}
			onClose={onCloseFunc}
			onAction={onActionFunc}
			closeButtonText={'Cancel'}
			actionButtonText={'Delete'}
			onVisible={onCloseFunc}
			target="vault"
		/>

		<NameModal
			title={'Rename File'}
			value={_data.name}
			fileType={_data.ext}
			visible={renameInfo.open}
			error_message={renameInfo.error}
			onClose={onCloseRenameFunc}
			onAction={onActionRenameFunc}
			closeButtonText={'Cancel'}
			actionButtonText={'Confirm'}

		/>
		<TipsModal visible={isTipsModal} onVisible={setIsTipsModal} diskInfo={cloudDiskInfo}/>
		<NewAccountModal open={open} onOpen={setOpen} position={document.querySelector('.contentContainer')} />


	</>
}

export default DetailsModal
