import { Row, Col, Button, Form, Input, DatePicker, Select } from 'antd';
import dayjs from "dayjs";
import _ from 'lodash';
import Icon from "@ant-design/icons";
import { useEffect, useState } from 'react';
import { getCloudOrderList } from '../../../server/plan'
import { getListCloudPlan, getListSubscription, getSubscriptionChange } from '../../../server/cloudPlan'
import SecurityPlanPlus from '../../../assets/images/cloudPlan/security_plan_plus.png'
import SelectActive from '../../../assets/images/cloudPlan/select_active.svg'
import Selected from '../../../assets/images/cloudPlan/selected.svg'
import Unselected from '../../../assets/images/cloudPlan/unselected.svg'
import Add from '../../../assets/images/cloudPlan/add.svg'
import Minus from '../../../assets/images/cloudPlan/minus.svg'
import SafetyTips from '../../../assets/images/cloudPlan/safety_tips.svg'
import { useLocation, useNavigate } from "react-router-dom";
import MaybeLaterModal from '../components/maybeLaterModal'
import {getLGToken} from "../../../utils/common";

const SubscribeSecurity = (props) => {
    const { onIsDetailsModal, onDetailsObj } = props
    const navigate = useNavigate();
    const [isBanner, setIsBanner] = useState(true);
    const location = useLocation()
	const params = new URLSearchParams(location.search)
	const target = params.get('target')
    const id = params.get('id')

    const [subscriptionsList, setSubscriptionsList] = useState<any[]>([])
    const [loseList, setLoseList] = useState<any[]>([])
    const [cloudPlanList, setCloudPlanList] = useState<any[]>([])
    // const [subscriptionsList, setSubscriptionsList] = useState<any[]>([])
    // const [loseList, setLoseList] = useState<any[]>([])
    const [detailsObj, setDetailsObj] = useState<any>({})
    const [plusList, setPlusList] = useState<any[]>([])
    const [isPlus, setIsPlus] = useState<any>(true)
    const [monthplusObj, setMonthPlusObj] = useState<any>({})
    const [yearplusObj, setYearPlusObj] = useState<any>({})
    const [premiumList, setPremiumList] = useState<any[]>([])
    const [platiumList, setPlatiumList] = useState<any[]>([])
    const [num, setNum] = useState<number>(1)
    const [plan, setPlan] = useState<string>('plus')
    const [planList, setPlanList] = useState<any>([])
    const [currentPlanInfo, setCurrentPlanInfo] = useState<any>({ count: 0, plan: '', year: '', month: '' });
    const [isMaybeLaterModal, setIsMaybeLaterModal] = useState(false)

    const handleBanner = () => {
        setIsBanner(false);
    }


    useEffect(() => {
        // planList
        let _currentPlanInfo: any = _.cloneDeep(currentPlanInfo);
        for (let item of planList) {
            if (num === item.num && plan === item.species) {
                _currentPlanInfo.num = num;
                _currentPlanInfo.plan = plan;
                if (item.time === 'year') {
                    _currentPlanInfo.year = Number(item.price) / 100;
                } else if (item.time === 'month') {
                    _currentPlanInfo.month = Number(item.price) / 100;
                }
            }
        }

        if (_currentPlanInfo.num && _currentPlanInfo.plan) {
            setCurrentPlanInfo(_currentPlanInfo)
        }
        console.log(_currentPlanInfo);
    }, [num, plan, planList])

    useEffect(() => {
        if (target) {
            setPlan(target)
        }
    }, [target])


    const initListSubscription = async () => {
        const res: any = await getListSubscription()
        res.map((item: any) => {
            item.list = (item.description)?.split('_')

            return item
        })
        setCloudPlanList(res)
    }

    useEffect(() => {
        initListSubscription()
        // initListCloudPlan()

    }, [])

    const handleViewDetails = (item: any) => {
        onIsDetailsModal(true)
        onDetailsObj(item)
    }


    const initListCloudPlan = async () => {
        const res: any = await getListCloudPlan()
        res.map((item: any) => {
            item.skuList = (item.sku)?.split('_')
            item.species = item.skuList[0]
            item.time = item.skuList[1]
            item.num = Number(item.skuList[2])
            item.device = item.skuList[3]


            return item
        })


        setPlanList(() => res)
    }

    useEffect(() => {
        initListCloudPlan()
    }, [])


    const handleQuanity = (target) => {
        if (target === "add") {
            let count = num + 1;
            count = count > 2 ? 100 : count
            setNum(count)
        } else {
            let count = num;
            count = count === 100 ? 3 : count
            if (num === 1) {
                return false
            }
            setNum(count - 1)
        }
    }

    const handleMaybeLater = () => {
        setIsMaybeLaterModal(() => !isMaybeLaterModal)
    }

    const handleCheckOut = async () => {
        let newPlanList = _.cloneDeep(planList)
        let key:any
        let id:any
        newPlanList.map((item:any) => {
            if (item.species === currentPlanInfo.plan && item.num === currentPlanInfo.num ) {

                key = item.product_key
                id = item.id
            }
        })
        if (id) {
            const params = {
                user_token: getLGToken(),
                product_key: key,
                subscription_id: id
            }
            const res:any =  getSubscriptionChange(params)
            navigate(`/check-out?product_key=${key}`)
        } else {
            navigate(`/check-out?product_key=${key}`)
        }



    }

    return <>
        <div className='your-cart'>
            <div className='your-cart-title'>
            Subscribe
            </div>
            <div className='set-meal'>
                <div className={plan === "plus" ? 'set-meal-l active' : "set-meal-l"} onClick={() => setPlan("plus")}>
                    <h4>Plus</h4>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>Clips</span>
                    </p>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>30 Days Storage</span>
                    </p>
                </div>
                <div className={plan === "premium" ? 'set-meal-l active-premium' : "set-meal-l"} onClick={() => setPlan("premium")}>
                    <h4>Premium</h4>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>Clips</span>
                    </p>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>60 Days Storage</span>
                    </p>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>300GB Vault</span>
                    </p>
                </div>
                <div className={plan === "platium" ? 'set-meal-l active-platium' : "set-meal-l"} onClick={() => setPlan("platium")}>
                    <h4>Platium</h4>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>Continuous</span>
                    </p>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>60 Days Storage</span>
                    </p>
                    <p>
                        <Icon component={SelectActive} style={{ fontSize: "12px", }} />
                        <span>500GB Vault</span>
                    </p>
                </div>
            </div>
            <div className='your-cart-card'>
                <div className='your-cart-card-l'>
                    <div>
                        {yearplusObj.time === "year" && <img src={SecurityPlanPlus} alt="" />}
                    </div>
                    <div>
                        <p>Safeguard Plan</p>
                        <p>{plan}</p>
                    </div>
                </div>
                <div className='your-cart-card-m'>
                    <div>Device Quantity</div>
                    <div>
                        <p onClick={() => handleQuanity("minus")} className={num === 1 ? "" : 'active'}><Icon component={Minus} style={{ fontSize: "14px" }} /></p>
                        <p>{num === 100 ? <span style={{ color: "#16ADFD" }}>Unlimited</span> : num}</p>
                        <p onClick={() => handleQuanity("add")} className={num >= 3 ? "" : 'active'}><Icon component={Add} style={{ fontSize: "14px" }} /></p>
                    </div>
                </div>
                <div className='your-cart-card-r'>
                    <p onClick={() => setIsPlus(true)}>
                        <Icon component={isPlus ? Selected : Unselected} style={{ fontSize: "24px", marginRight: "12px" }} />
                        ${currentPlanInfo.year} <span>/year</span></p>
                    <p onClick={() => setIsPlus(false)}>
                        <Icon component={!isPlus ? Selected : Unselected} style={{ fontSize: "24px", marginRight: "12px" }} />
                        ${currentPlanInfo.month} <span>/month</span></p>
                </div>
            </div>
            {/* <div className='shop'>
                <div className='shop-title'>
                    Buy Annual Plan Get Free Devices, You can select (1) device(s) from the following model(s) for free.
                </div>
                <div className='shop-card'>
                    <div className='shop-card-l'>
                        <div>
                            <img src={Device1} alt="" />
                        </div>
                        <div>
                            Outdoor Camera
                        </div>
                    </div>
                    <div className='shop-card-r'>
                        <p><Icon component={Minus} style={{ fontSize: "14px" }} /></p>
                        <p>1</p>
                        <p className='active'><Icon component={Add} style={{ fontSize: "14px" }} /></p>
                    </div>
                </div>
                <div className='shop-card'>
                    <div className='shop-card-l'>
                        <div>
                            <img src={Device1} alt="" />
                        </div>
                        <div>
                            Indoor Camera
                        </div>
                    </div>
                    <div className='shop-card-r'>
                        <p><Icon component={Minus} style={{ fontSize: "14px" }} /></p>
                        <p>1</p>
                        <p className='active'><Icon component={Add} style={{ fontSize: "14px" }} /></p>
                    </div>
                </div>
            </div> */}
            {/* <div className='bundle'>
                <div className='bundle-title'>Subscribe security plan, get free devices, don't miss the biggest deal:</div>
                <div className='bundle-con'>
                    <Row gutter={12}>
                        <Col span={8}>
                            <div className='bundle-con-card bundle-con-card-active' >
                                <div>
                                    <h4>Bundle 1</h4>
                                    <p>Premium+Single Camera+Annual</p>
                                    <p>1 PCS Free Device</p>
                                </div>
                                <div>
                                    <Icon component={Selected} style={{ fontSize: "24px", marginRight: "12px" }} />
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='bundle-con-card'>
                                <div>
                                    <h4>Bundle 2</h4>
                                    <p>Premium+Unlimited Cameras+Annual</p>
                                    <p>Indoor Camera*1+Outdoor Camera*1</p>
                                </div>
                                <div>
                                    <Icon component={Unselected} style={{ fontSize: "24px", marginRight: "12px" }} />
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='bundle-con-card'>
                                <div>
                                    <h4>Bundle 3</h4>
                                    <p>Platinum+Unlimited Cameras+Annual</p>
                                    <p>Indoor Camera*2+Outdoor Camera*2</p>
                                </div>
                                <div>
                                    <Icon component={Unselected} style={{ fontSize: "24px", marginRight: "12px" }} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div> */}
            <div className='note'>
                <h4>Note:</h4>
                <p>1.The safeguard plan is a subscription. You can cancel at any time.</p>
                <p>2.The safeguard plan takes effect on the activation date and renews automatically according to the billing cycle.  </p>
                <p>3.For plans that include free equipment, you are free to change the plan to higher tier in the first year. But it is restricted to change the plan to lower tier during this period.</p>
            </div>
            <div className='footer'>
                <div className='footer-l'>
                    <p>
                        <Icon component={SafetyTips} style={{ fontSize: "18px" }} />
                    </p>
                    <p>The Cloud Recording is protected by bank-level encryption that can be accessed only by your personal account, to<br />
                        ensure that your videos are kept safe at all times.</p>
                </div>
                <div className='footer-r'>
                    <Button onClick={handleMaybeLater}>Maybe Later</Button>
                    <Button onClick={handleCheckOut}>Check Out</Button>
                </div>
            </div>
            <MaybeLaterModal open={isMaybeLaterModal} onOpen={setIsMaybeLaterModal}/>
        </div>
    </>
}

export default SubscribeSecurity
