import './index.less'
import DeviceCenterApi from "../../../../server/device";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {getPlatfromByUrl} from "../../../../utils/common";

const deviceCenterApi = new DeviceCenterApi();

export default function DeviceCenterModuleLiveView(){
	const [list, setList] = useState<any>([]);
	const [onlineDevice, setOnlineDevice] = useState<any>({});
	const [checkDeviceStatusTime, setCheckDeviceStatusTime] = useState<number>(0);
	const [devCloud, setDevCloud] = useState<any>({});
	const [requestListStatus, setRequestListStatus] = useState<number>(0); // 1 请求中， 2 请求之后

	const navigate = useNavigate();

	const timeOutRef = useRef<any>(0);

	const _platform = getPlatfromByUrl();

	useEffect(() => {
		setRequestListStatus(() => 1);

		deviceCenterApi.getDeviceList().then((res:any) => {
			setList(() => res);
			setRequestListStatus(() => -1);

			if(res.length){
				setCheckDeviceStatusTime(() => 1);

				clearTimeout(timeOutRef.current);
				timeOutRef.current = setTimeout(() => {
					setCheckDeviceStatusTime((v) => v + 1);
				}, 1000 * 60 * 5)
			}
		});

		return () => {
			clearTimeout(timeOutRef.current);
		}
	}, [])


	const playerVideoFun = ({physical_id}) => {
		navigate(`/device-center/player/${physical_id}`)
	}

	useEffect(() => {
		if(list.length && checkDeviceStatusTime > 0){
			for(let item of list){
				checkDevOnlione(item['physical_id']);
			}

			checkDevCloud('');
		}
	}, [list, checkDeviceStatusTime])

	// 查询设备是否在线
	const checkDevOnlione = (physical_id) => {
		deviceCenterApi.checkDeviceOnlineStatus(physical_id).then(res => {
			let _onlineDevice = JSON.parse(JSON.stringify(onlineDevice));
			_onlineDevice[physical_id] = res;

			setOnlineDevice((prev) => {
				prev[physical_id] = res;
				return JSON.parse(JSON.stringify(prev));
			});
		})
	}

	// 查询设备云服务状态
	const checkDevCloud = (physical_id) => {
		deviceCenterApi.checkDeviceCloudServeStatus(physical_id).then((res:any) => {
			if(Array.isArray(res)){
				let _devCloud = {};
				for(let item of res as any[]){
					_devCloud[item.physical_id] = 0;
					if([1,3,4,6].includes(Number(item.status))){
						// 60 天连续云
						if(item.period === 3 && item.plan_type == 6){
							_devCloud[item.physical_id] = 7;
						}else if(item.period === 3 && item.plan_type == 5){ //60 天告警云
							_devCloud[item.physical_id] = 60;
						}else if(item.period === 2 && item.plan_type == 5){ //30 天告警云
							_devCloud[item.physical_id] = 30;
						}
					}
				}
				setDevCloud(() => _devCloud);
			}
		})
	}

	return <>
		<div className={'device-center-liveview'}>

			{
				requestListStatus > 0 && <>
          <div className={'loading-status-box'}>
            <div className={'loading-status-icon'}>
              <Spin size="large" />
							{/*indicator={<LoadingOutlined spin />}*/}
            </div>
          </div>
				</>
			}

			{
				list.map((item, key) => {

					return <div
							className={'device-item-container'}
							key={key}
					>
						<div className={'camera-cover'}>
							<div className={'camera-default-bg'}>
								{['smartz'].includes(_platform) && <img src="/res/icons/player/smartz-logo.png" alt=""/>}
								{['zmodo', 'zmd'].includes(_platform) && <img src="/res/zmodo/icons/player/zmodo-logo.svg" alt=""/>}
							</div>
							<div className={'play-bg-box'}>
								<div className={'play-btn'} onClick={() => playerVideoFun(item)}>
									<img src="/res/icons/player/icon_play.svg" alt=""/>
								</div>
							</div>
						</div>
						<div className={'camera-intro-box'} >
							<div className={'camera-name-box'}>
								<p className={'camera-name'}>{item.name}</p>
								<p className={'camera-alert'}>No new alerts</p>
							</div>
							<div className={'camera-status-box'}>
								{
									devCloud[item?.physical_id] === 7 && <span style={{marginRight: '20px'}}><img src={'/res/icons/cloud/cloud-7.svg'} alt=""/></span>
								}
								{
									devCloud[item?.physical_id] === 30 && <span style={{marginRight: '20px'}}><img src={'/res/icons/cloud/cloud-30.svg'} alt=""/></span>
								}
								{
									devCloud[item?.physical_id] === 60 && <span style={{marginRight: '20px'}}><img src={'/res/icons/cloud/cloud-60.svg'} alt=""/></span>
								}

								{
									onlineDevice[item?.physical_id] ?
										<span className={'icon-online'}></span> :
										<span className={'icon-offline'}></span>
								}
							</div>
						</div>
					</div>
				})
			}

			{
				requestListStatus < 0 && list.length === 0 && <div className={'no-device-item'}>
          <img src="/res/icons/icon-no-device.png" alt=""/>
          <p className={'text-18'}>There are no devices yet. </p>
        </div>
			}

		</div>
	</>
}
