import './index.less'
import UserPng from '../../assets/images/topBar/user.png'
import Png from '../../assets/images/account/13.png'
import visaPng from '../../assets/images/account/visa.png'
import addPng from '../../assets/images/account/add.png'
import {getLogout} from '../../server/login'
import {Button, Drawer, Row, Col, message} from 'antd';
import ShippingAddressModal from './components/shippingAddressModal'
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import ChangePassword from './components/changePassword'
import IconUserDefault from '../../assets/images/user-default-profile.png';
import PaymentHistory from "./components/paymentHistory";
import {useAccountBillingHistoryStore} from "../../store/account";
import {getInfoSubscription, getListSubscription, getVdrInfo, getVdrList} from "../../server/cloudPlan";
import dayjs from "dayjs";
import AnyUrlModel from "../cloudPlan/components/anyUrlModel";

import IconPlanPlus from '../../assets/images/cloudPlan/security_plan_plus.png';
import IconPlanPremium from '../../assets/images/cloudPlan/security_plan_premium.png';
import IconPlanPlatinum from '../../assets/images/cloudPlan/security_Plan_platium.png';
import IconPlan1T from '../../assets/images/cloudPlan/vault_plan_basic.png';
import IconPlan3T from '../../assets/images/cloudPlan/vault_plan_advanced.png';
import {getCookie, getLGToken, getPlatfromByUrl, removeCookie, setCookie} from "../../utils/common";

const orderStatusMap = {
	FREETRIAL: 1,
	ACTIVE: 2,
	UNSUBSCRIBED: 4,
	canceled: 4,
	CANCELED: 4,
	SCHEDULED: 1,
	UNPAID: 7,
}

const statusTagMap = {
	'-1': 'FREETRIAL',
	0: 'Pedding',
	1: 'Subscribed',
	2: 'Subscribed',
	3: 'Expired',
	4: 'Cancelled',
	5: 'Expired',
	6: '',
	7: 'Billing Issue',
	8: 'Expired'
}

function ZmodoPaymentManagement(){
	let _platform = getPlatfromByUrl();

	let _token = getLGToken();
	let _url = `https://11-web.meshare.com/${_platform}/application/user/paymentmanagement.html?token=${_token}&version=2&run_env=web`

	return <div className={'z-payment-management-box'}>
		<iframe src={_url}></iframe>
	</div>
}

const Account = () => {
	const navigate = useNavigate();
	const [isShippingAddressModal, setIsShippingAddressModal] = useState(false)
	const [isChangePassword, setIsChangePassword] = useState(false)
	const [accountObj, setAccountObj] = useState<any>({})

	const {accountBillingHistoryZU, setAccountBillingHistoryZU} = useAccountBillingHistoryStore();
	const [cloudPlanOrderWeb, setCloudPlanOrderWeb] = useState<any>([]);
	const [vaultPlanOrderWeb, setVaultPlanOrderWeb] = useState<any>([]);

	const [anyModelUrl, setAnyModelUrl] = useState<string>('');

	let _platform = getPlatfromByUrl();

	const handleUpdate = () => {
		setIsShippingAddressModal(true)
	}

	useEffect(() => {
		const account: any = getCookie('accountInfo');
		if (JSON.stringify(account) !== '' && account !== null)
			setAccountObj(JSON.parse(account));
	}, [])

	const handleLogOut = async () => {
		let params = {
			token: getLGToken(),
		}
		const res: any = await getLogout(params)

		removeCookie('token');
		removeCookie('oldPassword')
		removeCookie('accountInfo')
		removeCookie('email');
		removeCookie('username');

		if (res.result === "ok") {
			message.success("Exit successfully")
			localStorage.clear();
			navigate('/login');
		} else {
			message.error(`${res.res}`)
			localStorage.clear();
			navigate('/login');

		}

		postMSGSendFun();
	}

	const billingHistoryFun = (data) => {
		setAccountBillingHistoryZU({open: true, data: data})
	}

	useEffect(() => {
		getCloudListFun();
		getVaultListFun();

		receivePostMessage();

		return () => {
			window.removeEventListener('message', processMessageEvent);
		}
	}, [])

	const receivePostMessage = () => {
		window.removeEventListener('message', processMessageEvent);
		window.addEventListener('message', processMessageEvent)
	}
	const processMessageEvent = (evt) => {
		let data = evt.data;
		let origin = evt.origin;

		if(["https://11-web.meshare.com", "https://11-web.meshare.cn"].includes(origin)){

			let type = data?.type;
			let url = data.data;
			if(type === 'openDialog'){
				setAnyModelUrl(() => url);
			}
		}
	}


	const getCloudListFun = async () => {
		setCloudPlanOrderWeb(() => []);
		let res_cloud = await getListSubscription();

		if(Array.isArray(res_cloud)){
			for(let item of res_cloud){
				getWebOrderDetail(item);
			}
		}
	}

	const getVaultListFun = async () => {
		setVaultPlanOrderWeb(() => []);
		let res_vault = await getVdrList();

		if(Array.isArray(res_vault)){
			for(let item of res_vault){
				getVaultOrderDetail(item);
			}
		}
	}

	const getWebOrderDetail = async (o) => {
		const result: any = await getInfoSubscription(o.id);
		let _obj:any = {};
		if(result){
			_obj = {...result};
			_obj.price = Number(result.amount_in_cent) / 100
			_obj.end_time = result.next_payment_day && Number(result.next_payment_day) !== 0 ? dayjs(result.next_payment_day).format('MM/DD/YYYY') : '---';

			_obj.txt = "Security"

			_obj.pay_type = Number(result.pay_type);
			_obj.period = Number(result.period);
			_obj.plan_type = Number(result.plan_type);
			_obj.devices_count = Number(result.device_count);

			_obj.o_status = o.status;
			_obj.status = (o.status).toUpperCase();
			o.status = orderStatusMap[o.status] ? orderStatusMap[o.status] : o.status;
			_obj.order_pf = 'web_pay';

			setCloudPlanOrderWeb((prev) => [...prev, _obj]);
		}

	}

	const getVaultOrderDetail = async (o) => {
		const result: any = await getVdrInfo(o.id);

		let _obj:any = {};
		if(result){
			_obj = {...result};
			_obj.price = (Number(result.amount_in_cent) / 100).toFixed(2);
			_obj.end_time = _obj.next_payment_day ? dayjs(_obj.next_payment_day).format('MM/DD/YYYY') : '';

			_obj.o_status = _obj.status;
			_obj.status = (_obj.status).toUpperCase();
			_obj.status = orderStatusMap[_obj.status] ? orderStatusMap[_obj.status] : _obj.status;
			_obj.order_pf = 'web_pay';
			_obj.txt = "Vault";
			_obj.id = o.id;

			setVaultPlanOrderWeb((prev) => [...prev, _obj]);
		}

		// return _obj;
	}

	const postMSGSendFun = () => {
		setCookie('WEB_APP_EVT_MSG_LOGIN_OR_LOGOUT', 'logout');

		setTimeout(() => {
			removeCookie('WEB_APP_EVT_MSG_LOGIN_OR_LOGOUT');
		}, 5000)
	}

	return (
		<div className="account-con">
			<div className="title">
				<h4>Account</h4>
				<p>{accountObj?.email}</p>
			</div>
			<div className='account-content'>
				<Row gutter={34} className={'cls-t-2503201336s'}>
					<Col span={12}>
						<div className='account-content-information'>
							<div className='account-content-information-title'>Information</div>
							<div className='card'>
								<div className='user'>
									<img src={IconUserDefault} alt=""/>
									<p>{accountObj?.email}</p>
								</div>
								<div className='card-button'>
									<Button onClick={() => setIsChangePassword(true)}>Change Password</Button>
									<Button onClick={handleLogOut}>Log Out</Button>
								</div>
							</div>
						</div>
					</Col>
					<Col span={12}>

						<div className='shipping-address-visa' style={{marginTop: "0px"}}>
							<div className='shipping-address-visa-title title-pre-a'>
								Payment Management
							</div>

							{
								['zmd', 'zmodo'].includes(_platform) ?
								<ZmodoPaymentManagement />
									:
								<div>
									{
										cloudPlanOrderWeb.map((item:any, index:number) => {
											let _icon = null;
											let _name = '';
											if(item.period === 2 && item.plan_type === 5){
												_icon = IconPlanPlus;
												_name = 'Plus';
											}else if(item.period === 3 && item.plan_type === 5){
												_icon = IconPlanPremium;
												_name = 'Premium';
											}else if(item.period === 3 && item.plan_type === 6){
												_icon = IconPlanPlatinum;
												_name = 'Platinum';
											}

											_name = `${_name} (${item.pay_type === 2 ? 'Yearly' : 'Monthly'})`


											return <div className="payment-content">
												<div className="payment-card">
													<div className="card-img-con" style={{border: 'none'}}>
														<img src={_icon} width={64} alt=""/>
													</div>
													<div>
														<div className="card-num">{_name}</div>
														<div className="time">Device Quantity: {Number(item.devices_count) === 100 ? 'Unlimited' : item.devices_count}</div>
														<div className="time">{[1,2,7].includes(item.status)?'Renewal Date: ':'Expired Date: '}{item.end_time}</div>
													</div>
												</div>
												<div className="line"></div>
												<div className="btn-con" onClick={() => billingHistoryFun(item)}>
													Billing History
												</div>
											</div>
										})
									}

									{
										vaultPlanOrderWeb.map((item:any, index:number) => {
											let _icon = null;
											let _name = '';
											if(Number(item.size_type) === 1){
												_icon = IconPlan1T;
												_name = 'Basic';
											}else if(Number(item.size_type) === 2){
												_icon = IconPlan3T;
												_name = 'Advanced';
											}

											_name = `${_name} (${item.pay_type === 2 ? 'Yearly' : 'Monthly'})`


											return <div className="payment-content">
												<div className="payment-card">
													<div className="card-img-con" style={{border: 'none'}}>
														<img src={_icon} width={64} alt=""/>
													</div>
													<div>
														<div className="card-num">{_name}</div>
														<div className="time">Cloud Storage: {Number(item.size_type) === 2 ? '3TB' : '1TB'}</div>
														<div className="time">{[1,2,7].includes(item.status)?'Renewal Date: ':'Expired Date: '}{item.end_time}</div>
													</div>
												</div>
												<div className="line"></div>
												<div className="btn-con" onClick={() => billingHistoryFun(item)}>
													Billing History
												</div>
											</div>
										})
									}
								</div>
							}

							{/*<div className="payment-content">*/}
							{/*	<div className="payment-card">*/}
							{/*		<div className="card-img-con">*/}
							{/*			<img src={Png} alt=""/>*/}
							{/*		</div>*/}
							{/*		<div>*/}
							{/*			<div className="card-num">VISA **** **** **** 4023</div>*/}
							{/*			<div className="time">Expires 04/22</div>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*	<div className="line"></div>*/}
							{/*	<div className="btn-con" onClick={billingHistoryFun}>*/}
							{/*		Billing History*/}
							{/*		/!*<button className="history">Billing History</button>*!/*/}
							{/*		/!*<button className="remove">Remove</button>*!/*/}
							{/*	</div>*/}
							{/*</div>*/}

						</div>
					</Col>
				</Row>
			</div>
			<ShippingAddressModal open={isShippingAddressModal} onOpen={setIsShippingAddressModal}/>
			<ChangePassword open={isChangePassword} onOpen={setIsChangePassword}/>

			<PaymentHistory open={accountBillingHistoryZU.open}></PaymentHistory>

			{anyModelUrl && <AnyUrlModel url={anyModelUrl} closeFun={setAnyModelUrl}/>}
		</div>
	)
}

export default Account
