import '../index.less'
import {Menu} from 'antd';
import type { MenuProps } from 'antd';
import {useEffect, useState} from "react";
import DeviceCenterModuleLiveView from "../modules/liveView";
import DeviceCenterModuleAlerts from "../modules/alerts";
import DeviceCenterModuleMemories from "../modules/Memories";
import {useNavigate, useParams} from "react-router-dom";

const LiveView = () => {

	const [current, setCurrent] = useState('liveview');

	const navigate = useNavigate();
	const {pathKey} = useParams()

	type MenuItem = Required<MenuProps>['items'][number];
	const items: MenuItem[] = [
		{
			label: 'Live View',
			key: 'liveview',
		},
		{
			label: 'Alerts',
			key: 'alerts',
		},
		{
			label: 'Memories',
			key: 'memories',
		}
	]

	useEffect(() => {
		if(pathKey){
			setCurrent(pathKey);
		}
	}, [pathKey])

	const onClick: MenuProps['onClick'] = (e) => {
		// console.log('click ', e);
		// setCurrent(e.key);
		navigate(`/device-center/${e.key}`)
	};

	return <>
		<div className={'page-device-center'}>
			<div className={'main-container'}>
				<p className={'h1-title'}>Live View</p>
				{/* <Menu className={'device-center-nav'} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} /> */}
				<div>
                 <DeviceCenterModuleLiveView />
				</div>
			</div>
		</div>
	</>
}

export default LiveView;