import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function generatePDF(isNo, id, title) {
  //下载pdf方法
  let demo = document.getElementById(id);
  demo.style.overflow = 'visible';
  html2canvas(demo, {
    allowTaint: true, //允许跨域
    height: document.getElementById(id).scrollHeight, //
    width: document.getElementById(id).scrollWidth, //为了使横向滚动条的内容全部展示，这里必须指定
    backgroundColor: "#FFFFFF", //如果指定的div没有设置背景色会默认成黑色
    // dpi: 300,
    scale: 1.5,
    useCORS: true, //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
  }).then((canvas) => {
    
    let pdf = new jsPDF('p', 'mm', 'a4'); //A4纸，纵向
    let ctx = canvas.getContext('2d'),
      a4w = 190,
      a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
      imgHeight = Math.floor(a4h * canvas.width / a4w), //按A4显示比例换算一页图像的像素高度
      renderedHeight = 0;

    while (renderedHeight < canvas.height) {
      let page = document.createElement("canvas");
      page.width = canvas.width;
      page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
      //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
      page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(
        imgHeight, canvas.height - renderedHeight)), 0, 0);
      pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height /
        page.width)); //添加图像到页面，保留10mm边距
      renderedHeight += imgHeight;
      if (renderedHeight < canvas.height) pdf.addPage(); //如果后面还有内容，添加一个空页
    }
    if (isNo) {
        // 导出pdf
        pdf.save(title + '.pdf');
    } else {
        // 打印
        pdf.autoPrint()
        pdf.output('dataurlnewwindow');
    }
  })
  demo.style.overflow = 'auto';
}

export { generatePDF };