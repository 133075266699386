import "./index.less"
import {useEffect, useRef, useState} from 'react';
import {Radio, Space, Switch, Toast} from 'antd-mobile'
import HeadNav from "../../../components/headNav"
// import _ from 'lodash'
import {getSettingConfig, setSettingConfig} from "../../../server/setting";
import NAPI from "../../../utils/NAPI";

function Settings() {
	const [checkedObj, setCheckedObj] = useState<any>({
		auto_save: 0,
		back_up: false,
		only_wifi: false
	})
	const [uaMSG, setUaMSG] = useState('');
	const [clickTimce, setClickTimce] = useState(0);

	const timeoutRef:any = useRef(0);

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if(NAPI.check()){
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	const showBUGINFO = () => {
		let _count = clickTimce;
		_count = _count + 1;
		setClickTimce(() => _count);
		if(_count % 10 === 0){
			let ua = navigator.userAgent;
			setUaMSG(() => ua);
		}else{
			setUaMSG(() => '');
		}
	}

	const handleChecke = (target: string, e: any) => {
		let newObj = JSON.parse(JSON.stringify(checkedObj))
		newObj[target] = e
		setConfigFunc(newObj)
		console.log(newObj);
		// setCheckedObj(newObj)
	}

	const setConfigFunc = (o) => {
		let req = {
			...o
		}
		setSettingConfig(req).then((res:any) => {
			let {data, result} = res;
			if(result === 'ok' && data){
				getSetConfig();
				settingConfig2App(o);
			}
		}).catch(e => {
			console.log(e)
			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	const getSetConfig = () => {
		getSettingConfig().then((res:any) => {
			let {data, result} = res;
			if(result === 'ok' && data){
				setCheckedObj(() => data)
			}
		}).catch(e => {
			console.log(e)
			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			getSetConfig()
		}, 300)

		return () => {
			clearTimeout(timeoutRef.current);
		}
	}, [])

	const settingConfig2App = (o) => {
		if(NAPI.check()){
			NAPI.apptodo('systemConfig', {...o})
		}
	}

	return <div className="favourite-con settings-con">
		<HeadNav title="Settings"/>
		<div className="content">
			<div className="content-title">
				Auto Save
			</div>
			<div className="settings-con" style={{flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start"}}>
				{/*<p>Auto save pictures and videos*/}
				{/*	created by App to Vault.</p>*/}
				{/*<span>*/}
				{/*	<Switch style={{*/}
				{/*		'--checked-color': '#16ADFD',*/}
				{/*		'--height': '24px',*/}
				{/*		'--width': '48px',*/}
				{/*	}} checked={checkedObj.auto_save} onChange={(e) => handleChecke("auto_save", e)}/>*/}
				{/*</span>*/}

				<div className={'--desc'}>Auto save pictures and videos from the Smartz Eaze app to:</div>
				<Radio.Group value={checkedObj.auto_save} onChange={(e) => handleChecke("auto_save", e)} >
					<Space direction='vertical'>
						<Radio value={3} icon={checked => checked ? <span className={'adm-radio-icon --checked'}></span> : <span className={'adm-radio-icon'}></span>}>Phone's Photos & Vault</Radio>
						<Radio value={1} icon={checked => checked ? <span className={'adm-radio-icon --checked'}></span> : <span className={'adm-radio-icon'}></span>}>Smartz Vault</Radio>
						<Radio value={2} icon={checked => checked ? <span className={'adm-radio-icon --checked'}></span> : <span className={'adm-radio-icon'}></span>}>Phone's Photos</Radio>
					</Space>
				</Radio.Group>

			</div>
			<div className="settings-con" style={{flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start"}}>
				<div className={'--flex'}>
					<p className={'--wds'}>Backup Phone Photos</p>
					<span>
						<Switch style={{
							'--checked-color': '#16ADFD',
							'--height': '6px',
							'--width': '48px',
						}} checked={checkedObj.back_up} onChange={(e) => handleChecke("back_up", e)}/>
					</span>
				</div>
				<div className={'--desc-a'}>
					Auto save the pictures and videos from your Phone's
					Photos to Vault.
				</div>
			</div>
			<div className="content-title" onClick={showBUGINFO}>
				Data Usage
			</div>
			<div className="settings-con" style={{padding: "12px"}}>
				{/*<p>Transfer files only over Wi-Fi.*/}
				{/*	<br/>*/}
				{/*	<span>Uploading and downloading file will pause when the Wi-Fi connection is not available.</span>*/}
				{/*</p>*/}
				{/*<span style={{marginTop: "-36px"}}>*/}
				{/*	<Switch style={{*/}
				{/*	'--checked-color': '#16ADFD',*/}
				{/*	'--height': '24px',*/}
				{/*	'--width': '48px',*/}
				{/*}} checked={checkedObj.only_wifi} onChange={(e) => handleChecke("only_wifi", e)}/>*/}
				{/*</span>*/}

				<Radio.Group value={checkedObj.only_wifi?1:0} onChange={(e) => handleChecke("only_wifi", e)}>
					<Space direction='vertical'>
						<Radio value={1} icon={checked => checked ? <span className={'adm-radio-icon --checked'}></span> : <span className={'adm-radio-icon'}></span>}>Transfer files only over Wi-Fi</Radio>
						<Radio value={0} icon={checked => checked ? <span className={'adm-radio-icon --checked'}></span> : <span className={'adm-radio-icon'}></span>}>Transfer Files over Wi-Fi or Mobile Data</Radio>
					</Space>
				</Radio.Group>
			</div>
		</div>

		{
			uaMSG !== '' && <>
        <div style={{padding: ' 10px 25px', boxSizing: 'border-box',  opacity: 0.15, position: "absolute",  left: 0, right: 0, bottom: 0, userSelect: "none", pointerEvents: "none"}}>
					{uaMSG}
        </div>
			</>
		}

	</div>
}

export default Settings
