import './index.less'
import { Col, InputNumber, Row, Slider, Space, Spin } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import {clipIotekVideo, downloadClipIotekVideo, checkClipIotekVideo} from '../../../../server/device'
import { LoadingOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'

const _format = 'HH:mm:ss a'
export default function ClipVideo(props){
  const {startTime, closeWin, physical_id} = props;
  const [endTime, setEndTime] = useState<string>('');
  const [value, setValue] = useState(0);
  const [currentStartTime, setCurrentStartTime] = useState<string>('');
  const [downloadUrl, setDownloadUrl] = useState<string>('');
  const [downloadMSG, setDownloadMSG] = useState<string>('');
  const [downloadName, setDownloadName] = useState<string>('');

  const startTimeRef = useRef<any>('');
  const endTimeRef = useRef<any>('');

  const timeoutRef = useRef<any>(0);
  const downloadAtagRef = useRef<any>(null);

  let _step = 100 / (60 * 10);

  useEffect(() => {
    console.log(startTime)

    let _dt = String(startTime);
    _dt = _dt.length === 10 ? startTime * 1000 : startTime;

    startTimeRef.current = _dt;

    let _end = dayjs(_dt).add(1, 'minutes').format(_format);

    setEndTime(() => _end);

    let _endFull = dayjs(_dt).add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    endTimeRef.current = dayjs(_endFull).valueOf();

    let _val = 60 * _step;
    setValue(() => _val);

    let _st = dayjs(_dt).format(_format)
    setCurrentStartTime(() => _st)
  }, [])

  const onChange = (v) => {
    let minSec = v / _step;
    let _end = dayjs(startTimeRef.current).add(minSec, 'seconds').format(_format);

    let _endFull = dayjs(startTimeRef.current).add(minSec, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    endTimeRef.current = dayjs(_endFull).valueOf();

    setEndTime(() => _end);
    setValue(() => v);
  }

  const startClip = async () => {

    let _len = Math.floor((endTimeRef.current - startTimeRef.current) / 1000);
    let _name = dayjs(startTimeRef.current).format('YYYYMMDDHHmmss');
    _name = `clip_video_${_name}`;
    let _start = dayjs(startTimeRef.current).unix();


    let req = {
      physical_id: physical_id,
      start_time: _start,  //起始时间，unix时间戳，如1525856859
      time_length: _len,  //视频片段的长度（单位秒S）
      clip_name: _name, //视频指定的视频片段名称
      attach_osd: 0, //是否添加OSD时间；0:不添加，1:添加
    }


    let res:any = await clipIotekVideo(req);


    setDownloadMSG(() => 'Video clipping...')

    const {result, clip_id} = res;

    if(result === 'ok' && clip_id){
      let timer:any = 0;

      clearInterval(timeoutRef.current);
      timeoutRef.current = setInterval(() => {
        clearTimeout(timer);
        let rq = {
          // url: '',
          clip_id: clip_id
        }

        checkClipIotekVideo(rq).then((re:any) => {

          let {result, data, url} = re;

          if(result === 'ok' && url){
            // let { url, video_url } = data;

            if(url){

              clearInterval(timeoutRef.current);

              let rq = {
                url: url,
              }
              let _url = downloadClipIotekVideo(url);
              _url = `${window.location.origin}${_url}`

              setDownloadUrl(() => _url);
              setDownloadMSG(() => 'Start download...');
              setDownloadName(() => _name);

              clearTimeout(timer);
              timer = setTimeout(() => {
                setDownloadMSG(() => '');
                downloadFile()
              },1000)

            }
          }

        }).catch(e => {
          console.log(e);
        })

      }, 5000)
    }
  }

  const downloadFile = () => {
    if(downloadAtagRef.current){
      downloadAtagRef.current.click();
    }
  }

  const closeWinFunc = () => {
    if(closeWin){
      closeWin(false);
    }
  }

  return <>
    <div data-v-f6624a4c className="clipbox">
      <span data-v-f6624a4c className="close" onClick={closeWinFunc}>×</span>
      <h3 data-v-f6624a4c className="title">Select Clip Period</h3>
      <p data-v-f6624a4c className="ctxt">Intelligent compression will be applied to this clip</p>
      <span data-v-f6624a4c className="line"></span>
      <div data-v-f6624a4c  className="prograss-box">
        <p data-v-f6624a4c className="wtxt">
          <span data-v-f6624a4c>1MIN</span>
        </p>
        <div data-v-f6624a4c className="bar-box">
          <div data-v-f6624a4c className="bar">
            <Slider onChange={onChange} value={value} step={_step} tooltip={{open:false}} />
          </div>
        </div>
        <p data-v-f6624a4c className="wtxt">
          <span data-v-f6624a4c>10MIN</span>
        </p>
      </div>
      <div data-v-f6624a4c className="show-time">
        <p data-v-f6624a4c>
          <span data-v-f6624a4c className="wtxt">Start </span>
          <span data-v-f6624a4c className="wtxt">{(currentStartTime).toUpperCase()}</span>
        </p>
        <p>
          <span data-v-f6624a4c className="wtxt">End </span>
          <span data-v-f6624a4c className="wtxt">{(endTime).toUpperCase()}</span>
        </p>
        <p data-v-f6624a4c className="loadering-status">
          {downloadMSG && <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: 'rgba(15, 45, 81, 0.75)' }} spin />} />}
          {downloadMSG && <span style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '3px',
          }}>{downloadMSG}</span>}
        </p>
        {/*<div data-v-f6624a4c className="circle">*/}
        {/*  <span data-v-f6624a4c></span>*/}
        {/*  <span data-v-f6624a4c></span>*/}
        {/*</div>*/}
      </div>
      <div data-v-f6624a4c className="btn" onClick={startClip}>
        <span data-v-f6624a4c>Start Clipping</span>
      </div>
      <a
        ref={downloadAtagRef}
        href={downloadUrl}
        download={downloadName}
        style={{ display: 'none' }}
				target={'_blank'}
      />
    </div>
  </>
}
