/* eslint-disable */
import './index.less'
import {Button, Flex, Space, Image, Alert, message} from "antd";
import {useEffect, useRef, useState} from "react";
import React from 'react';
// import VideoPlayer from 'react-video-js-player';
import {getMemoryStatus} from "../../../../server/plan";
import MemoriesApi from "../../../../server/memories";
import MyVideoJsPlayer from "../../../../components/myVideoJsPlayer";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import pfFileUrl from "../../../../server/PFFileUrl";
import {getStorageInformation} from "../../../../server/storage";
import {getLGToken, isJSON} from "../../../../utils/common";

const memoriesApi = new MemoriesApi();

export default function DeviceCenterModuleMemories(){

	const [memoriesEnable, setMemoriesEnable] = useState<boolean>(false);
	const [videoList, setVideoList] = useState<any>([]);
	const [currentVideoData, setCurrentVideoData] = useState<any>({});
	const [videoOptions, setVideoOptions] = useState({
		// src: 'https://cdn.meshare.com/platform/video/memories/Check-Out-Who-Stopped-By.mp4', // 初始视频地址
		poster: '', // 初始封面图地址
		controls: true,
		autoplay: false,
		preload: 'auto',
		// width: '100%',
		// height: 'auto',
		sources: [{
			src: '',
			type: 'video/mp4'
		}],
	});

	const [loadMore, setLoadMore] = useState<number>(0);
	const [memoryVideosDirID, setMemoryVideosDirID] = useState<number>(0);

	const loadMoreFlagRef = useRef<any>(null);
	const scrollHVRef = useRef<number>(0);
	const loadDataRef = useRef<any>({start: 0, count: 999})
	const navigate = useNavigate();

	useEffect(() => {
		checkStatusFun();
		getVaultinfoFun();
	}, [])

	useEffect(() => {

		if(currentVideoData?.id){
			let _videoOptions = JSON.parse(JSON.stringify(videoOptions));
			_videoOptions.sources[0].src = currentVideoData.video_url;
			_videoOptions.poster = currentVideoData.cover_url;

			_videoOptions._isOK = true;

			setVideoOptions(() => _videoOptions);

		}

	}, [currentVideoData])

	useEffect(() => {

		if(loadMore == 1){
			loadDataRef.current.start = loadDataRef.current.start + loadDataRef.current.count;
			getMemoriesList();
		}
	}, [loadMore])

	const checkStatusFun = () => {
		let req = {
			cid: 0,
			token: getLGToken()
		}
		getMemoryStatus(req).then((res:any) => {
			if(res.result === 'ok' && res?.data?.enable){
				getMemoriesList();
				setMemoriesEnable(() => true);
			}else{
				getMemoriesExample();
				setMemoriesEnable(() => false);
			}
		}).catch(e => {
			console.log(e)
		})
	}

	const getMemoriesList = () => {
		let {start, count} = loadDataRef.current;
		memoriesApi.getMemoriesList(start, count).then((res:any) => {

			res.map((item:any) => {
				if(item.cover_url){
					item.cover_url = pfFileUrl(item.cover_url);
				}
				if(item.video_url){
					item.video_url = pfFileUrl(item.video_url);
				}

				return item;
			})

			setVideoList((prev) => [...prev, ...res]);

			if(res.length === count){
				setLoadMore(() => -1);
			}else{
				setLoadMore(() => 0);
			}

			if(res.length && start === 0){
				setCurrentVideoData(() => res[0])
			}
		})
	}

	const getMemoriesExample = () => {
		memoriesApi.getMemoriesExp().then((res:any) => {
			setVideoList(() => res);

			if(res.length){
				setCurrentVideoData(() => res[0])
			}
		})
	}

	const clickVideoItemFun = (o) => {
		setCurrentVideoData(() => o);
	}

	const saveToVaultFun = () => {
		let _id = currentVideoData.id;
		memoriesApi.saveMemoriesToVault(_id, memoryVideosDirID).then((res:any) => {
			if(res){
				message.success('Saved successfully');
			}else{
				message.error('Save failed');
			}
		})
	}

	const downloadFileToLocalFun = () => {
		// if(dwonloadTagRef.current){
			try {
				// dwonloadTagRef.current.click();

				if(currentVideoData?.video_url){
					let a = document.createElement('a');
					a.style.display = 'none';
					a.href = currentVideoData?.video_url;
					a.download = currentVideoData?.video_url;
					a.target = '_blank';
					a.rel = 'noopener noreferrer';
					document.body.append(a);
					a.click();

					// setTimeout(() => {
					// 	a.remove();
					// },300)
					setTimeout(() => {
						// a.remove();
					},500)
				}

			}catch (e) {
				console.log(e);
			}
		// }
	}

	const toSubscribePage = () => {
		navigate('/cloud-plan');
	}

	const getVaultinfoFun = async () => {
		let res:any = await getStorageInformation();
		let {data} = res;
		if(isJSON(data)){
			let _MemoryVideos = data?.cloud_gallery?.MemoryVideos;
			setMemoryVideosDirID(() => _MemoryVideos);
		}
	}

	const scrollListFun = (e) => {
		// console.log(JSON.stringify(e.target.getBoundingClientRect()), 22)

		let _tag = loadMoreFlagRef.current;
		// console.dir(_tag)
		let dd = {
			clientHeight:_tag.clientHeight,
			clientLeft:_tag.clientLeft,
			clientTop:_tag.clientTop,
			clientWidth:_tag.clientWidth,

			offsetHeight:_tag.offsetHeight,
			offsetLeft:_tag.offsetLeft,
			offsetTop:_tag.offsetTop,
			offsetWidth:_tag.offsetWidth,
		}

		let _pPs = e.target.getBoundingClientRect();
		let _bPs = _tag.getBoundingClientRect();

		// console.log(_pPs, _bPs);

		if(scrollHVRef.current !== 0){
			if(scrollHVRef.current > _bPs.top){
				// console.log('向下')

				if(_bPs.bottom - _pPs.bottom < 100){

					if(loadMore == -1){
						setLoadMore(() => 1)
					}
				}
			}else{
				// console.log('向上')
			}
		}
		scrollHVRef.current = _bPs.top;
	}

	let _dateTime = currentVideoData?.end_time ? dayjs(Number(currentVideoData?.end_time) * 1000).format('MMM DD, YYYY') : dayjs().format('MMM DD, YYYY');

	return <>
		<div className={'device-center-memories'}>
			<div className={'--head-box'}>
				<Flex vertical={false} justify={'space-between'}>
					<div className={'title-box'}>
						<p className={'t1'} style={{marginBottom: '10px'}}>{currentVideoData?.title ? currentVideoData?.title : 'Enjoy the family life'}</p>
						<p className={'w1'}>{currentVideoData?.device_name} {_dateTime}</p>
					</div>

					{
						memoriesEnable && <div className={'button-box'}>
              <Space>
                <Button shape={'round'} onClick={downloadFileToLocalFun}>Save to My Computer</Button>
                <Button shape={'round'} type={'primary'} onClick={saveToVaultFun}>Save to Smartz Vault</Button>
              </Space>
            </div>
					}

				</Flex>

			</div>
			<div className={'--body-box'}>
				<Flex vertical={false} justify={'space-between'}>
					<div className={'--video-box'}>
						<MyVideoJsPlayer videoOptions={videoOptions} />
					</div>
					<div className={'--video-list'} onScroll={scrollListFun}>
						{
							videoList.map((item:any, index: number) => {

								return <div
									className={`--video-item ${currentVideoData?.id === item.id ? '--current' : ''}`}
									key={index}
									onClick={() => clickVideoItemFun(item)}
								>
									<Image src={item.cover_url} preview={false} />
								</div>
							})
						}
						<div ref={loadMoreFlagRef} className={'a-loadmore-flag'}></div>
					</div>
				</Flex>
			</div>

			{
				!memoriesEnable && <div className={'--foot-box'}>
          <Flex vertical={false} justify={'space-between'}>
            <div className={'intro-box w1'}>
              Subscribe to the Premium or Platinum Security Plan now to extend your video storage duration.
              Plus, enjoy automatic memory video creation powered by AI.
            </div>
            <div className={'button-box'}>
              <Button shape={'round'} type={'primary'} style={{width: '250px'}} onClick={toSubscribePage}>Subscribe Now</Button>
            </div>
          </Flex>
        </div>
			}

		</div>

		{/*<a*/}
		{/*	ref={dwonloadTagRef}*/}
		{/*	href={currentVideoData?.video_url}*/}
		{/*	target={'_blank'}*/}
		{/*	download={currentVideoData?.video_url}*/}
		{/*	style={{display: 'none'}}*/}
		{/*></a>*/}
	</>
}
