import './index.less'
import { Row, Col, Button, Form, Input, Modal, message } from 'antd';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'
import Icon from "@ant-design/icons";
import { getChangePassword } from '../../../../server/login'
import md5 from 'js-md5';
import { useNavigate } from "react-router-dom";
import {getCookie, getLGToken, removeCookie, setCookie} from "../../../../utils/common";

const ChangePassword = (props) => {
  const { open, onOpen } = props;
  const [userForm] = Form.useForm();
  const navigate = useNavigate();

  const handleOk = async () => {
    try {
      const res: any = await userForm.validateFields();
      // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      //  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z][A-Za-z\d@$!%*#?&]{8,}$/
      const regex = /^(?!^\d+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^a-z0-9]+$)(?!^[^A-Z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S*$/

      if (JSON.stringify(res) !== '{}') {
        let oldPassword = getCookie('oldPassword')
        if (oldPassword !== md5(res.oldpassword)) {
          message.error('Old password is incorrect')
          return
        }

        if (!regex.test(res.oldpassword) || !regex.test(res.newpassword) || !regex.test(res.confirmationpassword)) {
          message.error('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number')
          return
        }
          if (res.newpassword !== res.confirmationpassword) {
            message.error('Passwords do not match ')
            return false;
          }


        let params: any = {
          oldpassword: md5(res.oldpassword),
          password: md5(res.newpassword),
          token: getLGToken(),
        }
        const result: any = await getChangePassword(params)
        if (result.result === 'ok') {
          message.success('Password changed successfully')
          handleCancel()

					removeCookie('token');
					removeCookie('oldPassword');
					removeCookie('accountInfo');
					removeCookie('email');
					removeCookie('username');

          navigate('/login');
          localStorage.clear();
        }
        onOpen(false);
      }
    } catch (error) {
      // message.error('Password change failed')
    }

  };

  const handleCancel = () => {
    userForm.resetFields();
    // userForm.setFieldsValue({
    //   oldPassword: "",
    //   newPassword: "",
    //   confirmationpassword: "",
    // })
    onOpen(false);
  };
  return (
    <>
      <Modal title="" open={open} className='change-password-modal' centered onCancel={handleCancel} footer={null}>
        <div className='change-password-modal-title'>
          <p></p>
          <p>Change Password</p>
          <p onClick={handleCancel}>
            <Icon component={CloseIcon} style={{ fontSize: "14px" }} />
          </p>
        </div>
        <div className='change-password-modal-from'>
          <Form
            form={userForm}
            layout="vertical"
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="Old Password:" name="oldpassword" rules={[{ required: true, message: 'Please enter your old password!' }]}>
                  <Input.Password placeholder='Please enter your old password' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="New Password:" name="newpassword" rules={[{ required: true, message: 'Please enter your new password!' }]}>
                  <Input.Password placeholder='Please enter your new password' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Confirmation Password:" name="confirmationpassword" rules={[{ required: true, message: 'Please enter your confirmation password!' }]}>
                  <Input.Password placeholder='Please enter your confirmation password' />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <div className='change-password-modal-footer'>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOk}>Save</Button>
        </div>
      </Modal>
    </>
  );
};
export default ChangePassword
