import {useEffect, useState} from "react";
import {Drawer} from "antd";
import './index.less';
import {useAccountBillingHistoryStore} from "../../../../store/account";
import {getPaymentHistoryCloud, getPaymentHistoryVault} from "../../../../server/cloudPlan";
import dayjs from "dayjs";

export default function PaymentHistory(props){
	const {open} = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [list, setList] = useState<any>([]);
	const {accountBillingHistoryZU, setAccountBillingHistoryZU} = useAccountBillingHistoryStore();

	useEffect(() => {
		if(accountBillingHistoryZU.open){console.log(accountBillingHistoryZU, 'accountBillingHistoryZU');
			setLoading(() => true);
			getHistoryListFun(accountBillingHistoryZU.data);
			setTimeout(() => {
				// setLoading(() => false);
			}, 500)
		}
	}, [accountBillingHistoryZU])

	const closeFun = () => {
		setAccountBillingHistoryZU({open:false, data: accountBillingHistoryZU.data});
		setLoading(() => false);
	}

	const getHistoryListFun = async (data) => {
		let {order_pf, identity_id, size_type} = data;

		let _func = size_type ? getPaymentHistoryVault : getPaymentHistoryCloud

		let res:any = await _func(identity_id);
		setLoading(() => false);

		if(Array.isArray(res)){

			// res.map((item:any) => {
			// 	item.price = (item.amount / 100);
			// 	item.datetime_str = dayjs(item.createAt).format('MMM DD, YYYY')
			// 	return item;
			// })

			let arr = [];

			for(let item of res){
				item.price = (item.amount / 100);
				item.datetime_str = dayjs(item.createAt).format('MMM DD, YYYY');

				// if(item.paymentType !== 'cancel'){
				// 	arr.push(item);
				// }
				arr.push(item);
			}

			setList(() => arr);
		}
	}

	return <>
		<Drawer
			closable
			destroyOnClose
			title={<p>Billing History</p>}
			placement="right"
			open={open}
			loading={loading}
			onClose={closeFun}
			className={'a-billing-history-drawer'}
		>
			<div className={'a-billing-history-con'}>
				{
					list.map((item:any, index:number) => {
						return <div className={'a-billing-history-item'} key={index}>
							{item.paymentType !== 'cancel' && <div className={'a-status-tag'}>{item.status}</div>}
							<div className={'a1-row a1-mrn-btm'}>
								<span className={'a-fs'}>{item.datetime_str}</span>
								<span className={'a-fs'}>{item.billType}</span>
							</div>
							<div className={'a1-row'}>
								<span className={'c-fs'}>{item.paymentType}</span>
								{item.paymentType !== 'cancel' ? <span className={'e-fs'}>${item.price}</span> : <span>---</span>}
							</div>
						</div>
					})
				}
			</div>
		</Drawer>
	</>
}
