import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import HomePage from "../pages/vault/meDrive";
import PhotoPage from "../pages/vault/Photo";
import Favourite from "../pages/vault/Favourite";
import Recent from "../pages/vault/Recent";
import Trash from "../pages/vault/Trash";
import Settings from "../pages/vault/Settings";
import Search from "../pages/vault/search";
import StoragePage from "../pages/vault/Storage";
import PlanPage from "../pages/vault/Plan";
import CleanUpMeDrivePage from "../pages/vault/cleanUpMeDrive";
import FileItemsPage from "../pages/vault/FileItems";
import UploadPage from "../pages/vault/Upload";
import Details from "../pages/vault/Details";
import MiddlePage from "../pages/vault/middlePage";
import FilePage from "../pages/vault/File";
import LargeFilePage from "../pages/vault/largeFilePage";
import CloudGalleryPhoto from "../pages/vault/cloudGalleryPhone"

import VaultHeadNav from "../components/vaultHeadNav";

const MyRoute = (path, element) => {
	return <>
		<Route path={`/${path}`} element={element} />
		{/*<Route path={`/:platform/${path}`} element={element} />*/}
	</>
}

function VaultRouter() {

	return (<>
		<VaultHeadNav />
		<Routes>
			{MyRoute('/', <HomePage />)}
			{MyRoute('photo', <PhotoPage />)}
			{MyRoute('favourite', <Favourite />)}
			{MyRoute('recent', <Recent />)}
			{MyRoute('trash', <Trash />)}
			{MyRoute('settings', <Settings />)}
			{MyRoute('search', <Search />)}
			{MyRoute('storage', <StoragePage />)}
			{MyRoute('plan', <PlanPage />)}
			{MyRoute('clean-up-medrive', <CleanUpMeDrivePage />)}
			{MyRoute('file-items', <FileItemsPage />)}
			{MyRoute('upload', <UploadPage />)}
			{MyRoute('details', <Details />)}
			{MyRoute('middle', <MiddlePage />)}
			{MyRoute('file', <FilePage />)}
			{MyRoute('large-file', <LargeFilePage />)}
			{MyRoute('cloud-gallery-photo', <CloudGalleryPhoto />)}
		</Routes>
	</>)
}

export default VaultRouter;
