import getApiUrl from "../apiUrl";
import requsetData from "../request";
import service from '../newRequest'
import {getCookie, getLGToken, isJSON, json2params} from "../../utils/common";

// const url = getApiUrl('cloudDisk');
const url = "/vdr"


// 上传文件
export function getUploadFile(data, onUploadProgress=null){
    let headers = {
        'Content-Type': 'multipart/form-data',
    }
    return service.post(`${url}/disk/upload_file`, data, headers, onUploadProgress)
}

/**
 * 请求上传大文件
 * @param data
 */
export function tryUploadByChunk(data) {
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
	}
	return service.post(`${url}/disk/try_upload_by_chunk`, data, headers)
}

/**
 * 上传大文件分片
 * @param data
 */
export function uploadFileChunk(data, onUploadProgress=null) {
	let headers = {
		'Content-Type': 'multipart/form-data',
	}
	return service.post(`${url}/disk/upload_file_chunk`, data, headers, onUploadProgress)
}

/**
 * 查询大文件已上传分片
 * @param data
 */
export function queryFileChunk(data) {
	return service.get(`${url}/disk/query_file_chunk`, data)
}

/**
 * 获取上传列表
 * @param data
 */
export function uploadRecord(data={}) {
	let token = getLGToken();
	let headers = {
		'Authorization': `Bearer ${token}`,
	}
	return service.get(`${url}/upload-record`, data, headers)
}

/**
 * 删除上传记录
 * @param id
 */
export function deleteUploadRecord(id) {
	let token = getLGToken();
	let headers = {
		'Authorization': `Bearer ${token}`,
	}
	return service.delete(`${url}/upload-record/${id}`, {}, headers)
}

/**
 * 批量删除上传记录
 * @param data
 */
export function batchDeleteUploadRecord(data) {
	return service.delete(`${url}/upload-record/batch-delete`, data)
}

/**
 * 删除未上传完的文件分片
 * @param data
 */
export function removeUnUploadedFileChunk(data:any) {
	let accountInfo:any = getCookie('accountInfo');

	try {
		accountInfo = JSON.parse(accountInfo);
		data.cid = accountInfo.cid;
		data.user_id = accountInfo.id;
	}catch (e) {
		console.log(e)
	}

	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
	}

	return service.post(`${url}/disk/delete_file_chunk`, data, headers);
}
