import BackSvg from '../../../../assets/images/order/back.svg'
import CreateOrderGraySvg from '../../../../assets/images/order/create_order_gray.svg'
import CreateOrderWhiteSvg from '../../../../assets/images/order/create_order_white.svg'
import CreateOrderBlackSvg from '../../../../assets/images/order/create_order_black.svg'

import PackagingGraySvg from '../../../../assets/images/order/packaging_gray.svg'
import PackagingWhiteSvg from '../../../../assets/images/order/packaging_white.svg'
import PackagingBlackSvg from '../../../../assets/images/order/packaging_black.svg'

import PrintGraySvg from '../../../../assets/images/order/print_gray.svg'
import PrintWhiteSvg from '../../../../assets/images/order/print_white.svg'
import PrintBlackSvg from '../../../../assets/images/order/print_black.svg'

import PickingGraySvg from '../../../../assets/images/order/picking_gray.svg'
import PickingWhiteSvg from '../../../../assets/images/order/picking_white.svg'
import PickingBlackSvg from '../../../../assets/images/order/picking_black.svg'

import ShippingGraySvg from '../../../../assets/images/order/shipping_gray.svg'
import ShippingWhiteSvg from '../../../../assets/images/order/shipping_white.svg'
import ShippingBlackSvg from '../../../../assets/images/order/shipping_black.svg'

import DeliveredGraySvg from '../../../../assets/images/order/delivered_gray.svg'
import DeliveredWhiteSvg from '../../../../assets/images/order/delivered_white.svg'
import DeliveredBlackSvg from '../../../../assets/images/order/delivered_black.svg'

import RmaRequestGraySvg from '../../../../assets/images/order/rma_request_gray.svg'
import RmaRequestWhiteSvg from '../../../../assets/images/order/rma_request_white.svg'
import RmaRequestBlackSvg from '../../../../assets/images/order/rma_request_black.svg'

import ReceiveCheckGraySvg from '../../../../assets/images/order/receive_check_gray.svg'
import ReceiveCheckWhiteSvg from '../../../../assets/images/order/receive_check_white.svg'
import ReceiveCheckBlackSvg from '../../../../assets/images/order/receive_check_black.svg'

import Device1 from '../../../../assets/images/cloudPlan/device1.png'
import Icon from "@ant-design/icons";
import './index.less';
import { useState } from 'react'
import { Button } from 'antd'

const MyOrdersDetails = () => {
  const [current, setCurrent] = useState(1)
  const [id, setId] = useState(5)

  const handleTab = (ind) => {
    setCurrent(ind)
  }

  const handleBack = () => {
    window.history.back()
  }

  return <div className='order-details'>
    <div className='order-details-title'>
      <p onClick={handleBack}><Icon component={BackSvg} style={{ fontSize: "18px" }} /></p>
      <p>Order #: SO202408279024</p>
    </div>
    {
      id === 1 && <div className='order-details-con'>
        <div className='order-information'>
          <div className='order-information-title'>
            <p>Order Information</p>
          </div>
          <div className='order-information-card'>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Security Plan: Premium+Single Camera+Annual</p>
                  <p>Date: 30/07/2024</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p>$79.99</p>
              </div>
            </div>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Zmodo SH001C-1PCS</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p><span>$39.99</span>$79.99</p>
              </div>
            </div>
          </div>
        </div>
        <div className='information'>
          <div className='information-item'>
            <h4>Billing Information:</h4>
            <div>
              <p>Payment Method: Credit Card</p>
              <p>Card Number: 245577484</p>
              <p>Expiration Date: 18/08/2026</p>
              <p>Security Code: 2355</p>
            </div>
          </div>
          <div className='information-item'>
            <h4>Shipping Information:</h4>
            <div>
              <p>Address: 1401 Interstate,Champaign,IL,USA</p>
              <p>Name: Kevin Zhao</p>
              <p>Postal Code: xxxxxx</p>
              <p>Phone Number: 18944394445</p>
            </div>
          </div>
          <div className='information-item'>
            <h4>User Information:</h4>
            <div>
              <p>Eaze Account: <span className='active'>kevinzhao@meshare.com</span></p>
            </div>
          </div>
        </div>
        <div className='hr'></div>
        <div className='tracking-tab'>
          <div className='tracking-tab-title'>
            <p className={current === 1 ? 'active' : ''} onClick={() => handleTab(1)}>Order Tracking</p>
            <p className={current === 2 ? 'active' : ''} onClick={() => handleTab(2)}>RMA Tracking</p>
          </div>
          {
            current === 1 && <div className='tracking-tab-con'>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={CreateOrderBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Create Order</p>
                  <p>24/08/2024 10:12:35 AM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PackagingBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>24/08/2024 10:22:39 AM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PrintBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Print Shipping Label</p>
                  <p>24/08/2024 10:24:35 AM</p>
                </div>
                <div className='shipping'>
                  <p>shipping #: <span>3345566673355663</span></p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PickingBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>25/08/2024 1:24:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one tracking-tab-con-one-active'>
                <div>
                  <p><Icon component={ShippingWhiteSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Shipping</p>
                  <p>25/08/2024 5:32:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={DeliveredGraySvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Delivered</p>
                  <p></p>
                </div>
              </div>
            </div>
          }
          {
            current === 2 && <div className='tracking-tab-con'>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={RmaRequestBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Create Order</p>
                  <p>24/08/2024 10:12:35 AM</p>
                </div>
                <div className='line'></div>
                <div className='comments'>
                  <p>RMA#: <span className='active'>177446994844166357</span></p>
                  <p>Return Type: <span>Replacement</span></p>
                  <p>Return Reason: <span>Defective Device</span></p>
                  <p>Comments: <span>The device doesn't work, I need to replace an new one.</span></p>
                </div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={ReceiveCheckBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>24/08/2024 10:22:39 AM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PrintBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Print Shipping Label</p>
                  <p>24/08/2024 10:24:35 AM</p>
                </div>
                <div className='shipping'>
                  <p>shipping #: <span>3345566673355663</span></p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PickingBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>25/08/2024 1:24:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one tracking-tab-con-one-active'>
                <div>
                  <p><Icon component={ShippingWhiteSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Shipping</p>
                  <p>25/08/2024 5:32:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={DeliveredGraySvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Delivered</p>
                  <p></p>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    }
    {
      id === 2 && <div className='order-details-con'>
        <div className='order-information'>
          <div className='order-information-title'>
            <p>Order Information</p>
          </div>
          <div className='order-information-card'>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Security Plan: Premium+Single Camera+Annual</p>
                  <p>Date: 30/07/2024</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p>$79.99</p>
              </div>
            </div>
          </div>
          <div className='order-details-check-out'>
            <Button>Check Out</Button>
        </div>
        </div>
       
      </div>
    }
    {
      id === 3 && <div className='order-details-con'>
        <div className='order-information'>
          <div className='order-information-title'>
            <p>Order Information</p>
          </div>
          <div className='order-information-card'>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Security Plan: Premium+Single Camera+Annual</p>
                  <p>Date: 30/07/2024</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p>$79.99</p>
              </div>
            </div>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Zmodo SH001C-1PCS</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p><span>$39.99</span>$79.99</p>
              </div>
            </div>
          </div>
          <div className='order-details-check-out'>
            <Button>Check Out</Button>
        </div>
        </div>
       
      </div>
    }

{
      id === 4 && <div className='order-details-con'>
        <div className='order-information'>
          <div className='order-information-title'>
            <p>Order Information</p>
          </div>
          <div className='order-information-card'>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Security Plan: Premium+Single Camera+Annual</p>
                  <p>Date: 30/07/2024</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p>$79.99</p>
              </div>
            </div>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Zmodo SH001C-1PCS</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p><span>$39.99</span>$0</p>
              </div>
            </div>
          </div>
        </div>
        <div className='information'>
          <div className='information-item'>
            <h4>Billing Information:</h4>
            <div>
              <p>Payment Method: Credit Card</p>
              <p>Card Number: 245577484</p>
              <p>Expiration Date: 18/08/2026</p>
              <p>Security Code: 2355</p>
            </div>
          </div>
          <div className='information-item'>
            <h4>Shipping Information:</h4>
            <div>
              <p>Address: 1401 Interstate,Champaign,IL,USA</p>
              <p>Name: Kevin Zhao</p>
              <p>Postal Code: xxxxxx</p>
              <p>Phone Number: 18944394445</p>
            </div>
          </div>
          <div className='information-item'>
            <h4>User Information:</h4>
            <div>
              <p>Eaze Account: <span className='active'>kevinzhao@meshare.com</span></p>
            </div>
          </div>
        </div>
        <div className='hr'></div>
        <div className='tracking-tab'>
          <div className='tracking-tab-title'>
            <p>Order Tracking</p>
            {/* <p className={current === 2 ? 'active' : ''} onClick={() => handleTab(2)}>RMA Tracking</p> */}
          </div>
          {
            current === 1 && <div className='tracking-tab-con'>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={CreateOrderBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Create Order</p>
                  <p>24/08/2024 10:12:35 AM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PackagingBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>24/08/2024 10:22:39 AM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PrintBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Print Shipping Label</p>
                  <p>24/08/2024 10:24:35 AM</p>
                </div>
                <div className='shipping'>
                  <p>shipping #: <span>3345566673355663</span></p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PickingBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>25/08/2024 1:24:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one tracking-tab-con-one-active'>
                <div>
                  <p><Icon component={ShippingWhiteSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Shipping</p>
                  <p>25/08/2024 5:32:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={DeliveredGraySvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Delivered</p>
                  <p></p>
                </div>
              </div>
            </div>
          }
          {
            current === 2 && <div className='tracking-tab-con'>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={RmaRequestBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Create Order</p>
                  <p>24/08/2024 10:12:35 AM</p>
                </div>
                <div className='line'></div>
                <div className='comments'>
                  <p>RMA#: <span className='active'>177446994844166357</span></p>
                  <p>Return Type: <span>Replacement</span></p>
                  <p>Return Reason: <span>Defective Device</span></p>
                  <p>Comments: <span>The device doesn't work, I need to replace an new one.</span></p>
                </div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={ReceiveCheckBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>24/08/2024 10:22:39 AM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PrintBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Print Shipping Label</p>
                  <p>24/08/2024 10:24:35 AM</p>
                </div>
                <div className='shipping'>
                  <p>shipping #: <span>3345566673355663</span></p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={PickingBlackSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Packaging</p>
                  <p>25/08/2024 1:24:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one tracking-tab-con-one-active'>
                <div>
                  <p><Icon component={ShippingWhiteSvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Shipping</p>
                  <p>25/08/2024 5:32:30 PM</p>
                </div>
                <div className='line'></div>
              </div>
              <div className='tracking-tab-con-one'>
                <div>
                  <p><Icon component={DeliveredGraySvg} style={{ fontSize: "22px" }} /></p>
                </div>
                <div>
                  <p>Delivered</p>
                  <p></p>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    }

{
      id === 5 && <div className='order-details-con'>
        <div className='order-information'>
          <div className='order-information-title'>
            <p>Order Information</p>
          </div>
          <div className='order-information-card'>
            <div className='order-information-card-item'>
              <div className='order-information-card-item-l'>
                <div>
                  <img src={Device1} alt="" />
                </div>
                <div>
                  <p>Security Plan: Premium+Single Camera+Annual</p>
                  <p>Date: 30/07/2024</p>
                </div>
              </div>
              <div className='order-information-card-item-r'>
                <p>$3.99</p>
              </div>
            </div>
          </div>
        </div>
        <div className='information'>
          <div className='information-item'>
            <h4>Billing Information:</h4>
            <div>
              <p>Payment Method: Credit Card</p>
              <p>Card Number: 245577484</p>
              <p>Expiration Date: 18/08/2026</p>
              <p>Security Code: 2355</p>
            </div>
          </div>
          <div className='information-item'>
            <h4>Shipping Information:</h4>
            <div>
              {/* <p>Address: 1401 Interstate,Champaign,IL,USA</p>
              <p>Name: Kevin Zhao</p>
              <p>Postal Code: xxxxxx</p>
              <p>Phone Number: 18944394445</p> */}
            </div>
          </div>
          <div className='information-item'>
            <h4>User Information:</h4>
            <div>
              <p>Eaze Account: <span className='active'>kevinzhao@meshare.com</span></p>
            </div>
          </div>
        </div>
      </div>
    }




  </div>;
};




export default MyOrdersDetails;