import './index.less';
import DeviceAlertApi from "../../../../server/deviceAlert";
import {useEffect, useRef, useState} from "react";
import pfFileUrl from "../../../../server/PFFileUrl";
import {Image} from "antd";
import dayjs from "dayjs";

const dviceAlertApi = new DeviceAlertApi();

export default function PlayListMD(props){
	const {showClose, open, onChange, onClick, offset_seconds} = props;

	const [loadMore, setLoadMore] = useState<boolean>(false);
	const [currentMaxTime, setCurrentMaxTime] = useState<any>(0);
	const [list, setList] = useState<any>([]);
	const [listDate, setListDate] = useState<any>([]);

	const listDataRef = useRef<any>(null);
	const endDataRef = useRef<any>(null);
	const listDateRef = useRef<any>([]);

	const countRef = useRef(0)

	useEffect(() => {
		// getAlerListFun();

		let dom = listDataRef.current;

		let fg = 0;
		let timer:any = 0;

		if(dom){
			dom.removeEventListener('scroll', () => {});
			dom.addEventListener('scroll', () => {
				let el = endDataRef.current;

				if(el){
					let o_bottom = dom.getBoundingClientRect().bottom;
					let e_bottom = el.getBoundingClientRect().bottom;

					fg = fg - e_bottom;

					clearTimeout(timer);
					if(o_bottom + 50 > e_bottom){

						if(fg > 0){
							timer = setTimeout(() => {
								listenLoadMoreFun();
							}, 300)
						}
					}

					fg = el.getBoundingClientRect().bottom;
				}
			})
		}

		return () => {
			if(dom){
				dom.removeEventListener('scroll', () => {});
			}

			clearTimeout(timer);
		}

	}, [])

	useEffect(() => {
		if(Number(offset_seconds) === 0 || offset_seconds){
			getAlerListFun();
		}
	}, [offset_seconds])

	useEffect(() => {
		if(loadMore){
			getAlerListFun();
		}
	}, [loadMore])

	useEffect(() => {
		if(open){
			// getAlerListFun();
		}
	}, [open])

	// 获取列表懒加载
	const getAlerListFun = async () => {
		let {physical_id} = props;
		let req = {
			physical_id: physical_id,
			count: 20,
			max_time: currentMaxTime
		}
		let res:any = await dviceAlertApi.getAlertList(req);
		if(Array.isArray(res)){
			if(res.length === 20){
				let last_item = res[19];
				if(last_item.create_time){
					setCurrentMaxTime(() => last_item.create_time);
				}
			}

			let arr = [];

			for(let item of res){
				item.a_lable = item.moving_object;
				let _time = '--:--';
				let _ta = dayjs().unix();
				let _fm = 'hh:mm:ss A';
				if(item.create_time){
					if(_ta - item.create_time > 24 * 60 * 60 ){
						// _fm = 'DD,MMM hh:mm:ss A';
					}

					let local_off_time = new Date().getTimezoneOffset() * 60; // 本地时间
					let dev_off_time = offset_seconds ? Number(offset_seconds) : 0;  //设备时间

					_time = dayjs((Number(item.alarm_time) + local_off_time + dev_off_time) * 1000).format(_fm)
				}
				item.a_time = _time;

				let _date = dayjs().format('MMM DD, YYYY');
				item.a_date = dayjs(item.alarm_time * 1000).format('MMM DD, YYYY');
				if(_date === item.a_date){
					item.a_date = 'Today';
				}

				arr.push(item);
			}


			countRef.current = res.length;
			setList((prev) => [...prev, ...arr]);

			if(loadMore){
				setLoadMore(() => false);
			}
		}
		// console.log(res, 'getAlerListFun');
	}

	// 监听滚动加载
	const listenLoadMoreFun = () => {
		if(countRef.current === 20){
			setLoadMore(() => true);
		}
	}

	const onChangeFun = () => {
		if(onChange){
			onChange(!open);
		}
	}

	const onClickFun = (o) => {
		let alarm_time = o.alarm_time;
		if(alarm_time){

			let is_cloud = Number(o.cloud_playback);
			let v_url = o.video_url ? `${pfFileUrl(o.video_url)}&transcoding=1&physical_id=${o?.from_id}` : '';
			if(onClick){
				onClick(alarm_time, is_cloud, v_url);
			}
		}
	}

	listDateRef.current = [];

	return <div className={'play-list-comp'}>
		{
			showClose && <div className={'o-btn'} onClick={onChangeFun}>
        <span className={`o-btn-icon ${open?'rt-180':''}`}></span>
      </div>
		}

		<div className={'o-list'} ref={listDataRef}>
			{
				list.map((item:any, index:number) => {
					let a_date = item.a_date;
					if(!listDateRef.current.includes(a_date)){
						listDateRef.current.push(a_date);

						return <div key={index}>
							<div className={'o-item-date-label'}>{a_date}</div>
							<div className={'o-item'} onClick={() => onClickFun(item)}>
								<div className={'o-icon'}><Image src={pfFileUrl(item.image_url)} preview={false} alt=""/></div>
								<div className={'o-cont'}>
									<p className={'o-t1'}>{item.a_lable}</p>
									<p className={'o-t2'}>{item.a_time}</p>
								</div>
								<span className={'o-end-icon'}>
									<Image src={item.moving_object === 'People' ? '/res/icons/alert/pp.png' : '/res/icons/alert/at.png'} preview={false} alt=""/>
								</span>
							</div>
						</div>
					}else{
						return <div className={'o-item'} key={index} onClick={() => onClickFun(item)}>
							<div className={'o-icon'}><Image src={pfFileUrl(item.image_url)} preview={false} alt=""/></div>
							<div className={'o-cont'}>
								<p className={'o-t1'}>{item.a_lable}</p>
								<p className={'o-t2'}>{item.a_time}</p>
							</div>
							<span className={'o-end-icon'}>
							<Image src={item.moving_object === 'People' ? '/res/icons/alert/pp.png' : '/res/icons/alert/at.png'} preview={false} alt=""/>
						</span>
						</div>
					}
				})
			}
			<div className={'--end-load-more-flag'} ref={endDataRef}></div>
		</div>
	</div>
}
