import {getPlatfromByUrl} from "../common";
import logo_zmodo from '../../assets/images/logo.png';
import logo_smartz from '../../assets/images/logo.svg';
function getLogo(){
	let res = logo_smartz;
	let pf = getPlatfromByUrl();
	if(pf === 'smartz'){
		res = logo_smartz;
	}else if(pf === 'zmodo' || pf === 'zmd'){
		res = logo_zmodo;
	}

	return res;
}

const mainLogo = getLogo();

export default mainLogo;
