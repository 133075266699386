import { BaseResource } from "../../utils/resource";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Button, message, Popover, Upload, Dropdown, Space, Modal } from 'antd';
import { Ellipsis, ProgressBar, Toast } from "antd-mobile";
import type { UploadFile } from 'antd/es/upload/interface'
import './index.less'
import { useEffect, useRef, useState } from "react";
import jumpPage from "../../utils/jumpPage";
import { getStorageInformation } from "../../server/storage";
import { getStorageInfo } from '../../server/vdrInfo';
import { getOrderList } from "../../server/plan";
import {
	deleteUploadRecord,
	getUploadFile,
	queryFileChunk,
	removeUnUploadedFileChunk,
	tryUploadByChunk,
	uploadFileChunk,
	uploadRecord
} from "../../server/upload"
import { getLGToken, isJSON, sizeFormat } from "../../utils/common";
import UploadSvg from '../../assets/images/vault/upload.svg'
import CreateNewFolderSvg from '../../assets/images/vault/createNewFolder.svg'
import getApiUrl from "../../server/apiUrl";
import dayjs from "dayjs";
import Icon from "@ant-design/icons";
import SparkMD5 from 'spark-md5';
import _ from 'lodash';
import FileUploadInformation from './components/file-upload-information'
import fileTypeIcon from "../../utils/fileTypeIcon";
import fileMIMEMap from "../../utils/fileMIMEMap";

import {
	useVaultInfoStore,
	useFileListStore,
	useUploadFileListStore,
	useIsUpdateFileListStore,
	useCreateDirStore,
	useVaultSystemFolderInfoStore,
	useVaultDetailInfoStore,
	useUpdateVaultInfoStore,
	useUpdateFolderListStore,
	useUploadingFileStatusStore
} from "../../store/vault";
import { getMSGExpire } from "../../server/storage";
import StoragePage from "../../pages/vault/Storage";
import CleanUpMeDrive from "../../pages/vault/cleanUpMeDrive";
import LargeFilesModal from "../../pages/vault/largeFilesModal";
import NewAccountModal from "./components/new-account-modal";
import TipsModal from "../../components/tips-modal";
import { useMyNavigate } from "../../hook/useMyNavigate";

// import IconUploadList from "/res/"

const { Dragger } = Upload;

const imageUrl = getApiUrl('cloudDisk');


export default function HomeHeadNavX(props) {
	const { storeInfo, onVisible, itemNum, onItemNum,
		onIsFolder, onIsStorage, onFileList } = props;
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isFileUploadInformation, setIsFileUploadInformation] = useState(false);
	const [noticeNotEnoughSpaceUpgrade, setNoticeNotEnoughSpaceUpgrade] = useState<boolean>(false);

	const [dropFileList, setDropFileList] = useState<any>([]);

	const [uploadList, setUploadList] = useState<any>([]);
	const [uploadListX, setUploadListX] = useState<any>([]);
	const [varInfo, setVarInfo] = useState<any>({});
	const [open, setOpen] = useState(false);


	const timeOutRef = useRef<any>(0);
	const timeOutARef = useRef<any>(0);
	const uploadFileArrayRef = useRef<any>([]);
	const pendingUploadFileArrayRef = useRef<any>([]);
	const uploadingFileArrayRef = useRef<any>([]);
	const uploadedFileArrayRef = useRef<any>([]);

	const pendingUploadFileListRef = useRef<any>([]);
	const uploadingFileListRef = useRef<any>([]);
	const uploadedFileListRef = useRef<any>([]);

	// 正在上传的文件id
	const uploadingFileIdsArrayRef = useRef<any>([]);
	// 已经上传的文件id
	const uploadedFileIdsArrayRef = useRef<any>([]);
	// 被暂停的文件ID
	const stopuploadFileIdsArrayRef = useRef<any>([]);
	// 被取消的文件ID
	const cancelUploadFileIdsArrayRef = useRef<any>([]);

	const uploadIndex: any = useRef(0);

	const [diskInfo, setDiskInfo] = useState({
		size: 0,
		usable: 0,
		used: 0,
		percent: 0,
		cloud_gallery_id: undefined,
		root_id: 0,
		_total: 0,
		upload_used: 0,
		_size: 0,
		_usable: 0,
		_used: 0,
	})

	const navigate = useNavigate()
	const { navigateX } = useMyNavigate()
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	let dirId = params.get('id') ? params.get('id') : 0;
	const isManagement = params.get('isManagement')
	const isCleanUpVault = params.get('isCleanUpVault')


	const ajaxTimeOut: any = useRef(0);

	const inVaultPageFlagRef = useRef<boolean>(false);

	const o_uploadListFunRef = useRef<any>();
	const o_uploadListXunRef = useRef<any>();



	o_uploadListFunRef.current = (v) => {


		setTimeout(() => {
			if (o_uploadListXunRef.current) {
				// o_uploadListXunRef.current(v);
			}
		}, 200)

		// console.log(datetime_11.current, 'o_uploadListFunRef')
		//
		// setUploadFileListZU(v);
	}

	o_uploadListXunRef.current = (v) => {

		setUploadListX(() => v);

	}
	const [isForbid, setIsforbid] = useState<any>(false)

	const datetime_11 = useRef<any>(dayjs().format('YYYY-MM-DD HH:mm:ss'));

	const leaveFlagRef = useRef<boolean>(true);

	const { vaultInfoZU, setVaultInfoZU } = useVaultInfoStore();
	const { uploadFileListZU, setUploadFileListZU } = useUploadFileListStore();
	const { fileListZU, setFileListZU } = useFileListStore();
	const { isUpdatefileListZU, setIsUpdateFileListZU } = useIsUpdateFileListStore();
	const { createDirZU, setCreateDirZU } = useCreateDirStore();
	const { uploadingFileStatusZU, setUploadingFileStatusZU } = useUploadingFileStatusStore();

	const { vaultDetailInfoZU, setVaultDetailInfoZU } = useVaultDetailInfoStore();
	const { vaultSystemFolderInfoZU, setVaultSystemFolderInfoZU } = useVaultSystemFolderInfoStore();
	const { isUpdateVaultInfoZU, setIsUpdateVaultInfoZU } = useUpdateVaultInfoStore();
	const { setUpdateFolderListZU } = useUpdateFolderListStore();

	const eFileListRef = useRef<any>([]);
	const updateFileListTimeRef = useRef<any>(0);

	const [isStorage, setIsStorage] = useState<any>(false);
	const [isCleanUpMeDrive, setIsCleanUpMeDrive] = useState<boolean>(false)

	const uploadDomRef = useRef<any>(null);
	const [isFolderUpload, setIsFolderUpload] = useState<boolean>(false);
	const onceUploadFileSizeRef = useRef<number>(0); // 一次上传的文件总大小

	const updateVaultInfoTimeOutRef = useRef<any>(null);
	const [isTipsModal, setIsTipsModal] = useState<boolean>(false);
	const [orderList, setOrderList] = useState<any>([]);
	const [isVaultSize, setIsVaultSize] = useState<boolean>(false);
	const uploadFileBeforeCountSizeRef = useRef<number>(0); // 上传之前的大小统计
	const uploadFileBeforeCountSizeTimeOutRef = useRef<any>(null);
	const uploadFileBeforeStoreFileItemsRef = useRef<any>([]);
	const [isLargeFilesModal, setIsLargeFilesModal] = useState<boolean>(false);

	useEffect(() => {
		// console.log(uploadingFileStatusZU, 'uploadingFileStatusZU')
	}, [uploadingFileStatusZU])

	// 判断文件是不是上传和新增
	useEffect(() => {

		if (location.pathname.includes("/photo") || location.pathname.includes("/favourite") || location.pathname.includes("/trash") || location.pathname.includes("/large-file")) {
			setIsforbid(true)
		} else if (location.pathname.includes("/vault")) {
			setIsforbid(false)
		}

	}, [location])


	useEffect(() => {
		if (diskInfo.root_id !== 0) {
			setVaultInfoZU(diskInfo);
		}
	}, [diskInfo])

	useEffect(() => {
		setUploadFileListZU(uploadList);
	}, [uploadList])

	useEffect(() => {
		eFileListRef.current = fileListZU;
		// console.log(fileListZU, 991)
	}, [fileListZU])

	useEffect(() => {
		// console.log(isUpdateVaultInfoZU, 'Update Vault Info');
		if (isUpdateVaultInfoZU) {
			clearTimeout(updateVaultInfoTimeOutRef.current);
			updateVaultInfoTimeOutRef.current = setTimeout(() => {
				getDiskStorageSize();
			}, 300)
		}
	}, [isUpdateVaultInfoZU])

	useEffect(() => {

		if (isManagement === 'true') {
			setIsStorage(true)
		} else if (isManagement == null || isManagement == undefined) {
			// console.log(isManagement, '134')
			setIsStorage(false)
		}

		if (isCleanUpVault === 'true') {
			setIsCleanUpMeDrive(true)
		}
	}, [isManagement, isCleanUpVault])

	useEffect(() => {

		if (location.pathname === "/vault/large-file") {
			setIsStorage(false)
		} else if (location.pathname === "/vault/trash") {
			setIsStorage(false)
		}
	}, [location.pathname])


	// 获取记录
	const getUploadRecord = async () => {
		let res: any = await uploadRecord();

		let i_data = res?.data;

		let vault_uploading_list: any = sessionStorage.getItem('vault_uploading_list');
		vault_uploading_list = vault_uploading_list === null ? '[]' : vault_uploading_list;
		let fileIdsArr = [];
		let uidObj = {};
		try {
			vault_uploading_list = JSON.parse(vault_uploading_list);
			for (let o of vault_uploading_list) {
				fileIdsArr.push(o._fileId);
				uidObj[o._fileId] = o.uid;
			}
		} catch (e) {
			console.log(e)
		}

		if (Array.isArray(i_data)) {
			i_data.map((item: any) => {
				item.uid = item.file_id;
				item.o_status = item.status;
				item.status = 5;
				item._fileId = item.file_id;
				if (item.ext) {
					item.ext = (item.ext).toLowerCase();
				}

				// 在上传列表的
				// if(fileIdsArr.includes(item.file_id)){
				// 	item.status = 2;
				// 	item.progress = (item.uploaded_size/item.size) * 100;
				// 	item.uid = uidObj[item.file_id]
				// }else{
				//
				// }

				if (item.status) {
					item.error_message = `Upload incomplete on ${dayjs(item.update_at).format('MM/DD/YYYY H:mm:ss A')}`
				}
				item.size_str = sizeFormat(item.size)
				if (1024 * 1024 * 50 < item.size) {
					item.is_large_file = 1;
				}




				return item;
			})

			setUploadList(() => i_data);
		}
	}


	// 判断网盘是不是订阅
	const initStorageInfo = async () => {
		const res: any = await getStorageInfo()

		if (res.result === "ok") {
			setVarInfo(res.data)
			if (res.data.root_id === 0 && res.data.backup_id === 0 && res.data.cloud_gallery == null) {
				setOpen(true)
			} else {
				setOpen(false)
			}
		} else {
			message.error(res.error)
		}
	}

	useEffect(() => {
		initStorageInfo();
		getUploadRecord();

		window['isVaultPage'] = true;

		return () => {
			o_uploadListXunRef.current = null;
			leaveFlagRef.current = false;
			window['isVaultPage'] = false;
			window['vaultLoadingListCount'] = uploadingFileListRef.current;
			window['vaultPendingListCount'] = pendingUploadFileListRef.current
		}
	}, [])


	useEffect(() => {
		getSessionData();
		clearTimeout(ajaxTimeOut.current)
		ajaxTimeOut.current = setTimeout(() => {
			getDiskStorageSize();
		}, 250);
		window['inVaultPageFlagRef'] = inVaultPageFlagRef;
		inVaultPageFlagRef.current = true;
		return () => {
			clearTimeout(ajaxTimeOut.current)
			inVaultPageFlagRef.current = false;
		}
	}, [])

	const getSessionData = () => {
		try {
			let vault_size_info = sessionStorage.getItem('vault-size-info');
			let _2json = JSON.parse(vault_size_info);
			if (isJSON(_2json)) {
				let _datetime = dayjs().format('YYYYMMDDHH');
				if (_datetime == _2json._datetime) {
					setDiskInfo(() => _2json);
				}
			}
		} catch (e) {
			console.log(e)
		}
	}

	// useEffect(() => {
	// 	// 1、服务到期， 提示用户订阅
	// 	// 2、空间不足提示用户升级套餐或者清理文件
	// 	// 3、取消订阅， 用户的
	// 	getNewNotice();
	// 	// console.log(new Date().getTime())
	// }, [])

	// // getNewNotification
	// // 获取最新消息
	// const getNewNotice = () => {

	// 	getMSGExpire().then((res:any) => {
	//         console.log(res, '367')
	// 		if(res?.result === 'ok'){
	// 			let _data = res?.data;

	// 			if(isJSON(_data)){
	// 				let _noticeInfo = _data?.content;
	// 				console.log( _noticeInfo, ' _noticeInfo')
	// 				setNoticeInfo(() => _noticeInfo);
	// 			}
	// 		}

	// 	}).catch(e => {
	// 		console.log(e)
	// 	})
	// }


	const getDiskStorageSize = () => {
		getStorageInformation().then((res: any) => {
			let { data, result } = res;
			if (result === 'ok' && data) {
				if (Object.prototype.toString.call(storeInfo) === '[object Function]') {
					storeInfo(() => JSON.parse(JSON.stringify(data)))
					sessionStorage.setItem('vault-sysdir-info', JSON.stringify(data))
				}

				if (isJSON(data)) {
					let _totalUseSize = Number(data.used + data.upload_used);

					if (!Number.isNaN(_totalUseSize)) {
						_totalUseSize = _totalUseSize * 100;
					}

					data.percent = data.size === 0 ? 0 : _totalUseSize / data.size;
					data._total = sizeFormat(data.used + data.upload_used);
					data._size = `${(sizeFormat(data.size))}`;
					data._usable = `${(sizeFormat(data.usable))}`;
					data._used = `${(sizeFormat(data.used))}`;
					data._datetime = dayjs().format('YYYYMMDDHH');

					setDiskInfo(() => data);
					setVaultDetailInfoZU(data);

					sessionStorage.setItem('vault-size-info', JSON.stringify(data))

					//	用户的目录信息
					let user_cloud_disk_info = {
						cloud_gallery: data.cloud_gallery,
						cloud_gallery_id: data.cloud_gallery_id,
						root_id: data.root_id
					}

					if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
						localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
					}

					let { backup_id, cloud_gallery_id, root_id, cloud_gallery } = data;

					if (root_id && cloud_gallery_id && cloud_gallery) {
						if (isJSON(cloud_gallery)) {
							let { Flashbacks, MemoryVideos, Screenshots, VideoClips } = cloud_gallery;
							let arr = [root_id, cloud_gallery_id, backup_id, Flashbacks, MemoryVideos, Screenshots, VideoClips];
							arr = arr.filter((v) => {
								return v !== 0 && v !== null;
							})
							// 存放系统目录
							setVaultSystemFolderInfoZU(arr);
						}
					}

				}
			} else if (Number(result) === 401) {
				navigate('/login')
			}
		}).catch(e => {
			console.log(e);
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
			navigate('/login')
		}).finally(() => {
			setIsUpdateVaultInfoZU(false);
		})
	}

	const backFunc = () => {
		let searchParams = new URLSearchParams(location.search);
		let _hisdeep: any = searchParams.get('hisdeep');

		if (typeof _hisdeep === 'string') {
			_hisdeep = - Number(_hisdeep)
		} else {
			_hisdeep = -1;
		}
		navigate(_hisdeep);
	}

	const toPage = (_path) => {
		jumpPage(navigate, _path);
	}

	// const goSettingsPage = (e) => {
	// 	e.stopPropagation();
	// 	toPage('/settings')
	// }

	const toPlanPage = (e) => {
		e.stopPropagation();
		// 判断账户新建
	// 	if (varInfo.root_id === 0 && varInfo.backup_id === 0 && varInfo.cloud_gallery == null) {
	// 		setOpen(true)
	// 		return false
	//    }
		if (varInfo.size === 0 && varInfo.used === 0) {
			setOpen(true)
			return false
	   }

	   if ((!_has_unuse_size) || Number(diskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}

		if (onIsStorage) {
			onIsStorage(true)
		} else {
			setIsStorage(() => true)
		}


		// toPage('/storage');
	}
	
	let _store_percent = diskInfo.percent < 3 ? 3 : diskInfo.percent;
	_store_percent = _store_percent > 100 ? 100 : _store_percent;
	
	if ((varInfo.size === 0 && varInfo.used === 0))  {
		_store_percent = Number(diskInfo.size) === 0 ? 0 : _store_percent;
	} else {
		_store_percent = Number(diskInfo.size) === 0 ? 100 : _store_percent;
	}
	let _progress_bar_fill_color = _store_percent < 80 ? 'var(--adm-color-primary)' : (_store_percent < 90 ? 'var(--adm-color-warning)' : 'var(--adm-color-danger)');


	let _has_unuse_size: any = Number(diskInfo?.usable);
	_has_unuse_size = isNaN(_has_unuse_size) ? -1 : _has_unuse_size;
	_has_unuse_size = _has_unuse_size > 0;

	const handleCreateFolder = () => {
		
		// console.log(_has_unuse_size, diskInfo.size, '505')
		// 判断账户新建
		if (varInfo.size === 0 && varInfo.used === 0) {
             setOpen(true)
			 return false
		}
		// 判断网盘到期
		if ((!_has_unuse_size) || Number(diskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}


		if (vaultSystemFolderInfoZU.includes(Number(dirId)) || isForbid) {
			Modal.warning({
				title: 'Tips',
				// content: 'This is a system folder for videos and screenshots from Smartz Eaze apps, It is not allowed to create folder and upload files manually. Please switch to All Files list',
				content: 'It is only allowed to Upload or Create Folder under “All Files” tab, Please switch to “All Files” tab.',
				onOk() {

				}
			});
			return;
		}

		if ((varInfo.root_id && varInfo.size === 0) || (varInfo.root_id === 0 && varInfo.size === 0) || isForbid) {
			// message.warning("This is a system folder for videos and screenshots from Smartz Eaze apps, It is not allowed to create folder and upload files manually. Please switch to All Files list")
			message.warning('It is only allowed to Upload or Create Folder under “All Files” tab, Please switch to “All Files” tab.')
			return false
		} else {
			if (onVisible) {
				onVisible(false)
			}
			if (onIsFolder) {
				onIsFolder(true)
			}
			if (onItemNum) {
				onItemNum(() => itemNum)
			}

			setCreateDirZU(true);
		}
	}


	useEffect(() => {
		clearTimeout(timeOutRef.current)

		timeOutRef.current = setTimeout(() => {
			addFileToUploadingListFun();
		}, 500)

		return () => {
			clearTimeout(timeOutRef.current)
		}
	}, [dropFileList])


	const uploadFileFun = async (file) => {

		let formData: any = new FormData();

		let fileMd5 = await getFileMD5(file);
		// getFileReader(file).then(fileMd5 => {
		if (file.type.indexOf("video") >= 0) {
			formData.append('video', file as any);
		} else if (file.type.indexOf("image") >= 0) {
			formData.append('image', file as any);
		} else {
			formData.append('file', file as any);
			file.file_type = 2;
		}

		formData.append('mediaType', file._type)
		formData.append('dirId', file._dirId ? file._dirId : 0)
		formData.append('MD5', fileMd5)
		formData.append('size', file.size)
		formData.append('name', file.name)
		formData.append('token', getLGToken())
		formData.append('createTime', Math.floor(file.lastModified / 1000))
		formData.append('lastModifyTime', Math.floor(file.lastModified / 1000))

		if (file.file_path) {
			formData.append('path', file.file_path)
		}

		const func = (e) => {
			let { bytes, total, rate, upload, loaded, progress } = e;

			setUploadList((prev) => {
				let _uploadList = _.cloneDeep(prev);
				_uploadList = _uploadList.map((item: any) => {

					if (item.uid === file.uid) {
						item.progress = progress * 100;
					}

					return item;
				})
				return _uploadList
			});
		}

		getUploadFile(formData, func).then((res: any) => {

			if (res.result === "ok") {
				uploadSuccessToDo(file, res.data);
			} else {

				if (res.result == 101500004) {
					file.error_message = res.error;//'The file already exist';
				}

				uploadFailureTodo(file)
			}

		}).catch(e => {
			console.log(e);
		})

	}

	// 上传成功调用
	const uploadSuccessToDo = (file, data) => {
		file.status = 3;
		file.uploading = -1;
		file.progress = 100;

		// 清理掉传完的文件
		uploadingFileListRef.current = uploadingFileListRef.current.filter((item: any) => {
			return item.uid !== file.uid;
		})


		if (!uploadedFileArrayRef.current.includes(file.uid)) {
			// 记录已上传完的文件
			uploadedFileArrayRef.current.push(file.uid);
			uploadedFileListRef.current.push(file);

			// 移除上传完的文件
			uploadFileArrayRef.current = uploadFileArrayRef.current.filter((item: any) => {
				return item.uid !== file.uid;
			})

			addFileToUploadingListFun();

			if (data.id) {

				let obj = { ...data }
				obj.id = Number(obj.id)

				obj.ext = file.ext;

				if (obj?.mediaType.indexOf("video") >= 0) {
					obj.type = 1
				} else if (obj?.mediaType.indexOf("image") >= 0) {
					obj.type = 3
				} else {
					obj.type = 2
				}


				let _lb = obj.create_at === obj.update_at ? 'Created at' : 'Modified at';
				let _dt = dayjs(obj.update_at).format("MM/DD/YYYY hh:mm A");
				obj.content = `${_lb} ${_dt}`;
				obj.createdTime =  dayjs(obj.create_at).format('MM/DD/YYYY hh:mm A')
				obj.modifiedTime = dayjs(obj.update_at).format('MM/DD/YYYY hh:mm A')

				obj.icon = `${imageUrl}/disk/download_file?url=${obj.url}&name=${obj.name}&token=${getLGToken()}`
				if (file?.file_type === 2) {
					obj.icon = fileTypeIcon[file.ext];
				} else {
					if (obj?.cover) {
						obj.icon = `${imageUrl}/disk/download_file?url=${obj.cover}&name=${obj.name}&token=${getLGToken()}`
					} else {
						if (obj.type === 1) {
							obj.icon = fileTypeIcon['mp4'];
						} else if (obj.type === 3) {
							obj.icon = fileTypeIcon['png'];
						}
					}
				}

				updateFileListFun(obj);

				//是否为文件夹
				if (file.file_path) {
					let _arr = (file.file_path).split('/');
					let _data = { update: true, data: { dirId: data.dirId, name: _arr[0] } };
					setUpdateFolderListZU(_data);
				}

				if (onFileList) {
					onFileList((prev: any) => {
						return [obj].concat(prev)
					})
				}

			}
		}

		//	更新空间大小
		clearTimeout(ajaxTimeOut.current)
		ajaxTimeOut.current = setTimeout(() => {
			// getDiskStorageSize();
			setIsUpdateVaultInfoZU(true)
		}, 350);
	}

	// 上传失败调用
	const uploadFailureTodo = (file) => {
		file.status = -1;
		file.uploading = -1;
		file.progress = 0;

		uploadingFileListRef.current = uploadingFileListRef.current.filter((item: any) => {
			return item.uid !== file.uid;
		})

		if (!uploadedFileArrayRef.current.includes(file.uid)) {
			uploadedFileArrayRef.current.push(file.uid);
			uploadedFileListRef.current.push(file);

			uploadFileArrayRef.current = uploadFileArrayRef.current.filter((item: any) => {
				return item.uid !== file.uid;
			})

			addFileToUploadingListFun();
		}

		//	更新空间大小
		clearTimeout(ajaxTimeOut.current)
		ajaxTimeOut.current = setTimeout(() => {
			// getDiskStorageSize();
			setIsUpdateVaultInfoZU(true)
		}, 350);
	}

	// 更新文件列表
	const updateFileListFun = (obj) => {

		let _location = window['location']

		const params = new URLSearchParams(_location.search)
		let dirId = params.get('id') ? params.get('id') : 0;

		let user_cloud_disk_info: any = localStorage.getItem('user_cloud_disk_info');
		try {
			user_cloud_disk_info = JSON.parse(user_cloud_disk_info);
		} catch (e) {
			console.log(e);
		}

		if ((Number(obj.dirId) === Number(dirId) || Number(user_cloud_disk_info.root_id) === Number(obj.dirId)) && (location.pathname === '/vault')) {
			clearTimeout(updateFileListTimeRef.current);
			let _fileListZU = [obj].concat(eFileListRef.current);
			eFileListRef.current = _fileListZU;

			updateFileListTimeRef.current = setTimeout(() => {

				setFileListZU(_fileListZU);
			}, 500)
		}
	}

	// 往上传列表中添加文件
	const addFileToUploadingListFun = () => {

		let _pendingUploadFileListRef = pendingUploadFileListRef.current;
		let _uploadingFileListRef = uploadingFileListRef.current;

		let _uploading_file_num = 5; // 同时处理的文件数量

		window['vaultLoadingListCount'] = uploadingFileListRef.current;
		window['vaultPendingListCount'] = pendingUploadFileListRef.current;

		const getNumFile = () => {
			let c = _uploadingFileListRef.length; // 正在上传数量
			let m = _pendingUploadFileListRef.length; // 待上传数量
			let n = _uploading_file_num; // 标准上传数量


			// 上传列表为空
			if (c === 0) {

			} else { // 上传列表不为空
				n = n - c; // 空出来的数量
			}

			n = Math.min(m, n);

			for (let i = 0; i < n; i++) {
				// 从待传列表中取文件
				let item = _pendingUploadFileListRef.shift();
				if (item) {
					fileUploadResolver(item);



				}
			}

			// 更新待上传列表
			pendingUploadFileListRef.current = _pendingUploadFileListRef;
		}

		if (_pendingUploadFileListRef.length) {
			getNumFile();
			// if(_uploadingFileListRef.length === 0){
			// 	// 从待传列表中取文件
			// 	let item = _pendingUploadFileListRef.shift();
			// 	// 更新待传列表
			// 	pendingUploadFileListRef.current = _pendingUploadFileListRef;
			//
			// 	if(item){
			// 		fileUploadResolver(item);
			// 	}
			//
			// 	if(_pendingUploadFileListRef.length){
			// 		item = _pendingUploadFileListRef.shift();
			// 		// 更新待传列表
			// 		pendingUploadFileListRef.current = _pendingUploadFileListRef;
			//
			// 		if(item){
			// 			fileUploadResolver(item);
			// 		}
			//
			//
			// 	}else{
			// 		let item = _pendingUploadFileListRef.shift();
			// 		// 更新待传列表
			// 		pendingUploadFileListRef.current = _pendingUploadFileListRef;
			//
			// 		if(item){
			// 			fileUploadResolver(item);
			// 		}
			// 	}
			// }else if(_uploadingFileListRef.length === 1){
			// 	let item = _pendingUploadFileListRef.shift();
			// 	// 更新待传列表
			// 	pendingUploadFileListRef.current = _pendingUploadFileListRef;
			//
			// 	if(item){
			// 		fileUploadResolver(item);
			// 	}
			// }
		}

		// uploadingFileIdsArrayRef
		for (let item of _uploadingFileListRef) {
			uploadingFileIdsArrayRef.current.push(item.uid);
		}

		// 更新主列表 uploadedFileArrayRef.current
		setUploadList((pre) => {
			let _uploadList = _.cloneDeep(pre);

			_uploadList.map((item: any) => {
				if (uploadingFileIdsArrayRef.current.includes(item.uid)) {
					for (let o of uploadingFileListRef.current) {
						if (o.uid === item.uid) {

							if (o.progress === 0 && o.status === 2) {
								o.info_message = 'Analyzing...';
							}
							item = _.cloneDeep(o);
						}
					}

				} else if (uploadedFileArrayRef.current.includes(item.uid)) {

					for (let o of uploadedFileListRef.current) {
						if (o.uid === item.uid) {
							item = _.cloneDeep(o);
						}
					}
				}
				return item;
			})

			return _uploadList
		})
	}



	// 文件上传分辨器
	const fileUploadResolver = (item) => {
		item.status = 2;
		item.uploading = 0;

		item._type = item.type;
		if (item.type === '' && item.ext !== '') {
			item._type = fileMIMEMap.get(item.ext);
		}

		if (item.size >= 50 * 1024 * 1024) {
			item.progress = item.progress ? item.progress : 0;
			item.is_large_file = 1;

			// 添加到上传列表
			uploadingFileListRef.current.push(item);

			uploadLargeFileFun(item);
		} else {
			item.progress = item.progress ? item.progress : 1;

			// 添加到上传列表
			uploadingFileListRef.current.push(item);

			uploadFileFun(item);
		}
	}

	// 大文件上传处理
	const uploadLargeFileFun = async (file) => {
		//  获取文件 MD5
		// let _md5 = await getFileMD5(file);
		let _md5 = await calculateMD5(file);

		let params = { 'token': getLGToken(), dirId: file._dirId };
		params["mediaType"] = file._type;
		params["MD5"] = _md5;
		params["size"] = file.size;
		params["name"] = file.name;
		params["createTime"] = Math.floor(file.lastModified / 1000);
		params["lastModifyTime"] = params["createTime"];

		if (file.file_path) {
			params['path'] = file.file_path;
		}

		//	1  咨询后台， 获取该文件上传方式
		const _lfUploadInfo: any = await getLFUploadInfo(params);
		let _fileId = _lfUploadInfo?.fileId;
		if (_fileId === undefined) {
			// 文件重复
			if (_lfUploadInfo?.code == 101500004 || _lfUploadInfo?.code == 101500000) {

				file.error_message = _lfUploadInfo.error //'The file already exist';

				uploadFailureTodo(file);
			}

			return;
		}

		//	2  先查这个文件的上传记录 分片记录
		let _req_record = {
			token: params['token'],
			fileId: _fileId,
		}

		file._fileId = _fileId;

		setTimeout(() => {
			let vault_uploading_list: any = sessionStorage.getItem('vault_uploading_list');
			vault_uploading_list = vault_uploading_list === null ? '[]' : vault_uploading_list;
			try {
				if (vault_uploading_list) {
					vault_uploading_list = JSON.parse(vault_uploading_list);
					if (Array.isArray(vault_uploading_list)) {
						vault_uploading_list.push(file);
						sessionStorage.setItem('vault_uploading_list', JSON.stringify(vault_uploading_list));
					}
				} else {

				}
			} catch (e) {
				console.log(e)
			}

		}, 10)

		let _record: any = await checkLFUploadRecord(_req_record);
		if (_record?.result === "ok") {
			let chunkIndex = _record?.data["uploadCount"];
			largeFileSlicerFun(_lfUploadInfo, file, chunkIndex, _lfUploadInfo?.suggestChunkSize);
		} else if (_record?.result === "101500003") {
			largeFileSlicerFun(_lfUploadInfo, file, 0, _lfUploadInfo?.suggestChunkSize);
		} else {
			// 已上传完成
			message.error(`Query file error:${_record?.error}!`);
		}

	}

	const getLFUploadInfo = async (req) => {
		return await new Promise(resolve => {
			tryUploadByChunk(req).then((res: any) => {
				if (res.result === "ok") {
					resolve(res.data);
				} else {
					if (Number(res.result) == 101500004) {
						resolve({ code: res.result, error: res?.error });
					} else if (Number(res.result) == 101500000) {
						resolve({ code: res.result, error: res?.error });
					} else {
						resolve({});
					}
				}
			}).catch(e => {
				console.log(e);
				resolve({});
			})
		})
	}

	const checkLFUploadRecord = async (req) => {
		return await new Promise(resolve => {
			queryFileChunk(req).then((res: any) => {
				resolve(res);
			}).catch(e => {
				console.log(e);
				resolve({});
			})
		})
	}

	// 大文件分片
	// data:
	// file: 文件
	// chunkIndex： 当前片段索引
	// chunkSize： 片段大小
	// chunkCount：片段总数
	// fileId：文件id, 发起分片请求的文件id
	const largeFileSlicerFun = async (data, file, chunkIndex, chunkSize) => {
		let chunkCount = data['chunkCount'];

		if (chunkIndex < chunkCount) {

			let start = chunkIndex * chunkSize;
			let end = Math.min(file.size, (chunkIndex + 1) * chunkSize);
			const fileData = file.slice(
				start,
				end,
				file.type
			);

			let _md5 = await getFileMD5(fileData);

			let chunkData = {
				fileData: fileData,
				md5: _md5,
				chunkSize: end - start,
				chunkIndex: chunkIndex,
				offset: start
			}

			// 处理 被取消的上传列表
			if (!cancelUploadFileIdsArrayRef.current.includes(file.uid)) {
				await uploadFileShardsFun(data, file, chunkIndex, chunkSize, chunkData);
			} else {

				// file.status = 4;

				uploadingFileListRef.current = uploadingFileListRef.current.filter((item: any) => {
					return item.uid !== file.uid;
				})

				addFileToUploadingListFun();
			}
		} else {
			// console.log(`upload ${file.name} success`)

		}
	}

	// 上传文件片段
	const uploadFileShardsFun = async (data, file, chunkIndex, chunkSize, chunkData) => {
		// console.log(data, file, chunkIndex, chunkSize, chunkData);
		let formData: any = new FormData();

		if (file.type.indexOf("video") >= 0) {

		} else if (file.type.indexOf("image") >= 0) {

		} else {
			file.file_type = 2;
		}

		formData.append('token', getLGToken())
		formData.append("fileId", data["fileId"]);
		formData.append("chunkIndex", chunkData["chunkIndex"]);
		formData.append("chunkOffset", chunkData["offset"]);
		formData.append("chunkSize", chunkData["chunkSize"]);
		formData.append("chunkMD5", chunkData["md5"]);
		formData.append("chunkFile", chunkData["fileData"]);

		const func = (e) => {
			// console.log(e, new Date().getTime())
			let { bytes, total, rate, upload, loaded, progress } = e;

			setUploadList((prev) => {
				let _uploadList = _.cloneDeep(prev);
				_uploadList = _uploadList.map((item: any) => {

					if (item.uid === file.uid) {

						if (item.loaded) {
							item.loaded = item.loaded + bytes
						} else {
							item.loaded = bytes
						}

						let _progress = (item.loaded / item.size) * 100;
						_progress = _progress > 100 ? 100 : _progress;
						item.progress = _progress;

						if (item.progress !== 0 && item.status === 2 && item.info_message) {
							item.info_message = '';
						}
					}

					return item;
				})
				return _uploadList
			});
		}

		const res: any = await uploadFileShardsFunA(formData, func);
		// console.log(leaveFlagRef.current, 'leaveFlagRef.current')
		if (leaveFlagRef.current) {
			if (res?.result === 'ok') {
				// await LFProgressProcess(file,chunkIndex + 1, data['chunkCount']);

				await largeFileSlicerFun(data, file, chunkIndex + 1, chunkSize);

				if (chunkIndex + 1 === data['chunkCount']) {
					uploadSuccessToDo(file, res?.data)
				}

			} else {
				uploadFailureTodo(file);
			}
		}


	}

	const uploadFileShardsFunA = async (req, onUploadProgress) => {
		return await new Promise(resolve => {

			// setTimeout(() => {
			// 	resolve({});
			// }, 1000)

			uploadFileChunk(req, onUploadProgress).then((res: any) => {
				// if(res.result === "ok"){
				// 	resolve(res?.data);
				// }else{
				// 	resolve(false);
				// }
				resolve(res);
			}).catch(e => {
				console.log(e);
				resolve(false);
			})
		})
	}

	const getFileMD5 = async (file) => {

		return await new Promise(resolve => {
			getFileReader(file).then(fileMd5 => {
				resolve(fileMd5);
			}).catch((e) => {
				console.log(e);
				resolve('');
			})
		})
	}

	// 大文件进度条处理
	const LFProgressProcess = async (file, currentIndex, count) => {

		let flg = true;

		setUploadList((prev) => {
			let _uploadList = _.cloneDeep(prev);

			flg = false;
			_uploadList = _uploadList.map((item: any) => {

				if (item.uid === file.uid) {
					item.progress = (currentIndex / count) * 100;

					if (currentIndex === count) {
						item.status = 3;
						item.uploading = -1;
						item.progress = 100;
					}

					if (item.progress !== 0 && item.status === 2) {
						item.info_message = '';
					}
				}

				return item;
			})
			return _uploadList
		});

		if (flg) {

			if (o_uploadListFunRef.current) {

				let _uploadList = _.cloneDeep(uploadList);
				// console.log(_uploadList, 9941);
				flg = false;
				_uploadList = _uploadList.map((item: any) => {


					if (item.uid === file.uid) {
						item.progress = (currentIndex / count) * 100;

						if (currentIndex === count) {
							item.status = 3;
							item.uploading = -1;
							item.progress = 100;
						}
					}

					return item;
				})


				o_uploadListFunRef.current(_uploadList);
				// o_uploadListFunRef.current((pre) => {
				// 	console.log(pre, 'dd')
				// 	return _uploadList;
				// })
			}


		}
	}

	const countFileSizeFun = (fileArr) => {
		let size = 0;
		for (let aItem of fileArr) {
			size = size + aItem.size;
		}
		return size;
	}

	const imgprops = {
		multiple: true,
		directory: isFolderUpload,
		onChange: async (info: any) => {

			const aFileList = info.fileList;

			clearTimeout(uploadFileBeforeCountSizeTimeOutRef.current);

			uploadFileBeforeCountSizeTimeOutRef.current = setTimeout(() => {
				let all_size = countFileSizeFun(aFileList);

				let disk_usable_size = vaultDetailInfoZU?.usable;

				// console.log(uploadFileBeforeStoreFileItemsRef.current)

				// 空间足够
				if (disk_usable_size > all_size) {
					let _uploadFileBeforeStoreFileItems = uploadFileBeforeStoreFileItemsRef.current;

					if (_uploadFileBeforeStoreFileItems.length) {
						setDropFileList((prevList) => [..._uploadFileBeforeStoreFileItems, ...prevList]);
						pendingUploadFileListRef.current = [..._uploadFileBeforeStoreFileItems, ...pendingUploadFileListRef.current];

						setUploadList((prevList) => [..._uploadFileBeforeStoreFileItems, ...prevList]);

						setIsFileUploadInformation(true);

						setTimeout(() => {
							uploadFileBeforeStoreFileItemsRef.current = [];
						}, 200)
					}
				} else {
					setNoticeNotEnoughSpaceUpgrade(() => true);
					// alert('Insufficient remaining space on the cloud storage.');
					uploadFileBeforeStoreFileItemsRef.current = [];
				}

			}, 350)

		},
		beforeUpload: async (file: any) => {

			if (file.size < 200 * 1024 * 1024 * 1024) {
				dirId = params.get('id') ? params.get('id') : 0;

				let _fileObject: any = file;
				_fileObject.status = 1;
				_fileObject.tips = 'waiting';
				_fileObject.progress = 0;

				file._dirId = dirId;

				pendingUploadFileArrayRef.current.push(file.uid);

				if (file.name && (file.name).lastIndexOf('.') > -1) {
					file.ext = ((file.name).substr((file.name).lastIndexOf('.') + 1)).toLowerCase();
				}

				if (file.webkitRelativePath) {
					file.file_path = (file.webkitRelativePath).replace(/\/[^\/]*$/, '');
				}

				uploadFileBeforeStoreFileItemsRef.current.push(file);

				// onceUploadFileSizeRef.current = onceUploadFileSizeRef.current + file.size;
				//
				// // setPendingUploadFileList((prevList) => [file, ...prevList])
				// setDropFileList((prevList) => [file, ...prevList]);
				// pendingUploadFileListRef.current.push(file);
				//
				// setUploadList((prevList) => [file, ...prevList]);
				//
				// setIsFileUploadInformation(true)
			} else {
				message.warning('It is not supported to upload files larger than 2GB');
			}

			return false
		},
		fileList,
	}

	async function calculateMD5(file) {
		let num = 50;

		const chunkSize = 1024 * 1024 * num; // 1MB chunks
		const chunks = Math.ceil(file.size / chunkSize);
		const spark = new SparkMD5.ArrayBuffer();

		for (let i = 0; i < chunks; i++) {
			const start = i * chunkSize;
			const end = Math.min(file.size, start + chunkSize);
			const chunk = file.slice(start, end);

			const arrayBuffer = await chunk.arrayBuffer();
			spark.append(arrayBuffer);
		}

		const result = spark.end();

		return result;
	}

	function getFileReader(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {

				// 当文件读取完成后，计算MD5
				const md5Value = SparkMD5.ArrayBuffer.hash(e.target.result);
				// 将MD5值作为文件的一个属性存储，或者其他用途
				file.md5 = md5Value;
				// 继续上传流程
				resolve(file.md5);
			};
			reader.onerror = error => reject(error);
			// 读取文件内容，计算MD5
			reader.readAsArrayBuffer(file); // 只读取文件的一部分，避免大文件全部加载进内存
		});
	}

	const cancelUploadFileFun = (o) => {
		let uid = o.uid;

		// 记录被取消上传的文件
		cancelUploadFileIdsArrayRef.current.push(uid);

		// 清理待上传列表的文件
		pendingUploadFileListRef.current = pendingUploadFileListRef.current.filter((item: any) => {
			return item.uid !== uid;
		})

		window['vaultLoadingListCount'] = uploadingFileListRef.current;
		window['vaultPendingListCount'] = pendingUploadFileListRef.current

		// 更新文件列表列表
		setUploadList((prev) => {
			let _uploadList = _.cloneDeep(prev);
			_uploadList = _uploadList.filter((item: any) => {
				return item.uid !== uid;
			})

			return _uploadList
		});


		if (o.is_large_file === 1) {
			if (o.o_status === 1 || o.status === 5) {
				deleUploadRecordFun(o);
			} else {
				removeLFUploadRecordFun(o)
			}

		}
	}

	const stopUploadFileFun = (uid) => {

		// console.log(uid, 'stopUploadFileFun')

		setUploadList((prev) => {
			let _uploadList = _.cloneDeep(prev);
			_uploadList = _uploadList.map((item: any) => {
				if (item.uid === uid) {
					// item.status = 4;
					item.stop_status = 1;
				}
				return item;
			})

			return _uploadList
		});
	}

	// 重新上传
	const reUploadFileFun = (uid) => {

		// 清除 暂停数组的文件
		stopuploadFileIdsArrayRef.current = stopuploadFileIdsArrayRef.current.filter((item) => {
			return item !== uid
		})

		let _pendingUploadFileListRef = pendingUploadFileListRef.current;
		let _uploadingFileListRef = uploadingFileListRef.current;



		// 修改列表状态
		setUploadList((prev) => {
			let _uploadList = _.cloneDeep(prev);
			_uploadList = _uploadList.map((item: any) => {
				if (item.uid === uid) {
					item.status = 1;
					item.stop_status = 0;

					// 把文件放到待传列表中
					pendingUploadFileListRef.current.push(item);
				}
				return item;
			})
			return _uploadList
		});

		if (_uploadingFileListRef.length < 2) {
			addFileToUploadingListFun()
		}
	}

	// 被删除的文件， 调接口删除 上传记录
	const removeLFUploadRecordFun = (o) => {

		let _fileId = o._fileId;
		if (_fileId === undefined) return;

		let req = {
			dirId: o._dirId,
			fileId: _fileId
		}
		removeUnUploadedFileChunk(req).then((res: any) => {

			if (res.result === 'ok') {
				message.success(res.result);
			} else {
				if (res.error) {
					message.error(res.error);
				}
			}
		}).catch(e => {
			console.log(e);
			message.error('error');
		})
	}

	// 删除记录列表获取的 deleteUploadRecord
	const deleUploadRecordFun = (o) => {
		let _id = o.id;
		deleteUploadRecord(_id).then((res: any) => {
			if (res.result === 'ok') {
				message.success(res.result);
			} else {
				if (res.error) {
					message.error(res.error);
				}
			}
		}).catch(e => {
			console.log(e);
			message.error('error');
		})
	}

	// fl: 1 取消  2 暂停  3 重传
	const optionUploadList = (o, fl) => {
		if (fl === 1) {
			cancelUploadFileFun(o);
		} else if (fl === 2) {
			// if(o?.stop_status !== 1){
			// 	stopUploadFileFun(o.uid);
			// 	stopuploadFileIdsArrayRef.current.push(o.uid);
			// }
		} else if (fl === 3) {
			// reUploadFileFun(o.uid);
		}
	}

	const uploadFun = async ({ key }) => {
		if (!_has_unuse_size || Number(diskInfo.size) === 0) {
			// setIsTipsModal(true)
			return false
		}
		const { nativeElement } = uploadDomRef.current

		// 判断是否为系统目录
		if (vaultSystemFolderInfoZU.includes(Number(dirId)) || isForbid) {
			Modal.warning({
				title: 'Tips',
				// content: 'This is a system folder for videos and screenshots from Smartz Eaze apps, It is not allowed to create folder and upload files manually. Please switch to All Files list',
				content: 'It is only allowed to Upload or Create Folder under “All Files” tab, Please switch to “All Files” tab.',
				onOk() {

				}
			});
			return;
		}

		onceUploadFileSizeRef.current = 0;
		if (key === 'upload-file') {
			await setIsFolderUpload(() => false);
		} else if (key === 'upload-folder') {
			await setIsFolderUpload(() => true);
		}

		if (nativeElement) {
			let file_input_dom = nativeElement.querySelector('input')

			if (file_input_dom) {
				setTimeout(() => {
					file_input_dom.click();
				}, 150)
			}
		}
	}

	const handleFile = () => {
		// 判断账户新建
		if (varInfo.size === 0 && varInfo.used === 0) {
			setOpen(true)
			return false
	   }
	   // 判断网盘到期
		if (!_has_unuse_size || Number(diskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}
	}

	const toClearUpFun = () => {
		navigate(`/vault?isCleanUpVault=true`)
		setNoticeNotEnoughSpaceUpgrade(false)
	}

	const toUpgrade = () => {
		let data = {
			size_type: 1,
			order_pf: 'web_pay',
			pay_type: 2,
			order_type: 9,
			is_vault: 1,
		}

		let _params = `?order_type=${data.order_type}&size_type=${data.size_type}&order_pf=${data.order_pf}&pay_type=${data.pay_type}`
		navigateX(`/cloud-plan-form-jump/subscribe${_params}`, { state: { order: data } })
	}

	const uploadMenuItems = [
		{
			key: 'upload-file',
			label: (
				<Space onClick={handleFile}>
					<img src="/res/icons/upload/icon_File.svg" alt="" />
					File
				</Space>
			),
		},
		{
			key: 'upload-folder',
			label: (
				<Space onClick={handleFile}>
					<img src="/res/icons/upload/icon_Folder.svg" alt="" />
					Folder
				</Space>
			),
		},
	]

	const handleUploadingList = () => {
		// 判断账户新建
		if (varInfo.size === 0 && varInfo.used === 0) {
			setOpen(true)
			return false
	   }
	    // 判断网盘到期
		if (!_has_unuse_size || Number(diskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}
		setIsFileUploadInformation(!isFileUploadInformation)
	}

    const toSubscribeNow = () => {
		navigate('/cloud-plan')
	} 

	return <div className="vault-con">
		{/* <div className="vault-con-title">
			Vault
		</div> */}
		<div className={'ui-home-head-nav-container'}>
			<div className='vault-con-user-l' style={{ alignItems: 'center' }}>
				<Dropdown
					menu={{ items: uploadMenuItems, onClick: uploadFun }}
					placement="bottomLeft"
					trigger={['click']}
				>
					<div className={'a-upload-btn'} style={{ padding: '4px 15px', boxSizing: 'border-box', justifyContent: 'center' }}>
						{/*<Dragger {...imgprops} disabled={(varInfo.root_id && varInfo.size === 0) || (varInfo.root_id === 0 && varInfo.size === 0) || isForbid}>*/}
						{/*	<Icon component={UploadSvg} style={{ fontSize: "16px", marginRight: "14px" }} />*/}
						{/*	Upload*/}
						{/*</Dragger>*/}

						<div style={{ display: "none" }}>
							<Dragger
								{...imgprops}
								ref={uploadDomRef}
								disabled={(varInfo.root_id && varInfo.size === 0) || (varInfo.root_id === 0 && varInfo.size === 0) || isForbid || (Number(diskInfo.size) > 0 && Number(diskInfo.usable) < 0)}
							/>
							{/*<Upload*/}
							{/*	{...imgprops}*/}
							{/*	ref={uploadDomRef}*/}
							{/*	disabled={(varInfo.root_id && varInfo.size === 0) || (varInfo.root_id === 0 && varInfo.size === 0) || isForbid}*/}
							{/*>*/}
							{/*</Upload>*/}
						</div>


						<>
							<Icon component={UploadSvg} style={{ fontSize: "18px", marginRight: "14px" }} />
							<br />
							Upload

						</>


					</div>
				</Dropdown>
				<Button onClick={handleCreateFolder} style={{ marginLeft: '16px' }}>
					<Icon component={CreateNewFolderSvg} style={{ fontSize: "20px" }} />
					Create Folder
				</Button>

				<Button style={{ marginLeft: '16px' }} onClick={handleUploadingList} >
					<img src={'/res/icons/upload/upload_list.svg'} width={19} alt="" />
					Uploading List
				</Button>
			</div>
			<div className={'--dis-size-box'} >
				<div className='vault-con-user-r'>
					<div onClick={toPlanPage}>
						<ProgressBar percent={_store_percent} style={{ "--fill-color": _progress_bar_fill_color }} /></div>
					<div className="vault-con-user-item">
					{(varInfo.root_id === 0 && varInfo.backup_id === 0 && varInfo.cloud_gallery == null) ?  <p> {diskInfo._size} Used</p> : <p>{diskInfo._total} of {diskInfo._size} Used</p> } 
						<p >{ diskInfo._size <= 0 ? <span className="management" onClick={toSubscribeNow}>Subscribe Now</span> : <span className="management" onClick={toPlanPage}>Management</span>}</p>
					</div>
					{
						(varInfo.root_id === 0 && varInfo.backup_id === 0 && varInfo.cloud_gallery == null) && <div className="no-subscription">No Subscription</div>
					}
					
				</div>
				
			</div>
			{/* <div className={'--setting-box'} onClick={goSettingsPage}>
				<img src={BaseResource('/res/icons/icon_settings.png')} width={24} alt=""/>
			</div> */}
		</div>
		<div className="vault-all">All Files</div>
		{isFileUploadInformation && <FileUploadInformation
			onOpen={setIsFileUploadInformation}
			list={uploadList}
			flleArr={fileList}
			optionFun={optionUploadList}
		/>}

		{/*拖拽上传*/}
		{/*<div>*/}
		{/*	<Dragger {...imgprops} />*/}
		{/*</div>*/}

		<StoragePage isStorage={isStorage} onIsStorage={setIsStorage} onIsCleanUpMeDrive={setIsCleanUpMeDrive} />
		<CleanUpMeDrive open={isCleanUpMeDrive} onOpen={setIsCleanUpMeDrive} isLargeFilesModal={isLargeFilesModal} onIsLargeFilesModal={setIsLargeFilesModal} onIsStorage={setIsStorage} />
		<LargeFilesModal isLargeFilesModal={isLargeFilesModal}  onIsLargeFilesModal={setIsLargeFilesModal} />
		<NewAccountModal open={open} onOpen={setOpen} position={document.querySelector('.contentContainer')} />
		<TipsModal visible={isTipsModal} onVisible={setIsTipsModal} diskInfo={diskInfo} />


		<Modal
			open={noticeNotEnoughSpaceUpgrade}
			onCancel={() => { setNoticeNotEnoughSpaceUpgrade(false) }}
			maskStyle={{ background: 'transparent', pointerEvents: 'none' }}
			bodyStyle={{ minHeight: '90px' }}
			className={'--notice-popup-box'}
			centered
			footer={null}
		>
			<div className={'--notice-popup-body'}>
				<div className={'--flex'}>
					<div className={'--icon-1'}>
						<img src={'/res/icons/icon_notice_a.svg'} alt="" />
					</div>
					<div className={'--box-1'}>
						<p className={'--notice-desc'}>
							You currently do not have enough storage space for uploading these new files to the Vault.
							To access more storage space, consider upgrading your Vault Plan or deleting some existing files.
						</p>
						<div className={'--box-2'}>
							<Button shape="round" style={{ maxWidth: '128px', marginRight: '15px', width: 'calc(50% - 10px)', background: '#EAEEF2' }} onClick={toClearUpFun}>Clear Up</Button>
							<Button color='primary' shape="round" style={{ maxWidth: '128px', width: 'calc(50% - 10px)' }} onClick={toUpgrade}>Upgrade</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
}
