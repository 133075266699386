// 1:Images;2:videos;3:audio;4:file;100:other
//0:audio;1:videos;2:file;3:Images
const fileType = {
	0: { label: 'audio', icon: '/res/icons/file/icon_voice.svg' },
	1: { label: 'videos', icon: '/res/icons/file/icon_video.svg' },
	2: { label: 'file', icon: '/res/icons/file/icon_unknown.svg' },
	3: { label: 'Images', icon: '/res/icons/file/icon_picture.svg' },
	100: { label: 'file', icon: '/res/icons/file-type/icon_zip.svg' },
}

export default fileType;
