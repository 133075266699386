import './index.less'
import {useNavigate, useParams} from "react-router-dom";
import {CaretDownOutlined, CaretUpOutlined, CloseOutlined, SettingFilled} from "@ant-design/icons";
import DeviceCenterApi from "../../../server/device";
import {useEffect, useRef, useState} from "react";
import VideoPlayer from "../../../components/videoPlayer";
import {getLGToken} from "../../../utils/common";
import VideoPlayerII from "../components/VideoPlayerII";
import VideoPreview from "../components/VideoPreview";

const deviceCenterApi = new DeviceCenterApi();

export default function DeviceCenterPlayer(){
	//physical_id

	const [liveURL, setLiveURL] = useState<string>('');
	const [playerSize, setPlayerSize] = useState<any>({width: 300, height: 168});
	const [deviceName, setDeviceName] = useState<string>('');
	const [currentDeviceInfo, setCurrentDeviceInfo] = useState<any>({});

	const [devlist, setDevList] = useState<any>([]);
	const [showNvr, setShowNvr] = useState<boolean>(false);
	const [nvrDevList, setNvrDevList] = useState<any>([]);
	const [currentNvrChannel, setCurrentNvrChannel] = useState<number>(0);

	const navigate = useNavigate();
	const {physical_id} = useParams();

	const playerBoxRef = useRef<any>(null)

	const toCenterPage = () => {
		navigate('/device-center',  {replace: true});
	}


	useEffect(() => {

	}, [])

	useEffect(() => {
		if(physical_id){
			getDeviceData(physical_id);
		}

		PlayerSizeFun();
	}, [physical_id])

	const getDeviceData = (physical_id) => {
		deviceCenterApi.getDeviceList().then(res => {

			if(Array.isArray(res)){
				if(res.length > 0){
					let item = res[0];
					setDevList(() => res)

					for(let o of res){
						if(physical_id === o.physical_id){
							item = o;
						}
					}

					let passive_device = [];
					if(Array.isArray(item?.passive_device)){
						passive_device = item?.passive_device;
					}
					setNvrDevList(() => passive_device);

					playCurrentDevVideo(item);
				}
			}
		});
	}

	const playCurrentDevVideo = (item) => {
		let aes_key = item.aes_key === undefined ? `` : `&aes_key=${item.aes_key}`
		let has_audio = item.support_audio === undefined ? `` : `&has_audio=${item.support_audio}`
		let aes_key_has_audio = aes_key !== '' && has_audio !== '' ? `${aes_key}${has_audio}` : '';
		let randomNumber = new Date().getTime()

		let token = getLGToken();
		let _host = 'flv.meshare.com';
		if(
			(window.location?.hostname).includes('localhost') ||
			(window.location?.hostname).includes('meshare.cn') ||
			(window.location?.hostname).includes('sz-user.smartzliving.ai')
		){
			// _host = 'flv.meshare.cn';
		}
		//wss://flv.meshare.com/live-ws  https://flv.meshare.com/live
		// let url = `https://${_host}/live?devid=${item.physical_id}&token=${token}&media_type=${2}&channel=${0}&rn=${randomNumber}${aes_key_has_audio}`;
		// url = `${url}&cid=0`;

		// setLiveURL(() => url);
		setDeviceName(() => item?.name)

		item.aes_key_has_audio = aes_key_has_audio;
		setCurrentDeviceInfo(() => item)
	}

	const PlayerSizeFun = () => {
		if (playerBoxRef.current !== null) {
			let w = playerBoxRef.current.clientWidth;

			let win_h = window.innerHeight - 64 - 100;

			let h = Math.round(w * 9 / 16);
			let _data = { width: w };

			h = h < win_h ? h : win_h;

			let _playerSize = {
				width: w,
				height: h
			}

			setPlayerSize(() => _playerSize);
		}
	}

	const showNvrFun = () => {
		if(nvrDevList.length === 0) return;
		setShowNvr(() => !showNvr)
	}

	const switchChannelFun = (o) => {
		setCurrentNvrChannel(() => o.channel);
		showNvrFun();
	}

	return <>
		<div className={'device-center-play-video-container'}>
			<div className={'top-box'}>
				<div className={'btn-close'} onClick={toCenterPage}><CloseOutlined style={{fontSize: '24px'}} /></div>
				<div className={'dev-name'}>
					<span onClick={showNvrFun}>
						{deviceName? deviceName : physical_id}

						{
							nvrDevList.length !== 0 && <i className={'dp-icon'}>
								{showNvr ? <CaretDownOutlined /> : <CaretUpOutlined /> }
              </i>
						}
					</span>
					{showNvr && <div className={'nvr-channel-box'}>
						<p className={'nvr-box-title'}>Switch Camera</p>
						<div className={'nvr-channel-list'}>
							{
								nvrDevList.map((item:any, index: number) => {
									return <div
										className={`${currentNvrChannel === item.channel ? 'nvr-channel-item nvr-channel-item-active' : 'nvr-channel-item'}`}
										key={index}
										onClick={() => switchChannelFun(item)}
									>
										<div className={'nvr-dev-img'}>
											<img src="/res/icons/camera-1.png" alt=""/>
										</div>
										<p className={'nvr-channel-name'}>{item.name}</p>
									</div>
								})
							}
						</div>

					</div>}
				</div>
				<div className={'btn-setting'} style={{opacity: 0, cursor: 'default'}}><SettingFilled style={{fontSize: '24px'}} /></div>
			</div>
			<div className={'video-out-box'} ref={playerBoxRef}>

				<VideoPlayerII
					isMain={true}
					// url={liveURL}
					physical_id={physical_id}
					width={playerSize.width}
					height={playerSize.height}
					scaleMode={1}
					config={{playerBar: true, calendar: currentDeviceInfo.cloud, duration: true, definition: true, clip: true, fullscreen: true}}
					data={currentDeviceInfo}
					channel={currentNvrChannel}
					// playTimeDrive={playTimeDrive}
					// setPlayTime={setPlayTime}
					// pause={pause}
					// volume={volume}
					// setVideoRef={setVdieoPlayer}
					// setFps={setFps}
				/>
			</div>
		</div>
	</>
}
