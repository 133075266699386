import {useLocation, useNavigate} from "react-router-dom";
import {useUploadingFileStatusStore} from "../../store/vault";

function useMyNavigate(){
	const navigate = useNavigate();
	const location = useLocation();
	const {pathname} = location;

	const {setUploadingFileStatusZU} = useUploadingFileStatusStore();

	function navigateX(path, state=null) {
		// console.log(`from: ${pathname}, to: ${path}`);
		// navigate(path);
		let vaultLoadingListCount = window['vaultLoadingListCount'];
		let vaultPendingListCount = window['vaultPendingListCount'];

		let len = 0;

		if(Array.isArray(vaultLoadingListCount)){
			len = vaultLoadingListCount.length
		}
		if(Array.isArray(vaultPendingListCount)){
			len = len ? len : vaultPendingListCount.length
		}

		if(
			len > 0 &&
			String(path).indexOf('/vault') === -1 &&
			String(pathname).indexOf('/vault') > -1
		){
			let u = window['confirm']('Switching pages may result in file upload failure');
			if(!u){
				return;
			}
		}

		// 正在上传文件
		// if(vaultLoadingListCount > 0 && String(path).indexOf('/vault') === -1 && String(pathname).indexOf('/vault') > -1){
		// 	setUploadingFileStatusZU(true);
		// 	return;
		// }

		if(state){
			navigate(path, state);
		}else{
			navigate(path);
		}

	}

	return { navigateX };
}

export {
	useMyNavigate
}
