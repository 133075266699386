import { Image, Toast } from "antd-mobile";
import { Drawer } from 'antd';
import './index.less'
// import HeadNav from "../../../components/headNav";
import { useEffect, useRef, useState } from "react";
import token from "../../../utils/token";
import { getTrashList } from "../../../server/trash";
import { getLargeFile } from "../../../server/folder";
import fileType from "../../../utils/fileType";
import getApiUrl from "../../../server/apiUrl";
import CloseIcon from '../../../assets/images/cloudPlan/close.svg'
import Icon from "@ant-design/icons";
import { getStorageStatInformation } from "../../../server/storage";
import {getLGToken, isJSON, sizeFormat} from "../../../utils/common";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import jumpPage from "../../../utils/jumpPage";
// import NAPI from "../../../utils/NAPI";
import LoadDataWaitingState from "../../../components/loadDataWaitingState";
// import fileTypeIcon from "../../../utils/fileTypeIcon";
import {useMyNavigate} from "../../../hook/useMyNavigate";

// const icon_popup_a = BaseResource('/res/icons/icon.png');
const imageUrl = getApiUrl('cloudDisk');


export default function CleanUpMeDrivePage(props) {
	const { open, onOpen, onIsStorage, onIsLargeFilesModal, isLargeFilesModal } = props
	const [largeFileList, setLargeFileList] = useState<any>([])
	const [typeSize, setTypeSize] = useState<any>({})
	const [trashList, setTrashList] = useState<any>([]);
	const [loadingMainData, setLoadingMainData] = useState<number>(0); // 0: 还没请求数据， 1：请求中， 2：请求结束

	const timeOutRef: any = useRef(0)
	const {navigateX} = useMyNavigate()
	const location = useLocation()

	// const pathObj = `${location.pathname}${location.search}`

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	}, [])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}


	const getTrashData = async () => {
		setLoadingMainData(() => 1);
        const req:any = {
			page: 0,
			pageSize: 10
		}
		let res: any = await getTrashList(req)
		const { data, result, error } = res;
		if (result === 'ok' && isJSON(data)) {
			let _dirs = Array.isArray(data.dirs) ? data.dirs : [];
			let _files = Array.isArray(data?.files?.content) ? data?.files?.content : [];
			let _count = 0;
			let _trashList = [];

			_dirs.map((item: any) => {
				let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified on';
				let _dt = dayjs(item.update_at).format('MMM D, YYYY');
				item.icon = '/res/icons/icon_folder.svg?v=1';
				item.content = `${_lb} ${_dt}`;

				if (_count < 8) {
					_trashList.push(item);
					_count++
				}
				return item;
			})

			_files.map((item: any) => {
				let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified on';
				let _dt = dayjs(item.update_at).format('MMM D, YYYY');

				item.icon = fileType[item.type]['icon'];
				item.content = `${_lb} ${_dt}`;
				if (item.type === 3 && item.cover) {
					item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
				} else if (item.type === 1 && item.cover) {
					item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
				}

				if (_count < 8) {
					_trashList.push(item);
					_count++
				}

				return item;
			})
   
            console.log(_dirs, _files, '99')
			setTrashList(() => _trashList)

		} else {
			setTrashList([])
			if (error && result !== 'ok') {
				Toast.show({
					content: error
				})
			}
		}

		getLageFileFunc();
	}


	const getLageFileFunc = async () => {
		let token = getLGToken()

		let res: any = await getLargeFile({ page: 0, pageSize: 9, orderBy: "size",
			order: "desc"  });
		const { data, result, error } = res;

		if (result === 'ok' && Array.isArray(data)) {
			let _largeFileList = data.map((item: any) => {
				item.icon = fileType[item.type]['icon'];
				if (item.type === 3 && item.cover) {
					item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
				} else if (item.type === 1 && item.cover) {
					item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
				}
				//  else if (item.type === 2) {
				// 	item.icon = fileTypeIcon[item.ext];
				// }

				return item;
			})

			setLargeFileList(() => _largeFileList);
		} else {
			setLargeFileList([]);
			if (error && result !== 'ok') {
				Toast.show({
					content: error
				})
			}

		}

		setTimeout(() => {
			setLoadingMainData(() => 2);
		}, 200)
	}

	const getCloudDiscFileInfo = async () => {
		let res: any = await getStorageStatInformation(2);
		let { data, result }: any = res;
		if (result === 'ok' && data) {
			if (isJSON(data)) {
				data._large_size = sizeFormat(data['large_size']);
				data._trash_size = sizeFormat(data['trash_size']);

				setTypeSize(() => data);
			}
		}

	}

	useEffect(() => {
		clearTimeout(timeOutRef.current)
		timeOutRef.current = setTimeout(() => {
			getTrashData();
			getCloudDiscFileInfo();
		}, 200)

		return () => {
			clearTimeout(timeOutRef.current)
		}
	}, [open, isLargeFilesModal])

	const toTrashPage = () => {
		jumpPage(navigateX, '/trash')
		onClose()
	}

	const toLargeFilePage = () => {
		// jumpPage(navigateX, `/large-file?file_type=10`)
		onIsLargeFilesModal(true)
		// jumpPage(navigateX, `/vault/large-file?file_type=4`)
		// onOpen(false)
		
	}
	const onClose = () => {
		// jumpPage(navigateX, `/vault`)
		jumpPage(navigateX, `/trash`)
		onOpen(false)
	}

	const handleBack = () => {
		onIsStorage(true)
		onOpen(false)

	}


	return <>
		<Drawer open={open} width={464} className="page-storge-con" onClose={onClose}>
			<div className={'page-storage'}>
				<div className='details-modal-title details-modal-back'>
					<p onClick={handleBack}>
						<img src="/res/icons/icon_order.svg" style={{ width: "18px", height: "18px" }} alt="" />
					</p>
					<p>Clean Up Vault</p>
					<p onClick={onClose}>
						<Icon component={CloseIcon} style={{ fontSize: "14px" }} />
					</p>
				</div>
				<div className={'page-clean-up-medrive'}>
					{/* <div className={'page-head-container'}>
				<HeadNav title={'Subscription'} />
			</div> */}
					<div className={'page-body-container page-body-container-tom'}>
						{/* <div className={'a-title-i'}>Clean Up</div>
				<div className={'a-desc-i'}>
					Make room for new photos, videos and files <br />
					by optimizing your Vault
				</div> */}


						{
							trashList.length > 0 && <>
								<div className={'a-card-iii a-card-iii-tom'}>
									<div className={'a-con-i'}>

										<div className={'a-card-name'}>Items in Trash</div>
										<div className={'a-intro-ii a-intro-ii-tom'}>Gain up to <span>{typeSize._trash_size}</span></div>

										<div className={'a-card-list'}>
											{
												trashList.map((item: any, index: number) => {

													return <div className={'a-card-item'} key={index}>
														<Image src={item.icon} fit='cover' lazy={true} />
													</div>
												})
											}
											<div className={'a-card-item'}>
												<img src={'/res/icons/icon_more.svg'} alt="" />
											</div>
										</div>

									</div>
									<div className={'a-button-i a-button-i-tom'} onClick={toTrashPage}>
										<span>View And Clean Up</span>
									</div>
								</div>
							</>
						}


						{
							largeFileList.length > 0 && <>
								<div className={'a-card-iii a-card-iii-tom'}>
									<div className={'a-con-i'}>

										<div className={'a-card-name'}>Large Files</div>
										<div className={'a-intro-ii a-intro-ii-tom'}>Gain up to <span>{typeSize._large_size}</span></div>

										<div className={'a-card-list'}>
											{
												largeFileList.map((item: any, index: number) => {

													return <div className={'a-card-item'} key={index}>
														<Image src={item.icon} fit='cover' lazy={true} />
													</div>
												})
											}
											<div className={'a-card-item'}>
												<img src={'/res/icons/icon_more.svg'} alt="" />
											</div>

										</div>

									</div>
									<div className={'a-button-i a-button-i-tom'} onClick={toLargeFilePage}>
										<span>View And Clean Up</span>
									</div>
								</div>
							</>
						}

						{
							loadingMainData === 1 && <div style={{ marginTop: '35px' }}>
								<LoadDataWaitingState />
							</div>
						}

						{
							trashList.length === 0 && largeFileList.length === 0 && loadingMainData === 2 && <div style={{ textAlign: 'center', marginTop: '35px' }}>
								<img src={'/res/icons/no_file_found.png'} alt="" />
							</div>
						}

					</div>
				</div>
			</div>
		</Drawer>
	</>
}
