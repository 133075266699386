import './index.less'
import { Image, ImageViewer, Toast } from "antd-mobile";
import { getFileDetails } from "../../../server/folder";
import { collectFolderOrFile } from "../../../server/folder";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {getLGToken, isAndroid, isApple, isJSON} from "../../../utils/common";
import getApiUrl from "../../../server/apiUrl";
// import NAPI from "../../../utils/NAPI";
import jumpPage from "../../../utils/jumpPage";
import DetailsModal from "../../../components/details-modal";
import fileType from "../../../utils/fileType";
import { getStorageInformation } from "../../../server/storage";
import TrashDetailsModal from "../../../components/trash-details-modal";
import TipsModal from "../../../components/tips-modal";
import DeleteModal from "../../../components/delete-modal";
import { deleteFile, getFolderContent } from "../../../server/folder"
import { deleteTrashFile, deleteTrashFolder, recoverTrashFile, recoverTrashFolder, getTrashList } from "../../../server/trash";
import EsVideo from '../../../components/esVideo/eindex'
import Icon from "@ant-design/icons";
import CloseIcon from '../../../assets/images/cloudPlan/close1.svg'
import _ from 'lodash';
import { message } from 'antd';
import FileDetailsModal from '../../vault/components/fileDetailsModal'
import dayjs from 'dayjs';
import { generatePDF } from "../../../utils/generatePDF";
import {useMyNavigate} from "../../../hook/useMyNavigate";

const fileUrl = getApiUrl('cloudDisk');

const FilePage = () => {
	const [fileID, setFileID] = useState<number>(0);
	const [fileInfo, setFileInfo] = useState<any>({});
	const [visibleMore, setVisibleMore] = useState<boolean>(false);
	const [visibleImageViewer, setVisibleImageViewer] = useState<boolean>(false);
	const [imageViewer, setImageViewer] = useState<string>('');
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
    const [isFileDetailsModal, setIsFileDetailsModal] = useState<boolean>(false)
	const location = useLocation();
	const navigate = useNavigate();
	const {navigateX} = useMyNavigate()
	const [rotateDegrees, setRotateDegrees] = useState(0);
    const [isFlip, setIsFlip] = useState(false);
	const [scale, setScale] = useState(1);
	const [distance, setDistance] = useState<number>(120)
	const [imgList, setImgList] = useState<any[]>([])
	const [delInfo, setDelInfo] = useState({ title: '', content: '', open: false })
	const timeOutRef: any = useRef(0);
	const videoRef = useRef<any>(null);

	let searchParams = new URLSearchParams(location.search);
	let _frompage: any = searchParams.get('frompage');
	let _id: any = searchParams.get('id');
	let _type:any = searchParams.get('type');
	let _fileid:any = searchParams.get('fileID');
	let _target:any = searchParams.get('target'); // 1 All Files; 2 Photos; 3 Starred; 4 Trash;  5 Images
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isTipsModal, setIsTipsModal] = useState<boolean>(false);


	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	}, [])

	const tokenCallback = (v) => {
		localStorage.setItem('token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const collateData = (arr) => {
		const compare = (a, b) => {
			var val1 = a.update_at;
			var val2 = b.update_at;
			if (val1 > val2) {
				return -1;
			} else if (val1 < val2) {
				return 1;
			} else {
				return 0;
			}
		}
		arr = arr.sort(compare);

		let _ls = {};

		const viewObj = { time: 'Days', type: "All" };
		let _date = `date_${(viewObj.time).toLowerCase()}`;
		let _type = viewObj.type; // All Starred  Images  Videos
		let _fileSelectMap = {};


		for (let item of arr) {
			let _key = item[_date];
			if (_type === 'All') {
				if (_ls[_key] === undefined) {
					_ls[_key] = [item];

					if (_fileSelectMap[_key] === undefined) {
						_fileSelectMap[_key] = [];
					}

				} else {
					// if(_ls[_key].length < 11) {
					(_ls[_key]).push(item);
					// }
				}
			} else if (_type === 'Starred') {
				if (item.collect) {
					if (_ls[_key] === undefined) {
						_ls[_key] = [item];

						if (_fileSelectMap[_key] === undefined) {
							_fileSelectMap[_key] = [];
						}
					} else {
						(_ls[_key]).push(item);
					}
				}
			} else if (_type === 'Images') {
				if (item.type === 3) {
					if (_ls[_key] === undefined) {
						_ls[_key] = [item];

						if (_fileSelectMap[_key] === undefined) {
							_fileSelectMap[_key] = [];
						}
					} else {
						(_ls[_key]).push(item);
					}
				}
			} else if (_type === 'Videos') {
				if (item.type === 1) {
					if (_ls[_key] === undefined) {
						_ls[_key] = [item];

						if (_fileSelectMap[_key] === undefined) {
							_fileSelectMap[_key] = [];
						}
					} else {
						(_ls[_key]).push(item);
					}
				}
			}
		}

		// setFileSelectMap(() => _fileSelectMap)

		return _ls;
	}


    const initFolderContent = async () => {
		if (_target === "1" && _type === "3") {
			let params = {
				page: 0,
				pageSize: 15,
				orderBy: 'name',
				order: 'asc',
				dirId: _fileid == "null" ? 0 : _fileid,
			}
			let res:any = await getFolderContent(params)
			let list:any = []
			res?.data?.files?.content.map((item:any, index:Number) => {
				if (item.type === 3) {
					list.push(item)
				}
				setImgList(list)
		   })



		} else if (_target === "2" && _type === "3") {
			let params = {
				page: 0,
				pageSize: 99999999,
				orderBy: 'name',
				order: 'asc',
				type: 3
			}

			let res:any = await getFolderContent(params)
			let { data, result }: any = res;
			let list:any = []
			res?.data?.files?.content.map((item:any, index:Number) => {
				let _onePageData = {
					number_of_elements: data['files']['number_of_elements'],
					page_number: data['files']['page_number'],
					total_elements: data['files']['total_elements'],
					total_pages: data['files']['total_pages'],
				}

				let files: any = data['files']['content']; //文件

				files.map((item: any) => {
					let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified';
					let _dt = dayjs(item.update_at).format('MMM D, YYYY');

					item.icon = fileType[item.type]['icon'];


					let last_modify_time = Number(item.last_modify_time);
					last_modify_time = Number.isNaN(last_modify_time) ? 0 : last_modify_time * 1000;

					last_modify_time = item.update_at ? item.update_at : item.create_at;

					item.date_days = dayjs(last_modify_time).format('DD/MM, dddd');
					item.date_months = dayjs(last_modify_time).format('MMMM dddd');
					item.date_years = dayjs(last_modify_time).format('dddd');
					return item;
				})

				// console.log(files, '204')
				let arr:any = collateData(files)
				// console.log(arr, '205')

				setImgList(files)
		   })
		} else if (_target === "3" && _type === "3") {
			let params = {
				page: 0,
				pageSize: 15,
				orderBy: 'name',
				order: 'asc',
				collect: true
			}

			let res:any = await getFolderContent(params)
			let list:any = []
			res?.data?.files?.content.map((item:any, index:Number) => {
				if (item.type === 3) {
					list.push(item)
				}
				setImgList(list)
		   })
		} else if (_target === "4" && _type === "3") {
			let res: any = await getTrashList({ isRecycle: 1 });
			let list:any = []
			res?.data?.files?.content.map((item:any, index:Number) => {
				if (item.type === 3) {
					list.push(item)
				}
				setImgList(list)
		   })
		}  else if (_target === "5" && _type === "3") {
			let params = {
				page: 0,
				pageSize: 15,
				orderBy: 'size',
				order: 'desc',
				type: 3
			}

			let res:any = await getFolderContent(params)
			let list:any = []
			res?.data?.files?.content.map((item:any, index:Number) => {
				if (item.type === 3) {
					list.push(item)
				}
				setImgList(list)
		   })
		}




	}

	useEffect(() => {
		if ( typeof _target === "string") {
			initFolderContent()
		}

	},[_target])

	useEffect(() => {
		imgList.map((item:any,index:Number) => {
			item.index = index
            return item
		})


	}, [imgList])

	console.log(imgList, 'imgList')


	useEffect(() => {
		if (_id) {
			let index = imgList.findIndex(item => {
				return item.id === Number(_id)
			});
			if (imgList.length === index) {
				setCurrentIndex(() => index - 1)
			} else {
				setCurrentIndex(index)
			}
		}
	}, [imgList])

	useEffect(() => {
		if (_type === "3") {
			let newImgList = _.cloneDeep(imgList)
			const nextObj = newImgList[currentIndex];
			setFileID(nextObj?.id)
		} else {
			setFileID(_id)
		}

	}, [currentIndex])

	useEffect(() => {
		let searchParams = new URLSearchParams(location.search);
		let _id: any = searchParams.get('id');
		if (_id === null || _id === undefined) {
			_id = 0;
		}
		setFileID(() => Number(_id))

	}, [location.search])

	useEffect(() => {
		clearTimeout(timeOutRef.current);
		if (fileID) {
			timeOutRef.current = setTimeout(() => {
				getDetail(fileID);
			}, 250);
		}
		return () => {
			clearTimeout(timeOutRef.current);
		}
	}, [fileID])




  const handlePrev =  () => {
	let newImgList = _.cloneDeep(imgList)
	setCurrentIndex(() => (currentIndex - 1 + newImgList.length) % newImgList.length);

  }

  const handleNext = () => {
	let newImgList = _.cloneDeep(imgList)
	setCurrentIndex(() => (currentIndex + 1) % newImgList.length);

  }



	useEffect(() => {
		getDiskStorageSize()
	}, [])

	useEffect(() => {
          if (fileInfo.type === 1) {
			setDistance(220)
		  }
	}, [fileInfo])

	const getDiskStorageSize = async () => {
		let res: any = await getStorageInformation();
		let { data, result } = res;
		if (result === 'ok' && data) {

			if (isJSON(data)) {
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data?.backup_id,
					backup_id: data.backup_id,
				}

				if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data)
			}
		} else if (Number(result) === 401){
			navigateX('/login')
		}
	}



	// 获取文件详情
	const getDetail = (id) => {
		let reqFunc = null;
		console.log(_frompage, '388')
		if (_frompage === 'trash') {

			reqFunc = getFileDetails(id, { isRecycle: 1 })
		} else {
			reqFunc = getFileDetails(id)
		}

		reqFunc.then((res: any) => {
			let { data, result }: any = res;
			if (result === 'ok' && isJSON(data)) {
				data.icon = fileType[data.type]['icon'];
				if (data.type === 3 && data?.cover) {
					// item.icon = getFileByUrl({url: item.url, name: item.name})
					data.icon = `${fileUrl}/disk/download_file?url=${data?.cover}&name=${data.name}&token=${token}`;
				}

				setFileInfo(() => data)
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	// 收藏和取消
	const collectFunc = () => {
		let _orderExpired = Number(cloudDiskInfo.size) === 0
		if ((!_orderExpired) && fileInfo.status === 0) {
			_orderExpired = true;
		}

         if (_orderExpired) {
			if (tipFunc()) return;
		 }


		let req = {
			type: 2,
			fid: fileInfo.id
		}
		collectFolderOrFile(req).then((res: any) => {
			let { result, data, error } = res;
			if (result === 'ok' && data) {
				getDetail(fileID);
				message.success(error)
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	const tipFunc = () => {
		if (Number(cloudDiskInfo.size) === 0 || Number(cloudDiskInfo.usable) < 0) {
			let _content = 'Access restricted';
			if (Number(cloudDiskInfo.size) > 0 && Number(cloudDiskInfo.usable) < 0) {
				_content = 'Not enough space'
			}

			setIsTipsModal(true)

			return true;
		} else {
			return false;
		}
	}




	const moreFunc = () => {
		openMorePopup();
	}

	const detailFunc = () => {
		let _url = `/details?id=${fileInfo.id}&type=${fileInfo.type}`
		if (_frompage === 'trash') {
			_url = `${_url}&frompage=${_frompage}`
		}
		jumpPage(navigateX, _url)
	}

	const openMorePopup = () => {
		setVisibleMore(true)
	}

	const closeMorePopup = () => {
		setVisibleMore(false)
	}

	const reqData = (v, o, action = '') => {
		if (v) {
			getDetail(fileID);
		}
	}

	const openImageViewer = () => {
		if (_frompage === 'trash') return;
		setVisibleImageViewer(() => true);
		let token = getLGToken()
		if (isApple()) {
			setImageViewer(() => `${fileUrl}/disk/download_file?url=${fileInfo?.url}&name=${fileInfo.name}&token=${token}`)
		} else {
			if (fileInfo.ext === 'HEIC') {
				setImageViewer(() => `${fileUrl}/disk/get_format_image?url=${fileInfo?.url}&name=${fileInfo.name}&token=${token}`)
			} else {
				setImageViewer(() => `${fileUrl}/disk/download_file?url=${fileInfo?.url}&name=${fileInfo.name}&token=${token}`)
			}

		}

	}


	// 底部详情弹窗，操作
	const bottomMenuListActionFunc = (v) => {
		// console.log(v);
		if (v === 'delete') {
			setIsDeleteModal(() => true);
		} else if (v === 'restore') {
			restoreFunc();
		} else if (v === 'detail') {
			detailFunc();
		}
	}

	const onCloseFunc = () => {
		let _copyWord = fileInfo?.name
		let _delInfo
	    if (_frompage === 'trash') {
            _delInfo = {
				title: "Delete forever",
				content: `"${_copyWord}" will be deleted forever. This can NOT be undone.`,
				open: false
			}
		} else {
			_delInfo = {
				title: _copyWord,
				content: `Are you sure you want to delete "${_copyWord}" ? "${_copyWord}" will be deleted forever after 30 days.`,
				open: false
			}
		}



		setDelInfo(() => _delInfo);
	}

	// 执行 恢复操作
	const restoreFunc = async () => {
		if (tipFunc()) return;

		if (fileInfo.id === undefined) {
			return;
		}
		let _func = fileInfo.id === undefined ? recoverTrashFolder : recoverTrashFile;
		let res: any = await _func(fileInfo.id);
		let { result, data }: any = res;
		if (result === 'ok' && data) {
			onCloseFunc();
			setTimeout(() => {
				jumpPage(navigateX, `/file?id=${fileInfo.id}&type=${fileInfo.type}`, { replace: true })
			}, 100)
		}
	}

	const onActionFunc = async () => {

		if (fileInfo.id === undefined) {
			return;
		}
		let _func
		if (_frompage === 'trash') {
			_func = fileInfo.type === undefined ? deleteTrashFolder : deleteTrashFile;
		} else {
			_func = fileInfo.type === undefined ? deleteTrashFolder : deleteFile;
		}




		let res: any = await _func(fileInfo.id);
		let { result, data, error }: any = res;

		if (result === 'ok' && data) {
			onCloseFunc();
			message.success(`${error}`)
			setTimeout(() => {
				navigateX(-1);
			}, 100)
		} else {
			message.error(`${error}`)
			onCloseFunc();
			setTimeout(() => {
				navigateX(-1);
			}, 100)
		}
	}


	const getBrowerVersion = () => {
		let ua = navigator.userAgent;
		let reg = /Chrome\/[0123456789]{2,3}/g;
		let _arr = ua.match(reg);
		let _ver = '';

		if (Array.isArray(_arr)) {
			if (_arr.length > 0) {
				_ver = _arr[0].replace('Chrome/', '');
			}
		}

		return Number(_ver);
	}

	const isMacOS = () => {
		let ua = navigator.userAgent;
		return ua.indexOf('Mac OS X') > -1 || ua.indexOf('Mac OS') > -1;
	}

	const getSafariVersion = () => {

	}

	const heic2png = (_url) => {
		// console.log(_url);
	}

	const isH265 = (o) => {
		let r = false;
		if (isJSON(o)) {
			if (o.hasOwnProperty('standard')) {
				let _standard = o['standard'];
				if (typeof _standard === 'string') {
					r = _standard.includes('hevc');
				}
			}
		}
		return r;
	}

	const aContent = `"${fileInfo?.name}" will be deleted forever. This can NOT be undone.`;
	// let _version = ((getBrowerVersion() > 104 && isAndroid()) || isApple());
	let _version = ((getBrowerVersion() > 104 && isAndroid()) || isMacOS())
	let _is_apple = isMacOS() || isApple();

	let _isApple = getBrowerVersion() > 104;
	let _chrome_version_gt_104 = (getBrowerVersion() > 104);
	let token = getLGToken()

	let _urlData = heic2png(`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`)
	let _isH265 = isH265(fileInfo);

	const imageStyle = {
		transform: `rotate(${rotateDegrees}deg)`,
		transition: 'transform 0.3s ease-in-out' // 可选的过渡动画
	  };

	  const imageStyleScale = {
		transform: `scale(${scale})`,
	  };


	const showFile = () => {
		let res = null;
		// res = <ImageHeic url={`/res/IMG_1191.HEIC`} ext={'HEIC'} />
		let token = getLGToken()
		if (fileInfo.type !== undefined) {
			res = <Image
				src={fileType[fileInfo.type]['icon']}
				alt=""
				lazy={true}
			/>
			if (fileInfo.type === 3) {
				// res = <>
				// 	<Image
				// 		src={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
				// 		alt=""
				// 		lazy={true}
				// 		fit={"contain"}
				// 		onClick={openImageViewer}
				// 	/>
				// </>

				// 回收站的源文件不存在，只能看封面
				if (_frompage === 'trash') {
					res = <Image
						src={`${fileUrl}/disk/download_file?url=${fileInfo.cover}&name=${fileInfo.name}&token=${token}`}
						alt=""
						lazy={true}
						fit={"contain"}
						onClick={openImageViewer}
					/>
				} else {
					if (fileInfo.ext === 'HEIC' && (!_isApple)) {
						// res = <ImageHeic url={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`} ext={fileInfo.ext} />
						res = <div id="contentPage" style={isFlip ? imageStyle : imageStyleScale}><Image
							src={`${fileUrl}/disk/get_format_image?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
							alt=""
							lazy={true}
							fit={"contain"}
							onClick={openImageViewer}

						/></div>
					} else {
						res = <div id="contentPage" style={isFlip ? imageStyle : imageStyleScale}><Image
							src={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
							alt=""
							lazy={true}
							fit={"contain"}
							onClick={openImageViewer}

						/></div>
					}
				}



			} else if (fileInfo.type === 1) {
				/* eslint-disable */
				/* eslint-enable */
				// res = <>
				// 	{
				// 		_version ? <>
				//
				// 				{
				// 					_isApple ? <>
				// 							<video
				// 								webkit-playsinline={'true'}
				// 								playsInline={true}
				// 								x5-video-player-type={'h5'}
				// 								x5-video-player-fullscreen={'true'}
				// 								x-webkit-airplay={'true'}
				// 								src={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
				// 								controls={true}
				// 								poster={`${fileUrl}/disk/download_file?url=${fileInfo.cover}&name=${fileInfo.name}&token=${token}`}
				// 								ref={videoRef}
				// 							>
				// 								<source src={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`} />
				// 							</video>
				// 					</>
				// 					:
				// 					<EsVideo
				// 						url={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
				// 						poster={`${fileUrl}/disk/download_file?url=${fileInfo.cover}&name=${fileInfo.name}&token=${token}`}
				// 					>
				// 					</EsVideo>
				// 				}
				//
				// 		</>
				// 		:
				// 		<>
				// 			<div className={'a-full-card'}>
				// 				<span style={{marginTop: '-100px'}}>
				// 					<img src={'/res/icons/icon_alert.png'} alt=""/>
				// 				</span>
				// 				<span style={{marginTop: '30px'}}>
				// 					This video needs the latest browser to play, please update your system browser.
				// 				</span>
				// 			</div>
				// 		</>
				// 	}
				//
				//
				// 	{/*<ReactPlayer*/}
				// 	{/*	url={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}*/}
				// 	{/*	controls={true}*/}
				// 	{/*	width={'100%'}*/}
				// 	{/*	height={'auto'}*/}
				// 	{/*	playsinline={true}*/}
				// 	{/*	// webkit-playsinline="true"*/}
				// 	{/*	x5-playsinline="true"*/}
				// 	{/*	x-webkit-airplay="allow"*/}
				// 	{/*	x5-video-player-fullscreen="true"*/}
				// 	{/*	x5-video-player-type="h5"*/}
				// 	{/*/>*/}
				// </>


				if (_is_apple || _chrome_version_gt_104 || !_isH265) {

					res = <video
						webkit-playsinline={'true'}
						playsInline={true}
						x5-video-player-type={'h5'}
						x5-video-player-fullscreen={'true'}
						x-webkit-airplay={'true'}
						src={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
						controls={true}
						poster={`${fileUrl}/disk/download_file?url=${fileInfo.cover}&name=${fileInfo.name}&token=${token}`}
						ref={videoRef}
						autoPlay={true}

					>
						<source src={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`} />
					</video>
				} else {

					// if (_isH265) {
					// 	if (_chrome_version_gt_104) {
					// 		res = <EsVideo
					// 			url={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
					// 			poster={`${fileUrl}/disk/download_file?url=${fileInfo.cover}&name=${fileInfo.name}&token=${token}`}
					// 		>
					// 		</EsVideo>
					// 	} else {
							res = <div className={'a-full-card'}>
								<span style={{ marginTop: '-100px' }}>
									<img src={'/res/icons/icon_alert.png'} alt="" />
								</span>
								<span style={{ marginTop: '30px' }}>
									Failed to play H.265 video.
								</span>
							</div>
						// }
					// } else {
					// 	res = <EsVideo
					// 		url={`${fileUrl}/disk/download_file?url=${fileInfo.url}&name=${fileInfo.name}&token=${token}`}
					// 		poster={`${fileUrl}/disk/download_file?url=${fileInfo.cover}&name=${fileInfo.name}&token=${token}`}
					// 	>
					// 	</EsVideo>
					// }
				}

			}
		}
		// This video needs the latest browser to play, please update your system browser.
		return res;
	}

  const onClose = () => {
	backFunc()
  }

  const backFunc = () => {
	let searchParams = new URLSearchParams(location.search);
	let _hisdeep:any = searchParams.get('hisdeep');

	if(typeof _hisdeep === 'string'){
		_hisdeep = - Number(_hisdeep)
	}else{
		_hisdeep = -1;
	}
		navigateX(_hisdeep);
   }

   function downloadImage(url, name) {
	let link = document.createElement('a');
	link.href = url;
	link.download = name;
	document.body.appendChild(link);
	link.click()
	document.body.removeChild(link);
}


   // 下载
   const handleDownload = () => {
	const data = _.cloneDeep(fileInfo)
	let url =  `${fileUrl}/disk/download_file?url=${data.url}&name=${data.name}&token=${getLGToken()}`;
	downloadImage(url, data.name)
   }

   const handleMessage = () => {
	setIsFileDetailsModal(() => true)
   }

   // 删除
   const handleDelete = () => {
	let _copyWord = fileInfo.name
	let _delInfo
	    if (_frompage === 'trash') {
            _delInfo = {
				title: "Delete forever",
				content: `"${_copyWord}" will be deleted forever. This can NOT be undone.`,
				open: true
			}
		} else {
			_delInfo = {
				title: _copyWord,
				content: `Are you sure you want to delete "${_copyWord}" ? "${_copyWord}" will be deleted forever after 30 days.`,
				open: true
			}
		}


		setDelInfo(() => _delInfo);
   }

   // 旋转
   const handleFlip = () => {
	setIsFlip(() => true)
	setRotateDegrees(rotateDegrees - 90)
   }

   // 放大
   const handleBlowUp = () => {
	setIsFlip(() => false)
	setScale(scale + 0.5);
   }

   // 缩小
   const handleLessen = () => {
    setIsFlip(() => false)
	setScale(scale - 0.5);
   }

   useEffect (() => {
	let newScale = Number(scale.toFixed(1))
      if (newScale <= 0) {
		setScale(0.5);
		message.warning("Already minimized")
	  } else if (newScale >= 5.1) { {
		message.warning("Already maximized")
		setScale(5.1);
	  }
	  }
   }, [scale])

   console.log(scale, '934')

   const handlePrint = () => {
	//    window.print()
	   let time = new Date();
	   const newTime = dayjs(time).format("YYYY-MM-DD");
	   generatePDF(false, "contentPage", `PO-${newTime}`);
   }

	return <>
 		<div className={'file-page'}>
            <div className="file-page-title">
				  <p></p>
				  <p className='file-page-name'>{fileInfo.name}</p>
				  <p onClick={onClose} style={{ marginRight: "24px" }}>
						<Icon component={CloseIcon} style={{ fontSize: "44px" }} />
				  </p>
			</div>


			{/* <div className={'hd-box'}>
				<HeadNav
					title={fileInfo.name}
					icon={'/res/icons/icon_close.svg'}
					endElement={
						<span>
							<Space style={{ '--gap': '32px' }}>

								{
									_frompage === 'trash' ? null : <>
										<span
											onClick={collectFunc}
											style={{ opacity: `${Number(cloudDiskInfo.size) > 0 ? 1 : 0.65}` }}
										>
											<img
												src={fileInfo?.collect ? "/res/icons/icon_favourite_on.svg" : "/res/icons/icon_favourite.svg"}
												style={{ width: "20px", verticalAlign: 'middle' }}
												alt=""
											/>
										</span>
									</>
								}
							</Space>
						</span>
					}
				/>
			</div> */}
			<div className={'mn-box'} style={{ marginTop: `${distance}px`}}>
				<div className={`file-content ${_version || true ? '' : 'a-no-video'} ${fileInfo.type === 3 ? '-img' : ''}`}>
					{
						showFile()
						// <p style={{color: '#ffffff'}}>{getBrowerVersion()}</p>
					}
				</div>
			</div>
			{
				fileInfo?.type === 3 && <div className='file-left'>
				<p onClick={handlePrev} className='underline'><img src="/res/icons/icon_left.svg" /></p>
			</div>
			}
		    {
				fileInfo?.type === 3 && <div className='file-right'>
				<p onClick={handleNext} className='underline'><img src="/res/icons/icon_right.svg" /></p>
			</div>
			}

			<div className="file-operation">
				 <div>
					{fileInfo?.type === 3 && <p onClick={handlePrint} className='underline'><img src="/res/icons/icon_print1.svg" /></p>}
					{fileInfo?.type === 3 && <p onClick={handleBlowUp}><img src="/res/icons/icon_blow_up.svg" /></p> }
					{fileInfo?.type === 3 && <p onClick={handleLessen}><img src="/res/icons/icon_lessen.svg" /></p> }
					{fileInfo?.type === 3 && <p onClick={handleFlip} className='underline'><img src="/res/icons/icon_flip.svg" /></p>}
					<p onClick={handleDownload}><img src="/res/icons/icon_download.svg" /></p>
					{_frompage !== 'trash' && <p onClick={collectFunc}>{fileInfo?.collect ? <img src="/res/icons/icon_collect.svg" /> : <img src="/res/icons/icon_not_collect.svg" />}</p> }
					<p onClick={handleMessage}><img src="/res/icons/icon_message.svg" /></p>
					<p onClick={handleDelete}><img src="/res/icons/icon_delete.svg" /></p>
				 </div>

			</div>
            <FileDetailsModal open={isFileDetailsModal} onOpen={setIsFileDetailsModal} top={"top"} initPageData={reqData} data={fileInfo}/>
			<DetailsModal
				type={"file"}
				visible={_frompage !== 'trash' && visibleMore}
				onVisible={setVisibleMore}
				data={fileInfo}
				reqData={reqData}
				cloudDiskInfo={cloudDiskInfo}
			/>

			<TrashDetailsModal
				type={"file"}
				visible={_frompage === 'trash' && visibleMore}
				onVisible={setVisibleMore}
				detailsObj={fileInfo}
				onAction={bottomMenuListActionFunc}
				cloudDiskInfo={cloudDiskInfo}
			/>

			{/*<ImageViewer*/}
			{/*	visible={visibleImageViewer}*/}
			{/*	image={imageViewer}*/}
			{/*	onClose={() => setVisibleImageViewer(false)}*/}
			{/*/>*/}
		</div>

        <DeleteModal
			title={delInfo.title}
			contentObj={delInfo.content}
			visible={delInfo.open}
			onClose={onCloseFunc}
			onAction={onActionFunc}
			closeButtonText={'Cancel'}
			actionButtonText={'Delete'}
			onVisible={onCloseFunc}
		/>
        <TipsModal visible={isTipsModal} onVisible={setIsTipsModal} diskInfo={cloudDiskInfo}/>

	</>
}



export default FilePage
