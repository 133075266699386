import './index.less'
import {Row, Col, Button, Form, Input, DatePicker, Select, message, Drawer} from 'antd';

import BackSvg from '../../../../assets/images/order/back.svg'
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'
// import ReactDOM from 'https://esm.sh/react-dom@18.2.0'


// import { getCloudOrderList } from '../../../../server/plan'
import {getListCloudPlan, getListSubscription, getVdrSubmit} from '../../../../server/cloudPlan'
import {useLocation} from "react-router-dom";
import _ from 'lodash';
import Icon from "@ant-design/icons";
import {useEffect, useState} from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {usePaymentStore} from "../../../../store/cloudPlan";
import {getCookie, getLGToken} from "../../../../utils/common";

const CheckOut = (props) => {
	const {open, currentPlanObj_XX, onIsCheckOut} = props;
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const product_key = params.get('product_key')

	const [accountObj, setAccountObj] = useState<any>({})

	const [stripeLoadInfo, setStripeLoadInfo] = useState<any>(null);
	const [stripeCheckoutPayment,setStripeCheckoutPayment] = useState<any>(null);

	const [currentPlanObj, setCurrentPlanObj] = useState<any>({});

	const {paymentInfoZU, setPaymentInfoZU} = usePaymentStore();

	useEffect(() => {
		let {data} = paymentInfoZU;

		if(data){
			setCurrentPlanObj(() => data);
		}

	}, [paymentInfoZU])

	useEffect(() => {
		const account: any = getCookie('accountInfo');
		if (JSON.stringify(account) !== '' && account !== null)
			setAccountObj(JSON.parse(account));
	}, [])


	useEffect(() => {
		if(open){

		}else{
			if(stripeCheckoutPayment){
				stripeCheckoutPayment.destroy();
			}
		}
	}, [open, stripeCheckoutPayment])


	const handleBack = () => {
		window.history.back()
	}

	const reSetData = () => {
		setStripeLoadInfo(() => null);
	}

	useEffect(() => {
		if (currentPlanObj?.product_key) {
			initialize(currentPlanObj?.type);
		}

	}, [currentPlanObj])

	// Create a Checkout Session
	const initialize = async (type) => {

		if(!['vault', 'cloud'].includes(type)) {

			return;
		}

		let akey = 'pk_test_51Lxc44H5ffOi0bIPLIT2ATbD9rFftbZzexmjpzlgQoTQgajotbqF7Oh16rDiUS0sTWuAP4OElZ2Y4ngHaMLzcuVN00mzJ1iozB';
		if(process.env.NODE_ENV !== 'development'){
			akey = 'pk_live_51Lxc44H5ffOi0bIPrcLuaFGYdMJXyRHFS9QYw8oUdc5VDzcaQaVIWUb5IZpa6JdaAVzJOd5vuQRrWgGFSEQDcMje003yYZlRJd';
		}

		let newStripe = await loadStripe(akey);

		setStripeLoadInfo(() => newStripe);


		const is_change_plan = currentPlanObj?.is_change_plan;
		const oid = currentPlanObj?.id;
		const token = getLGToken();
		const email = getCookie('email');

		let req:any = {
			user_token: token,
			email: email,
			devices: currentPlanObj?.devices,
			product_key: currentPlanObj?.product_key,
			return_url: window.location.origin + "/result/cloud",
		}

		let _url = 'https://store-test.smartz.cloud/subscribe/session';
		if(is_change_plan === 1){
			_url = 'https://store-test.smartz.cloud/subscribe/change/session';
			req.subscription_id = oid;
		}
		if (window.location.host === 'user.smartzliving.ai' || window.location.host === 'user.smartz.cloud') {
			_url = 'https://houtai.smartz.cloud/subscribe/session';

			if(is_change_plan === 1){
				_url = 'https://houtai.smartz.cloud/subscribe/change/session';
			}
		}

		if(type === 'vault'){
			req = {
				user_token: token,
				email: email,
				product_key: currentPlanObj?.product_key,
				return_url: window.location.origin + "/result/vault",
			}

			_url = 'https://store-test.smartz.cloud/vdr/session';
			if(is_change_plan === 1){
				_url = 'https://store-test.smartz.cloud/vdr/change/session';
				req.vdr_id = oid;
			}
			if (window.location.host === 'user.smartzliving.ai' || window.location.host === 'user.smartz.cloud') {
				_url = 'https://houtai.smartz.cloud/vdr/session';
				if(is_change_plan === 1){
					_url = 'https://houtai.smartz.cloud/vdr/change/session';
				}
			}
		}



		const fetchClientSecret = async () => {
			const response = await fetch(_url, {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(req),
			});
			const {client_secret} = await response.json();
			return client_secret;
		};

		const checkoutPayment = await newStripe?.initEmbeddedCheckout({
			fetchClientSecret,
		});

		// Mount Checkout
		checkoutPayment.mount('#checkoutTom');

		setStripeCheckoutPayment(() => checkoutPayment);
	}

	const onClose = () => {
		// onIsCheckOut(false)

		setPaymentInfoZU({open: false, data: {}})
	}


	return <Drawer
		title="Basic Drawer"
		placement="right"
		width={640}
		className='security-subscribe-modal subscribe-checkout-modal'
		destroyOnClose={true}
		onClose={onClose}
		open={open}
	>
		<div className='details-modal-title'>
			<p></p>
			<p>Subscribe</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>
		<div className='your-cart1'>
			<div className='check-out'>
				<div id="checkoutTom"></div>
			</div>
		</div>
	</Drawer>
}

export default CheckOut
