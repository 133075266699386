import {Button, Modal, Progress} from 'antd';
import './index.less';
import {useEffect, useState} from 'react';
import Icon from "@ant-design/icons";
import CloseIcon from '../../../../assets/images/cloudPlan/close1.svg'
import fileTypeIcon from "../../../../utils/fileTypeIcon";

import {sizeFormat} from "../../../../utils/common";

const FileUploadInformation = (props) => {
	const {open, onOpen, list, flleArr, optionFun} = props;


	//  useEffect(() => {
	//      setTimeout(() => {
	//       onOpen(true)
	//      }, 1000)
	//  }, )

	const onClose = () => {
		onOpen(false)
	}

	const cancelUploadFun = (o) => {

		if (optionFun) {
			optionFun(o, 1)
		}
	}

	const stopUploadFun = (o) => {
		if (optionFun) {
			optionFun(o, 2)
		}
	}

	const reUploadFun = (o) => {
		if (optionFun) {
			optionFun(o, 3)
		}
	}

	let _all = list.length;
	let _done = 0;
	list?.map((o:any) => {
		if([2,3].includes(o.status)){
			_done = _done + 1;
		}
		return o;
	})
 
    


	return <div className='file-upload-information'>

		<div className='file-information-modal-title'>
			<p className={'a-title'}>Uploading ({_done}/{_all})</p>
			<div className={'close-btn-a'} onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "44px"}}/>
			</div>
		</div>

		{
			list.length === 0 ? <div
					className={'upload-file-items'}
					style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
				>
				<img src="/res/icons/no_file_found.png" alt=""/>
				<p style={{opacity: 0.6, marginTop: '25px'}}>No content</p>
			</div>

			:

			<div className={'upload-file-items'}>
				{
					list.map((item: any, index: number) => {
						return <div className={'upload-file-item'} key={index}>
							<div className={'icon-name-box'}>
								<div className={'icon-box'}>
									<img src={fileTypeIcon[item.ext]} style={{width: "24px", height: "24px"}} alt=""/>
								</div>
								<div className={'name-box'}>
									<p className={'-name'}>{item?.name}</p>
									<div className={'progress-box'} style={{minHeight: '5px'}}>
										{(item.status === 1 || item.status === 2 || item.status === 3 || item.status === 4) && <Progress
											percent={item.progress}
											size="small"
											showInfo={false}
										/>}
									</div>
									{
										((item.status === -1 || item.status === 5) && item.error_message !== undefined && item.error_message !== '') ?
											<>
												<p className={'error-msg-a'}>{item.error_message}</p>
											</>
											:
											<>
												<p className={'file-size'}>{sizeFormat(item.size)}</p>
											</>
									}
									{
										item.status === 2 && item.progress === 0 && item.info_message && <p className={'info-msg-a'}>{item.info_message}</p>
									}

								</div>
								{/*}*/}
							</div>
							<div className={'opt-box'}>
								{/*等待*/}
								{
									item.status === 1 && <span className={'opt-btn'}>
												<img src={'/res/icons/upload/icon_waitting.svg'} alt=""/>
											</span>
								}
								{/*暂停*/}
								{/*{*/}
								{/*	item.status === 2 && item.is_large_file === 1 &&*/}
								{/*  <span className={'opt-btn'} onClick={() => stopUploadFun(item)}>*/}
								{/*						<img style={{filter: `${item?.stop_status === 1 ? 'grayscale(1)' : 'grayscale(0)'}`}} src={'/res/icons/upload/icon_stop.svg'} alt=""/>*/}
								{/*					</span>*/}
								{/*}*/}
								{/*/!*重新开始*!/*/}
								{/*{*/}
								{/*	item.status === 4 && item.is_large_file === 1 &&*/}
								{/*  <span className={'opt-btn'} onClick={() => reUploadFun(item)}>*/}
								{/*						<img src={'/res/icons/upload/icon_upload.svg'} alt=""/>*/}
								{/*					</span>*/}
								{/*}*/}
								{/*取消*/}
								{
									(item.status === 1 || (item.status === 2 && item.is_large_file === 1) || item.status === 5) &&
									<span className={'opt-btn'} style={{cursor: 'pointer'}} onClick={() => cancelUploadFun(item)}>
												<img src={'/res/icons/upload/icon_cancel.svg'} alt=""/>
											</span>
								}
								{/*完成*/}
								{
									item.status === 3 && <span className={'opt-btn'} style={{width: '20px'}}>
												<img src={'/res/icons/icon_select.svg'} alt=""/>
											</span>
								}
								{/*失败*/}
								{
									item.status === -1 && <span style={{fontSize: '14px', color: '#E02020 '}}>Failed</span>
								}
							</div>
						</div>
					})
				}
			</div>
		}





	</div>
}


export default FileUploadInformation;
