import { Button, Modal } from 'antd';
import PromptIcon from '../../../../assets/images/cloudPlan/prompt_icon.svg'
import Icon from "@ant-design/icons";
import './index.less';


const MaybeLaterModal = (props) => {
    const { open, onOpen } = props;
    const handleOk = () => {
        onOpen(false);
      };

      const handleCancel = () => {
        onOpen(false);
      };
    return <Modal title="Basic Modal" open={open} className='prompt-conter' centered onOk={handleOk} onCancel={handleCancel} footer={null}>
            <div className='prompt'>
            <Icon component={PromptIcon} style={{ fontSize: "56px" }}/>
            </div>
            <div className='prompt-con'>
            You have subscribed a Safeguard Plan before, but you didn’t <br/>
            activate it yet, Activate the plan now?
            </div>
            <div className='prompt-footer'>
                <Button onClick={handleOk}>Maybe Later</Button>
                <Button onClick={handleOk}>Activate Now</Button>
            </div>

  </Modal>
}


export default MaybeLaterModal;
