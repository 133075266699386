import Icon from "@ant-design/icons";
import SelectActive from "../../../../assets/images/cloudPlan/select_active.svg";

export default function CVRPlanCardZmodo(props){
	const {cloudPromoInfo, handleSubscribe, hasFreeTrial} = props;

	return <div className='cloud-plan-con-card-con'>
		<div className='subscribe-card'>
			{cloudPromoInfo?.p2?.discount &&<div className={'a-promo-tag'}>{cloudPromoInfo?.p2?.discount}% Off</div>}
			<div className='subscribe-card-title'>
				Premium
			</div>
			<div className='subscribe-card-con'>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>Continuous</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>7 Days Storage</span>
				</p>
			</div>
			{
				cloudPromoInfo?.p2?.discount ?
					<div className='subscribe-starting'>
						Starting at:
						<span className={'o_price'}>${cloudPromoInfo?.p2?.o_price}</span>
						<span>${cloudPromoInfo?.p2?.price}</span>
					</div>
					:
					<div className='subscribe-starting'>
						Starting at: <span>$4.99</span>
					</div>
			}

			<div className='subscribe-card-btn' onClick={() => handleSubscribe(1, 1, 0)}>
				{hasFreeTrial && false ? "Start My Free Trial" : "Subscribe"}
			</div>
		</div>
		<div className='subscribe-card'>
			{cloudPromoInfo?.p3?.discount &&<div className={'a-promo-tag'}>{cloudPromoInfo?.p3?.discount}% Off</div>}
			<div className='subscribe-card-title'>
				Platinum
			</div>
			<div className='subscribe-card-con'>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>Continuous</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px"}}/>
					<span>30 Days Storage</span>
				</p>
			</div>
			{
				cloudPromoInfo?.p3?.discount ?
					<div className='subscribe-starting'>
						Starting at:
						<span className={'o_price'}>${cloudPromoInfo?.p3?.o_price}</span>
						<span>${cloudPromoInfo?.p3?.price}</span>
					</div>
					:
					<div className='subscribe-starting'>
						Starting at: <span>$9.99</span>
					</div>
			}

			<div className='subscribe-card-btn' onClick={() => handleSubscribe(1,2,0)}>
				{hasFreeTrial && false ? "Start My Free Trial" : "Subscribe"}
			</div>
		</div>
	</div>
}
