import './index.less'
import { Button, message, Form, Row, Col, Input } from 'antd';
import { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
// import LogoSvg from '../../assets/images/logo.svg'
// import Icon from "@ant-design/icons";
import LogoPng from '../../assets/images/logo.png'
import { useState } from 'react';
import { getTryRegister, getActivateEmail, loginUser } from '../../server/login'
import md5 from 'js-md5';
import { getPlatfromByUrl, setCookie } from "../../utils/common";
import { useMyNavigate } from "../../hook/useMyNavigate";
import lang from "../../utils/lang";
import mainLogo from "../../utils/logo/index.js";
import Icon from "@ant-design/icons";
const _platform = getPlatfromByUrl();
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const Login = () => {
    const navigate = useNavigate();
    const { navigateX } = useMyNavigate()
    const [userform] = Form.useForm();
    const [inputform] = Form.useForm();
    const [isActive, setIsActive] = useState(false);
    const [email, setEmail] = useState(true)
    const [current, setCurrent] = useState(1);
    const [verifyId, setVerifyId] = useState("")
    const [isEmailActive, setIsEmailActive] = useState(false) // 是否正在发送验证码
    const [password, setPassword] = useState<any>()
    const [count, setCount] = useState(0) // 默认0秒
    const timerRef = useRef(null) // 使用useRef设置一个倒计时器，这样我就可以防止重新渲染
    // const [isSending, setIsSending] = useState(false); // 是否正在发送验证码

    const handleNext = async () => {
        if (isActive) {
            const values = await userform.validateFields();
            // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
            // const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z][A-Za-z\d@$!%*#?&]{8,}$/
            const regex = /^(?!^\d+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^a-z0-9]+$)(?!^[^A-Z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S*$/
            if (!regex.test(values.password) && !regex.test(values.rePassword)) {
                message.error('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number')
                return
            }


            if (values.password === values.rePassword) {
                const params: any = {
                    cid: 0,
                    platform: 21,
                    client: 1,
                    language: "en",
                    email: (values.username).trim(),
                    password: md5(values.password),
                }
                setEmail(values.username)
                setPassword(params.password)
                const res: any = await getTryRegister(params)
                if (res.result === 'ok') {
                    setVerifyId(res.verify_id)
                    setCount(59)
                    setCurrent(2)
                    message.success('Email sent successfully')
                } else {
                    message.error(`${res?.error}`)
                }
            } else {
                message.error('Passwords do not match')
            }
        } else {
            message.error('Please enter your username and password')
        }

    }

    useEffect(() => {
        userform.setFieldsValue({
            usename: '',
            password: '',
            rePassword: ''
        });
    }, [])

    const handleResendEmail = async () => {
        if (isEmailActive) {
            inputform.setFieldsValue({
                // vcode1: '',
                // vcode2: '',
                // vcode3: '',
                // vcode4: '',
                // vcode5: '',
                // vcode6: '',
                vcode: ''
            })
            const params: any = {
                cid: 0,
                platform: 21,
                client: 1,
                language: "en",
                email,
                password,
            }
            const res: any = await getTryRegister(params)
            if (res.result === 'ok') {
                setVerifyId(res.verify_id)
                setCount(59)
                message.success('Email sent successfully')
            } else {
                message.error(`${res?.error}`)
            }
        }
    }


    const handleUser = async (e) => {
        const values = await userform.validateFields();
        if (values.username && values.password && values.rePassword) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }

    const handleInput = async () => {
        const values = await inputform.validateFields();
        if (values.vcode.length === 6) {
            const parmas = {
                verify_id: verifyId,
                // verify_code: `${values.vcode1}${values.vcode2}${values.vcode3}${values.vcode4}${values.vcode5}${values.vcode6}`,
                verify_code: `${values.vcode}`
            }
            const res: any = await getActivateEmail(parmas)
            if (res.result === 'ok') {
                message.success('Email sent successfully')
                const params1 = {
                    cid: 0,
                    platform: 21,
                    client: 1,
                    language: "en",
                    email,
                    password,
                }
                const res: any = await loginUser(params1)
                if (res.result === "ok") {
                    localStorage.setItem('token', res.token)
                    localStorage.setItem('accountInfo', JSON.stringify(res.data))
                    // message.success('Login Successfully')

                    setCookie('username', res.data?.username);
                    setCookie('email', res.data?.email);
                    setCookie('token', res.token);
                    setCookie('accountInfo', JSON.stringify(res.data))
                    setCookie('oldPassword', params1?.password)

                    let _search = new URLSearchParams(window.location.search);
                    let return_url = _search.get('return_url');

                    if (return_url) {
                        return_url = decodeURIComponent(return_url);
                        window.location.href = return_url;
                    } else {
                        navigateX('/cloud-plan');
                    }
                }
            } else {
                message.error(`${res?.error}`)
            }
        }
    }

    // 监听count的变化
    useEffect(() => {
        if (count >= 59) {
            timerRef.current = setInterval(() => {
                setCount((preCount) => preCount - 1);
            }, 1000);
        } else if (count === 0) {
            // console.log(124, count, '124')
            setIsEmailActive(true)
            clearInterval(timerRef.current);
            // setIsSending(false);
        } else {
            // setIsSending(true);
            setIsEmailActive(false)
        }
    }, [count, isEmailActive]);

    const handlePrivacyPolicy = (type) => {
        if (type === 1) {
            window.open('https://www.smartz.cloud/terms-of-services')
        } else {
            window.open('https://www.smartz.cloud/privacy-policy')
        }
    }

    let _text = lang?.sign?.description_up
    if (_text) {
        _text = _text.replace('{terms_of_service}', lang.note.terms_of_service).replace('{privacy_policy}', lang.note.privacy_policy)
    }

    let _year = lang?.note?.copy;
    if (_year) {
        _year = _year.replace('{year}', (new Date().getFullYear()).toString());
    }

    return (
        <>
            <div className="sign-up">
                <div className='login-logo'>
                    <p>
                        {(_platform === 'zmodo' || _platform === 'zmd') && <img src={mainLogo} alt="" style={{ width: "96px", marginTop: "90px", marginBottom: "70px" }} />}
                        {_platform === 'smartz' && <Icon component={mainLogo} style={{ fontSize: "174px" }} />}
                    </p>
                    <p>{lang?.sign?.welcome}</p>
                </div>
                {current === 1 && <div className='login-con'>
                    <div className='login-form'>
                        <div className='login-form-title'>
                            Sign Up
                        </div>
                        <Form
                            {...layout}
                            form={userform}
                            layout="vertical"
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="" name="username">
                                        <Input onChange={(e) => handleUser(e)} placeholder='Email' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='login-form-password'>
                                    <Form.Item label="" name="password">
                                        <Input.Password minLength={8} onChange={(e) => handleUser(e)} placeholder='Password' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='login-form-password'>
                                    <Form.Item label="" name="rePassword">
                                        <Input.Password minLength={8} onChange={(e) => handleUser(e)} placeholder='Re-enter Password' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <div className='login-form-footer'>
                            {/*<p>By continuing, I agree to Zmodo's <span style={{ textDecoration: "underline" }} onClick={() => handlePrivacyPolicy(1)}>Terms of Service</span> <br />and &nbsp;*/}
                            {/*    <span style={{ textDecoration: "underline" }} onClick={() => handlePrivacyPolicy(2)}>Privacy Policy.</span></p>*/}
                            <p dangerouslySetInnerHTML={{ __html: _text }}></p>
                        </div>
                        <Row>
                            <Col span={24}>
                                <Button onClick={handleNext} className={isActive ? 'login-button-active' : "login-button"}>Next</Button>
                            </Col>
                        </Row>
                        <div className='login-up'>
                            <p>Security Requirements:</p>
                            <p>Password must have more than 8 characters</p>
                            <p>Password must have numbers and letters</p>
                        </div>
                    </div>
                </div>
                }
                {current === 2 && <div className='login-con'>
                    <div className='login-form'>
                        <div className='login-form-title'>
                            Verify Your Email
                        </div>
                        <div className='login-email'>
                            <div>An verification email has been sent to </div>
                            <div>{email}</div>
                            <div style={{ marginTop: "12px" }}>
                                You will need to open the email and tap the link<br />
                                to verify, or enter the code below. If you don't <br />
                                see the email, please check your Spam folder, or<br />
                                tap "Resend Verfication Email"
                            </div>
                            <div className='login-code' style={{ marginTop: "32px" }}>
                                <Form
                                    // {...layout}
                                    form={inputform}
                                    // layout="vertical"
                                    style={{ width: "100%" }}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item label="" name="vcode">
                                                <Input onChange={() => handleInput()} maxLength={6} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {/* <Row gutter={16}>
                                <Col span={4}>
                                    <Form.Item label="" name="vcode1">
                                        <Input onChange={() => handleInput()} maxLength={1}   />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="" name="vcode2">
                                        <Input onChange={() => handleInput()} maxLength={1} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="" name="vcode3">
                                        <Input onChange={() => handleInput()}  maxLength={1}/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="" name="vcode4">
                                        <Input onChange={() => handleInput()}  maxLength={1} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="" name="vcode5">
                                        <Input onChange={() => handleInput()} maxLength={1}/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="" name="vcode6">
                                        <Input onChange={() => handleInput()}  maxLength={1}/>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                                </Form>
                            </div>
                        </div>
                        <Row>
                            <Col span={24}>
                                <Button onClick={handleResendEmail} className={isEmailActive ? 'login-button-active' : "login-button"}>{isEmailActive ? "Resend Verification Email" : `${count}s`}</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                }


            </div>
            <div className='login-footer'>
                <div>
                    <p>{_year}</p>
                </div>
                <div>
                    <p className={'a-link-sty'} dangerouslySetInnerHTML={{ __html: lang?.note?.privacy_policy }}></p>
                    <p className={'a-link-sty'} dangerouslySetInnerHTML={{ __html: lang?.note?.terms_of_service }}></p>
                    <p>Support</p>
                </div>
            </div>
        </>
    )
}

export default Login
