import {Row, Col, Carousel} from 'antd';
import VideoRecording from '../../../../assets/images/cloudPlan/video_recording.svg'
import CloudStorage from '../../../../assets/images/cloudPlan/cloud_storage.svg'
import Flashback from '../../../../assets/images/cloudPlan/flashback.svg'
import Playback from '../../../../assets/images/cloudPlan/Playback.svg'
import BankLevelSecurity from '../../../../assets/images/cloudPlan/bank_level_security.svg'
import ClipMaking from '../../../../assets/images/cloudPlan/clip_making.svg'
import AlPowered from '../../../../assets/images/cloudPlan/al_powered.svg'
import PhotoStorage from '../../../../assets/images/cloudPlan/photo_storage.svg'
import VideoCreation from '../../../../assets/images/cloudPlan/video_creation.svg'
import Sharing from '../../../../assets/images/cloudPlan/Sharing.svg'
import FilePreview from '../../../../assets/images/cloudPlan/file_preview.svg'
import Intelligent from '../../../../assets/images/cloudPlan/intelligent.svg'
import DataSync from '../../../../assets/images/cloudPlan/data_sync.svg'
import CloudBackup from '../../../../assets/images/cloudPlan/cloud_backup.svg'
import SelectActive from '../../../../assets/images/cloudPlan/select_active.svg'
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'
import Banner from '../../../../assets/images/cloudPlan/banner.png'
import NoDevices from '../../../../assets/images/cloudPlan/no_devices.png'
import CVRPlanCardSmartz from "../CVRPlanCardSmartz";
import CVRPlanCardZmodo from "../CVRPlanCardZmodo";
import {getCloudOrderList} from '../../../../server/plan'
import {
	getListCloudPlan,
	getListSubscription,
	getVdrList,
	getInfoSubscription,
	getVdrInfo, checkPFPromo, getPFPromoBanner, getBraintreeOrderList, getDeviceStorageList
} from '../../../../server/cloudPlan'
import dayjs from "dayjs";
import Icon from "@ant-design/icons";
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {
	useCloudSubscribeStore, useDeviceDetailStore,
	useFreeTrialSubscribeStore,
	useOrderDetailStore,
	useVaultSubscribeStore
} from "../../../../store/cloudPlan";
import {getLGToken} from "../../../../utils/common";
import {getPlatfromByUrl} from "../../../../utils/common";

import {
	IconCloudPlan_1,
	IconCloudPlan_2,
	IconCloudPlan_3,
	IconCloudPlan_4,
	IconCloudPlan_5,
	IconCloudPlan_6, IconVaultPlan_1, IconVaultPlan_2, IconVaultPlan_3, IconVaultPlan_4, IconVaultPlan_5, IconVaultPlan_6
} from '../../iconsMap';
import pfFileUrl from "../../../../server/PFFileUrl";

const _platform = getPlatfromByUrl();

const cloudPlanMap = {
	plus: {plan_type: 5, period: 2},
	premium: {plan_type: 5, period: 3},
	platium: {plan_type: 6, period: 3}
}

const orderStatusMap = {
	FREETRIAL: 1,
	ACTIVE: 2,
	UNSUBSCRIBED: 4,
	canceled: 4,
	CANCELED: 4,
	SCHEDULED: 1,
	UNPAID: 7,
}

const cloudPlanNameMap = {
	'1-1': 'Continuous',
	'1-2': 'Continuous',
	'1-3': 'Continuous',
	'2-1': 'Clips',
	'2-2': 'Clips',
	'2-3': 'Clips',
	'3-1': '',
	'3-2': '',
	'3-3': '',
	'4-1': '',
	'4-2': '',
	'4-3': '',
	'5-1': '',
	'5-2': 'Plus',
	'5-3': 'Premium',
	'6-3': 'Platium',
}

const statusTagMap = {
	'-1': 'FREETRIAL',
	0: 'Pedding',
	1: 'Subscribed',
	2: 'Subscribed',
	3: 'Expired',
	4: 'Cancelled',
	5: 'Expired',
	6: '',
	7: 'Billing Issue',
	8: 'Expired'
}

const CloudPlanOne = (props) => {

	const [isBanner, setIsBanner] = useState(true);
	const [cloudPlanList, setCloudPlanList] = useState<any[]>([])
	const [vaultList, setVaultList] = useState<any[]>([])

	const [cloudPlanOrderApp, setCloudPlanOrderApp] = useState<any>([]);
	const [cloudPlanOrderWeb, setCloudPlanOrderWeb] = useState<any>([]);

	const [vaultPlanOrderApp, setVaultPlanOrderApp] = useState<any>([]);
	const [vaultPlanOrderWeb, setVaultPlanOrderWeb] = useState<any>([]);

	const [cloudOrderList, setCloudOrderList] = useState<any>([]);
	const [vaultOrderList, setVaultOrderList] = useState<any>([]);

	const [cloudOrderBRT, setCloudOrderBRT] = useState<any>([]);
	const [vaultOrderBRT, setVaultOrderBRT] = useState<any>([]);

	const [hasFreeTrial, setHasFreeTrial] = useState<boolean>(false); // 拥有试用的机会
	const [cloudPromoInfo, setCloudPromoInfo] = useState<any>({}); // 云服务优惠信息
	const [vaultPromoInfo, setVaultPromoInfo] = useState<any>({}); // Vault服务优惠信息
	const [promoBannerList, setPromoBannerList] = useState<any>([]); // 活动banner

	const {setCloudInfoZU} = useCloudSubscribeStore();
	const {setVaultInfoZU} = useVaultSubscribeStore();
	const {setOrderDetailZU} = useOrderDetailStore();
	const {freeTrialSubscribeZU, setFreeTrialSubscribeZU} = useFreeTrialSubscribeStore();
	const {setDeviceDetailZU} = useDeviceDetailStore();


	const handleBanner = () => {
		setIsBanner(false);
	}

	// 检查是否有试用的机会
	const checkFreeTrialFun = async () => {
		let res:any = await getListCloudPlan();

		if(Array.isArray(res)){
			let _flag = false;
			for(let item of res){
				if(Number(item?.freetrial) === 1){
					_flag = true;
				}
			}
			setHasFreeTrial(() => _flag);
		}
	}

	// 获取内购订单列表
	const initCloudOrderList = async () => {
		let req = {
			token: getLGToken()
		}
		const res: any = await getCloudOrderList(req)
		let list: any[]
		if (res.result === "ok") {
			res.data.appleOrders.map((item: any) => {
				item.type = 1;
				item.order_pf = 'apple';
				item.end_time = dayjs(item.end_time * 1000).format('MM/DD/YYYY')
				return item
			})
			res.data.googleOrders.map((item: any) => {
				item.type = 3;
				item.order_pf = 'google';
				item.end_time = dayjs(item.end_time * 1000).format('MM/DD/YYYY')
				return item
			})

			list = [...res.data.appleOrders, ...res.data.braintreeOrders, ...res.data.googleOrders]

			setCloudPlanOrderApp(() => list);
		}

	}


	// List subscription  订单列表
	const initListSubscription = async () => {
		await setCloudPlanOrderWeb(() => []);
		let list: any[] = []
		const res: any = await getListSubscription()
		let _data = await res.map(async (item: any) => {

			await list.push(item)
			await setCloudPlanList([...list])

			await getWebOrderDetail(item)

			return item
		})

		// setCloudPlanOrderWeb(() => _data);
	}

	const getWebOrderDetail = async (o) => {
		const result: any = await getInfoSubscription(o.id)
		o.price = Number(result.amount_in_cent) / 100
		o.end_time = result.next_payment_day && Number(result.next_payment_day) !== 0 ? dayjs(result.next_payment_day).format('MM/DD/YYYY') : '---';

		o.txt = "Security"

		o.pay_type = Number(result.pay_type);
		o.period = Number(result.period);
		o.plan_type = Number(result.plan_type);
		o.devices_count = Number(result.device_count);

		o.o_status = o.status;
		o.status = (o.status).toUpperCase();
		o.status = orderStatusMap[o.status] ? orderStatusMap[o.status] : o.status;
		o.order_pf = 'web_pay';

		setCloudPlanOrderWeb((prev) => [...prev, o]);
	}

	// 获取网盘内购订单列表
	const getAppVaultOrderList = async () => {
		let req = {
			token: getLGToken(),
			is_vault: 1,
		}
		const res: any = await getCloudOrderList(req)
		let list: any[]

		if (res.result === "ok") {
			res.data.appleOrders.map((item: any) => {
				item.type = 1;
				item.order_pf = 'apple';
				item.end_time = dayjs(item.end_time * 1000).format('MM/DD/YYYY')
				return item
			})
			res.data.googleOrders.map((item: any) => {
				item.type = 3;
				item.order_pf = 'google';
				item.end_time = dayjs(item.end_time * 1000).format('MM/DD/YYYY')
				return item
			})

			list = [...res.data.appleOrders, ...res.data.braintreeOrders, ...res.data.googleOrders]

			setVaultPlanOrderApp(() => list)
		}
	}

	// vault 订单列表
	const initVdrList = async () => {
		let list: any[] = []
		setVaultPlanOrderWeb((prev) => []);
		const res: any = await getVdrList()
		res.map(async (item: any) => {

			await list.push(item)
			await setVaultList([...list])

			await getVaultOrderDetail(item);

			return item
		})

		// setVaultPlanOrderWeb(() => res);
	}

	const getVaultOrderDetail = async (o) => {
		const result: any = await getVdrInfo(o.id);

		o.txt = "Vault"

		o.order_pf = 'web_pay';

		let _obj:any = {};
		if(result){
			_obj = result;
			_obj.price = (Number(result.amount_in_cent) / 100).toFixed(2);
			_obj.end_time = _obj.next_payment_day ? dayjs(_obj.next_payment_day).format('MM/DD/YYYY') : '';

			_obj.o_status = o.status;
			_obj.status = (o.status).toUpperCase();
			_obj.status = orderStatusMap[_obj.status] ? orderStatusMap[_obj.status] : _obj.status;
			_obj.order_pf = 'web_pay';
			_obj.txt = "Vault";
			_obj.id = o.id;

			setVaultPlanOrderWeb((prev) => [...prev, _obj]);
		}

	}

	const getBRTorderListFun = async () => {
		let res:any = await getBraintreeOrderList();
		let {result, data} = res;

		if(result === 'ok' && data){
			let {subscriptions} = data;
			if(Array.isArray(subscriptions)){
				let cvr_order = [];
				let cvr_item:any = {};
				let vault_order = [];
				let vault_item:any = {};

				for(let item of subscriptions){

					if([1000,3000].includes(item.plan)){
						vault_order.push(item);
						vault_item = item;
					}else{
						cvr_order.push(item);
						cvr_item = item;
					}
				}

				if(vault_item.id){
					let vault:any = {
						id: vault_item.id,
						size_type: 0,
						pay_type: vault_item.duration,
						price: vault_item.renewAmount,
						end_time: vault_item.nextPaymentDateString,
						order_pf: 'BRT'
					}

					if(vault_item.plan === 1000){
						vault.size_type = 1;
					}else if(vault_item.plan === 3000){
						vault.size_type = 2;
					}

					//'-1': 'FREETRIAL',
					// 	0: 'Pedding',
					// 	1: 'Subscribed',
					// 	2: 'Subscribed',
					// 	3: 'Expired',
					// 	4: 'Cancelled',
					// 	5: 'Expired',
					// 	6: '',
					// 	7: 'Billing Issue',
					// 	8: 'Expired'

					if([-2,7].includes(vault_item.status)){
						vault.status = 5;
					}else if([1,6,9].includes(vault_item.status)){
						vault.status = 1;
					}else if([-1,7].includes(vault_item.status)){
						vault.status = 4;
					}else if([2,3].includes(vault_item.status)){
						vault.status = 7;
					}

					setVaultOrderBRT(() => [vault])
				}

			}
		}
	}

	const getBRTCvrorderFun = async () => {
		let res:any = await getDeviceStorageList();
		let {data} = res;

		if(Array.isArray(data)){

			let cvr_dev = [];
			for(let item of data){
				if((item.order_type === 'braintree' && [1,3,4,5,6].includes(item.status))){ // || item.status === 1
					if(item.status === 1){
						item.label = 'Free Trial';
					}else if(item.status === 6){
						item.label = 'Billing Issue';
					}else if(item.status === 5){
						item.label = 'Cancelled';
					}else{
						item.label = 'Subscribed';
					}


					let pname = {
						'1-1': 'Premium Plan',
						'1-2': 'Platinum Plan',
						'1-3': '60-Day Cloud Plan',
						'2-1': 'Clips Plan',
						'2-2': 'Clips Plan',
						'2-3': 'Clips Plan',
						'3-1': 'Continuous Plan',
						'3-2': 'Continuous Plan',
						'3-3': 'Continuous Plan',
						'4-1': 'Clips Plan',
						'4-2': 'Clips Plan',
						'4-3': 'Clips Plan',
						'5-1': '---',
						'5-2': 'Plus',
						'5-3': 'Premium',
						'6-1': '---',
						'6-2': '---',
						'6-3': 'Platium',
					}

					let _name = pname[`${item.plan_type}-${item.period}`];

					item.plan_name = `Service: ${_name}`;
					item.plan_desc = `${item.status === 5 ? 'Expires on' : 'Renews on' } ${item.end_time ? dayjs(item.end_time * 1000).format('MM/DD/YYYY') : '---'}`
					cvr_dev.push(item);
				}

				setCloudOrderBRT(() => cvr_dev);
			}
		}
	}

	useEffect(() => {
		initData();
	}, [])

	useEffect(() => {

		let order_1 = []	// 正常有效订单
		let order_7 = []	// 问题订单
		let order_4 = []	// 已取消订单
		let order_b = []	// 失效订单

		for(let item of cloudPlanOrderApp){
			if([1,2].includes(item.status)){
				order_1.push(item)
			}else if([7].includes(item.status)){
				order_7.push(item)
			}else if([4].includes(item.status)){
				order_4.push(item)
			}else{
				order_b.push(item)
			}
		}

		for(let item of cloudPlanOrderWeb){
			if([1,2].includes(item.status)){
				order_1.push(item)
			}else if([7].includes(item.status)){
				order_7.push(item)
			}else if([4].includes(item.status)){
				order_4.push(item)
			}else{
				order_b.push(item)
			}
		}

		setCloudOrderList(() => [...order_1, ...order_7, ...order_4, ...order_b]);

	}, [cloudPlanOrderApp, cloudPlanOrderWeb])

	useEffect(() => {

		let order_1 = []	// 正常有效订单
		let order_7 = []	// 问题订单
		let order_4 = []	// 已取消订单
		let order_b = []	// 失效订单

		for(let item of vaultPlanOrderApp){
			if([1,2].includes(item.status)){
				order_1.push(item)
			}else if([7].includes(item.status)){
				order_7.push(item)
			}else if([4].includes(item.status)){
				order_4.push(item)
			}else{
				order_b.push(item)
			}
		}

		for(let item of vaultPlanOrderWeb){
			if([1,2].includes(item.status)){
				order_1.push(item)
			}else if([7].includes(item.status)){
				order_7.push(item)
			}else if([4].includes(item.status)){
				order_4.push(item)
			}else{
				order_b.push(item)
			}
		}

		for(let item of vaultOrderBRT){
			if([1,2].includes(item.status)){
				order_1.push(item)
			}else if([7].includes(item.status)){
				order_7.push(item)
			}else if([4].includes(item.status)){
				order_4.push(item)
			}else{
				order_b.push(item)
			}
		}

		setVaultOrderList(() => [...order_1, ...order_7, ...order_4, ...order_b]);

	}, [vaultPlanOrderApp, vaultPlanOrderWeb, vaultOrderBRT])

	const initData = async () => {
		await checkFreeTrialFun();

		// 查询活动
		await checkPromoFun();
		// 获取推送的banner
		await getBannerFun();

		// app 订阅的
		await initCloudOrderList();
		await getAppVaultOrderList();

		if(['zmodo', 'zmd'].includes(_platform)){
			// braintree 订阅的
			await getBRTCvrorderFun();
			await getBRTorderListFun();
		}else{
			// stripe 订阅的 主要是smartz
			await initListSubscription()
			await initVdrList()
		}


	}

	const handleViewDetails = (item: any, num: number) => {
		setOrderDetailZU({open: true, data: item})
	}

	const handleSubscribe = (plan_type, period, size_type) => {
		if (size_type !== 0) {
			setVaultInfoZU({open: true, data: {size_type: size_type}})
		} else {
			setCloudInfoZU({open: true, data: {plan_type: plan_type, pay_type: 1, period: period, is_free: hasFreeTrial}})
		}
	}

	const checkPromoFun = async () => {
		let res:any = await checkPFPromo();
		let {result, data} = res;
		if(result === 'ok' && data?.id){
			let {code, price_plan} = data;

			let _cloud:any = {};
			let _vault:any = {};

			if(Array.isArray(price_plan)){
				for(let item of price_plan){
					let _type = Number(item.type);
					let _discount = Number(item.discount);
					let _device_count = Number(item.device_count);

					if(_device_count === 1 && _discount) {

						if ([21].includes(_type)) {  // 月付PLUS
							_cloud.p1 = {o_price: 3.99};
							_cloud.p1.discount = _discount;
							_cloud.p1.price = priceFun(_cloud.p1.o_price, _discount);
						} else if ([22].includes(_type)) {  // 年付PLUS

						} else if ([23].includes(_type)) {  // 月付Premium
							_cloud.p2 = {o_price: 7.99};
							_cloud.p2.discount = _discount;
							_cloud.p2.price = priceFun(_cloud.p2.o_price, _discount);
						} else if ([24].includes(_type)) {  // 年付Premium

						} else if ([25].includes(_type)) {  // 月付Platinum
							_cloud.p3 = {o_price: 14.99};
							_cloud.p3.discount = _discount;
							_cloud.p3.price = priceFun(_cloud.p3.o_price, _discount);
						} else if ([26].includes(_type)) {  // 年付Platinum

						}
					}

					if(_discount){
						// Vault Plan
						if([31].includes(_type)){ // 月付Basic
							_vault.p1 = {o_price: 5.99};
							_vault.p1.discount = _discount;
							_vault.p1.price = priceFun(_vault.p1.o_price, _discount);
						}else if([32].includes(_type)){// 年付Basic

						}else if([33].includes(_type)){// 月付Advanced
							_vault.p2 = {o_price: 15.99};
							_vault.p2.discount = _discount;
							_vault.p2.price = priceFun(_vault.p2.o_price, _discount);
						}else if([34].includes(_type)){// 年付Advanced

						}
					}
				}

				setCloudPromoInfo(() => _cloud);
				setVaultPromoInfo(() => _vault);
			}
		}
	}

	const loadDevIconErrorFun = (e) => {
		e.target.src = '/res/icons/camera-1.png';
	}

	const priceFun = (n, pot) => {
		return (Math.floor(n * (100 - pot))) / 100;
	}

	const getBannerFun = async () => {
		let res:any = await getPFPromoBanner(10,0);
		let {list} = res;

		if(Array.isArray(list)){
			setPromoBannerList(() => list)
		}
	}

	const toFreeTrialFun = () => {
		setFreeTrialSubscribeZU({open: true, data: freeTrialSubscribeZU.data});
	}

	const toDeviceDetailFun = (o) => {
		setDeviceDetailZU({open: true, data: o});
	}

	return <>
		<div className="cloud-plan">
			<div className='cloud-plan-title'>
				Cloud Plan
			</div>
			<div className='cloud-plan-con'>

				{
					promoBannerList.length !== 0 && <div className={'cloud-banner-box'}>
            <Carousel
              arrows={promoBannerList.length > 1}
              autoplay={promoBannerList.length > 1}
              autoplaySpeed={6000}
              adaptiveHeight={true}
            >
							{
								promoBannerList.map((item:any, index:number) => {
									return <div className={'cloud-banner-item'} key={index}>
										<img src={item.img} className={'banner-img'} alt=""/>
									</div>
								})
							}
            </Carousel>
          </div>
				}


        <div className={'cplan-row'}>
					<div className='cloud-plan-con-card'>
						<div className='cloud-plan-con-card-title'>
							<h4>Safeguard Plan</h4>
							<p>Our Safeguard plan ensures the safety of your camera's cloud video recordings, </p>
							<p>providing you with enhanced protection and peace of mind.</p>
						</div>
						<div className='cloud-plan-con-card-feature'>
							<div>
								<p><Icon component={IconCloudPlan_1} style={{fontSize: "22px"}}/></p>
								<p>Video Recording</p>
							</div>
							<div>
								<p><Icon component={IconCloudPlan_2} style={{fontSize: "22px"}}/></p>
								<p>Cloud Storage</p>
							</div>
							<div>
								<p><Icon component={IconCloudPlan_3} style={{fontSize: "22px"}}/></p>
								<p>AI Alerts</p>
							</div>
							<div>
								<p><Icon component={IconCloudPlan_4} style={{fontSize: "22px"}}/></p>
								<p>Rich Notification</p>
							</div>
							<div>
								<p><Icon component={IconCloudPlan_5} style={{fontSize: "22px"}}/></p>
								<p>Clip-Making</p>
							</div>
							<div>
								<p><Icon component={IconCloudPlan_6} style={{fontSize: "22px"}}/></p>
								<p>Bank-level Security</p>
							</div>
						</div>

						{
							['zmodo', 'zmd'].includes(_platform) && <CVRPlanCardZmodo cloudPromoInfo={cloudPromoInfo} handleSubscribe={handleSubscribe} hasFreeTrial={hasFreeTrial} />
						}
						{
							['smartz'].includes(_platform) && <CVRPlanCardSmartz cloudPromoInfo={cloudPromoInfo} handleSubscribe={handleSubscribe} hasFreeTrial={hasFreeTrial} />
						}
						{ ['zmodo', 'zmd'].includes(_platform) && <div className='start-my-free-trial' onClick={toFreeTrialFun}>Start My Free Trial</div>}

					</div>

					<div className='cloud-plan-con-card'>
						<div className='cloud-plan-con-card-title'>
							<h4>Vault Plan</h4>
							<p>Our Vault plan offers secure cloud backup for your important files, ensuring they </p>
							<p>remain safe and accessible from anywhere, at any time.</p>
						</div>
						<div className='cloud-plan-con-card-feature'>
							<div>
								<p><Icon component={IconVaultPlan_1} style={{fontSize: "22px"}}/></p>
								<p>Camera Roll Backup</p>
							</div>
							<div>
								<p><Icon component={IconVaultPlan_2} style={{fontSize: "22px"}}/></p>
								<p>File Storage</p>
							</div>
							<div>
								<p><Icon component={IconVaultPlan_3} style={{fontSize: "22px"}}/></p>
								<p>File Preview</p>
							</div>
							<div>
								<p><Icon component={IconVaultPlan_4} style={{fontSize: "22px"}}/></p>
								<p>Muti-platform Access</p>
							</div>
							<div>
								<p><Icon component={IconVaultPlan_5} style={{fontSize: "22px"}}/></p>
								<p>Sharing</p>
							</div>
							<div>
								<p><Icon component={IconVaultPlan_6} style={{fontSize: "22px"}}/></p>
								<p>Bank-level Security</p>
							</div>
						</div>
						<div className={['smartz'].includes(_platform) ? 'cloud-plan-con-card-con cloud-plan-con-card-con-3 vault-items-box' : 'cloud-plan-con-card-con vault-items-box'} style={{justifyContent: "center"}}>
							<div className='subscribe-card'>
								{vaultPromoInfo?.p1?.discount &&<div className={'a-promo-tag'}>{vaultPromoInfo?.p1?.discount}% Off</div>}
								<div className='subscribe-card-title'>
									Basic
								</div>
								<div className='subscribe-card-con'>
									<p>
										<Icon component={SelectActive} style={{fontSize: "12px"}}/>
										<span>1TB Cloud Storage</span>
									</p>
									<p>
										<Icon component={SelectActive} style={{fontSize: "12px"}}/>
										<span>Transfer Files up to 50 GB</span>
									</p>

								</div>
								{
									vaultPromoInfo?.p1?.discount ?
										<div className='subscribe-starting'>
											Starting at:
											<span className={'o_price'}>${vaultPromoInfo?.p1?.o_price}</span>
											<span>${vaultPromoInfo?.p1?.price}</span>
										</div>
										:
										<div className='subscribe-starting'>
											Starting at: <span>$5.99</span>
										</div>
								}
								<div className='subscribe-card-btn' onClick={() => handleSubscribe(1,1,1)}>
									Subscribe
								</div>
							</div>
							<div className='subscribe-card' style={{marginLeft: "12px"}}>
								{vaultPromoInfo?.p2?.discount &&<div className={'a-promo-tag'}>{vaultPromoInfo?.p2?.discount}% Off</div>}
								<div className='subscribe-card-title'>
									Advanced
								</div>
								<div className='subscribe-card-con'>
									<p>
										<Icon component={SelectActive} style={{fontSize: "12px"}}/>
										<span>3TB Cloud Storage</span>
									</p>
									<p>
										<Icon component={SelectActive} style={{fontSize: "12px"}}/>
										<span>Transfer Files up to 100 GB</span>
									</p>

								</div>
								{
									vaultPromoInfo?.p2?.discount ?
										<div className='subscribe-starting'>
											Starting at:
											<span className={'o_price'}>${vaultPromoInfo?.p2?.o_price}</span>
											<span>${vaultPromoInfo?.p2?.price}</span>
										</div>
										:
										<div className='subscribe-starting'>
											Starting at: <span>$15.99</span>
										</div>
								}
								<div className='subscribe-card-btn' onClick={() => handleSubscribe(1,1,2)}>
									Subscribe
								</div>
							</div>
						</div>
						<p style={{fontSize: '12px', color: '#0550AE', textAlign: 'center', marginTop: '15px', paddingBottom: '5px'}}>Note: The Vault Plan does not support security camera recordings, please use the Safeguard Plan instead.</p>
					</div>
				</div>

				<Row gutter={36}>
					<Col span={12}>

					</Col>
					<Col span={12}>

					</Col>
				</Row>

			</div>
			<div className='my-subscriptions-title'>
				My Subscriptions
			</div>
			<div className='my-subscriptions-con'>
				<Row gutter={24}>
					<Col span={12}>
					{
						cloudOrderList.map((item: any, index: number) => {
							let _tag = statusTagMap[item.status];
							if(item.order_pf === 'web_pay'){
								if(item.status === 1){
									_tag = 'Freetrial';

									if(item.o_status === 'SCHEDULED'){
										_tag = 'Scheduled';
									}
								}
							}

							return <div className='my-subscriptions-card' key={index}>
									<div>
										<h4>Safeguard Plan <span style={{color: '#2F25F7'}}>{item.order_pf}</span></h4>
										<span>{_tag}</span>
									</div>
									<div>
										<p>Safeguard Plan</p>
										<p>
											{cloudPlanNameMap[`${item.plan_type}-${item.period}`]}
											({item.pay_type === 1 ? "Monthly" : "Yearly"})</p>
									</div>
									<div>
										<p>Device Quantity</p>
										<p>{Number(item.devices_count) === 100 ? 'Unlimited' : item.devices_count}</p>
									</div>

								{
									[1,2,7].includes(item.status) ? <>
                    <div>
                      <p>Renewal Price</p>
                      <p>${item.price}</p>
                    </div>
									</>
									:
									<>

									</>
								}

								{
									[1,2,7].includes(item.status) ? <>
											<div>
												<p>Renewal Date</p>
												<p>{item.end_time}</p>
											</div>
										</>
										:
										<>
											<div>
												<p>Expired Date</p>
												<p>{item.end_time}</p>
											</div>
										</>
								}

									<div onClick={() => handleViewDetails(item, 1)}>View Details</div>
								</div>
						})
					}

					{
						cloudOrderBRT.map((item:any, index:number) => {

							return <div className={'cvr-plan-item'} key={index} onClick={() => toDeviceDetailFun(item)}>
								<div className={'cvr-plan-icon'}>
									<img src={pfFileUrl(item.pic_url)} alt="" onError={loadDevIconErrorFun} />
								</div>
								<div className={'cvr-plan-content'}>
									<p className={'cvr-dev-name'}>{item.device_name}</p>
									<p className={'cvr-plan-name'}>{item.plan_name}</p>
									<p className={'cvr-plan-desc'}>{item.plan_desc}</p>
									<span className={'cvr-plan-status'}>{item.label}</span>
								</div>
							</div>
						})
					}
					</Col>

					<Col span={12}>
					{
						vaultOrderList.map((item: any, index: number) => {
							return <div className='my-subscriptions-card' key={index}>
								<div>
									<h4>Vault Plan <span style={{color: '#2F25F7'}}>{item.order_pf}</span></h4>
									<span>{statusTagMap[item.status]}</span>
								</div>
								<div>
									<p>Vault Plan</p>
									<p>
										{Number(item.size_type) === 1 && 'Basic'}
										{Number(item.size_type) === 2 && 'Advanced'}
										({Number(item.pay_type) === 1 ? "Monthly" : "Yearly"})</p>
								</div>
								<div>
									<p>Cloud Storage</p>
									<p>{Number(item.size_type) == 1 ? '1TB' : '3TB'}</p>
								</div>
								{
									[1,2,7].includes(item.status) ? <>
											<div>
												<p>Renewal Price</p>
												<p>${item.price}</p>
											</div>
										</>
										:
										<>

										</>
								}
								{
									[1,2,7].includes(item.status) ? <>
											<div>
												<p>Renewal Date</p>
												<p>{item.end_time}</p>
											</div>
										</>
										:
										<>
											<div>
												<p>Expired Date</p>
												<p>{item.end_time}</p>
											</div>
										</>
								}
								<div onClick={() => handleViewDetails(item, 2)}>View Details</div>
							</div>
						})
					}
				</Col>

					{(
						cloudOrderList.length === 0 &&
						vaultOrderList.length === 0 &&
						cloudOrderBRT.length === 0 &&
						vaultOrderBRT.length === 0
					) &&
          <Col span={12}>
            <div className='no-devices'>
              <p>
                <img src={NoDevices} alt=""/>
              </p>
              <p>There are no subscriptions yet. </p>
            </div>
          </Col>
					}
				</Row>
			</div>
		</div>
	</>
}

export default CloudPlanOne
