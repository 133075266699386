import './index.less'
import {Button, Drawer, Row, Col, message} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'

import SelectActive from '../../../../assets/images/cloudPlan/select_active.svg'
import {getSubscriptionInfo, cancelCloudSubscription, getVdrList, getVdrPlanList} from '../../../../server/cloudPlan'
// import dayjs from "dayjs";
import Icon from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {usePaymentStore, useVaultSubscribeStore} from "../../../../store/cloudPlan";


const VaultSubscribeModal = (props) => {
	const navigate = useNavigate();
	const {open, onOpen, onCurrentPlanObj, onIsCheckOut, targetPlan} = props;

	const [selectPlanInfo, setSelectPlanInfo] = useState<any>({size_type: 1}); // 选择的套餐信息
	const [selectPayType, setSelectPayType] = useState<number>(2);  //选择的支付方式
	const [productListInfo, setProductListInfo] = useState<any>({}); //产品列表信息
	const [displayPriceInfo, setDisplayPriceInfo] = useState<any>({year: 0, month: 0, size: '', name: '', a_year: 0, a_month: 0}); // 选择好之后，显示的价格信息

	const [isChangePlan, setIsChangePlan] = useState<boolean>(false);
	const [isUpgradeOrDowngrade, setIsUpgradeOrDowngrade] = useState<string>(''); //Upgrade  Downgrade
	const [currentPlanKey, setCurrentPlanKey] = useState<string>(''); //当前生效的产品id
	const [currentOrderID, setCurrentOrderID] = useState<string>(''); //当前生效的订单id

	const {vaultInfoZU, setVaultInfoZU} = useVaultSubscribeStore();
	const {setPaymentInfoZU} = usePaymentStore();

	const REF_productList = useRef<any>({});

	const onClose = () => {
		// onOpen(false);
		setVaultInfoZU({open: false, data: vaultInfoZU.data});
	};

	useEffect(() => {
		if(vaultInfoZU.open && vaultInfoZU.data){
			let {pay_type, size_type} = vaultInfoZU.data;
			if(size_type){
				let a = {size_type: Number(size_type)};
				setSelectPlanInfo(() => a);
			}
			if(pay_type){
				let c = Number(pay_type);
				setSelectPayType(() => c);
			}
		}
	}, [vaultInfoZU])

	useEffect(() => {
		if (targetPlan) {
			let _mp = {
				basic: {size_type: 1},
				advanced: {size_type: 2},
			}
			let _ob = _mp[targetPlan];
			if(_ob){
				setSelectPlanInfo(_ob);
			}
		}
	}, [targetPlan])

	useEffect(() => {

		if(open){
			getVaultProductList();
			checkPlanFun();
		}else{

			REF_productList.current = {};
			setCurrentPlanKey('');
		}

	}, [open])


	useEffect(() => {
		let o = {
			year: 0,
			month: 0,
			size: '',
			name: '',
			a_year: 0,
			a_month: 0
		}

		if(JSON.stringify(productListInfo) !== '{}'){
			let pro_key_month = ''; //`VDR_1_${selectPlanInfo.size_type}_1`;
			let pro_key_year = ''; //`VDR_1_${selectPlanInfo.size_type}_2`;

			for(let akey in productListInfo) {
				let item = productListInfo[akey];

				if(item.size_type === selectPlanInfo.size_type){
					if(item.pay_type === 1){
						pro_key_month = akey;
					}else if(item.pay_type === 2){
						pro_key_year = akey;
					}
				}
			}


			let month_pay_plan_info = productListInfo[pro_key_month];
			let year_pay_plan_info = productListInfo[pro_key_year];

			if(month_pay_plan_info && year_pay_plan_info){
				o = {
					year: year_pay_plan_info.price,
					month: month_pay_plan_info.price,
					size: selectPlanInfo.size_type === 1 ? '1TB' : (selectPlanInfo.size_type === 2 ? '3TB' : ''),
					name: selectPlanInfo.size_type === 1 ? 'Basic' : (selectPlanInfo.size_type === 2 ? 'Advanced' : ''),
					a_year:  year_pay_plan_info.promo_price ? year_pay_plan_info.promo_price : 0,
					a_month: month_pay_plan_info.promo_price ? month_pay_plan_info.promo_price : 0,
				}
			}
		}

		setDisplayPriceInfo(() => o);

	}, [selectPlanInfo, productListInfo])

	// 升降级识别
	useEffect(() => {
		// 套餐排序，从低到高
		let plan_arr = [
			'VDR_1_1_1', 'VDR_1_1_2',
			'VDR_1_2_1', 'VDR_1_2_2'
		];

		if(currentPlanKey){
			let _key = `VDR_1_${selectPlanInfo.size_type}_${selectPayType}`
			let _c_index = plan_arr.indexOf(currentPlanKey);
			let _e_index = plan_arr.indexOf(_key);

			let _txt = 'Subscribe';
			if(_c_index > -1 && _e_index > -1){
				if(_c_index < _e_index){ //升级套餐
					_txt = 'Upgrade';
				}else if(_c_index === _e_index){ // 同一套餐
					_txt = 'Subscribe';
				}else{ //降级套餐
					_txt = 'Downgrade';
				}
			}

			setIsUpgradeOrDowngrade(() => _txt);
		}

	}, [currentPlanKey, selectPayType, selectPlanInfo])


	const handlePlan = (pay_type) => {
		setSelectPayType(() => pay_type);
	}

	//1、 获取产品列表
	const getVaultProductList = async () => {
		let res:any = await getVdrPlanList();

		if(Array.isArray(res)){
			let _obj = {};
			let _sku_obj = {};

			for(let item of res){
				let {product_key, price, sku, promo_price} = item;
				let _arr = product_key.split('_');
				if(_arr.length > 3){
					let [akey, plan_type, size_type, pay_type] = _arr;
					item.plan_type = Number(plan_type);
					item.size_type = Number(size_type);
					item.pay_type = Number(pay_type);
					item.price = (price/100).toFixed(2);
					item.sku = sku;
					item.product_key = product_key;

					if(promo_price){
						item.promo_price = (promo_price/100).toFixed(2);
					}

					_obj[product_key] = item;
					_sku_obj[sku] = item;
				}
			}

			setProductListInfo(() => _obj);
			REF_productList.current = _sku_obj;
		}
	}

	const selectPlanFun = (size_type) => {
		let _obj = {size_type};
		setSelectPlanInfo(() => _obj)
	}

	const handleSubscribe = () => {
		let product_key = '';

		for(let akey in productListInfo){
			let item = productListInfo[akey];
			if(item.pay_type === selectPayType && item.size_type === selectPlanInfo.size_type){
				product_key = akey;
			}
		}

		if(product_key){
			let _plan_info = {
				product_key: product_key,
				type: 'vault'
			}

			setPaymentInfoZU({open: true, data: _plan_info});
			onClose();
		}
	}

	const changePlanFun = () => {
		let product_key = '';

		for(let akey in productListInfo){
			let item = productListInfo[akey];
			if(item.pay_type === selectPayType && item.size_type === selectPlanInfo.size_type){
				product_key = akey;
			}
		}

		if(product_key){
			let _plan_info = {
				product_key: product_key,
				type: 'vault',
				is_change_plan: 1,
				id: currentOrderID
			}

			if(currentPlanKey === product_key){
				message.warning("The same plan already exists.")
			}else{
				setPaymentInfoZU({open: true, data: _plan_info});
				onClose();
			}
		}
	}

	// 检查是否有有效订单， 变变更套餐
	const checkPlanFun = async () => {
		let arr = await checkVaultPlanFun();

		let _flag = false;
		let _key = '';
		let _oid = '';

		let _productList = REF_productList.current
		for(let item of arr){
			let {status, description, id} = item;

			if(['ACTIVE'].includes((status).toUpperCase())){ // 购买之后激活的
				_flag = true;
				let p_item = _productList[description];
				if(p_item){
					let {product_key} = p_item;
					if(product_key){
						_key = product_key;
						_oid = id;
					}
				}

			}
		}

		setIsChangePlan(() => _flag);
		setCurrentPlanKey(() => _key);
		setCurrentOrderID(() => _oid);

	}

	const checkVaultPlanFun = async () => {
		let res = await getVdrList();
		return Array.isArray(res) ? res : [];
	}

	return <Drawer title="Basic Drawer" placement="right" width={640} className='security-subscribe-modal-lj'
								 onClose={onClose} open={open}>
		<div className='details-modal-title'>
			<p></p>
			<p>Vault Plan Subscribe</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>
		<div className='set-meal'>
			<div
				className={selectPlanInfo.size_type === 1 ? 'set-meal-l active' : "set-meal-l"}
				onClick={() => selectPlanFun(1)}
			>
				<h4>Basic</h4>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>1TB Cloud Storage</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>Transfer Files up to 50 GB</span>
				</p>
			</div>
			<div
				className={selectPlanInfo.size_type === 2 ? 'set-meal-l active-platium' : "set-meal-l"}
			  onClick={() => selectPlanFun(2)}
			>
				<h4>Advanced</h4>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>3TB Cloud Storage</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>Transfer Files up to 100 GB</span>
				</p>
			</div>
		</div>
		<div className='cloud-features'>
			<div className='cloud-features-title'>Plan Details</div>
			<div className='cloud-features-con'>
				<div className='security-plan'>
					<div className="security-plan-l">
						<p>Plan</p>
						<p>Cloud Storage</p>
					</div>
					<div className="security-plan-r">
						<p>{displayPriceInfo.name}</p>
						<p>{displayPriceInfo.size}</p>
					</div>
				</div>
				<div className='plan-select'>
					<div className={selectPayType === 2 ? 'plan-select-item plan-select-item-active' : 'plan-select-item'}
							 onClick={() => handlePlan(2)}>
						<div className='plan-select-con-l'>
							<p>Annual</p>
						</div>

						{
							displayPriceInfo?.a_year ?
								<div className='plan-select-con-r'>
									<p>
										<span style={{color: '#16ADFD', marginRight: '9px'}}>${displayPriceInfo?.a_year} /year</span>
										<span style={{textDecoration: 'line-through', fontSize: '75%'}}>${displayPriceInfo?.year} /year</span>
									</p>
								</div>
								:
								<div className='plan-select-con-r'>
									<p>${displayPriceInfo.year} /year</p>
									<p>equail to$3.33/mo, <span>saves ${((displayPriceInfo?.month * 12) - displayPriceInfo?.year).toFixed(2)}/yr</span></p>
								</div>
						}


					</div>
					<div className={selectPayType === 1 ? 'plan-select-item plan-select-item-active' : 'plan-select-item'}
							 onClick={() => handlePlan(1)}>
						<div className='plan-select-con-l'>
							<p>Monthly</p>
						</div>

						{
							displayPriceInfo?.a_month ?
								<div className='plan-select-con-r'>
									<p>
										<span style={{color: '#16ADFD', marginRight: '9px'}}>${displayPriceInfo?.a_month} /month</span>
										<span style={{textDecoration: 'line-through', fontSize: '75%'}}>${displayPriceInfo?.month} /month</span>
									</p>
								</div>
								:
								<div className='plan-select-con-r'>
									<p>${displayPriceInfo.month} /month</p>
								</div>
						}


					</div>
				</div>
			</div>
		</div>
		<div className="note">
			<h4>Note:</h4>
			<p className={'a-front-point'}>The Vault Plan does not support security camera recordings, please use the Safeguard Plan instead.</p>
			<p className={'a-front-point'}>The 1TB cloud storage space is 1000GB</p>
		</div>
		<div className='policy'>
			<h4>Policy:</h4>
			<p>By continuing, you agree to our <a href="https://www.smartz.cloud/terms-of-services" target={'_blank'}>Terms of Services</a></p>
		</div>
		<div className='policy'>
			<h4>Renewal Policy:</h4>
			<p>Your subscription will renew automatically until you cancel. <br/>
				You may cancel at any time to avoid future charges.</p>
		</div>
		<div className='footer-button'>
			{
				isChangePlan ?
					<Button onClick={changePlanFun}>{isUpgradeOrDowngrade}</Button>
					:
					<Button onClick={handleSubscribe}>Subscribe</Button>
			}
		</div>
	</Drawer>;
}

export default VaultSubscribeModal
