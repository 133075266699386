import {Button, Popup, ProgressBar, Toast} from 'antd-mobile'
import {useEffect, useRef, useState} from 'react'
import './index.less'
import {useNavigate} from "react-router-dom";
import {getStorageInformation} from "../../../../../server/storage";
import {isFunction, isJSON, sizeFormat} from "../../../../../utils/common";
import jumpPage from "../../../../../utils/jumpPage";
import NAPI from "../../../../../utils/NAPI";

function MeDriveModal(props) {
	const {visible, onVisible, storeInfo} = props
	const [itemNum, setItemNum] = useState<Number>(1)
	const [diskInfo, setDiskInfo] = useState({
		size: 0,
		usable: 0,
		used: 0,
		percent: 0,
		cloud_gallery_id: undefined,
		root_id: 0,
		_total: 0,
		upload_used: 0,
		_size: 0,
		_usable: 0,
		_used: 0,
	})

	const ajaxTimeOut:any = useRef(0);
	const navigate = useNavigate()

	const handleItem = (num: number) => {
		setItemNum(num)
		// onVisible(false)

		if(num === 1){
			if(diskInfo.cloud_gallery_id !== null || diskInfo.cloud_gallery_id !== undefined){
				toPage(`/vault?id=${diskInfo.cloud_gallery_id}`);
			}else{
				toPage('/');
			}
		}else if(num === 2){
			// if(NAPI.check()){
			// 	NAPI.apptodo('openPhotoPage', {})
			// }
			let _cloud_gallery = diskInfo['cloud_gallery'];
			if(isJSON(_cloud_gallery)){
				let _BackupID:any = _cloud_gallery['Backup'];
				_BackupID = Number(_BackupID);
				if(!Number.isNaN(_BackupID)){
					toPage(`/vault/photo?id=${_BackupID}`);
				}
			}
		}else if(num === 3){
			toPage('/vault/favourite');
		}else if(num === 4){
			toPage('/vault/recent');
		}else if(num === 5){
			toPage('/vault/trash');
		}else if(num === 6){
			toPage('/vault/settings');
		}else if(num === 7){
			toPage('/vault/storage')
		}

		if(isFunction(onVisible)){
			onVisible(false);
		}
	}

	const toPage = (_path) => {
		jumpPage(navigate, _path);
	}

	useEffect(() => {
		clearTimeout(ajaxTimeOut.current)
		ajaxTimeOut.current = setTimeout(() => {
			getDiskStorageSize();
		},250);
		return () => {
			clearTimeout(ajaxTimeOut.current)
		}
	}, [])

	const getDiskStorageSize = () => {
		getStorageInformation().then((res:any) => {
			let {data, result} = res;
			if(result === 'ok' && data){
				if(Object.prototype.toString.call(storeInfo) === '[object Function]'){
					storeInfo(() => JSON.parse(JSON.stringify(data)))
				}

				if(isJSON(data)){
					let _totalUseSize = Number(data.used + data.upload_used);

					if(!Number.isNaN(_totalUseSize)){
						_totalUseSize = _totalUseSize * 100;
					}

					data.percent = data.size === 0 ? 0 : _totalUseSize / data.size;
					data._total = sizeFormat(data.used + data.upload_used);
					data._size = `${(sizeFormat(data.size))}`;
					data._usable = `${(sizeFormat(data.usable))}`;
					data._used = `${(sizeFormat(data.used))}`;

					setDiskInfo(() => data);

					//	用户的目录信息
					let user_cloud_disk_info = {
						cloud_gallery: data.cloud_gallery,
						cloud_gallery_id: data.cloud_gallery_id,
						root_id: data.root_id,
						photos_id: data.photos_id,
						backup_id: data.backup_id,
					}

					if(user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id){
						localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
					}
				}
			} else if (result === "401") {
				navigate('/login')
			}
		}).catch(e => {
			console.log(e);
			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
			
		})
	}

	const clickPlan = (e) => {
		e.stopPropagation();

		toPage('/plan');
		// () => toPage('/plan')
		// console.log('clickPlan');
	}

	let _store_percent = diskInfo.percent < 3 ? 3 : diskInfo.percent;
	_store_percent = _store_percent > 100 ? 100 : _store_percent;
	_store_percent = Number(diskInfo.size) === 0 ? 100 : _store_percent;
	let _progress_bar_fill_color = _store_percent < 80 ? 'var(--adm-color-primary)' : (_store_percent < 90 ? 'var(--adm-color-warning)' : 'var(--adm-color-danger)');

	return <Popup
		className="me-drive-modal"
		visible={visible}
		position='top'
		onMaskClick={() => { onVisible(false) }}
		onClose={() => { onVisible(false) }}
	>
		<div className="popup-title">Vault</div>

		<div className={itemNum === 1 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(1)}>
			<img src="/res/icons/icon_cloud_gallery.svg" alt="" style={{width: "22px"}} onClick={() => toPage('/')}/>
			<p onClick={() => toPage('/vault')}>Cloud Gallery</p>
		</div>

		<div className={itemNum === 2 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(2)}>
			<img src="/res/icons/icon_photo.svg" alt="" style={{width: "20px"}}/>
			<p>Photo</p>
		</div>

		<div className={itemNum === 3 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(3)}>
			<img src="/res/icons/icon_favourite.svg" alt="" style={{width: "22px"}}/>
			<p>Favourite</p>
		</div>

		<div className={itemNum === 4 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(4)}>
			<img src="/res/icons/icon_recent.svg" alt="" style={{width: "22px"}}/>
			<p>Recent</p>
		</div>

		<div className={itemNum === 5 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(5)}>
			<img src="/res/icons/icon_trash.svg" alt="" style={{width: "22px"}}/>
			<p>Trash</p>
		</div>

		<div className="underline"></div>

		<div className={itemNum === 6 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(6)}>
			<img src="/res/icons/icon_settings.svg" alt="" style={{width: "22px"}}/>
			<p>Settings</p>
		</div>

		<div className={itemNum === 7 ? "popup-item popup-item-active" : "popup-item"} onClick={() => handleItem(7)}>
			<img src="/res/icons/icon_storage.svg" alt="" style={{width: "22px"}}/>
			<p>Storage</p>
		</div>

		<div className="popup-upgrade">
			<div onClick={() => toPage('/storage')}>
				<ProgressBar percent={_store_percent} style={{"--fill-color": _progress_bar_fill_color}} />
			</div>
			<div className="popup-upgrade-footer">
				<p>{diskInfo._total} of {diskInfo._size} Used</p>
				<Button onClick={clickPlan}>Get more storage</Button>
			</div>
		</div>


	</Popup>
}

export default MeDriveModal
