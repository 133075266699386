import getApiUrl from "../apiUrl";
import requsetData from "../request";

const url = getApiUrl('cloudDisk');

// 获取配置
export function getSettingConfig(){
	return requsetData(`${url}/setting`, {})
}

// 编辑配置
export function setSettingConfig(params={}){
	return requsetData(`${url}/setting`, params, {method: 'post', headers: null})
}
