import './index.less'
import { Button, Drawer, Row, Col } from 'antd';
import React, { useState } from 'react';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'
import Device3 from '../../../../assets/images/cloudPlan/device3.png'
import Unselected from '../../../../assets/images/cloudPlan/unselected.svg'


import Icon from "@ant-design/icons";

const ActivateCloudModal = (props) => {
    const { open, onOpen } = props;

    const onClose = () => {
        onOpen(false);
    };
    return <Drawer title="Basic Drawer" placement="right" width={464} className='details-modal' onClose={onClose} open={open}>
        <div className='details-modal-title'>
            <p></p>
            <p>Activate Cloud</p>
            <p onClick={onClose}>
                <Icon component={CloseIcon} style={{ fontSize: "14px" }} />
            </p>
        </div>
        <div className='clound-plan'>
            <div className='clound-plan-title'>
                 <p>Cloud Plan</p>
                 <p>Plus+Single Camera+Monthly</p>
            </div>
        </div>
        <div className='cloud-device'>
            <div className='cloud-device-title'>
                <p>Select Device(s)</p>
            </div>
            <div className='txt'>You can select (1) device to enable the cloud video recording.</div>
            <div className='cloud-device-card'>
                <div className='cloud-device-card-l'>
                    <p> <img src={Device3} alt="" /></p>
                    <p>Back Yard</p>
                </div>
                <div className='cloud-device-card-r'><Icon component={Unselected} style={{ fontSize: "24px" }} /></div>
            </div>
            <div className='cloud-device-card'>
                <div className='cloud-device-card-l'>
                    <p> <img src={Device3} alt="" /></p>
                    <p>Front Door</p>
                </div>
                <div className='cloud-device-card-r'><Icon component={Unselected} style={{ fontSize: "24px" }} /></div>
            </div>
            <div className='cloud-device-card'>
                <div className='cloud-device-card-l'>
                    <p> <img src={Device3} alt="" /></p>
                    <p>Living Room</p>
                </div>
                <div className='cloud-device-card-r'><Icon component={Unselected} style={{ fontSize: "24px" }} /></div>
            </div>
        </div>
    </Drawer>;
}

export default ActivateCloudModal;