import { Modal, Form, Input, Button } from 'antd';
import './index.less'
import { useRef } from "react";
import { isApple } from "../../../../../utils/common";

function CreateNewFolder(props) {

	const { visible, onVisible, setFolderName, folderList } = props

	const inputElmRef = useRef<any>(null);

	const [form] = Form.useForm()
	const handleAction = async (action: any, index: number) => {
		const { text }: any = action
		if (text === "Cancel") {
			setFolderName(() => '')
			onVisible(() => false)
			form.resetFields()
		} else if (text === "Create") {
			// console.log(await form.validateFields())
			let params: any = await form.validateFields()
			if (params.name) {

				setFolderName(() => params.name)
				onVisible(() => false)
				form.resetFields()
			}
		}

	}

	const handleOk = async () => {
		let params: any = await form.validateFields()
		if (params.name) {

			setFolderName(() => params.name)
			onVisible(() => false)
			form.resetFields()
		}
	}

	const handleCancel = () => {
		setFolderName(() => '')
		onVisible(() => false)
		form.resetFields()
	}

	const errorMessageFunc = (rule, value, callback) => {
		if (value === '') {
			callback('Can not be empty.')
		} else {
			callback('')
		}
	}

	const setFocusFunc = (e) => {
		console.log('setFocusFunc')
		console.log(inputElmRef.current)
		if (isApple() && inputElmRef.current) {
			inputElmRef.current.focus();
		}
	}

	const changeValueFunc = (e) => {
		console.log(e)
	}

	const content = <div className="create-new-folder-con">
		<div className='title'>Create New Folder</div>
		<Form
			// onFinish={onFinish}
			form={form}
		>
			<Form.Item
				name='name'
				label=''
				rules={[
					{ required: true, message: "Can not be empty." },
					// {validator: errorMessageFunc}
				]}
			>
				<Input placeholder='Untitled Folder' ref={inputElmRef} onClick={setFocusFunc} readOnly={false} onFocus={changeValueFunc} />
				{/*<TextArea></TextArea>*/}
			</Form.Item>
			{/* <div className='footer-button'>
          <Button block type='submit' color='primary' size='large'>
            Cancel
            </Button>
          <Button block type='submit' color='primary' size='large'>
            Create
          </Button>
        </div> */}

		</Form>
	</div>

	return <Modal
		title="Basic Modal" open={visible} className='prompt-conter' centered onOk={handleOk} onCancel={handleCancel} footer={null}

	>
		<div className='prompt'>
            Create New Folder
            </div>
			<div className='prompt-con'>
			<Form
				// onFinish={onFinish}
				form={form}
			>
				<Form.Item
					name='name'
					label=''
					rules={[
						{ required: true, message: "Can not be empty." },
					]}
				>
					<Input placeholder='Untitled Folder' ref={inputElmRef} onClick={setFocusFunc} readOnly={false} onFocus={changeValueFunc} />
				</Form.Item>


			</Form>
           </div>
            <div className='prompt-footer'>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleOk}>Create</Button>
            </div>
			
			

	</Modal>
}

export default CreateNewFolder
