import getApiUrl from "../apiUrl";
import service from "../newRequest";
import {errorToLogin, getLGToken} from "../../utils/common";

const url = getApiUrl('cloudDisk');

export async function checkPFToken(){
	let _token = getLGToken();
	let headers = {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${_token}`
	}
	let _url = `${url}/vdr/storage?token=${_token}`;
	if(_token){
		return service.get(`${_url}`, null, headers);
	}else{
		errorToLogin();
		return false;
	}
}
