import { BaseResource } from "../../../utils/resource";
import {ProgressBar, Checkbox, Button, SpinLoading, Toast, PullToRefresh} from "antd-mobile";
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import './index.less'
import HeadNav from "../../../components/headNav";
import {useEffect, useRef, useState} from "react";
import InfoMadal from "../../../components/InfoModal";
import NAPI from "../../../utils/NAPI";
import axios from 'axios'
import token from "../../../utils/token";
import {isJSON, isApple, isAndroid, sizeFormat, isFunction, getLGToken} from "../../../utils/common";
import {planListGPA, planListIAP, subscribeGPA, subscribeIAP, getOrderList} from "../../../server/plan";
import usePlatform from "../../../utils/platform";
import getTokenFromApp from "../../../utils/getTokenFromApp";
import WaitingToken from "../../../components/waitingToken";
import dayjs from "dayjs";
import {getStorageInformation} from "../../../server/storage";
import jumpPage from "../../../utils/jumpPage";
import MeDriveModal from "../meDrive/components/me-drive-modal";

// const icon_popup_a = BaseResource('/res/icons/icon.png');

const storageSizeObj = {
	1: 'Standard (30GB)',
	2: 'Premium (300GB)',
	3: 'Platinum (3000GB)'
}

export default function PlanPage() {
	const [visible, setVisible] = useState<boolean>(false);
	const [paytype, setPaytype] = useState<number>(1); // 1|2
	const [activePlan, setActivePlan] = useState<any>({});
	const [products, setProducts] = useState<any>([]);
	const [loadStatus, setLoadStatus] = useState<boolean>(true);
	const [submitStatus, setSubmitStatus] = useState<boolean>(false);
	// const [token, setToken] = useState<string>('');
	const [tokenStatus, setTokenStatus] = useState<boolean>(false);
	const [currentPlan, setCurrentPlan] = useState<any>({order: {}});
	const [currentBasicPlan, setCurrentBasicPlan] = useState<any>(false);
	const [recommendPlan, setRecommendPlan] = useState<any>('');
	const [isHasCloudDiskServe, setIsHasCloudDiskServe] = useState<boolean>(false);
	const [maxSaveOffSet, setMaxSaveOffSet] = useState<string>('');
	const [diskInfo, setDiskInfo] = useState({
		_size: 0,
		_usable: 0,
		_used: 0,
		size: 0,
		usable: 0,
		used: 0,
		percent: 0,
		cloud_gallery_id: undefined,
		root_id: 0,
		_total: 0,
	})
	const [isMeDrive, setIsMeDrive] = useState<boolean>(false)
	const [storeInfo, setStoreInfo] = useState<any>({});

	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = useSearchParams();
	const platform = usePlatform();
	const reqTimeOutRef:any = useRef(0)

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if(NAPI.check()){
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}


	useEffect(() => {
		clearTimeout(reqTimeOutRef.current)
		reqTimeOutRef.current = setTimeout(() => {
			if(token){
				getDiskStorageSize();
			}
		},200)

	},[token])

	const changePlanTypeFunc = (pay_type) => {
		setPaytype(() => pay_type);
	}

	const choosePlan = (o) => {
		// if(submitStatus) return;
		// setActivePlan(() => o)
	}

	const handleMeDrive = () => {
		setIsMeDrive(() => !isMeDrive)
	}

	const getProducts = () => {
		let req = {
			token: getLGToken(),
			platform: '21',
		}
		// setLoadStatus(() => true);
		let _reqFunc = isApple() ? planListIAP : planListGPA;

		_reqFunc(req).then((res:any) => {

			const {result, data} = res;
			if(result === 'ok' && isJSON(data)){
				let _obj = data;
				let _arr = [];
				if(isJSON(_obj)){
					for(let _key in _obj){
						if(isJSON(_obj[_key])){
							_arr.push({
								..._obj[_key],
								key: _key
							})
						}

					}
				}

				let _max_save_info = 0;
				_arr = _arr.map((item:any) => {
					let _month_price = 0;
					for(let o of _arr){
						if(o.size_type === item.size_type && o.pay_type === 1){
							_month_price = Number(o.price);
						}
					}
					if(item.pay_type === 2){
						let _year_price = Number(item.price);
						let _save_info = 1 - (_year_price / (_month_price * 12))

						if(_save_info > 0 && _save_info < 1){
							_save_info = Math.round(_save_info * 100);

							if(_max_save_info < _save_info){
								_max_save_info = _save_info;
							}

							if(_save_info !== 0){
								item.save_info = `${_save_info}%`
							}
						}
					}

					return item;
				})

				if(_max_save_info > 0){
					setMaxSaveOffSet(() => `${_max_save_info}%`);
				}
				setProducts(() => _arr);
			}
		}).catch(e => {
			console.log(e)
			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		}).finally(() => {
			setTimeout(() => {
				setLoadStatus(() => false);
			},10)
		})
	}

	const orderListFunc = () => {
		try {
		let req = {
			type: isApple() ? 1 : 2,
			token: getLGToken()
		}
		// setLoadStatus(() => true);
		getOrderList(req).then((res:any) => {

			const {result, data} = res;
			if(result === 'ok' && Array.isArray(data)){
				let _item:any = null;
				let _basic_size = 0;
				let _dcs_iap:any = false;
				let _dcs_gpa:any = false;

				for(let item of data){
					let _etime = Number(String(item.end_time).padEnd(13, '000'));
					item.show_time = dayjs(_etime).format('YYYY-MM-DD');
					if((window.location['host']).includes('localhost') || (window.location['host']).includes('storage.meshare.cn')){
						item.show_time = dayjs(_etime).format('YYYY-MM-DD hh:mm:ss');
					}

					if(isJSON(item.order)){
						if([1,2].includes(Number(item.order_type)) && [0,1,3].includes(Number(item.order.status))){
							_basic_size = _basic_size + 3;
						}else if([3,4].includes(Number(item.order_type)) && [0,1,3].includes(Number(item.order.status))){
							_basic_size = _basic_size + 5;
						}else if(Number(item.order_type) === 5){
							_dcs_iap = item;
						}else if(Number(item.order_type) === 6){
							_dcs_gpa = item;
						}
					}

					if((isApple() && Number(item.order_type) === 5) || (isAndroid() && Number(item.order_type) === 6)){
						if(_item === null){
							_item = item;
						}
					}

					if(_basic_size){
						let _currentBasicPlan:any = {}
						_currentBasicPlan.name = `Basic (${_basic_size}GB)`;
						setCurrentBasicPlan(() => _currentBasicPlan);
					}
				}

				if(_item === null){
					_item = {
						order: {}
					};
				}else{

				}
				// console.log(_item, '----------')
				setCurrentPlan(() => _item);
			}

		}).catch(e => {

			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		}).finally(() => {
			// getProducts();

			setTimeout(() => {
				setLoadStatus(() => false);
			},10)
		})
	  } catch(e) {
		 localStorage.clear()
		 navigate("/login")
	  }
	}

	const getDiskStorageSize = async () => {
		let res:any = await getStorageInformation();
		let {data, result} = res;
		if(result === 'ok' && data){

			if(isJSON(data)){
				let _totalUseSize = Number(data.used + data.upload_used);

				if(!Number.isNaN(_totalUseSize)){
					_totalUseSize = _totalUseSize * 100;
				}

				data.percent = data.size === 0 ? 0 : _totalUseSize / data.size;
				data._size = `${(sizeFormat(data.size))}`;
				data._usable = `${(sizeFormat(data.usable))}`;
				data._used = `${(sizeFormat(data.used))}`;
				data._total = sizeFormat(data.used + data.upload_used);

				setDiskInfo(() => data);

				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id,
					backup_id: data.backup_id,
				}

				if(user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id){
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				if(data.root_id){
					setIsHasCloudDiskServe(() => true);
					window['__isHasCloudDiskServe'] = true;
				}
			}

			getProducts();
			orderListFunc();
		} else if(Number(result) === 401){
			navigate('/login')
		}
	}

	const subscribeFunc = (o) => {
			if(submitStatus) return;

			setSubmitStatus(() => true);
			// console.log(o);
			setActivePlan(() => o)

			let req = {
				token: token,
				prd_id: o.key,
				product_id: o.key,
			}

			let _reqFunc = isApple() ? subscribeIAP : subscribeGPA;

			_reqFunc(req).then((res:any) => {
				// Toast.show({
				// 	content: JSON.stringify(res)
				// })

				const {result, data} = res;
				// console.log(res);
				if(result === 'ok' && isJSON(data)){
					let _obj:any = data;
					let _arr = [];

					if(isJSON(_obj)){
						let account_id = _obj.account_id;
						let profile_id = _obj.profile_id;
						let order_id = _obj.order_id;
						let is_up = _obj.hasOwnProperty('is_up') ? parseInt(_obj.is_up) : 0;
						let aToken = _obj.hasOwnProperty('token') ? _obj.token : '';
						let sku_id = _obj.hasOwnProperty('sku_id') ? _obj.sku_id : '';

						if(order_id === false || order_id === 'false' || order_id === 0 || order_id === '0'){
							// Toast.show({
							// 	content: `order_id: ${order_id}`
							// })
						}else{
							try {
								// Toast.show({
								// 	content: 'OK'
								// })


								if(NAPI.check()){
									// if(!NAPI.payment2(token, order_id, o.key, account_id, profile_id, is_up, aToken, sku_id, 'paymentCallback')) {
									// 	Toast.show({
									// 		content: '...'
									// 	})
									// }
									let _data = {
										..._obj,
										product_id: o.key,
										prd_id: o.key,
									}

									// Toast.show({
									// 	content: `#${isHasCloudDiskServe}# ${JSON.stringify(_obj)}`
									// })

									NAPI.apptodo('payment', _data, 'paymentCallback');
								}
							}catch (e) {
								console.log(e)
								let {message}:any = e;
								if(message){
									Toast.show({
										content: message
									})
								}
							}
						}
						}


					// let _keys = Object.keys(data.data);


					console.log(_obj);
					// setProducts(() => _arr);
				}
			}).catch(e => {
				console.log(e)
				let {message} = e;
				if(message){
					Toast.show({
						content: message
					})
				}
			}).finally(() => {
				setTimeout(() => {
					// setSubmitStatus(() => false)
				},600)
			})

	}

	useEffect(() => {
		window['paymentCallback'] = paymentCallback;
	},[])

	const paymentCallback = (result:any) => {
		// Toast.show({
		// 	content: JSON.stringify(result)
		// })
		if(result && true === result) {

			Toast.show({
				content: `Payment Successful`
			})

			// 支付成功之后，重新获取套餐数据
			setSubmitStatus(() => false)
			if(isHasCloudDiskServe || window['__isHasCloudDiskServe']){
				// orderListFunc();
				jumpPage(navigate, '/storage');
			}else{
				jumpPage(navigate, '/vault', {replace: true});
			}
		}else {
			setSubmitStatus(() => false)
			Toast.show({
				content: 'Payment failed'
			})
			if(result === false){

			}else{//result: 0: 未知, 2: 支付已取消, 3: 支付失败
				if(typeof(result) == 'number'){
					if(result == 2){

					}else{

					}
				}else{ //0

				}
			}
		}
	}

	console.log(isHasCloudDiskServe)

	useEffect(() => {
		if(products.length > 0 && diskInfo.root_id){
			recommendedFunc();
		}else{
			// 还没有云服务
			if(diskInfo.root_id === 0){

			}
		}

	}, [diskInfo, products, currentPlan])

	const recommendedFunc = () => {
		// cloudDiskInfo
		// products
		let _g1 = 1024 * 1024 * 1024 * 1; // 1GB
		let _g30 = _g1 * 30;
		let _g300 = _g1 * 300;
		let _g3000 = _g1 * 3000;

		let _size = diskInfo.size;

		let _size_type = 0;

		if(_size < _g3000){
			_size_type = 3;
		}
		if(_size < _g300){
			_size_type = 2;
		}
		if(_size < _g30){
			_size_type = 1;
		}

		let _pay_type = Number(currentPlan.order?.pay_type);
		_pay_type = Number.isNaN(_pay_type) ? 1 : _pay_type

		_pay_type = Number(_pay_type);
		let _recommendPlan:any = null;
		if(_size_type > 0 && Array.isArray(products) && products.length > 0){
			for(let item of products){
				if(Number(item.pay_type) === _pay_type && _size_type === Number(item.size_type)){
					_recommendPlan = item;
				}
			}
		}

		if(_recommendPlan !== null){
			setRecommendPlan(() => _recommendPlan);
		}
	}

	const toServPage = () => {

		if(NAPI.check()){
			NAPI.open('https://www.smartz.cloud/terms-of-services.html')
		}else{
			window.location.href = 'https://www.smartz.cloud/terms-of-services.html'
		}
	}


	const managePlanFunc = () => {
		jumpPage(navigate, '/storage')
	}

	let _order_status = Number(currentPlan.order.status);
	let _store_percent = diskInfo.percent < 3 ? 3 : diskInfo.percent;
	_store_percent = _store_percent > 100 ? 100 : _store_percent;
	_store_percent = diskInfo.size === 0 ? 100 : _store_percent;
	let _progress_bar_fill_color = _store_percent < 80 ? 'var(--adm-color-primary)' : (_store_percent < 90 ? 'var(--adm-color-warning)' : 'var(--adm-color-danger)');

	return <>
		<div className={'page-plan'}>

			{
				(isHasCloudDiskServe) && <div className={'page-head-container'}>
						<HeadNav title={'Subscription'} />
					</div>
			}

			<div className={`page-body-container ${isHasCloudDiskServe ? '':'-no-head'}`}>

				{
					loadStatus ? <div className={'a-loading-bx'}>
							<SpinLoading color='primary' style={{'--size': '32px'}} />
					</div>
					:
					<div>
						{
							// isHasCloudDiskServe ?
							// <div className={'a-title-i'} onClick={() => setVisible(true)}>Get More Storage</div>
							// :
							<div style={{marginBottom: '15px'}}>
								<div className={'a-title-i'} onClick={() => setVisible(true)} style={{marginBottom: '8px'}}>
									Vault Personal <br />
									Storage
								</div>
								<p className={'a-decs-i'}>Store your important memory in the cloud.</p>
							</div>
						}

						<div className={'a-group-btn'}>
							<button
								className={`a-btn-start ${paytype===1?'a-active-btn':''}`}
								onClick={() => changePlanTypeFunc(1)}
							>
								<span className={'a-btn-label'}>Monthly</span>
							</button>
							<button
								className={`a-btn-end ${paytype===2?'a-active-btn':''}`}
								onClick={() => changePlanTypeFunc(2)}
							>
								<span className={'a-btn-label'}>Annual</span>
								{maxSaveOffSet && <span className={'a-btn-intro'}>Save up to ${maxSaveOffSet}</span>}
							</button>
						</div>

						{
							(currentPlan.order.id === undefined || (![0,1,3].includes(_order_status))) && currentBasicPlan && diskInfo.size > 0 && <div className={'a-card-ii'}>
                <div className={'a-button-i'}>
                  <span>Current Plan</span>
                </div>

                <div className={'a-acrd-body'}>
                  <div className={'a-plan-name'}>{currentBasicPlan.name}</div>
                  <div className={'a-progress-bar'}>
                    <ProgressBar percent={diskInfo.percent} style={{"--fill-color": _progress_bar_fill_color}}  />
                  </div>
                  <div className={'a-bar-size'}>{diskInfo._total} of {diskInfo._size} used</div>

									<div className={'a-plan-intro'}>Storage from Video Cloud Plan</div>
                </div>
              </div>
						}

						{
							currentPlan.order.id !== undefined && [0,1,3].includes(_order_status) && <>

                <div className={'a-card-ii'}>
                  <div className={'a-button-i'}>
                    <span>Current Plan</span>
                  </div>

                  <div className={'a-acrd-body'}>
                    <div className={'a-plan-name'}>{storageSizeObj[currentPlan.order.size_type]}</div>
                    <div className={'a-progress-bar'}>
                      <ProgressBar percent={diskInfo.percent} style={{"--fill-color": _progress_bar_fill_color}}  />
                    </div>
                    <div className={'a-bar-size'}>{diskInfo._total} of {diskInfo._size} used</div>

                    <div className={'a-flex-ii'}>
                      <div className={'a-price-con'}>
                        <div className={'a-price-num'}>
                          <span className={'a-price-xiff'}>$</span>
                          <span className={'a-price-nui'}>{currentPlan.order.price}</span>
                          <span className={'a-price-unit'}>{Number(currentPlan.order.pay_type) === 1 ? '/month' : '/year'}</span>
                        </div>

                        <div className={'a-price-desc'}>{[0,1,3].includes(_order_status) ? 'Renew date:' : 'Expire Date:'} {currentPlan.show_time}</div>

                      </div>
                      <div className={'a-btn-con'}>
												{
													[0,1,3].includes(_order_status) && ((isApple() && Number(currentPlan.order_type) === 5) || (isAndroid() && Number(currentPlan.order_type) === 6)) ?
														<button className={'a-default-btn'} onClick={managePlanFunc}>Manage</button>
														:
														null
												}

                      </div>
                    </div>
                  </div>
                </div>

							</>
						}




						{
							products.map((item:any, index: number) => {
								let _element = null

								let _show = (!(Number(currentPlan.order.pay_type) === Number(item.pay_type) && Number(currentPlan.order.size_type) === Number(item.size_type))) || (![0,1,3].includes(_order_status));

								let _recommend = recommendPlan === '' ? false : (Number(recommendPlan.pay_type) === Number(item.pay_type) && Number(recommendPlan.size_type) === Number(item.size_type));

								if(recommendPlan === '' && Number(diskInfo.root_id) === 0){
									_recommend = Number(item.size_type) === 1;
								}

								if(item.pay_type === paytype && _show){
									_element = <div
										className={`a-card-ii a-pad-top a-card-hgt ${_recommend ? 'a-act' : ''}`}
										key={index}
										// onClick={() => choosePlan(item)}
									>
										<div className={'a-acrd-body a-flex-col'}>
											<div className={'a-plan-name'}>{storageSizeObj[item.size_type]}</div>

											<div className={'a-flex-ii'}>
												<div className={'a-price-con'}>
													<div className={'a-price-num'}>
														<span className={'a-price-xiff'}>$</span>
														<span className={'a-price-nui'}>{item.price}</span>
														{
															item.pay_type===1 ? <span className={'a-price-unit'}>/month</span>
																: <span className={'a-price-unit'}>/year</span>
														}
													</div>
													{item.pay_type===2 && item.save_info && <div className={'a-lb-i-note'}>Save {item.save_info} with Annual</div>}
													<div className={'a-price-desc'}>Cancel anytime.</div>
												</div>
												<div className={'a-btn-con'}>
													<Button
														className={(_recommend || activePlan.key === item.key) ? 'a-primary-btn' : 'a-default-btn-i'}
														onClick={() => subscribeFunc(item)}
														loading={activePlan.key === item.key && submitStatus}
														disabled={activePlan.key !== item.key && submitStatus}
														loadingIcon={<SpinLoading color='#ffffff' style={{'--size': '19px'}} />}
													>Subscribe</Button>
												</div>
											</div>
										</div>

										{
											_recommend ? <span className={'a-ps-end'}>
													<img src={'/res/icons/icon_recommend.svg'} alt=""/>
												</span>
												:
												null
										}

									</div>

								}else{
									_element = null
								}

								return _element
							})
						}

						<div className={'a-ft-bot-box'}>
							<p className={'a-lb-title'}>Policy:</p>
							<p className={'a-lb-intro'}>
								By continuing, you agree to our <a onClick={toServPage} >Terms and Conditions</a>.
							</p>

							<p className={'a-lb-title'}>Renewal and cancel policy:</p>
							<p className={'a-lb-intro'}>
								Your subscription will renew automatically until you cancel.
								You may cancel at any time to avoid future charges.
							</p>
						</div>
					</div>
				}

			</div>
		</div>


		{/*弹窗 1*/}

		{/*<InfoMadal*/}
		{/*	visible={visible}*/}
		{/*	content={*/}
		{/*		<div className={'a-modal-container-i'}>*/}
		{/*			<div className={'a-i-body'}>*/}
		{/*				<div className={'a-i-icon'}>*/}
		{/*					<img src={'/res/icons/icon.png'} alt="" style={{width: '100px'}}/>*/}
		{/*				</div>*/}

		{/*				<p className={'a-i-intro'}>*/}
		{/*					If you cancel while using more than*/}
		{/*					<b>5 GB</b> of storage, you’ll notice some*/}
		{/*					restrictions.*/}
		{/*					<br />*/}
		{/*					You can still access all the functionality*/}
		{/*					until <b>August 10, 2023</b>*/}
		{/*					<br />*/}
		{/*					You will be unable to upload new files*/}
		{/*					until you reduce your storage usage to*/}
		{/*					below <b>5 GB</b>. You can still access your*/}
		{/*					current files.*/}
		{/*				</p>*/}
		{/*			</div>*/}
		{/*			<div className={'a-i-foot'}>*/}
		{/*				<span className={'a-btn-cancel'} onClick={() => setVisible(false)}>Cancel Anyway</span>*/}
		{/*				<button>Clean Up meDrive</button>*/}
		{/*			</div>*/}
		{/*		</div>*/}
		{/*	}*/}
		{/*/>*/}


		{/*弹窗2*/}

		{/*<InfoMadal*/}
		{/*	visible={visible}*/}
		{/*	content={*/}
		{/*		<div className={'a-modal-container-i'}>*/}
		{/*			<div className={'a-i-body'}>*/}
		{/*				<div className={'a-i-icon'}>*/}
		{/*					<img src={'/res/icons/icon.png'} alt="" style={{width: '100px'}}/>*/}
		{/*				</div>*/}

		{/*				<p className={'a-i-name'}>Delete 3 item?</p>*/}

		{/*				<p className={'a-i-intro'}>*/}
		{/*					This will permanently delete your files.*/}
		{/*					Collaborators will also lose access.*/}
		{/*				</p>*/}

		{/*				<div className={'a-i-checkbox'}>*/}
		{/*					<Checkbox*/}
		{/*						block={true}*/}
		{/*					>*/}
		{/*						I understand that deleted items can't*/}
		{/*						be recovered.*/}
		{/*					</Checkbox>*/}
		{/*				</div>*/}
		{/*			</div>*/}
		{/*			<div className={'a-i-foot'}>*/}
		{/*				<span className={'a-btn-cancel'} onClick={() => setVisible(false)}>Cancel Anyway</span>*/}
		{/*				<button>Clean Up meDrive</button>*/}
		{/*			</div>*/}
		{/*		</div>*/}
		{/*	}*/}
		{/*/>*/}

		<MeDriveModal
			visible={isMeDrive}
			onVisible={setIsMeDrive}
			storeInfo={setStoreInfo}
		/>


		<WaitingToken status={tokenStatus} change={setTokenStatus} />
	</>
}
