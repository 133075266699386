import NAPI from "../NAPI";
import {Toast} from "antd-mobile";
import Cookies from 'js-cookie';

export function cancelSubscribe(){
	// if(spApplePay()){
	// 	if(NAPI.check()){
	// 		NAPI.open('https://finance-app.itunes.apple.com/account/billing');
	// 	}else{
	// 		location.href = 'https://finance-app.itunes.apple.com/account/billing';
	// 	}
	// 	return;
	// }
	//
	// if(isAndroid() && dev.order_type == 'google'){
	// 	if(NAPI.check()){
	// 		NAPI.open('https://play.google.com/store/paymentmethods');
	// 	}else{
	// 		location.href = 'https://play.google.com/store/paymentmethods';
	// 	}
	// 	return;
	// }
	//
	// if(NAPI.check()){
	// 	NAPI.open('https://apps.apple.com/account/subscriptions');
	// }else{
	// 	location.href = 'https://apps.apple.com/account/subscriptions';
	// }


}

// 支持苹果支付
export function spApplePay(){
	if (-1 < navigator.userAgent.indexOf('ApplePay')) return true;
	return false;
}

// 支持谷歌支付
function spGooglePay() {
	if (-1 < navigator.userAgent.indexOf('GooglePay')) return true;
	return false;
}

// ios设备
export function isApple(){
	var ua = navigator.userAgent;
	if(-1 < ua.indexOf('iPad') || -1 < ua.indexOf('iPhone')) {
		return true;
	}
	return false;
}

// 安卓设备
export function isAndroid(){
	var ua = navigator.userAgent;
	if(-1 < ua.indexOf('Android') || -1 < ua.indexOf('Linux')) {
		return true;
	}
	return false;
}

// 容量大小格式化
export function sizeFormat(n, standard=1024){
	let res = '';
	let _kb = n / standard;
	let _mb = _kb / standard;
	let _gb = _mb / standard;
	let _tb = _gb / standard;

	const innerFunc = (str) => {
		let _arr = str.split('.');
		if(_arr.length === 2){
			if(_arr[1] === '0' || _arr[1] === 0){
				str = _arr[0]
			}
		}
		return str;
	}

	if(_kb < 1024){
		res = `${innerFunc(_kb.toFixed(0))} KB`;
	}else{
		if(_mb < 1024){
			res =  (String(_mb).indexOf('.') !== -1) ?  `${innerFunc(_mb.toFixed(1))} MB` : `${innerFunc(_mb.toFixed(0))} MB`;
		}else{
			if(_gb < 1024){
				res =  (String(_gb).indexOf('.') !== -1) ? `${innerFunc(_gb.toFixed(2))} GB` : `${innerFunc(_gb.toFixed(0))} GB`;
			}else{
				// res = `${innerFunc(_tb.toFixed(1))} TB`;
				res = (String(_gb).indexOf('.') !== -1) ? `${innerFunc(_gb.toFixed(2))} GB` : `${innerFunc(_gb.toFixed(0))} GB`;
			}
		}
	}

	return n === 0 ? 0 : res;
}

// json 格式 转换成 url参数
export function json2params(o){
	let res = '';

	if(isJSON(o)){
		for(let ky of Object.keys(o)){
			if(res === ''){
				res = `${ky}=${o[ky]}`
			}else{
				res = `${res}&${ky}=${o[ky]}`
			}
		}
	}else{
		res = o;
	}

	return res;
}

// 判断是否为json
export function isJSON(o){
	return Object.prototype.toString.call(o) === '[object Object]'
}

// 判断是否为function
export function isFunction(o){
	return Object.prototype.toString.call(o) === '[object Function]' || Object.prototype.toString.call(o) === '[object AsyncFunction]'
}

// 获取基础目录配置信息
export function getUserBasicCloudDisk(){
	let user_cloud_disk_info = localStorage.getItem('user_cloud_disk_info');
	let res = {};
	if(user_cloud_disk_info){
		try{
			res = JSON.parse(user_cloud_disk_info);
		}catch (e) {
			console.log(e);
		}
	}

	return res;
}

// 获取基础目录ID数组
export function getUserBasicFolderIDS(){
	let {cloud_gallery_id, root_id, photos_id, cloud_gallery, backup_id, }:any = getUserBasicCloudDisk();
	let _arr = [];

	if(isJSON(cloud_gallery)){
		_arr = Object.values(cloud_gallery);
	}


    if(backup_id){
		_arr.push(backup_id);
	}

	if(cloud_gallery_id){
		_arr.push(cloud_gallery_id);
	}
	if(root_id){
		_arr.push(root_id);
	}
	if(photos_id){
		_arr.push(photos_id);
	}

	_arr = _arr.map((v) => {
		return Number(v);
	})

	return _arr;
}

// 时间显示格式
export const dateTimeFormat = 'MM/DD/YYYY';

export function setCookie(name, value){
	let _pf = getPlatfromByUrl();
	let hostname = 'smartz.cloud'

	if(_pf === 'zmodo' || _pf === 'zmd'){
		hostname = 'zmodo.com'
	}

	let secure = true;
	if((window.location.hostname).indexOf(hostname) > -1){
		hostname = 'smartz.cloud'

		if(_pf === 'zmodo' || _pf === 'zmd'){
			hostname = 'zmodo.com'
		}
	}else if((window.location.hostname).indexOf('localhost') > -1 || (window.location.hostname).indexOf('127.0.0.1') > -1){
		hostname = window.location.hostname
		secure = false;
	}
	let expires = 180;
	// if(secure){
	// 	Cookies.set(name, value, {domain: hostname, secure: secure, path: '/', expires: expires})
	// }else{
	// 	Cookies.set(name, value, {domain: hostname, path: '/', expires: expires})
	// }
	Cookies.set(name, value, {domain: hostname, secure: secure, path: '/', expires: expires})
}
window['setCookie'] = setCookie;

export function getCookie(name){
	return Cookies.get(name);
}
window['getCookie'] = getCookie;

export function removeCookie(name){
	let _pf = getPlatfromByUrl();
	let _hostname = window.location.hostname;
	let _domain = 'smartz.cloud';

	if(_pf === 'zmodo' || _pf === 'zmd'){
		_domain = 'zmodo.com'
	}

	let secure = true;
	// 2015.1.9
	if(_hostname.indexOf(_domain) > -1){
		_domain = 'smartz.cloud'
		if(_pf === 'zmodo' || _pf === 'zmd'){
			_domain = 'zmodo.com'
		}
	}else if((_hostname).indexOf('localhost') > -1 || (_hostname).indexOf('127.0.0.1') > -1){
		_domain = 'localhost'
		secure = false;
	}
	// Cookies.remove(name);
	Cookies.remove(name, {domain: _domain, secure: secure, path: '/'});
}
window['removeCookie'] = removeCookie;

export function errorToLogin(){
	let _href = encodeURIComponent(window.location.href);
	let _pathname = window.location.pathname;

	removeCookie('token');
	removeCookie('oldPassword')
	removeCookie('accountInfo')
	removeCookie('email');
	removeCookie('username');

	if(_pathname !== '/login'){
		window.location.href = `/login?return_url=${_href}`;
	}else{
		window.location.href = `/login`;
	}
}

export function returnUrl(){
	let _search = new URLSearchParams(window.location.search);
	let _return_url = _search.get('return_url');
	if(_return_url){
		_return_url = decodeURIComponent(_return_url);
		window.location.href = _return_url;
		return true
	}else{
		return false
	}
}

export function getLGToken(){
	return getCookie('token');
}


export function getPlatfromByUrl(){
	let {hostname} = window.location;
	let _ret = 'smartz';
	if(['sz-user.smartzliving.ai', 'user.smartzliving.ai', 'user.smartz.cloud', 'sz-user.smartz.cloud'].includes(hostname)){
		_ret = 'smartz';
	}else if(['user.zmodo.com', 'sz-user.zmodo.com'].includes(hostname)){
		_ret = 'zmd'
	}else if(['user.meshare.com', 'sz-user.meshare.com'].includes(hostname)){
		_ret = 'meshare'
	}else if(['user.funlux.com', 'sz-user.funlux.com'].includes(hostname)){
		_ret = 'funlux'
	}else if(['user.sho.com', 'sz-user.sho.com'].includes(hostname)){
		_ret = 'sho'
	}else if(['ismartalarm.meshare.com', 'sz-ismartalarm.meshare.com'].includes(hostname)){
		_ret = 'ismartalarm'
	}

	return _ret;
}
