import './index.less'
import { BaseResource } from "../../utils/resource";
import { useNavigate } from "react-router-dom";
import { isFunction } from "../../utils/common";
import { Button } from 'antd';
import { useEffect } from 'react';

const BottomOperation = (props) => {
	const { icon, title, start, buttonTxt, todoAction, visible = true, iconState = 0, onIsCreateFolder, errorObj, onErrorObj } = props;
	let _iconUrl = icon ? icon : '/res/icons/icon_back.svg';
	const navigate = useNavigate()

	const backFunc = () => {
		onIsCreateFolder(true)
		// navigate(-1);
	}

	const clickFunc = () => {
		if (isFunction(todoAction)) {
			todoAction()
		}
	}

	const prevFunc = () => {
		if (isFunction(start)) {
			start();
		}
	}

	useEffect(() => {
		setTimeout(() => {
			onErrorObj("")
		}, 1000)
	}, [errorObj])

	console.log(errorObj, '36')

	return <>{
		errorObj !== "" &&  <div className="bottom-operation-txt">
		<p>{errorObj}</p>
	</div>
	}
	   
		{
			visible ? <div className="bottom-operation">
				<div className={'head-nav-start'} onClick={backFunc}>
					{
						iconState === 0 && <>
							{/* <span className={'head-nav-icon'} onClick={backFunc}><img src={BaseResource(_iconUrl)} alt="" /></span> */}
							<Button className={'head-nav-title'}>{title}</Button></>
					}

					{
						iconState === 1 && <img src={BaseResource("/res/icons/icon_detail.svg")} alt="" className="Icon-detail" onClick={prevFunc} />
					}
				</div>
				<div className={'head-nav-end'} onClick={clickFunc}>
					<Button>{buttonTxt}</Button>
				</div>
			</div>
				:
				null
		}
	</>

}

export default BottomOperation
