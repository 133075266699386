import React, {useRef, useEffect, useState} from 'react';
import videojs from 'video.js';

const MyVideoJsPlayer = ({ videoOptions }) => {
	const [playerInstance, setPlayerInstance] = useState(null);

	const videoRef = useRef(null);
	const videoInfoRef = useRef<any>(null);

	const playOnceRef = useRef(0);

	useEffect(() => {

		return () => {
			if (videoInfoRef.current) {
				videoInfoRef.current.dispose();
			}
		}
	}, [])

	useEffect(() => {
		// 确保在卸载组件时清理播放器，避免内存泄漏
		if(videoOptions?._isOK){console.log('990');
			const player = videojs(videoRef.current, videoOptions, function onPlayerReady() {
				// console.log('onPlayerReady', this);

				if(playerInstance){
					changeVideo(videoOptions);
				}

				setTimeout(() => {
					playOnceRef.current = 1;
				}, 200)

			});

			// 设置错误监听
			player.on('error', (event) => {
				console.error('Error in video player:', event);
			});

			setPlayerInstance(player);
			videoInfoRef.current = player;
		}


		// 清理函数
		return () => {
			if (videoInfoRef.current) {
				// player.dispose();
			}
		};
	}, [videoOptions]); // 当videoOptions改变时，重新初始化播放器

	const changeVideo = (newOptions) => {
		if (playerInstance) {
			playerInstance.src(newOptions.sources);
			playerInstance.poster(newOptions.poster);
			playerInstance.ready(() => {

				playerInstance.play();


				playOnceRef.current = 2;
			});
		}
	};

	return (
		<div data-vjs-player>
			<video ref={videoRef} className="video-js" />
		</div>
	);
};

export default MyVideoJsPlayer;

