import './index.less'
import {Button, Drawer, Row, Col, message} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'
import Device3 from '../../../../assets/images/cloudPlan/device3.png'
import DeviceNo from '../../../../assets/images/cloudPlan/device_no.png'
import Unselected from '../../../../assets/images/cloudPlan/unselected.svg'
import Selected from '../../../../assets/images/cloudPlan/selected.svg'
import SafetyTips from '../../../../assets/images/cloudPlan/safety_tips.svg'
import VideoRecording from '../../../../assets/images/cloudPlan/video_recording.svg'
import CloudStorage from '../../../../assets/images/cloudPlan/cloud_storage.svg'
import Flashback from '../../../../assets/images/cloudPlan/flashback.svg'
import BankLevelSecurity from '../../../../assets/images/cloudPlan/bank_level_security.svg'
import ClipMaking from '../../../../assets/images/cloudPlan/clip_making.svg'
import AlPowered from '../../../../assets/images/cloudPlan/al_powered.svg'
import SelectActive from '../../../../assets/images/cloudPlan/select_active.svg'
import {
	getSubscriptionInfo,
	getDeviceStorageList,
	getListCloudPlan,
	getListSubscription,
	checkBundleOrderStatus
} from '../../../../server/cloudPlan'
import Icon from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import _ from 'lodash';
import dayjs from "dayjs";
import pfFileUrl from "../../../../server/PFFileUrl";
import {useCloudSubscribeStore, usePaymentStore} from "../../../../store/cloudPlan";


const SecuritySubscribeModal = (props) => {
	const navigate = useNavigate();
	const {
		open,
		cloudPlandetailsObj,
		planCurrent,
		targetPlan,
	} = props;
	const [planObj, setPlanObj] = useState<any>({})
	const [devicesList, setDevicesList] = useState<any>([
		// { id: "1", device_name: "Back Yard", selected: true },
	])
	const [plan, setPlan] = useState<string>('plus')
	const [num, setNum] = useState<number>(1)
	const [planList, setPlanList] = useState<any>([])
	const [currentPlanInfo, setCurrentPlanInfo] = useState<any>({count: 0, plan: '', year: '', month: ''});

	const [isSubscribe, setIsSubscribe] = useState<boolean>(false)

	const [selectDeviceToBuyArray, setSelectDeviceToBuyArray] = useState<any>([]); // 选择购买的设备数组
	const [selectPlanInfo, setSelectPlanInfo] = useState<any>({plan_type: 5, period: 2}); // 选择的套餐信息
	const [selectPayType, setSelectPayType] = useState<number>(2);  //选择的支付方式
	const [productListInfo, setProductListInfo] = useState<any>({}); //产品列表信息
	const [displayPriceInfo, setDisplayPriceInfo] = useState<any>({year: 0, month: 0, count: 0, a_year: 0, a_month: 0}); // 选择好之后，显示的价格信息

	const [subscribeMessageAlert, setSubscribeMessageAlert] = useState<string>('');
	const [isChangePlan, setIsChangePlan] = useState<boolean>(false);
	const [isUpgradeOrDowngrade, setIsUpgradeOrDowngrade] = useState<string>(''); //Upgrade  Downgrade
	const [currentPlanKey, setCurrentPlanKey] = useState<string>(''); //当前生效的产品id
	const [currentOrderID, setCurrentOrderID] = useState<string>(''); //当前生效的订单id
	const [currentOrderStatus, setCurrentOrderStatus] = useState<string>(''); // 当前订单状态

	const {cloudInfoZU, setCloudInfoZU} = useCloudSubscribeStore();
	const {setPaymentInfoZU} = usePaymentStore();

	const REF_productList = useRef<any>({});

	useEffect(() => {
		// console.log(cloudInfoZU, 'SecuritySubscribeModal');
		if(cloudInfoZU.open){
			setSelectPlanInfo(() => cloudInfoZU.data)
		}

	}, [cloudInfoZU])

	useEffect(() => {

		let _currentPlanInfo: any = _.cloneDeep(currentPlanInfo);
		for (let item of planList) {
			if (num === item.num && plan === item.species) {
				_currentPlanInfo.num = num;
				_currentPlanInfo.plan = plan;
				_currentPlanInfo.product_key = item.product_key
				if (item.time === 'year') {
					_currentPlanInfo.year = Number(item.price) / 100;
				} else if (item.time === 'month') {
					_currentPlanInfo.month = Number(item.price) / 100;
				}

			}
		}

		if (_currentPlanInfo.num && _currentPlanInfo.plan) {

			setCurrentPlanInfo(_currentPlanInfo)
		}

	}, [num, plan, planList])


	useEffect(() => {

		let o:any = {
			year: 0,
			month: 0,
			count: 0,
			a_year: 0,
			a_month: 0
		}

		if(selectDeviceToBuyArray.length){
			let _len = selectDeviceToBuyArray.length > 2 ? 100 : selectDeviceToBuyArray.length;
			let pro_key_month = ''; //`${selectPlanInfo.plan_type}_${selectPlanInfo.period}_1_${_len}`;
			let pro_key_year = ''; //`${selectPlanInfo.plan_type}_${selectPlanInfo.period}_2_${_len}`;

			for(let akey in productListInfo) {
				let item = productListInfo[akey];

				if(item.plan_type === selectPlanInfo.plan_type && item.period === selectPlanInfo.period && item.device_count === _len){
					if(item.pay_type === 1){
						pro_key_month = akey;
					}else if(item.pay_type === 2){
						pro_key_year = akey;
					}
				}
			}

			let month_pay_plan_info = productListInfo[pro_key_month];
			let year_pay_plan_info = productListInfo[pro_key_year];

			if(month_pay_plan_info && year_pay_plan_info){
				o = {
					year: year_pay_plan_info.price,
					month: month_pay_plan_info.price,
					count: _len === 100 ? 'unlimited' : _len,
					a_month: month_pay_plan_info.promo_price ? month_pay_plan_info.promo_price : 0,
					a_year: year_pay_plan_info.promo_price ? year_pay_plan_info.promo_price : 0
				}
			}
		}

		setDisplayPriceInfo(() => o);

	}, [selectDeviceToBuyArray, selectPlanInfo, productListInfo])

	// 升降级识别
	useEffect(() => {
		// 套餐排序，从低到高
		let plan_arr = [
			'5_2_1_1', '5_2_2_1',
			'5_2_1_2', '5_2_2_2',
			'5_2_1_100', '5_2_2_100',
			'5_3_1_1', '5_3_2_1',
			'5_3_1_2', '5_3_2_2',
			'5_3_1_100', '5_3_2_100',
			'6_3_1_1', '6_3_2_1',
			'6_3_1_2', '6_3_2_2',
			'6_3_1_100', '6_3_2_100'
		];

		if(currentPlanKey){
			let _len = selectDeviceToBuyArray.length;
			_len = _len > 2 ? 100 : _len;
			let _key = `${selectPlanInfo.plan_type}_${selectPlanInfo.period}_${selectPayType}_${_len}`;
			let _c_index = plan_arr.indexOf(currentPlanKey);
			let _e_index = plan_arr.indexOf(_key);

			let _txt = 'Subscribe';
			if(_c_index > -1 && _e_index > -1){
				if(_c_index < _e_index){ //升级套餐
					_txt = 'Upgrade';
				}else if(_c_index === _e_index){ // 同一套餐
					_txt = 'Subscribe';
				}else{ //降级套餐
					_txt = 'Downgrade';
				}
			}

			setIsUpgradeOrDowngrade(() => _txt);
		}

	}, [selectPayType, currentPlanKey, selectDeviceToBuyArray, selectPlanInfo])

	// 获取套餐价格数据
	const initListCloudPlan = async () => {
		const res: any = await getListCloudPlan()
		res?.map((item: any) => {
			item.skuList = (item.sku)?.split('_')
			item.species = item.skuList[0]
			item.time = item.skuList[1]
			item.num = Number(item.skuList[2])
			item.device = item.skuList[3]

			return item
		})


		setPlanList(() => res)
	}

	useEffect(() => {
		initListCloudPlan()
	}, [])


	const onClose = () => {
		// onOpen(false);

		setCloudInfoZU({open: false, data: cloudInfoZU.data});
	};

	useEffect(() => {
		if (targetPlan) {
			let _mp = {
				plus: {plan_type: 5, period: 2},
				premium: {plan_type: 5, period: 3},
				platium: {plan_type: 6, period: 3},
			}

			let _ob = _mp[targetPlan];
			if(_ob){
				setSelectPlanInfo(_ob);
			}

		}
	}, [targetPlan])


	const initDatails = async () => {
		let id = cloudPlandetailsObj.id
		const res: any = await getSubscriptionInfo(id);
		res.devices_count = cloudPlandetailsObj?.list?.[2]
		res.price = Number(res.amount_in_cent) / 100
		res.end_time = `${(res.next_payment_day).slice(6, 8)}/${(res.next_payment_day).slice(4, 6)}/${(res.next_payment_day).slice(0, 4)}`
		setPlanObj(res);

	}

	useEffect(() => {
		if (JSON.stringify(cloudPlandetailsObj) !== "{}" && planCurrent === 2) {
			initDatails()
		}
	}, [cloudPlandetailsObj])

	const initDeviceStorageList = async () => {
		const res: any = await getDeviceStorageList()
		if (res.result === "ok") {

			let _selectDeviceToBuyArray = [];

			res.data.map((item: any, index: Number) => {
				item.selected = true
				item.id = index

				if(item.support_cvr == 0){ // 不支持云服务
					item.disabled = true;
				}else{ // 支持云服务

					if(
						([3,4].includes(item.status) && (item.order_type !== 'web_pay'))
						|| ((![5,6].includes(item.plan_type) ) && [3,4].includes(item.status))
					){
						item.disabled = true;
					}else{

						if([3,4].includes(item.status) && (item.order_type === 'web_pay')){
							_selectDeviceToBuyArray.push(item.physical_id);
						}
					}
				}

				return item
			})
			let newIsSubscribe = res.data.some((item: any) => item.selected === true)
			if (newIsSubscribe) {
				setIsSubscribe(() => true)
			}

			setSelectDeviceToBuyArray(() => _selectDeviceToBuyArray);
			setDevicesList(() => res.data)
		}
	}

	useEffect(() => {
		if(open){
			initDeviceStorageList();
			initData();
		}else{
			setSelectDeviceToBuyArray([]);
			setSelectPlanInfo({plan_type: 5, period: 2});
			setSelectPayType(2);
			setProductListInfo({});
			setDisplayPriceInfo({year: 0, month: 0, count: 0, a_year: 0, a_month: 0});

			setSubscribeMessageAlert('');
			REF_productList.current = {};
			setCurrentPlanKey('');
		}

	}, [open])

	const initData = async () => {
		await cloudProductList();
		await checkPlanFun();
	}

	// 获取产品列表
	const cloudProductList = async () => {
		let res:any = await getListCloudPlan();

		if(Array.isArray(res)){
			let _obj = {};
			let _sku_obj = {};

			for(let item of res){
				let {product_key, price, sku, promo_price} = item;
				let _arr = product_key.split('_');
				if(_arr.length > 3){
					let [plan_type, period, pay_type, device_count] = _arr;
					item.plan_type = Number(plan_type);
					item.period = Number(period);
					item.pay_type = Number(pay_type);
					item.device_count = Number(device_count);
					item.price = (price/100).toFixed(2);
					item.sku = sku;
					item.product_key = product_key;

					_obj[product_key] = item;
					_sku_obj[sku] = item;

					if(promo_price){
						item.promo_price = (promo_price/100).toFixed(2);
					}
				}
			}

			setProductListInfo(() => _obj);
			REF_productList.current = _sku_obj;
		}
	}


	const handlePlan = (pay_type:number) => {

		setSelectPayType(() => pay_type);
	}


	const handleSubscribe = () => {
		let _len = selectDeviceToBuyArray.length;
		if (_len > 0) {
			_len = _len > 2 ? 100 : _len;
			let product_key = `${selectPlanInfo.plan_type}_${selectPlanInfo.period}_${selectPayType}_${_len}`

			let _plan_info = {
				devices: selectDeviceToBuyArray.toString(),
				product_key: product_key,
				type: 'cloud',
			}

			setPaymentInfoZU({open: true, data: _plan_info});

			onClose();

		} else {
			message.warning("Please select a device!")
		}

	}

	const changePlanFun = async () => {
		let _len = selectDeviceToBuyArray.length;

		if(currentOrderStatus === 'FREETRIAL'){
			// alert('We could not change your plan currently');
			message.warning("We could not change your plan currently.")
			return;
		}

		if (_len > 0) {
			_len = _len > 2 ? 100 : _len;
			let product_key = `${selectPlanInfo.plan_type}_${selectPlanInfo.period}_${selectPayType}_${_len}`;

			let k_arr = ['5_2_2_100', '5_3_2_100', '6_3_2_100'];  //isUpgradeOrDowngrade === 'Downgrade' &&
			let ke_arr = ['5_2_2_100', '6_3_2_1', '5_3_2_2', '5_3_2_100', '6_3_2_2', '6_3_2_100']; // 可升级的
			let c_index = ke_arr.indexOf(currentPlanKey);
			let s_index = ke_arr.indexOf(product_key);
			// console.log(k_arr.includes(currentPlanKey), s_index, c_index, product_key, currentPlanKey);

			// 原来的套餐是bundle套餐，且现在选的金额比原来低
			if(k_arr.includes(currentPlanKey) && s_index < c_index){
				// 查询是否是bundle可升级套餐
				let res = await checkBundleOrderStatusFun();

				if(res === 1){
					message.warning("We could not change your plan currently.");
					return;
				}
			}


			let _plan_info = {
				devices: selectDeviceToBuyArray.toString(),
				product_key: product_key,
				type: 'cloud',
				is_change_plan: 1,
				id: currentOrderID
			}

			if(currentPlanKey === product_key){
				message.warning("The same plan already exists.")
			}else{
				setPaymentInfoZU({open: true, data: _plan_info});
				onClose();
			}

		} else {
			message.warning("Please select a device!")
		}
	}

	const loadDevIconErrorFun = (e) => {
		e.target.src = '/res/icons/camera-1.png';
	}

	const selectDeviceToBuyFun = (o) => {
		let {physical_id, disabled} = o;

		if(disabled) return;

		let _arr = JSON.parse(JSON.stringify(selectDeviceToBuyArray));
		if(_arr.includes(physical_id)){
			_arr = _arr.filter((v) => v !== physical_id);
		}else{
			_arr.push(physical_id);
		}
		setSelectDeviceToBuyArray(() => _arr);
	}

	const selectPlanFun = (plan_type, period) => {
		let _obj = {plan_type, period};
		setSelectPlanInfo(() => _obj)
	}

	// 检查是否有有效订单， 变变更套餐
	const checkPlanFun = async () => {
		let arr = await checkCloudPlanFun();
		let _msg = '';
		let _flag = false;
		let _key = '';
		let _oid = '';
		let _status = '';

		let _productList = REF_productList.current
		for(let item of arr){
			let {status, description, id} = item;

			if(['ACTIVE', 'FREETRIAL'].includes((status).toUpperCase())){ // 购买之后激活的
				_flag = true;
				let p_item = _productList[description];
				if(p_item){
					let {product_key} = p_item;
					if(product_key){
						_key = product_key;
						_oid = id;
					}
				}

				if(_status !== 'FREETRIAL'){
					_status = (status).toUpperCase();
				}

			}else if(['SCHEDULED'].includes((status).toUpperCase())){ // 增送设备套餐的， 在一年之内不能进行套餐变更
				_msg = 'You have already participated in our Buy Cloud Service Get Device program, and you cannot change your package during the period of time you receive this service.';
			}
		}
		setSubscribeMessageAlert(() => _msg);
		setIsChangePlan(() => _flag);
		setCurrentPlanKey(() => _key);
		setCurrentOrderID(() => _oid);
		setCurrentOrderStatus(() => _status);
	}

	const checkCloudPlanFun = async () => {
		let res = await getListSubscription();
		return Array.isArray(res) ? res : [];
	}

	const checkVaultPlanFun = () => {

	}

	const checkBundleOrderStatusFun = async () => {
		if(currentOrderID){
			let res:any = await checkBundleOrderStatus(currentOrderID);
			return Number(res);
		}else{
			return 0
		}
	}


	return <Drawer title="Basic Drawer" placement="right" width={640} className='security-subscribe-modal'
								 onClose={onClose} open={open}>
		<div className='details-modal-title'>
			<p></p>
			<p>Safeguard Plan Subscribe</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>
		<div className='set-meal'>
			<div
				className={selectPlanInfo.plan_type === 5 && selectPlanInfo.period === 2 ? 'set-meal-l active' : "set-meal-l"}
				onClick={() => selectPlanFun(5,2)}
			>
				<h4>Plus</h4>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>Clips</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>Events history</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>30 Days Storage</span>
				</p>
			</div>

			<div
				className={selectPlanInfo.plan_type === 5 && selectPlanInfo.period === 3 ? 'set-meal-l active-premium' : "set-meal-l"}
				onClick={() => selectPlanFun(5,3)}
			>
				<h4>Premium</h4>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>Clips</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>Events history</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>60 Days Storage</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>300GB bonus Vault</span>
				</p>
			</div>
			<div
				className={selectPlanInfo.plan_type === 6 && selectPlanInfo.period === 3 ? 'set-meal-l active-platium' : "set-meal-l"}
				onClick={() => selectPlanFun(6,3)}
			>
				<h4>Platium</h4>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>Continuous</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>24/7 recording</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>60 Days Storage</span>
				</p>
				<p>
					<Icon component={SelectActive} style={{fontSize: "12px",}}/>
					<span>500GB bonus Vault</span>
				</p>
			</div>
		</div>

		<div className='cloud-device'>
			<div className='cloud-device-title'>
				<p>Select Devices</p>
				{/* <p>Change Device</p> */}
			</div>
			{
				(devicesList || []).map((item: any, index: number) => {
					return <div
						className='cloud-device-card'
						key={index}
						style={{opacity: item.disabled ? 0.65 : 1}}
					>
						<div className='cloud-device-card-l'>
							<div className={'dev-icon'}>
								<img src={pfFileUrl(item.pic_url)} alt="" onError={loadDevIconErrorFun} />
							</div>
							<div className={'dev-desc-box'}>
								<p className={'dev-name'}>{item.device_name}</p>
								{item.status === 0 && <p className={'dev-srv-info'}>Cloud Service Available </p>}
								{
									[1,3,4,7].includes(item.status) &&
									<p className={'dev-srv-info'}>Service: &nbsp;
										{(item?.period === 2 && item?.plan_type === 5) && "Plus"}
										{(item?.period === 3 && item?.plan_type === 5) && "Premium"}
										{(item?.period === 3 && item?.plan_type === 6) && "Platium"}
										({item?.pay_type === 1 ? "Monthly" : "Yearly"})
									</p>
								}
								{
									[1].includes(item.status) &&
                  <p className={'dev-date-info'}>Trial Expired on {dayjs(item.end_time * 1000).format('MM/DD/YYYY')}</p>
								}
								{
									[7].includes(item.status) &&
                	<p className={'dev-date-info'}>Expired on {dayjs(item.end_time * 1000).format('MM/DD/YYYY')}</p>
								}
								{
									[3,4].includes(item.status) && <p>Renews on {dayjs(item.end_time * 1000).format('MM/DD/YYYY')}</p>
								}
							</div>
						</div>

						{
							!item.disabled && <div className='cloud-device-card-r check-icon' onClick={() => selectDeviceToBuyFun(item)}>
                <Icon component={selectDeviceToBuyArray.includes(item.physical_id) ? Selected : Unselected} style={{fontSize: "24px"}}/>
              </div>
						}

						<div className='tag'>
							{item.status === 1 && <span>Trial</span>}
							{item.status === 7 && <span>Expired</span>}
							{(item.status === 3 || item.status === 4) && <span>Subscribed</span>}
							{(item.status === 5) && <span>Cancelled</span>}
						</div>
					</div>
				})
			}

		</div>
		<div className='cloud-features'>
			<div className='cloud-features-title'>Plan Details</div>
			<div className='cloud-features-con'>
				<div className='security-plan'>
					<div className="security-plan-l">
						<p>Safeguard Plan</p>
						<p>Device Quantity</p>
					</div>
					<div className="security-plan-r">
						<p>Safeguard Plan</p>
						<p>{displayPriceInfo.count}</p>
					</div>
				</div>
				<div className='plan-select'>
					<div className={selectPayType === 2 ? 'plan-select-item plan-select-item-active' : 'plan-select-item'}
							 onClick={() => handlePlan(2)}>
						<div className='plan-select-con-l'>
							<p>Annual</p>
						</div>

						{
							displayPriceInfo?.a_year ?
								<div className='plan-select-con-r'>
									<p>
										<span style={{color: '#16ADFD', marginRight: '9px'}}>${displayPriceInfo?.a_year} /year</span>
										<span style={{textDecoration: 'line-through', fontSize: '75%'}}>${displayPriceInfo?.year} /year</span>
									</p>
								</div>
								:
								<div className='plan-select-con-r'>
									<p>${displayPriceInfo?.year} /year</p>
									<p>equail
										to${(displayPriceInfo?.year / 12).toFixed(2)}/mo, <span>saves ${((displayPriceInfo?.month * 12) - displayPriceInfo?.year).toFixed(2)}/yr</span>
									</p>
								</div>
						}

					</div>
					<div className={selectPayType === 1 ? 'plan-select-item plan-select-item-active' : 'plan-select-item'}
							 onClick={() => handlePlan(1)}>
						<div className='plan-select-con-l'>
							<p>Monthly</p>
						</div>
						{
							displayPriceInfo?.a_month ?
								<div className='plan-select-con-r'>
									<p>
										<span style={{color: '#16ADFD', marginRight: '9px'}}>${displayPriceInfo?.a_month} /month</span>
										<span style={{textDecoration: 'line-through', fontSize: '75%'}}>${displayPriceInfo?.month} /month</span>
									</p>
								</div>
								:
								<div className='plan-select-con-r'>
									<p>${displayPriceInfo?.month} /month</p>
								</div>
						}

					</div>
				</div>
			</div>
		</div>
		<div className='policy'>
			<h4>Policy:</h4>
			<p>By continuing, you agree to our <a href="https://www.smartz.cloud/terms-of-services" target={'_blank'}>Terms of Services</a></p>
		</div>
		<div className='policy'>
			<h4>Renewal Policy:</h4>
			<p>Your subscription will renew automatically until you cancel. <br/>
				You may cancel at any time to avoid future charges.</p>
		</div>
		<div className={isSubscribe ? 'footer-button footer-button-active' : 'footer-button'}>
			{
				isChangePlan ?
				<Button onClick={changePlanFun}>{isUpgradeOrDowngrade}</Button>
				:
				<Button onClick={handleSubscribe}>{cloudInfoZU?.data?.is_free ? 'Start Free Trial for 14 Days': 'Subscribe'}</Button>
			}
		</div>
	</Drawer>;
}

export default SecuritySubscribeModal
