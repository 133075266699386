import { Button, Modal, Input, message } from 'antd';
import './index.less';
import { useEffect, useState } from 'react';
import {  getFolder } from '../../../../server/folder';


const CreateFolderModal = (props) => {
    const { visible, onVisible, setFolderName } = props;
    const [name, setName] = useState('')
    const handleOk = async () => {
      if (name) {
				setFolderName(() => name)
				onVisible(() => false)
        setName('');
			}
      };

     
      const handleName = (e) => {
        if (e) {
          setName(e.target.value);
        } else {
          setName('');
        }
      };


      const handleCancel = () => {
        setFolderName(() => '')
        setName(() => "")
			   onVisible(() => false)
      };
    return <Modal title="Basic Modal" open={visible} className='prompt-conter' centered onOk={handleOk} onCancel={handleCancel} footer={null}>
            <div className='prompt'>
            Create New Folder
             </div>
           <div className='prompt-con'>
              <Input value={name} onChange={handleName}/>
           </div>
            <div className='prompt-footer'>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleOk}>Create</Button>
            </div>

  </Modal>
}


export default CreateFolderModal;