import {Modal} from "antd-mobile";
import './index.less'

export default function InfoMadal(props) {
	const {visible,content, onClose} = props;

	return <>
		<Modal
			visible={visible}
			content={content}
			bodyStyle={{width: '90vw', maxWidth: '415px'}}
		/>
	</>
}
