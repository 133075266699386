import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import './index.less';

export default function FilePreviewPage(){
	const [url, setUrl] = useState('');
	const [fileName, setFileName] = useState<string>('');


	let location = useLocation()

	useEffect(() => {
		let baseUrl = 'https://file-view.smartzliving.ai/onlinePreview';

		let {hostname} = window.location;
		let {search} = location;
		let searchObj = new URLSearchParams(search);

		if(hostname === 'localhost' || hostname === 'vault.meshare.cn' || hostname === 'sz-user.smartzliving.ai' || hostname === 'sz-user.smartz.cloud'){
			// baseUrl = 'http://10.101.14.59:8012/onlinePreview';
			// baseUrl = 'https://kkfile.meshare.cn/onlinePreview';
			baseUrl = 'https://file-view-test.smartz.cloud/onlinePreview'
		}

		let pUrl = searchObj.get('url');

		let filename = searchObj.get('filename');

		let _url = `${baseUrl}?url=${pUrl}`;  //&officePreviewType=pdf

		let filetype = searchObj.get('type').toLowerCase();
		if(['doc', 'docx', 'pdf'].includes(filetype)){
			_url = `${_url}&officePreviewType=pdf`
		}
		_url = `${_url}&forceUpdatedCache=true`;

		setUrl(() => _url);
		setFileName(() => filename);


		return () => {
			setUrl(() => '');
			setFileName(() => '');
		}
	}, [])

	return <>
		<div className={'--file-preview-page'}>
			<div className={'--head'}>
				<p className={'--title'}>{fileName}</p>
			</div>
			<div className={'--body'}>
				<iframe
					src={url}
					frameBorder="0"
					className={'--iframe'}
				/>
			</div>
			<div className={'--foot'}></div>
		</div>

	</>
}
