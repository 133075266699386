import './index.less';
import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Popup,
	Dialog,
	AutoCenter,
	Space,
	PullToRefresh,
	DotLoading, Checkbox, Toast, Popover
} from 'antd-mobile'
import { Modal, Input } from 'antd';
import CreateNewFolder from '../components/createFolderModal'
import DetailsModal from '../../../components/details-modal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import token from "../../../utils/token";
import usePlatform from "../../../utils/platform";
import { getFolderContent, createFolder, getFileByUrl, getFileDetails, getFolderDetails } from "../../../server/folder";
import dayjs from "dayjs";
import jumpPage from "../../../utils/jumpPage";
import fileType from "../../../utils/fileType";
import { getLGToken, isApple, isJSON, sizeFormat } from "../../../utils/common";
import NAPI from "../../../utils/NAPI";
import NameModal from "../../../components/name-modal";
import HeadNav from "../../../components/headNav";
import getApiUrl from "../../../server/apiUrl";
// import { DownOutline } from 'antd-mobile-icons';
import ContentItem from "../../../components/content-item";
import { setSettingConfig } from "../../../server/setting";
import { getUserBasicCloudDisk, getUserBasicFolderIDS } from "../../../utils/common";
import { getStorageInformation } from "../../../server/storage";
import getTokenFromApp from "../../../utils/getTokenFromApp";
import LoadDataWaitingState from "../../../components/loadDataWaitingState";
import { getOrderList } from "../../../server/plan";
import { useSwipeable } from "react-swipeable";
import HomeHeadNav from "../../../components/homeHeadNav";
import HeadNavII from "../../../components/headNavII";
import StoragePage from '../Storage'
import MiddlePageModle from './middlePageModle'
import CleanUpMeDrive from '../cleanUpMeDrive'
import FileDetailsModal from "../components/fileDetailsModal";
import fileTypeIcon from "../../../utils/fileTypeIcon";
import Navigation from "../meDrive/components/navigation";


const imageUrl = getApiUrl('cloudDisk');

const icon_folder = '/res/icons/icon_folder_v2.svg';
const icon_folder_sys = '/res/icons/icon_folder.svg';

let keysObj = {
	'name': 'Name',
	'updateTime': 'Last Modified',
	'size': 'Storage Used'
}

const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
	return (
		<>
			{hasMore ? (
				<>
					<span>Loading</span>
					<DotLoading />
				</>
			) : (
				// <span>--- End ---</span>
				<></>
			)}
		</>
	)
}

const g_cloud_disk_min_size = 1024 * 1024 * 300; // 300M

function MiddlePage() {
	const navigate = useNavigate()
	const [isShow, setIsShow] = useState<any>(undefined)
	const [visible, setVisible] = useState<boolean>(false)
	const [itemNum, setItemNum] = useState<Number>(1)
	const [isFolder, setIsFolder] = useState<boolean>(false)
	const [isSortBy, setIsSortBy] = useState<boolean>(false)
	const [isMeDrive, setIsMeDrive] = useState<boolean>(false)
	const [isPause, setIsPause] = useState<boolean>(false)
	const [isUpgradeStorage, setIsUpgradeStorage] = useState<boolean>(false)
	const [openItemDetails, setOpenItemDetails] = useState<boolean>(false)
	const [detailsObj, setDetailsObj] = useState<any>({})
	const [orderExpired, setOrderExpired] = useState<boolean>(false);

	const [folderList, setFolderList] = useState<any>([]); //  文件夹列表
	const [fileList, setFileList] = useState<any>([]); //  文件列表
	const [addFolderName, setAddFolderName] = useState<any>(''); //  新建文件夹的名称
	const [currentListFolderPid, setCurrentListFolderPid] = useState<any>(0); // 当前列表的pid
	// const [activeID, setActiveID] = useState<any>(-1); // 操作更多时的文件夹或者文件ID
	const [pageInfo, setPageInfo] = useState<any>({
		page: 0,
		pageSize: 10 * 1.5,
		orderBy: 'name', //name, updateTime, size, types
		order: 'asc', //Asc:顺序 Desc:逆序
	})

	const [onePageData, setOnePageData] = useState<any>({
		number_of_elements: 0,
		page_number: 0,
		total_elements: 0,
		total_pages: 0,
	}); // 文件分也请求数据
	const [loadMoreFlag, setLoadMoreFlag] = useState<boolean>(false);
	const [sortRequst, setSortRequst] = useState<boolean>(false);
	const [storeInfo, setStoreInfo] = useState<any>({});
	const [currentFolderInfo, setCurrentFolderInfo] = useState<any>({ name: '' })
	const [enterLongPressEvent, setEnterLongPressEvent] = useState<boolean>(false);  // 进入长按事件
	const [userBasicCloudDisk] = useState(getUserBasicCloudDisk())
	const [folderSelectList, setFolderSelectList] = useState<any>([]);
	const [fileSelectList, setFileSelectList] = useState<any>([]);
	const [basicFolderSelectList, setBasicFolderSelectList] = useState<any>([]);
	const [loadingMainData, setLoadingMainData] = useState<number>(0); // 0: 还没请求数据， 1：请求中， 2：请求结束
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})
	const [orderList, setOrderList] = useState<any>([]);
	const [noteText, setNoteText] = useState<any>(null);
	const [slidingDirection, setSlidingDirection] = useState<string>('');

	const [noticeAutoSave, setNoticeAutoSave] = useState<boolean>(false);
	const [noticeDeleteFile30, setNoticeDeleteFile30] = useState<boolean>(false);
	const [noticeDeleteFile90, setNoticeDeleteFile90] = useState<boolean>(false);
	const [noticeNotEnoughSpaceUpgrade, setNoticeNotEnoughSpaceUpgrade] = useState<boolean>(false);
	const [noticeNotEnoughSpaceClearUp, setNoticeNotEnoughSpaceClearUp] = useState<boolean>(false);

	const [systemFolders, setSystemFolders] = useState<any>([]);

	const parmas = useParams();
	const location = useLocation();
	const pf = usePlatform();
	const timeOutRef: any = useRef(0);
	const reqTimeceRef: any = useRef(true);
	const reqPageRef = useRef(-1);
	const pullPageFlag = useRef(false); // 下拉刷新标志
	const currentReqFolderID = useRef(-1);  //当前请求的ID
	const containRef: any = useRef<any>(false)

	const changeFolderRef = useRef<number>(0); // 目录改变状态 0：初始， 1：请求渲染页面的数据中，2：请求渲染页面的数据结束
	const isChildPageRef = useRef(false); // 是子目录
	const [isStorage, setIsStorage] = useState<any>(false);
	const [isFileDetailsModal, setIsFileDetailsModal] = useState<any>(false)
	const [inputVal, setInputVal] = useState<string>("")
	const [isSearch, setIsSearch] = useState<boolean>(false)
	const [isCopyDrawer, setIsCopyDrawer] = useState<boolean>(false)
	const [searchVal, setSearchVal] = useState<string>("")
	const [isCleanUpMeDrive, setIsCleanUpMeDrive] = useState<boolean>(false)
	const [isNavigation, setIsNavigation] = useState<boolean>(true);

	useEffect(() => {
		window['tokenCallback'] = tokenCallback;

		// getSessionDirData();
	}, [])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if (NAPI.check()) {
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	useEffect(() => {
		let searchParams = new URLSearchParams(location.search);
		let _id: any = searchParams.get('id');

		isChildPageRef.current = Boolean(_id);

		if (_id === null || _id === undefined) {
			_id = 0;
		}

		if (_id === 0) {
			// 页面请求信息初始化
			resetPageInfo();
		}

		setCurrentListFolderPid(() => _id);
		setFileList(() => []);
		setFolderList(() => []);
		setFolderSelectList(() => []);
		setFileSelectList(() => []);
		setBasicFolderSelectList(() => []);
		setLoadingMainData(() => 0);
		setSlidingDirection(() => '');

		changeFolderRef.current = 0;
	}, [])

	// useEffect(() => {
	// 	// 页面请求信息初始化
	// 	resetPageInfo();

	// 	clearTimeout(timeOutRef.current);
	// 	timeOutRef.current = setTimeout(() => {
	// 		// getPageData(currentListFolderPid);
	// 		initPageData();

	// 		if (currentListFolderPid) {
	// 			getDetail();
	// 		} else {
	// 			setCurrentFolderInfo({ name: '' })
	// 		}
	// 	}, 250);

	// 	return () => {
	// 		clearTimeout(timeOutRef.current);
	// 	}
	// }, [currentListFolderPid])

	// useEffect(() => {
	// 	    resetPageInfo();
	// 		clearTimeout(timeOutRef.current);
	// 		timeOutRef.current = setTimeout(() => {
	// 			initPageData();
	// 		}, 250);

	// 		return () => {
	// 			clearTimeout(timeOutRef.current);
	// 		}
	// }, [])

	// 搜索
	// useEffect(() => {
	// 		resetPageInfo();

	// 		clearTimeout(timeOutRef.current);
	// 		timeOutRef.current = setTimeout(() => {
	// 			initPageData();
	// 		}, 250);

	// 		return () => {
	// 			clearTimeout(timeOutRef.current);
	// 		}
	// }, [searchVal])

	// 数据初始化
	// const initPageData = async () => {
	// 	currentReqFolderID.current = currentListFolderPid;
	// 	setLoadingMainData(() => 1);
	// 	changeFolderRef.current = 1;
	// 	await getDiskStorageSize();
	// 	await getOrderListFunc();
	// 	let _arr = getUserBasicFolderIDS();
	// 	setSystemFolders(() => _arr);
	// 	// getPageData(currentListFolderPid, 0,  0, searchVal);
	// }

	// const getSessionDirData = () => {
	// 	try {
	// 		let vault_sysdir_info = sessionStorage.getItem('vault-sysdir-info');
	// 		let _2json = JSON.parse(vault_sysdir_info);
	// 		if (isJSON(_2json)) {
	// 			let _datetime = dayjs().format('YYYYMMDDHH');
	// 			// if(_datetime == _2json._datetime){
	// 			// 	// setDiskInfo(() => _2json);
	// 			// }
	// 			setStoreInfo(() => _2json);
	// 		}
	// 	} catch (e) {
	// 		console.log(e)
	// 	}
	// }

	const getOrderListFunc = async () => {
		try {
			let req = {
				token: getLGToken(),
			}
			let res: any = await getOrderList(req);
			const { result, data } = res;
			if (result === 'ok' && Array.isArray(data)) {
				let _order_valid = false;
				let _basic_size = 0;
				let _dcs_iap: any = false;
				let _dcs_gpa: any = false;

				for (let item of data) {
					if (Number(item.flag) === 1 && (!_order_valid)) {
						_order_valid = true;
					}

					if (isJSON(item.order)) {
						if ([1, 2].includes(Number(item.order_type)) && [0, 1, 3].includes(Number(item.order.status))) {
							_basic_size = _basic_size + 3;
						} else if ([3, 4].includes(Number(item.order_type)) && [0, 1, 3].includes(Number(item.order.status))) {
							_basic_size = _basic_size + 5;
						} else if (Number(item.order_type) === 5) {
							_dcs_iap = item;
						} else if (Number(item.order_type) === 6) {
							_dcs_gpa = item;
						}
					}
				}

				setOrderList(() => data);
			}
		} catch (error) {
			localStorage.clear()
			navigate("/login")
		}

	}

	const getDiskStorageSize = async () => {
		let res: any = await getStorageInformation();
		let { data, result } = res;
		if (result === 'ok' && data) {
			if (isJSON(data)) {
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id,
					backup_id: data.backup_id,
				}

				if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data)
			}
		} else if (result === "401") {
			navigate('/login')
		}
	}

	useEffect(() => {
		getDiskStorageSize();
		getPageData();

	}, [])


	// 获取页面数据
	const getPageData = async () => {
		let req: any = {
			page: 0,
			pageSize: 15,
			orderBy: "name",
			order: "asc",
			dirId: 0
		}

		// if (key) {
		// 	req = {
		// 		...pageInfo,
		// 		dirId: dirId,
		// 		page: _page,
		// 		key
		// 	}
		// } else {
		// 	req = {
		// 		...pageInfo,
		// 		dirId: dirId,
		// 		page: _page
		// 	}
		// }
		let res = await getFolderContent(req);
		reqPageRef.current = -1;
		const token = getLGToken()
		let { data, result }: any = res;
		if (result === 'ok' && data) {
			if (isJSON(data)) {
				let _onePageData = {
					number_of_elements: data['files']['number_of_elements'],
					page_number: data['files']['page_number'],
					total_elements: data['files']['total_elements'],
					total_pages: data['files']['total_pages'],
				}

				let files: any = data['files']['content']; //文件

				files.map((item: any) => {
					let _lb = item.create_at === item.update_at ? 'Created at' : 'Modified at';
					let _dt = dayjs(item.update_at).format("MM/DD/YYYY");
					item.createdTime =  dayjs(item.create_at).format('MM/DD/YYYY hh:mm A')
					item.modifiedTime = dayjs(item.update_at).format('MM/DD/YYYY hh:mm A')


					let _lb2 = 'Storage used:';
					let _dt2 = sizeFormat(item.size);

					item.icon = fileType[item.type]['icon'];
					if (item.type === 3 && item.cover && Number(item.status) !== 0) {
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 1 && item.cover && Number(item.status) !== 0) {
						item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
					} else if (item.type === 2 && Number(item.status) !== 0) {
						item.icon = fileTypeIcon[item.ext];
					}

					if (pageInfo.orderBy === 'size') { }
					item.content = pageInfo.orderBy === 'size' ? `${_lb2} ${_dt2}` : `${_lb} ${_dt}`;

					return item;
				})

				// 第一次加载的时候， 添加文件夹数据， 添加文件数据
				if (data['files']['page_number'] === 0) {
					let dirs: any = data['dirs']; // 文件夹

					let _arr = getUserBasicFolderIDS();

					dirs.map((item: any) => {
						let _lb = item.create_at === item.update_at ? 'Created at' : 'Modified at';
						let _dt = dayjs(item.update_at).format("MM/DD/YYYY");
						item.createdTime =  dayjs(item.create_at).format('MM/DD/YYYY hh:mm A')
						item.modifiedTime = dayjs(item.update_at).format('MM/DD/YYYY hh:mm A')

						item.icon = icon_folder;
						item.content = `${_lb} ${_dt}`;

						if (_arr.includes(item.id)) {
							// item.disabled = true;
							item.system_dir = true;
							item.icon = icon_folder_sys;
						}

						return item;
					})

					let _dir_arr = []
					for (let o of dirs) {
						if (o.system_dir) {
							_dir_arr.unshift(o)
						} else {
							_dir_arr.push(o);
						}
					}

					setFolderList(() => _dir_arr);
					setFileList(() => files);

					setTimeout(() => {
						setLoadingMainData(() => 2);
						changeFolderRef.current = 2;
					}, 200)
				} else { // 文件数据追加， 处理分页数据
					setFileList((prevList) => prevList.concat(files));
				}

				// 已加载完成
				if (_onePageData.page_number + 1 >= _onePageData.total_pages || _onePageData.total_pages === 0) {

				} else {
					// getPageData(dirId, _page + 1, 0, searchVal)
				}

				setSortRequst(() => false);
			}
		}
	}

	useEffect(() => {
		let _orderList = JSON.parse(JSON.stringify(orderList));
		let _order_valid = false;
		let _basic_is_end = 0;
		let _vault_is_end = 0;
		let _basic_is_not_end = 0;
		let _vault_is_not_end = 0;
		let _order_list = [];

		for (let item of _orderList) {
			if (Number(item.flag) === 1 && (!_order_valid)) {
				_order_valid = true;
			}

			if (isJSON(item.order)) {
				if ([1, 2, 3, 4].includes(Number(item.order_type))) {
					if ([2, 4, 5, 6].includes(Number(item.order.status))) {
						_basic_is_end = 1;
						_order_list.push(item);
					} else {
						_basic_is_not_end = 1;
					}

				} else if ([5, 6].includes(Number(item.order_type))) {
					if ([2, 4, 5, 6].includes(Number(item.order.status))) {
						_vault_is_end = 1;
						_order_list.push(item);
					} else {
						_vault_is_not_end = 1;
					}

				}
			}
		}

		let _noteText: any = null;
		let _end_date = 0;
		let _is_free_order = 0;

		for (let item of _order_list) {

			if (_end_date < Number(item.end_time)) {
				_end_date = Number(item.end_time);

				if (isJSON(item.order)) {
					_is_free_order = Number(item.order.use_free);
				}
			}
		}


		if ((_basic_is_end === 1 || _vault_is_end === 1) && (_vault_is_not_end === 0 && _basic_is_not_end === 0)) {
			if (cloudDiskInfo.usable < 0 || cloudDiskInfo.usable === 0) {
				let _end_month = _is_free_order === 1 ? 1 : 6;
				let _date_str = dayjs(Number(String(_end_date).padEnd(13, '0'))).add(_end_month, "months").format('DD-MM-YYYY');
				_noteText = <p>
					Your Files Will Expire Soon <br />
					Please back up your
					Vault files or <span onClick={() => toPageFunc('plan')}>restore your Vault subscription</span> in time to
					resume the full access and avoid files being permanently
					deleted on {_date_str}
				</p>

				setNoteText(() => _noteText);
			}
		} else if (_vault_is_not_end !== 0 || _basic_is_not_end !== 0) {

		}

		// _noteText = <p>
		// 	Your Files Will Expire Soon Please back up your
		// 	Vault files or <span onClick={() => toPageFunc('plan')}>restore your Vault subscription</span> in time to
		// 	resume the full access and avoid files being permanently
		// 	deleted on {'_date_str'} and
		// </p>

		setNoteText(() => _noteText);

	}, [])

	// 新建文件夹
	useEffect(() => {
		if (addFolderName !== '') {
			createFolderFunc(currentListFolderPid, addFolderName);
		}
	}, [])

	const createFolderFunc = (pid, name) => {
		let req = {
			pid: pid,
			name: name
		}

		createFolder(req).then((res: any) => {
			// setAddFolderName
			let { result, data } = res;
			if (result === 'ok' && data) {
				setAddFolderName(() => '');
				if (typeof data === 'number') {
					openFolder(null, { id: data, type: undefined })
				} else {
					// initPageData()
				}

			} else {
				if (res?.error) {
					Dialog.alert({
						content: <AutoCenter style={{ textAlign: "center" }}>{res?.error}</AutoCenter>,
						destroyOnClose: true,
						confirmText: 'OK'
					})
				}
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	const getDetail = () => {
		// getFileDetails
		getFolderDetails(currentListFolderPid).then((res: any) => {
			const { data, result } = res;
			if (data && result === 'ok') {
				setCurrentFolderInfo(() => data);
			}
		}).catch(e => {
			console.log(e);
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	// 打开文件夹
	const openFolder = (e, o) => {
		setCurrentFolderInfo(() => o);
		setFileList(() => []);
		setFolderList(() => []);
		jumpPage(navigate, `/vault?id=${o.id}&type=${o.type}`);
	}

	const handleMeDrive = () => {
		setIsMeDrive(() => !isMeDrive)
	}

	const handleList = () => {
		let _isShow = isShow === undefined ? false : isShow;
		setIsShow(() => !isShow)
	}
	const handlePopup = () => {
		setVisible(() => !visible)
	}

	const handleSortBy = () => {
		setIsSortBy(() => !isSortBy)
	}

	const handleItem = (num: number) => {
		console.log(num, '590')
		if (Number(storeInfo.size) === 0 || Number(storeInfo.usable) < 0) {
			let _content = 'Access restricted';
			if (Number(storeInfo.size) > 0 && Number(storeInfo.usable) < 0) {
				_content = 'Not enough space'
			}
			// Modal.alert({
			// 	title: 'Tips',
			// 	content: _content,
			// 	destroyOnClose: true,
			// 	confirmText: 'OK',
			// })
			// 2024-09-18
			// const _show_modal = Modal.show({
			// 	title: 'Tips',
			// 	content: _content,
			// 	closeOnAction: !true,
			// 	actions: [
			// 		{
			// 			key: 'submit',
			// 			text: 'Restore now',
			// 			primary: true,
			// 		},
			// 		{
			// 			key: 'close',
			// 			text: 'Later',
			// 		},
			// 	],
			// 	onAction: ({ key }) => {
			// 		if (key === 'close') {
			// 			_show_modal.close();
			// 		} else if (key === 'submit') {
			// 			jumpPage(navigate, `/plan`);
			// 			_show_modal.close();
			// 		}
			// 	}
			// })

		} else {

			if (num === 3) {
				setIsFolder(true)
				setVisible(false)

			} else if (num === 1) {

				if (NAPI.check()) {
					NAPI.apptodo('upload', { folder_id: currentListFolderPid }, 'reloadList')

					setVisible(false)
				}
			}
		}

		setItemNum(num)
	}

	useEffect(() => {
		window['reloadList'] = reloadList;

		let _isShow = localStorage.getItem('me-drive-view-style');
		setIsShow(() => _isShow === 'block');

		let _localJSON: any = localStorage.getItem('vault-sort-info');

		if (_localJSON !== null) {
			let _isJSON = true;
			let _order = '';
			let _orderBy = '';

			try {
				_localJSON = JSON.parse(_localJSON);

				if (_localJSON.hasOwnProperty('order')) {
					_order = _localJSON.order;
				}
				if (_localJSON.hasOwnProperty('orderBy')) {
					_orderBy = _localJSON.orderBy;
				}

			} catch (e) {
				console.log(e);
				_isJSON = false;
			}


			if (_order || _orderBy) {
				let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
				_pageInfo = {
					..._pageInfo,
					order: _order,
					orderBy: _orderBy,
				}

				setPageInfo(() => _pageInfo);
			}
		}

	}, [])

	useEffect(() => {
		if (isShow !== undefined) {
			let _isShow = localStorage.getItem('me-drive-view-style');
			if (_isShow !== null) {
				localStorage.setItem('me-drive-view-style', isShow ? 'block' : 'list');
			} else {
				localStorage.setItem('me-drive-view-style', 'list');
			}
		}
	}, [])

	const reloadList = (id) => {
		// console.log('reloadList Function');
		window.location.reload();
		setCurrentListFolderPid(() => id);
		setVisible(() => false)
	}

	const handleSearch = () => {
		// navigate(`/search?token=${token}`)
		// console.log(, '111')
		jumpPage(navigate, `/search?id=${currentListFolderPid}`);
	}

	const handleMore = (e, item: any) => {
		// e.stopPropagation();
		setOpenItemDetails(true)
		setDetailsObj(item)
	}
	const hanleUpgradeStorage = () => {
		setIsUpgradeStorage(false);
		setOrderExpired(() => false);
		setNoteText(() => null);
		localStorage.setItem('remind-storage-almost-full', '1');
	}

	// 操作后的处理
	const reqData = (v, o, action = '') => {

		if (v && action === 'closeLongPress') {
			setEnterLongPressEvent(() => false);
			return;
		}

		if (v) {
			let _iFolder = [];
			let _iFile = [];
			if (isJSON(o)) {
				if (o.type === undefined) {
					_iFolder.push(o);
				} else {
					_iFile.push(o);
				}

			} else if (Array.isArray(o)) {
				for (let item of o) {
					if (item.type === undefined) {
						_iFolder.push(item);
					} else {
						_iFile.push(item);
					}
				}
			}


			if (action === 'delete') {
				removeItemFromList(_iFolder, _iFile)
			} else if (action === 'rename') {
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			} else if (action === 'collect') {
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			}
		}
	}

	const updateList = (_folder: any, _file: any) => {

		if (_folder.length > 0) {
			let _folderList = JSON.parse(JSON.stringify(folderList));
			_folderList = _folderList.map((o: any) => {
				for (let item of _folder) {
					if (o.id === item.id) {
						o = item;
					}
				}
				return o;
			})
			setFolderList(() => _folderList);
		}

		if (_file.length > 0) {
			let _fileList = JSON.parse(JSON.stringify(fileList));
			_fileList = _fileList.map((o: any) => {
				for (let item of _file) {
					if (o.id === item.id) {
						o = item;
					}
				}
				return o;
			})
			setFileList(() => _fileList);
		}

		if (enterLongPressEvent) {
			cancelSelectFunc();
		} else {
			setDetailsObj(() => Object({}));
		}
	}

	const removeItemFromList = (_folder: any, _file: any) => {

		if (_folder.length > 0) {
			let _arrids = _folder.map((item: any) => {
				return item.id;
			})

			let _folderList = JSON.parse(JSON.stringify(folderList));
			let _arr = [];
			for (let o of _folderList) {
				if (!_arrids.includes(o.id)) {
					_arr.push(o);
				}
			}
			setFolderList(() => _arr);
		}

		if (_file.length > 0) {
			let _arrids = _file.map((item: any) => {
				return item.id;
			})

			let _fileList = JSON.parse(JSON.stringify(fileList));
			let _arr = [];
			for (let o of _fileList) {
				if (!_arrids.includes(o.id)) {
					_arr.push(o);
				}
			}
			setFileList(() => _arr);
		}

		if (enterLongPressEvent) {
			cancelSelectFunc();
		} else {
			setDetailsObj(() => Object({}));
		}
	}

	// 重置页面信息
	const resetPageInfo = () => {
		let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		_pageInfo.page = 0;
		setPageInfo((prev) => _pageInfo);

		reqPageRef.current = 0;
	}

	const loadMoreData = async () => {
		let _onePageData = JSON.parse(JSON.stringify(onePageData));

		if (_onePageData.page_number < _onePageData.total_pages) {

			if (reqTimeceRef.current) {

				reqTimeceRef.current = false;
				let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
				_pageInfo.page = _pageInfo.page + 1;
				setPageInfo((prev) => _pageInfo);

			}
		}
	}

	// 排序方式
	const sortFunc = (v) => {
		let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		_pageInfo = {
			..._pageInfo,
			...v
		}
		// console.log(_pageInfo, '_pageInfo470')

		let _localJSON = {
			order: _pageInfo.order,
			orderBy: _pageInfo.orderBy,
		}

		localStorage.setItem('vault-sort-info', JSON.stringify(_localJSON));

		setPageInfo(() => _pageInfo);
		setSortRequst(() => true);
	}

	useEffect(() => {
		// setIsUpgradeStorage
		let _remind = localStorage.getItem('remind-storage-almost-full');

		if (_remind === null) {
			if (storeInfo['size'] && storeInfo['used']) {
				let _diff = storeInfo['size'] - storeInfo['used']
				let _kb = _diff / 1024;
				let _mb = _kb / 1024;
				let _gb = _mb / 1024;
				let _tb = _gb / 1024;

				if (_mb < 300) {
					if (storeInfo['size'] <= (1024 * 1024 * 1024 * 3)) {
						// setIsUpgradeStorage(() => true)
					}
				} else {
					if (_gb < 1) {
						if (storeInfo['size'] > (1024 * 1024 * 1024 * 3)) {
							// setIsUpgradeStorage(() => true)
						}
					}
				}
			}
		} else {

		}

		if (storeInfo?.cloud_gallery_id === null && storeInfo?.size !== 0) {
			// createCloudDisk();
		}
		// console.log(storeInfo, storeInfo.usable, g_cloud_disk_min_size,  Number(storeInfo.usable) < g_cloud_disk_min_size)
		// 		if(Number(storeInfo.usable) < g_cloud_disk_min_size){
		// 			setIsUpgradeStorage(() => true)
		// 		}
		//
		// 		if(Number(storeInfo.size) === 0){
		// 			setOrderExpired(() => true);
		// 		}

	}, [])

	useEffect(() => {
		if (cloudDiskInfo.root_id) {
			// console.log(cloudDiskInfo)

			if (Number(cloudDiskInfo.usable) < g_cloud_disk_min_size) {
				setIsUpgradeStorage(() => true)
				setNoticeNotEnoughSpaceUpgrade(() => true)
			}

			if (Number(cloudDiskInfo.size) === 0) {
				setOrderExpired(() => true);
			}
		}

	}, [])

	// 没有云盘，调用编辑自动同步接口生成;
	const createCloudDisk = () => {
		let req = {
			auto_save: false,
			back_up: false,
			only_wifi: false
		}
		setSettingConfig(req).then((res: any) => {
			let { data, result } = res;
			if (result === 'ok' && data) {

				// reloadPage();
			}
		}).catch(e => {
			console.log(e)
			let { message } = e;
			if (message) {
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		})
	}

	const fingerAction = useSwipeable({
		// onSwiped: (eventData) => {
		// 	console.log("User Swiped!", eventData)
		// },
		onSwipedUp: (eventData) => {
			// console.log("User onSwipedUp!", eventData)
			setSlidingDirection(() => 'Up')
		},
		onSwipedDown: (eventData) => {
			// console.log("User onSwipedDown!", eventData)
			setSlidingDirection(() => 'Down')
		},
		onSwiping: (eventData) => {
			// console.log("User onSwiping!", eventData)
		},
	})



	const reloadPage = () => {
		navigate(0);
	}

	// 打开文件
	const openFile = (e, o) => {
		jumpPage(navigate, `/file?id=${o.id}&type=${o.type}`)
	}

	// 下拉刷新数据
	const onRefreshFunc = async () => {
		console.log('onRefreshFunc')
		if (pullPageFlag.current) return;
		resetPageInfo();
		pullPageFlag.current = true;

		clearTimeout(timeOutRef.current);
		timeOutRef.current = setTimeout(() => {
			// initPageData()
			pullPageFlag.current = false;
		}, 1050);
	}

	const toPageFunc = (_path) => {
		if (_path) {
			jumpPage(navigate, `/${_path}`)
		}
	}

	const fallbackFunc = () => {
		return <span></span>
	}

	const onErrorFunc = () => {
		return null
	}

	const toPage = (_path) => {
		jumpPage(navigate, _path);
	}

	// const changeTabsFun = (key) => {
	// 	if(key === 'All'){
	// 		toPage('/home');
	// 	}else if(key === 'Cloud Gallery'){
	// 		toPage(`/home?id=${cloudDiskInfo?.cloud_gallery_id}`)
	// 	}else if(key === 'Photos'){
	// 		// toPage(`/home?id=${cloudDiskInfo?.photos_id}`)
	// 		toPage('/photo')
	// 	}else if(key === 'Starred'){
	// 		toPage('/favourite');
	// 	}else if(key === 'Trash'){
	// 		toPage('/trash');
	// 	}
	// }

	const clickRowMore = (o) => {
		handleMore(null, o)
	}

	const clickRowFunc = (o) => {

		if (enterLongPressEvent) {
			if (o.type === undefined) {
				if (o.disabled) {

				} else {
					let _folderSelectList = JSON.parse(JSON.stringify(folderSelectList));
					let _disIDS = getUserBasicFolderIDS();
					let _folderList = JSON.parse(JSON.stringify(folderList));
					let _disIDSArr = [];

					// for(let item of _folderList){
					// 	if(_disIDS.includes(item.id)){
					// 		_disIDSArr.push(item.id);
					// 	}
					// }

					// setBasicFolderSelectList(() => _disIDSArr)

					if (_folderSelectList.includes(o.id)) {
						_folderSelectList = _folderSelectList.filter((item) => {
							return item !== o.id;
						})
					} else {
						_folderSelectList.push(o.id)
					}

					setFolderSelectList(() => _folderSelectList)
				}
			} else {
				let _fileSelectList = JSON.parse(JSON.stringify(fileSelectList));

				if (_fileSelectList.includes(o.id)) {
					_fileSelectList = _fileSelectList.filter((item) => {
						return item !== o.id;
					})
				} else {
					_fileSelectList.push(o.id)
				}

				setFileSelectList(() => _fileSelectList)
			}
			return;
		}


		if (o.type === undefined) {
			openFolder(null, o)
		} else {
			if (Number(o.status) !== 0) {
				openFile(null, o)
			}
		}
	}

	const selectAllFunc = (v) => {

		let _folderList = JSON.parse(JSON.stringify(folderList));
		let _fileList = JSON.parse(JSON.stringify(fileList));
		let _disIDS = getUserBasicFolderIDS();

		let _arr = []
		let _disIDSArr = []
		if (v) {
			for (let item of _folderList) {
				// if(_disIDS.includes(item.id)){
				// 	_disIDSArr.push(item.id);
				// }else{
				// 	_arr.push(item.id);
				// }
				_arr.push(item.id);
			}
		}

		setBasicFolderSelectList(() => _disIDSArr)
		setFolderSelectList(() => _arr)

		let _arrii = [];

		if (v) {
			for (let item of _fileList) {
				_arrii.push(item.id);
			}
		}

		setFileSelectList(() => _arrii)
	}

	const downLoadFunc = (e) => {
		let _arr = [];
		let _fileList = JSON.parse(JSON.stringify(fileList));

		for (let item of _fileList) {
			if (fileSelectList.includes(item.id)) {
				_arr.push(item);
			}
		}

		if (NAPI.check()) {
			NAPI.apptodo('downloadList', _arr);

			setEnterLongPressEvent(() => false);

		}
	}

	const checkLongPressMoreMenu = () => {
		setOpenItemDetails(() => true)
	}

	const changePressStart = (v) => {
		if (pullPageFlag.current) return;
		setEnterLongPressEvent(() => v)
		// pullPageFlag.current
	}

	useEffect(() => {
		if (!enterLongPressEvent) {
			setFolderSelectList(() => []);
			setFileSelectList(() => []);
		}
	}, [enterLongPressEvent])

	const cancelSelectFunc = () => {
		setEnterLongPressEvent(() => false);
		setFolderSelectList(() => []);
		setFileSelectList(() => []);
		setBasicFolderSelectList(() => []);

	}

	const getSelectDataByList = () => {
		let _arr = [];
		for (let item of folderList) {
			if (folderSelectList.includes(item.id)) {
				_arr.push(item)
			}
		}

		for (let item of fileList) {
			if (fileSelectList.includes(item.id)) {
				_arr.push(item)
			}
		}

		return _arr
	}

	const getEleSize = () => {
		let _dom = containRef.current;
		if (_dom) {
			let _h = _dom.clientWidth;
			_h = (_h - 120) / 6;

			return {
				style: {
					width: `${_h}px`,
					height: `152px`
				}
			}
		} else {
			return {};
		}
	}


	const _eleSize = typeof containRef.current === 'object' ? getEleSize() : {}
	const _selectData = enterLongPressEvent ? getSelectDataByList() : detailsObj;
	let _select_count = folderSelectList.length + fileSelectList.length;

	const endDom = () => {
		let _checked = (fileSelectList.length + folderSelectList.length + basicFolderSelectList.length === fileList.length + folderList.length) && (fileList.length + folderList.length !== 0) && (fileSelectList.length + folderSelectList.length > 0);
		let _indeterminate = (!_checked) && (fileSelectList.length + folderSelectList.length > 0);
		return <div>
			<Space align={"center"} justify={"center"} direction={"horizontal"} style={{ '--gap': '25px' }}>
				<div className={'check-box-quadrate'}>
					<Checkbox
						onChange={selectAllFunc}
						indeterminate={_indeterminate}
						checked={_checked}
						block
					></Checkbox>
				</div>

				{
					folderSelectList.length === 0 && fileSelectList.length > 0 ?
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px" }} onClick={downLoadFunc} />
						:
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px", opacity: 0.35 }} />
				}
				{
					_select_count === 0 ? <img src="/res/icons/icon_more.svg" alt="" style={{ transform: "rotate(90deg)", opacity: 0.5 }} />
						: <img src="/res/icons/icon_more.svg" alt="" style={{ transform: "rotate(90deg)" }} onClick={checkLongPressMoreMenu} />
				}
			</Space>
		</div>
	}

	const actions = [
		{ key: 'updateTime', icon: null, text: 'Modified Date' },
		{ key: 'name', icon: null, text: 'Name' },
		{ key: 'type', icon: null, text: 'Types' },
		{ key: 'size', icon: null, text: 'Size' },
	]
	// name, updateTime, size, types
	const sortMenuFun = (v) => {
		let _data: any = {
			orderBy: v.key,
		}

		if (pageInfo.orderBy === v.key) {
			_data.order = pageInfo.order === 'asc' ? 'desc' : 'asc';
		}

		sortFunc(_data);
	}

	const handleSearchInput = async (e) => {
		const val = (e.target.value).trim()
		if (val) {
			setSearchVal(val)
		} else {
			setSearchVal('')

		}
	}

	const handleSearchCancel = async () => {
		setIsSearch(true)
		setSearchVal('')
	}


	return (
		<div
			{...fingerAction}
			className={`con ${(noteText !== null && noteText !== '') ? 'a-h100' : ((isPause || ((!orderExpired) && isUpgradeStorage) || orderExpired) ? 'has-upgrade-storage' : '')}`}
		>
			<div className={'vault-head-contain vault-head-contain-tom'}>
				<div className={'file-head-box'}>


				</div>
				<div className="content-title" style={{ marginBottom: isSearch ? "24px" : "12px" }}>
					{
						isNavigation && <Navigation />
					}
					
					<div className="page-con-item">
						<div className="page-con-search">
							<Input placeholder="Search" value={searchVal} onChange={(e) => handleSearchInput(e)} />
							<img src="/res/icons/icon_search.svg" alt="" className="search-img"></img>
						</div>
						<div className="page-con-sort">
							<Space align={"center"} style={{ '--gap': '16px' }}>
								<div onClick={handleList}>
									{isShow ? <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_1.svg"} alt="" /> : <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_1-active.svg"} alt="" />}
								</div>
								<div onClick={handleList}>
									{isShow ? <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_2-active.svg"} alt="" /> : <img style={{ width: "18px", height: "18px" }} src={"/res/icons/icon_view_2.svg"} alt="" />}
								</div>
							</Space>
						</div>
					</div>
				</div>
				<div className="content-title-header" >
					<div>
					
						Name
						{ <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px" }} alt="" className="order-img"></img>}
						</div>
					<div> 
						Created Time
						{/* { <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px" }} alt="" className="order-img"></img>} */}
					</div>
					<div>
						Modified Time
						{/* { <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px"}} alt="" className="order-img"></img>} */}
					</div>
					<div >
						Size
						{/* { <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px" }} alt="" className="order-img"></img>} */}
					</div>
					<div></div>
				</div>
			</div>

			<div className="content" ref={containRef}>


				{
					loadingMainData === 1 ? <>
						<LoadDataWaitingState />
					</>
						:
						<> {changeFolderRef.current === 2 &&
							<div className={'file-item-list'}>
								<PullToRefresh
									onRefresh={onRefreshFunc}
									renderText={() => {
										return <div>Pull down to refresh</div>
									}}
									disabled={enterLongPressEvent}
								>
									{
										(folderList.length === 0 && fileList.length === 0 && loadingMainData === 2) &&
										<div className="content-data">
											<div className="content-data-img">
												<img src="/res/icons/no_file_found.png" alt="" />
											</div>
											<div className="content-data-txt">
												<h4>A place for all your files.</h4>
												<p>Tap the "Upload" button to upload files.</p>
											</div>
										</div>
									}

									{
										(folderList.length > 0 || fileList.length > 0) && <>
											<ContentItem
												{..._eleSize}
												isShow={isShow}
												list={[...folderList, ...fileList]}
												onHandleMore={clickRowMore}
												onClick={clickRowFunc}
												onLongPress={changePressStart}
												longPress={enterLongPressEvent}
												folderCheckedList={folderSelectList}
												fileCheckedList={fileSelectList}
												inputVal={inputVal}
												onInputVal={setInputVal}
											/>
										</>
									}

								</PullToRefresh>
							</div>
						}
						</>
				}
				<DetailsModal
					type={"file"}
					visible={openItemDetails}
					onVisible={setOpenItemDetails}
					data={_selectData}
					reqData={reqData}
					onClick={clickRowFunc}
					cloudDiskInfo={cloudDiskInfo}
					onIsFileDetailsModal={setIsFileDetailsModal}
				/>
			</div>

			{/*{*/}
			{/*	slidingDirection !== 'Up' && <>*/}
			{/*		{*/}
			{/*			((!enterLongPressEvent)) && <div className="add" onClick={handlePopup}>*/}
			{/*				<img src="/res/icons/icon_add.svg" alt=""/>*/}
			{/*			</div>*/}
			{/*		}*/}
			{/*	</>*/}
			{/*}*/}


			{/* {
				!systemFolders.includes(Number(currentListFolderPid)) && <>
					<Popover.Menu
						actions={[
							{ key: '3', icon: <img src="/res/icons/icon_create_new_folder_ii.svg" alt="" style={{ width: "18px" }} />, text: 'Create New Folder' },
							{ key: '1', icon: <img src="/res/icons/icon_file_upload.svg" alt="" style={{ width: "18px" }} />, text: 'Upload Photo/Video' },
						]}
						onAction={({ key }) => handleItem(Number(key))}
						placement='bottom-start'
						trigger='click'
						className={'adm-popover--add-btn'}
						destroyOnHide={true}
					>
						<div className="add">
							1111
							<img src="/res/icons/icon_add.svg" alt="" />
						</div>
					</Popover.Menu>
				</>
			} */}


			<Popup
				className="popup-con"
				visible={visible}
				onMaskClick={() => {
					setVisible(false)
				}}
				onClose={() => {
					setVisible(false)
				}}
			>
				<div className="popup-title">Add To Vault</div>

				<div
					className={itemNum === 1 ? "popup-item popup-item-active" : "popup-item"}
					onClick={() => handleItem(1)}
					style={{ opacity: `${Number(cloudDiskInfo.size) > 0 ? 1 : 0.65}` }}
				>
					<img src="/res/icons/icon_photo.svg" alt="" style={{ width: "20px" }} />
					<p>Upload Photos Or Videos</p>
				</div>

				<div
					className={itemNum === 3 ? "popup-item popup-item-active" : "popup-item"}
					onClick={() => handleItem(3)}
					style={{ opacity: `${Number(cloudDiskInfo.size) > 0 ? 1 : 0.65}` }}
				>
					<img src="/res/icons/icon_create_new_folder.svg" alt="" style={{ width: "18px" }} />
					<p>Create New Folder</p>
				</div>

			</Popup>

			{/*<SortByModal*/}
			{/*	visible={isSortBy}*/}
			{/*	onVisible={setIsSortBy}*/}
			{/*	sortFunc={sortFunc}*/}
			{/*	sortValue={{orderBy: pageInfo.orderBy, order: pageInfo.order}}*/}
			{/*/>*/}
			<CreateNewFolder
				visible={isFolder}
				onVisible={setIsFolder}
				setFolderName={setAddFolderName}
			/>

			<FileDetailsModal open={isFileDetailsModal} onOpen={setIsFileDetailsModal} initPageData={getPageData} data={_selectData} />
			{/* <CopyDrawer open={isCopyDrawer} onOpen={setIsCopyDrawer}/> */}
			{/*<BottomOperation  title="Back" buttonTxt="Set Location"/>*/}

			{/*	各种底部提示 */}

			{
				noticeAutoSave && <>
					<div className={'--notice-card-box'}>
						<div className={'--icon-a'}><img src={'/res/icons/icon_picture-a.svg'} alt="" /></div>
						<div className={'--words-a'}>
							<h3>Auto save the local photos to Vault</h3>
							<p>We keep your memories on the cloud.</p>
						</div>
						<div className={'--btn-a'}>
							<Button color='primary' shape='rounded'>Open</Button>
						</div>

						<span className={'--btn-close'} onClick={() => setNoticeAutoSave(false)}>
							<img src={'/res/icons/icon_close.svg'} alt="" width={10} />
						</span>
					</div>
				</>
			}

			<Popup
				visible={noticeDeleteFile30}
				onClose={() => { setNoticeDeleteFile30(false) }}
				// position='bottom'
				maskStyle={{ background: 'transparent', pointerEvents: 'none' }}
				bodyStyle={{ minHeight: '90px' }}
				showCloseButton
				destroyOnClose={true}
				closeOnMaskClick={false}
				className={'--notice-popup-box'}
			>
				<div className={'--notice-popup-body'}>
					<div className={'--flex'}>
						<div className={'--icon-1'}>
							<img src={'/res/icons/icon_notice_a.svg'} alt="" />
						</div>
						<div className={'--box-1'}>
							<p className={'--notice-desc'}>
								All the files on the Vault will be
								permanently deleted <b>30</b> days (<b>25/08/2024</b>)
								later due to the subscription cancellation.
								Subscribe again to keep the Vault storage.
							</p>
							<div className={'--box-2'}>
								<Button color='primary' shape='rounded'>Subscribe Again</Button>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				visible={noticeDeleteFile90}
				onClose={() => { setNoticeDeleteFile90(false) }}
				// position='bottom'
				maskStyle={{ background: 'transparent', pointerEvents: 'none' }}
				bodyStyle={{ minHeight: '90px' }}
				showCloseButton
				destroyOnClose={true}
				closeOnMaskClick={false}
				className={'--notice-popup-box'}
			>
				<div className={'--notice-popup-body'}>
					<div className={'--flex'}>
						<div className={'--icon-1'}>
							<img src={'/res/icons/icon_notice_a.svg'} alt="" />
						</div>
						<div className={'--box-1'}>
							<p className={'--notice-desc'}>
								All files in the Vault will be permanently
								deleted <b>90</b> days (<b>03/25/2025</b>) after
								subscription cancellation. Renew your
								subscription to maintain your Vault
								storage.
							</p>
							<div className={'--box-2'}>
								<Button color='primary' shape='rounded'>Renew Subscription</Button>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Modal
				open={noticeNotEnoughSpaceUpgrade}
				onCancel={() => { setNoticeNotEnoughSpaceUpgrade(false) }}
				// position='bottom'
				maskStyle={{ background: 'transparent', pointerEvents: 'none' }}
				bodyStyle={{ minHeight: '90px' }}
				// showCloseButton
				// destroyOnClose={true}
				// closeOnMaskClick={false}
				className={'--notice-popup-box'}
				centered
				footer={null}
			>
				<div className={'--notice-popup-body'}>
					<div className={'--flex'}>
						<div className={'--icon-1'}>
							<img src={'/res/icons/icon_notice_a.svg'} alt="" />
						</div>
						<div className={'--box-1'}>
							<p className={'--notice-desc'}>
								You don’t have extra storage space for
								uploading other files to the Vault due to
								the Plan downgrade, You can upgrade the
								Vault Plan or clear up some files on the
								Vault, so as to get more free storage
								space.
							</p>
							<div className={'--box-2'}>
								<Button shape='rounded' style={{ maxWidth: '128px', marginRight: '15px', width: 'calc(50% - 10px)', background: '#EAEEF2' }}>Clear Up</Button>
								<Button color='primary' shape='rounded' style={{ maxWidth: '128px', width: 'calc(50% - 10px)' }}>Upgrade</Button>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Popup
				visible={noticeNotEnoughSpaceClearUp}
				onClose={() => { setNoticeNotEnoughSpaceClearUp(false) }}
				position='bottom'
				maskStyle={{ background: 'transparent', pointerEvents: 'none' }}
				bodyStyle={{ minHeight: '90px' }}
				showCloseButton
				destroyOnClose={true}
				closeOnMaskClick={false}
				className={'--notice-popup-box'}
			>
				<div className={'--notice-popup-body'}>
					<div className={'--flex'}>
						<div className={'--icon-1'}>
							<img src={'/res/icons/icon_notice_a.svg'} alt="" />
						</div>
						<div className={'--box-1'}>
							<p className={'--notice-desc'}>
								You don't have additional storage space
								for uploading new files to the Vault due
								to your plan downgrade. To free up more
								storage space, consider removing some
								existing files in the Vault.
							</p>
							<div className={'--box-2'}>
								<Button color='primary' shape='rounded' style={{ maxWidth: '128px', width: 'calc(50% - 10px)' }}>Clear Up</Button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
			<CleanUpMeDrive open={isCleanUpMeDrive} onOpen={setIsCleanUpMeDrive} />
			<MiddlePageModle open={true}/>
		</div>
	);
}

export default MiddlePage;
