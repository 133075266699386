import { Button, Modal, Checkbox } from 'antd';
import './index.less';


const DeleteModal = (props) => {
  const {
		visible,
		onVisible,
		title,
		contentObj,
		onClose,
		onAction,
		closeButtonText,
		actionButtonText,
    target,
    num
	} = props


  const handleOk = async () => {
    onAction()
    onVisible(false)
    };

   
      const handleCancel = () => {
        onVisible(false)
      };


    return <Modal title="Basic Modal" open={visible} className='delete-Modal' centered onOk={handleOk} onCancel={handleCancel} footer={null}>
            {
              target === "Trash" ? <div className='trash'>
                  <div className='title'>
                     <img src="/res/icons/tips.svg" alt=""  width={80}/>
                     <p>Delete {num} items?</p>
                </div>
                <div className='trash-con'>
                     <p>This will permanently delete your files.<br/> Collaborators will also lose access.</p>  
                     {/* <p><Checkbox checked={true}/>  &nbsp; &nbsp;I understand that deleted items can't be recovered.</p> */}
                 </div>
              </div> : <>
              <div className='prompt'>
            {title} 
            </div>
           <div className='prompt-con'>
           {contentObj}
           </div>
              </>
            }
           
             
            <div className='prompt-footer'>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleOk}>Delete</Button>
            </div>

  </Modal>
}


export default DeleteModal;