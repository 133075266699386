import {create} from 'zustand';

// 打开cloud订阅
const useCloudSubscribeStore = create(set => ({
  cloudInfoZU: {open: false, data: {}},
  setCloudInfoZU: (cloudInfoZU) => set({cloudInfoZU})
}))

// 打开vault订阅
const useVaultSubscribeStore = create(set => ({
  vaultInfoZU: {open: false, data: {}},
  setVaultInfoZU: (vaultInfoZU) => set({vaultInfoZU})
}))

// 打开支付
const usePaymentStore = create(set => ({
  paymentInfoZU: {open: false, data: {}},
  setPaymentInfoZU: (paymentInfoZU) => set({paymentInfoZU})
}))

// 打开订单详情
const useOrderDetailStore = create(set => ({
  orderDetailZU: {open: false, data: {}},
  setOrderDetailZU: (orderDetailZU) => set({orderDetailZU})
}))

// 打开修改订单绑定的设备弹窗
const useChangeCloudDeviceStore = create(set => ({
  changeCloudDeviceZU: {open: false, data: {}},
  setChangeCloudDeviceZU: (changeCloudDeviceZU) => set({changeCloudDeviceZU})
}))

// 开通试用弹窗  针对zmodo meshare 等， smartz除外
const useFreeTrialSubscribeStore = create(set => ({
  freeTrialSubscribeZU: {open: false, data: {}},
  setFreeTrialSubscribeZU: (freeTrialSubscribeZU) => set({freeTrialSubscribeZU})
}))

// 更新cloud 主页
const useUpdateCloudPageStore = create(set => ({
  updateCloudPageZU: false,
  setUpdateCloudPageZU: (updateCloudPageZU) => set({updateCloudPageZU})
}))

// 设备详情弹窗
const useDeviceDetailStore = create(set => ({
  deviceDetailZU: {open: false, data: {}},
  setDeviceDetailZU: (deviceDetailZU) => set({deviceDetailZU})
}))

export {
  useCloudSubscribeStore,
  useVaultSubscribeStore,
  usePaymentStore,
  useOrderDetailStore,
  useChangeCloudDeviceStore,
  useFreeTrialSubscribeStore,
  useUpdateCloudPageStore,
  useDeviceDetailStore
}
