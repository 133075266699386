import './index.less'
import {Button, Drawer, Row, Col, Spin, message} from 'antd';
import React, {useEffect, useState} from 'react';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'
import Device3 from '../../../../assets/images/cloudPlan/device3.png'
import IconUnselected from '../../../../assets/images/cloudPlan/unselected.svg'
import IconSelected from '../../../../assets/images/cloudPlan/selected.svg'
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {useChangeCloudDeviceStore, useOrderDetailStore, useUpdateCloudPageStore} from "../../../../store/cloudPlan";
import {
	activateStripeOrder,
	bundledDeviceAndroid,
	bundledDeviceApple, bundledDeviceWEB,
	getDeviceStorageList,
	getInfoSubscription, getShippingOrderList
} from "../../../../server/cloudPlan";
import pfFileUrl from "../../../../server/PFFileUrl";
import _ from "lodash";
import {getCloudOrderInfo} from "../../../../server/plan";
import {getLGToken} from "../../../../utils/common";

const ChangeCloudDeviceModal = (props) => {
	const {open} = props;

	const [loading, setLoading] = useState<boolean>(false);
	const [waiting, setWaiting] = useState<boolean>(false);
	const [deviceList, setDeviceList] = useState<any>([]);
	const [selectDeviceArr, setSelectDeviceArr] = useState<any>([]);

	const [webOrderDetail, setWebOrderDetail] = useState<any>(null);

	const {changeCloudDeviceZU, setChangeCloudDeviceZU} = useChangeCloudDeviceStore();
	const {setOrderDetailZU} = useOrderDetailStore();
	const {setUpdateCloudPageZU} = useUpdateCloudPageStore();

	const [messageApi, contextHolder] = message.useMessage();

	let _order_data = changeCloudDeviceZU.data;

	const onClose = () => {
		setChangeCloudDeviceZU({open: false, data: {}});
	};

	useEffect(() => {
		if(open){
			initFun();
		}else{
			setDeviceList(() => []);
			setWaiting(() => false);
		}
	}, [open])

	const initFun = async () => {
		setLoading(() => true);
		let order_dev_arr = [];

		if(_order_data.order_pf === 'web_pay'){
			let res:any = await getWebOrderDetailFun();
			let obj:any = await getShippingOrderListFun();
			let {devices} = res;
			if(res){
				res.shipping_id = obj[res.identity_id]
			}
			setWebOrderDetail(() => res);
			order_dev_arr = Array.isArray(devices) ? devices : []
		}else{
			order_dev_arr = await getOrderDetailFun();
		}

		let user_dev_arr = await getDeviceListFun();
		let arr = [];
		for(let item of order_dev_arr){
			arr.push(item.physical_id);
		}

		user_dev_arr = user_dev_arr.map((item:any) => {
			item.disabled = false;  // 编辑状态

			if(item.support_cvr !== 1){ // 不支持云服务
				item.disabled = true;
			}else{
				if(arr.includes(item.physical_id)){
					item.is_original_dev = true;  // 原来的设备
				}

				if([4].includes(item.status)){ // 订阅中的设备
					if(item.order_type && _order_data.order_pf !== item.order_type){
						item.disabled = true;
					}
				}
			}

			return item;
		})

		// 到达限制数量之后， 不可选
		if(Number(_order_data.devices_count) !== 100){
			let _deviceList = _.cloneDeep(deviceList);
			user_dev_arr = user_dev_arr.map((o:any) => {
				o.is_restricted = Number(_order_data.devices_count) === arr.length && !arr.includes(o.physical_id)   ; // 限制选择
				return o;
			})
		}


		setSelectDeviceArr(() => arr);
		setDeviceList(() => user_dev_arr);

		setLoading(() => false);
	}

	const getDeviceListFun = async () => {

		const res: any = await getDeviceStorageList();
		const {result, data} = res;
		let arr = [];
		if(result === 'ok' && Array.isArray(data)){
			arr = data;
		}

		return arr;
	}

	const getOrderDetailFun = async () => {

		let req:any = {
			token: getLGToken(),
			order_id: _order_data.id,
			type: _order_data.type
		}

		const res:any = await getCloudOrderInfo(req);
		const {result, data} = res;

		let arr = [];

		if(result === 'ok' && data){
			let {order_current} = data;
			if(order_current){
				let {devices} = order_current;
				if(Array.isArray(devices)){
					arr = devices;
				}
			}
		}

		return arr;
	}

	const getWebOrderDetailFun = async () => {
		const res: any = await getInfoSubscription(_order_data.id);
		let {devices} = res;
		// return Array.isArray(devices) ? devices : []
		return res;
	}

	const loadDevIconErrorFun = (e) => {
		e.target.src = '/res/icons/camera-1.png';
	}

	const submitFun = async () => {
		setWaiting(() => true);

		let res = false;
		if(_order_data.order_pf === 'web_pay'){
			res = await submitDataWeb()
		}else{
			res = await submitDataApp()
		}

		if(res){
			let data = _.cloneDeep(_order_data);
			setOrderDetailZU({open: true, data: data})

			onClose();
		}

		setWaiting(() => false);
	}

	const chooseDevFun = (item) => {
		const {physical_id} = item;

		let arr = _.cloneDeep(selectDeviceArr);
		if(arr.includes(physical_id)){
			arr = arr.filter((v) => v !== physical_id);
		}else{
			if(Number(_order_data.devices_count) !== 100 && Number(_order_data.devices_count) === arr.length){ //限制数量

			}else{
				arr.push(physical_id);
			}
		}

		setSelectDeviceArr(() => arr);

		// 到达限制数量之后， 不可选
		if(Number(_order_data.devices_count) !== 100){
			let _deviceList = _.cloneDeep(deviceList);
			_deviceList = _deviceList.map((o:any) => {
				o.is_restricted = Number(_order_data.devices_count) === arr.length && !arr.includes(o.physical_id)   ; // 限制选择
				return o;
			})

			setDeviceList(() => _deviceList);
		}

	}

	const submitDataApp = async () => {
		let req = {
			token: getLGToken(),
			order_id: _order_data.id,
			physical_id: selectDeviceArr.join('_')
		}

		let _func = _order_data.order_pf === 'apple' ? bundledDeviceApple : bundledDeviceAndroid;

		let res:any = await _func(req);
		let {result, error} = res;

		let is_ok = false;

		if(result){
			if(result === 'ok'){
				successMSG(result);
				is_ok = true;
			}else{
				if(error){
					warningMSG(error);
				}
			}
		}else{
			warningMSG('error');
		}

		return is_ok;
	}

	const submitDataWeb = async () => {
		let req = {
			token: getLGToken(),
			sub_token: _order_data.id,
			devices: selectDeviceArr
		}

		if(webOrderDetail && webOrderDetail.status === 'SCHEDULED' && webOrderDetail.devices === null){
			return await activateAnOrderFun();
		}

		let res:any = await bundledDeviceWEB(req);
		let {result} = res;
		let is_ok = false;

		if(result){
			if(result === 'ok'){
				successMSG(result);
				is_ok = true;
			}else{
				warningMSG(result);
			}
		}else{
			warningMSG('error');
		}
		return is_ok;
	}

	//获取shipping id
	const getShippingOrderListFun = async () => {
		let res:any = await getShippingOrderList();
		let obj = {};
		if(Array.isArray(res)){
			for(let item of res){
				obj[item.subscriptionIdentityId] = item.identityId;
			}
		}
		return obj;
	}

	// 激活订单
	const activateAnOrderFun = async () => {
		let req = {
			user_token: getLGToken(),
			order_id: webOrderDetail.shipping_id,
			devices: selectDeviceArr.join(',')
		}

		let res:any = await activateStripeOrder(req);
		let {subscription_id, error} = res;
		let is_ok = false;

		if(subscription_id){
			successMSG('Ok');
			setUpdateCloudPageZU(true);
			is_ok = true;
		}else{
			if(error){
				warningMSG(error);
			}else{
				warningMSG('error');
			}
		}
		return is_ok;
	}

	const successMSG = (v) => {
		messageApi.open({
			type: 'success',
			content: v,
		});
	}

	const warningMSG = (v) => {
		messageApi.open({
			type: 'warning',
			content: v,
		});
	}

	return <Drawer
		title="Change Device"
		placement="right"
		width={640}
		className='change-cloud-device-modal'
		onClose={onClose}
		open={open}
	>
		{contextHolder}
		<div className='details-modal-title'>
			<p></p>
			<p>Change Device</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>

		{/*<div className={'desc-info-com'}>*/}
		{/*	<p className={'-a'}>Congrats! You're ready to enjoy some new amazing security features.</p>*/}
		{/*	<p className={'-b'}>Choose which devices you'd like to enable.</p>*/}
		{/*</div>*/}

		<div className='cloud-device'>
			<div className={'sub-title-box'}>
				<div className='cloud-device-title'>
					<p>Select Device(s)</p>
				</div>
				<p className='txt'>You can select ({_order_data.devices_count == 100 ? '∞' : _order_data.devices_count}) device to enable the cloud video recording.</p>
			</div>

			{
				loading && <div className={'-dev-list-con'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </div>
			}

			{
				!loading && <div className={'-dev-list-con'}>
					{
						deviceList.map((item:any, index:number) => {

							return <div
								className={'cloud-device-card'}
								style={{opacity: (item.disabled || item.is_restricted) ? 0.6 : 1}}
								key={index}
							>
								<div className='cloud-device-card-l'>
									<p><img src={pfFileUrl(item.pic_url)} alt="" onError={loadDevIconErrorFun}/></p>
									<p>{item.device_name}</p>
								</div>

								{
									item.disabled ? null : <div className='cloud-device-card-r' onClick={() => chooseDevFun(item)}>
										<Icon
											component={ selectDeviceArr.includes(item.physical_id) ? IconSelected : IconUnselected}
											style={{fontSize: "24px"}}
										/>
									</div>
								}

							</div>
						})
					}
        </div>
			}


			<div className={'-btn-box'}>
				<Button
					type="primary"
					shape="round"
					size={'large'}
					block
					onClick={submitFun}
					loading={waiting}
				>
					Confirm
				</Button>
			</div>
		</div>
	</Drawer>;
}

export default ChangeCloudDeviceModal;
