import { useParams } from "react-router-dom";
/*
* _path: 路径
* rp: 是否需要重写路径
* */
export function BaseResource(_path='', rp=false) {
	const params = useParams();

	let _resPath = _path
	if(params.platform){
		let _pathArr = _path.split('/')// .join('/')
		let _inx = _pathArr.indexOf('res');
		if(_inx > -1){
			_pathArr.splice(_inx + 1, 0, params.platform);
			let __resPath = _pathArr.join('/');
			if(rp){
				_resPath = __resPath;
			}
		}

	}
	return `${_resPath}`
}
