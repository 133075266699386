import {BaseResource} from "../../../utils/resource";
import { Button, ProgressBar, SpinLoading, Toast} from 'antd-mobile'
import { Drawer } from 'antd';
import './index.less'
import HeadNav from "../../../components/headNav";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import token from "../../../utils/token";
import {getStorageInformation, getStorageStatInformation} from "../../../server/storage";
import CloseIcon from '../../../assets/images/cloudPlan/close.svg'
import Icon from "@ant-design/icons";
import jumpPage from "../../../utils/jumpPage";
import {getLGToken, isAndroid, isApple, isJSON, sizeFormat} from "../../../utils/common";
import {getOrderList, planListGPA, planListIAP, subscribeGPA, subscribeIAP} from "../../../server/plan";
import dayjs from "dayjs";
import NAPI from "../../../utils/NAPI";
import InfoMadal from "../../../components/InfoModal";
import CleanUpMeDrive from "../cleanUpMeDrive"

const storageSizeObj = {
	1: 'Basic (1TB)',
	2: 'Advanced (3TB)'
}

const vaultSize = {
	'1': '1TB',
	'2': '3TB'
}

const cloudPlan = {
	"300_gb": {title: "Bonus 300 GB Storage", content: "Enjoy 300GB of extra cloud storage with your \"Premium\" plan."},
	"500_gb": {title: "Bonus 500 GB Storage", content: "Enjoy 500GB of extra cloud storage with your \"Platinum\" plan."},
	"300GB": {title: "Bonus 300 GB Storage", content: "Enjoy 300GB of extra cloud storage with your \"Premium\" plan."},
	"500GB": {title: "Bonus 500 GB Storage", content: "Enjoy 500GB of extra cloud storage with your \"Platinum\" plan."},
}

// 订单和平台之间的关系
const orderPlatform = {
	"1": "iOS",
	"2": "Android",
	"3": "iOS",
	"4": "Android",
	"5": "iOS",
	"6": "Android",
	"7": "Web",
	"8": "Web",
	"9": "Web",
}

// 1: 'Cloud Storage(300GB)',
// 	2: 'Cloud Storage(500GB)',
// 	3: 'Basic(1TB)',
// 	4: 'Advanced(3TB)'

const tagObj = {
	0: "To be paid", // 待支付已确认
	1: "Subscribed", // 有效
	2: "Cancelled",  // 已取消（到期才失效）
	3: "Subscribed", // 宽限期
	4: "Expired",    // 暂时保留
	5: "Expired",    // 已到期（已失效）
	6: "Cancelled"   // 客服操作撤销
}

export default function StoragePage(props) {
	const { isStorage, onIsStorage, onIsCleanUpMeDrive } = props;
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({ percent:0 })
	const [cloudFileInfo, setCloudFileInfo] = useState<any>({})
	const [currentBasicPlan, setCurrentBasicPlan] = useState<any>(false);
	const [currentPlan, setCurrentPlan] = useState<any>({order: {}});
	const [currentPlanList, setCurrentPlanList] = useState<any>([]);
	const [nextPlanList, setNextPlanList] = useState<any>([]);
	const [loadStatus, setLoadStatus] = useState<boolean>(true);
	const [products, setProducts] = useState<any>([]);
	const [recommendPlan, setRecommendPlan] = useState<any>({})
	const [submitStatus, setSubmitStatus] = useState<boolean>(false);
	const [visible, setVisible] = useState<boolean>(false);
	const [onlyVaultPlan, setOnlyVaultPlan] = useState<boolean>(false);
	const [onlyVaultPlanData, setOnlyVaultPlanData] = useState<any>({});

	const [cloudOrderList, setCloudOrderList] = useState<any>([]); // 云服务赠送的
	const [has3Tplan, setHas3Tplan] = useState<boolean>(true); // 已有3T套餐


	const navigate = useNavigate()
	const searchParams = useSearchParams()
	const timeOutRef:any = useRef(0);
	const location = useLocation()


	const evtTypeRef = useRef<any>('');
	const pathObj = `${location.pathname}${location.search}`




	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	},[])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}



	// 获取云盘信息
	const getCloudDiscInfo = async () => {
		let res:any = await getStorageInformation();
		let {data, result} = res;
		if(result === 'ok' && data){
			if(isJSON(data)){
				let _totalUseSize = Number(data.used + data.upload_used);

				if(!Number.isNaN(_totalUseSize)){
					_totalUseSize = _totalUseSize * 100;
				}

				data.percent = data.size === 0 ? 0 : _totalUseSize / data.size;
				data._size = sizeFormat(data.size);
				data._usable = sizeFormat(data.usable);
				data._used = sizeFormat(data.used);
				data._total = sizeFormat(data.used + data.upload_used);
				setCloudDiskInfo(() => data);
			}
			getCloudDiscFileInfo();
		} else if(Number(result) === 401){
			navigate('/login')
		}
	}

	// 获取云盘文件储存信息
	const getCloudDiscFileInfo = async () => {
		let res:any = await getStorageStatInformation(1);
		let {data, result}:any = res;
		if(result === 'ok' && data){
			if(isJSON(data)){
				data._audioSize = sizeFormat(data['audio_size']);
				data._fileSize  = sizeFormat(data['file_size']);
				data._imageSize = sizeFormat(data['image_size']);
				data._trashSize = sizeFormat(data['trash_size']);
				data._videoSize = sizeFormat(data['video_size']);
				data._otherSize = sizeFormat(data['other_size']);
				setCloudFileInfo(() => data);
			}
		}

		orderListFunc();
	}

	const orderListFunc = () => {
		try {

			let req = {
				// type: isApple() ? 1 : 2,
				token: getLGToken()
			}
			setLoadStatus(() => true);
			getOrderList(req).then((res:any) => {

				let {result, data} = res;

				if(result === 'ok' && Array.isArray(data)){

					let _basic_size = 0;
					let _dcs_iap:any = false;
					let _dcs_gpa:any = false;
					let _dcs_web:any = false;
					let _basic_size_usable = 0; // 可用的订单大小

					let _flag = [];
					let _vault_plan = []; //vault 套餐， 非赠送
					let _is_vault = [];
					let _vault_plan_ii = [];

					let _cloudOrderList = [];

					let _all_size_plan = [];  // 所有空间的套餐
					let _all_vault_plan = []; // 所有网盘的套餐

					for(let item of data){
						if([7,8,9].includes(Number(item.order_type))){
							if(item.order){
								if(item.order.token){
									item.order.id = item.order.token;
								}
							}
						}

						if(item.order){

						}else{
							item.order = {}
						}

						let _etime = Number(String(item.end_time).padEnd(13, '000'));
						item.show_time = dayjs(_etime).format('YYYY-MM-DD');
						if((window.location['host']).includes('localhost') || (window.location['host']).includes('storage.meshare.cn')){
							item.show_time = dayjs(_etime).format('YYYY-MM-DD hh:mm:ss');
						}

						if(Number(item.flag) === 1){
							_all_size_plan.push(item);

							if(Number(item?.order?.size_type) > 0){
								_all_vault_plan.push(item);
							}
						}

						if(Number(item.flag) === 1 && ( ![5,6,9].includes(Number(item.order_type)) ) ){
							_cloudOrderList.push(item);
						}

						// 已取消， 判断是否已结束
						if(Number(item?.order?.status) === 2){
							let end_time = Number(item?.end_time);
							if(end_time > 0){
								end_time = end_time * 1000;
								let nowTime = new Date().getTime();
								if(end_time < nowTime){
									item.order.status = 5;
								}
							}
						}

						// 赠送的空间 300G
						if([1,2,7].includes(Number(item.order_type)) ){
							_basic_size = _basic_size + 300;
							if([0,1,3].includes(Number(item?.order?.status))){
								_basic_size_usable = _basic_size_usable + 300;

								_flag.push(Number(item.order_type));
							}
						}else if([3,4,8].includes(Number(item.order_type))){  // 赠送的空间 500G
							_basic_size = _basic_size + 500;
							if([0,1,3].includes(Number(item?.order?.status))){
								_basic_size_usable = _basic_size_usable + 500;

								_flag.push(Number(item.order_type));
							}
						}else if(Number(item.order_type) === 5){  //5：Vdr Apple 云计划
							_dcs_iap = item;

							if([0,1,3].includes(Number(item?.order?.status))){
								_flag.push(Number(item.order_type));
								_vault_plan.push(item);
							}else{
								_is_vault.push(Number(item.order_type));
								_vault_plan_ii.push(item);
							}
						}else if(Number(item.order_type) === 6){  //6：Vdr Google 云计划
							_dcs_gpa = item;

							if([0,1,3].includes(Number(item?.order?.status))){
								_flag.push(Number(item.order_type));
								_vault_plan.push(item);
							}else{
								_is_vault.push(Number(item.order_type));
								_vault_plan_ii.push(item);
							}
						}else if(Number(item.order_type) === 9){  //9：Vdr web pay云计划
							_dcs_web = item;

							if([0,1,3].includes(Number(item?.order?.status))){
								_flag.push(Number(item.order_type));
								_vault_plan.push(item);
							}else{
								_is_vault.push(Number(item.order_type));
								_vault_plan_ii.push(item);
							}
						}
					}

					if(_flag.length === 1){
						if(_flag[0] === 5 || _flag[0] === 6 || _flag[0] === 9){
							setOnlyVaultPlan(() => true);
							setOnlyVaultPlanData(() => _vault_plan[0]);
						}
					}else if(_is_vault.length === 1){
						if(_is_vault[0] === 5 || _is_vault[0] === 6 || _flag[0] === 9){
							setOnlyVaultPlan(() => true);
							setOnlyVaultPlanData(() => _vault_plan_ii[0]);
						}
					}

					setCloudOrderList(() => _cloudOrderList);


					let _has_3T_plan = false;
					for(let item of _all_vault_plan){
						if(Number(item?.order?.size_type) === 2){
							_has_3T_plan = true;
						}
					}

					setHas3Tplan(() => _has_3T_plan);


					if(_basic_size_usable){
						let _currentBasicPlan:any = {}
						_currentBasicPlan.name = `Basic (${_basic_size_usable}GB)`;
						_currentBasicPlan.status = 1;
						setCurrentBasicPlan(() => _currentBasicPlan);
					}else if(_basic_size){

					}

					let _currentPlanList = [];
					let _nextPlanList = [];
					// if(isApple()){
						if(_dcs_web){
							_currentPlanList.push(_dcs_web);

							if(_dcs_web?.order?.next){
								_dcs_web.next = _dcs_web.order.next;
								_nextPlanList.push(_dcs_web);
							}
						}
						if(_dcs_iap){
							_currentPlanList.push(_dcs_iap);

							if(_dcs_iap?.order?.next){
								_dcs_iap.next = _dcs_iap.order.next;
								_nextPlanList.push(_dcs_iap);
							}
						}
						if(_dcs_gpa){
							_currentPlanList.push(_dcs_gpa);

							if(_dcs_gpa?.order?.next){
								_dcs_gpa.next = _dcs_gpa.order.next;
								_nextPlanList.push(_dcs_gpa);
							}
						}

					// }else{
					// 	if(_dcs_gpa){
					// 		_currentPlanList.push(_dcs_gpa);
					//
					// 		if(_dcs_gpa?.order?.next){
					// 			_dcs_gpa.next = _dcs_gpa.order.next;
					// 			_nextPlanList.push(_dcs_gpa);
					// 		}
					// 	}
					// 	if(_dcs_iap){
					// 		_currentPlanList.push(_dcs_iap);
					//
					// 		if(_dcs_iap?.order?.next){
					// 			_dcs_iap.next = _dcs_iap.order.next;
					// 			_nextPlanList.push(_dcs_iap);
					// 		}
					// 	}
					// }

					setCurrentPlanList(() => _currentPlanList);
					setNextPlanList(() => _nextPlanList)
				}

			}).catch(e => {
				console.log(e)
				let {message} = e;
				if(message){
					Toast.show({
						content: message,
						afterClose: () => {
							console.log('after')
						},
					})
				}
			}).finally(() => {
				getProducts();
			})
		} catch (e) {
			localStorage.clear()
			navigate("/login")
		}
	}

	const getProducts = () => {
		let req = {
			token: getLGToken(),
			platform: '21',
		}
		// setLoadStatus(() => true);
		let _reqFunc = isApple() ? planListIAP : planListGPA;

		_reqFunc(req).then((res:any) => {

			const {result, data} = res;
			if(result === 'ok' && isJSON(data)){
				let _obj = data;
				let _arr = [];
				if(isJSON(_obj)){
					for(let _key in _obj){
						if(isJSON(_obj[_key])){
							_arr.push({
								..._obj[_key],
								key: _key
							})
						}

					}
				}

				setProducts(() => _arr);
			}
		}).catch(e => {
			console.log(e)
			let {message} = e;
			if(message){
				Toast.show({
					content: message,
					afterClose: () => {
						console.log('after')
					},
				})
			}
		}).finally(() => {
			setTimeout(() => {
				setLoadStatus(() => false);
			},10)
		})
	}

	useEffect(() => {
		// clearTimeout(timeOutRef.current);

		// timeOutRef.current = setTimeout(() => {
		// 	 // getCloudDiscInfo();
		// }, 200)


		return () => {
			clearTimeout(timeOutRef.current);
		}
	},[])

	useEffect(() => {
		if(isStorage){
			clearTimeout(timeOutRef.current);

			timeOutRef.current = setTimeout(() => {
				getCloudDiscInfo();
			}, 200)
		}else{
			clearTimeout(timeOutRef.current);
		}
	}, [isStorage])


	const toPage = (_path) => {
		// navigate(`${_path}?token=${token}`);

		jumpPage(navigate, _path)
		onClose()
	}

	useEffect(() => {
		if(products.length > 0 && cloudDiskInfo.root_id && !has3Tplan){
			recommendedFunc();
		}
	}, [cloudDiskInfo, products, currentPlanList, has3Tplan])

	const recommendedFunc = () => {
		// cloudDiskInfo
		// products
		let _g1 = 1024 * 1024 * 1024 * 1; // 1GB
		let _g30 = _g1 * 30;
		let _g1000 = _g1 * 1000;
		let _g3000 = _g1 * 3000;

		let _size = cloudDiskInfo.size;
		let _size_type = 0;

		if(_size < _g3000){
			_size_type = 2;
		}
		if(_size < _g1000){
			_size_type = 1;
		}
		// if(_size < _g30){
		// 	_size_type = 0;
		// }

		let _currentPlanList = JSON.parse(JSON.stringify(currentPlanList));
		let _pay_type = 1;
		if(Array.isArray(_currentPlanList)){
			if(_currentPlanList.length > 0){
				_pay_type = _currentPlanList[0]['order']['pay_type'];
			}
		}

		_pay_type = Number(_pay_type);
		let _recommendPlan:any = null;
		if(_size_type > 0 && Array.isArray(products) && products.length > 0){
			for(let item of products){
				// if(Number(item.pay_type) === _pay_type && _size_type === Number(item.size_type)){
				// 	_recommendPlan = item;
				// }

				if(Number(item.pay_type) === _pay_type && Number(item.size_type) === 2){
					_recommendPlan = item;
				}
			}
		}

		if(_recommendPlan !== null){
			setRecommendPlan(() => _recommendPlan);
		}
	}




	const viewDetailsFun = (o) => {
		// order_id=' + oid + '&order_type=2' + '&token='+ (_tokenid) + '&plan_type=' + (o.plan_type)

		//order_type:
		//1：Apple 云告警
		// 2：Google 云告警
		// 3：Apple 云录像
		// 4：Google 云录像
		// 5：Vdr Apple 云计划
		// 6：Vdr Google 云计划
		// 7：Web pay 云告警
		// 8：Web pay 云录像
		// 9：Vdr web pay云计划

		let typePayMap = {
			'1': 'apple',
			'2': 'google',
			'3': 'apple',
			'4': 'google',
			'5': 'apple',
			'6': 'google',
			'7': 'web_pay',
			'8': 'web_pay',
			'9': 'web_pay',
		}

		let {order, order_type} = o;
		if(order){
			let {id,pay_type,size_type} = order;
			order['order_pf'] = typePayMap[order_type];
			let _params = `?order_type=${order_type}&size_type=${size_type}&order_pf=${typePayMap[order_type]}&pay_type=${pay_type}&id=${id}`
			navigate(`/cloud-plan-form-jump/view${_params}`, {state: {data: order}})
		}

	}

	const toSubscribeFun = () => {

		let data = {
			size_type: 2,
			order_pf: 'web_pay',
			pay_type: 2,
			order_type: 9,
			is_vault: 1,
		}

		let _params = `?order_type=${data.order_type}&size_type=${data.size_type}&order_pf=${data.order_pf}&pay_type=${data.pay_type}`
		navigate(`/cloud-plan-form-jump/subscribe${_params}`, {state: {order: data}})

		// navigate('/cloud-plan')
	}

	const upgradeFunc = (_obj) => {
		toSubscribeFun();

		// submitOrder(_obj);
	}


	const cancelPlanFunc = () => {
		// setVisible(() => true);
		//
		// return;

		if(isApple()){
			if(NAPI.check()){
				NAPI.open('https://apps.apple.com/account/subscriptions');
			}else{
				window.location.href = 'https://apps.apple.com/account/subscriptions';
			}
		}else{
			if(NAPI.check()){
				NAPI.open('https://play.google.com/store/account/subscriptions');
			}else{
				window.location.href = 'https://play.google.com/store/account/subscriptions';
			}
		}
	}

	const getPlanSizeType = (arr) => {
		let _arr = [];
		if(Array.isArray(arr)){
			for(let item of arr){

				if(item.hasOwnProperty('order')){
					let _order = item.order;
					if(isJSON(_order)){
						_arr.push(Number(_order.size_type));
					}
				}
			}
		}
		return _arr;
	}

	const orderIsExpiredFunc = (_ls) => {
		let res = false;
		for(let item of _ls){
			// console.log(item);
			if(isJSON(item.order)){
				if(([0,1,3].includes(Number(item.order.status))) && !res){
					res = true;
				}
			}
		}
		return res;
	}

	let _store_percent = cloudDiskInfo.percent < 3 ? 3 : cloudDiskInfo.percent;
	_store_percent = _store_percent > 100 ? 100 : _store_percent;
	_store_percent = cloudDiskInfo.size === 0 ? 100 : _store_percent;
	let _current_order_type_size_arr = currentPlanList.length === 0 ? [] : getPlanSizeType(currentPlanList);
	let _progress_bar_fill_color = _store_percent < 80 ? 'var(--adm-color-primary)' : (_store_percent < 90 ? 'var(--adm-color-warning)' : 'var(--adm-color-danger)');

	let _medrive_plan_is_no_expired = orderIsExpiredFunc(currentPlanList);

	let planName = {
		1: 'Cloud Storage(300GB)',
		2: 'Cloud Storage(500GB)',
		3: 'Basic(1TB)',
		4: 'Advanced(3TB)'
	}

	const onClose = () => {
		onIsStorage(false);
		onIsStorage(false);
	}

	const handleCleanUpVault = () => {
		onIsCleanUpMeDrive(true)
		onIsStorage(false)
	}

	const handleTrash = async () => {
		if (location.pathname.includes("/trash")) {
			onClose()
			onClose()
		} else if (location.pathname.includes("vault")){
			toPage(`/trash`)
		}
	}


	return <Drawer open={isStorage} width={464} className="page-storge-con right-popup" onClose={onClose}>
		<div className={'page-storage'}>
		<div className='details-modal-title'>
            <p></p>
            <p>Vault Management</p>
            <p onClick={onClose}>
                <Icon component={CloseIcon} style={{ fontSize: "14px" }} />
            </p>
        </div>
			<div className={'page-body-container'}>
				<div className={'a-card-i'} style={{marginBottom: '12px'}}>
					<div className={'a-card-ii'} style={{paddingTop: '25px', paddingBottom: '25px'}}>
						<div className={'a-plan-name-18'}>Storage  {cloudDiskInfo._size}</div>
						<div className={'a-progress-bar'}>
							<ProgressBar percent={_store_percent} style={{"--fill-color": _progress_bar_fill_color}} />
						</div>
						<div className={'a-bar-size'} style={{marginBottom: 0}}>{cloudDiskInfo._total} of {cloudDiskInfo._size} used</div>
					</div>
					<div className={'a-list'}>
						<div className={'a-item'}  onClick={() => toPage(`/vault/photo?file_type=3`)}>
							<div className={'a-flex-i'}>
								<span className={'a-icon'}>
									<img src={BaseResource('/res/icons/icon-Images-a.svg')} alt=""/>
								</span>
								<span className={'a-label'}>Images</span>
							</div>

							<div className={'a-size'}>{cloudFileInfo._imageSize}</div>
						</div>

						<div className={'a-item'}  onClick={() => toPage(`/vault/photo?file_type=1`)}>
							<div className={'a-flex-i'}>
								<span className={'a-icon'}>
									<img src={BaseResource('/res/icons/video_off.svg')} alt=""/>
								</span>
								<span className={'a-label'}>Videos</span>
							</div>

							<div className={'a-size'}>{cloudFileInfo._videoSize}</div>
						</div>

						<div className={'a-item'}  onClick={() => toPage(`/vault/large-file?file_type=2`)}>
							<div className={'a-flex-i'}>
								<span className={'a-icon'}>
									<img src={BaseResource('/res/icons/audio_off.svg')} alt=""/>
								</span>
								<span className={'a-label'}>Audio</span>
							</div>
							<div className={'a-size'}>{cloudFileInfo._audioSize}</div>
						</div>

						<div className={'a-item'}  onClick={() => toPage(`/vault/large-file?file_type=4`)}>
							<div className={'a-flex-i'}>
								<span className={'a-icon'}>
									<img src={BaseResource('/res/icons/document_off.svg')} alt=""/>
								</span>
								<span className={'a-label'}>Document</span>
							</div>

							<div className={'a-size'}>{cloudFileInfo._fileSize}</div>
						</div>
						<div className={'a-item'}  onClick={() => toPage(`/vault/large-file?file_type=5`)}>
							<div className={'a-flex-i'}>
								<span className={'a-icon'}>
									<img src={BaseResource('/res/icons/others_off.svg')} alt=""/>
								</span>
								<span className={'a-label'}>Others</span>
							</div>

							<div className={'a-size'}>{cloudFileInfo._otherSize}</div>
						</div>

						<div className={'a-item'}  onClick={handleTrash}>
							<div className={'a-flex-i'}>
								<span className={'a-icon'}>
									<img src={BaseResource('/res/icons/icon-Trash-a.svg')} alt=""/>
								</span>
								<span className={'a-label'}>Trash</span>
							</div>

							<div className={'a-size'}>{cloudFileInfo._trashSize}</div>
						</div>
					</div>

					<div className={'a-button-i'} onClick={handleCleanUpVault}>
						<span >Clean Up Vault</span>
					</div>
				</div>

				{
					cloudOrderList.map((item:any, index: number) => {

						return <div
							className={'a-card-ii'}
							style={{overflow: 'hidden', marginBottom: '12px'}}
							key={index}
						>
							<div className={'a-plan-name'}>{cloudPlan?.[item.storage_desc]?.['title']} ({orderPlatform?.[item.order_type]})</div>
							<div className={'a-plan-intro'}>{cloudPlan?.[item.storage_desc]?.['content']}</div>
						</div>
					})
				}

				{
					currentPlanList.map((item:any, index: number) => {
						let planName = {'1': 'Basic', '2':'Advanced'};
						let payType = {'1':'Monthly', '2':'Annual'};
						let _order_status = Number(item?.order?.status);
						let _next_plan = (item?.order).hasOwnProperty('next');

						return <div className={'c-plan-item'} key={index}>
							{item?.order?.status !== undefined && <div className={'c-tag'}>{tagObj[item?.order?.status]}</div>}
							<div className={'c-box'}>
								<p className={'c-h1'}>My Subscriptions ({orderPlatform?.[item.order_type]})</p>
								<div className={'c-row'}>
									<span className={'c-lb'}>Vault Plan</span>
									<span className={'c-val'}>{planName[item?.order?.size_type]}({payType[item?.order?.pay_type]})</span>
								</div>
								<div className={'c-row'}>
									<span className={'c-lb'}>Cloud Storage</span>
									<span className={'c-val'}>{vaultSize[item?.order?.size_type]}</span>
								</div>
								<div className={'c-row'}>
									<span className={'c-lb'}>{_next_plan ? 'Price:' : <>{[0,1,3].includes(_order_status) ? 'Renewal Price:' : 'Price:'}</>}</span>
									<span className={'c-val'}>${item?.order?.price}</span>
								</div>
								<div className={'c-row'}>
									<span className={'c-lb'}>{_next_plan ? 'Expire Date:' : <>{[0,1,3].includes(_order_status) ? 'Renew date:' : 'Expire Date:'}</>}</span>
									<span className={'c-val'}>{item?.show_time}</span>
								</div>
							</div>
							<div className={'c-btn'} onClick={() => viewDetailsFun(item)}>
								View Details
							</div>
						</div>
					})
				}


				{
					nextPlanList.length > 0 && <>
            <div className={'a-inner-title'} style={{marginTop: '32px'}}>
              <span className={'a-inner-icon'}></span>
              <span className={'a-inner-label'}>Next Plan</span>
            </div>

						{
							nextPlanList.map((item:any, index:number) => {

								return <div
									className={'a-card-ii'}
									key={index}
									style={{marginBottom: '16px'}}
								>
									<div className={'a-plan-name'}>{storageSizeObj[item.next.size_type]}</div>
									<div className={'a-flex-ii'}>
										<div className={'a-price-con'}>
											<div className={'a-price-num'}>
												<span className={'a-price-xiff'}>$</span>
												<span className={'a-price-nui'}>{item.next.price}</span>
												<span className={'a-price-unit'}>
													{Number(item.next.pay_type) === 1 ? '/month' : '/year'}
												</span>
											</div>
											<div className={'a-price-desc'}>
												Renew date: {item.show_time}
											</div>
										</div>
									</div>
								</div>
							})
						}

					</>
				}

				{/*{_current_order_type_size_arr}*/}
				{
					(currentPlanList.length > 0 || currentBasicPlan) && recommendPlan.size_type && (!_current_order_type_size_arr.includes(Number(recommendPlan.size_type))) && <>
            <div className={'a-card-iii'}>
              <div className={'a-con-i'}>
                {/*<div className={'a-hot-i'}>*/}
                {/*  <img src={'/res/icons/icon_like.svg'} alt=""/>*/}
                {/*  <span>Recommended</span>*/}
                {/*</div>*/}
                <div className={'a-plan-name'}>{storageSizeObj[recommendPlan.size_type]}</div>
                <div className={'a-intro-ii'}>Get more room for photos, videos and files.</div>
                <div className={'a-flex-ii'}>
                  <div className={'a-price-con'}>
                    <div className={'a-price-num'}>
                      <span className={'a-price-xiff'}>$</span>
                      <span className={'a-price-nui'}>{recommendPlan.price}</span>
                      <span className={'a-price-unit'}>{recommendPlan.pay_type === 2 ? '/year' : '/month'}</span>
                    </div>
                    <div className={'a-price-desc'}>Cancel anytime.</div>
                  </div>
                  <div className={'a-btn-con'}>
                    <Button
											className={'a-primary-btn'}
											onClick={() => upgradeFunc(recommendPlan)}
                      loading={submitStatus && evtTypeRef.current === 'upgrade'}
                      disabled={submitStatus}
                      loadingIcon={<SpinLoading color='#ffffff' style={{'--size': '19px'}} />}
										>
											Upgrade
                    </Button>
										{/*submitStatus*/}
                  </div>
                </div>
              </div>
              {/*<div className={'a-button-i'}>*/}
              {/*  <span onClick={() => toPage('/plan')}>See More Plans</span>*/}
              {/*</div>*/}
            </div>
					</>
				}

			</div>
		</div>


		<InfoMadal
			visible={visible}
			content={
				<div className={'a-modal-container-i'}>
					<div className={'a-i-body'}>
						<div className={'a-i-icon'}>
							<img src={'/res/icons/icon.png'} alt="" style={{width: '100px'}}/>
						</div>

						<p className={'a-i-intro'}>
							If you cancel while using more than
							<b> 5 GB</b> of storage, you’ll notice some
							restrictions.
							<br />
							You can still access all the functionality
							until <b>August 10, 2023</b>
							<br />
							You will be unable to upload new files
							until you reduce your storage usage to
							below <b>5 GB</b>. You can still access your
							current files.
						</p>
					</div>
					<div className={'a-i-foot'}>
						<span className={'a-btn-cancel'} onClick={() => setVisible(false)}>Cancel Anyway</span>
						<button onClick={() => toPage('/clean-up-medrive')}>Clean Up</button>
					</div>
				</div>
			}
		/>
	</Drawer>
}
