import './index.less'
import {Button, Drawer, Row, Col, message, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import CloseIcon from '../../../../assets/images/cloudPlan/close.svg'
import SafetyTips from '../../../../assets/images/cloudPlan/safety_tips.svg'
import {getCloudOrderInfo} from '../../../../server/plan'
import dayjs from "dayjs";
import Icon, {LoadingOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import pfFileUrl from "../../../../server/PFFileUrl";
import {
	cancelBRTVaultOrder,
	cancelCloudSubscription,
	cancelVaultSubscription, getBraintreeOrderList,
	getInfoSubscription,
	getVdrInfo, submitReport
} from "../../../../server/cloudPlan";
import {
	useChangeCloudDeviceStore,
	useCloudSubscribeStore,
	useOrderDetailStore,
	useVaultSubscribeStore
} from "../../../../store/cloudPlan";
import _ from "lodash";
import {getLGToken} from "../../../../utils/common";
import {
	IconCloudPlan_1,
	IconCloudPlan_2,
	IconCloudPlan_3,
	IconCloudPlan_4,
	IconCloudPlan_5,
	IconCloudPlan_6, IconVaultPlan_1, IconVaultPlan_2, IconVaultPlan_3, IconVaultPlan_4, IconVaultPlan_5, IconVaultPlan_6
} from "../../iconsMap";
import CommModal from "../../../../components/commModal";
import IconNoo from "../../../../assets/images/comm/noo.png";
import IconYee from "../../../../assets/images/comm/yee.png";
import usePlatform from "../../../../utils/platform";

const cloudPlanNameObj = {
	'1_3': 'Continuous',
	'2_3': 'Clips',
	'5_2': 'Plus',
	'5_3': 'Premium',
	'6_3': 'Platinum',
}

const msg_is_android = 'This subscription was created in Android App, so please manage it in the Android App';
const msg_is_apple = 'This subscription order was created in iOS App, so please manage it in the iOS App';

const DetailsModal = (props) => {
	const navigate = useNavigate();
	const {open} = props;
	const [devicesList, setDevicesList] = useState<any>([])

	const [loading, setLoading] = useState<boolean>(false);
	const [showData, setShowData] = useState<any>({});

	const [detailsObj, setDetailsObj] = useState<any>({});

	const [cancelVaultA, setCancelVaultA] = useState<boolean>(false);
	const [cancelVaultB, setCancelVaultB] = useState<boolean>(false);
	const [cancelVaultFormCont, setCancelVaultFormCont] = useState<string>('');
	const [submitReportStatus, setSubmitReportStatus] = useState<boolean>(false);

	const {setCloudInfoZU} = useCloudSubscribeStore();
	const {setVaultInfoZU} = useVaultSubscribeStore();
	const {orderDetailZU, setOrderDetailZU} = useOrderDetailStore();
	const {setChangeCloudDeviceZU} = useChangeCloudDeviceStore();

	const platformObject:any = usePlatform()


	useEffect(() => {
		if (open) {
			setShowData(() => {});
			setDevicesList(() => []);
		} else {

		}
		// console.log(orderDetailZU, 'DetailsModal');
	}, [open])

	useEffect(() => {
		let {data} = orderDetailZU;
		if(data){
			setDetailsObj(() => data);
		}
	}, [orderDetailZU])

	const onClose = () => {
		// onOpen(false);
		//orderDetailZU.data
		setOrderDetailZU({open: false, data: {}});
	};

	const initDatails = async () => {
		let req:any = {
			token: getLGToken(),
			order_id: detailsObj.id,
			type: detailsObj.type
		}

		if(detailsObj.size_type){
			req['is_vault'] = 1
		}

		setLoading(() => true);
		const res: any = await getCloudOrderInfo(req);
		setTimeout(() => {
			setLoading(() => false);
		},200)


		const {result, data, error} = res;

		if (result === "ok" && data) {
			let order_info = data.hasOwnProperty('order_current') ? data.order_current : {};
			let dev_arr = order_info.hasOwnProperty('devices') ? order_info.devices : [];

			order_info.dis_name = cloudPlanNameObj[`${order_info.plan_type}_${order_info.period}`];
			order_info.dis_pay_type = order_info.pay_type === 1 ? 'Monthly' : 'Yearly';;
			order_info.dis_dev_count = order_info.devices_count;
			order_info.dis_price = order_info.price;
			order_info.is_renewal = '';
			order_info.dis_date = dayjs((order_info.end_time * 1000)).format('MM/DD/YYYY');

			if(detailsObj.size_type){
				order_info.dis_name = Number(order_info.size_type) === 1 ? 'Basic' : (Number(order_info.size_type) === 2 ? 'Advanced' : '');
				order_info.dis_size = Number(order_info.size_type) === 1 ? '1TB' : (Number(order_info.size_type) === 2 ? '3TB' : '');
			}

			setShowData(() => order_info);


			setDevicesList(() => dev_arr);

		} else {
			if(error){
				message.error(error);
			}else{
				message.error("error");
			}

		}
	}

	const webPayOrderInfo = async (id) => {
		setLoading(() => true);

		let _func = detailsObj.size_type ? getVdrInfo : getInfoSubscription;

		let res:any = await _func(id);
		setLoading(() => false);
		if(res){
			let dev_arr = res?.devices;
			dev_arr = Array.isArray(dev_arr) ? dev_arr : [];

			res.dis_name = cloudPlanNameObj[`${res.plan_type}_${res.period}`];
			res.dis_pay_type = Number(res.pay_type) === 1 ? 'Monthly' : 'Yearly';
			res.dis_dev_count = Number(res.device_count) === 100 ? 'Unlimited' : Number(res.device_count);
			res.dis_price = (Number(res.amount_in_cent) / 100).toFixed(2);
			if(['FREETRIAL', 'ACTIVE', 'SCHEDULED'].includes((res.status).toUpperCase())){
				res.is_renewal = true;
				res.dis_date = res.next_payment_day && Number(res.next_payment_day) !== 0 ? dayjs(res.next_payment_day).format('MM/DD/YYYY') : '---';
			}else{
				res.is_renewal = false;
				res.dis_date = res.next_payment_day && Number(res.next_payment_day) !== 0 ? dayjs(res.next_payment_day).format('MM/DD/YYYY') : '---';
			}

			if(detailsObj.size_type){
				res.dis_name = Number(res.size_type) === 1 ? 'Basic' : (Number(res.size_type) === 2 ? 'Advanced' : '');
				res.dis_size = Number(res.size_type) === 1 ? '1TB' : (Number(res.size_type) === 2 ? '3TB' : '');
			}

			setShowData(() => res);
			setDevicesList(() => dev_arr);
		}
	}

	const getBRTOrderFun = async () => {
		let res:any = await getBraintreeOrderList();
		let {result, data} = res;

		if(result === 'ok' && data){
			let {subscriptions} = data;
			if(Array.isArray(subscriptions)){
				let cvr_order = [];
				let cvr_item:any = {};
				let vault_order = [];
				let vault_item:any = {};

				for(let item of subscriptions){

					if([1000,3000].includes(item.plan)){
						vault_order.push(item);
						vault_item = item;
					}else{
						cvr_order.push(item);
						cvr_item = item;
					}
				}

				if(vault_item.id){
					let vault:any = {
						id: vault_item.id,
						size_type: 0,
						pay_type: vault_item.duration,
						price: vault_item.renewAmount,
						end_time: vault_item.nextPaymentDateString,
						order_pf: 'BRT',

						dis_name: '',
						dis_pay_type: '',
						dis_price: '',
						is_renewal: '',
						dis_date: '',
						dis_size: '',
					}

					if(vault_item.plan === 1000){
						vault.size_type = 1;
					}else if(vault_item.plan === 3000){
						vault.size_type = 2;
					}
					vault.dis_pay_type = Number(vault.pay_type) === 1 ? 'Monthly' : 'Yearly';
					vault.dis_name = Number(vault.size_type) === 1 ? 'Basic' : (Number(vault.size_type) === 2 ? 'Advanced' : '');
					vault.dis_size = Number(vault.size_type) === 1 ? '1TB' : (Number(vault.size_type) === 2 ? '3TB' : '');
					vault.dis_date = vault.end_time;
					vault.dis_price = vault.price;

					//'-1': 'FREETRIAL',
					// 	0: 'Pedding',
					// 	1: 'Subscribed',
					// 	2: 'Subscribed',
					// 	3: 'Expired',
					// 	4: 'Cancelled',
					// 	5: 'Expired',
					// 	6: '',
					// 	7: 'Billing Issue',
					// 	8: 'Expired'

					if([-2,7].includes(vault_item.status)){
						vault.status = 5;
					}else if([1,6,9].includes(vault_item.status)){
						vault.status = 1;
						vault.is_renewal = true;
					}else if([-1,7].includes(vault_item.status)){
						vault.status = 4;
					}else if([2,3].includes(vault_item.status)){
						vault.status = 7;
					}

					setShowData(() => vault);
				}
			}
		}
	}

	useEffect(() => {

		if(open){
			let _detailsObj:any = detailsObj ? detailsObj : {}

			if(_detailsObj.id){
				if(_detailsObj.order_pf === 'web_pay'){
					webPayOrderInfo(_detailsObj.id)
				}else if(_detailsObj.order_pf === 'BRT'){
					getBRTOrderFun();
				}else{
					initDatails()
				}
			}

		}

	}, [detailsObj, open])


	const changePlanFun = () => {

		onClose();

		// 网盘
		if(showData.size_type){
			setVaultInfoZU({open: true, data: {...showData, change_plan: 1}})
		}else{ // 云服务
			setCloudInfoZU({open: true, data: {...showData, change_plan: 1}})
		}

		// navigate(`/subscribe-security?target=${target}`);
	}

	const loadDevIconErrorFun = (e) => {
		e.target.src = '/res/icons/camera-1.png';
	}

	const unsubscibeFun = () => {
		if(detailsObj?.size_type){
			setCancelVaultA(() => true);
			setSubmitReportStatus(() => false);
		}else{
			submitUnsubscibeData();
		}
	}

	const submitUnsubscibeData = async () => {
		let _func = detailsObj?.size_type ? cancelVaultSubscription : cancelCloudSubscription;
		let _token = getLGToken()
		let req:any = {
			user_token: _token,
			subscription_id: detailsObj?.id
		}

		if(detailsObj?.size_type){
			req = {
				user_token: _token,
				vdr_id: detailsObj?.id
			}
		}

		let res:any = await _func(req);
		setSubmitReportStatus(() => false);
		if(res === 'ok'){
			message.success("Cancel successfully!")
			onClose()
			window.location.reload();
		}else{
			if(res?.error){
				message.error(res?.error);
			}else{
				message.error("error");
			}
		}
	}

	const changeDeviceFunc = () => {
		let data = _.cloneDeep(detailsObj)
		setChangeCloudDeviceZU({open: true, data: data});

		onClose();
	}

	const reSubscibeFun = () => {
		onClose();

		// 网盘
		if(showData.size_type){
			setVaultInfoZU({open: true, data: {...showData, re_subscribe: 1}})
		}else{ // 云服务
			setCloudInfoZU({open: true, data: {...showData, re_subscribe: 1}})
		}
	}

	const cancelVaultAOnOkFun = () => {
		cancelVaultAOnCancelFun();

		setCancelVaultB(() => true);
	}

	const cancelVaultAOnCancelFun = () => {
		setCancelVaultA(() => false);
	}

	const cancelVaultBOnOkFun = async () => {
		let {id, order_pf, size_type} = orderDetailZU.data;

	//	提交用户填写的数据
		let tagCode = `code_${dayjs().format('YYYYMMDD')}_${id}`;
		let _msg = cancelVaultFormCont.trim();

		let a_data = {
			content: _msg,
			order_id: id,
			order_type: order_pf,
			is_vault: size_type?1:0
		}

		let _tokenid = getLGToken();

		let req  = {
			token   : _tokenid,
			platform: platformObject.id,
			code: tagCode,
			client  : 2,
			check   : false,
			data : JSON.stringify(a_data),
		};

		setSubmitReportStatus(() => true);

		// 提交数据
		if(_msg){
			await submitReport(req);
		}

		if(order_pf === 'BRT'){
			await cancelVaultBRTOrderFun();
		}else{
			await submitUnsubscibeData();
		}
	}

	const cancelVaultBRTOrderFun = async () => {
		let res:any = await cancelBRTVaultOrder();
		let {result, data} = res;
		if(result === 'ok'){
			message.success("Cancel successfully!")
			setSubmitReportStatus(() => false);
			onClose();
			window.location.reload();
			getBRTOrderFun(); //更新数据
		}else{
			message.error("error")
		}
	}

	const cancelVaultBOnCancelFun = () => {
		setCancelVaultB(() => false);
	}

	const cancelVaultFormContFun = (e) => {
		let {value} = e.target;
		setCancelVaultFormCont(() => value);
	}

	useEffect(() => {
		if(cancelVaultB){

		}else{
			setCancelVaultFormCont(() => '');
		}
	}, [cancelVaultB])

	let _showData:any = showData === undefined ? {} : showData;
	let _devicesList:any = devicesList === undefined ? [] : devicesList;
	let _opacity = loading;

	return <Drawer
		title="Basic Drawer"
		placement="right"
		width={640}
		className='details-modal'
		onClose={onClose}
		open={open}
	>
		<div className='details-modal-title'>
			<p></p>
			<p>Details</p>
			<p onClick={onClose}>
				<Icon component={CloseIcon} style={{fontSize: "14px"}}/>
			</p>
		</div>

		{
			loading && <div className={'loading-box'}>
        <Spin indicator={<LoadingOutlined spin />} size={'large'} />
      </div>
		}


		<div className='my-subscriptions-con'>

			<Row gutter={24}>
				<Col span={24}>
					<div className='my-subscriptions-card a-opacity' style={{opacity: _opacity ? 0 : 1}}>
						<div>
							<h4>My Subscriptions -<span style={{fontSize: '75%', color: '#16ADFD'}}>{detailsObj.order_pf}</span></h4>
						</div>
						<div>
							<p>{_showData.size_type ? 'Vault' : 'Safeguard'} Plan</p>
							{
								_showData.dis_name && <p>
									{_showData.dis_name}
                  ({_showData.dis_pay_type})
                </p>
							}

						</div>

						{
							_showData.size_type ? <div>
								<p>Cloud Storage</p>
								<p>{_showData.dis_size}</p>
							</div>
							:
							<div>
								<p>Device Quantity</p>
								<p>{_showData.dis_dev_count}</p>
							</div>
						}


						{
							_showData.is_renewal ? <div>
								<p>Renewal Price</p>
								{
									_showData.dis_price && <p>${_showData.dis_price}</p>
								}
							</div>
							:
							null
						}


						{
							_showData.is_renewal ? <div>
								<p>Renewal Date</p>
								<p>{_showData.dis_date}</p>
							</div>
							:
							<div>
								<p>Expired Date</p>
								<p>{_showData.dis_date}</p>
							</div>
						}


						{
							detailsObj.order_pf === 'web_pay' || detailsObj.order_pf === 'BRT' ? <>
									{detailsObj.o_status && (['FREETRIAL', 'ACTIVE'].includes((detailsObj.o_status).toUpperCase())) ?
										<div onClick={changePlanFun}>Change Plan</div>
										:
										<div style={{border: 'none', height: '1px'}}></div>
									}
								</>
								:
								<div style={{fontSize: '12px', fontFamily: 'HelveticaNeue', cursor: 'default'}}>
									{
										detailsObj.order_pf === 'apple' ? msg_is_apple : msg_is_android
									}
								</div>
						}

					</div>
				</Col>
			</Row>
		</div>

		{
			_showData.size_type ? null : <div className='cloud-device a-opacity' style={{opacity: _opacity ? 0 : 1}}>
				<div className='cloud-device-title'>
					<p>Enabled Device(s)</p>
					<p onClick={changeDeviceFunc}>Change Device</p>
				</div>

				{
					_devicesList.length === 0 ? <div>
						<div className='cloud-device-card' style={{justifyContent: 'center'}}>
							<span style={{opacity: 0.5}}>No Device</span>
						</div>
					</div>
					:
					<div>
						{
							_devicesList.map((item: any, index: number) => {
								return <div className='cloud-device-card' key={index}>
									<div className='cloud-device-card-l'>
										<p><img src={pfFileUrl(item.pic_url)} alt="" onError={loadDevIconErrorFun}/></p>
										<p>{item.device_name}</p>
									</div>
									{/*<div className='cloud-device-card-r'><Icon component={item.selected ? Selected : Unselected}*/}
									{/*																					 style={{fontSize: "24px"}}/></div>*/}
								</div>
							})
						}
					</div>
				}
			</div>
		}

		{
			detailsObj.size_type ? <div className='cloud-features a-opacity' style={{opacity: _opacity ? 0 : 1}}>
				<div className='cloud-features-title'>Cloud Features</div>
				<div className='cloud-features-con'>
					<Row>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconVaultPlan_1} style={{fontSize: "22px"}}/></p>
								<p>Camera Roll Backup</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconVaultPlan_2} style={{fontSize: "22px"}}/></p>
								<p>File Storage</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconVaultPlan_3} style={{fontSize: "22px"}}/></p>
								<p>File Preview</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconVaultPlan_4} style={{fontSize: "22px"}}/></p>
								<p>Muti-platform Access</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconVaultPlan_5} style={{fontSize: "22px"}}/></p>
								<p>Sharing</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconVaultPlan_6} style={{fontSize: "22px"}}/></p>
								<p>Bank-level Security</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>

			:

			<div className='cloud-features a-opacity' style={{opacity: _opacity ? 0 : 1}}>
				<div className='cloud-features-title'>Cloud Features</div>
				<div className='cloud-features-con'>
					<Row>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconCloudPlan_1} style={{fontSize: "22px"}}/></p>
								<p>Video Recording</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconCloudPlan_2} style={{fontSize: "22px"}}/></p>
								<p>Cloud Storage</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconCloudPlan_3} style={{fontSize: "22px"}}/></p>
								<p>AI Alerts</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconCloudPlan_4} style={{fontSize: "22px"}}/></p>
								<p>Rich Notification</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconCloudPlan_5} style={{fontSize: "22px"}}/></p>
								<p>Clip-Making</p>
							</div>
						</Col>
						<Col span={8}>
							<div className='cloud-features-con-card'>
								<p><Icon component={IconCloudPlan_6} style={{fontSize: "22px"}}/></p>
								<p>Bank-level Security</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		}


		{/*退订*/}
		{
			(
				(detailsObj.order_pf === 'web_pay' && ['FREETRIAL', 'ACTIVE'].includes((detailsObj.o_status).toUpperCase()))
				|| (detailsObj.order_pf === 'BRT' && [1,6,9].includes((detailsObj.status)))
			)
			&& <div className='unsubscribe' style={{opacity: _opacity ? 0 : 1}}>
				<div onClick={unsubscibeFun}>Unsubscribe</div>
			</div>
		}
		{/*重订*/}
		{
			(
				(detailsObj.order_pf === 'web_pay'
				&& (
					['UNSUBSCRIBED'].includes((detailsObj.o_status).toUpperCase())
					|| ((!detailsObj.size_type) && ['CANCELED'].includes((detailsObj.o_status).toUpperCase()))
				))
				|| (detailsObj.order_pf === 'BRT' && ![1,6,9].includes((detailsObj.status)))
			)
			&& <div className='unsubscribe' style={{opacity: _opacity ? 0 : 1}}>
        <div onClick={reSubscibeFun}>Resubscribe</div>
      </div>
		}

		<div className='note'>
			<p><Icon component={SafetyTips} style={{fontSize: "22px"}}/></p>
			<p>The Cloud Recording is protected by bank-level encryption<br/>
				that can be accessed only by your personal account, to <br/>
				ensure that your videos are kept safe at all times.
			</p>
		</div>

		<CommModal
			open={cancelVaultA}
			cancelText={'No'}
			okText={'Yes'}
			onOk={cancelVaultAOnOkFun}
			onCancel={cancelVaultAOnCancelFun}
		>
			<div className={'a-md-cont-241230valeyolu'}>
				<div className={'a-md-icon'} style={{textAlign: "center", marginBottom: '15px'}}>
					<img src={IconNoo} alt=""/>
				</div>
				<p className={'a-md-ws'} style={{textAlign: 'center', marginBottom: '15px'}}>
					Are you sure you want to cancel your subscription?
				</p>
			</div>
		</CommModal>

		<CommModal
			open={cancelVaultB}
			cancelText={'Skip'}
			okText={'Submit'}
			onOk={cancelVaultBOnOkFun}
			onCancel={cancelVaultBOnCancelFun}
			confirmLoading={submitReportStatus}
		>
			<div className={'a-md-cont-241230valeyolu'}>
				<div className={'a-md-icon'} style={{textAlign: "center", marginBottom: '15px'}}>
					<img src={IconYee} alt=""/>
				</div>
				<p className={'a-md-ws'} style={{textAlign: 'center', marginBottom: '25px'}}>
					Why did you cancel
					your subscription, and how can we improve your experience?
				</p>
				<div className={'a-md-textarea'}>
					<textarea value={cancelVaultFormCont} onChange={cancelVaultFormContFun} style={{width: '100%', borderRadius: '3px'}} rows={5}></textarea>
				</div>
			</div>
		</CommModal>
	</Drawer>;
}

export default DetailsModal;
