import "./index.less"
import { useEffect, useRef, useState } from 'react';
import { Button, Toast, Modal, PullToRefresh } from 'antd-mobile'
import { Space, message, Input } from 'antd';
import ContentItem from "../../../components/content-itemI"
import TrashDetailsModal from "../../../components/trash-details-modal"
import DeleteModal from '../../../components/delete-modal'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getTrashList, deleteTrashFile, deleteTrashFolder, recoverTrashFile, recoverTrashFiles, recoverTrashFolder, recoverTrashFolders, clearTrash } from "../../../server/trash";
import { isJSON, sizeFormat } from "../../../utils/common";
import dayjs from "dayjs";
import fileType from "../../../utils/fileType";
import jumpPage from "../../../utils/jumpPage";
import token from "../../../utils/token";
import getApiUrl from "../../../server/apiUrl";
// import IconFolder2Svg from '../../../assets/images/file/icon_folder2.svg'
import { getStorageInformation } from "../../../server/storage";
import HomeHeadNav from "../../../components/homeHeadNav";
import HeadNavII from "../../../components/headNavII";
import StoragePage from '../Storage'
import { dateTimeFormat } from "../../../utils/common";
import TrashFileDetailsModal from "../components/trashFileDetailsModal";
import TipsModal from '../../../components/tips-modal'
import { getUserBasicCloudDisk, getUserBasicFolderIDS } from "../../../utils/common";
import CleanUpMeDrive from '../cleanUpMeDrive'
import fileTypeIcon from "../../../utils/fileTypeIcon";
import _ from 'lodash';
import { useUpdateVaultInfoStore } from "../../../store/vault";
import LoadDataWaitingState from "../../../components/loadDataWaitingState";
import { useMyNavigate } from "../../../hook/useMyNavigate";
import NoFileFoundPng from '../../../assets/images/vault/no_file_found.png'
const imageUrl = getApiUrl('cloudDisk');
// import { useVaultSystemFolderInfoStore } from "../../../store/vault";


function Trash() {
	const [list, setList] = useState<any[]>([])
	const [isShow, setIsShow] = useState<boolean>(false)
	const [isSortBy, setIsSortBy] = useState<boolean>(false)
	const [isDetails, setIsDetails] = useState<boolean>(false)
	const [detailsObj, setDetailsObj] = useState<any>({})
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
	const [folderSelectList, setFolderSelectList] = useState<any>([]);
	const [fileSelectList, setFileSelectList] = useState<any>([]);
	const [enterLongPressEvent, setEnterLongPressEvent] = useState<boolean>(false);
	const [isSearch, setIsSearch] = useState<boolean>()
	const [searchValue, setSearchValue] = useState('')
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})
	// const [storeInfo, setStoreInfo] = useState<any>({});

	const timeOutRef: any = useRef(0)
	const deleteTypeRef: any = useRef(0); // 删除类型： 0：默认 单个，1： 清空回收站
	// const diskInfoRef = useRef<any>({});

	const { navigateX } = useMyNavigate();
	const [isStorage, setIsStorage] = useState<boolean>(false)
	const [inputVal, setInputVal] = useState<string>("")
	const [isCleanUpMeDrive, setIsCleanUpMeDrive] = useState<boolean>(false)
	const [positionObj, setPositionObj] = useState<any>({
		top: 0,
		left: 0
	})
	const [isFileDetailsModal, setIsFileDetailsModal] = useState<any>(false)
	const location = useLocation();
	const params = new URLSearchParams(location.search)
	const isManagement = params.get('isManagement')
	const cFolderID = params.get('id')
	// const [visible, setVisible] = useState<boolean>(false)
	// const [itemNum, setItemNum] = useState<Number>(1)
	// const [isFolder, setIsFolder] = useState<boolean>(false)
	const containRef: any = useRef<any>(false)
	const [currentBg, setCurrentBg] = useState<number>(0) // 设置背景
	// const [isSystem, setIsSystem] = useState<number>(0)
	// const [isFile, setIsFile] = useState<number>(0)
	// const [isFold, setIsFold] = useState<number>(0)
	const [isAllHide, setIsAllHide] = useState<boolean>(false) // 显示全选按钮
	const [isAllSelect, setIsAllSelect] = useState<number>(0) // 1 未选择 2 一个和多个选择 3全选择
	const [folderList, setFolderList] = useState<any>([]); //  文件夹列表
	const [fileList, setFileList] = useState<any>([]); //  文件列表
	const [isRestore, setIsRestore] = useState<boolean>(false)
	// const [arrFileId, setArrFileId] = useState<any[]>([])
	// const [arrFolderId, setArrFolderId] = useState<any[]>([])
	const { setIsUpdateVaultInfoZU } = useUpdateVaultInfoStore();
	const [isTipsModal, setIsTipsModal] = useState<boolean>(false);
	const [isAllNum, setIsAllNum] = useState<number>(0);
	const [loadingMainData, setLoadingMainData] = useState<number>(2); // 0: 还没请求数据， 1：请求中， 2：请求结束
	const changeFolderRef = useRef<number>(2); // 目录改变状态 0：初始， 1：请求渲染页面的数据中，2：请求渲染页面的数据结束
	const pullPageFlag = useRef(false); // 下拉刷新标志
	// const {vaultSystemFolderInfoZU} = useVaultSystemFolderInfoStore();
	const [pageInfo, setPageInfo] = useState<any>({
		page: 0,
		pageSize: 10 * 1.5,
		orderBy: 'name', //name, updateTime, size, types
		order: 'asc', //Asc:顺序 Desc:逆序
		isRecycle: 1
	})
	const [searchVal, setSearchVal] = useState<string>("")
	const [isName, setIsName] = useState<boolean>(false);
	const [isCreated, setIsCreated] = useState<boolean>(false);
	const [isModified, setIsModified] = useState<boolean>(false);
	const [isSize, setIsSize] = useState<boolean>(false);
	const [isSort, setIsSort] = useState<any>(1)
	const [sortRequst, setSortRequst] = useState<boolean>(false);
	const [sizeNum, setSizeNum] = useState<any>(0)


	// 根据路由控制管理弹窗
	useEffect(() => {
		if (isManagement === 'true') {
			setIsStorage(true)
		}
	}, [isManagement])

	useEffect(() => {
		window['tokenCallback'] = tokenCallback;

		let _isShow = localStorage.getItem('me-drive-view-style');
		setIsShow(() => _isShow === 'block');
	}, [])

	const tokenCallback = (v) => {
		localStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	// const getTokenFromApp = () => {
	// 	console.log('WaitingToken getToken')
	// 	if(NAPI.check()){
	// 		NAPI.apptodo('getToken', {}, 'tokenCallback');

	// 		Toast.show({
	// 			icon: 'loading',
	// 			content: 'Get token …',
	// 			duration: 0,
	// 		})
	// 	}
	// }

	const getDiskStorageSize = async () => {
		let res: any = await getStorageInformation();
		let { data, result } = res;
		if (result === 'ok' && data) {

			if (isJSON(data)) {
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data?.backup_id,
					backup_id: data.backup_id,
				}

				if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}
				// console.log(data.size)
				setCloudDiskInfo(() => data)
			}
		} else if (result === "401") {
			navigateX("/login")
		}

		getList(searchVal);
	}

	const handleSortBy = () => {
		setIsSortBy(() => !isSortBy)
	}

	const handleList = () => {
		localStorage.setItem('me-drive-view-style', !isShow ? 'block' : 'list');
		setIsShow(() => !isShow)
	}

	const handleMore = (item: any) => {
		setIsDetails(true);
		item._frompage = "trash"
		setDetailsObj(item);
		deleteTypeRef.current = 0;
	}

	const handleSearch = () => {
		setIsSearch(() => true)
	}

	const handleSearchInput = async (e) => {
		const val = (e.target.value).trim()
		if (val) {
			setSearchVal(val)
		} else {
			setSearchVal('')

		}
	}

	// 获取列表
	const getList = async (key) => {
		let req: any = {}
		if (key) {
			req = {
				...pageInfo,
				// page: _page,
				key
			}
		} else {
			req = {
				...pageInfo,
				// page: _page
			}
		}

		let res: any = await getTrashList(req);
		// const token = localStorage.getItem('token')
		// setLoadingMainData(() => 1);
		setLoadingMainData(() => 2);
		let { result, data }: any = res;
		if (result === 'ok' && data) {
			if (isJSON(data)) {
				let _dirs = data['dirs'];
				_dirs = Array.isArray(_dirs) ? _dirs : [];
				_dirs.map((item: any) => {
					let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified on';
					let _dt = dayjs(item.update_at).format(dateTimeFormat);
					item.content = `${_lb} ${_dt}`;
					item.createdTime = dayjs(item.update_at).format('MM/DD/YYYY hh:mm A')
					// item.last_modify_time =  dayjs(item.last_modify_time).format('MM/DD/YYYY hh:mm A')
					// item.update_at =  dayjs(item.update_at).format('MM/DD/YYYY hh:mm A')
					// item.icon = '/res/icons/icon_folder_v1.svg?v=1';
					item.icon = '/res/icons/icon_folder2.svg';
					item.isSelect = false
					item.isHide = false
					return item;
				})
				setFolderList(() => _dirs);

				let _files = data['files'].content;
				_files = Array.isArray(_files) ? _files : [];
				_files.map((item: any) => {
					let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified on';
					let _dt = dayjs(item.update_at).format(dateTimeFormat);
					item.createdTime = dayjs(item.update_at).format('MM/DD/YYYY hh:mm A')
					// item.last_modify_time =  dayjs(item.last_modify_time).format('MM/DD/YYYY hh:mm A')
					// item.update_at =  dayjs(item.update_at).format('MM/DD/YYYY hh:mm A')
					item.icon = fileType[item.type]['icon'];
					// item.content = `${_lb} ${_dt}`;
					item.content = pageInfo.orderBy === 'size' ? `Storage used: ${sizeFormat(item.size)}` : `${_lb} ${_dt}`;
					if (Number(cloudDiskInfo.size) > 0) {
						// if (item.type === 3 && item.cover && Number(item.status) !== 0) {
						// 	item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
						// } else if (item.type === 1 && item.cover && Number(item.status) !== 0) {
						// 	item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
						// } else if (item.type === 2 && Number(item.status) !== 0) {
						// 	item.icon = fileTypeIcon[item.ext];
						// }
					}

					item.isSelect = false
					item.isHide = false
					return item;
				})

				setFileList(() => _files);
				setList(() => _dirs.concat(_files))
				setTimeout(() => {
					setLoadingMainData(() => 2);
					changeFolderRef.current = 2;
				}, 200)
				setSortRequst(() => false);
			}
		}
	}

	useEffect(() => {
		clearTimeout(timeOutRef.current);
		timeOutRef.current = setTimeout(() => {
			getDiskStorageSize();
		}, 230)

		return () => {
			clearTimeout(timeOutRef.current);
		}
	}, [searchVal])

	useEffect(() => {
		if (sortRequst) {
			clearTimeout(timeOutRef.current);
			timeOutRef.current = setTimeout(() => {
				getDiskStorageSize();
			}, 230)

			return () => {
				clearTimeout(timeOutRef.current);
			}
		}

	}, [sortRequst])


	// 关闭删除弹窗
	const onCloseFunc = () => {
		setIsDeleteModal(() => false);
		setDetailsObj(() => { });
		deleteTypeRef.current = 0;
		setIsAllHide(() => false)
	}

	// 执行确认删除操作
	const onActionFunc = async () => {

		if (deleteTypeRef.current === 1) {
			clearTrashDoneFunc();
		} else {
			if (detailsObj.id === undefined) {
				return;
			}
			let _func = detailsObj.type === undefined ? deleteTrashFolder : deleteTrashFile;
			let res: any = await _func(detailsObj.id);
			let { result, data }: any = res;
			if (result === 'ok' && data) {
				onCloseFunc();
				setTimeout(() => {
					getList(searchVal);
				}, 100)
			}
		}


	}

	// 底部详情弹窗，操作
	const bottomMenuListActionFunc = (v) => {

		if (v === 'delete') {
			setIsDeleteModal(() => true);
		} else if (v === 'restore') {
			restoreFunc();
		} else if (v === 'detail') {
			jumpPage(navigateX, `/details?id=${detailsObj.id}&type=${detailsObj.type}&frompage=trash&target=4&fileID=${cFolderID}`)
		}
	}

	// 执行 恢复操作
	const restoreFunc = async () => {
		if (tipFunc()) return;

		if (detailsObj.id === undefined) {
			return;
		}
		let _func = detailsObj.type === undefined ? recoverTrashFolder : recoverTrashFile;
		let res: any = await _func(detailsObj.id);
		let { result, data }: any = res;
		if (result === 'ok' && data) {
			onCloseFunc();
			setTimeout(() => {
				getList(searchVal);
			}, 100)
		}
	}

	const tipFunc = () => {

		if (Number(cloudDiskInfo.size) === 0 || Number(cloudDiskInfo.usable) < 0) {
			let _content = 'Access restricted';
			if (Number(cloudDiskInfo.size) > 0 && Number(cloudDiskInfo.usable) < 0) {
				_content = 'Not enough space'
			}
			// Modal.alert({
			// 	title: 'Tips',
			// 	content: _content,
			// 	destroyOnClose: true,
			// 	confirmText: 'OK'
			// })
			setIsTipsModal(true)

			// const _show_modal = Modal.show({
			// 	title: 'Tips',
			// 	content: _content,
			// 	closeOnAction: !true,
			// 	actions: [
			// 		{
			// 			key: 'submit',
			// 			text: 'Restore now',
			// 			primary: true,
			// 		},
			// 		{
			// 			key: 'close',
			// 			text: 'Later',
			// 		},
			// 	],
			// 	onAction: ({ key }) => {
			// 		if (key === 'close') {
			// 			_show_modal.close();
			// 		} else if (key === 'submit') {
			// 			jumpPage(navigate, `/plan`);
			// 			_show_modal.close();
			// 		}
			// 	}
			// })

			// return true;
		} else {
			return false;
		}
	}

	// 清空垃圾筐
	const clearTrashFunc = (num: Number) => {
		if (num === 2) {
			let newFolderList = _.cloneDeep(folderList)
			let newFileList = _.cloneDeep(fileList)
			const list = [...newFolderList, ...newFileList]
			let numTotal = list.length
			setIsAllNum(numTotal)
			setIsAllHide(false)
			if (list.length <= 0) {
				message.warning("The recycle bin data is empty!")
			} else {
				setIsDeleteModal(() => true);
				deleteTypeRef.current = 1;
			}
			setIsRestore(false)
		} else {
			const list = [...folderList, ...fileList]
			let numTotal = list.filter((item: any) => item.isSelect).length
			setIsAllNum(numTotal)
			if (list.length <= 0) {
				message.warning("The recycle bin data is empty!")
			} else {
				setIsDeleteModal(() => true);
				deleteTypeRef.current = 1;
			}
		}
	}

	const clearTrashDoneFunc = async () => {
		let res: any = await clearTrash();
		let { result, data }: any = res;
		if (result === 'ok' && data) {
			onCloseFunc();
			setTimeout(() => {
				getList(searchVal);
			}, 100)

			setIsUpdateVaultInfoZU(true)
		}
	}

	const clickRowFunc = (o) => {
		if (Number(cloudDiskInfo?.size) === 0) {
			return false
		}
		if (enterLongPressEvent) {
			if (o.type === undefined) {
				if (o.disabled) {

				} else {
					let _folderSelectList = JSON.parse(JSON.stringify(folderSelectList));

					if (_folderSelectList.includes(o.id)) {
						_folderSelectList = _folderSelectList.filter((item) => {
							return item !== o.id;
						})
					} else {
						_folderSelectList.push(o.id)
					}

					setFolderSelectList(() => _folderSelectList)
				}
			} else {
				let _fileSelectList = JSON.parse(JSON.stringify(fileSelectList));

				if (_fileSelectList.includes(o.id)) {
					_fileSelectList = _fileSelectList.filter((item) => {
						return item !== o.id;
					})
				} else {
					_fileSelectList.push(o.id)
				}

				setFileSelectList(() => _fileSelectList)
			}
			return;
		} else {
			if (o.type === undefined) {
				openFolder(o)
			} else {
				openFile(o)
			}

		}
	}

	const getEleSize = () => {
		let _dom = containRef.current;
		if (_dom) {
			let _h = _dom.clientWidth;
			_h = (_h - 120) / 6;

			return {
				style: {
					width: `${_h}px`,
					height: `152px`
				}
			}
		} else {
			return {};
		}
	}


	const _eleSize = typeof containRef.current === 'object' ? getEleSize() : {}

	const openFolder = (o) => {
		if (Number(cloudDiskInfo.size) > 1024 * 1024 * 300) {

		}
		// jumpPage(navigate, `/home?id=${o.id}&type=${o.type}`);
	}

	const openFile = (o) => {
		if (Number(o.status) === 0) return;
		if (Number(cloudDiskInfo.size) === 0) return;
		if (isJSON(o)) {
			jumpPage(navigateX, `/vault/file?id=${o.id}&type=${o.type}&target=1&frompage=trash`)
		}

	}

	const changePressStart = (v) => {
		// setEnterLongPressEvent(() => v)
	}

	const changeSearchValue = (v) => {
		// console.log(v);

		setSearchValue(() => v);
	}

	const filterList = (v) => {
		let _arr = [];
		if (Array.isArray(v)) {
			for (let item of v) {
				let _name = item.name;
				if (_name.includes(searchValue)) {
					_arr.push(item);
				}
			}
		}
		return _arr;
	}

	let _list = JSON.parse(JSON.stringify(list));
	_list = isSortBy ? _list.reverse() : _list;

	_list = searchValue ? filterList(_list) : _list;


	const aContent = `"${detailsObj?.name}" will be deleted forever. This can NOT be undone.`
	const content = `All items in the trash will be deleted forever and you will not be able to restore them.`

	let searchDOM = <div className={'trash-search-box'}>
		<span className={'sh-icon'}><img src="/res/icons/icon_search2.svg" alt="" /></span>
		{/* <Input clearable={true} onChange={changeSearchValue} /> */}
	</div>;
	let searchBTN = <span onClick={handleSearch}><img src="/res/icons/icon_search2.svg" style={{ width: "20px" }} alt="" /></span>

	// 全选
	const handleSelect = (num: Number) => {
		let newFolderList = _.cloneDeep(folderList)
		let newFileList = _.cloneDeep(fileList)
		if (num === 1) {
			setIsAllSelect(3)
			newFileList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFileList(newFileList)
			newFolderList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFolderList(newFolderList)

		} else if (num === 2) {
			newFileList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFileList(newFileList)
			newFolderList.map((it: any) => {
				it.isSelect = true;
				return it
			})
			setFolderList(newFolderList)
			setIsAllSelect(3)
		} else {
			newFileList.map((it: any) => {
				it.isSelect = false;
				return it
			})
			setFileList(newFileList)
			newFolderList.map((it: any) => {
				it.isSelect = false;
				return it
			})
			setFolderList(newFolderList)
			setIsAllSelect(1)
		}
	}

	useEffect(() => {
		let newFolderList = _.cloneDeep(folderList)
		let newFileList = _.cloneDeep(fileList)
		let list = [...newFolderList, ...newFileList]
		let num = list.length >= 1 ? list.some((it: any) => it.isSelect) : false
		let num1 = list.length >= 1 ? list.every((it: any) => it.isSelect) : false
		if (num) {
			setIsAllSelect(2)
		} else {
			setIsAllSelect(1)
		}
		if (num1) {
			setIsAllSelect(3)
		}
		let arr: any = []
		list.map((item: any) => {
			if (item.isSelect) {

				arr.push(item)
			}
			return item
		})
		let sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0);
		setSizeNum(sizeFormat(sum))

	}, [folderList, fileList])

	// 恢复文件
	const handleRestore = async () => {
		if ((!_has_unuse_size) || Number(cloudDiskInfo.size) === 0) {
			setIsTipsModal(true)
			return false
		}
		let newFolderList = _.cloneDeep(folderList)
		let newFileList = _.cloneDeep(fileList)
		let newFolderArr: any[] = []
		let newFileArr: any[] = []
		let isTrue1 = newFileList?.some((item: any) => item.isSelect)
		let isTrue2 = newFolderList?.some((item: any) => item.isSelect)
		newFolderList.map((item: any) => {
			if (item.isSelect) {
				newFolderArr.push(item.id)
			}
			return item
		})
		newFileList.map((item: any) => {
			if (item.isSelect) {
				newFileArr.push(item.id)
			}
			return item
		})

		if (isTrue2) {

			const res: any = await recoverTrashFolders(newFolderArr)
			let { result, data }: any = res;
			if (result === 'ok' && data) {
				setTimeout(() => {
					getList(searchVal);
					setIsAllHide(() => false)
					setIsAllSelect(1)
					message.success('Restore success');
				}, 100)
			}
		}

		if (isTrue1) {

			const res: any = await recoverTrashFiles(newFileArr)
			let { result, data }: any = res;
			if (result === 'ok' && data) {
				setTimeout(() => {
					getList(searchVal);
					setIsAllHide(() => false)
					setIsAllSelect(1)
					message.success('Restore success');
				}, 100)
			}
		}

	}

	const handleMouseLeave = () => {
		let newFileList = _.cloneDeep(fileList)
		let newFolderList = _.cloneDeep(folderList)  // 文件夹
		// let isTrue1 = newFileList.some((item: any) => item.isSelect)
		// let isTrue2 = newFolderList.some((item: any) => item.isSelect)

		// if (isTrue1 || isTrue2) {
		// 	setIsAllHide(() => true)
		// } else {
		// 	setIsAllHide(() => false)
		// }
		let list = [...newFileList, ...newFolderList]
		let numTotal = list.filter((item: any) => item.isSelect).length
		if (numTotal <= 0) {
			setIsAllHide(() => false)
		}


	}

	const handleMouseEnter = () => {
		// let newFileList = _.cloneDeep(fileList)
		// let newFolderList = _.cloneDeep(folderList)
		// if (newFileList.length >= 1 || newFolderList.length >= 1) {
		// 	setIsAllHide(() => true)
		// } else {
		// 	setIsAllHide(() => false)
		// }
		setIsAllHide(() => true)
	}

	useEffect(() => {
		let newFileList = _.cloneDeep(fileList)
		let newFolderList = _.cloneDeep(folderList)
		let list = [...newFolderList, ...newFileList]
		let isTrue = list.some((item: any) => item.isSelect)
		// let isTrue2 = newFolderList.some((item: any) => item.isSelect)
		let numTotal = list.filter((item: any) => item.isSelect).length
		if (isTrue) {
			setIsRestore(true)
		} else {
			setIsRestore(false)
		}
		setIsAllNum(numTotal)


	}, [fileList, folderList])

	let _has_unuse_size: any = Number(cloudDiskInfo?.usable);
	_has_unuse_size = isNaN(_has_unuse_size) ? -1 : _has_unuse_size;
	_has_unuse_size = _has_unuse_size > 0;


	// 下拉刷新数据
	const onRefreshFunc = async () => {
		if (pullPageFlag.current) return;
		resetPageInfo();
		pullPageFlag.current = true;

		clearTimeout(timeOutRef.current);
		timeOutRef.current = setTimeout(() => {
			getList(searchVal)
			pullPageFlag.current = false;
		}, 1050);
	}
	// 重置页面信息
	const resetPageInfo = () => {
		// let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		// _pageInfo.page = 0;
		// setPageInfo((prev) => _pageInfo);

		// reqPageRef.current = 0;
	}


	const sortFunc = (v) => {
		let _pageInfo = JSON.parse(JSON.stringify(pageInfo));
		_pageInfo = {
			..._pageInfo,
			...v
		}
		// console.log(_pageInfo, '_pageInfo470')

		let _localJSON = {
			order: _pageInfo.order,
			orderBy: _pageInfo.orderBy,
		}

		localStorage.setItem('vault-sort-info', JSON.stringify(_localJSON));

		setPageInfo(() => _pageInfo);
		setSortRequst(() => true);
	}
	const handleSort = (e: any) => {
		console.log(e.target, '789')
		if (Number(e.target.id) === 1) {
			setIsName(() => !isName)
			let _data: any = {
				orderBy: "name",
				order: isName ? 'desc' : 'asc',
			}
			sortFunc(_data);
		} else if (Number(e.target.id) === 2) {

			setIsCreated(() => !isCreated)
			let _data: any = {
				orderBy: "createTime",
				order: isCreated ? 'desc' : 'asc',
			}
			sortFunc(_data);
		} else if (Number(e.target.id) === 3) {
			setIsModified(() => !isModified)
			let _data: any = {
				orderBy: "updateTime",
				order: isModified ? 'desc' : 'asc',
			}
			sortFunc(_data);
		} else if (Number(e.target.id) === 4) {
			setIsSize(() => !isSize)
			let _data: any = {
				orderBy: "size",
				order: isSize ? 'desc' : 'asc',
			}
			sortFunc(_data);
		}
		setIsSort(Number(e.target.id))

	}

	useEffect(() => {
		if (!isDeleteModal) {
			let newFileList = _.cloneDeep(fileList)
			newFileList.map((item: any) => {
				item.isSelect = false
				return item
			})
			setFileList(newFileList)
			let newFolderList = _.cloneDeep(folderList)
			newFolderList.map((item: any) => {
				item.isSelect = false
				return item
			})
			setFolderList(newFolderList)
		}
	}, [isDeleteModal])


	return <><div className="favourite-con trash-page con">
		<div className={'vault-head-contain'}>
			<div className="page-con">
				<div className="page-con-title">
					Trash
				</div>

			</div>

			<div className="content-title" style={{ alignItems: "center" }}>
				{/* <div className='adm-popover--sort-menu-all'>
							{
								 <div className='select-all'  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
									{isAllSelect === 1 && <img src="/res/icons/icon_select1.svg" onClick={() => handleSelect(1)} style={{ width: "18px", height: "18px" }} alt="" />}
									{isAllSelect === 2 && <img src="/res/icons/icon_select2_active.svg" onClick={() => handleSelect(2)} style={{ width: "18px", height: "18px" }} alt="" />}
									{isAllSelect === 3 && <img src="/res/icons/icon_select1_active.svg" onClick={() => handleSelect(3)} style={{ width: "18px", height: "18px" }} alt="" />}

								</div>
							}
					<h4 onClick={handleSortBy}>Delete Date</h4>
					<img src="/res/icons/icon_order.svg" alt="" style={{ transform: isSortBy ? 'rotate(180deg)' : 'rotate(0deg)', marginLeft: "6px" }} />
				</div> */}
				<div className='controls controls-tom'>
					{
						(isRestore) && <div className='move-to-trash' onClick={handleRestore}>
							<img src="/res/icons/icon_restore.svg" alt="" />
							<span>Restore </span>
						</div>

					}
					{(isRestore) && <div className="move-to-line"></div>}
					{
						(isRestore) && <div className='move-to-trash' onClick={() => clearTrashFunc(1)}>
							<img src="/res/icons/icon_trash_v2.svg" />
							<span>Permanently Remove</span>
						</div>
					}
					{(isRestore) && <div className="move-to-line"></div>}
					{(isRestore) && <div className='move-to-trash move-to-trash-selected'><span>{isAllNum}</span> &nbsp; Selected ({sizeNum})</div>}
				</div>
				<div className="page-con-item">
					<div className="page-con-search">
						<Input placeholder="Search" value={searchVal} onChange={(e) => handleSearchInput(e)} />
						<img src="/res/icons/icon_search.svg" alt="" className="search-img"></img>
					</div>
					<div className="page-con-sort">
						<Space size={16}>
							<div onClick={handleList}>
								{isShow ? <img src={"/res/icons/icon_view_1.svg"} alt="" style={{ width: "18px", height: "18px", cursor: "pointer" }} /> : <img style={{ width: "18px", height: "18px", cursor: "pointer" }} src={"/res/icons/icon_view_1-active.svg"} alt="" />}
							</div>
							<div onClick={handleList}>
								{isShow ? <img src={"/res/icons/icon_view_2-active.svg"} alt="" style={{ width: "18px", height: "18px", cursor: "pointer" }} /> : <img style={{ width: "18px", height: "18px", cursor: "pointer" }} src={"/res/icons/icon_view_2.svg"} alt="" />}
							</div>
						</Space>
					</div>
				</div>

				{/*<div onClick={handleList}>*/}
				{/*	<img src={isShow ? "/res/icons/icon_list_two.svg" : "/res/icons/icon_list_one.svg"} alt="" />*/}
				{/*</div>*/}

				{/* <div>
					<Space size={16}>
						<div onClick={handleList}>
							{isShow ? <img src={"/res/icons/icon_view_1.svg"} alt="" style={{ width: "18px", height: "18px", cursor: "pointer" }} /> : <img style={{ width: "18px", height: "18px", cursor: "pointer" }} src={"/res/icons/icon_view_1-active.svg"} alt="" />}
						</div>
						<div onClick={handleList}>
							{isShow ? <img src={"/res/icons/icon_view_2-active.svg"} alt="" style={{ width: "18px", height: "18px", cursor: "pointer" }} /> : <img style={{ width: "18px", height: "18px", cursor: "pointer" }} src={"/res/icons/icon_view_2.svg"} alt="" />}
						</div>
					</Space>
				</div> */}
			</div>
			<div className="content-title-header" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={(e) => handleSort(e)}>
				<div id="1">
					Name
					{isSort === 1 && <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px", transform: isName ? 'rotate(0deg)' : 'rotate(180deg)' }} alt="" className="order-img"></img>}
				</div>
				<div id="2">
					Delete Time
					{isSort === 2 && <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px", transform: isCreated ? 'rotate(0deg)' : 'rotate(180deg)' }} alt="" className="order-img"></img>}
				</div>
				<div id="4">
					Size
					{isSort === 4 && <img src="/res/icons/icon_order.svg" style={{ width: "12px", marginLeft: "6px", transform: isSize ? 'rotate(0deg)' : 'rotate(180deg)' }} alt="" className="order-img"></img>}
				</div>
				<div></div>
				{isAllHide &&
					<div className='select-all'>
						{isAllSelect === 1 && <img src="/res/icons/icon_select1.svg" onClick={() => handleSelect(1)} style={{ width: "18px", height: "18px" }} alt="" />}
						{isAllSelect === 2 && <img src="/res/icons/icon_select2_active.svg" onClick={() => handleSelect(2)} style={{ width: "18px", height: "18px" }} alt="" />}
						{isAllSelect === 3 && <img src="/res/icons/icon_select1_active.svg" onClick={() => handleSelect(3)} style={{ width: "18px", height: "18px" }} alt="" />}
					</div>
				}
			</div>
		</div>

		<div className="content" ref={containRef} >
			{
				loadingMainData === 1 ? <>
					<LoadDataWaitingState />
				</>
					:
					<> {changeFolderRef.current === 2 &&
						<div className={'file-item-list'}>
							<PullToRefresh
								onRefresh={onRefreshFunc}
								renderText={() => {
									return <div>Pull down to refresh</div>
								}}
								disabled={enterLongPressEvent}
							>
								{
									(folderList.length === 0 && fileList.length === 0 && loadingMainData === 2) &&
									<div className="content-data">
										<div className="content-data-img">
											<img src="/res/icons/no_file_found.png" alt="" />
										</div>
										{/* {
											 <div className="content-data-txt">
												<p style={{ color: "#ff3141" }}>No files</p>
											</div> 
										} */}

									</div>
								}

								{
									(folderList.length > 0 || fileList.length > 0) && <>
										<ContentItem
											{..._eleSize}
											isShow={isShow}
											// list={[..._list]}
											list={[...folderList, ...fileList]}
											folderList={folderList}
											fileList={fileList}
											isAllHide={isAllHide}
											onFileList={setFileList}
											onFolderList={setFolderList}
											onIsAllSelect={setIsAllSelect}
											onIsAllHide={setIsAllHide}
											onHandleMore={handleMore}
											onClick={clickRowFunc}
											onLongPress={changePressStart}
											longPress={enterLongPressEvent}
											folderCheckedList={folderSelectList}
											fileCheckedList={fileSelectList}
											inputVal={inputVal}
											onInputVal={setInputVal}
											onPositionObj={setPositionObj}
											currentBg={currentBg}
											onCurrentBg={setCurrentBg}
											cloudDiskInfo={cloudDiskInfo}
										/>
									</>
								}

							</PullToRefresh>
						</div>
					}
					</>
			}


		</div>
		<TrashFileDetailsModal open={isFileDetailsModal} onOpen={setIsFileDetailsModal} initPageData={getList} data={detailsObj} />
		<TrashDetailsModal
			type={"file"}
			visible={isDetails}
			onVisible={setIsDetails}
			detailsObj={detailsObj}
			onAction={bottomMenuListActionFunc}
			cloudDiskInfo={cloudDiskInfo}
			onCurrentBg={setCurrentBg}
			onIsFileDetailsModal={setIsFileDetailsModal}
			init={getList}
			positionObj={positionObj}
			onIsAllNum={setIsAllNum}

		/>
		<DeleteModal
			visible={isDeleteModal}
			onVisible={setIsDeleteModal}
			title={deleteTypeRef.current === 1 ? "Empty Trash" : "Delete forever"}
			contentObj={deleteTypeRef.current === 1 ? content : aContent}
			onClose={onCloseFunc}
			onAction={onActionFunc}
			actionButtonText={deleteTypeRef.current === 1 ? "OK" : 'Delete Forever'}
			target="Trash"
			num={isAllNum}
		/>
		{/* <StoragePage isStorage={isStorage} onIsStorage={setIsStorage} onIsCleanUpMeDrive={setIsCleanUpMeDrive} /> */}
		<CleanUpMeDrive open={isCleanUpMeDrive} onIsStorage={setIsStorage} onOpen={setIsCleanUpMeDrive} />
		<div className="empty-trash">
			<div>
				<p><img src="/res/icons/icon_trash_v2.svg" alt="" /></p>
				<p>Items will be permanently removed after 30 days.</p>
			</div>
			<Button onClick={() => clearTrashFunc(2)}>Empty Trash</Button>
		</div>
		<TipsModal visible={isTipsModal} onVisible={setIsTipsModal} diskInfo={cloudDiskInfo} />
	</div>
	</>
}

export default Trash
