import { Button, Popup, Input, InfiniteScroll, DotLoading, Space, Checkbox, Toast } from 'antd-mobile'
import './index.less'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFolderContent } from "../../../server/folder";
import {getLGToken, getUserBasicCloudDisk, getUserBasicFolderIDS, isJSON, sizeFormat} from "../../../utils/common";
import dayjs from "dayjs";
import fileType from "../../../utils/fileType";
import getApiUrl from "../../../server/apiUrl";
import token from "../../../utils/token";
import ContentItem from "../../../components/content-item";
import jumpPage from "../../../utils/jumpPage";
import CreateNewFolder from "../meDrive/components/create-new-folder";
import DetailsModal from "../../../components/details-modal";
import { useParams } from 'react-router-dom';
import { getStorageInformation } from "../../../server/storage";
import HeadNav from "../../../components/headNav";
import NAPI from "../../../utils/NAPI";
import fileTypeIcon from "../../../utils/fileTypeIcon";

const imageUrl = getApiUrl('cloudDisk');

const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
	return (
		<>
			{hasMore ? (
				<>
					<span>Loading</span>
					<DotLoading />
				</>
			) : (
				// <span>--- End ---</span>
				<></>
			)}
		</>
	)
}

function Search(props) {
	const navigate = useNavigate()
	const [values, setValues] = useState<string>('')
	const [pageInfo, setPageInfo] = useState<any>({
		page: 0,
		pageSize: 10 * 3,
		orderBy: 'name', //name, updateTime, size
		order: 'asc', //Asc:顺序 Desc:逆序
		// key: '', // 关键字
	})
	const [loadMoreFlag, setLoadMoreFlag] = useState<boolean>(false);
	const [isDetails, setIsDetails] = useState<boolean>(false)
	const [detailsObj, setDetailsObj] = useState<any>({})

	const [folderList, setFolderList] = useState<any>([]); //  文件夹列表
	const [fileList, setFileList] = useState<any>([]); //  文件列表
	const [enterLongPressEvent, setEnterLongPressEvent] = useState<boolean>(false);  // 进入长按事件
	const [userBasicCloudDisk] = useState(getUserBasicCloudDisk())
	const [folderSelectList, setFolderSelectList] = useState<any>([]);
	const [fileSelectList, setFileSelectList] = useState<any>([]);
	const [basicFolderSelectList, setBasicFolderSelectList] = useState<any>([]);
	const [cloudDiskInfo, setCloudDiskInfo] = useState<any>({})

	const timeOutRef: any = useRef(0);
	const requestDataRef = useRef(false);
	const requestPageInfo = useRef({
		number_of_elements: 0,
		page_number: 0,
		total_elements: 0,
		total_pages: 0,
	});
	const textRef = useRef('');

	const location = useLocation()

	useEffect(() => {
		window['tokenCallback'] = tokenCallback
	}, [])

	const tokenCallback = (v) => {
		sessionStorage.setItem('my-cloud-disk-token', v);
		// setLoading((() => false))
		Toast.clear();
	}

	const getTokenFromApp = () => {
		console.log('WaitingToken getToken')
		if (NAPI.check()) {
			NAPI.apptodo('getToken', {}, 'tokenCallback');

			Toast.show({
				icon: 'loading',
				content: 'Get token …',
				duration: 0,
			})
		}
	}

	const handleInput = (e: any) => {
		if (e) {
			const vals = e.trim()
			setValues(vals)
			// setList(newList)
		} else {
			setValues(() => "")
			clearList();
		}
	}

	const handleReturn = () => {
		navigate(-1)
	}

	const getDiskStorageSize = async () => {
		let res: any = await getStorageInformation();
		let { data, result } = res;
		if (result === 'ok' && data) {

			if (isJSON(data)) {
				//	用户的目录信息
				let user_cloud_disk_info = {
					cloud_gallery: data.cloud_gallery,
					cloud_gallery_id: data.cloud_gallery_id,
					root_id: data.root_id,
					photos_id: data.photos_id,
					backup_id: data.backup_id,
				}

				if (user_cloud_disk_info.root_id && user_cloud_disk_info.cloud_gallery_id) {
					localStorage.setItem('user_cloud_disk_info', JSON.stringify(user_cloud_disk_info));
				}

				setCloudDiskInfo(() => data);
			}
		} else if(Number(result) === 401){
			navigate('/login')
		}
	}

	useEffect(() => {
		getDiskStorageSize();
	}, [])

	const getList = async (_page = 0) => {
		if (textRef.current !== values) return;
		const token = getLGToken()

		let req = {
			...pageInfo,
			page: _page,
			key: values,
		}

		if (requestPageInfo.current.page_number) {
			req.page = requestPageInfo.current.page_number;
		}

		let searchParams = new URLSearchParams(location.search);
		let _id: any = searchParams.get('id');
		_id = Number(_id);

		if (!(Number.isNaN(_id) || _id === 0)) {
			req.dirId = _id;
		}
		console.log(typeof _id, _id)

		let res: any = await getFolderContent(req);
		let { data, result }: any = res;
		if (result === 'ok' && data) {
			let files: any = data['files']; //文件
			let dirs: any = data['dirs']; // 文件夹
			dirs = Array.isArray(dirs) ? dirs : [];
			files = isJSON(files) ? files['content'] : [];
			files = Array.isArray(files) ? files : [];

			let _arr = getUserBasicFolderIDS();

			dirs.map((item: any) => {
				let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified';
				let _dt = dayjs(item.update_at).format('MMM D, YYYY');
				item.icon = '/res/icons/icon_folder_v2.svg';
				item.content = `${_lb} ${_dt}`;
				// item.name = `${values}==${item.name}`;

				if (_arr.includes(item.id)) {
					// item.disabled = true;
					item.system_dir = true;
					item.icon = '/res/icons/icon_folder.svg';
				}

				return item;
			})

			files.map((item: any) => {
				let _lb = item.create_at === item.update_at ? 'Created on' : 'Modified';
				let _dt = dayjs(item.update_at).format('MMM D, YYYY');
				item.icon = fileType[item.type]['icon'];
				item.content = pageInfo.orderBy === 'size' ? `Storage used: ${sizeFormat(item.size)}` : `${_lb} ${_dt}`;

				if (item.type === 3 && item.cover && Number(item.status) !== 0) {
					item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
				} else if (item.type === 1 && item.cover && Number(item.status) !== 0) {
					item.icon = `${imageUrl}/disk/download_file?url=${item.cover}&name=${item.name}&token=${token}`;
				}else if(item.type === 2 && Number(item.status) !== 0){
					item.icon = fileTypeIcon[item.ext];
				}
				// item.name = `${values}==${item.name}`;
				return item;
			})

			let _onePageData = {
				number_of_elements: data['files']['number_of_elements'],
				page_number: data['files']['page_number'],
				total_elements: data['files']['total_elements'],
				total_pages: data['files']['total_pages'],
			}
			if (data['files']['page_number'] === 0) {
				let _dir_arr = []
				for (let o of dirs) {
					if (o.system_dir) {
						_dir_arr.unshift(o)
					} else {
						_dir_arr.push(o);
					}
				}

				setFolderList(() => _dir_arr);
				setFileList(() => files);
			} else {
				setFileList((prev) => prev.concat(files));
			}

			// 已加载完成
			if (_onePageData.page_number + 1 >= _onePageData.total_pages || _onePageData.total_pages === 0) {

			} else {
				getList(_page + 1)
			}
		}
	}

	const initData = async () => {
		textRef.current = values;
		getList();
	}

	useEffect(() => {
		clearTimeout(timeOutRef.current);
		if (values !== '') {
			timeOutRef.current = setTimeout(() => {
				initData();
			}, 230)
		} else {
			clearList()
			requestPageInfo.current.page_number = 0;
		}

		return () => {
			clearTimeout(timeOutRef.current);
		}
	}, [values])

	const clearList = () => {
		setFolderList(() => [])
		setFileList(() => [])
		setBasicFolderSelectList(() => []);
		setFolderSelectList(() => []);
		setFileSelectList(() => []);
		changePressStart(false);
	}

	// 操作后的处理
	const reqData = (v, o, action = '') => {
		if (v) {
			let _iFolder = [];
			let _iFile = [];
			if (isJSON(o)) {
				if (o.type === undefined) {
					_iFolder.push(o);
				} else {
					_iFile.push(o);
				}

			} else if (Array.isArray(o)) {
				for (let item of o) {
					if (item.type === undefined) {
						_iFolder.push(item);
					} else {
						_iFile.push(item);
					}
				}
			}


			if (action === 'delete') {
				removeItemFromList(_iFolder, _iFile)
			} else if (action === 'rename') {
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			} else if (action === 'collect') {
				updateList(_iFolder, _iFile);
				setDetailsObj(() => o);
			}
		}
	}

	const updateList = (_folder: any, _file: any) => {

		if (_folder.length > 0) {
			let _folderList = JSON.parse(JSON.stringify(folderList));
			_folderList = _folderList.map((o: any) => {
				for (let item of _folder) {
					if (o.id === item.id) {
						o = item;
					}
				}
				return o;
			})
			setFolderList(() => _folderList);
		}

		if (_file.length > 0) {
			let _fileList = JSON.parse(JSON.stringify(fileList));
			_fileList = _fileList.map((o: any) => {
				for (let item of _file) {
					if (o.id === item.id) {
						o = item;
					}
				}
				return o;
			})
			setFileList(() => _fileList);
		}

		if (enterLongPressEvent) {
			cancelSelectFunc();
		} else {
			setDetailsObj(() => Object({}));
		}
	}

	const removeItemFromList = (_folder: any, _file: any) => {

		if (_folder.length > 0) {
			let _arrids = _folder.map((item: any) => {
				return item.id;
			})

			let _folderList = JSON.parse(JSON.stringify(folderList));
			let _arr = [];
			for (let o of _folderList) {
				if (!_arrids.includes(o.id)) {
					_arr.push(o);
				}
			}
			setFolderList(() => _arr);
		}

		if (_file.length > 0) {
			let _arrids = _file.map((item: any) => {
				return item.id;
			})

			let _fileList = JSON.parse(JSON.stringify(fileList));
			let _arr = [];
			for (let o of _fileList) {
				if (!_arrids.includes(o.id)) {
					_arr.push(o);
				}
			}
			setFileList(() => _arr);
		}

		if (enterLongPressEvent) {
			cancelSelectFunc();
		} else {
			setDetailsObj(() => Object({}));
		}
	}

	const clickRowMore = (o) => {
		handleMore(o);
	}

	const clickRowFunc = (o) => {
		if (enterLongPressEvent) {
			if (o.type === undefined) {
				if (o.disabled) {

				} else {
					let _folderSelectList = JSON.parse(JSON.stringify(folderSelectList));
					let _disIDS = getUserBasicFolderIDS();
					let _folderList = JSON.parse(JSON.stringify(folderList));
					let _disIDSArr = [];

					// for(let item of _folderList){
					// 	if(_disIDS.includes(item.id)){
					// 		_disIDSArr.push(item.id);
					// 	}
					// }
					//
					// setBasicFolderSelectList(() => _disIDSArr)

					if (_folderSelectList.includes(o.id)) {
						_folderSelectList = _folderSelectList.filter((item) => {
							return item !== o.id;
						})
					} else {
						_folderSelectList.push(o.id)
					}

					setFolderSelectList(() => _folderSelectList)
				}
			} else {
				let _fileSelectList = JSON.parse(JSON.stringify(fileSelectList));

				if (_fileSelectList.includes(o.id)) {
					_fileSelectList = _fileSelectList.filter((item) => {
						return item !== o.id;
					})
				} else {
					_fileSelectList.push(o.id)
				}

				setFileSelectList(() => _fileSelectList)
			}
			return;
		}

		if (o.type === undefined) {
			openFolder(o)
		} else {
			if (Number(o.status) !== 0) {
				openFile(o)
			}
		}
	}

	const handleMore = (item: any) => {
		// e.stopPropagation();
		setIsDetails(true)
		setDetailsObj(item)
	}

	// 打开文件夹
	const openFolder = (o) => {
		if (enterLongPressEvent) return;
		jumpPage(navigate, `/vault?id=${o.id}&type=${o.type}`);
	}

	// 打开文件
	const openFile = (o) => {
		if (enterLongPressEvent) return;
		jumpPage(navigate, `/file?id=${o.id}&type=${o.type}`)
	}

	const changePressStart = (v) => {
		setEnterLongPressEvent(() => v)
	}

	const selectAllFunc = (v) => {

		let _folderList = JSON.parse(JSON.stringify(folderList));
		let _fileList = JSON.parse(JSON.stringify(fileList));
		let _disIDS = getUserBasicFolderIDS();

		let _arr = []
		let _disIDSArr = []
		if (v) {
			for (let item of _folderList) {
				// if(_disIDS.includes(item.id)){
				// 	_disIDSArr.push(item.id);
				// }else{
				// 	_arr.push(item.id);
				// }
				_arr.push(item.id);
			}
		}

		setBasicFolderSelectList(() => _disIDSArr)
		setFolderSelectList(() => _arr)

		let _arrii = [];

		if (v) {
			for (let item of _fileList) {
				_arrii.push(item.id);
			}
		}

		setFileSelectList(() => _arrii)
	}

	const downLoadFunc = () => {
		let _arr = [];
		let _fileList = JSON.parse(JSON.stringify(fileList));

		for (let item of _fileList) {
			if (fileSelectList.includes(item.id)) {
				_arr.push(item);
			}
		}

		if (NAPI.check()) {
			NAPI.apptodo('downloadList', _arr)

			setEnterLongPressEvent(() => false);
		}
	}

	const checkLongPressMoreMenu = () => {
		setIsDetails(() => true)
	}

	const cancelSelectFunc = () => {
		setEnterLongPressEvent(() => false);
		setFolderSelectList(() => []);
		setFileSelectList(() => []);
		setBasicFolderSelectList(() => []);
	}

	const getSelectDataByList = () => {
		let _arr = [];
		for (let item of folderList) {
			if (folderSelectList.includes(item.id)) {
				_arr.push(item)
			}
		}

		for (let item of fileList) {
			if (fileSelectList.includes(item.id)) {
				_arr.push(item)
			}
		}

		return _arr
	}

	const endDom = () => {
		let _checked = (fileSelectList.length + folderSelectList.length + basicFolderSelectList.length === fileList.length + folderList.length) && (fileList.length + folderList.length !== 0) && (fileSelectList.length + folderSelectList.length > 0);
		let _indeterminate = (!_checked) && (fileSelectList.length + folderSelectList.length > 0);
		return <div>
			<Space align={"center"} justify={"center"} direction={"horizontal"} style={{ '--gap': '25px' }}>
				<div className={'check-box-quadrate'}>
					<Checkbox
						onChange={selectAllFunc}
						indeterminate={_indeterminate}
						checked={_checked}
						block
					/>
				</div>

				{
					folderSelectList.length === 0 && fileSelectList.length > 0 ?
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px" }} onClick={downLoadFunc} />
						:
						<img src="/res/icons/icon_save_to_device.svg" alt="" style={{ width: "20px", opacity: 0.35 }} />
				}
				<img src="/res/icons/icon_more.svg" alt="" style={{ transform: "rotate(90deg)" }} onClick={checkLongPressMoreMenu} />
			</Space>
		</div>
	}

	let _list = [...folderList, ...fileList];
	let _select_count = folderSelectList.length + fileSelectList.length;
	const _selectData = enterLongPressEvent ? getSelectDataByList() : detailsObj;


	return <div className="search-con">

		{
			enterLongPressEvent ? <>
				<div className="a-header">
					<HeadNav
						start={cancelSelectFunc}
						icon={'/res/icons/icon_close.svg'}
						title={_select_count === 0 ? 'Select items' : `${_select_count} selected`}
						endElement={endDom()}
					/>
				</div>
			</>
				:
				<>
					<div className="search-header">
						<div className="search-header-l" onClick={handleReturn}>
							<img src="/res/icons/icon_return.svg" alt="" />
						</div>
						<div className="search-header-r">
							<Input value={values} placeholder="Search for anything" className="header-search-input" clearable onChange={handleInput} />
							<img src="/res/icons/icon_search.svg" alt="" />
						</div>
					</div>
				</>
		}

		{
			_list.length === 0 && <div className="search-item">
				<div className="search-item-no-data">
					<img src="/res/icons/icon_no_search.svg" alt="" />
					<p>No matching result.</p>
				</div>
			</div>
		}
		{
			_list.length >= 1 && <div className="search-con-item">

				<ContentItem
					isShow={false}
					list={_list}
					onHandleMore={clickRowMore}
					onClick={clickRowFunc}
					onLongPress={changePressStart}
					longPress={enterLongPressEvent}
					folderCheckedList={folderSelectList}
					fileCheckedList={fileSelectList}
				/>

			</div>
		}
		<DetailsModal
			type={"file"}
			visible={isDetails}
			onVisible={setIsDetails}
			data={_selectData}
			reqData={reqData}
			cloudDiskInfo={cloudDiskInfo}
		/>
	</div>
}

export default Search
