import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {useOrderDetailStore, useVaultSubscribeStore} from "../../store/cloudPlan";


const CloudPlanFromJump = () => {
	const navigate = useNavigate()
	const location = useLocation();
	const {search,state} = location;
	const params = new URLSearchParams(search);
	const {dokey} = useParams();
	const {data} = state;

	const {setOrderDetailZU} = useOrderDetailStore();
	const {setVaultInfoZU} = useVaultSubscribeStore();

	useEffect(() => {
		if(dokey === 'view') {
			let _data = {open: true, data: {}}

			if (data) {
				_data.data = data;
			} else {
				_data.data = {
					id: params.get('id'),
					order_pf: params.get('order_pf'),
					order_type: params.get('order_type'),
					size_type: params.get('size_type'),
					pay_type: params.get('pay_type'),
				}
			}

			setOrderDetailZU(_data);
			navigate('/cloud-plan');
		}else if(dokey === 'subscribe'){
			let _data = {open: true, data: {}}

			if (data) {
				_data.data = data;
			} else {
				_data.data = {
					order_pf: params.get('order_pf'),
					order_type: params.get('order_type'),
					size_type: params.get('size_type'),
					pay_type: params.get('pay_type'),
					is_vault: params.get('is_vault'),
				}
			}

			setVaultInfoZU(_data);
			navigate('/cloud-plan');
		}
	}, [])



	console.log(location)

	return <>

	</>
}

export default CloudPlanFromJump
